import { useState, useEffect, useCallback } from 'react'
import awsconfig from '../../aws-exports'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { ApolloClient } from '@apollo/client'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import { isWeb } from '@constants'
import { cache } from 'apollo/cache'
import { AsyncStorageWrapper, LocalStorageWrapper, CachePersistor } from 'apollo3-cache-persist'
import { initAugmentedHooks } from 'apollo-augmented-hooks'
import { persistenceMapper } from './utils/persistence/persistanceMapper'
import { createPersistLink } from './utils/persistence/persistLink'

export const useApolloClient = () => {
    const [client, setClient] = useState()
    const [persistor, setPersistor] = useState()
    const url = awsconfig.aws_appsync_graphqlEndpoint
    const region = awsconfig.aws_appsync_region
    const auth = {
        type: awsconfig.aws_appsync_authenticationType,
        apiKey: awsconfig.aws_appsync_apiKey,
    }
    const persistLink = createPersistLink()
    const link = ApolloLink.from([createAuthLink({ url, region, auth }), createHttpLink({ uri: url })])

    const clearCache = useCallback(async () => {
        if (!persistor) {
            return
        }
        persistor.pause()
        await persistor.purge()
        await client.resetStore()
        persistor.resume()
    }, [persistor])

    useEffect(() => {
        async function initClient() {
            let newPersistor = new CachePersistor({
                cache,
                storage: isWeb ? new LocalStorageWrapper(window.localStorage) : new AsyncStorageWrapper(AsyncStorage),
                debug: __DEV__ ? false : false,
                trigger: 'write',
                persistenceMapper,
                maxSize: 5242880,
            })

            await newPersistor?.restore()
            setPersistor(newPersistor)
            const apolloClient = new ApolloClient({ link: persistLink.concat(link), cache, connectToDevTools: true })
            setClient(apolloClient)
            initAugmentedHooks(apolloClient)
        }

        initClient()
    }, [])

    return {
        client,
        clearCache,
    }
}

export default useApolloClient
