import { ENVIRONMENT_VARIABLES, isWeb } from '@constants'
const { AGORA_APP_ID = '' } = ENVIRONMENT_VARIABLES

export const useAgoraClient = () => {
    const { createClient, createMicrophoneAndCameraTracks } = isWeb && require('agora-rtc-react')

    //The following configuration is required for live streaming
    const config = {
        mode: 'live',
        codec: 'vp8',
        appId: AGORA_APP_ID,
        token: null,
        role: 'audience',
    }

    const videoEncoderConfig = {
        encoderConfig: '1080p',
        facingMode: 'user',
        optimizationMode: 'motion',
    }

    const useClient = createClient(config)
    const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks(undefined, videoEncoderConfig)

    return {
        useClient,
        useMicrophoneAndCameraTracks,
    }
}

export default useAgoraClient
