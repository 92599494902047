import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { View, Image, FlatList, ScrollView, Dimensions, Text, TouchableOpacity, Linking } from 'react-native'
import { Inter500, Inter700, Inter900 } from 'typography'
import colors from 'colors'
import Client, { Product, Collection } from 'shopify-buy'
import { Ionicons } from '@expo/vector-icons'

import styled from 'styled-components/native'
import { useDimensions } from 'dimensions'
import { useMediaQuery } from 'react-responsive'

const PRODUCT_IMAGE_DIMENSIONS_WEB = 200
const PRODUCT_IMAGE_DIMENSIONS_TABLET = 175
const PRODUCT_IMAGE_DIMENSIONS_MOBILE = 150

const client = Client.buildClient({
    domain: 'homebody-fitness-app.myshopify.com',
    storefrontAccessToken: 'c61cd6feb258f83bb6bbcd00eafd60a5',
})

const Shop: React.FC = () => {
    const [products, setProducts] = useState<Product[]>([])

    useEffect(() => {
        async function fetchProducts() {
            try {
                const collections = (await client.collection.fetchAllWithProducts()) || []
                const { products } = collections.find(({ handle }) => handle == 'website') || []
                setProducts(products)
            } catch (error) {
                //todo
            }
        }
        fetchProducts()
    }, [])

    return (
        <Container>
            <TitleText>Shop our favorites</TitleText>
            <ProductCarusel productList={products} />
        </Container>
    )
}

const ProductCarusel = ({ productList }: { productList: Product[] }): JSX.Element => {
    const { isMobile, isTablet } = useDimensions()
    const caruselRef = useRef<FlatList>(null)

    const activeIndexRef = useRef<number>(0)

    const handleNavPress = (type: 'prev' | 'next') => {
        const firstItem = activeIndexRef.current
        if (type == 'prev' && !!firstItem) {
            const prevItem = firstItem - 2 > 0 ? firstItem - 2 : 0
            caruselRef.current?.scrollToIndex({
                index: prevItem,
                animated: true,
            })
        }

        if (type == 'next') {
            const nextItemIdx = firstItem + 2 < productList.length - 1 ? firstItem + 2 : productList.length - 1
            caruselRef.current?.scrollToIndex({
                index: nextItemIdx,
                animated: true,
            })
        }
    }

    const getItemLayout = (_: any, index: number) => {
        const itemWidth = isMobile || isTablet ? PRODUCT_IMAGE_DIMENSIONS_MOBILE : PRODUCT_IMAGE_DIMENSIONS_WEB
        return { length: itemWidth, offset: itemWidth * index, index }
    }

    const onViewableItemsChanged = useCallback(({ changed, viewableItems }) => {
        if (viewableItems.length > 0 && viewableItems[0]?.index) {
            activeIndexRef.current = viewableItems[0].index
        }
    }, [])

    const viewabilityConfig = useMemo(() => {
        return {
            viewAreaCoveragePercentThreshold: 0,
        }
    }, [])

    const Header = () =>
        isMobile ? null : (
            <ArrowIcon onPress={() => handleNavPress('prev')}>
                <Ionicons
                    name={'ios-arrow-forward'}
                    size={18}
                    style={{
                        alignSelf: 'center',
                        transform: [{ rotateY: '180deg' }],
                    }}
                />
            </ArrowIcon>
        )

    const Footer = () =>
        isMobile ? null : (
            <ArrowIcon onPress={() => handleNavPress('next')}>
                <Ionicons name={'ios-arrow-forward'} size={18} style={{ alignSelf: 'center' }} />
            </ArrowIcon>
        )

    const separatorWidth = useMediaQuery({ minWidth: 1050 }) ? 50 : 12

    return (
        <View
            style={{
                flex: 1,
                flexDirection: 'row',
                marginBottom: 10,
            }}
        >
            <Header />
            <FlatList
                style={{ flex: 1 }}
                contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
                horizontal
                showsHorizontalScrollIndicator={false}
                ref={caruselRef}
                data={productList}
                initialNumToRender={productList.length}
                renderItem={({ item }) => <ProductItem product={item} />}
                ItemSeparatorComponent={() => <View style={{ width: separatorWidth }} />}
                onViewableItemsChanged={onViewableItemsChanged}
                viewabilityConfig={viewabilityConfig}
                getItemLayout={getItemLayout}
            />
            <Footer />
        </View>
    )
}

const ProductItem = ({ product }: { product: Product }): JSX.Element => {
    return (
        <ProductContainer onPress={() => Linking.openURL(product.onlineStoreUrl)}>
            {!!product.images[0]?.src && <ProductImage source={{ uri: product.images[0].src }} resizeMode="cover" />}
            <ProductName>{product.title}</ProductName>
            <ProductPrice>${product.variants[0].price}</ProductPrice>
        </ProductContainer>
    )
}

const Container = styled(View)`
    background: ${colors.white};
    padding-top: 30px;
    padding-bottom: 30px;
    padding-horizontal: 5%;
`

const ProductContainer = styled(TouchableOpacity)`
    max-width: ${({ theme }) => (theme.isMobile ? PRODUCT_IMAGE_DIMENSIONS_MOBILE : PRODUCT_IMAGE_DIMENSIONS_WEB)}px;
`

const ProductImage = styled(Image)`
    width: ${({ theme }) => (theme.isMobile ? PRODUCT_IMAGE_DIMENSIONS_MOBILE : PRODUCT_IMAGE_DIMENSIONS_WEB)}px;
    height: ${({ theme }) => (theme.isMobile ? PRODUCT_IMAGE_DIMENSIONS_MOBILE : PRODUCT_IMAGE_DIMENSIONS_WEB)}px;
`

const ProductName = styled(Text)`
    font-size: ${({ theme }) => (theme.isMobile ? 14 : 22)}px;
    font-family: ${({ theme }) => (theme.isMobile ? 'Inter_500Medium' : 'Inter_700Bold')};
    margin-top: 10px;
`

const ProductPrice = styled(Inter900)`
    font-size: ${props => (props.theme.isMobile ? '10px' : '16px')};
    line-height: ${props => (props.theme.isMobile ? '18px' : '28px')};
    color: ${props => (props.theme.isMobile ? colors.black : colors.subTextGray)};
    color: ${colors.subTextGray};
`

const TitleText = styled(Inter900)`
    font-size: ${props => (props.theme.isMobile ? '30px' : '38px')};
    line-height: 72px;
    color: ${colors.black};
    margin-bottom: 30px;
`

const ArrowIcon = styled(TouchableOpacity)`
    height: 30px;
    width: 30px;
    margin-horizontal: 25px;
    border-radius: 20px;
    background-color: white;
    shadow-color: #000000;
    shadow-offset: 0px 2.65px;
    shadow-radius: 2.65px;
    shadow-opacity: 0.25;
    justify-content: center;
    align-self: center;
`

export default Shop
