import React from 'react'
import { View, Image, TouchableOpacity } from 'react-native'
import { useDimensions } from 'dimensions'
import GradientBar from 'components/GradientBar'
import VectorIcon from 'components/VectorIcon'
import { Inter900, Inter700, Inter500, TEXT_LINEAR_GRADIENT } from 'typography'
import styled from 'styled-components/native'
import * as screenNames from 'screenNames'
import { useNavigation } from '@react-navigation/native'
import colors from 'colors'

const Transforming: React.FC = () => {
    const { isMobile } = useDimensions()
    const navigation = useNavigation()
    const goToSignUp = () => navigation.navigate(screenNames.SIGN_UP)

    return (
        <>
            <Container>
                {!isMobile && (
                    <Cell>
                        <Image
                            source={require('assets/phones2.png')}
                            style={{ flex: 1, height: 348 }}
                            resizeMode="contain"
                        />
                    </Cell>
                )}
                <Cell style={{ maxWidth: 650 }}>
                    <TitleText>
                        Live classes with your favorite athletes, celebrities, influencers and creators
                    </TitleText>
                    <SubTitleText>Access to world class creators anytime, anywhere.</SubTitleText>
                    <DescriptionText>
                        Homebody is connecting the world through a shared passion for health, fitness and wellness by
                        making workouts with your favorite creators accessible no matter your fitness goals. Fuel your
                        body like never before.
                    </DescriptionText>
                    <DescriptionText>
                        Join a community, transform your mind, welcome change and let’s look good but feel better
                        together.
                    </DescriptionText>
                    <SignUpButton onPress={goToSignUp}>
                        <SignUpText>sign up now</SignUpText>
                        <VectorIcon
                            family="Ionicons"
                            name="ios-arrow-forward"
                            size={22}
                            color={isMobile ? 'black' : colors.homebodyGreen}
                            style={{ paddingLeft: 10 }}
                        />
                    </SignUpButton>
                </Cell>
            </Container>
        </>
    )
}

const Container = styled(View)`
    flex-direction: ${props => (props.theme.isMobile ? 'column' : 'row')};
    padding-top: ${props => (props.theme.isMobile ? '61px' : '134px')};
    padding-bottom: ${props => (props.theme.isMobile ? '61px' : '147px')};
    padding-left: 5%;
    padding-right: 5%;
    background-color: ${colors.upNextStreamGray};
`

const Cell = styled(View)`
    width: ${props => (props.theme.isMobile ? '100%' : '50%')};
`

const TitleText = styled(Inter900)`
    font-size: 38px;
    line-height: 45px;
    color: #111111;
`

const SubTitleText = styled(Inter700)`
    font-size: 16px;
    line-height: 23px;
    color: black;
    margin-top: 19px;
`

const DescriptionText = styled(Inter500)`
    font-size: 16px;
    line-height: 22px;
    margin-top: 19px;
    color: ${colors.subTextGray};
`

const SignUpButton = styled(TouchableOpacity)`
    margin-top: 40px;
    flex-direction: row;
    align-items: center;
`

const SignUpText = styled(Inter900)`
    ${({ theme }) =>
        !theme.isMobile &&
        TEXT_LINEAR_GRADIENT({
            direction: '180deg',
            colorStop1: `${colors.homebodyTurquoise} 6.47%`,
            colorStop2: `${colors.homebodyGreen} 100%`,
        })};
    font-size: 20px;
    line-height: 26px;
`

export default Transforming
