import React, { useState, useEffect } from 'react'
import { View, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import colors from 'colors'
import { useDimensions } from 'dimensions'
import { Inter500, Inter600 } from 'typography'
import { get } from 'lodash'
import { Hoverable } from 'react-native-web-hover'
import { AntDesign } from '@expo/vector-icons'
import { iconHitSlop } from '@constants'
import { roundToNearestFive } from 'helpers'
const noop = () => {}
const StyledCounter = styled(View)`
    height: 61.52px;
    width: 235px;
    flex-direction: row;
    justify-content: space-between;
    padding-horizontal: 15px;
    align-items: center;
    padding-left: 10px;
    border-width: 1px;
    border-color: ${props => (props.hasError ? 'red' : props.hovered ? colors.blueContinue : colors.inputFieldGray)}
    background-color: ${colors.inputFieldGray};
    border-radius: 8.79px;
`

export const CounterInput = ({
    fieldName,
    nestedFieldName = '',
    fieldTitle,
    errors = [],
    counterInterval = 1,
    defaultValue = 1,
    maxValue,
    minValue = 1,
    updateForm,
    largeTitle = true,
    localValue = false,
    onChange,
    handleBlur = noop,
}) => {
    const [counter, setCounter] = useState(defaultValue)
    const increment = () => (counter + counterInterval > maxValue ? noop : setCounter(counter + counterInterval))
    const decrement = () => (counter - counterInterval < minValue ? noop : setCounter(counter - counterInterval))
    const error = nestedFieldName ? get(errors, `${fieldName}.${nestedFieldName}`) : errors[fieldName]

    const updateAndValidate = () => {
        updateForm({ fieldName, value: counter })
        error ? handleBlur({ fieldName, nestedFieldName }) : noop
    }

    useEffect(() => {
        if (counterInterval == 5) {
            setCounter(roundToNearestFive(counter))
        }
    }, [counterInterval])

    useEffect(() => {
        localValue ? onChange(counter) : updateAndValidate()
    }, [counter])

    return (
        <CounterContainer localValue={localValue}>
            <InputFieldTitleText largeTitle={largeTitle} error={error}>
                {error ? error : fieldTitle}
            </InputFieldTitleText>
            <Hoverable>
                {({ hovered }) => (
                    <StyledCounter hasError={error} hovered={hovered}>
                        <CounterText localValue={localValue}>{counter}</CounterText>
                        <ArrowContainer>
                            <TouchableOpacity onPress={increment} hitSlop={iconHitSlop}>
                                <AntDesign name="up" size={24} color="black" />
                            </TouchableOpacity>
                            <TouchableOpacity onPress={decrement} hitSlop={iconHitSlop}>
                                <AntDesign name="down" size={24} color="black" />
                            </TouchableOpacity>
                        </ArrowContainer>
                    </StyledCounter>
                )}
            </Hoverable>
        </CounterContainer>
    )
}

const ArrowContainer = styled(View)``
const CounterText = styled(Inter600)`
    padding-left: ${props => (props.localValue ? '47.5%' : '10px')};
    font-size: 15.38px;
    line-height: 22px;
`
export const InputFieldTitleText = styled(Inter500)`
    line-height: 14px;
    color: ${props => (props.error ? 'red' : 'black')};
    font-weight: ${props => (props.largeTitle ? 'bold' : 'normal')};
    padding-top: ${props => (props.largeTitle ? '20px' : '0px')};
    padding-bottom: ${props => (props.largeTitle ? '20px' : '10px')};
    font-size: ${props => (props.largeTitle ? '22px' : '14.1794px')};
`
const CounterContainer = styled(View)`
    flex: 1;
    flex-direction: column;
    align-self: flex-start;
`
export default CounterInput
