import { useState, useEffect, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
const emptyObj = {}
const noop = () => {}

const useCustomForm = ({
    initialValues,
    fieldNames,
    fieldErrors,
    validationFunc,
    onSubmit,
    fieldQueryValidation = emptyObj,
}) => {
    const [values, setValues] = useState(initialValues || {})
    const [errors, setErrors] = useState(fieldErrors || {})
    const [populateErrors, setPopulateErrors] = useState(false)
    const [touched, setTouched] = useState({})
    const [onSubmitting, setOnSubmitting] = useState(false)
    const [onBlur, setOnBlur] = useState(false)
    const [queryError, setQueryError] = useState(false)
    const onChangeRef = useRef({})
    const { query, fieldName, evaluateQueryResponse = noop, queryErrorObj, getFilter = noop } = fieldQueryValidation
    const [fieldQuery, { loading, error, data, called }] = useLazyQuery(query, {
        variables: {
            filter: getFilter(values),
        },
    })
    const validateFieldQuery = () => {
        const isValid = evaluateQueryResponse(data)
        if (!isValid) {
            setErrors({ ...errors, [fieldName]: queryErrorObj })
            return setQueryError(true)
        }

        setQueryError(false)
    }
    useEffect(validateFieldQuery, [loading])

    const formRendered = useRef(true)

    useEffect(() => {
        if (formRendered.current) {
            setValues(initialValues)
            setErrors({})
            setTouched({})
            setOnSubmitting(false)
            setOnBlur()
        }
        formRendered.current = false
    }, [initialValues])

    const onChangeText = ({ fieldName, shouldSanitizeInput = false, sanitizeRegex }) => {
        if (!onChangeRef[fieldName]) {
            onChangeRef.current[fieldName] = text =>
                setValues({ ...values, [fieldName]: shouldSanitizeInput ? text?.replace(sanitizeRegex, '') : text })
        }
        return onChangeRef.current[fieldName]
    }

    const handleBlur = async ({ fieldName }) => {
        if (fieldName === fieldQueryValidation.fieldName) {
            fieldQuery()
            if (called) return validateFieldQuery()
        }
    }

    const handleSubmit = () => {
        onSubmit({ values, errors })
    }

    const getFieldError = ({ fieldName, nestedFieldName = '' }) => {
        const isQueryField = fieldName == fieldQueryValidation.fieldName
        if (isQueryField && queryError) return queryErrorObj
        const valid = validationFunc({ values })
        const isNested = nestedFieldName != ''
        const formattedFieldName = isNested ? `${fieldName}.${nestedFieldName}` : fieldName
        const error = isNested ? _.get(fieldErrors, formattedFieldName) : fieldErrors[formattedFieldName]
        const fieldValid = isNested ? _.get(valid, formattedFieldName) : valid[formattedFieldName]
        return !fieldValid && error ? { showError: true, error } : { showError: false }
    }

    function handleInvalidPage() {
        setPopulateErrors(true)
        const newErrors = {}
        //Get the current values belonging to components requiring validation on the current page
        Object.entries(validationFunc({ values })).map(([key, value]) => {
            if (typeof value === 'object') {
                //Validation for component with nested keys
                return Object.keys(value)
                    .filter(nestedKey => nestedKey != '__typename') //remove __typename key generated by graphql
                    .map(nestedKey => {
                        if (!newErrors[key]) newErrors[key] = {} //initialize nested key which will contain nested error
                        const fieldError = getFieldError({ fieldName: key, nestedFieldName: nestedKey })
                        newErrors[key] = {
                            ...newErrors[key],
                            [nestedKey]: fieldError,
                        }
                    })
            } else {
                //validation for component w/o nested keys
                newErrors[key] = getFieldError({ fieldName: key })
            }
        })
        setErrors(queryError ? { ...errors, ...newErrors } : newErrors)
    }

    const formIsFilled = Object.values(values).every(val => !!val)
    const isValid = Object.values(validationFunc({ values })).every(val => !!val) && !queryError

    return {
        values,
        errors,
        touched,
        isValid,
        formIsFilled,
        populateErrors,
        getFieldError,
        handleInvalidPage,
        onChangeText,
        handleBlur,
        handleSubmit,
    }
}

export default useCustomForm
