import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import colors from 'colors'
import styled from 'styled-components/native'
import { ScreenContainer, ContentContainer } from 'commons/containers'
import { FooterBar } from 'commons/components/FooterBar'
import { useDimensions } from 'dimensions'
import { ContinueButton } from 'components/ContinueButton'
import CustomImage from 'components/CustomImage'
import AllowNotificationModal from 'commons/components/instructor/AllowNotificationModal'
import { getCurrentUser } from 'apollo/selectors'
import { cache } from 'apollo/cache'
import { useRoute, useNavigation } from '@react-navigation/native'
import ClassOverview from 'components/ClassOverview'
import * as screenNames from 'screenNames'
import { stripeAPI } from 'src/actions'
import { STRIPE_API_ROUTES, APOLLO_FETCH_POLICIES, CLASS_STATUS } from '@constants'
import { useApolloClient } from '@apollo/client'
import * as queries from 'apollo/queries'
const { WITHDRAW_FROM_CLASS } = STRIPE_API_ROUTES
const { NO_CACHE } = APOLLO_FETCH_POLICIES

const emptyObj = {}
const emptyArr = []

export const ClassDetails = () => {
    const client = useApolloClient()
    const navigation = useNavigation()
    const {
        params: { Class: classFromRoute, classID, hasBookedClass: bookedClassFromRoute },
    } = useRoute() || {}
    const { isMobile } = useDimensions()
    const { id: userID, classBookings: { items: existingBookings } = emptyArr } = getCurrentUser({
        fetchPolicy: NO_CACHE,
    })
    const [Class, setClass] = useState(classFromRoute)
    const [classBooking, setClassBooking] = useState(emptyObj)
    const [actionLoading, setActionLoading] = useState(false)
    const [hasBookedClass, setHasBookedClass] = useState(false)
    const [notificationModalVisible, setNotificationModalVisible] = useState(false)
    const [deleteBookingStatus, setDeleteBookingStatus] = useState({ actionSuccess: false, statusMessage: '' })

    useEffect(() => {
        const getClass = async () => {
            const {
                data: { getClass: classData },
            } = await client.query({
                query: queries.GET_CLASS,
                variables: {
                    id: `${classID}`,
                },
            })

            setClass({ ...classData })
        }
        classID && getClass()
    }, [classID])

    const { price, banner, defaultBannerIndex, classStatus } = Class || {}

    function handleBooking() {
        if (!userID) {
            navigation.navigate(screenNames.SIGN_UP)
            return
        } else {
            navigation.navigate(screenNames.BOOK_CLASS, { classID: classID })
        }
    }

    function updateDeleteClassBookingStatus(actionSuccess) {
        const statusMessage = actionSuccess
            ? 'You have successfully withdrawn from the class.'
            : 'Error withdrawing from class'
        setTimeout(() => {
            setActionLoading(false)
            if (actionSuccess) {
                const normalizedID = cache.identify({
                    id: classBooking?.id,
                    userID: classBooking?.userID,
                    classID: classBooking?.classID,
                    __typename: 'ClassBooking',
                })
                cache.evict({ id: normalizedID })
                cache.gc()
                setClassBooking(emptyObj)
                setHasBookedClass(false)
            }
            setDeleteBookingStatus({ actionSuccess, statusMessage })
        }, 1000)
    }
    async function handleWithdraw() {
        setActionLoading(true)
        const value = await stripeAPI({
            path: WITHDRAW_FROM_CLASS,
            body: { classID, userID, classBookingID: classBooking?.id },
        })
        updateDeleteClassBookingStatus(value.code ? false : true)
        const normalizedID = cache.identify({
            id: userID,
            __typename: 'User',
        })
        cache.modify({
            id: normalizedID,
            fields: {
                credits(existing) {
                    if (price && !value.code) {
                        return parseInt(existing) + parseInt(price)
                    }
                },
            },
        })
    }

    useEffect(() => {
        const existingBooking = existingBookings?.filter(
            booking => booking?.classID == classID && !booking?._deleted,
        )?.[0]
        setClassBooking(existingBooking)
        setHasBookedClass(!!existingBooking?.id)
    }, [existingBookings, classID])

    const shouldRenderBookingButton = classStatus === CLASS_STATUS.PUBLISHED
    const imageStyle = { height: isMobile ? 150 : 339, width: '100%' }
    return (
        <ScreenContainer>
            <BannerContainer>
                <CustomImage resizeMode="cover" imgKey={banner?.key} style={imageStyle} />
            </BannerContainer>
            <ContentContainer style={{ width: '100%' }}>
                <ClassOverview Class={Class} navigation={navigation} />
                {shouldRenderBookingButton && (
                    <BookButtonContainer>
                        <ContinueButton
                            nonHover
                            style={{ width: 150, alignSelf: 'flex-end' }}
                            color={hasBookedClass ? 'red' : colors.homebodyTurquoise}
                            apolloActionStatus={deleteBookingStatus?.statusMessage && deleteBookingStatus}
                            actionLoading={actionLoading}
                            text={hasBookedClass ? 'Withdraw' : 'Book'}
                            onPress={hasBookedClass ? handleWithdraw : handleBooking}
                        />
                    </BookButtonContainer>
                )}
            </ContentContainer>
            {notificationModalVisible && isMobile && (
                <AllowNotificationModal visible={notificationModalVisible} toggleVisible={toggleNotificationModal} />
            )}
            <FooterBar />
        </ScreenContainer>
    )
}
export default ClassDetails

const BookButtonContainer = styled(View)`
    margin-vertical: 5%;
    align-self: center;
    ${props => !props.isMobile && 'margin-left: auto; margin-right: 100px;'}
`
const BannerContainer = styled(View)`
    width: 100%;
    justify-content: center;
    align-items: center;
`
