import React, { useState, useEffect } from 'react'
import { Text, View, ActivityIndicator, Image } from 'react-native'
import colors from 'colors'
import styled from 'styled-components/native'
export const SplashScreen = () => {
    return (
        <Container>
            <ActivityIndicator size="large" animating={true} color={colors.homebodyTurquoise} />
        </Container>
    )
}

const Container = styled(View)`
    flex: 1;
    justify-content: center;
    align-items: center;
`

export default SplashScreen
