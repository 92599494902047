import React, { useEffect, useMemo, useState } from 'react'
import { useNavigation, useIsFocused } from '@react-navigation/core'
import { ScreenContainer, ContentContainer } from 'commons/containers'
import { USER_PROFILE, EXPLORE_NAVIGATOR, EXPLORE } from 'screenNames'
import styled from 'styled-components/native'
import InstructorFlatList from 'components/trainee/InstructorFlatList'
import { getCurrentUser, getUsersByFavoriteInstructors } from 'apollo/selectors'
import { TraineeWebBannerHeader } from 'commons/components/TraineeWebBannerHeader'
import { useDimensions } from 'dimensions'
import { FooterBar } from 'commons/components/FooterBar'
const STATIC_BANNER_HAS_FAVORITES = require('assets/favorites-banner.png')
const emptyArr = []
const emptyObj = {}

export default function FavoritesScreen() {
    const isFocused = useIsFocused()
    const navigation = useNavigation()
    const { favorites: userFavorites } = getCurrentUser()
    const { isMobile, isTablet } = useDimensions()
    const [refreshing, setRefreshing] = useState(false)
    const [favorites, setFavorites] = useState(emptyArr)
    const [favoritesToken, setFavoritesToken] = useState(null)
    const {
        favoriteInstructors: favoritesResult,
        nextToken: initialFavoritesToken,
        fetchMore: fetchMoreFavorites,
        loading: instructorsLoading,
        refetch: refetchFavoriteInstructors,
    } = getUsersByFavoriteInstructors({
        favorites: userFavorites,
        onCompleted: () => {
            if (favorites?.length === 0 && !favoritesToken) {
                setFavoritesToken(initialFavoritesToken)
                setFavorites(favoritesResult)
            }
        },
    })

    async function handleFetchMoreFavorites() {
        if (favoritesToken) {
            const fetchMoreResult = await fetchMoreFavorites({ variables: { nextToken: favoritesToken } })
            const {
                data: {
                    listUsers: { items: moreFavoriteInstructors = emptyArr, nextToken: token = null },
                },
            } = fetchMoreResult ?? emptyObj
            const allFavorites =
                favorites?.length > 0 ? [...favorites, ...moreFavoriteInstructors] : [...moreFavoriteInstructors]
            setFavorites(allFavorites)
            setFavoritesToken(token)
        }
    }

    const goToUserProfile = instructor => navigation.navigate(USER_PROFILE, { user: instructor, id: instructor?.id })
    const goToExploreInstructors = () =>
        navigation.navigate(EXPLORE_NAVIGATOR, { screen: EXPLORE, params: { byInstructor: true } })

    function handlePullToRefresh() {
        setRefreshing(true)
        refetchFavoriteInstructors && refetchFavoriteInstructors()
        setTimeout(() => {
            setRefreshing(false)
        }, 1000)
    }

    const bannerHeaderImage = useMemo(() => {
        const noFavoriteInstructors = favorites?.length == 0 && !instructorsLoading
        return noFavoriteInstructors ? '' : STATIC_BANNER_HAS_FAVORITES
    }, [favorites, instructorsLoading])

    useEffect(() => {
        if (favoritesToken !== null) {
            handleFetchMoreFavorites()
        }
    }, [favoritesToken])

    useEffect(() => {
        if (refetchFavoriteInstructors) {
            refetchFavoriteInstructors()
        }
    }, [isFocused])

    useEffect(() => {
        setFavorites(emptyArr)
        setFavoritesToken(null)
        refetchFavoriteInstructors && refetchFavoriteInstructors()
    }, [userFavorites])

    return (
        <ScreenContainer>
            <ContentContainer
                style={{ width: '100%', padding: 0, alignItems: 'center' }}
                refreshing={refreshing}
                onPullToRefresh={handlePullToRefresh}
            >
                {!isMobile && !isTablet && <TraineeWebBannerHeader title="Favorites" image={bannerHeaderImage} />}

                <FavoriteInstructorsList
                    hidePicker
                    shouldHideNoInstructorsText
                    data={favorites}
                    instructorsLoading={false}
                    onCardPress={goToUserProfile}
                    onHeadingPress={goToExploreInstructors}
                    headingText={bannerHeaderImage && !isMobile ? 'My Favorite Instructors' : ''}
                />
            </ContentContainer>
            {!isMobile && !isTablet && <FooterBar />}
        </ScreenContainer>
    )
}

const FavoriteInstructorsList = styled(InstructorFlatList)`
    width: 100%;
    padding-top: 5%;
    ${({ theme }) => !theme.isMobile && !theme.isTablet && 'padding-horizontal: 10%'};
`
