import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { View } from 'react-native'
import { ScreenContainer, ContentContainer } from 'commons/containers'
import { TraineeWebBannerHeader } from 'commons/components/TraineeWebBannerHeader'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'typography'
import { TRAINEE_SETTINGS } from 'screenNames'
import { getCurrentUser } from 'apollo/selectors'
import { stripeAPI } from 'src/actions'
import colors from 'colors'
import styled from 'styled-components/native'
import VectorIcon from 'components/VectorIcon'
import { STRIPE_API_ROUTES } from '@constants'
const { LIST_SUBSCRIPTIONS, CANCEL_SUBSCRIPTION } = STRIPE_API_ROUTES

const cancelSubscriptionText = 'Are you sure you want to cancel your subscription? This can not be undone.'

export default function CancelSubscription() {
    const navigation = useNavigation()
    const { customerID } = getCurrentUser()
    const [actionLoading, setActionLoading] = useState(false)
    const [cancelSubscriptionStatus, setCancelSubscriptionStatus] = useState({
        actionSuccess: false,
        statusMessage: '',
    })
    const goToSettings = () => navigation.navigate(TRAINEE_SETTINGS)

    async function handleCancelSubscription() {
        setActionLoading(true)
        try {
            const subscriptions = await stripeAPI({ path: LIST_SUBSCRIPTIONS, pathParameters: customerID })
            if (subscriptions?.length > 0) {
                const subscriptionToCancel = subscriptions?.[0]
                const { id: subscriptionID, cancel_at_period_end: isSubscriptionAlreadyCancelled } =
                    subscriptionToCancel
                if (isSubscriptionAlreadyCancelled) {
                    setCancelSubscriptionStatus({
                        actionSuccess: true,
                        statusMessage: 'Your subscription is already cancelled.',
                    })
                } else {
                    const { cancel_at_period_end: isSubscriptionCancelled } = await stripeAPI({
                        path: CANCEL_SUBSCRIPTION,
                        body: { subscriptionID },
                    })
                    if (isSubscriptionCancelled) {
                        setCancelSubscriptionStatus({
                            actionSuccess: true,
                            statusMessage: 'Subscription cancelled successfully',
                        })
                    } else {
                        setCancelSubscriptionStatus({
                            actionSuccess: false,
                            statusMessage: 'Error cancelling subscription. Please contact support.',
                        })
                    }
                }
            } else {
                setCancelSubscriptionStatus({
                    actionSuccess: true,
                    statusMessage: 'No subscription found',
                })
            }
        } catch (error) {
            __DEV__ && console.log('error cancelling subscription: ', error)
            setCancelSubscriptionStatus({
                actionSuccess: false,
                statusMessage: 'Error cancelling subscription. Please contact support.',
            })
        }
        setActionLoading(false)
    }
    return (
        <ScreenContainer>
            <TraineeWebBannerHeader title="My Settings" />
            <ContentContainer isFluid style={{ alignItems: 'center' }}>
                <TextAndButtonsContainer>
                    <VectorIcon family="MaterialCommunityIcons" name="emoticon-sad-outline" size={60} color="black" />
                    <Heading isMostBold fontSize={34} textAlign="center" paddingTop={35}>
                        {cancelSubscriptionText}
                    </Heading>
                    <ButtonContainer>
                        <ContinueButton
                            text="YES Cancel"
                            actionLoading={actionLoading}
                            apolloActionStatus={cancelSubscriptionStatus}
                            onPress={handleCancelSubscription}
                            color={colors.grayContinue}
                        />
                        <ContinueButton text="Keep Subscription" onPress={goToSettings} style={{ marginTop: 35 }} />
                    </ButtonContainer>
                </TextAndButtonsContainer>
            </ContentContainer>
        </ScreenContainer>
    )
}

const TextAndButtonsContainer = styled(View)`
    padding-top: 5%;
    max-width: 525px;
    width: 30%;
    align-self: center;
`
const ButtonContainer = styled(View)`
    align-self: center;
    width: 100%;
    padding-top: 70px;
`
