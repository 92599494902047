import React from 'react'
import { FillContainer } from 'commons/containers'
import ContactSupport from './ContactSupport'
import { FooterBar } from 'components/FooterBar'

export default function Support() {
    return (
        <FillContainer contentContainerStyle={{ flex: 1, justifyContent: 'center' }}>
            <ContactSupport />
            <FooterBar />
        </FillContainer>
    )
}
