import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { Inter500, Inter900, SubHeading, ButtonText } from 'typography'
import { HeaderText, SubHeaderText } from 'typography'
import CustomImage from 'components/CustomImage'
import { ContinueButton } from 'commons/components/ContinueButton'
import { RowContainer, FormBottomRowToColumnContainer, ScreenContainer, BannerContainer } from 'commons/containers'
import colors from 'colors'
import styled from 'styled-components/native'
import { getClass, getCurrentUser } from 'apollo/selectors'
import { useApolloClient } from '@apollo/client'
import * as queries from 'apollo/queries'
import { cache } from 'apollo/cache'
import { isNative, isWeb } from '@constants'
import { FooterBar } from 'commons/components/FooterBar'
import { formatDate, toStandardTime, copyClassLinkToClipboard } from 'helpers'
import { useDimensions } from 'dimensions'
import { useNavigation, useRoute } from '@react-navigation/core'
import { StackActions } from '@react-navigation/routers'
import {
    CREDIT_PACKAGES,
    MY_CREDITS,
    PAYMENT_NAVIGATOR,
    PROFILE_NAVIGATOR,
    TRAINEE_HOME_STACK_NAVIGATOR,
    TRAINEE_HOME_SCREEN,
    TRAINEE_UPCOMING_CLASSES,
} from 'screenNames'
import useNotifications from 'hooks/useNotifications'
import { stripeAPI } from 'src/actions'
import { STRIPE_API_ROUTES } from '@constants'
const { PURCHASE_CLASS } = STRIPE_API_ROUTES
const emptyArr = []
const emptyObj = {}

const PaymentDetail = ({ name, detail, isHeader = false }) => {
    return (
        <PaymentDetailContainer>
            {isHeader ? (
                <>
                    <HeaderText>{name}</HeaderText>
                    <HeaderText>{detail}</HeaderText>
                </>
            ) : (
                <>
                    <SubHeaderText color={colors.homebodyTurquoise}>{name}</SubHeaderText>
                    <SubHeaderText>{detail}</SubHeaderText>
                </>
            )}
        </PaymentDetailContainer>
    )
}

export default function BookClass() {
    const client = useApolloClient()
    const navigation = useNavigation()
    const { params } = useRoute() || emptyObj
    const { classID } = params || emptyObj
    const { isMobile } = useDimensions()
    const [shareClassText, setShareClassText] = useState('')
    const { id: userID, pushToken, classBookings: { items: existingBookings } = emptyArr, credits } = getCurrentUser()
    const [actionLoading, setActionLoading] = useState(false)
    const [createBookingStatus, setCreateBookingStatus] = useState({ actionSuccess: false, statusMessage: '' })
    const [bookClassSuccess, setBookClassSuccess] = useState(false)
    const [classFromID, setClassFromID] = useState({})
    useEffect(() => {
        const getClass = async () => {
            const { data: { getClass: classData = emptyObj } = emptyObj } = await client.query({
                query: queries.GET_CLASS,
                variables: {
                    id: `${classID}`,
                },
            })

            setClassFromID({ ...classData })
        }
        classID && getClass()
    }, [classID])

    const {
        name,
        price,
        banner,
        date: beforeDateFormat,
        startTime: beforeTimeFormat,
        userID: instructorID,
    } = classFromID || emptyObj
    const date = formatDate(beforeDateFormat)
    const startTime = toStandardTime(beforeTimeFormat)
    const dateTime = `${date} ${startTime}`
    const {
        notificationTypes: { ENROLLED_IN_CLASS },
        handleCreateNotification,
    } = useNotifications()

    function updateCreateBookingStatus(actionSuccess) {
        const statusMessage = actionSuccess ? 'You have successfully booked the class.' : 'Error booking class'
        setTimeout(() => {
            setActionLoading(false)
            setCreateBookingStatus({ actionSuccess, statusMessage })
            if (actionSuccess) {
                setBookClassSuccess(true)
                const normalizedID = cache.identify({
                    id: userID,
                    __typename: 'User',
                })
                cache.modify({
                    id: normalizedID,
                    fields: {
                        credits(existing) {
                            if (price && bookClassSuccess) {
                                return parseInt(existing) - parseInt(price)
                            }
                        },
                    },
                })
            } else {
                setBookClassSuccess(false)
            }
        }, 1000)
    }

    async function handleBooking() {
        if (credits < price) {
            navigation.navigate(CREDIT_PACKAGES, { credits, hasSufficientCredits: false, classID })
        } else if (userID == instructorID) {
            setCreateBookingStatus({ actionSuccess: false, statusMessage: 'You cannot book your own class.' })
        } else {
            setActionLoading(true)
            const value = await stripeAPI({ path: PURCHASE_CLASS, body: { classID, userID } })
            updateCreateBookingStatus(value.code ? false : true)
            const content = {
                title: 'Your Class has been Booked!',
                body: `You just booked ${name}.`,
                data: JSON.stringify({ classID: `${classID}` }),
            }
            await handleCreateNotification({ userID, pushToken, content, type: ENROLLED_IN_CLASS })
        }
    }

    const goToCredits = () => navigation.navigate(MY_CREDITS, { isProfile: true })
    const goToUpcoming = () =>
        isMobile
            ? navigation.navigate(PROFILE_NAVIGATOR, { screen: TRAINEE_UPCOMING_CLASSES, initial: false })
            : navigation.navigate(TRAINEE_HOME_STACK_NAVIGATOR, { screen: TRAINEE_HOME_SCREEN })

    useEffect(() => {
        const existingBooking = existingBookings?.filter(
            booking => booking?.classID == classID && !booking?._deleted,
        )?.[0]
        setBookClassSuccess(!!existingBooking?.id)
    }, [existingBookings, classID])
    useEffect(() => {
        const resetStack = navigation?.getParent()?.addListener('blur', () => {
            navigation.dispatch(StackActions?.popToTop())
        })
        return resetStack
    }, [navigation])

    const handleShareClass = () => {
        setShareClassText('Class link copied to clipboard')
        copyClassLinkToClipboard({ id: classID })
        setTimeout(() => {
            setShareClassText('')
        }, 2500)
    }

    const imageStyle = { height: isMobile ? 150 : 250, width: '100%' }
    return (
        <ScreenContainer>
            <BannerContainer>
                <CustomImage resizeMode="cover" imgKey={banner?.key} style={imageStyle} />
            </BannerContainer>
            <BookClassContainer>
                {bookClassSuccess ? (
                    isMobile ? (
                        <SuccessContainer>
                            <InfoContainer alignItems="center">
                                <TitleText>Your class has been successfully booked!</TitleText>
                                <InfoText>You can access your classes under Upcoming Classes</InfoText>
                                <SubHeading isMostBold color={colors.homebodyTurquoise} paddingTop={25}>
                                    {name}
                                </SubHeading>
                                <SubHeading isMostBold color={colors.homebodyTurquoise}>
                                    {dateTime}
                                </SubHeading>
                            </InfoContainer>
                            <ButtonContainer>
                                <FormBottomRowToColumnContainer isCentered={!isNative}>
                                    <ShareClassText color={colors.homebodyTurquoise}>{shareClassText}</ShareClassText>
                                    <StyledContinueButton
                                        nonHover
                                        color={colors.homebodyTurquoise}
                                        text={'Share Class with Friends'}
                                        onPress={handleShareClass}
                                    />
                                </FormBottomRowToColumnContainer>
                                <FormBottomRowToColumnContainer isCentered={!isNative}>
                                    <StyledContinueButton
                                        nonHover
                                        color={colors.grayContinue}
                                        text={'View Upcoming Classes'}
                                        onPress={goToUpcoming}
                                    />
                                </FormBottomRowToColumnContainer>
                                <FormBottomRowToColumnContainer isCentered={!isNative}>
                                    <StyledContinueButton
                                        nonHover
                                        color={colors.grayContinue}
                                        text={'Check Credit Balance'}
                                        onPress={goToCredits}
                                    />
                                </FormBottomRowToColumnContainer>
                            </ButtonContainer>
                        </SuccessContainer>
                    ) : (
                        <SuccessContainer>
                            <InfoContainer alignItems="flex-start">
                                <HeaderText>Your class has been sucessfully booked!</HeaderText>
                                <SubHeaderText isMostBold paddingTop={27}>
                                    You can access your classes under Upcoming Classes
                                </SubHeaderText>
                                <SubHeaderText
                                    isMostBold
                                    isLargeSubheader
                                    color={colors.homebodyTurquoise}
                                    paddingTop={25}
                                >
                                    {name}
                                </SubHeaderText>
                                <SubHeaderText isMostBold isLargeSubheader color={colors.homebodyTurquoise}>
                                    {dateTime}
                                </SubHeaderText>
                            </InfoContainer>
                            <ButtonContainer>
                                <InfoContainer alignItems={'flex-start'}>
                                    <RowContainer>
                                        <StyledContinueButton
                                            nonHover
                                            color={colors.homebodyTurquoise}
                                            disabled
                                            text={'Share Class with Friends'}
                                            onPress={() => {}}
                                            style={{ marginRight: 40 }}
                                        />
                                        <StyledContinueButton
                                            nonHover
                                            color={colors.grayContinue}
                                            text={'View Upcoming Classes'}
                                            onPress={goToUpcoming}
                                        />
                                    </RowContainer>

                                    <StyledContinueButton
                                        nonHover
                                        color={colors.grayContinue}
                                        text={'Check Credit Balance'}
                                        onPress={goToCredits}
                                    />
                                </InfoContainer>
                            </ButtonContainer>
                        </SuccessContainer>
                    )
                ) : isMobile ? (
                    <>
                        <InfoContainer flex={1}>
                            <HeaderText paddingTop={20}>You're About to Book</HeaderText>
                            <SubHeaderText isMostBold paddingTop={20}>
                                {name}
                            </SubHeaderText>
                            <SubHeaderText isMostBold paddingTop={10} paddingBottom={10}>
                                {dateTime}
                            </SubHeaderText>
                            <PaymentDetail name="Available Credits" detail={credits ?? 0} />
                            <HeaderText paddingTop={5}>You Pay</HeaderText>
                            <PaymentDetail name="Credits" detail={`${price} credits`} />
                            <BottomBorderView />
                            <PaymentDetail isHeader name="Total" detail={`${price} credits`} />
                        </InfoContainer>
                        <FormBottomRowToColumnContainer
                            style={{
                                justifyContent: 'center',
                                marginVertical: 15,
                            }}
                        >
                            <StyledContinueButton
                                nonHover
                                color={colors.homebodyTurquoise}
                                apolloActionStatus={createBookingStatus}
                                actionLoading={actionLoading}
                                text={'Book'}
                                onPress={handleBooking}
                            />
                        </FormBottomRowToColumnContainer>
                    </>
                ) : (
                    <WebBookContainer>
                        <InfoContainer flex={1}>
                            <HeaderText>You're About to Book</HeaderText>
                            <SubHeaderText isMostBold paddingTop={27}>
                                {name}
                            </SubHeaderText>
                            <SubHeaderText isMostBold paddingTop={27} paddingBottom={10}>
                                {dateTime}
                            </SubHeaderText>
                        </InfoContainer>
                        <InfoContainer flex={1}>
                            <HeaderText paddingTop={5}>You Pay</HeaderText>
                            <PaymentDetail name="Available Credits" detail={credits ?? 0} />
                            <PaymentDetail name="Class Price" detail={`${price} credits`} />
                            <BottomBorderView />
                            <PaymentDetail isHeader name="Total" detail={`${price} credits`} />
                            <PurchaseButton
                                nonHover
                                color={colors.homebodyTurquoise}
                                apolloActionStatus={createBookingStatus}
                                actionLoading={actionLoading}
                                text={'Book'}
                                onPress={handleBooking}
                            />
                        </InfoContainer>
                    </WebBookContainer>
                )}
            </BookClassContainer>
            {isWeb && <FooterBar />}
        </ScreenContainer>
    )
}

const BookClassContainer = styled(View)`
    flex: 1;
`
const WebBookContainer = styled(View)`
    flex-direction: row;
    padding-top: 5%;
    justify-content: space-around;
`
const ShareClassText = styled(ButtonText)`
    align-self: center;
    position: absolute;
    top: -30px;
`
const SuccessContainer = styled(View)`
    flex: 1;
    display: flex;
    flex-direction: column;
    ${({ theme }) => !theme.isMobile && 'padding-top: 5%;'}
`
const InfoContainer = styled(View)`
    padding-horizontal: 6%;
    ${({ flex }) => flex && `flex: ${flex};`}
    ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
`
const ButtonContainer = styled(View)`
    ${({ theme }) => theme.isMobile && 'flex: 1;'}
    display: flex;
    flex-direction: ${({ theme }) => (theme.isMobile || theme.isTablet ? 'column' : 'row')};
    justify-content: ${({ theme }) => (theme.isMobile || theme.isTablet ? 'space-around' : 'flex-start')};
    padding-top: ${({ theme }) => (theme.isMobile ? '5' : '3')}%;
`
const BottomBorderView = styled(View)`
    height: 20px;
    width: 100%;
    margin-top: ${({ theme }) => (theme.isMobile ? '2.5' : '10')}%;
    border-bottom-width: 2px;
    border-color: ${colors.dividingGray};
`
const PaymentDetailContainer = styled(RowContainer)`
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
`
const TitleText = styled(Inter900)`
    padding-top: 10%;
    padding-left: 15%;
    padding-right: 15%;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
`
const InfoText = styled(Inter500)`
    padding-top: 5%;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: ${colors.dividingGray};
`
const StyledContinueButton = styled(ContinueButton)`
    ${({ theme }) => !theme.isMobile && 'width: 500px; align-self:flex-end; margin-top: 10%; padding-right: 5%;'}
`
const PurchaseButton = styled(ContinueButton)`
    ${({ theme }) => !theme.isMobile && 'width: 165px; align-self:flex-end; margin-top: 15%;'}
`
