import React, { useState, useEffect } from 'react'
import { Text, View, StyleSheet } from 'react-native'
import { Picker } from '@react-native-picker/picker'
import styled from 'styled-components/native'
import colors from 'colors'
import { Hoverable } from 'react-native-web-hover'
import { isWeb } from '@constants'
import { Inter500, Inter400 } from 'typography'
import { useDimensions } from 'dimensions'
import { SANITIZE_INPUT_TYPES } from '@constants'
const { NUMERIC_INPUT } = SANITIZE_INPUT_TYPES
const noop = () => {}

export const StyledInputField = styled.TextInput`
    font-family: Inter_600SemiBold;
    width: ${props => props.width || '100%'};
    height: ${props => (props.largeTitle ? '56px' : '50px')};
    border-width: 1px;
    border-color: ${props => (props.hasError ? 'red' : props.hovered ? colors.blueContinue : colors.inputFieldGray)}
    background-color: ${colors.inputFieldGray};
    border-radius: 16px;
    padding-left: ${props => (props.largeTitle ? '10px' : '10px')};
    margin-vertical: ${props => (props.largeTitle ? '0px' : '10px')};
    ${isWeb && `outline-color: ${colors.blueContinue};`}
`
const StyledPicker = styled(Picker)`
    height: ${props => props.height || '50px'};
    width: ${props => props.width || '100%'};
    border-width: 1px;
    border-color: ${props => (props.hasError ? 'red' : props.hovered ? colors.blueContinue : colors.inputFieldGray)}
    background-color: ${colors.inputFieldGray};
    border-radius: 10px;
    padding-left: 10px;
    margin-vertical: 10px;
`

export const StyledTextInput = ({
    fieldTitle,
    placeholder,
    onChangeText,
    style,
    errorMessage,
    secure = false,
    autoCapitalize = 'none',
    autoCorrect = false,
    value,
    shouldSanitizeInput = false,
    sanitizeRegex = NUMERIC_INPUT,
    ...rest
}) => {
    return (
        <View style={styles.container}>
            <InputFieldTitleText style={{ color: errorMessage ? 'red' : 'black' }}>
                {errorMessage ? errorMessage : fieldTitle}
            </InputFieldTitleText>
            <Hoverable>
                {({ hovered }) => (
                    <StyledInputField
                        {...rest}
                        textContentType="none"
                        placeholder={placeholder}
                        hovered={hovered}
                        style={style}
                        secureTextEntry={secure}
                        value={value}
                        onChangeText={text =>
                            shouldSanitizeInput
                                ? { value: onChangeText(text.replace(sanitizeRegex, '')) }
                                : { value: onChangeText(text) }
                        }
                        autoCapitalize={autoCapitalize}
                        autoCorrect={autoCorrect}
                    />
                )}
            </Hoverable>
        </View>
    )
}

export const InputField = ({
    fieldName,
    nestedFieldName = '',
    fieldTitle,
    onChangeText = noop,
    handleBlur = noop,
    getFieldError = noop,
    errors = [],
    touched,
    placeholder,
    populateErrors = false,
    style,
    value,
    width = '100%',
    hoverable = true,
    defaultValue,
    updateForm,
    reactiveForm = false,
    secure = false,
    autoCapitalize = 'none',
    autoCorrect = false,
    multiline = false,
    largeTitle = false,
    smallTitle = false,
    shouldSanitizeInput = false,
    sanitizeRegex = NUMERIC_INPUT,
    containerStyle,
    ...rest
}) => {
    const isNested = nestedFieldName != ''
    const [fieldError, setFieldError] = useState({})
    const { showError = false, error = '' } = fieldError
    const updateFieldError = () => setFieldError(getFieldError({ fieldName, nestedFieldName }))
    const hideFieldError = () => setFieldError({ ...fieldError, showError: false })

    useEffect(() => {
        if (populateErrors) {
            updateFieldError()
        }
    }, [populateErrors])

    const onBlur = () => {
        handleBlur({ fieldName, nestedFieldName })
        if (populateErrors) {
            updateFieldError()
        }
    }

    return (
        <View style={[styles.container, containerStyle]}>
            <InputFieldTitleText
                largeTitle={largeTitle}
                smallTitle={smallTitle}
                style={{ color: showError ? 'red' : smallTitle ? colors.darkGrey : 'black' }}
            >
                {showError ? error : fieldTitle}
            </InputFieldTitleText>
            <Hoverable>
                {({ hovered }) => (
                    <StyledInputField
                        textContentType="none"
                        hasError={error}
                        hovered={hoverable ? hovered : false}
                        style={style}
                        width={width}
                        value={value}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        secureTextEntry={secure}
                        autoCapitalize={autoCapitalize}
                        autoCorrect={autoCorrect}
                        multiline={multiline}
                        largeTitle={largeTitle}
                        smallTitle={smallTitle}
                        onFocus={populateErrors ? hideFieldError : noop}
                        onBlur={onBlur}
                        onChangeText={
                            reactiveForm
                                ? text =>
                                      updateForm(
                                          !isNested
                                              ? {
                                                    fieldName,
                                                    value: shouldSanitizeInput ? text.replace(sanitizeRegex, '') : text,
                                                }
                                              : {
                                                    fieldName,
                                                    nestedField: {
                                                        [nestedFieldName]: shouldSanitizeInput
                                                            ? text.replace(sanitizeRegex, '')
                                                            : text,
                                                    },
                                                },
                                      )
                                : onChangeText({ fieldName, shouldSanitizeInput, sanitizeRegex })
                        }
                        {...rest}
                    />
                )}
            </Hoverable>
        </View>
    )
}

export const InputPicker = ({
    fieldName,
    fieldTitle,
    errors,
    selected,
    asLabel,
    asValue,
    style,
    initialValue,
    setSelected,
    hasError,
    items = [],
}) => {
    const { isDesktop } = useDimensions()

    return (
        <View style={style || styles.container}>
            <Text style={{ color: hasError ? 'red' : 'black' }}>{hasError ? errors[fieldName] : fieldTitle}</Text>
            <StyledPicker selectedValue={selected} onValueChange={setSelected}>
                {items.length > 0 &&
                    items.map((i, k) => (
                        <Picker.Item key={'item' + k} label={i[asLabel] || i.label} value={i[asValue] || i.value} />
                    ))}
            </StyledPicker>
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        paddingHorizontal: 0,
        flex: 1,
        paddingTop: 20,
    },
})
export const InputFieldTitleText = styled(Inter500)`
    font-size: ${props => (props.largeTitle ? '22px' : props.smallTitle ? '11.14px' : '14.1794px')};
    font-weight: ${props => (props.largeTitle ? 'bold' : 'normal')};
    padding-bottom: ${props => (props.largeTitle ? '20px' : '0px')};
    padding-top: ${props => (props.largeTitle ? '20px' : '0px')};
    line-height: 14px;
`

export const InputFieldRowSpacer = styled(View)`
    width: 15px;
`
