import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/core'
import { View, TouchableOpacity } from 'react-native'
import { ScreenContainer, ContentContainer } from 'commons/containers'
import { TraineeWebBannerHeader } from 'commons/components/TraineeWebBannerHeader'
import colors from 'colors'
import styled from 'styled-components/native'
import { isWeb, SANITIZE_INPUT_TYPES } from '@constants'
import { StyledTextInput } from 'components/InputField'
import VectorIcon from 'components/VectorIcon'
import { ContinueButton } from 'components/ContinueButton'
import { changePassword } from 'actions'
import { INSTRUCTOR_ACCOUNT } from 'screenNames'
import { FooterBar } from 'commons/components/FooterBar'
const emptyObj = {}

const PasswordField = ({
    value,
    onPress,
    onFocus,
    fieldTitle,
    onChangeText,
    showPassword,
    errorMessage,
    onSubmitEditing,
    isInstructorView = false,
}) => {
    return (
        <InputFieldContainer isInstructorView={isInstructorView}>
            <StyledTextInput
                value={value}
                shouldSanitizeInput
                fieldTitle={fieldTitle}
                onChangeText={onChangeText}
                secure={!showPassword}
                errorMessage={errorMessage}
                onFocus={onFocus}
                onSubmitEditing={onSubmitEditing}
                sanitizeRegex={SANITIZE_INPUT_TYPES.NO_SPACES}
            />
            <VisibilityIcon onPress={onPress}>
                <VectorIcon
                    family="MaterialCommunityIcons"
                    name={showPassword ? 'eye-outline' : 'eye-off'}
                    size={22}
                    color={colors.dividingGray}
                />
            </VisibilityIcon>
        </InputFieldContainer>
    )
}

export default function ChangePassword() {
    const navigation = useNavigation()
    const { routeNames } = navigation.getState()
    const isInstructorView = routeNames?.includes(INSTRUCTOR_ACCOUNT)
    const [showPassword, setShowPassword] = useState(false)
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [oldPasswordError, setOldPasswordError] = useState('')
    const [verifyNewPassword, setVerifyNewPassword] = useState('')
    const [verifyPasswordError, setVerifyPasswordError] = useState('')
    const [newPasswordSet, setNewPasswordSet] = useState(false)
    const [actionLoading, setActionLoading] = useState(false)
    const [changePasswordStatus, setChangePasswordStatus] = useState({ actionSuccess: false, statusMessage: '' })
    const togglePasswordVisibility = () => setShowPassword(!showPassword)

    const handleChangePassword = async () => {
        if (newPasswordSet) setOldPasswordError('New password already set')
        else if (newPassword == '') {
            setVerifyPasswordError('New password cannot be blank')
        } else if (newPassword == oldPassword) {
            setVerifyPasswordError('New password cannot be current password')
        } else if (newPassword == verifyNewPassword) {
            setActionLoading(true)
            const result = await changePassword({ oldPassword, newPassword })
            if (result == 'SUCCESS') {
                setTimeout(() => {
                    setNewPasswordSet(true)
                    setActionLoading(false)
                    setChangePasswordStatus({ actionSuccess: true, statusMessage: 'Password successfully changed' })
                }, 1000)
            } else if (result?.code) {
                setTimeout(() => {
                    setActionLoading(false)
                    setOldPasswordError(result?.message)
                    setChangePasswordStatus({ actionSuccess: false, statusMessage: '' })
                }, 1000)
            } else {
                setTimeout(() => {
                    setActionLoading(false)
                    setOldPasswordError('Incorrect password provided')
                    setChangePasswordStatus({ actionSuccess: false, statusMessage: '' })
                }, 1000)
            }
        } else {
            setVerifyPasswordError('Passwords do not match')
        }
    }

    const clearVerifyPasswordError = () => setVerifyPasswordError('')
    const clearOldPasswordError = () => setOldPasswordError('')

    return (
        <ScreenContainer>
            {isWeb && <TraineeWebBannerHeader title="Change Password" />}
            <ContentContainer isFluid={isWeb} style={isWeb ? { alignItems: 'center' } : emptyObj}>
                <PasswordField
                    value={oldPassword}
                    showPassword={showPassword}
                    fieldTitle="Current Password"
                    onChangeText={setOldPassword}
                    errorMessage={oldPasswordError}
                    onPress={togglePasswordVisibility}
                    onFocus={clearOldPasswordError}
                />
                <PasswordField
                    value={newPassword}
                    showPassword={showPassword}
                    fieldTitle="New Password"
                    onChangeText={setNewPassword}
                    errorMessage={verifyPasswordError}
                    onPress={togglePasswordVisibility}
                    onFocus={clearVerifyPasswordError}
                />
                <PasswordField
                    value={verifyNewPassword}
                    showPassword={showPassword}
                    fieldTitle="Verify New Password"
                    onChangeText={setVerifyNewPassword}
                    errorMessage={verifyPasswordError}
                    onPress={togglePasswordVisibility}
                    onFocus={clearVerifyPasswordError}
                    onSubmitEditing={handleChangePassword}
                />
                <SaveButtonContainer>
                    <ContinueButton
                        text="Save"
                        disabled={newPasswordSet}
                        onPress={handleChangePassword}
                        apolloActionStatus={changePasswordStatus}
                        actionLoading={actionLoading}
                    />
                </SaveButtonContainer>
            </ContentContainer>
            {isWeb && <FooterBar />}
        </ScreenContainer>
    )
}

const VisibilityIcon = styled(TouchableOpacity)`
    position: absolute;
    align-self: center;
    ${({ theme }) => (theme.isMobile ? 'padding-top: 32px;' : 'margin-top: 32px;')}
    right: 10px;
`
const InputFieldContainer = styled(View)`
    flex-direction: row;
    padding-vertical: 10px;
    width: ${({ theme, isInstructorView }) => (theme.isMobile ? '100%' : isInstructorView ? '30%' : '30%')};
`
const SaveButtonContainer = styled(View)`
    padding-top: 5%;
    width: ${({ theme, isInstructorView }) => (theme.isMobile ? '100%' : isInstructorView ? '30%' : '30%')};
`
