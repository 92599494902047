export const AUTH_CHANNEL = {
    AUTH: 'auth',
    SIGN_IN: 'signIn',
    CONFIRM_SIGN_UP: 'confirmSignUp',
    SIGN_OUT: 'signOut',
    SIGN_IN_FAILURE: 'signInFailure',
    TOKEN_REFRESH: 'tokenRefresh',
    TOKEN_REFRESH_FAILURE: 'tokenRefreshFailure',
    CONFIGURED: 'configured',
}
