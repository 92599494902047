import React from 'react'
import { View, Modal, StyleSheet } from 'react-native'
import colors from 'colors'
import { Inter700 } from 'typography'
import styled from 'styled-components/native'
import FormButtons from 'components/FormButtons'

const headerText = 'Are you sure you want to delete your class?'
const modalDetails = 'Deleting a class cannot be undone.'

export const DeleteClassModal = ({ visible, firstButtonOnPress, secondButtonOnPress, loading, isGradient }) => {
    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={visible}
            style={{ position: 'absolute', top: '10%', left: '10%', borderColor: 'transparent' }}
        >
            <View style={{ flex: 1, paddingTop: '15%' }}>
                <ModalContainer>
                    <View>
                        <ModalHeader>{headerText}</ModalHeader>
                        <ModalDetails>{modalDetails}</ModalDetails>
                    </View>
                    <FormButtons
                        isCentered={true}
                        firstButtonOnPress={firstButtonOnPress}
                        secondButtonOnPress={secondButtonOnPress}
                        loading={loading}
                        isGradient={isGradient}
                        firstButtonText="Cancel"
                        secondButtonText="Delete"
                        firstButtonColor={colors.grayContinue}
                        secondButtonColor={colors.blueContinue}
                    />
                </ModalContainer>
            </View>
        </Modal>
    )
}

const ModalHeader = styled(Inter700)`
    font-size: 38px;
    line-height: 45px;
    text-align: center;
    color: ${colors.homebodyTurquoise};
`
const ModalDetails = styled(ModalHeader)`
    font-size: 20px;
    color: black;
    padding-vertical: 40px;
`
const ModalContainer = styled(View)`
    align-self: center;
    width: 850px;
    height: 400px;
    margin: 0px;
    border-radius: 46.61px;
    border-width: ${StyleSheet.hairlineWidth}px;
    border-color: gray;
    shadow-color: #000000;
    shadow-offset: 4px 4px;
    shadow-radius: 8px;
    shadow-opacity: 0.25;
    background-color: white;
    padding-top: 62px;
    padding-right: 150px;
    padding-left: 150px;
    padding-bottom: 50px;
    justify-content: space-between;
`

export default DeleteClassModal
