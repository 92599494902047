import React, { useState, useEffect } from 'react'
import { View, StyleSheet, Linking } from 'react-native'
import styled from 'styled-components/native'
import CheckBoxWithLabel from 'components/CheckBoxWithLabel'
import { ContinueButton } from 'components/ContinueButton'
import { StandardContainer } from 'commons/containers'
import colors from 'colors'
import * as screenNames from 'screenNames'
import { instructorApplicationForm } from 'apollo/cache'
import { UPDATE_TRAINER_APPLICATION } from 'apollo/mutations'
import { useReactiveVar, useMutation as useApolloMutation } from '@apollo/client'
import { useMutation } from 'apollo-augmented-hooks'
import { CREATE_TRAINER_APPLICATION } from 'apollo/mutations'
import { getCurrentUser, getTrainerApplication } from 'apollo/selectors'
import { FieldContainer, HeaderTextWrapper, ScreenContainer, ContentContainer } from 'commons/containers'
import { HeaderText, Paragraph, SubHeaderText, Headline } from 'typography'
import { useDimensions } from 'dimensions'
import { USER_TYPES, APPLICATION_STATUS, isWeb } from '@constants'
import { FooterBar } from 'commons/components/FooterBar'
const START_APPLICATION_STATES = {
    START_APPLICATION: 'START_APPLICATION',
    DENIED_APPLICATION: 'DENIED_APPLICATION',
    UNDER_REVIEW_APPLICATION: 'UNDER_REVIEW_APPLICATION',
    UNFINISHED_APPLICATION: 'UNFINISHED_APPLICATION',
}
const { START_APPLICATION, DENIED_APPLICATION, UNDER_REVIEW_APPLICATION, UNFINISHED_APPLICATION } =
    START_APPLICATION_STATES
import * as queries from 'apollo/queries'
const userTypes = {
    isInfluencer: 'I am an influencer',
    isTrainer: 'I am a certified trainer',
    isOwner: 'I am a gym/studio owner',
}
const START_APPLICATION_TEXT = `Thank you for setting up an account with HOMEBODY. Please fill out an application so our team can learn about you.`
const START_APPLICATION_SUB_TEXT = `What best describes you? Select all that apply.`
const UNFINISHED_APPLICATION_TEXT = `You have an unfinished application.`
const UNDER_REVIEW_TEXT = `Your application is under review. You should receive an email within 48 hours on the status of your application. Need help? Contact us at `
const UNDER_REVIEW_TEXT_LINK = `support@homebodylivefitness.com`
const UNDER_REVIEW_SUB_TEXT = `\nPlease note that currently creators accepted onto our platform will only be able to host classes through our web portal. If you download our app, you will be browsing as a user. `
const APPLICATION_DENIED_TEXT = `Your application has been denied. You should recieve an email with additional information.`

const StartInstructorApp = ({ navigation }) => {
    const { isMobile } = useDimensions()
    const { id: userID, firstName, application, userType } = getCurrentUser() || {}
    const [types, setTypes] = useState([])
    const isAdmin = userType === USER_TYPES.ADMIN
    const instructorForm = useReactiveVar(instructorApplicationForm)
    const { isInfluencer, isTrainer, isOwner } = instructorForm
    const instructorTypeSelected = isInfluencer || isTrainer || isOwner
    const goToForm = (index = 0) =>
        navigation.navigate(screenNames.INSTRUCTOR_APPLICATION.PERSONAL_DETAILS, {
            index,
            trainerApplicationID: userID,
        })
    const createTrainerApplication = useMutation(CREATE_TRAINER_APPLICATION)
    const existingApplication = getTrainerApplication({
        trainerApplicationID: userID,
        onCompleted: () => {
            const applicationId = existingApplication?.id ?? null
            if (!instructorApplicationForm().id && applicationId) {
                instructorApplicationForm({ ...existingApplication })
            }
        },
    })
    const updateApplication = useMutation(UPDATE_TRAINER_APPLICATION)
    const updateTypes = (type = 'influencer') => {
        instructorApplicationForm({ ...instructorApplicationForm(), [type]: !instructorApplicationForm()[type] })
        setTypes(types.includes(type) ? types.filter(t => t != type) : [...types, type])
    }

    async function createApplication() {
        //Nested fields passed here to ensure their values are initialized appropriately upon application creation
        const {
            firstName,
            lastName,
            username,
            isInfluencer,
            isTrainer,
            isOwner,
            submitted,
            location,
            socials,
            specializations,
            gymStudioName,
            gymStudioAddress,
            approved,
            website,
        } = instructorApplicationForm()
        const {
            data: {
                createTrainerApplication: { id },
            },
        } = await createTrainerApplication({
            input: {
                id: userID,
                firstName,
                lastName,
                username,
                isInfluencer,
                isTrainer,
                isOwner,
                submitted,
                socials,
                location,
                website,
                specializations,
                gymStudioName,
                gymStudioAddress,
                userID,
                lastIndex: 0,
                approved,
            },
            refetchQueries: [
                { query: queries.GET_USER, variables: { id: userID } },
                { query: queries.GET_TRAINER_APPLICATION, variables: { id: userID } },
            ],
        })
        goToForm(0)
    }

    function handleGoToForm() {
        if (!existingApplication.id) return createApplication()

        const { id, isInfluencer, isTrainer, isOwner } = instructorApplicationForm()
        const { isInfluencer: wasInfluencer, isTrainer: wasTrainer, isOwner: wasOwner, lastIndex } = existingApplication
        const theLastIndex =
            isInfluencer == wasInfluencer && isTrainer == wasTrainer && isOwner == wasOwner ? lastIndex : 0
        updateApplication({
            input: {
                id,
                isInfluencer,
                isTrainer,
                isOwner,
                // have to manually add version because AppSync requires it and apollo doesn't automatically manage it
                _version: existingApplication._version,
                lastIndex: theLastIndex,
            },
        })
        goToForm(0)
    }

    const StartApplication = ({
        headingText,
        instructorForm,
        userTypes,
        types,
        instructorTypeSelected,
        updateTypes,
        handleGoToForm,
    }) => (
        <View>
            <HeaderTextWrapper>
                <Header>{headingText}</Header>
                <SubHeaderText>{START_APPLICATION_TEXT}</SubHeaderText>
            </HeaderTextWrapper>
            <SubHeaderText>{START_APPLICATION_SUB_TEXT}</SubHeaderText>
            <FormButtons>
                <CheckBoxWithLabel
                    label={userTypes.isInfluencer}
                    value={instructorForm.isInfluencer ?? types.includes('isInfluencer')}
                    onValueChange={() => updateTypes('isInfluencer')}
                />
                <CheckBoxWithLabel
                    label={userTypes.isTrainer}
                    value={instructorForm.isTrainer ?? types.includes('isTrainer')}
                    onValueChange={() => updateTypes('isTrainer')}
                />
                <CheckBoxWithLabel
                    label={userTypes.isOwner}
                    value={instructorForm.isOwner ?? types.includes('isOwner')}
                    onValueChange={() => updateTypes('isOwner')}
                />
                <ContinueButton
                    disabled={!instructorTypeSelected}
                    color={instructorTypeSelected ? colors.blueContinue : colors.grayContinue}
                    style={{ marginTop: 40, height: 36.72, width: 238 }}
                    buttonTextStyle={{ fontSize: 13.11 }}
                    text={'Continue'}
                    onPress={handleGoToForm}
                />
            </FormButtons>
        </View>
    )

    const UnfinishedApplication = ({ headingText, handleGoToForm }) => (
        <View>
            <HeaderTextWrapper>
                <Header>{headingText}</Header>
                <SubHeaderText>{UNFINISHED_APPLICATION_TEXT}</SubHeaderText>
            </HeaderTextWrapper>
            <FormButtons>
                <ContinueButton
                    text={'Finish Application'}
                    buttonTextStyle={{ fontSize: 13.11 }}
                    color={colors.blueContinue}
                    onPress={handleGoToForm}
                    style={{ width: 275, height: 37 }}
                />
            </FormButtons>
        </View>
    )

    const UnderReviewApplication = ({ headingText }) => (
        <View>
            <HeaderTextWrapper>
                <Header>{headingText}</Header>
                <SubHeaderText>
                    {UNDER_REVIEW_TEXT}
                    <UnderReviewTextLink onPress={() => Linking.openURL('mailto:support@homebodylivefitness.com')}>
                        {UNDER_REVIEW_TEXT_LINK}
                    </UnderReviewTextLink>
                </SubHeaderText>
            </HeaderTextWrapper>
            <SubHeaderText>{UNDER_REVIEW_SUB_TEXT}</SubHeaderText>
        </View>
    )

    const DeniedApplication = ({ headingText, secondaryText }) => (
        <HeaderTextWrapper>
            <Header>{headingText}</Header>
            <SubHeaderText>{secondaryText}</SubHeaderText>
        </HeaderTextWrapper>
    )

    function CreatorApplication({ currentScreen }) {
        const HI_HEADING_TEXT = firstName ? `Hi, ${firstName}!` : 'Hi!'
        const WELCOME_HEADING_TEXT = `Welcome back, ${firstName}`
        const headingText = !existingApplication.id || isAdmin ? HI_HEADING_TEXT : WELCOME_HEADING_TEXT

        switch (currentScreen) {
            case START_APPLICATION:
                return (
                    <StartApplication
                        headingText={headingText}
                        instructorForm={instructorForm}
                        userTypes={userTypes}
                        types={types}
                        instructorTypeSelected={instructorTypeSelected}
                        updateTypes={updateTypes}
                        handleGoToForm={handleGoToForm}
                    />
                )

            case UNFINISHED_APPLICATION:
                return <UnfinishedApplication headingText={headingText} handleGoToForm={handleGoToForm} />

            case UNDER_REVIEW_APPLICATION:
                return <UnderReviewApplication headingText={headingText} />

            case DENIED_APPLICATION:
                return <DeniedApplication headingText={headingText} secondaryText={APPLICATION_DENIED_TEXT} />

            default:
                return
        }
    }

    const hasStartedApplication = existingApplication?.id
    const isApplicationSubmitted = existingApplication?.submitted
    const isApplicationDenied = existingApplication?.approved == APPLICATION_STATUS.DENIED

    const currentScreen = !hasStartedApplication
        ? START_APPLICATION
        : !isApplicationSubmitted
        ? UNFINISHED_APPLICATION
        : !isApplicationDenied
        ? UNDER_REVIEW_APPLICATION
        : DENIED_APPLICATION
    return (
        <ScreenContainer>
            <ContentContainer>
                <HeadlineText isBold>HOMEBODY Creator Application</HeadlineText>
                <CreatorApplication currentScreen={currentScreen} />
            </ContentContainer>
            {isWeb && <FooterBar />}
        </ScreenContainer>
    )
}

const Header = styled(HeaderText)`
    padding-bottom: 25px;
`
const FormButtons = styled(View)`
    margin-vertical: 5%;
    width: ${({ theme }) => (theme.isMobile ? '100%' : '75%')};
`

const HeadlineText = styled(Headline)`
    margin-top: 30px;
    padding-bottom: 25px;
`

const UnderReviewTextLink = styled(Paragraph)`
    font-weight: bold;
    color: ${colors.homebodyTurquoise};
`

export default StartInstructorApp
