import React, { useState, useEffect } from 'react'
import { useNavigation, useRoute, useIsFocused } from '@react-navigation/native'
import { Text, View, TouchableOpacity, ActivityIndicator } from 'react-native'
import { Heading, SubHeading, Paragraph } from 'typography'
import colors from 'colors'
import {
    getCurrentUser,
    getUserByID,
    getPublishedClassesByInstructorID,
    getReviewsByInstructorID,
} from 'apollo/selectors'
import styled from 'styled-components/native'
import {
    getSocialURL,
    openURL,
    formatDate,
    getInstructorRatingAverage,
    capitalizeFirstLetter,
    getInstructorSpecializationsString,
} from 'helpers'
import TraineeAvatar from 'components/TraineeAvatar'
import VectorIcon from 'components/VectorIcon'
import InstaButton from 'commons/components/InstaButton'
import { navigateClassDetailsFromCurrentStack } from 'navigationActions'
import FavoriteInstructorIcon from 'components/trainee/FavoriteInstructorIcon'
import ClassesFlatList from 'components/instructor/ClassesFlatList'
import { FooterBar } from 'commons/components/FooterBar'
import { ScreenContainer, ContentContainer } from 'commons/containers'
import CalendarDays from 'commons/components/react-native-calendar-slider-carousel'
import ReviewFlatList from 'commons/components/ReviewFlatList'
const emptyObj = {}
const emptyArr = []

export const UserProfile = () => {
    const isFocused = useIsFocused()
    const navigation = useNavigation()
    const { params = emptyObj } = useRoute()
    const { id: instructorID, user: userFromRoute } = params
    const [user, setUser] = useState(userFromRoute)
    const { id: userID, favorites } = getCurrentUser()
    const userFromID = getUserByID({
        userID: instructorID ?? userID,
        onCompleted: () => {
            setUser(userFromID)
        },
    })
    const {
        classes: instructorClasses,
        loading: instructorClassesLoading,
        refetch: refetchInstructorClasses,
    } = getPublishedClassesByInstructorID({ instructorID })
    const isInstructorProfile = user?.userType == 'INSTRUCTOR' || user?.userType == 'ADMIN'
    const { avatar, profile, application, classCategories } = user || emptyObj
    const specializations = getInstructorSpecializationsString({ classCategories, numSpecializations: 3 })
    const { firstName, lastName, socials } = profile || emptyObj
    const { instagram: handle } = socials || emptyObj
    const { dateApproved: beforeDateFormat } = application || emptyObj
    const dateApproved = formatDate(beforeDateFormat, 'MMM yyyy') ?? ''
    const instagramURL = getSocialURL({ handle, platform: 'instagram' })
    const {
        instructorReviews: reviewsResult,
        nextToken: initialReviewsToken,
        fetchMore: fetchMoreReviews,
        loading: reviewsLoading,
        refetch: refetchInstructorReviews,
    } = getReviewsByInstructorID({
        instructorID,
        onCompleted: () => {
            if (reviews?.length === 0 && !reviewsToken) {
                setReviewsToken(initialReviewsToken)
                setReviews(reviewsResult)
            }
        },
    })

    const [reviews, setReviews] = useState(emptyArr)
    const [reviewsToken, setReviewsToken] = useState(null)

    async function handleFetchMoreReviews() {
        if (reviewsToken) {
            const fetchMoreResult = await fetchMoreReviews({ variables: { nextToken: reviewsToken } })
            const {
                data: {
                    listReviews: { items: moreReviews = emptyArr, nextToken: token = null },
                },
            } = fetchMoreResult ?? emptyObj
            const allReviews = reviews?.length > 0 ? [...reviews, ...moreReviews] : [...moreReviews]
            setReviews(allReviews)
            setReviewsToken(token)
        }
    }

    useEffect(() => {
        if (reviewsToken !== null) {
            handleFetchMoreReviews()
        }
    }, [reviewsToken])

    const averageRating = getInstructorRatingAverage(reviews)
    const hasReviewsWithText = reviews?.map(item => item?.review)?.length > 0
    const [date, setDate] = useState(undefined)
    const [classes, setClasses] = useState(instructorClasses)

    const openInstagramURL = () => openURL(instagramURL)

    useEffect(() => {
        if (refetchInstructorClasses && isFocused) {
            refetchInstructorClasses()
        }
        if (refetchInstructorReviews && isFocused) {
            refetchInstructorReviews()
        }
    }, [isFocused])

    useEffect(() => {
        date === undefined
            ? setClasses(instructorClasses)
            : setClasses(instructorClasses?.filter(Class => Class?.date === date))
    }, [date])

    const onDateSelect = selectedDate =>
        setDate(date ? (selectedDate == date ? undefined : selectedDate) : selectedDate)

    const isLoading = user?.loading || (!userFromRoute && userFromID?.loading)
    return (
        <ScreenContainer>
            {isLoading ? (
                <LoadingContainer>
                    <ActivityIndicator animating={user?.loading} color={colors.homebodyTurquoise} size="large" />
                </LoadingContainer>
            ) : (
                <ContentContainer isFluid>
                    <ProfileDetailsContainer>
                        <AvatarCol>
                            <UserAvatar size={197} userInfo={{ firstName, lastName }} avatarImageKey={avatar?.key} />
                        </AvatarCol>
                        <DetailsCol>
                            <DetailsContainer>
                                <Row marginTop={20}>
                                    <NameText isMostBold isLargeHeading fontSize="51.76">{`${firstName || ''} ${
                                        lastName || ''
                                    }`}</NameText>
                                    <FavoriteInstructor
                                        size={40}
                                        instructorID={instructorID}
                                        userID={userID}
                                        favorites={favorites}
                                    />
                                </Row>
                                {isInstructorProfile && (
                                    <Row marginTop={22}>
                                        <SpecializationsText fontSize={31.41} paddingTop={5} paddingRight={30}>
                                            {specializations}
                                        </SpecializationsText>
                                        {!!averageRating && (
                                            <RatingContainer>
                                                <VectorIcon
                                                    family="AntDesign"
                                                    name="star"
                                                    color={colors.homebodyGreen}
                                                    size={32}
                                                />
                                                <RatingText isBold isSubtext paddingLeft={10} fontSize={31}>
                                                    {averageRating}
                                                </RatingText>
                                            </RatingContainer>
                                        )}
                                    </Row>
                                )}
                            </DetailsContainer>
                            {isInstructorProfile && (
                                <InstructorInfoContainer>
                                    <AboutContainer>
                                        <AboutText isMostBold paddingRight={15} fontSize={31}>
                                            About
                                        </AboutText>
                                        <InstaButton onPress={openInstagramURL} disabled={handle ? false : true} />
                                    </AboutContainer>
                                    <BioContainer>
                                        <BioText fontSize={25} paddingTop={10}>
                                            {profile?.bio}
                                        </BioText>
                                    </BioContainer>
                                </InstructorInfoContainer>
                            )}
                        </DetailsCol>
                    </ProfileDetailsContainer>
                    <CalendarDays
                        showArrows
                        onDateSelect={onDateSelect}
                        leftArrow={<VectorIcon family="Entypo" name="chevron-left" size={24} />}
                        rightArrow={<VectorIcon family="Entypo" name="chevron-right" size={24} />}
                    />
                    <ClassesFlatList
                        hidePicker
                        horizontal
                        isWebProfile
                        headingText={`Upcoming Classes`}
                        data={classes}
                        classesLoading={instructorClassesLoading}
                        navigationAction={navigateClassDetailsFromCurrentStack}
                    />
                    {hasReviewsWithText && (
                        <ReviewFlatList
                            style={{ paddingTop: 50 }}
                            horizontal
                            isSmallHeading
                            data={reviews}
                            headingText={`Reviews (${reviews?.length})`}
                            reviewsLoading={reviewsLoading}
                        />
                    )}
                </ContentContainer>
            )}
            <FooterBar />
        </ScreenContainer>
    )
}

const LoadingContainer = styled(View)`
    flex: 1;
    justify-content: center;
    align-items: center;
`
const InstructorInfoContainer = styled(View)`
    width: 100%;
    padding-top: 5%;
`
const BioText = styled(Paragraph)``
const BioContainer = styled(View)`
    width: 75%;
    align-self: flex-start;
    padding-top: 10px;
    padding-bottom: 20px;
`
const RatingContainer = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
`
const AboutContainer = styled(RatingContainer)`
    width: 100%;
    justify-content: flex-start;
`
const RatingText = styled(SubHeading)``
const SpecializationsText = styled(SubHeading)``
const NameText = styled(Heading)`
    align-self: center;
`
const AboutText = styled(NameText)``

const DetailsContainer = styled(View)`
    width: 100%;
    margin-top: 20px;
    justify-content: flex-start;
`
const UserAvatar = styled(TraineeAvatar)``
const FavoriteInstructor = styled(FavoriteInstructorIcon)`
    margin-left: 50px;
    align-items: center;
    align-self: center;
`
const ProfileDetailsContainer = styled(View)`
    display: flex;
    flex-direction: row;
    margin-top: 113px;
    width: 100%;
    flex-wrap: wrap;
    padding-bottom: 50px;
`

const AvatarCol = styled(View)``

const DetailsCol = styled(View)`
    margin-left: 58px;
    width: 80%;
`

const Row = styled(View)`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${props => props.marginTop && `margin-top: ${props.marginTop}px;`}
`

export default UserProfile
