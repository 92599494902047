import React from 'react'
// ui
import { ContentContainer, ScreenContainer } from 'commons/containers'
import { Headline } from 'styles/typography'
import { FooterBar } from 'commons/components/FooterBar'
import InstructorSettings from 'components/instructor/InstructorSettings'

function Settings() {
    return (
        <ScreenContainer hasTopPadding>
            <ContentContainer buttonPaddingBottom isFluid>
                <Headline isLargeHeadline isMostBold paddingBottom={25} marginBottom={50}>
                    Settings
                </Headline>
                <InstructorSettings />
            </ContentContainer>
            <FooterBar />
        </ScreenContainer>
    )
}

export default Settings
