import React, { useState, useEffect, useRef } from 'react'
import { Text, View, TouchableOpacity, Image } from 'react-native'
import colors from 'colors'
import styled from 'styled-components/native'
import { AntDesign } from '@expo/vector-icons'
import Carousel from 'react-native-snap-carousel'
import CustomImage from 'components/CustomImage'
import { Heading } from 'styles/typography'
const noop = () => {}
const renderItem = ({
    item,
    index,
    activeIndex,
    snapToItem,
    itemHeight,
    itemWidth,
    isSuggested,
    numImages,
    disableCards,
    activePadding,
}) => {
    const isActive = isSuggested ? index == activeIndex + 1 : index == activeIndex
    const { imgKey, defaultImage } = item
    const selectItem = () => {
        snapToItem(index - 1 < 0 ? numImages - 1 : index - 1)
    }
    return (
        <TouchableSlide
            itemHeight={itemHeight}
            itemWidth={itemWidth}
            activePadding={activePadding}
            isActive={isActive}
            onPress={selectItem}
            disabled={disableCards}
        >
            <CustomImage
                style={{
                    width: itemWidth - activePadding,
                    height: itemHeight - activePadding,
                    borderRadius: 2 * activePadding,
                }}
                defaultBanner
                imgKey={imgKey}
                defaultImage={defaultImage || item}
            />
        </TouchableSlide>
    )
}
export const BannerCarousel = ({
    data,
    handleSnapToItem,
    disableButtons,
    activeIndex,
    isSuggested = false,
    disableCards,
    hasHeading,
    headingText,
    carouselConfig,
}) => {
    const carousel = useRef(null)
    const snapToNext = () => carousel.current.snapToNext()
    const snapToPrev = () => carousel.current.snapToPrev()
    const snapToItem = index => (!disableButtons ? carousel.current.snapToItem(index) : noop)
    const { itemHeight, itemWidth, sliderHeight, sliderWidth, buttonSize, activePadding, iconSize } = carouselConfig
    if (data.length == 0) return null
    return (
        <Container>
            {hasHeading && (
                <Heading isBold style={{ color: 'white' }}>
                    {headingText}
                </Heading>
            )}
            <CarouselContainer sliderHeight={sliderHeight} sliderWidth={sliderWidth}>
                <PrevButton
                    buttonSize={buttonSize}
                    sliderWidth={sliderWidth}
                    itemHeight={itemHeight}
                    isSuggested={isSuggested}
                    disabled={disableButtons}
                    onPress={snapToPrev}
                >
                    <AntDesign name="left" size={iconSize} color="white" />
                </PrevButton>
                <Carousel
                    loop
                    ref={carousel}
                    data={data}
                    firstItem={isSuggested ? activeIndex : activeIndex - 1}
                    horizontal={true}
                    layout={'default'}
                    itemWidth={itemWidth}
                    loopClonesPerSide={1}
                    sliderWidth={sliderWidth}
                    sliderHeight={sliderHeight}
                    activeSlideAlignment={'center'}
                    contentContainerCustomStyle={{
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                    onBeforeSnapToItem={index => handleSnapToItem(index)}
                    renderItem={({ item, index }) =>
                        renderItem({
                            item,
                            index,
                            activeIndex,
                            snapToItem,
                            itemHeight,
                            itemWidth,
                            activePadding,
                            isSuggested,
                            numImages: data.length,
                            disableCards,
                        })
                    }
                />
                <NextButton
                    buttonSize={buttonSize}
                    sliderWidth={sliderWidth}
                    itemHeight={itemHeight}
                    isSuggested={isSuggested}
                    disabled={disableButtons}
                    onPress={snapToNext}
                >
                    <AntDesign name="right" size={iconSize} color="white" />
                </NextButton>
            </CarouselContainer>
        </Container>
    )
}
const TouchableSlide = styled(TouchableOpacity)`
    background-color: ${props => (props.isActive ? 'white' : 'transparent')};
    width: ${props => (props.isActive ? `${props.itemWidth + props.activePadding}px` : `${props.itemWidth}px`)};
    height: ${props => (props.isActive ? `${props.itemHeight + props.activePadding}px` : `${props.itemHeight}px`)};
    border-radius: ${props => (props.isActive ? `${2 * props.activePadding}px` : '0px')};
    justify-content: center;
    align-items: center;
    padding: ${props => props.activePadding}px;
    ${props =>
        props.isActive &&
        `
        shadow-color: #000000;
        shadow-offset: 0px 15px;
        shadow-radius: 15px;
        shadow-opacity: 0.25;
    `}
`
const SlideImage = styled(Image)`
    width: ${props => props.itemWidth - props.activePadding}px;
    height: ${props => props.itemHeight - props.activePadding}px;
    border-radius: 12px;
`
const Container = styled(View)``
const CarouselContainer = styled(View)`
    align-self: center;
    max-width: 900px;
    height: ${props => props.sliderHeight}px;
    width: ${props => props.sliderWidth}px;
`
const NextButton = styled(TouchableOpacity)`
    position: absolute;
    z-index: 5;
    width: ${props => props.buttonSize}px;
    height: ${props => props.buttonSize}px;
    background-color: ${colors.blueContinue};
    border-radius: 28px;
    justify-content: center;
    align-items: center;
    left: ${props => `${props.sliderWidth - props.buttonSize / 2}px`};
    bottom: ${props => `${props.itemHeight / 2 + props.buttonSize / 2 + (props.isSuggested ? 5 : 0)}px`};
`
// top: ${sliderHeight / 2 - 28};
const PrevButton = styled(NextButton)`
    z-index: 5;
    left: ${props => `-${props.buttonSize / 2}px}`};
`

export default BannerCarousel
