import React, { useState, useEffect } from 'react'
import { View, Text, TouchableOpacity, ActivityIndicator } from 'react-native'
import styled from 'styled-components/native'
import { StyledTextInput } from 'components/InputField'
import colors from 'colors'
import { pickFile, deleteFile } from 'actions'
import { FormHeaderLabel } from 'typography'
import { getCurrentUser } from 'apollo/selectors'
import { FieldContainer, FieldRowContainer } from 'src/commons/containers'
import { DELETE_FILE } from 'apollo/mutations'
import { useMutation } from 'apollo-augmented-hooks'
import UploadButton from 'components/UploadButton'
import { FILE_TYPES } from '@constants'
const { BUSINESS } = FILE_TYPES
const noop = () => {}
const emptyArr = []

export const BusinessRegistration = ({
    files = {
        items: emptyArr,
    },
    reactiveFormProps,
    businessRegistrationUploads,
    setBusinessRegistrationUploads,
}) => {
    const { isValid, getFieldError, populateErrors } = reactiveFormProps
    const { id } = getCurrentUser()
    const deleteFileMutation = useMutation(DELETE_FILE)

    const [fieldError, setFieldError] = useState({})
    const { showError = false, error = '' } = fieldError

    const updateFieldError = () => setFieldError(getFieldError({ fieldName: 'files' }))
    const hideFieldError = () => setFieldError({ ...fieldError, showError: false })

    useEffect(() => {
        if (isValid) {
            updateFieldError()
        }
    }, [isValid])

    useEffect(() => {
        if (populateErrors) {
            updateFieldError()
        }
    }, [populateErrors])

    const onBlur = () => (populateErrors ? updateFieldError() : noop)

    const defaultBusinessRegistrationUploads = [
        {
            name: '',
            existing: false,
            file: { key: '' },
            fileType: BUSINESS,
        },
    ]
    const existingBusinessRegistrationUploads = files?.items
        ?.filter(file => file?.fileType == BUSINESS && !file?._deleted)
        ?.map(item => ({
            existing: true,
            name: item?.name,
            fileID: item?.id,
            file: { key: `trainerApplicationDocuments/${id}/${item?.name}` },
            fileType: item?.fileType,
            _version: item?._version,
        }))

    useEffect(() => {
        setBusinessRegistrationUploads(
            existingBusinessRegistrationUploads?.length
                ? existingBusinessRegistrationUploads
                : defaultBusinessRegistrationUploads,
        )
    }, [files?.items])

    function handleDeleteFile(file) {
        deleteFile({ file, deleteFileMutation })
    }

    return (
        <FieldContainer>
            <FieldRowContainer>
                <FormHeaderLabel>{`Please upload proof of \nbusiness ownership`}</FormHeaderLabel>
                <AddButton
                    onPress={() => {
                        setBusinessRegistrationUploads([
                            ...businessRegistrationUploads,
                            { name: '', file: {}, existing: false, fileType: BUSINESS },
                        ])
                    }}
                >
                    <AddText>+ Add</AddText>
                </AddButton>
            </FieldRowContainer>
            {businessRegistrationUploads?.map((item, index) => (
                <RowContainer>
                    <BusinessRegistrationUpload
                        item={item}
                        index={index}
                        key={item.fileID}
                        businessRegistrationUploads={businessRegistrationUploads}
                        setBusinessRegistrationUploads={setBusinessRegistrationUploads}
                        showError={showError}
                        error={error}
                        hideFieldError={hideFieldError}
                        updateFieldError={updateFieldError}
                        onBlur={onBlur}
                        deleteFile={handleDeleteFile}
                    />
                </RowContainer>
            ))}
        </FieldContainer>
    )
}

const BusinessRegistrationUpload = ({
    index,
    businessRegistrationUploads,
    setBusinessRegistrationUploads,
    item,
    deleteFile,
    error,
    showError,
    hideFieldError,
    updateFieldError,
    onBlur,
}) => {
    const { id } = getCurrentUser()
    const { existing } = item
    const [uploading, setUploading] = useState(false)
    const [successfulUpload, setSuccessfulUpload] = useState(existing)
    const [businessRegistrationTitle, setBusinessRegistrationTitle] = useState(item.name || '')
    const [errorMessage, setErrorMessage] = useState('')
    async function handleFileUpload() {
        if (showError) {
            hideFieldError()
        }
        if (businessRegistrationTitle) {
            setErrorMessage('')
            const key = await pickFile({ fileName: businessRegistrationTitle, id, setUploading })
            const newBusinessRegistrations = businessRegistrationUploads?.map((item, i) =>
                i === index ? { name: businessRegistrationTitle, file: { key }, fileType: BUSINESS } : item,
            )
            setSuccessfulUpload(true)
            setBusinessRegistrationUploads(newBusinessRegistrations)
            updateFieldError()
        } else {
            setErrorMessage('*name required')
        }
    }

    const clearErrorMessage = () => (showError ? hideFieldError() : errorMessage ? setErrorMessage('') : noop)

    return (
        <>
            <StyledTextInput
                editable={!successfulUpload}
                fieldTitle={`Ownership proof ${index + 1}`}
                errorMessage={showError && error ? error : errorMessage}
                onChangeText={setBusinessRegistrationTitle}
                placeholder="DBA filing"
                onFocus={clearErrorMessage}
                onBlur={onBlur}
                defaultValue={item.name}
            />
            <UploadButton
                successfulUpload={successfulUpload}
                onPress={item.existing ? () => deleteFile(item) : handleFileUpload}
                uploaded={item.existing}
            />
            {uploading && (
                <ActivityIndicator
                    animating={uploading}
                    size="large"
                    color={colors.blueContinue}
                    style={{ marginLeft: 10 }}
                />
            )}
        </>
    )
}

const AddText = styled(Text)`
    color: white;
    text-align: center;
`
const AddButton = styled(TouchableOpacity)`
    margin-horizontal: 10px;
    background-color: ${colors.blueContinue};
    height: 25px;
    width: 60px;
    margin-top: 10px;
    align-self: center;
    justify-content: center;
`
const RowContainer = styled(View)`
    flex-direction: row;
    align-items: center;
`

export default BusinessRegistration
