export const SOCIAL_LINKS = {
    fb: 'https://www.facebook.com/homebodylivefitness/',
    twitter: 'https://twitter.com/homebodyliveapp',
    instagramm: 'https://www.instagram.com/homebodyapp/',
} as const

export const CONTACT = {
    EMAIL: 'hello@homebodylivefitness.com',
    PRESS_EMAIL: 'press@homebodylivefitness.com',
    SUPPORT_EMAIL: 'support@homebodylivefitness.com',
} as const
