import React, { useState, useEffect } from 'react'
import { Alert, Linking } from 'react-native'
import Constants from 'expo-constants'
import * as ImagePicker from 'expo-image-picker'
import * as Notifications from 'expo-notifications'
import { isAndroid } from '@constants'
import { useMutation } from 'apollo-augmented-hooks'
import { UPDATE_USER } from 'apollo/mutations'
import { GET_USER } from 'apollo/queries'
import { updateUserPushToken } from 'actions'
import { APOLLO_FETCH_POLICIES } from '@constants'

function settingsAlert({ alertTitle, alertMessage, alertLink }) {
    Alert.alert(alertTitle, alertMessage, [
        {
            text: 'OK',
            onPress: () => {
                if (isAndroid) {
                    Linking.openSettings()
                } else {
                    Linking.openURL(alertLink)
                }
            },
        },
        {
            text: 'Cancel',
            style: 'cancel',
        },
    ])
}

export const useImagePermissions = () => {
    const [imagePermissions, setImagePermissions] = useState(false)

    async function getImagePermissions() {
        if (isAndroid) {
            const { granted, canAskAgain } = await ImagePicker.getMediaLibraryPermissionsAsync()

            if (granted) {
                setImagePermissions(true)
            } else {
                if (!canAskAgain) {
                    setImagePermissions(false)
                    settingsAlert({
                        alertTitle: 'We need permission to access your image libary.',
                        alertMessage: "To enable permission, we'll take you to your phone's settings.",
                        alertLink: 'app-settings://homebody',
                    })
                } else {
                    const response = await ImagePicker.requestMediaLibraryPermissionsAsync()
                    const newGranted = response?.granted
                    setImagePermissions(newGranted)
                    return response?.granted
                }
            }
        } else {
            const { status: existingStatus } = await ImagePicker.getMediaLibraryPermissionsAsync()
            let finalStatus = existingStatus
            if (existingStatus !== 'granted') {
                const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
                finalStatus = status
            }
            if (finalStatus !== 'granted') {
                settingsAlert({
                    alertTitle: 'We need permission to access your image library.',
                    alertMessage: "To enable permission, we'll take you to your phone's settings.",
                    alertLink: 'app-settings://homebody',
                })
            }
            setImagePermissions(finalStatus == 'granted')

            return finalStatus == 'granted'
        }
    }

    return {
        imagePermissions,
        getImagePermissions,
    }
}

export const usePushNotificationPermissions = ({ id, _version }) => {
    const updateUserMutation = useMutation(UPDATE_USER, {
        refetchQueries: [
            {
                query: GET_USER,
                variables: { id },
                fetchPolicy: APOLLO_FETCH_POLICIES.NETWORK_ONLY,
            },
        ],
    })
    const [pushNotificationPermissions, setPushNotificationPermissions] = useState()

    async function updateUserToken({ pushToken }) {
        await updateUserPushToken({ id, _version, pushToken, updateUserMutation })
    }

    async function registerPushNotifications() {
        try {
            if (Constants.isDevice) {
                const { status: existingStatus } = await Notifications.getPermissionsAsync()
                let finalStatus = existingStatus
                if (existingStatus !== 'granted') {
                    const { status } = await Notifications.requestPermissionsAsync({
                        ios: {
                            allowAlert: true,
                            allowBadge: true,
                            allowSound: true,
                            allowAnnouncements: true,
                        },
                        android: {},
                    })
                    finalStatus = status
                }
                if (finalStatus !== 'granted') {
                    settingsAlert({
                        alertTitle: 'We need permission to send you push notifications.',
                        alertMessage: "To enable permission, we'll take you to your phone's settings.",
                        alertLink: 'app-settings://homebody',
                    })
                }
                const { data: pushToken } = await Notifications.getExpoPushTokenAsync({
                    experienceId: '@homebody/homebody',
                })
                if (pushToken) {
                    const result = await updateUserToken({ pushToken })
                }
                setPushNotificationPermissions(finalStatus === 'granted')

                return
            }
        } catch (e) {
            setPushNotificationPermissions(false)
            __DEV__ && console.log('Error registering push notifications: ', e)
            return
        }
    }

    return {
        registerPushNotifications,
        pushNotificationPermissions,
    }
}
