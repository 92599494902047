import React, { useEffect, useState, useCallback } from 'react'
import colors from 'colors'
import styled from 'styled-components/native'
import { useNavigation } from '@react-navigation/native'
import { useDimensions } from 'dimensions'
import { Text, View, Image } from 'react-native'
import { PUBLISHED_CLASSES } from 'screenNames'
import { useRoute } from '@react-navigation/native'
import useReactiveStream from 'hooks/useReactiveStream'
import useHandleClassEvents from 'hooks/useHandleClassEvents'
import useHostAgoraStream from 'hooks/useHostAgoraStream'
import useHandleAgoraHostEvents from 'hooks/useHandleAgoraHostEvents'
import AgoraStreamPlayer from 'components/streaming/AgoraStreamPlayer'
import LinearGradientView from 'components/LinearGradientView'
import InstructorStreamRoomDetails from 'components/streaming/InstructorStreamRoomDetails'
import StreamMessageOverlay from 'components/streaming/StreamMessageOverlay'
import ClassInfoOverlay from 'components/streaming/InstructorStreamOverlay'
import InstructorStreamControls from 'components/streaming/StreamRoomButton'
import BackButton from 'components/BackButton'
import LeaveStreamModal from 'components/streaming/LeaveStreamModal'

const HOMEBODY_LOGO = require('assets/logo.png')
const emptyObj = {}
export const InstructorStreamRoom = () => {
    const navigation = useNavigation()
    const route = useRoute()
    const { params = emptyObj } = route
    const { classID, numBooked } = params
    const { windowDimensions } = useDimensions()
    const { windowHeight } = windowDimensions
    const [leaveStreamModalVisible, setLeaveStreamModalVisible] = useState(false)
    const reactiveStreamProps = useReactiveStream({ classID, isHost: true, numBooked })
    const streamPlayerHeight = windowHeight * 0.55
    const { streamState, hostEventEmitters, streamHelpers } = reactiveStreamProps
    const { triggerEndStreamHostEvent } = hostEventEmitters
    const {
        instructorClass,
        classStarted,
        streamRoomLocked,
        streamReady,
        streamEnded,
        streamOverlayMessage: { overlayMessage },
    } = streamState
    const { stream: { isHostInStreamRoom } = emptyObj } = instructorClass || emptyObj
    const {
        nextIntervalString,
        instructorStreamControlsButtonText: buttonText,
        instructorStreamControlsButtonColor: buttonColor,
        handleInstructorStreamControlsOnPress: onPress,
    } = streamHelpers

    useHostAgoraStream(reactiveStreamProps)
    useHandleAgoraHostEvents(reactiveStreamProps)
    useHandleClassEvents(reactiveStreamProps)

    const goToPublishedClasses = () => {
        navigation.navigate(PUBLISHED_CLASSES, { wasInstructorInStreamRoom: true })
    }
    const toggleLeaveStreamModalVisible = () => setLeaveStreamModalVisible(!leaveStreamModalVisible)

    async function handleLeaveClass() {
        if (leaveStreamModalVisible) {
            toggleLeaveStreamModalVisible()
        }
        goToPublishedClasses()
    }

    /*
        Heres a fun one. Previously, I had defined the following function in the useEffect responsible for 
        ending the stream with event listeners if the instructor were to leave the stream room via the browser's
        back button or closing their browser window. Turns out, removeEventListener was not being fired as a result
        of the fact that the following function was being reevaluated each time a value in the use effect dependecy array changed.
        useCallback solves this issue.
    */
    const endStreamOnBrowserBackOrClose = useCallback(async event => {
        await triggerEndStreamHostEvent({ wasStreamEndedWithListener: true })
    }, [])

    useEffect(() => {
        if (!streamEnded) {
            window.addEventListener('popstate', endStreamOnBrowserBackOrClose)
            window.addEventListener('beforeunload', endStreamOnBrowserBackOrClose)
        } else {
            window.removeEventListener('popstate', endStreamOnBrowserBackOrClose)
            window.removeEventListener('beforeunload', endStreamOnBrowserBackOrClose)
            goToPublishedClasses()
        }
    }, [streamEnded])

    return (
        <InstructorStreamRoomContainer windowHeight={windowHeight}>
            <StreamContainer>
                <StreamHeaderContainer>
                    <BackButton
                        size={40}
                        disabled={!streamReady}
                        onPress={
                            isHostInStreamRoom && !streamRoomLocked ? toggleLeaveStreamModalVisible : handleLeaveClass
                        }
                        containerStyle={{ right: 5 }}
                    />
                    <HomebodyLogo source={HOMEBODY_LOGO} />
                </StreamHeaderContainer>
                <AgoraStreamPlayer
                    reactiveStreamProps={reactiveStreamProps}
                    videoPlayerStyle={{ height: streamPlayerHeight, width: '100%' }} //AgoraVideoPlayer requires a pixel value, percentages are incompatible
                />
                {classStarted && (
                    <ClassInfoOverlay
                        isLargeInterface
                        reactiveStreamProps={reactiveStreamProps}
                        streamPlayerHeight={streamPlayerHeight}
                    />
                )}
                {!!overlayMessage && <ClassMessageOverlay reactiveStreamProps={reactiveStreamProps} />}
                <StreamFooterContainer>
                    <UpNextContainer>
                        <UpNextText>{nextIntervalString}</UpNextText>
                    </UpNextContainer>
                    {!streamEnded && (
                        <InstructorStreamControls onPress={onPress} buttonText={buttonText} buttonColor={buttonColor} />
                    )}
                    <FooterLinearGradient />
                </StreamFooterContainer>
            </StreamContainer>
            <InstructorStreamRoomDetails reactiveStreamProps={reactiveStreamProps} />
            {leaveStreamModalVisible && (
                <LeaveStreamModal
                    visible={leaveStreamModalVisible}
                    firstButtonOnPress={toggleLeaveStreamModalVisible}
                    secondButtonOnPress={handleLeaveClass}
                />
            )}
        </InstructorStreamRoomContainer>
    )
}

// Window height used as source of truth as a result of pixel value requirement for agoraVideoPlayer
const InstructorStreamRoomContainer = styled(View)`
    flex-direction: row;
    height: ${props => props.windowHeight}px;
    width: 100%;
    background-color: white;
`
const StreamContainer = styled(View)`
    flex-direction: column;
    width: 70%;
`
const StreamHeaderContainer = styled(View)`
    height: 20%;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
`
const ClassMessageOverlay = styled(StreamMessageOverlay)``
const StreamFooterContainer = styled(View)`
    height: 20%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`
const FooterLinearGradient = styled(LinearGradientView)`
    width: 100%;
    height: 10px;
`
const UpNextContainer = styled(View)`
    height: 60px;
    padding-left: 30px;
    width: 100%;
    background-color: ${colors.upNextStreamGray};
    align-self: flex-start;
    justify-content: center;
`
const UpNextText = styled(Text)`
    font-size: 35px;
    font-family: Inter_700Bold;
    line-height: 31px;
    color: black;
`
const HomebodyLogo = styled(Image)`
    margin-right: 5%;
    height: 35px;
    width: 300px;
`

export default InstructorStreamRoom
