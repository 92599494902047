import React, { useEffect } from 'react'
import { Text, TouchableOpacity, View, Image, Linking } from 'react-native'
import GradientBar from './GradientBar'
import * as screenNames from 'screenNames'

import styled from 'styled-components/native'
import colors from 'colors'
import { LinearGradient } from 'expo-linear-gradient'
import { useDimensions } from 'dimensions'
import { Inter600 } from 'typography'
import { agoraStreamState } from 'apollo/cache'
import { useReactiveVar } from '@apollo/client'
import { useNavigation } from '@react-navigation/native'

const noop = () => {}

export const FooterBar = () => {
    const { isMobile } = useDimensions()
    const navigation = useNavigation()
    const streamState = useReactiveVar(agoraStreamState)
    const { streamRoomJoined: isUserInStream } = streamState
    if (isUserInStream) return null
    return (
        <Container>
            <GradientBar />
            <RowContainer style={{ paddingVertical: isMobile ? 6 : 12 }}>
                {isMobile ? (
                    <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
                        <TouchableOpacity
                            style={{ paddingRight: 10 }}
                            onPress={() => navigation.navigate(screenNames.PRIVACY_PAGE)}
                        >
                            <FooterText>Privacy policy</FooterText>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => navigation.navigate(screenNames.PRESS_KIT_PAGE)}>
                            <FooterText>Press</FooterText>
                        </TouchableOpacity>
                    </View>
                ) : (
                    <View style={{ justifyContent: 'flex-start', flexDirection: 'row' }}>
                        <TouchableOpacity
                            style={{ paddingRight: 10 }}
                            onPress={() => navigation.navigate(screenNames.PRIVACY_PAGE)}
                        >
                            <FooterText>Privacy policy</FooterText>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => navigation.navigate(screenNames.TERMS_OF_SERVICE_PAGE)}>
                            <FooterText>Terms of Service</FooterText>
                        </TouchableOpacity>
                    </View>
                )}
                <FooterText>Copyright 2022, Homebody, LLC.</FooterText>
                {!isMobile && (
                    <View style={{ justifyContent: 'center', flexDirection: 'row' }}>
                        <TouchableOpacity
                            style={{ marginRight: 15 }}
                            onPress={() => Linking.openURL('mailto:hello@homebodylivefitness.com')}
                        >
                            <FooterText>Email Us</FooterText>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => navigation.navigate(screenNames.PRESS_KIT_PAGE)}>
                            <FooterText>Press</FooterText>
                        </TouchableOpacity>
                    </View>
                )}
            </RowContainer>
        </Container>
    )
}

export const FooterText = styled(Inter600)`
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    align-self: ${({ theme }) => (theme.isMobile ? 'flex-start' : 'center')};
    color: ${colors.subTextGray};
    margin-vertical: 10px;
`

const RowContainer = styled(View)`
    flex-direction: ${({ theme }) => (theme.isMobile ? 'column' : 'row')};
    justify-content: ${({ theme }) => (theme.isMobile ? 'flex-start' : 'space-between')};
    padding-horizontal: 5%;
`
const Container = styled(View)`
    flex-direction: column;
    background-color: ${colors.white};
`
