import React, { useState, useEffect } from 'react'
import { getS3Object } from 'actions'
import { View } from 'react-native'
import styled from 'styled-components/native'
import { Avatar } from 'react-native-elements'
import colors from 'colors'
import CustomImage from 'components/CustomImage'

const TraineeAvatar = ({ style, size, userInfo, avatarImageKey = '' }) => {
    const [uri, setUri] = useState(undefined)
    const { firstName, lastName } = userInfo || {}
    const initials = firstName?.charAt(0)?.toUpperCase() + lastName?.charAt(0)?.toUpperCase()

    useEffect(() => {
        async function getAvatarFromS3() {
            const avatarURI = await getS3Object(avatarImageKey)
            setUri(avatarURI)
        }
        if (avatarImageKey) {
            getAvatarFromS3()
        }
    }, [avatarImageKey])

    return (
        <TraineeAvatarContainer style={style}>
            <Avatar
                rounded
                size={size}
                title={uri ? '' : initials}
                source={{ uri }}
                containerStyle={{ backgroundColor: uri ? 'transparent' : colors.blueContinue }}
                placeholderStyle={{ backgroundColor: uri ? 'transparent' : colors.blueContinue }}
            />
        </TraineeAvatarContainer>
    )
}

const TraineeAvatarContainer = styled(View)``
export default TraineeAvatar
