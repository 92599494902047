import React from 'react'
import { View, ImageSourcePropType, Image } from 'react-native'
import { Inter900 } from 'typography'
import colors from 'colors'

import styled from 'styled-components/native'
import { useDimensions } from 'dimensions'
import { int } from 'aws-sdk/clients/datapipeline'

type Logo = {
    id: string
    photo: ImageSourcePropType
}

type Review = {
    id: int
    text: string
    onMobile: boolean
    mobileText: string
}

const Features: React.FC = () => {
    const { isMobile } = useDimensions()

    return (
        <Container>
            <LogoContainer>
                {LOGOS.map(item => (
                    <Logo logo={item} key={item.id} />
                ))}
            </LogoContainer>
            <TextContainer>
                {REVIEW_TEXT.map(item => {
                    if (!isMobile) return <TitleText key={item?.id}>{item.text}</TitleText>
                    if (isMobile && item.onMobile) return <TitleText key={item?.id}>{item.mobileText}</TitleText>
                    return
                })}
            </TextContainer>
        </Container>
    )
}

const Logo = ({ logo }: { logo: Logo }): JSX.Element => (
    <LogoImgContainer>
        <LogoImg source={logo.photo} resizeMode="center" />
    </LogoImgContainer>
)

const REVIEW_TEXT: Review[] = [
    {
        id: 0,
        text: 'a more “efficient, premium, clean\nplatform” for connecting and exercise,\nwhich is designed for creators”',
        onMobile: true,
        mobileText:
            'a more “efficient, premium, clean platform” for connecting\nand exercise, which is designed for creators”',
    },
    {
        id: 1,
        text: '“Users are able to access their “favorite\ntrainers, influencers, and celebrities” from the\ncomfort of their own home”',
        onMobile: false,
        mobileText: '',
    },
    {
        id: 2,
        text: '“a high-quality live class that connects\npeople with creatives they want to go ahead\nand work out with”',
        onMobile: false,
        mobileText: '',
    },
]

const LOGOS: Logo[] = [
    {
        id: 'usWeekly',
        photo: require('assets/logos/usWeekly.png'),
    },
    {
        id: 'People',
        photo: require('assets/logos/people.png'),
    },
    {
        id: 'Page Six',
        photo: require('assets/logos/pageSix.png'),
    },
    {
        id: 'Bravo',
        photo: require('assets/logos/bravo.png'),
    },
    {
        id: 'Bustle',
        photo: require('assets/logos/bustle.png'),
    },
    {
        id: 'Msn',
        photo: require('assets/logos/msn.png'),
    },
]

const Container = styled(View)`
    padding-left: 2.5%;
    padding-right: 2.5%;
    padding-top: 2.5%;
`

const LogoContainer = styled(View)`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: ${props => (props.theme.isMobile ? '30' : '60')}px;
`

const LogoImg = styled(Image)`
    width: 100%;
    height: 100%;
`

const LogoImgContainer = styled(View)`
    flex: 0.17;
    align-self: ${props => (props.theme.isMobile || props.theme.isTablet ? 'center' : 'auto')};
    width: ${props => (props.theme.isMobile ? '68px' : '206px')};
    height: ${props => (props.theme.isMobile ? '48px' : '100px')};
`

const TitleText = styled(Inter900)`
    font-weight: 500;
    font-size: ${props => (props.theme.isMobile ? '12px' : '15px')};
    line-height: ${props => (props.theme.isMobile ? '17px' : '22px')};
    color: ${colors.darkGrey};
    margin-bottom: 30px;
    text-align: center;
    ${({ theme }) => !theme.isMobile && 'white-space: pre-wrap;'}
`

const TextContainer = styled(View)`
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    margin-top: ${props => (props.theme.isMobile ? '26px' : '47px')};
`

export default Features
