import { useState, useEffect } from 'react'
import { Animated } from 'react-native'

const FADE_IN_DURATION = 1500
const FADE_OUT_DELAY = 2000
const FADE_OUT_DURATION = 1500
const COUNT_DELAY = 3000
const COUNT_FADE_OUT_DURATION = 2000
export const useStreamOverlayAnimation = ({ reactiveStreamProps }) => {
    const { streamState, classEventEmitters, streamHelpers } = reactiveStreamProps
    const { isIntervalCountdown } = streamHelpers
    const { startClass, queueIntervalCountdown } = classEventEmitters
    const {
        classEnded,
        streamOverlayMessage: { overlayMessage, delay, id: overlayMessageID = 1 },
    } = streamState || {}
    const [overlayOpacity] = useState(new Animated.Value(0))
    const [count, setCount] = useState(5)
    const isClassCountdown = overlayMessage == 'the class will start in...'
    const isCountdown = (isClassCountdown || isIntervalCountdown) && !classEnded

    useEffect(() => {
        overlayOpacity.setValue(0)
        const fadeIn = Animated.timing(overlayOpacity, {
            useNativeDriver: true,
            toValue: 1.0,
            duration: isIntervalCountdown ? 100 : FADE_IN_DURATION,
        })
        const fadeOut = Animated.timing(overlayOpacity, {
            useNativeDriver: true,
            toValue: 0,
            duration: isIntervalCountdown ? COUNT_FADE_OUT_DURATION : FADE_OUT_DURATION,
        })
        fadeIn.stop()
        fadeOut.stop()
        const animation = Animated.sequence([
            Animated.delay(delay ? delay : 0),
            fadeIn,
            Animated.delay(isIntervalCountdown ? COUNT_DELAY : FADE_OUT_DELAY),
            fadeOut,
        ])
        animation.start()
    }, [overlayMessage, overlayMessageID])

    useEffect(() => {
        if (isCountdown) {
            setTimeout(() => {
                let interval = setInterval(() => {
                    setCount(lastTimerCount => {
                        lastTimerCount <= 1 && clearInterval(interval)
                        return lastTimerCount - 1
                    })
                }, 1000) //count every second
                //cleanup the interval on complete
                return () => clearInterval(interval)
            }, delay)
        }
    }, [overlayMessage])

    useEffect(() => {
        if (count == 0) {
            if (isClassCountdown) {
                startClass()
                // Comment in for countdowns at end of each interval
                // queueIntervalCountdown()
            }
            setCount(5)
        }
    }, [count])

    return {
        count,
        isCountdown,
        isIntervalCountdown,
        overlayOpacity,
    }
}

export default useStreamOverlayAnimation
