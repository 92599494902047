import React, { useState, useEffect } from 'react'
import { useDimensions } from 'dimensions'
import { useNavigation, useIsFocused } from '@react-navigation/native'
import { View, TouchableOpacity, Image } from 'react-native'
import styled from 'styled-components/native'
import { FooterBar } from 'commons/components/FooterBar'
import { Heading } from 'typography'
import { ScreenContainer, ContentContainer, RowContainer, WebContentContainerFluid } from 'commons/containers'
import TraineeBookedClasses from 'components/TraineeBookedClasses'
import { EXPLORE_NAVIGATOR, EXPLORE, USER_PROFILE, FAVORITES_NAVIGATOR } from 'screenNames'
import { getCurrentUser, getUsersByFavoriteInstructors } from 'apollo/selectors'
import InstructorFlatList from 'components/trainee/InstructorFlatList'
import ViewAllHeading from 'components/trainee/ViewAllHeading'
import { agoraStreamState } from 'apollo/cache'
import { useReactiveVar } from '@apollo/client'
import { initialAgoraStreamState } from 'initialFormStates'
import TraineeSuggested from './TraineeSuggested'
const BROWSE_INSTRUCTORS = require('assets/worldClassInstructors.png')
const BROWSE_CLASSES = require('assets/virtualClasses.png')
const emptyObj = {}
const emptyArr = []

const TraineeHomeCard = ({ headingText, imageText, onHeadingPress }) => {
    const browseInstructors = headingText == 'Browse By Instructors'
    return (
        <HomeCardContainer>
            <ViewAllHeading headingText={headingText} onHeadingPress={onHeadingPress} />
            {browseInstructors ? (
                <InstructorsImage source={BROWSE_INSTRUCTORS} />
            ) : (
                <ClassesImage source={BROWSE_CLASSES} />
            )}
            <ImageTextContainer onPress={onHeadingPress}>
                <ImageText isMostBold color="white">
                    {imageText}
                </ImageText>
            </ImageTextContainer>
        </HomeCardContainer>
    )
}

export default function TraineeHomeScreen() {
    const navigation = useNavigation()
    const streamState = useReactiveVar(agoraStreamState)
    const { streamRoomJoined, streamEnded } = streamState
    const { isMobile } = useDimensions()
    const [favorites, setFavorites] = useState(emptyArr)
    const [favoritesToken, setFavoritesToken] = useState(null)
    const { favorites: userFavorites, customerID, profile, email, id, _version, userType } = getCurrentUser()
    const { firstName, lastName } = profile || {}
    const {
        favoriteInstructors: favoritesResult,
        refetch: refetchFavoriteInstructors,
        nextToken: initialFavoritesToken,
        fetchMore: fetchMoreFavorites,
        loading: instructorsLoading,
    } = getUsersByFavoriteInstructors({
        favorites: userFavorites,
        isAvatar: true,
        onCompleted: () => {
            if (favorites?.length === 0 && !favoritesToken) {
                setFavoritesToken(initialFavoritesToken)
                setFavorites(favoritesResult)
            }
        },
    })

    async function handleFetchMoreFavorites() {
        if (favoritesToken) {
            const fetchMoreResult = await fetchMoreFavorites({ variables: { nextToken: favoritesToken } })
            const {
                data: {
                    listUsers: { items: moreFavorites = emptyArr, nextToken: token = null },
                },
            } = fetchMoreResult ?? emptyObj
            const allFavorites = favorites?.length > 0 ? [...favorites, ...moreFavorites] : [...moreFavorites]
            setFavorites(allFavorites)
            setFavoritesToken(token)
        }
    }

    useEffect(() => {
        if (favoritesToken !== null) {
            handleFetchMoreFavorites()
        }
    }, [favoritesToken])

    const goToFavorites = () => navigation.navigate(FAVORITES_NAVIGATOR)
    const goToInstructorProfile = ({ instructor, instructorID }) =>
        navigation.navigate(USER_PROFILE, { user: instructor, id: instructorID })

    const goToExploreInstructors = () =>
        navigation.navigate(EXPLORE_NAVIGATOR, { screen: EXPLORE, params: { byInstructor: true } })

    const goToExplore = () =>
        navigation.navigate(EXPLORE_NAVIGATOR, { screen: EXPLORE, params: { byInstructor: false } })

    const isFocused = useIsFocused()

    useEffect(() => {
        if (isFocused && (streamRoomJoined || streamEnded)) {
            setTimeout(() => {
                agoraStreamState(initialAgoraStreamState)
            }, 3000)
        } else if (isFocused && streamState?.waitingRoomJoined) {
            agoraStreamState({ ...streamState, streamEvent: emptyObj, streamEventQueue: emptyArr })
        }
    }, [isFocused])

    useEffect(() => {
        if (!isFocused) {
            setFavorites(emptyArr)
            setFavoritesToken(null)
            refetchFavoriteInstructors && refetchFavoriteInstructors()
        }
    }, [userFavorites, isFocused])

    return (
        <ScreenContainer>
            <WebContentContainerFluid paddingHorizontal="0px">
                <TraineeSuggested />
                <HomeContainer>
                    <TraineeBookedClasses headingText="My Upcoming Live Classes" />
                    {favorites.length !== 0 && (
                        <FavoriteInstructors
                            isAvatar
                            hidePicker
                            horizontal
                            data={favorites}
                            instructorsLoading={instructorsLoading}
                            onHeadingPress={goToFavorites}
                            onCardPress={goToInstructorProfile}
                            headingText="Favorite Instructors"
                        />
                    )}
                    <BrowseCardContainer>
                        <TraineeHomeCard
                            headingText="Browse By Classes"
                            imageText="Virtual Classes"
                            onHeadingPress={goToExplore}
                        />
                        <TraineeHomeCard
                            headingText="Browse By Instructors"
                            imageText="World Class Instructors"
                            onHeadingPress={goToExploreInstructors}
                        />
                    </BrowseCardContainer>
                </HomeContainer>
            </WebContentContainerFluid>
            <FooterBar />
        </ScreenContainer>
    )
}

const FavoriteInstructors = styled(InstructorFlatList)`
    align-self: flex-start;
`
const HomeContainer = styled(ContentContainer)`
    width: 100%;
    height: 100%;
    margin-top: 2%;
`
const BrowseCardContainer = styled(RowContainer)`
    justify-content: space-between;
    margin-bottom: 3%;
`
const HomeCardContainer = styled(View)`
    width: 45%;
    padding-vertical: 20px;
`
const InstructorsImage = styled(Image)`
    width: 100%;
    height: ${({ theme }) => (theme.isMobile ? 150 : 250)}px;
    border-radius: 10px;
`
const ClassesImage = styled(InstructorsImage)``
const ImageTextContainer = styled(TouchableOpacity)`
    position: absolute;
    align-self: center;
    top: 55px;
    width: 100%;
    height: ${({ theme }) => (theme.isMobile ? 150 : 250)}px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const ImageText = styled(Heading)`
    align-self: center;
`
