import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import { ScreenContainer, ContentContainer } from 'commons/containers'
import { TraineeWebBannerHeader } from 'commons/components/TraineeWebBannerHeader'
import { ContinueButton } from 'components/ContinueButton'
import { isWeb, isNative } from '@constants'
import colors from 'colors'
import styled from 'styled-components/native'
import {
    LANDING_PAGE,
    LOGIN,
    CHANGE_PASSWORD,
    WALLET,
    TRAINEE_NOTIFICATIONS,
    TERMS_AND_CONDITIONS,
    TRANSACTION_HISTORY,
    TRAINEE_SUPPORT,
    DELETE_ACCOUNT,
    CANCEL_SUBSCRIPTION,
    PRIVACY_POLICY,
} from 'screenNames'
import AccountSettingsButton from 'components/trainee/AccountSettingsButton'
import { Paragraph } from 'typography'
import { logout } from 'actions'
import Purchases from 'react-native-purchases'
import { FooterBar } from 'commons/components/FooterBar'
import { useApolloClient } from '@apollo/client'

export default function TraineeSettings() {
    const client = useApolloClient()
    const navigation = useNavigation()

    const handleLogout = async () => {
        if (isNative) {
            const isRevCatAnonymous = await Purchases.isAnonymous()
            if (!isRevCatAnonymous) {
                await Purchases.logOut()
            }
        }
        navigation.navigate(isWeb ? LANDING_PAGE : LOGIN)
        await logout()
        await client.clearStore()
        client.cache.gc()
    }

    const goToNotifications = () => navigation.navigate(TRAINEE_NOTIFICATIONS, { initial: false })
    const goToChangePassword = () => navigation.navigate(CHANGE_PASSWORD)
    const goToWallet = () => navigation.navigate(WALLET, { isSettings: true })
    const goToTransactions = () => navigation.navigate(TRANSACTION_HISTORY)
    const goToSupport = () => navigation.navigate(TRAINEE_SUPPORT)
    const goToDeleteAccount = () => navigation.navigate(DELETE_ACCOUNT)
    const goToCancelSubscription = () => navigation.navigate(CANCEL_SUBSCRIPTION)
    const goToTermsAndConditions = () => navigation.navigate(TERMS_AND_CONDITIONS)
    const goToPrivacyPolicy = () => navigation.navigate(PRIVACY_POLICY)

    return (
        <ScreenContainer>
            {!isNative && <TraineeWebBannerHeader title="My Settings" />}
            <ContentContainer isFluid={isWeb} style={isWeb ? { alignItems: 'center' } : {}}>
                {!isNative && (
                    <MyWalletButton
                        text="My Wallet"
                        onPress={goToWallet}
                        iconProps={{ family: 'AntDesign', name: 'creditcard' }}
                    />
                )}
                <TransactionsButton
                    text="Transaction History"
                    onPress={goToTransactions}
                    iconProps={{ family: 'MaterialCommunityIcons', name: 'currency-usd' }}
                />
                <ChangePasswordButton
                    text="Change Password"
                    onPress={goToChangePassword}
                    iconProps={{ family: 'Feather', name: 'lock' }}
                />
                <ContactUsButton
                    text="Contact Us"
                    iconProps={{ family: 'FontAwesome', name: 'phone' }}
                    onPress={goToSupport}
                />
                <NotificationsButton
                    text="Notifications"
                    onPress={goToNotifications}
                    iconProps={{ family: 'Feather', name: 'bell' }}
                />
                <LogoutButton
                    text="Logout"
                    onPress={handleLogout}
                    iconProps={{ family: 'SimpleLineIcons', name: 'logout' }}
                />
                {!isWeb && (
                    <TermsContainer>
                        <Paragraph onPress={goToTermsAndConditions}>Terms and Conditions</Paragraph>
                        <Paragraph paddingTop={10} onPress={goToPrivacyPolicy}>
                            Privacy Policy
                        </Paragraph>
                    </TermsContainer>
                )}
            </ContentContainer>
            <SettingsButtonContainer>
                <ContinueButton
                    onPress={isWeb ? goToCancelSubscription : goToDeleteAccount}
                    text={isWeb ? 'Cancel Subscription' : 'Delete Account'}
                    color={colors.grayContinue}
                    style={{ height: 56 }}
                />
            </SettingsButtonContainer>
            {isWeb && <FooterBar />}
        </ScreenContainer>
    )
}
const SettingsButtonContainer = styled(View)`
    align-self: center;
    width: ${({ theme }) => (theme.isMobile ? '100%' : theme.isTablet ? '60%' : '37.5%')};
    padding-bottom: 25px;
    padding-horizontal: 5%;
`
const MyWalletButton = styled(AccountSettingsButton)`
    margin-top: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`
const TransactionsButton = styled(AccountSettingsButton)`
    ${({ theme }) =>
        (theme.isMobile || theme.isTablet) &&
        `margin-top: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;`}
`
const ChangePasswordButton = styled(AccountSettingsButton)``
const ContactUsButton = styled(AccountSettingsButton)``
const NotificationsButton = styled(AccountSettingsButton)``
const LogoutButton = styled(AccountSettingsButton)`
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
`
const TermsContainer = styled(View)`
    padding-top: 25px;
    align-self: ${({ theme }) => (theme.isMobile ? 'flex-start' : 'center')};
`
