import React from 'react'
import { ScreenContainer, ContentContainer } from 'commons/containers'
import { FooterBar } from 'commons/components/FooterBar'
import { isWeb } from '@constants'
import privacyContent from '../../data/privacyContent'
import MarkdownContent from 'screens/privacy/MarkdownContent'

const PrivacyPolicy = () => {
    return (
        <ScreenContainer>
            <ContentContainer>
                <MarkdownContent>{privacyContent}</MarkdownContent>
            </ContentContainer>
            {isWeb && <FooterBar />}
        </ScreenContainer>
    )
}

export default PrivacyPolicy
