import React, { useState, useEffect } from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import { View } from 'react-native'
import styled from 'styled-components/native'
// ui
import colors from 'colors'
// nav
import { CREATE_CLASS, PUBLISHED_CLASSES_NAVIGATOR, UNPUBLISHED_CLASSES, PUBLISHED_CLASSES } from 'screenNames'
// state/selectors
import { initialCreateClassForm } from 'initialFormStates'
import { useReactiveVar } from '@apollo/client'
import { createClassForm } from 'apollo/cache'
import { getCreateClassNavigation, getCurrentUser } from 'selectors'
import { useMutation } from 'apollo-augmented-hooks'
import { CREATE_CLASS as CREATE_NEW_CLASS, UPDATE_CLASS, CREATE_STREAM } from 'apollo/mutations'
import { LIST_CLASSES } from 'apollo/queries'
import { createClass, updateClass, createStream, sendMultiplePushNotifications } from 'actions'
import { createClassValidationFunc } from 'validations'
import { CLASS_STATUS, APOLLO_FETCH_POLICIES } from '@constants'
import { isClassDateTimeValid } from 'helpers'
// form
import useReactiveForm from 'hooks/useReactiveForm'
import useNotifications from 'hooks/useNotifications'
import { createClassFieldNames, createClassFieldErrors } from 'formFieldInfo'
// components
import { ScreenContainer, ContentContainer } from 'commons/containers'
import { NavigationTabs } from 'components/NavigationTabs'
import { PublishClassModal } from 'components/instructor/PublishClassModal'
import { FooterBar } from 'commons/components/FooterBar'
import FormButtons from 'components/FormButtons'
import Details from './Details'
import Banner from './Banner'
import Intervals from './Intervals'
import Playlist from './Playlist'
import Price from './Price'
import Review from './Review'
import useDefaultBanners from 'commons/hooks/useDefaultBanners'
const { UNFINISHED, UNPUBLISHED, PUBLISHED } = CLASS_STATUS
const { DETAILS, BANNER, INTERVALS, PLAYLIST, PRICE, REVIEW } = CREATE_CLASS
const emptyObj = {}
const noop = () => {}

function CreateClassForm() {
    const navigation = useNavigation()
    const route = useRoute()
    const defaultBanners = useDefaultBanners()
    const { params = emptyObj } = route
    const {
        isEditing,
        fromUnpublished = false,
        index = 0,
        parentNav,
        resetUnpublishedClassesState = noop,
    } = params || emptyObj
    const { nav, currentPage, prevPage, nextPage, nextIndex } = getCreateClassNavigation({ index, isEditing })
    const form = useReactiveVar(createClassForm)
    const { id: classID, classStatus, intervals: intervalsFromForm, date, startTime, name: className } = form
    const [showInvalidDateTime, setShowInvalidDateTime] = useState(false)
    const isDateTimeValidObj = { ...isClassDateTimeValid(date, startTime), showInvalidDateTime }
    const { isValid: isDateTimeValid = true } = isDateTimeValidObj ?? emptyObj
    const { id: userID = '', username = '' } = getCurrentUser()
    const isPublish = currentPage === REVIEW
    const [intervals, setIntervals] = useState(
        intervalsFromForm?.map(interval => ({ ...interval, existing: true })) ?? [],
    )
    const [checkedIntervals, setCheckedIntervals] = useState([])
    const [isSavingForm, setIsSavingForm] = useState(false)
    const [publishModalVisible, setPublishModalVisible] = useState(false)
    const [updateClassLoading, setUpdateClassLoading] = useState(false)
    const [favoritedInstructorUsers, setFavoritedInstructorUsers] = useState([])
    const createStreamMutation = useMutation(CREATE_STREAM)
    const createClassMutation = useMutation(CREATE_NEW_CLASS)

    const {
        notificationTypes: { FAVORITE_INSTRUCTOR_CLASS },
        handleCreateMultipleNotifications,
    } = useNotifications()

    const updateClassMutation = useMutation(UPDATE_CLASS, {
        onCompleted: async () => {
            if (isPublish && classStatus == PUBLISHED) {
                handlePostPublish()
            }
            setUpdateClassLoading(false)
        },
        refetchQueries: [
            {
                query: LIST_CLASSES,
                variables: {
                    filter: { userID: { eq: userID }, classStatus: { eq: PUBLISHED } },
                    skip: !isSavingForm && !isPublish,
                    limit: 1000,
                },
            },
        ],
    })

    async function handlePostPublish() {
        if (favoritedInstructorUsers?.length > 0) {
            const content = {
                title: `${username} Published a New Class!`,
                body: `Book ${username}'s ${className} now!`,
                data: JSON.stringify({ id: userID }),
            }
            if (!__DEV__) {
                await handleCreateMultipleNotifications({
                    users: favoritedInstructorUsers,
                    content,
                    type: FAVORITE_INSTRUCTOR_CLASS,
                })
            }
        }
        await createStream({ classID, createStreamMutation, instructorID: userID })
        createClassForm(initialCreateClassForm)
        resetUnpublishedClassesState()
        navigation.navigate(PUBLISHED_CLASSES_NAVIGATOR, {
            screen: PUBLISHED_CLASSES,
            params: { resetFormState: true, parentNav },
        })
    }

    const goToReview = () => navigation.navigate(REVIEW, { index: 4, isEditing, classID, fromUnpublished })
    const goToUnpublishedClasses = (isSaving = false) => {
        createClassForm(initialCreateClassForm)
        navigation.navigate(UNPUBLISHED_CLASSES, {
            resetFormState: fromUnpublished,
            shouldResetFetchMore: isSaving,
        })
    }

    const reactiveFormProps = useReactiveForm({
        currentPage,
        reactiveForm: createClassForm,
        fieldNames: createClassFieldNames,
        fieldErrors: createClassFieldErrors,
        validationFunc: createClassValidationFunc,
    })
    const { isValid, handleInvalidPage, updateForm } = reactiveFormProps

    const updateStep = async ({ isSaving = false } = {}) => {
        const shouldCreateClass = form?.id == ''
        const shouldGoToNext = !isSaving && !isPublish
        const shouldForceNewDateSelection = isPublish && !isDateTimeValid && !isSaving
        const shouldOpenPublishModal = isPublish && !isSaving && !publishModalVisible
        const shouldUpdateStatusPublished = isPublish && !isSaving && publishModalVisible
        const shouldUpdateStatusUnpublished = isPublish && isSaving

        if (shouldForceNewDateSelection) {
            setShowInvalidDateTime(true)
            return
        }

        if (shouldOpenPublishModal) {
            setPublishModalVisible(true)
            return
        } else {
            setIsSavingForm(isSaving)
        }

        if (!isValid && isSaving) goToUnpublishedClasses()
        if (!isValid) return handleInvalidPage({ currentPage })

        if (shouldCreateClass) {
            await createClass({ userID, createClassMutation })

            if (isSaving) {
                goToUnpublishedClasses(isSaving)
            } else {
                navigation.navigate(nextPage, { index: index + 1 })
            }
        } else {
            setUpdateClassLoading(true)

            if (shouldUpdateStatusUnpublished) {
                updateForm({ fieldName: createClassFieldNames.classStatus, value: UNPUBLISHED })
            }

            if (shouldUpdateStatusPublished) {
                updateForm({ fieldName: createClassFieldNames.classStatus, value: PUBLISHED })
            }

            if (isSaving) {
                setUpdateClassLoading(true)
                setPublishModalVisible(false)
            }

            await updateClass({ updateClassMutation, intervals, currentPage })

            if (isSaving) goToUnpublishedClasses(isSaving)
            if (shouldGoToNext) navigation.navigate(nextPage, { ...params, index: nextIndex })
        }
    }

    useEffect(() => {
        updateForm({ fieldName: createClassFieldNames.intervals, value: intervals })
    }, [intervals])

    useEffect(() => {
        if (isDateTimeValid) {
            setShowInvalidDateTime(false)
        }
    }, [isDateTimeValid])

    useEffect(() => {
        /* 
            Case where createClassStackNav has been cleared so navigation params passed via 'publishClass'
            in UnpublishedClasses are stale.
        */
        if (classStatus == UNPUBLISHED && !fromUnpublished) {
            navigation.navigate(REVIEW, { ...params, index: 4, fromUnpublished: true })
        }
    }, [])

    return (
        <ScreenContainer hasTopPadding>
            <ContentContainer style={{ paddingHorizontal: '5%', width: '1200px' }}>
                <NavigationTabs
                    onPress={
                        (index == 0 && !isEditing) || (isPublish && classStatus == UNPUBLISHED)
                            ? goToUnpublishedClasses
                            : isEditing && index == 0
                            ? goToReview
                            : undefined
                    }
                    title="Create Class"
                    screens={nav}
                    index={index}
                    currentPage={currentPage}
                    prevPage={prevPage}
                    isInstructor={true}
                    tabFraction={0.0525}
                />
                <>
                    {currentPage == DETAILS && <Details reactiveFormProps={reactiveFormProps} />}
                    {currentPage == BANNER && <Banner reactiveFormProps={reactiveFormProps} images={defaultBanners} />}
                    {currentPage == INTERVALS && (
                        <Intervals
                            intervals={intervals}
                            setIntervals={setIntervals}
                            checkedIntervals={checkedIntervals}
                            setCheckedIntervals={setCheckedIntervals}
                            reactiveFormProps={reactiveFormProps}
                        />
                    )}
                    {/* {currentPage == PLAYLIST && <Playlist reactiveFormProps={reactiveFormProps} />} */}
                    {currentPage == PRICE && <Price reactiveFormProps={reactiveFormProps} />}
                    {currentPage == REVIEW && (
                        <Review
                            isDateTimeValidObj={isDateTimeValidObj}
                            setFavoritedInstructorUsers={setFavoritedInstructorUsers}
                            defaultBanners={defaultBanners}
                        />
                    )}
                </>
                <ButtonContainer>
                    <FormButtons
                        isEnd
                        updateStep={updateStep}
                        loading={updateClassLoading}
                        renderLoadingOnFirstButton={isSavingForm}
                        isGradient={currentPage === REVIEW}
                        firstButtonText="Save"
                        width={148.32}
                        height={61.52}
                        firstButtonColor={colors.grayContinue}
                        secondButtonText={currentPage === REVIEW ? 'Publish' : 'Next'}
                        secondButtonColor={colors.blueContinue}
                        containerStyle={{ paddingHorizontal: 0 }}
                    />
                </ButtonContainer>
            </ContentContainer>
            {publishModalVisible && (
                <PublishClassModal
                    isGradient={true}
                    updateStep={updateStep}
                    isSavingForm={isSavingForm}
                    loading={updateClassLoading}
                    visible={publishModalVisible}
                />
            )}
            <FooterBar />
        </ScreenContainer>
    )
}

const ButtonContainer = styled(View)`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 10%;
    justify-content: flex-end;
`

export default CreateClassForm
