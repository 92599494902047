import React, { useMemo } from 'react'
import { View, Image, TouchableOpacity, Linking, useWindowDimensions } from 'react-native'
import styled from 'styled-components/native'
import colors from 'colors'
import * as screenNames from 'screenNames'
import { useDimensions } from 'dimensions'
import MenuIcon from 'icons/MenuIcon'
import { INSTRUCTOR_HEADER_HEIGHT, isWeb } from '@constants'
import { useQuery } from '@apollo/client'
import { IS_LOGGED_IN } from 'apollo/queries'
import { Inter600, TEXT_LINEAR_GRADIENT } from 'typography'
import { getCurrentUser } from 'apollo/selectors'
import NavigationItem from './NavigationElements'
import { logout } from 'actions'
import { USER_TYPES } from '@constants'
import { agoraStreamState } from 'apollo/cache'
import { useReactiveVar, useApolloClient } from '@apollo/client'
const noop = () => {}

export default function NavigationHeaderOrDrawer({ navigation, isDrawer, state }) {
    const client = useApolloClient()
    const streamState = useReactiveVar(agoraStreamState)
    const { streamRoomJoined = false } = streamState
    const { name: activeScreenName } = state?.routes?.[state.index] ?? {}
    const { isDesktop, isMobileWeb } = useDimensions()
    const { height: windowHeight } = useWindowDimensions()
    const headerHeight = useMemo(() => windowHeight * 0.1, [windowHeight])
    const goToLandingPage = () => navigation.navigate(screenNames.LANDING_PAGE)
    const goToSignUp = () => navigation.navigate(screenNames.SIGN_UP)
    const goToLogin = () => navigation.navigate(screenNames.LOGIN)
    const openDrawer = () => navigation.openDrawer()
    const navigateToInstructorApp = () =>
        navigation.navigate(screenNames.INSTRUCTOR_NAVIGATOR, {
            screen: screenNames.HOME,
        })
    const navigateToTraineeHome = () =>
        navigation.navigate(screenNames.TRAINEE_NAVIGATOR, {
            screen: screenNames.TRAINEE_HOME_SCREEN,
        })
    const {
        data: { isLoggedIn },
    } = useQuery(IS_LOGGED_IN)
    const { userType } = getCurrentUser()
    const isInstructor = userType === USER_TYPES.INSTRUCTOR || userType === USER_TYPES.ADMIN

    const handleLogout = async () => {
        await logout()
        await client.clearStore()
        client.cache.gc()
        navigation.navigate(screenNames.LANDING_PAGE)
    }

    function handleMyHomebodyPress() {
        if (isInstructor) {
            navigateToInstructorApp()
        } else {
            navigateToTraineeHome()
        }
    }

    if (!isDesktop && !isDrawer) {
        return (
            <Container isDrawer={isDrawer}>
                <TouchableOpacity onPress={goToLandingPage} style={{ alignSelf: 'center', flex: 1 }}>
                    <Logo source={require('assets/logo.png')} />
                </TouchableOpacity>
                {isWeb && (
                    <DrawerIconContainer>
                        <MenuIcon size={30} onPress={streamRoomJoined ? noop : openDrawer} />
                    </DrawerIconContainer>
                )}
            </Container>
        )
    }
    return (
        <Container isDrawer={isDrawer} headerHeight={headerHeight} isMobileWeb={isMobileWeb}>
            {!isDrawer && (
                <TouchableOpacity onPress={goToLandingPage} style={{ alignSelf: 'center' }}>
                    <Logo source={require('assets/logo.png')} />
                </TouchableOpacity>
            )}
            {!isMobileWeb && (
                <NavigationItem
                    title="Explore"
                    onPress={() =>
                        navigation.navigate(screenNames.TRAINEE_NAVIGATOR, { screen: screenNames.EXPLORE_NAVIGATOR })
                    }
                    isDrawer={isDrawer}
                    activeScreenName={activeScreenName}
                />
            )}

            <NavigationItem
                title="Shop"
                onPress={() => Linking.openURL('https://homebody-fitness-app.myshopify.com/')}
                isDrawer={isDrawer}
                activeScreenName={activeScreenName}
            />

            <NavigationItem
                title="Blog"
                onPress={() => Linking.openURL('https://homebodylivefitness.shop/blogs/news')}
                isDrawer={isDrawer}
                activeScreenName={activeScreenName}
            />

            <NavigationItem
                title="Press"
                onPress={() => navigation.navigate(screenNames.PRESS_KIT_PAGE)}
                screenName={screenNames.PRESS_KIT_PAGE}
                navigationState={state}
                isDrawer={isDrawer}
                activeScreenName={activeScreenName}
            />

            {/* <NavigationItem
                    title="Apply"
                    onPress={() =>
                        navigation.navigate(screenNames.INSTRUCTOR_APPLICATION.NAVIGATOR, {
                            screen: screenNames.INSTRUCTOR_APPLICATION.START_TRAINER_APP,
                        })
                    }
                    isDrawer={isDrawer}
                    navigationState={state}
                    screenName={screenNames.INSTRUCTOR_NAVIGATOR.START_TRAINER_APP}
                    activeScreenName={activeScreenName}
                /> */}
            <AuthContainer isLoggedIn={isLoggedIn} isDrawer={isDrawer}>
                {!isLoggedIn ? (
                    <>
                        <HeaderButtonText onPress={goToSignUp}>Sign up</HeaderButtonText>
                        <HeaderButtonText>{`  |  `}</HeaderButtonText>
                        <HeaderButtonText onPress={goToLogin}>Log in</HeaderButtonText>
                    </>
                ) : (
                    <>
                        <HeaderButtonText onPress={handleMyHomebodyPress}>My HOMEBODY</HeaderButtonText>
                        <HeaderButtonText>{`  |  `}</HeaderButtonText>
                        <HeaderButtonText onPress={handleLogout}>Log out</HeaderButtonText>
                    </>
                )}
            </AuthContainer>
        </Container>
    )
}

const Container = styled(View)`
    flex-direction: ${({ isDrawer }) => (isDrawer ? 'column' : 'row')};
    justify-content: ${({ isDrawer }) => (isDrawer ? 'flex-start' : isWeb ? 'space-between' : 'center')};
    align-items: ${({ isDrawer }) => (isDrawer ? 'flex-start' : 'center')};
    background-color: white;
    height: ${({ isDrawer, headerHeight, isMobileWeb }) => (isDrawer && !isMobileWeb ? '100%' : `${headerHeight}px`)};
    min-height: ${INSTRUCTOR_HEADER_HEIGHT * 0.6}px;
    max-height: ${INSTRUCTOR_HEADER_HEIGHT}px;
    width: 100%;
    margin-top: ${({ isDrawer }) => (isDrawer ? 10 : 0)}px;
    padding-left: 5%;
    padding-right: 5%;
    ${({ isMobileWeb }) =>
        !isMobileWeb &&
        isWeb &&
        `
    shadow-color: #000000;
    shadow-offset: 0px 4px;
    shadow-radius: 4px;
    shadow-opacity: 0.25;
    elevation: 0;
   `}
`
const DrawerIconContainer = styled(View)`
    position: absolute;
    right: 5%;
`
export const HeaderButtonText = styled(Inter600)`
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    align-self: center;
`

const AuthContainer = styled(View)`
    flex-direction: row;
    align-self: center;
    justify-content: center;
    height: 48px;
    width: ${({ isDrawer }) => (isDrawer ? '100%' : '')};
    ${({ theme }) =>
        !theme.isMobile &&
        TEXT_LINEAR_GRADIENT({
            direction: '90.74deg',
            colorStop1: `${colors.homebodyTurquoise} 3.23%`,
            colorStop2: `${colors.homebodyGreen} 70.45%`,
        })}
    margin-horizontal: 20px;
`

const Logo = styled(Image)`
    height: 30px;
    width: 200px;
    align-self: center;
`
