import { instructorApplicationForm, currentUserID } from './cache'
import {
    INSTRUCTOR_APPLICATION,
    defaultInstructorApplicationPaths,
    instructorApplicationPaths,
    createClassPath,
} from 'screenNames'
//------------------ Apollo ------------------
import * as queries from 'apollo/queries'
import { useQuery, gql } from '@apollo/client'
import * as queryHelpers from 'apollo/queryHelpers'
import _ from 'lodash'
import { APOLLO_FETCH_POLICIES, CLASS_STATUS, USER_TYPES } from '@constants'
const { PUBLISHED } = CLASS_STATUS
const { TRAINEE, INSTRUCTOR } = USER_TYPES
import { useApolloClient } from '@apollo/client'
import apolloClient from 'apolloClient'
import { classByUserID } from '@queries'
const { CACHE_FIRST, CACHE_ONLY, CACHE_AND_NETWORK, NETWORK_ONLY, NO_CACHE } = APOLLO_FETCH_POLICIES
const emptyObj = {}
const emptyArr = []
const emptyString = ''
const noop = () => {}

//------------------- BASE QUERIES --------------------

export const listClasses = ({
    variables,
    skip = false,
    onCompleted = noop,
    fetchPolicy = CACHE_AND_NETWORK,
    nextFetchPolicy = CACHE_FIRST,
}) => {
    const queryResult = useQuery(queries.LIST_CLASSES, {
        skip,
        variables,
        onCompleted,
        fetchPolicy,
        nextFetchPolicy,
        notifyOnNetworkStatusChange: true,
    })
    return queryResult
}

export const classesByUserID = ({
    variables,
    skip = false,
    onCompleted = noop,
    fetchPolicy = CACHE_FIRST,
    nextFetchPolicy = CACHE_AND_NETWORK,
}) => {
    const queryResult = useQuery(queries.CLASS_BY_USER, {
        skip,
        variables,
        onCompleted,
        fetchPolicy,
        nextFetchPolicy,
        notifyOnNetworkStatusChange: true,
    })
    return queryResult
}

export function listUsers({
    variables,
    skip = false,
    onCompleted = noop,
    fetchPolicy = CACHE_FIRST,
    nextFetchPolicy = CACHE_AND_NETWORK,
}) {
    const queryResult = useQuery(queries.LIST_USERS, {
        skip,
        variables,
        onCompleted,
        fetchPolicy,
        nextFetchPolicy,
        notifyOnNetworkStatusChange: true,
    })
    return queryResult
}

export function listUserByType({
    variables,
    skip = false,
    onCompleted = noop,
    fetchPolicy = CACHE_AND_NETWORK,
    nextFetchPolicy = CACHE_FIRST,
}) {
    const queryResult = useQuery(queries.LIST_USERS_BY_TYPE, {
        skip,
        variables,
        onCompleted,
        fetchPolicy,
        nextFetchPolicy,
        notifyOnNetworkStatusChange: true,
    })
    return queryResult
}

export function listUsersMin({
    variables,
    skip = false,
    onCompleted = noop,
    fetchPolicy = CACHE_AND_NETWORK,
    nextFetchPolicy = CACHE_FIRST,
}) {
    const queryResult = useQuery(queries.LIST_USERS_MIN, {
        skip,
        variables,
        onCompleted,
        fetchPolicy,
        nextFetchPolicy,
        notifyOnNetworkStatusChange: true,
    })
    return queryResult
}

export function listClassBookings({
    variables,
    skip = false,
    onCompleted = noop,
    fetchPolicy = CACHE_AND_NETWORK,
    nextFetchPolicy = CACHE_FIRST,
}) {
    const queryResult = useQuery(queries.LIST_CLASS_BOOKINGS, {
        skip,
        variables,
        onCompleted,
        fetchPolicy,
        nextFetchPolicy,
        notifyOnNetworkStatusChange: true,
    })
    return queryResult
}

export function searchClasses({
    variables,
    skip = false,
    onCompleted = noop,
    fetchPolicy = CACHE_AND_NETWORK,
    nextFetchPolicy = CACHE_FIRST,
}) {
    const queryResult = useQuery(queries.SEARCH_CLASSES_WITH_BOOKINGS, {
        skip,
        variables,
        onCompleted,
        fetchPolicy,
        nextFetchPolicy,
        notifyOnNetworkStatusChange: true,
    })
    return queryResult
}

export function classByStatus({
    variables,
    skip = false,
    onCompleted = noop,
    fetchPolicy = CACHE_AND_NETWORK,
    nextFetchPolicy = CACHE_FIRST,
}) {
    const queryResult = useQuery(queries.CLASS_BY_STATUS, {
        skip,
        variables,
        onCompleted,
        fetchPolicy,
        nextFetchPolicy,
        notifyOnNetworkStatusChange: true,
    })
    return queryResult
}

export const listReviewsMin = ({
    variables,
    skip = false,
    onCompleted = noop,
    fetchPolicy = CACHE_AND_NETWORK,
    nextFetchPolicy = CACHE_FIRST,
}) => {
    const queryResult = useQuery(queries.LIST_REVIEWS_MIN, {
        skip,
        variables,
        onCompleted,
        fetchPolicy,
        nextFetchPolicy,
        notifyOnNetworkStatusChange: true,
    })
    return queryResult
}

export function listFavoriteInstructors({
    variables,
    skip = false,
    onCompleted = noop,
    fetchPolicy = CACHE_AND_NETWORK,
    nextFetchPolicy = CACHE_FIRST,
}) {
    const queryResult = useQuery(queries.LIST_FAVORITE_INSTRUCTORS, {
        skip,
        variables,
        onCompleted,
        fetchPolicy,
        nextFetchPolicy,
        notifyOnNetworkStatusChange: true,
    })
    return queryResult
}

export function listNotifications({
    variables,
    skip = false,
    onCompleted = noop,
    fetchPolicy = CACHE_AND_NETWORK,
    nextFetchPolicy = CACHE_FIRST,
}) {
    const queryResult = useQuery(queries.LIST_NOTIFICATIONS, {
        skip,
        variables,
        onCompleted,
        fetchPolicy,
        nextFetchPolicy,
        notifyOnNetworkStatusChange: true,
    })
    return queryResult
}

export function notificationsByID({
    variables,
    skip = false,
    onCompleted = noop,
    fetchPolicy = CACHE_AND_NETWORK,
    nextFetchPolicy = CACHE_FIRST,
}) {
    const queryResult = useQuery(queries.NOTIFICATIONS_BY_ID, {
        skip,
        variables,
        onCompleted,
        fetchPolicy,
        nextFetchPolicy,
        notifyOnNetworkStatusChange: true,
    })
    return queryResult
}

export function searchNotifications({
    variables,
    skip = false,
    onCompleted = noop,
    fetchPolicy = CACHE_AND_NETWORK,
    nextFetchPolicy = CACHE_FIRST,
}) {
    const queryResult = useQuery(queries.SEARCH_NOTIFICATIONS, {
        skip,
        variables,
        onCompleted,
        fetchPolicy,
        nextFetchPolicy,
        notifyOnNetworkStatusChange: true,
    })
    return queryResult
}

export function aggregateNotifications({
    variables,
    skip = false,
    onCompleted = noop,
    fetchPolicy = CACHE_AND_NETWORK,
    nextFetchPolicy = CACHE_FIRST,
}) {
    const queryResult = useQuery(queries.AGGREGATE_NOTIFICATIONS, {
        skip,
        variables,
        onCompleted,
        fetchPolicy,
        nextFetchPolicy,
        notifyOnNetworkStatusChange: true,
    })
    return queryResult
}

export function listTransactions({
    variables,
    skip = false,
    onCompleted = noop,
    fetchPolicy = CACHE_AND_NETWORK,
    nextFetchPolicy = CACHE_FIRST,
}) {
    const queryResult = useQuery(queries.LIST_TRANSACTIONS, {
        skip,
        variables,
        onCompleted,
        fetchPolicy,
        nextFetchPolicy,
        notifyOnNetworkStatusChange: true,
    })
    return queryResult
}

export const listTrainerApplications = ({
    variables,
    onCompleted = noop,
    fetchPolicy = CACHE_AND_NETWORK,
    nextFetchPolicy = CACHE_FIRST,
}) => {
    const queryResult = useQuery(queries.LIST_TRAINER_APPLICATION, {
        variables,
        onCompleted,
        fetchPolicy,
        nextFetchPolicy,
        notifyOnNetworkStatusChange: true,
    })
    return queryResult
}

//------------------- QUERIES --------------------

export const getInstructorAppNavigation = ({ index = 0, isTrainer, isOwner }) => {
    let nav = [...defaultInstructorApplicationPaths]
    if (isTrainer) nav = nav.concat(instructorApplicationPaths.trainer)
    if (isOwner) nav = nav.concat(instructorApplicationPaths.owner)
    nav.push(INSTRUCTOR_APPLICATION.REVIEW_AND_SUBMIT)
    const currentPage = nav[index]
    const prevPage = nav[index - 1]
    const nextPage = nav[index + 1]
    return { nav, currentPage, prevPage, nextPage }
}
export const getCreateClassNavigation = ({ index, isEditing }) => {
    let nav = [...createClassPath]
    const currentPage = nav[index]
    const prevPage = nav[index - 1]
    const nextIndex = isEditing ? nav.length - 1 : index + 1
    const nextPage = nav[nextIndex]
    return { nav, currentPage, prevPage, nextPage, nextIndex }
}

export const getCurrentUser = (
    {
        fetchPolicy = CACHE_AND_NETWORK,
        nextFetchPolicy = CACHE_FIRST,
        notifyOnNetworkStatusChange = false,
        onCompleted = noop,
    } = {
        fetchPolicy: CACHE_FIRST,
        nextFetchPolicy: CACHE_FIRST,
        notifyOnNetworkStatusChange: false,
        onCompleted: noop,
    },
) => {
    const {
        data: { getCurrentUserID },
    } = useQuery(queries.GET_CURRENT_USER_ID)
    const id = currentUserID()
    const skip = id == '' || getCurrentUserID == ''
    const { data, error, refetch } = useQuery(queries.GET_USER, {
        skip,
        variables: { id: !!getCurrentUserID ? getCurrentUserID : id },
        onCompleted,
        fetchPolicy,
        nextFetchPolicy,
        notifyOnNetworkStatusChange,
    })
    const { profile = {} } = data?.getUser ?? emptyObj
    return data ? { ...data.getUser, refetch, ...profile } : emptyObj
}

export const getUserByID = ({ userID = '', onCompleted, onError }) => {
    const skip = userID == ''
    const { data, loading } = useQuery(queries.GET_USER_PROFILE, {
        variables: { id: userID },
        skip,
        onCompleted,
        onError,
    })
    const obj = data?.getUser ?? emptyObj
    return { ...obj, loading }
}
export const getIsLoggedIn = () => {
    const {
        data: { isLoggedIn },
    } = useQuery(queries.IS_LOGGED_IN)
}

export const getExistingApplicationID = () => {
    const { application } = getCurrentUser() || emptyObj

    return application?.items[0]?.id || emptyString
}

export const getTrainerApplication = ({ trainerApplicationID = '', onCompleted = noop, onError = noop }) => {
    const skip = trainerApplicationID == ''
    const { data, loading, error } = useQuery(queries.GET_TRAINER_APPLICATION, {
        skip,
        variables: { id: trainerApplicationID },
        fetchPolicy: CACHE_FIRST,
        onCompleted,
    })
    const obj = data?.getTrainerApplication ?? emptyObj
    return { ...obj, loading }
}
export const getTrainerApplicationAsync = async ({ id = '' }) => {
    const { data } = await apolloClient.query({ query: queries.GET_TRAINER_APPLICATION, variables: { id } })
    return data?.getTrainerApplication ?? emptyObj
}

export const getExistingClassID = () => {
    const { classes } = getCurrentUser() || emptyObj
    return classes?.items[0]?.id || emptyString
}

export const getClass = ({
    classID = '',
    onCompleted,
    onError,
    fetchPolicy = CACHE_AND_NETWORK,
    nextFetchPolicy = CACHE_FIRST,
}) => {
    const skip = classID == ''
    const { data, loading, error, refetch } = useQuery(queries.GET_CLASS, {
        skip,
        variables: { id: classID },
        onCompleted,
        onError,
        fetchPolicy,
        nextFetchPolicy,
        notifyOnNetworkStatusChange: true,
    })
    const obj = data?.getClass ?? emptyObj
    return { ...obj, loading, error, refetch }
}

export const getClassForCard = ({ classID = '', onCompleted, onError, fetchPolicy = CACHE_FIRST }) => {
    const skip = classID == ''
    const { data, loading, error, refetch } = useQuery(queries.GET_CLASS_CARD, {
        skip,
        variables: { id: classID },
        onCompleted,
        onError,
        fetchPolicy,
    })
    const obj = data?.getClassCard ?? emptyObj
    return { ...obj, loading, error, refetch }
}

export const getStream = ({ streamID = '', onCompleted, onError }) => {
    const skip = streamID == ''
    const { data, loading, error, startPolling, stopPolling } = useQuery(queries.GET_STREAM, {
        skip,
        variables: { id: streamID },
        onCompleted,
        onError,
    })
    const { getStream: stream } = data ?? emptyObj
    return { stream, loading, error, startPolling, stopPolling }
}

export const getExistingFiles = ({ files = emptyArr, fileType }) => {
    let existingFiles
    if (files) {
        existingFiles = files
            ?.filter(file => file?.fileType == fileType && !file?._deleted)
            ?.map(item => ({
                name: item?.name,
                fileID: item?.id,
                _version: item?._version,
                key: `trainerApplicationDocuments/${item?.userID}/${item?.name}`,
            }))
    }
    return existingFiles || emptyArr
}

export const listClassesByUserID = ({ userID = '', filter = { userID: { eq: userID } } }) => {
    const skip = userID == ''
    const { data, loading, error } = useQuery(queries.LIST_CLASSES, {
        skip,
        variables: {
            filter,
            userID,
        },
    })
    const obj = data?.listClasses ?? emptyObj
    return { ...obj, loading, error }
}

export const listClassBookingsByClassID = ({ classID = '', onCompleted = noop }) => {
    const skip = classID == ''
    const { data, loading, error } = useQuery(queries.LIST_CLASS_BOOKINGS, {
        skip,
        variables: {
            filter: { classID: { eq: classID } },
            limit: 1000,
        },
        onCompleted,
    })
    const obj = data?.listClassBookings ?? emptyObj
    return { ...obj, loading, error }
}

export function getInstructorClasses({ userID = '', filter }) {
    const { items: classes, error } = listClassesByUserID({ userID, filter })
    const orderedClasses = _.orderBy(classes, [obj => new Date(obj.date)], ['asc']) || []
    return orderedClasses
}

export function getExistingReviews({ userID = '', instructorID = '', classID = '' }) {
    const skip = userID == '' || instructorID == '' || classID == ''
    const { parseQueryResult, filter } = queryHelpers.getExistingReviewsHelper({ userID, instructorID, classID })
    const queryResult = listReviewsMin({ variables: { filter, isAvatar: false }, skip })

    return parseQueryResult({ queryResult })
}

export function getPublishedClassesByInstructorID({
    instructorID = '',
    onCompleted = noop,
    isInstructorView = false,
    date,
}) {
    const { filter, dateFilter, parseQueryResult } = queryHelpers.getPublishedClassesByInstructorIDHelper({
        isInstructorView,
        date,
    })
    const skip = instructorID == ''
    const queryResult = classesByUserID({
        variables: { filter, date: dateFilter, userID: instructorID, sortDirection: 'ASC' },
        onCompleted,
        skip,
    })

    return parseQueryResult({ queryResult })
}

export function getClassesByClassBookings({
    classBookings,
    onCompleted = noop,
    fetchPolicy = CACHE_AND_NETWORK,
    nextFetchPolicy = CACHE_FIRST,
}) {
    const skip = classBookings?.items == emptyArr
    const { parseQueryResult, filter } = queryHelpers.getClassesByClassBookingsHelper({ classBookings })
    const queryResult = listClasses({ skip, variables: { filter }, onCompleted, fetchPolicy, nextFetchPolicy })

    return parseQueryResult({ queryResult })
}

export function getUnpublishedClasses({ instructorID = '', onCompleted = noop, getUnfinished = false }) {
    const skip = instructorID == '' || instructorID == undefined
    const { parseQueryResult, filter } = queryHelpers.getUnpublishedClassesHelper({ instructorID, getUnfinished })
    const queryResult = listClasses({ skip, variables: { filter }, onCompleted })

    return parseQueryResult({ queryResult })
}

export function getUnfinishedClasses({ instructorID = '', onCompleted = noop, getUnfinished = false }) {
    const skip = instructorID == '' || instructorID == undefined
    const { parseQueryResult, filter } = queryHelpers.getUnfinishedClassesHelper({ instructorID, getUnfinished })
    const queryResult = listClasses({ skip, variables: { filter }, onCompleted })

    return parseQueryResult({ queryResult })
}

export function getClassesToBook({ onCompleted = noop }) {
    const { parseQueryResult, filter } = queryHelpers.getClassesToBookHelper()
    const queryResult = listClasses({ variables: { filter }, onCompleted, fetchPolicy: CACHE_FIRST })

    return parseQueryResult({ queryResult })
}

export function getSuggestedClasses({ onCompleted = noop }) {
    const { parseQueryResult, filter } = queryHelpers.getSuggestedClassesHelper()
    const queryResult = listClasses({ variables: { filter }, onCompleted })
    return parseQueryResult({ queryResult })
}

export function getClassesToBookByDateTime({
    date,
    startTime,
    endTime,
    category,
    classStatusString = PUBLISHED,
    searchBody,
    onCompleted = noop,
    limit,
}) {
    const { parseQueryResult, filter, dateFilter } = queryHelpers.getClassesToBookHelperByDateTime({
        date,
        startTime,
        endTime,
        category,
        searchBody,
    })
    const queryResult = classByStatus({
        variables: { filter, date: dateFilter, limit, classStatusString, sortDirection: 'ASC' },
        onCompleted,
    })

    return parseQueryResult({ queryResult })
}

export function getClassBookingsByClassID({ classID = '', onCompleted = noop }) {
    const skip = classID == ''
    const { parseQueryResult, filter } = queryHelpers.getClassBookingsByClassIDHelper({ classID })
    const queryResult = listClassBookings({ variables: { filter, limit: 1000 }, onCompleted, skip })
    return parseQueryResult({ queryResult })
}

export function getInstructors({ onCompleted = noop, limit = 100, category }) {
    const { parseQueryResult, filter } = queryHelpers.getInstructorsHelper({ category })
    const queryResult = listUserByType({ variables: { limit, userType: INSTRUCTOR, filter }, onCompleted })

    return parseQueryResult({ queryResult })
}

export function getUsersByFavoriteInstructors({ favorites, isAvatar = false, onCompleted = noop }) {
    const skip = favorites?.items == emptyArr || favorites?.items === undefined || favorites === undefined
    const {
        parseQueryResult,
        filter,
        emptyResponse = false,
    } = queryHelpers.getUsersByFavoriteInstructorsHelper({ favorites })
    const queryResult = listUsersMin({ variables: { filter, isAvatar }, skip, onCompleted })
    return emptyResponse ? emptyResponse : parseQueryResult({ queryResult })
}

export function listUsersByClassBookings({ classBookings, isAvatar = false, onCompleted = noop }) {
    const skip = classBookings?.items == emptyArr
    const {
        parseQueryResult,
        filter,
        emptyResponse = false,
    } = queryHelpers.listUsersByClassBookingsHelper({ classBookings })
    const queryResult = listUsersMin({ variables: { filter, isAvatar }, skip, onCompleted })
    return emptyResponse ? emptyResponse : parseQueryResult({ queryResult })
}

export function getReviewsByInstructorID({ instructorID = '', onCompleted = noop, isAvatar = false }) {
    const skip = instructorID == ''
    const { parseQueryResult, filter } = queryHelpers.getReviewsByInstructorIDHelper({ instructorID })
    const queryResult = listReviewsMin({ skip, variables: { filter, isAvatar }, onCompleted })
    return parseQueryResult({ queryResult })
}

export function getTraineesByFavoriteInstructorID({ instructorID = '' }) {
    const skip = instructorID == ''
    const { parseQueryResult, filter } = queryHelpers.getTraineesByFavoriteInstructorIDHelper({ instructorID })
    const queryResult = listFavoriteInstructors({ variables: { filter, limit: 1000 }, skip })

    return parseQueryResult({ queryResult })
}

export function getUsersByUserIDArray({ userIDArray = emptyArr }) {
    const skip = userIDArray == emptyArr
    const { parseQueryResult, filter } = queryHelpers.getUsersByUserIDArrayHelper({ userIDArray })
    const queryResult = listUsers({ variables: { filter, limit: 1000 }, skip })

    return parseQueryResult({ queryResult })
}

export function getNotificationsByUserID({ userID = '', limit, onCompleted = noop }) {
    const skip = userID == ''
    const { parseQueryResult } = queryHelpers.getNotificationsByUserIDHelper()
    const queryResult = notificationsByID({ variables: { limit, userID, sortDirection: 'DESC' }, skip, onCompleted })

    return parseQueryResult({ queryResult })
}

export function getNumNewNotifications({ userID = '' }) {
    const skip = userID == ''
    const { parseQueryResult, filter, aggregates } = queryHelpers.getNumNewNotificationsHelper({ userID })
    const queryResult = aggregateNotifications({ variables: { aggregates, filter }, skip })

    return parseQueryResult({ queryResult })
}

export function getTransactionsByUserID({ userID = '' }) {
    const skip = userID == ''
    const { parseQueryResult, filter } = queryHelpers.getTransactionsByUserIDHelper({ userID })
    const queryResult = listTransactions({ variables: { filter, limit: 1000 }, skip })

    return parseQueryResult({ queryResult })
}

export function getApplicationsToReview({ onCompleted = noop }) {
    const { parseQueryResult, filter } = queryHelpers.getApplicationsToReviewHelper()
    const queryResult = listTrainerApplications({ variables: { filter }, onCompleted })

    return parseQueryResult({ queryResult })
}
