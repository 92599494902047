import React, { useEffect, useRef } from 'react'
import { View, FlatList, TouchableOpacity } from 'react-native'
import useState from 'react-usestateref'
import { AntDesign } from '@expo/vector-icons'
import { RowContainer } from 'commons/containers'
import styled from 'styled-components/native'
import { Heading } from 'typography'

const noop = () => {}
export const FlatlistCarousel = ({ data, renderItem, headingText, hasHeader = true, itemHeight, ...rest }) => {
    const flatlistRef = useRef(null)
    const [index, setIndex, indexRef] = useState(0)
    const [endReached, setEndReached, endReachedRef] = useState(false)
    const [lastItemID, setLastItemID, lastItemIDRef] = useState('')

    const scrollToIndex = () => flatlistRef.current.scrollToIndex({ index: indexRef.current })

    const handleNext = () => {
        const adjustedIndex = endReached ? 0 : indexRef.current + 1
        setIndex(adjustedIndex)
        scrollToIndex()
    }

    const handlePrevious = () => {
        const adjustedIndex = indexRef.current - 1 < 0 ? data.length - 1 : indexRef.current - 1
        setIndex(adjustedIndex)
        scrollToIndex()
    }

    const onViewableItemsChanged = useRef(({ viewableItems, changed }) => {
        const lastViewableItemID = [...viewableItems].pop()?.key
        const isEndReached = lastViewableItemID === lastItemIDRef.current
        setEndReached(isEndReached)
        if (endReachedRef.current) {
            setIndex(indexRef.current - viewableItems.length + 1)
        }
    })

    useEffect(() => {
        if (data?.length > 0) {
            setLastItemID([...data].pop().id)
        }
    }, [data])

    return (
        <FlatlistContainer>
            {!!hasHeader && (
                <HeaderContainer>
                    <Heading paddingBottom={50}>{headingText}</Heading>
                </HeaderContainer>
            )}
            {!!data?.length && (
                <NextButton itemHeight={itemHeight} onPress={handleNext}>
                    <AntDesign name="arrowright" size={24} color="black" />
                </NextButton>
            )}
            <CarouselFlatlist
                ref={flatlistRef}
                data={data}
                renderItem={renderItem}
                onViewableItemsChanged={onViewableItemsChanged.current}
                viewabilityConfig={{ itemVisiblePercentThreshold: 75 }}
                showsHorizontalScrollIndicator={false}
                removeClippedSubviews={true}
                horizontal
                keyExtractor={item => item.id}
                {...rest}
            />
            {!!data?.length && (
                <PrevButton itemHeight={itemHeight} onPress={handlePrevious}>
                    <AntDesign name="arrowleft" size={24} color="black" />
                </PrevButton>
            )}
        </FlatlistContainer>
    )
}

const HeaderContainer = styled(RowContainer)`
    justify-content: center;
    padding-top: 50px;
`
const CarouselFlatlist = styled(FlatList)``
const FlatlistContainer = styled(View)`
    justify-content: center;
`
const NextButton = styled(TouchableOpacity)`
    position: absolute;
    z-index: 5;
    width: 54px;
    height: 54px;
    background-color: white;
    border-radius: 28px;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    right: -67.5px;
    bottom: ${props => (props.itemHeight ? `${props.itemHeight / 2}px` : undefined)};
`
const PrevButton = styled(NextButton)`
    align-self: flex-start;
    left: -67.5px;
`
export default FlatlistCarousel
