import React, { useMemo } from 'react'
import { View, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import colors from 'colors'
import { HeaderBarText } from 'typography'
import { linearGradientConfig } from '@constants'
import LinearGradientView from '../commons/components/LinearGradientView'

const whiteGradient = ['white']

export const NavigationItem = ({ navigation, title, isDrawer, onPress, screenName, activeScreenName = '' }) => {
    const isFocused = screenName == activeScreenName
    const textColor = !isDrawer ? colors.subTextGray : isFocused ? 'white' : colors.subTextGray
    const style = useMemo(
        () => ({
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: isDrawer ? 'flex-start' : 'center',
            paddingHorizontal: 10,
        }),
        [isDrawer],
    )

    // need to forcibly unmount LinearGradient because otherwise colors won't update
    if (isDrawer && isFocused) {
        return (
            <NavigationItemContainer onPress={onPress} isDrawer={isDrawer}>
                <LinearGradientView
                    colors={linearGradientConfig.colors}
                    start={linearGradientConfig.start}
                    end={linearGradientConfig.end}
                    style={style}
                >
                    <HeaderBarText color={title == 'Explore' ? colors.homebodyTurquoise : textColor}>
                        {title}
                    </HeaderBarText>
                </LinearGradientView>
            </NavigationItemContainer>
        )
    }
    return (
        <NavigationItemContainer onPress={onPress} isDrawer={isDrawer}>
            <View style={style}>
                <HeaderBarText color={title == 'Explore' ? colors.homebodyTurquoise : textColor}>{title}</HeaderBarText>
            </View>
        </NavigationItemContainer>
    )
}
const NavigationItemContainer = styled(TouchableOpacity)`
    justify-content: center;
    align-items: center;
    height: 60px;
    width: ${({ isDrawer }) => (isDrawer ? '100%' : '80px')};
    margin-vertical: 5px;
    border-radius: 12px;
    overflow: hidden;
`

export default NavigationItem
