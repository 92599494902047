import {
    getTodaysDateInAWSFormat,
    getUniqueArrayItems,
    getCurrentHour,
    removeDeletedRecords,
    isDateToday,
    orderByDateAndTime,
    orderByCreatedAt,
} from 'helpers'
import { USER_TYPES, CLASS_STATUS, APPLICATION_STATUS } from '@constants'
const { UNFINISHED, UNPUBLISHED, PUBLISHED, COMPLETED, CANCELLED, EXPIRED, REFUNDED, PAID_OUT } = CLASS_STATUS
const { PENDING } = APPLICATION_STATUS
const emptyArray = []
const emptyObj = {}
const todaysDate = getTodaysDateInAWSFormat()

function getOrFilterFromArray({ fieldName, array }) {
    const filterMembers = array?.map(item => JSON.parse(`{"${fieldName}":{"eq":"${item}"}}`))
    const filter = { or: filterMembers }
    return filter
}

export function getTraineesByFavoriteInstructorIDHelper({ instructorID }) {
    const filter = { instructorID: { eq: instructorID } }

    const parseQueryResult = ({ queryResult, shouldLogResponse = false }) => {
        const { data, loading, error, refetch } = queryResult
        if (shouldLogResponse) console.log('data: ', data, '    loading: ', loading, '    error: ', error)
        if (data) {
            const { listFavoriteInstructors: { items: trainees = emptyArray } = emptyObj } = data ?? emptyObj
            return { trainees, loading, error, refetch }
        } else {
            return { trainees: emptyArray, loading, error, refetch }
        }
    }

    return { filter, parseQueryResult }
}

export function getUsersByUserIDArrayHelper({ userIDArray }) {
    const traineeIDs = userIDArray?.map(user => user?.userID)
    const orTraineeIDs = getOrFilterFromArray({ fieldName: 'id', array: traineeIDs })
    const filter = orTraineeIDs

    const parseQueryResult = ({ queryResult, shouldLogResponse = false }) => {
        const { data, loading, error, refetch } = queryResult
        if (shouldLogResponse) console.log('data: ', data, '    loading: ', loading, '    error: ', error)
        if (data) {
            const { listUsers: { items: users = emptyArray } = emptyObj } = data ?? emptyObj
            return { users, loading, error, refetch }
        } else {
            return { users: emptyArray, loading, error, refetch }
        }
    }

    return { filter, parseQueryResult }
}

export function getPublishedClassesByInstructorIDHelper({ isInstructorView = false, date }) {
    let filter
    let dateFilter = { ge: todaysDate }

    if (isInstructorView) {
        filter = {
            classStatus: { eq: PUBLISHED },
        }
    } else {
        filter = {
            classStatus: { eq: PUBLISHED },
        }
        if (date) {
            dateFilter = { eq: date }
        }
    }

    const parseQueryResult = ({ queryResult, shouldLogResponse = false }) => {
        const { data, loading, error, refetch, fetchMore } = queryResult
        if (shouldLogResponse) console.log('data: ', data, '    loading: ', loading, '    error: ', error)
        if (data) {
            const { classByUserID: { items: classes = emptyArray, nextToken = null } = emptyObj } = data ?? emptyObj
            const orderedClasses = orderByDateAndTime(classes)
            return { classes: orderedClasses, loading, error, refetch, fetchMore, nextToken }
        } else {
            return { classes: emptyArray, loading, error, refetch }
        }
    }

    return { filter, dateFilter, parseQueryResult }
}

export function getUsersByFavoriteInstructorsHelper({ favorites }) {
    const favoriteInstructors = removeDeletedRecords(favorites?.items)
    const favoriteInstructorIDs = favoriteInstructors?.map(favoriteInstructor => favoriteInstructor?.instructorID)
    const orInstructorIDs = getOrFilterFromArray({ fieldName: 'id', array: favoriteInstructorIDs })
    const filter = orInstructorIDs
    const noFavoriteInstructors = filter?.or?.length == 0

    const parseQueryResult = ({ queryResult, shouldLogResponse = false }) => {
        const { data, loading, error, refetch, fetchMore } = queryResult
        if (shouldLogResponse) console.log('data: ', data, '    loading: ', loading, '    error: ', error)
        if (data) {
            const { listUsers: { items: favoriteInstructors = emptyArray, nextToken = null } = emptyObj } =
                data ?? emptyObj
            return { favoriteInstructors, loading, error, refetch, fetchMore, nextToken }
        } else {
            return { favoriteInstructors: emptyArray, loading, error, refetch }
        }
    }

    return {
        filter,
        parseQueryResult,
        emptyResponse: noFavoriteInstructors ? { favoriteInstructors: emptyArray, loading: false } : false,
    }
}

export function listUsersByClassBookingsHelper({ classBookings }) {
    const bookings = classBookings?.items ? classBookings?.items : classBookings
    const nonDeletedClassBookings = bookings?.filter(booking => !booking?._deleted)
    const bookedClassUserIDs = nonDeletedClassBookings?.map(classBooking => classBooking?.userID)
    const orUserIDs = getOrFilterFromArray({ fieldName: 'id', array: bookedClassUserIDs })
    const filter = {
        ...orUserIDs,
    }
    const noUsers = filter?.or?.length == 0 || filter?.or == undefined

    const parseQueryResult = ({ queryResult, shouldLogResponse = false }) => {
        const { data, loading, error, refetch, fetchMore } = queryResult
        if (shouldLogResponse) console.log('data: ', data, '    loading: ', loading, '    error: ', error)
        if (data) {
            const { listUsers: { items: bookedUsers = emptyArray, nextToken = '' } = emptyObj } = data ?? emptyObj
            return { bookedUsers, loading, error, refetch, nextToken, fetchMore }
        } else {
            return { bookedUsers: emptyArray, loading, error, refetch }
        }
    }

    return { filter, parseQueryResult, emptyResponse: noUsers ? { bookedUsers: emptyArray, loading: false } : false }
}

export function getClassesByClassBookingsHelper({ classBookings }) {
    const nonDeletedClassBookings = classBookings?.items?.filter(booking => !booking._deleted)
    const uniqueClassBookings = getUniqueArrayItems(nonDeletedClassBookings?.map(booking => booking.classID))
    const orClassBookingIDs = getOrFilterFromArray({ fieldName: 'id', array: uniqueClassBookings })
    let filter = {
        date: { ge: todaysDate },
        and: {
            classStatus: { ne: COMPLETED },
            and: {
                classStatus: { ne: CANCELLED },
                and: {
                    classStatus: { ne: EXPIRED },
                    and: {
                        classStatus: { ne: REFUNDED },
                        and: {
                            classStatus: { ne: PAID_OUT },
                            and: {
                                classStatus: { ne: UNFINISHED },
                                and: {
                                    classStatus: { ne: UNPUBLISHED },
                                },
                            },
                        },
                    },
                },
            },
        },
        ...orClassBookingIDs,
    }

    const parseQueryResult = ({ queryResult, shouldLogResponse = false }) => {
        const { data, loading, error, refetch, fetchMore } = queryResult
        if (shouldLogResponse) console.log('data: ', data, '    loading: ', loading, '    error: ', error)
        if (data) {
            const { listClasses: { items: classes = emptyArray, nextToken = '' } = emptyObj } = data ?? emptyObj
            const orderedClasses = orderByDateAndTime(classes)
            return { classes: orderedClasses, loading, error, refetch, nextToken, fetchMore }
        } else {
            return { classes: emptyArray, loading, error, refetch }
        }
    }

    return { filter, parseQueryResult }
}

export function getUnfinishedClassesHelper({ instructorID }) {
    const filter = { userID: { eq: instructorID }, classStatus: { eq: UNFINISHED } }

    const parseQueryResult = ({ queryResult, shouldLogResponse = false }) => {
        const { data, loading, error, refetch, fetchMore } = queryResult
        if (shouldLogResponse) console.log('data: ', data, '    loading: ', loading, '    error: ', error)
        if (data) {
            const { listClasses: { items: classes = emptyArray, nextToken = null } = emptyObj } = data ?? emptyObj

            const unfinishedClasses = removeDeletedRecords(classes)
            const orderedClasses = orderByDateAndTime(unfinishedClasses)
            return { unfinishedClasses: orderedClasses, loading, error, refetch, fetchMore, nextToken }
        } else {
            return { unfinishedClasses: emptyArray, loading, error, refetch }
        }
    }

    return { filter, parseQueryResult }
}

export function getUnpublishedClassesHelper({ instructorID }) {
    const filter = { userID: { eq: instructorID }, classStatus: { eq: UNPUBLISHED } }

    const parseQueryResult = ({ queryResult, shouldLogResponse = false }) => {
        const { data, loading, error, refetch, fetchMore } = queryResult
        if (shouldLogResponse) console.log('data: ', data, '    loading: ', loading, '    error: ', error)
        if (data) {
            const { listClasses: { items: classes = emptyArray, nextToken = null } = emptyObj } = data ?? emptyObj
            const unpublishedClasses = removeDeletedRecords(classes)
            const orderedClasses = orderByDateAndTime(unpublishedClasses)
            return { unpublishedClasses: orderedClasses, loading, error, refetch, fetchMore, nextToken }
        } else {
            return { unpublishedClasses: emptyArray, loading, error, refetch }
        }
    }

    return { filter, parseQueryResult }
}

export function getClassesToBookHelper() {
    const currentHour = getCurrentHour({ shouldAddPrefix: true })
    const filter = {
        or: [{ date: { eq: todaysDate }, and: { startTime: { ge: currentHour } } }, { date: { gt: todaysDate } }],
        and: {
            classStatus: { eq: PUBLISHED },
        },
    }

    const parseQueryResult = ({ queryResult, shouldLogResponse = false }) => {
        const { data, loading, error, refetch, fetchMore } = queryResult
        if (shouldLogResponse) console.log('data: ', data, '    loading: ', loading, '    error: ', error)
        if (data) {
            const { listClasses: { items: classesToBook = emptyArray, nextToken = null } = emptyObj } = data ?? emptyObj
            const orderedClasses = orderByDateAndTime(classesToBook)
            return { classesToBook: orderedClasses, loading, error, refetch, fetchMore, nextToken }
        } else {
            return { classesToBook: emptyArray, loading, error, refetch }
        }
    }
    return { filter, parseQueryResult }
}

export function getPublishedClassDateFilter({ isSearchSyntax = false } = {}) {
    const currentHour = getCurrentHour({ shouldAddPrefix: true })
    const filter = {
        or: isSearchSyntax
            ? /*
                Note: when using queries generated by adding the @searchable directive, filter syntax differs. 
                "ge" will throw a syntax error, using "gte" instead. Additionaly, "eq" is non-functional and "match" 
                is to be used in its place.
            */
              [{ date: { match: todaysDate }, and: { startTime: { gte: currentHour } } }, { date: { gt: todaysDate } }]
            : [{ date: { eq: todaysDate }, and: { startTime: { ge: currentHour } } }, { date: { gt: todaysDate } }],
    }
    return filter
}

export function getClassesToBookHelperByDateTime({ date, startTime, endTime, category, searchBody }) {
    const isSelectedDateToday = date ? isDateToday(date) : false

    let filter = { and: { startTime: { ge: startTime, le: endTime } } }

    if (category) {
        filter = {
            ...filter,
            and: {
                ...filter.and,
                categoryFilter: { contains: category },
            },
        }
    }

    if (searchBody) {
        filter = {
            ...filter,
            and: {
                ...filter.and,
                name: { contains: searchBody },
            },
        }
    }

    let dateFilter = { ge: todaysDate }

    if (date) {
        if (isSelectedDateToday) {
            dateFilter = { eq: todaysDate }
        } else {
            dateFilter = { eq: date }
        }
    }

    const parseQueryResult = ({ queryResult, shouldLogResponse = false }) => {
        const { data, loading, error, refetch, fetchMore } = queryResult
        if (shouldLogResponse) console.log('data: ', data, '    loading: ', loading, '    error: ', error)
        if (data) {
            const { classByClassStatus: { items: classesToBook = emptyArray, nextToken = null } = emptyObj } =
                data ?? emptyObj
            const orderedClasses = orderByDateAndTime(classesToBook)
            return { classesToBook: orderedClasses, loading, error, refetch, fetchMore, nextToken }
        } else {
            return { classesToBook: emptyArray, loading, error, refetch }
        }
    }
    return { filter, dateFilter, parseQueryResult }
}

export function getClassBookingsByClassIDHelper({ classID }) {
    const filter = { classID: { eq: classID } }
    const parseQueryResult = ({ queryResult, shouldLogResponse = false }) => {
        const { data, loading, error, refetch, fetchMore } = queryResult
        if (shouldLogResponse) console.log('data: ', data, '    loading: ', loading, '    error: ', error)
        if (data) {
            const { listClassBookings: { items: classBookingsWithDeleted = emptyArray, nextToken = null } = emptyObj } =
                data ?? emptyObj
            const classBookings = removeDeletedRecords(classBookingsWithDeleted)
            return { classBookings, loading, error, refetch, nextToken, fetchMore }
        } else {
            return { classBooking: emptyObj, loading, error, refetch }
        }
    }

    return { filter, parseQueryResult }
}

export function getInstructorsHelper({ category }) {
    let filter = emptyObj

    if (category) {
        filter = { categoryFilter: { contains: category } }
    }
    const parseQueryResult = ({ queryResult, shouldLogResponse = false }) => {
        const { data, loading, error, refetch, fetchMore } = queryResult
        if (shouldLogResponse) console.log('data: ', data, '    loading: ', loading, '    error: ', error)
        if (data) {
            const { userByUserType: { items: instructors = emptyArray, nextToken } = emptyObj } = data ?? emptyObj
            return { instructors, loading, error, refetch, fetchMore, nextToken }
        } else {
            return { instructors: emptyArray, loading, error, refetch }
        }
    }

    return { parseQueryResult, filter }
}

export function getReviewsByInstructorIDHelper({ instructorID }) {
    const filter = { instructorID: { eq: instructorID } }

    const parseQueryResult = ({ queryResult, shouldLogResponse = false }) => {
        const { data, loading, error, refetch, fetchMore } = queryResult
        if (shouldLogResponse) console.log('data: ', data, '    loading: ', loading, '    error: ', error)
        if (data) {
            const { listReviews: { items: instructorReviews = emptyArray, nextToken = null } = emptyObj } =
                data ?? emptyObj
            return { instructorReviews, loading, error, refetch, fetchMore, nextToken }
        } else {
            return { emptyObj, loading, error, refetch }
        }
    }

    return { filter, parseQueryResult }
}

export function getExistingReviewsHelper({ userID, instructorID, classID }) {
    const filter = { userID: { eq: userID }, instructorID: { eq: instructorID }, classID: { eq: classID } }

    const parseQueryResult = ({ queryResult, shouldLogResponse = false }) => {
        const { data, loading, error, refetch } = queryResult
        if (shouldLogResponse) console.log('data: ', data, '    loading: ', loading, '    error: ', error)
        if (data) {
            const { listReviews: { items: reviews = emptyArray } = emptyObj } = data ?? emptyObj
            const existingReview = reviews?.[0] || emptyObj
            return { existingReview, loading, error, refetch }
        } else {
            return { existingReview: emptyObj, loading, error, refetch }
        }
    }

    return { filter, parseQueryResult }
}
export function getNotificationsByUserIDHelper() {
    const parseQueryResult = ({ queryResult, shouldLogResponse = false }) => {
        const { data, loading, error, refetch, fetchMore } = queryResult
        if (shouldLogResponse) console.log('data: ', data, '    loading: ', loading, '    error: ', error)
        if (data) {
            const { notificationsByUserID: { items: notificationsBeforeSort = emptyArray, nextToken } = emptyObj } =
                data ?? emptyObj
            const notifications = orderByCreatedAt(notificationsBeforeSort)
            return { notifications, loading, error, refetch, fetchMore, nextToken }
        } else {
            return { emptyObj, loading, error, refetch }
        }
    }

    return { parseQueryResult }
}

function getAggregates({ aggregateType, aggregateField, name }) {
    const typeEnum = {
        type: aggregateType,
    }
    const fieldEnum = {
        field: aggregateField,
    }
    const { type } = typeEnum
    const { field } = fieldEnum

    return { type, field, name }
}

export function getNumNewNotificationsHelper({ userID }) {
    const filter = { userID: { eq: userID } }
    const aggregates = getAggregates({
        aggregateType: 'sum',
        aggregateField: 'isNew',
        name: 'numNewNotifications',
    })

    const parseQueryResult = ({ queryResult, shouldLogResponse = false }) => {
        const { data = emptyObj, loading, error, refetch } = queryResult
        if (shouldLogResponse) console.log('data: ', data, '    loading: ', loading, '    error: ', error)
        if (data) {
            const { searchNotifications: { aggregateItems = emptyArray } = emptyObj } = data ?? emptyObj
            const { result: { value: numNewNotifications = 0 } = emptyObj } = aggregateItems?.[0] ?? emptyObj
            return { numNewNotifications, loading, error, refetch }
        } else {
            return { numNewNotifications: 0, loading, error, refetch }
        }
    }

    return { filter, aggregates, parseQueryResult }
}

export function getSuggestedClassesHelper() {
    const currentHour = getCurrentHour({ shouldAddPrefix: true })
    const filter = {
        or: [{ date: { eq: todaysDate }, startTime: { ge: currentHour } }, { date: { gt: todaysDate } }],
        and: {
            classStatus: { eq: PUBLISHED },
        },
    }

    const parseQueryResult = ({ queryResult, shouldLogResponse = false }) => {
        const { data, loading, error, refetch, fetchMore } = queryResult
        if (shouldLogResponse) console.log('data: ', data, '    loading: ', loading, '    error: ', error)
        if (data) {
            const { listClasses: { items: suggestedClasses = emptyArray, nextToken = null } = emptyObj } =
                data ?? emptyObj
            return { suggestedClasses, loading, error, refetch, fetchMore, nextToken }
        } else {
            return { suggestedClasses: emptyArray, loading, error, refetch }
        }
    }
    return { filter, parseQueryResult }
}

export function getTransactionsByUserIDHelper({ userID }) {
    const filter = { userID: { eq: userID } }

    const parseQueryResult = ({ queryResult, shouldLogResponse = false }) => {
        const { data, loading, error, refetch } = queryResult
        if (shouldLogResponse) console.log('data: ', data, '    loading: ', loading, '    error: ', error)
        if (data) {
            const { listTransactions: { items: userTransactions = emptyArray } = emptyObj } = data ?? emptyObj
            return { userTransactions, loading, error, refetch }
        } else {
            return { userTransactions: emptyObj, loading, error, refetch }
        }
    }

    return { filter, parseQueryResult }
}

export function getApplicationsToReviewHelper() {
    const filter = { approved: { eq: PENDING }, submitted: { eq: true } }

    const parseQueryResult = ({ queryResult, shouldLogResponse = false }) => {
        const { data, loading, error, refetch, fetchMore } = queryResult
        if (shouldLogResponse || true) console.log('data: ', data, '    loading: ', loading, '    error: ', error)
        if (data) {
            const {
                listTrainerApplications: { items: trainerApplications = emptyArray, nextToken = null } = emptyObj,
            } = data ?? emptyObj
            return { trainerApplications, loading, error, refetch, fetchMore, nextToken }
        } else {
            return { trainerApplications: emptyObj, loading, error, refetch }
        }
    }

    return { filter, parseQueryResult }
}
