import { useEffect } from 'react'
import useAgoraClient from 'hooks/useAgoraClient'

export const useHostAgoraStream = reactiveStreamProps => {
    const { useMicrophoneAndCameraTracks } = useAgoraClient()
    const { ready: localTracksReady, tracks: localTracks } = useMicrophoneAndCameraTracks()
    const { initializeStream, streamState, streamStateSetters, hostEventEmitters } = reactiveStreamProps
    const { messageFromAudience, messageFromAudienceMember, sendHostUID, requestTraineeInfo } = hostEventEmitters
    const { setTracks, setTracksReady } = streamStateSetters
    const { streamClient, messagesClient, messagesChannel, streamReady, tracks, tracksReady } = streamState

    async function listenForEvents() {
        streamClient?.on('user-published', (user, mediaType) => {
            if (__DEV__) {
                console.log('user: ', user)
                console.log('mediaType: ', mediaType)
            }
        })
        streamClient?.on('user-joined', user => {
            if (__DEV__) {
                console.log('user joined', user)
            }
        })

        streamClient?.on('user-left', user => {
            if (__DEV__) {
                console.log('leaving', user)
            }
        })
    }

    async function listenForChannelMessages() {
        messagesChannel?.on('ChannelMessage', async message => {
            messageFromAudience(message)
        })
        messagesChannel?.on('MemberJoined', async memberId => {
            sendHostUID(memberId)
            requestTraineeInfo(memberId)
        })
    }

    async function listenForPeerMessages() {
        messagesClient?.on('MessageFromPeer', async message => {
            messageFromAudienceMember(message)
        })
    }

    useEffect(() => {
        if (localTracksReady) {
            setTracksReady(localTracksReady)
            setTracks(localTracks)
        }
    }, [localTracksReady])

    useEffect(() => {
        if (tracks && tracksReady) {
            initializeStream()
        }
    }, [tracks, tracksReady, streamReady])

    useEffect(() => {
        listenForEvents()
    }, [streamClient])

    useEffect(() => {
        listenForPeerMessages()
    }, [messagesClient])

    useEffect(() => {
        listenForChannelMessages()
    }, [messagesChannel])
}

export default useHostAgoraStream
