import React, { useState, useEffect, useMemo } from 'react'
import { Text, View, TouchableOpacity, ScrollView } from 'react-native'
import CheckBox from '@react-native-community/checkbox'
import styled from 'styled-components/native'
import AddButton from 'components/AddButton'
import { useReactiveVar } from '@apollo/client'
import { createClassForm } from 'apollo/cache'
import { StyledTextInput } from 'components/InputField'
import colors from 'colors'
import VectorIcon from 'components/VectorIcon'
import IntervalModal from 'components/instructor/IntervalModal'
import { generateID, calculateClassTimeRemaining, toTimeString } from 'helpers'
import { AppFormContainer, ScreenContainer, ContentContainer } from 'commons/containers'
import IconCheckBox from 'commons/icons/IconCheckBox'
const intervalTypes = [
    { value: 'WARMUP', title: 'Warm Up' },
    { value: 'WORKOUT', title: 'Work Out' },
    { value: 'REST', title: 'Rest' },
    { value: 'COOLDOWN', title: 'Cool Down' },
]

export const Intervals = ({ intervals, setIntervals, checkedIntervals, setCheckedIntervals, reactiveFormProps }) => {
    const {
        isValid,
        errors: {
            intervals: { showError: showIntervalsError, error: intervalsError } = { showError: false, error: '' },
            duration: { showError: showDurationError, error: durationError } = { showError: false, error: '' },
        },
    } = reactiveFormProps

    const form = useReactiveVar(createClassForm)

    const existingTime = intervals?.length
        ? intervals?.reduce((acc, interval) => {
              return calculateClassTimeRemaining({
                  timeType: interval.timeType,
                  duration: interval.duration,
                  timeRemaining: acc,
              })
          }, form?.duration * 60)
        : form?.duration * 60

    const [timeRemaining, setTimeRemaining] = useState(existingTime)
    const handleNewInterval = value => {
        setIntervals([
            ...intervals,
            { id: generateID(), name: '', duration: 10, timeType: 'sec', type: value, existing: false },
        ])
    }

    useEffect(() => {
        reactiveFormProps.getFieldError({ fieldName: 'intervals' })
    }, [intervals])

    useEffect(() => {
        reactiveFormProps.getFieldError({ fieldName: 'duration' })
    }, [timeRemaining])

    const error = intervalsError ? intervalsError : durationError

    return (
        <>
            <AddContainerGroup>
                {intervalTypes.map((intervalType, i) => {
                    const { title, value } = intervalType
                    return (
                        <AddContainer key={i}>
                            <AddButton
                                value={value}
                                onPress={() => handleNewInterval(value)}
                                color={colors.blueContinue}
                            />
                            <AddText>{title}</AddText>
                        </AddContainer>
                    )
                })}
                <RemainingTimeContainer>
                    <RemainingTimeText>Remaining Time: {toTimeString(timeRemaining)}</RemainingTimeText>
                </RemainingTimeContainer>
            </AddContainerGroup>
            {!isValid && <Text style={{ color: 'red' }}>{error}</Text>}
            <IntervalsContainer>
                <TableRow>
                    <TableHeader style={{ paddingLeft: 50 }} width="400px">
                        <TableRowText>Interval</TableRowText>
                    </TableHeader>
                    <TableHeader width="120px">
                        <TableRowText>Duration</TableRowText>
                    </TableHeader>
                    <TableHeader width="120px">
                        <TableRowText>Sec/Min</TableRowText>
                    </TableHeader>
                    <TableHeader width="120px">
                        <TableRowText>Type</TableRowText>
                    </TableHeader>
                    <TableHeader style={{ paddingRight: 45 }} justifyContent="flex-end" width="200px">
                        <TableRowText>Edit/Delete</TableRowText>
                    </TableHeader>
                </TableRow>
                <IntervalScrollContainer>
                    {intervals?.map((interval, index) => (
                        <IntervalRow
                            key={interval?.id}
                            currentInterval={interval}
                            intervals={intervals}
                            checkedIntervals={checkedIntervals}
                            index={index}
                            setIntervals={setIntervals}
                            setCheckedIntervals={setCheckedIntervals}
                            timeRemaining={timeRemaining}
                            setTimeRemaining={setTimeRemaining}
                        />
                    ))}
                </IntervalScrollContainer>
            </IntervalsContainer>
        </>
    )
}

const IntervalRow = ({
    currentInterval,
    intervals,
    index,
    setIntervals,
    checkedIntervals,
    setCheckedIntervals,
    setTimeRemaining,
    timeRemaining,
}) => {
    const { id: currentIntervalID, name, duration, timeType, type, existing } = currentInterval
    const [modalVisible, setModalVisible] = useState(!existing)
    const [isEditing, setIsEditing] = useState(false)
    const [isChecked, setIsChecked] = useState(false)

    const setExisting = value => {
        setIntervals(
            intervals.map(interval =>
                interval.id === currentIntervalID ? { ...interval, existing: value } : interval,
            ),
        )
    }
    const closeModal = () => {
        if (isEditing) {
            setIsEditing(false)
            setExisting(true)
        }
        setModalVisible(false)
    }
    const handleDelete = () => {
        if (!isChecked) {
            const newTime = calculateClassTimeRemaining({ timeType, duration, timeRemaining, addTime: true })
            setTimeRemaining(newTime)
            setIntervals(intervals?.filter(interval => interval.id != currentIntervalID))
        } else {
            const { newIntervals, timeAdded } = intervals?.reduce(
                ({ newIntervals, timeAdded }, interval) => {
                    if (checkedIntervals?.includes(interval.id)) {
                        timeAdded +=
                            interval.timeType == 'min' ? parseInt(interval.duration) * 60 : parseInt(interval.duration)
                        return { newIntervals, timeAdded }
                    }
                    newIntervals?.push(interval)
                    return { newIntervals, timeAdded }
                },
                { newIntervals: [], timeAdded: 0 },
            )
            setIntervals(newIntervals)
            setTimeRemaining(parseInt(timeRemaining, 10) + parseInt(timeAdded, 10))
            setCheckedIntervals([])
        }
    }
    const handleEdit = () => {
        const newTime = calculateClassTimeRemaining({ timeType, duration, timeRemaining, addTime: true })
        setTimeRemaining(newTime)
        setIsEditing(true)
        setExisting(false)
    }
    const handleChecked = () => {
        setIsChecked(!isChecked)
        setCheckedIntervals(
            isChecked
                ? checkedIntervals?.filter(interval => interval !== currentIntervalID)
                : [...checkedIntervals, currentIntervalID],
        )
    }

    const { title } = intervalTypes?.filter(obj => obj.value === type)?.[0] || ''

    useEffect(() => {
        setModalVisible(!existing)
    }, [existing])
    return (
        <>
            {!modalVisible && (
                <TableContainer key={currentIntervalID}>
                    <TableCell width="50px" justifyContent="center">
                        <IconCheckBox
                            style={{ alignSelf: 'center', marginLeft: 5, marginTop: 5 }}
                            isSquare
                            checked={isChecked}
                            onPress={handleChecked}
                            color={{ unchecked: colors.starGray, checked: colors.homebodyGreen }}
                            size={16}
                        />
                    </TableCell>
                    <TableCell width="350px">
                        <TableCellText>{name}</TableCellText>
                    </TableCell>
                    <TableCell width="120px">
                        <TableCellText>{duration}</TableCellText>
                    </TableCell>
                    <TableCell width="120px">
                        <TableCellText>{timeType}</TableCellText>
                    </TableCell>
                    <TableCell width="120px">
                        <TableCellText>{title}</TableCellText>
                    </TableCell>
                    <ButtonContainer>
                        <EditButton onPress={handleEdit}>
                            <VectorIcon family="SimpleLineIcons" name="pencil" size={13} color="black" />
                        </EditButton>
                        <DeleteButton onPress={handleDelete}>
                            <ButtonText>Delete</ButtonText>
                        </DeleteButton>
                    </ButtonContainer>
                </TableContainer>
            )}
            <IntervalModal
                title={title}
                visible={modalVisible}
                currentInterval={currentInterval}
                intervals={intervals}
                close={closeModal}
                isEditing={isEditing}
                setIntervals={setIntervals}
                index={index}
                timeRemaining={timeRemaining}
                setTimeRemaining={setTimeRemaining}
            />
        </>
    )
}

export const IntervalsContainer = styled(View)`
    flex: 1;
    min-height: 300px;
`

export const IntervalScrollContainer = styled(ScrollView)``

export const RemainingTimeContainer = styled(View)`
    width: 200px;
`
export const RemainingTimeText = styled(Text)`
    font-size: 14px;
    font-weight: 600;
    color: ${colors.headerTextGray};
    margin-left: 10px;
`

export const TableRow = styled(View)`
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-vertical: 15px;
    border-bottom-width: 1px;
    border-bottom-color: ${colors.deactiveLightmode};
`

export const TableContainer = styled(View)`
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border-top-color: #e4e4e4;
    border-top-width: 1px;
    align-self: stretch;
    flex: 1;
    max-height: 40px;
`

export const TableHeader = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'flex-start')};
    width: ${props => (props.width ? props.width : null)};
`

export const TableCell = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : 'flex-start')};
    width: ${props => (props.width ? props.width : null)};
    height: 40px;
    padding: 0px;
    margin: 0px;
`
export const ButtonContainer = styled(TableCell)`
    width: 200px;
    justify-content: flex-end;
`
export const TableRowText = styled(Text)`
    color: ${colors.deactiveLightmode};
    font-weight: 500;
    font-size: 11px;
`

export const TableCellText = styled(Text)`
    color: ${colors.primary};
    font-weight: 600;
    font-size: 14px;
    text-align: left;
`

export const DeleteButton = styled(TouchableOpacity)`
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    background-color: ${colors.danger};
    border-radius: 6.4px;
    padding-horizontal: 15px;
    padding-vertical: 5px;
`
export const EditButton = styled(DeleteButton)`
    margin-right: 10px;
    background-color: #fff;
`
export const ButtonText = styled(Text)`
    color: #fff;
    font-weight: 500;
    font-size: 11.4px;
    text-align: center;
`

export const AddContainerGroup = styled(View)`
    width: 100%;
    margin-bottom: 40px;
    flex-direction: row;
    justify-content: space-between;
`

export const AddContainer = styled(View)`
    flex-direction: column;
    width: 60px;
`

export const AddText = styled(Text)`
    color: ${colors.darkGrey};
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    margin-top: 6px;
`

export default Intervals
