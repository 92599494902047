import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import { SubHeaderText } from 'styles/typography'
import { getCurrentUser, getClassesByClassBookings } from 'apollo/selectors'
import ClassesFlatList from 'components/instructor/ClassesFlatList'
import { useNavigation, useIsFocused } from '@react-navigation/core'
import { TRAINEE_STREAM_ROOM } from 'screenNames'
import ViewAllHeading from 'components/trainee/ViewAllHeading'
import { navigateTraineeHomeAction } from 'navigationActions'
import { APOLLO_FETCH_POLICIES } from '@constants'
import { orderByDateAndTime } from 'helpers'
import { agoraStreamState } from 'apollo/cache'
import { useReactiveVar } from '@apollo/client'
const { CACHE_FIRST, CACHE_AND_NETWORK } = APOLLO_FETCH_POLICIES
const emptyArr = []
const emptyObj = {}

export const TraineeBookedClasses = ({
    headingText,
    horizontal = true,
    isProfileNav = false,
    disableCardPress = false,
}) => {
    const navigation = useNavigation()
    const isFocused = useIsFocused()
    const streamState = useReactiveVar(agoraStreamState)
    const { classComplete, classEnded, streamEnded, streamRoomJoined } = streamState
    const [bookedClasses, setBookedClasses] = useState(emptyArr)
    const [bookedClassesNextToken, setBookedClassesNextToken] = useState(null)
    const [numClassBookings, setNumClassBookings] = useState(0)
    const { classBookings, refetch: refetchUser } = getCurrentUser({
        fetchPolicy: CACHE_AND_NETWORK,
        nextFetchPolicy: CACHE_FIRST,
        notifyOnNetworkStatusChange: true,
        onCompleted: () => {
            setNumClassBookings(classBookings?.items?.length)
        },
    })

    useEffect(() => {
        if (bookedClasses?.length > 0) {
            setBookedClasses(emptyArr)
            setBookedClassesNextToken(null)
            refetchBookedClasses()
        }
    }, [numClassBookings])

    const {
        classes: classesResult,
        loading: classesLoading,
        refetch: refetchBookedClasses,
        nextToken: initialToken,
        fetchMore: fetchMoreBookedClasses,
    } = getClassesByClassBookings({
        classBookings,
        onCompleted: () => {
            if (bookedClasses?.length === 0 && !bookedClassesNextToken) {
                setBookedClasses(classesResult)
                setBookedClassesNextToken(initialToken)
            }
        },
    })

    async function handleFetchMoreBookedClasses() {
        if (bookedClassesNextToken) {
            const fetchMoreResult = await fetchMoreBookedClasses({
                variables: { nextToken: bookedClassesNextToken },
                notifyOnNetworkStatusChange: true,
            })
            const {
                data: { listClasses: { items: moreClasses = emptyArr, nextToken: token = null } = emptyObj },
            } = fetchMoreResult
            const allBookedClasses = bookedClasses?.length > 0 ? [...bookedClasses, ...moreClasses] : [...moreClasses]
            const orderedClasses = orderByDateAndTime(allBookedClasses)
            setBookedClasses(orderedClasses)
            setBookedClassesNextToken(token)
        }
    }

    useEffect(() => {
        if (bookedClassesNextToken !== null) {
            handleFetchMoreBookedClasses()
        }
    }, [bookedClassesNextToken])

    const goToTraineeStreamRoom = ({ Class, classID }) => {
        navigation.navigate(TRAINEE_STREAM_ROOM, { classID })
    }

    useEffect(() => {
        if (refetchUser && isFocused) {
            refetchUser()
        }
        const isClassOver = classComplete || classEnded || streamEnded

        if (refetchBookedClasses && bookedClasses?.length > 0 && streamRoomJoined) {
            if (isClassOver) {
                setBookedClasses(emptyArr)
                setBookedClassesNextToken(null)
            }
            refetchBookedClasses()
        }
    }, [isFocused, classComplete, classEnded, streamEnded, streamRoomJoined])

    return (
        <TraineeBookedClassesContainer>
            {!!headingText && <StyledViewAllHeading headingText={headingText} />}
            {bookedClasses.length === 0 ? (
                <StyledSubHeader isSubtext>No Upcoming Classes</StyledSubHeader>
            ) : (
                <ClassesFlatList
                    hidePicker
                    isBookedClass
                    isFullPage={isProfileNav}
                    horizontal={horizontal}
                    navigationAction={isProfileNav && navigateTraineeHomeAction}
                    disableCardPress={disableCardPress}
                    onCardPress={!isProfileNav && goToTraineeStreamRoom}
                    classesLoading={classesLoading}
                    data={bookedClasses}
                />
            )}
        </TraineeBookedClassesContainer>
    )
}

const StyledViewAllHeading = styled(ViewAllHeading)`
    align-self: flex-start;
`
const StyledSubHeader = styled(SubHeaderText)`
    margin-bottom: 20px;
`
const TraineeBookedClassesContainer = styled(View)`
    width: 100%;
    align-self: ${({ theme }) => (theme.isMobile ? 'center' : 'flex-start')};
    padding-top: 20px;
`
export default TraineeBookedClasses
