import React from 'react'
import { View, ImageSourcePropType, Image } from 'react-native'
import { Inter900, Inter500, Inter700 } from 'typography'
import colors from 'colors'

import styled from 'styled-components/native'
import { RowWeb } from 'styles/positionHelpers'

type Member = {
    id: string
    name: string
    position: string
    description: string
    photo: ImageSourcePropType
}

const Team: React.FC = () => (
    <Container>
        <Title>Our Team</Title>
        <MemberListContainer>
            {MEMBERS.map(item => (
                <TeamMember member={item} key={item.id} />
            ))}
        </MemberListContainer>
    </Container>
)

const TeamMember = ({ member }: { member: Member }): JSX.Element => (
    <MemberContainer>
        <MemberImgContainer>
            <MemberImg source={member.photo} resizeMode="cover" />
        </MemberImgContainer>
        <MemberInfoContainer>
            <MemberName>{member.name}</MemberName>
            <MemberPosition>{member.position}</MemberPosition>
            <MemberDescription>{member.description}</MemberDescription>
        </MemberInfoContainer>
    </MemberContainer>
)

const MEMBERS: Member[] = [
    {
        id: 'member_1',
        photo: require('assets/team_photos/brock.png'),
        name: 'Brock Davies',
        position: 'Co-Founder, Co-CEO/Visionary',
        description:
            'Brock Davies is an Australian-born native and small gym owner who came over to the United States to build up his professional career as a Pro Rugby Player and later moved on to play in the NFL. After leaving his professional sports career behind, Brock went on to open 2 successful F-45 gyms in San Diego California. His leadership and entrepreneurial spirit allows him to have a clear vision for HOMEBODY and work alongside his team to take over the inflencer fitness market. ',
    },
    {
        id: 'member_2',
        photo: require('assets/team_photos/sophia.png'),
        name: 'Sophia Das',
        position: 'Co-Founder, Co-CEO',
        description:
            'Sophia developed the entrepreneurial gene at a young age watching her father grow his own company into a success. Her passion for starting a business led her to her first job out of college at a startup in New York City, where she expanded her knowledge of the digital tech space. After going to school to become a CPM, her creativity for small business branding led her to start her own boutique branding agency. Her expert understanding of developing a successful brand has helped countless small businesses gain a footprint in today’s digital era. ',
    },
    {
        id: 'member_3',
        photo: require('assets/team_photos/nicole.png'),
        name: 'Nicole Kamback',
        position: 'Co-Founder, COO',
        description:
            'Beginning her career in the tech startup world, Nicole quickly developed a passion for helping a business grow from the ground level. She became an expert in product development and grew a deep understanding of what it takes to make a startup successful. With a business development, public relations and product management background, her drive to create a product with a great user experience and take that to market has remained her ultimate goal.',
    },
    {
        id: 'member_4',
        photo: null,
        name: 'Mugdho Debnath',
        position: 'CTO',
        description:
            "As a site reliability engineer, Mugdho's forte is in design and development of quality products, systems and services. After receiving his degree from the University of Mumbai, he went on to develop his knowledge of designing large scalable systems and cutting edge DevOps strategies.With almost 20 years of experience in the industry, he is deeply passionate about the quality of his work and takes pride in all his creations. ",
    },
]

const Container = styled(View)`
    padding-left: 2.5%;
    padding-right: 2.5%;
    padding-top: 5%;
    padding-bottom: 5%;
    background-color: ${colors.homeBodyGreyBg};
`

const MemberListContainer = styled(RowWeb)``

const MemberContainer = styled(RowWeb)`
    width: ${props => (props.theme.isMobile || props.theme.isTablet ? '100%' : '50%')};
    margin-top: 50px;
`
const MemberImg = styled(Image)`
    width: 200px;
    height: 200px;
`

const MemberImgContainer = styled(View)`
    flex: ${props => (props.theme.isMobile || props.theme.isTablet ? '0.25' : '0.35')};
    margin-bottom: 15px;
    align-self: ${props => (props.theme.isMobile || props.theme.isTablet ? 'center' : 'auto')};
`

const MemberInfoContainer = styled(View)`
    flex: 0.7;
`

const MemberName = styled(Inter700)`
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 11px;
    align-self: ${props => (props.theme.isMobile ? 'center' : 'auto')};
`

const MemberPosition = styled(Inter700)`
    font-size: 19px;
    line-height: 27px;
    margin-bottom: 11px;
    align-self: ${props => (props.theme.isMobile ? 'center' : 'auto')};
`

const MemberDescription = styled(Inter500)`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.subTextGray}
  max-width: 90%;
  margin-left: ${props => (props.theme.isMobile ? '15px' : '0px')};
`

const Title = styled(Inter900)`
    font-size: ${props => (props.theme.isMobile ? '39px' : '52px')};
    line-height: 56px;
    align-self: ${props => (props.theme.isMobile ? 'center' : 'auto')};
`

export default Team
