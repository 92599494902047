import React from 'react'
import { View, Image } from 'react-native'
import { Inter900, Inter700, Inter500, TEXT_LINEAR_GRADIENT } from 'typography'
import colors from 'colors'
import { RowWeb } from 'styles/positionHelpers'
import { useDimensions } from 'dimensions'
import Features from './Features'
import styled from 'styled-components/native'

const empowerText = 'To empower creators by\ncultivating a community\nthrough a shared passion for\nhealth & fitness'
const howWeStartedText =
    "In 2020, we set out to accomplish one goal: develop a platform that would allow fitness influencers to monetize content. During the pandemic, homebody’s creator and visionary, Brock Davies faced his fair amount of challenges with his gyms when the nationwide shutdowns occurred. He realized there was no easy way for an instructor to monetize online live group classes. With the pandemic, came the struggle for fitness instructors to find effective ways to teach classes, along with the rise in demand for quality at home workouts.\n\nSince the start of HOMEBODY, we've made it our mission to bring influencers a way to create, share and monetize their content to their already engaged audience. We strongly believe that by investing in our trainers first and giving them all the tools and resources they need to grow their businesses and brands into powerhouses, we can build an immersive live workout experience for users from anytime, anywhere around the world."
const PressContent: React.FC = () => {
    const { isMobile } = useDimensions()

    return (
        <Container>
            <Title>Press & Media Kit</Title>
            <Content>
                <ContentCell>
                    <CellNameText>Our Mission</CellNameText>
                    <CellTitleText>{empowerText}</CellTitleText>
                    <HashTagText>#bycreatorsforcreators</HashTagText>
                </ContentCell>
                <ContentCellItems>
                    <InfoItem label="Headquarters" value=" Los Angeles, CA" />
                    <InfoItem label="Year Founded" value="2020" />
                    <InfoItem label="Industry" value="Fitness, Health & Wellness" />
                </ContentCellItems>
            </Content>
            <Content style={{ paddingTop: isMobile ? 35 : 70 }}>
                <PromoContentCell>
                    <PromoImage source={require('assets/team.png')} resizeMode="cover" />
                </PromoContentCell>
                <ContentCell>
                    <CellNameText>Our Story</CellNameText>
                    <CellTitleText style={{ paddingTop: isMobile ? 7 : 15 }}>How we started</CellTitleText>
                    <CellSubTitle>Investing in building up our creators first.</CellSubTitle>
                    <CellConentText>{howWeStartedText}</CellConentText>
                </ContentCell>
            </Content>
        </Container>
    )
}

const InfoItem = ({ label, value }: { label: string; value: string }): JSX.Element => (
    <InfoItemConteiner>
        <InfoItemIng source={require('assets/presskit_info_icon.png')} />
        <InfoItemLabel>{`${label}:`}</InfoItemLabel>
        <InfoItemValue>{value}</InfoItemValue>
    </InfoItemConteiner>
)

const Container = styled(View)`
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 50px;
    max-width: 2000px;
    justify-content: center;
    margin: 0 auto;
`

const Title = styled(Inter700)`
    ${TEXT_LINEAR_GRADIENT({
        direction: '90deg',
        colorStop1: `${colors.homebodyTurquoise} 3%`,
        colorStop2: `${colors.homebodyGreen} 25%`,
    })};
    font-size: ${props => (props.theme.isMobile ? '35px' : '53px')};
    line-height: ${props => (props.theme.isMobile ? '45px' : '64px')};
    margin-bottom: 50px;
`

const Content = styled(RowWeb)`
    width: 100%;
`

const ContentCell = styled(View)`
    width: ${props => (props.theme.isMobile ? '100%' : '50%')};
`

const PromoContentCell = styled(ContentCell)`
    min-height: ${props => (props.theme.isMobile ? 'auto' : '600px')};
`

const ContentCellItems = styled(ContentCell)`
    margin-top: 50px;
`

const CellNameText = styled(Inter700)`
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 15px;
    color: ${colors.pressKitGreyText};
`

const HashTagText = styled(Inter900)`
    ${TEXT_LINEAR_GRADIENT({
        direction: '90deg',
        colorStop1: `${colors.homebodyTurquoise} 0.06%`,
        colorStop2: `${colors.homebodyGreen} 98.18%`,
    })};
    font-size: 20px;
    line-height: 26px;
    max-width: 300px;
`

const PromoImage = styled(Image)`
    width: ${props => (props.theme.isMobile ? '350px' : '100%')};
    height: ${props => (props.theme.isMobile ? '450px' : '100%')};
    align-self: ${props => (props.theme.isMobile ? 'center' : 'flex-start')};
    max-width: 600px;
    max-height: 600px;
    margin-top: ${props => (props.theme.isMobile ? '10px' : '25px')};
    margin-bottom: ${props => (props.theme.isMobile ? '25px' : '25px')};
`

const CellTitleText = styled(Inter900)`
    font-size: ${props => (props.theme.isMobile ? '35px' : '38px')};
    line-height: 42px;
    margin-bottom: ${props => (props.theme.isMobile ? '25px' : '25px')};
    margin-right: 50px;
`

const CellSubTitle = styled(Inter700)`
    font-size: 16px;
    line-height: 22px;
`
const CellConentText = styled(Inter500)`
  font-size: 16px;
  line-height: 22px;
  color: ${colors.subTextGray}
  margin-top: 29px;
`

const InfoItemIng = styled(Image)`
  margin-right: ${props => (props.theme.isMobile || props.theme.isTablet ? '5px' : ' 50px')}
  width: 22px;
  height: 22px;
`

const InfoItemLabel = styled(Inter900)`
    font-size: ${props => (props.theme.isMobile ? '15px' : props.theme.isTablet ? '19px' : ' 20px')};
    line-height: 26px;
`

const InfoItemValue = styled(InfoItemLabel)`
    color: ${colors.homebodyTurquoise};
    margin-left: 5px;
`

const InfoItemConteiner = styled(View)`
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 17px;
`

export default PressContent
