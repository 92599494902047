import React, { useEffect, useState } from 'react'
import { useNavigation, useIsFocused } from '@react-navigation/core'
import { View } from 'react-native'
import { Inter900 } from 'typography'
import colors from 'colors'
import styled from 'styled-components/native'
import ClassesFlatList from 'components/instructor/ClassesFlatList'
import { getClassesToBook } from 'apollo/selectors'
import { navigateClassDetailsFromCurrentStack } from 'navigationActions'
import ViewAllHeading from 'components/trainee/ViewAllHeading'
import { orderByDateAndTime } from 'helpers'
import { useDimensions } from 'dimensions'
const emptyObj = {}
const emptyArr = []
const noop = () => {}

export const BrowseClasses = ({}) => {
    const navigation = useNavigation()
    const isFocused = useIsFocused()
    const { isMobile } = useDimensions()
    const [classesToBook, setClassesToBook] = useState(emptyArr)
    const [classesToBookToken, setClassesToBookToken] = useState(null)
    const vectorIconProps = {
        family: 'AntDesign',
        name: 'arrowright',
        color: isMobile ? colors.homebodyGreen : colors.homebodyTurquoise,
        size: 22,
        style: {
            marginLeft: 5,
        },
    }

    const {
        classesToBook: classesToBookResult,
        refetch: refetchClassesToBook,
        nextToken: initialClassesToBookToken,
        fetchMore: fetchMoreClassesToBook,
        loading: classesLoading,
    } = getClassesToBook({
        onCompleted: () => {
            if (classesToBook?.length === 0 && !classesToBookToken) {
                setClassesToBookToken(initialClassesToBookToken)
                setClassesToBook(classesToBookResult)
            }
        },
    })

    async function handleFetchMoreClassesToBook() {
        if (classesToBookToken) {
            const fetchMoreResult = await fetchMoreClassesToBook({ variables: { nextToken: classesToBookToken } })
            const {
                data: {
                    listClasses: { items: moreClassesToBook = emptyArr, nextToken: token = null },
                },
            } = fetchMoreResult ?? emptyObj
            const allClassesToBook =
                classesToBook?.length > 0 ? [...classesToBook, ...moreClassesToBook] : [...moreClassesToBook]
            const orderedClasses = orderByDateAndTime(allClassesToBook)
            setClassesToBook(orderedClasses)
            setClassesToBookToken(token)
        }
    }

    useEffect(() => {
        if (classesToBookToken !== null) {
            handleFetchMoreClassesToBook()
        }
    }, [classesToBookToken])

    useEffect(() => {
        if (!isFocused) {
            setClassesToBook(emptyArr)
            setClassesToBookToken(null)
            refetchClassesToBook()
        }
    }, [isFocused])

    return (
        <BrowseClassesContainer>
            <ViewAllHeading
                isGradient
                style={{ justifyContent: 'flex-start', paddingTop: isMobile ? 25 : 50, paddingBottom: 20 }}
                vectorIconProps={vectorIconProps}
                headingText={'Upcoming Classes'}
            />
            <ClassesFlatList
                hidePicker
                horizontal
                isLandingPageClass
                data={classesToBook}
                classesLoading={false}
                navigationAction={navigateClassDetailsFromCurrentStack}
            />
        </BrowseClassesContainer>
    )
}

const TitleText = styled(Inter900)`
    font-size: ${props => (props.theme.isMobile ? '30px' : '38px')};
    line-height: 72px;
    color: ${colors.black};
    margin-bottom: 30px;
`

const BrowseClassesContainer = styled(View)`
    flex: 1;
    margin-left: 5%;
    margin-right: 2.5%;
    margin-top: 15px;
`

export default BrowseClasses
