import React, { useEffect } from 'react'
import { View, Text, Linking } from 'react-native'
import styled from 'styled-components/native'
import { useReactiveVar } from '@apollo/client'
import { instructorApplicationForm } from 'apollo/cache'
import { getExistingFiles } from 'apollo/selectors'
import { FrontText, SubTitle } from 'components/Text'
import { FieldContainer, MAX_FORM_WIDTH } from 'src/commons/containers'
import { ContinueButton } from 'components/ContinueButton'
import { getS3Object } from 'actions'
import { useDimensions } from 'dimensions'
import colors from 'colors'
import { FormHeaderLabel, Paragraph, SubHeaderText } from 'typography'
import { Platform } from 'react-native'
import { getSocialURL } from 'helpers'
import * as screenNames from 'screenNames'
import SvgIcon from 'commons/SvgIcon'
import { CHECKMARK_ICON } from 'constants/svgIcons'
const noop = () => {}
const emptyObj = {}
const emptyArr = []

const ApplicationDetail = ({ name, details, multiple = false }) => {
    const hasLink =
        ((name == 'Certifications' || name == 'Business Registration') && details[0]?.name != 'Nothing provided') ||
        name === 'Socials'

    async function handleLink({ key, handle = '', platform = '' }) {
        const url = key ? await getS3Object(key) : getSocialURL({ handle, platform })
        if (Platform.OS == 'web') {
            window.open(url, '_blank')
        } else {
            Linking.openURL(url)
        }
    }
    const detailsMap = multiple
        ? details.map((item, index) => {
              const socialInfo = item?.platform && item?.handle ? item?.platform + ': ' + item?.handle : ''
              return (
                  <TextDetails
                      hasLink={hasLink}
                      onPress={
                          hasLink
                              ? () => handleLink({ key: item?.key, handle: item?.handle, platform: item?.platform })
                              : undefined
                      }
                      key={index}
                  >
                      {item?.specialization || item?.name || socialInfo}
                  </TextDetails>
              )
          })
        : null

    if (multiple) {
        return (
            <NameDetails>
                <Paragraph>{name}: </Paragraph>
                <MultipleDetails>{detailsMap}</MultipleDetails>
            </NameDetails>
        )
    } else {
        return (
            <NameDetails>
                <Paragraph>{name}: </Paragraph>
                <Paragraph isBold>{details}</Paragraph>
            </NameDetails>
        )
    }
}

export const ReviewAndSubmit = ({ updateStep, existingApplication = emptyObj, navigation, isReviewing }) => {
    const { isMobile } = useDimensions()
    const form = useReactiveVar(instructorApplicationForm) || emptyObj
    const { firstName, lastName } = form
    const emptyDetails = [{ name: 'Nothing provided' }]
    const specializations =
        Object?.keys(form?.specializations || emptyObj)
            ?.filter(key => form?.specializations?.[key] && key != '__typename')
            ?.map(key => ({ specialization: key })) || emptyArr

    const socials =
        Object?.keys(form?.socials || emptyObj)
            ?.filter(key => key != '__typename')
            ?.map(key => ({
                platform: key,
                handle: form?.socials[key],
            })) || emptyArr

    const certifications = getExistingFiles({
        files: existingApplication?.files?.items,
        fileType: 'CERTIFICATION',
    })

    const businessUploads = getExistingFiles({ files: existingApplication?.files?.items, fileType: 'BUSINESS' })

    if (form?.submitted && !isReviewing) {
        return (
            <FieldContainer style={{ maxWidth: MAX_FORM_WIDTH }}>
                <SubTitle>Thanks! Your application is under review</SubTitle>
                <FrontText>
                    Hey {firstName ?? ''}. Thanks for submitting your application. Our team is verifying your account
                    and will be in touch within 48 hours via email. Please email hello@homebodylivefitness.com with any
                    questions or concerns.
                </FrontText>
                <FrontText>
                    In the meantime, make sure to follow us on instagram @homebodyapp or visit us at
                    www.homebodylivefitness.com
                </FrontText>
                <FrontText>Stay Fit! - The HOMEBODY Team</FrontText>
            </FieldContainer>
        )
    } else {
        return (
            <FieldContainer style={{ maxWidth: MAX_FORM_WIDTH }}>
                <ApplicationDetail name={'Name'} details={`${firstName} ${lastName}`} />
                <ApplicationDetail name={'Zip code'} details={`${form?.location?.zip || ''}`} />
                <ApplicationDetail name={'Socials'} details={socials.length ? socials : emptyDetails} multiple={true} />
                {form.isTrainer && (
                    <>
                        <ApplicationDetail
                            name={'Specializations'}
                            details={specializations?.length ? specializations : emptyDetails}
                            multiple={true}
                        />
                        <ApplicationDetail
                            name={'Certifications'}
                            details={certifications?.length ? certifications : emptyDetails}
                            multiple={true}
                        />
                    </>
                )}
                {form.isOwner && (
                    <FieldContainer>
                        <ApplicationDetail
                            name={'Gym/Studio Address'}
                            details={
                                `${form?.gymStudioAddress?.streetAddress},\n${form?.gymStudioAddress?.city}, ${form?.gymStudioAddress?.state},\n${form?.gymStudioAddress?.zip} ` ||
                                ''
                            }
                        />
                        {
                            <ApplicationDetail
                                name={'Business Registration'}
                                details={businessUploads?.length ? businessUploads : emptyDetails}
                                multiple={true}
                            />
                        }
                    </FieldContainer>
                )}
            </FieldContainer>
        )
    }
}

const MultipleDetails = styled(View)`
    flex: 1;
`
const TextDetails = styled(Text)`
    font-size: 22px;
    font-weight: bold;
    margin-left: 2px;
    max-width: ${props => (props.theme.isMobile ? '75%' : '100%')};
    color: ${props => (props.hasLink ? 'blue' : 'black')};
`
const NameDetails = styled(View)`
    flex-direction: row;
    padding-bottom: 20px;
    flex: 1;
    align-items: flex-start;
`
const MarkContainer = styled(View)`
    display: inline-block;
    height: 28px;
    width: 28px;
    position: relative;
    margin-left: 10px;
    border-radius: 50%;
    background-color: ${colors.homebodyTurquoise};
`
const MarkSvg = styled(View)`
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`
const SubHeaderTextContainer = styled(View)`
    display: flex;
    flex-direction: row;
`

export default ReviewAndSubmit
