import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import * as screenNames from 'screenNames'
import colors from 'styles/colors'
import styled from 'styled-components/native'
import { getSuggestedClasses } from 'apollo/selectors'
import { orderByBooking, formatDate, toStandardTime, truncateText } from 'helpers'
import { Heading, SmallText } from 'styles/typography'
import { RowContainer } from 'commons/containers'
import CustomImage from 'commons/components/CustomImage'
import BannerCarousel from 'commons/components/BannerCarousel'
import { ContinueButton } from 'commons/components/ContinueButton'
const STATIC_BANNER = require('assets/LandingPageBanner.png')
const emptyObj = {}
const emptyArr = []

export default function TraineeSuggested() {
    const navigation = useNavigation()
    const [suggestedClasses, setSuggestedClasses] = useState(emptyArr)
    const [suggestedClassesToken, setSuggestedClassesToken] = useState(null)
    const [username, setUsername] = useState('')
    const [Class, setClass] = useState({})
    const [className, setClassName] = useState('')
    const [classDate, setClassDate] = useState('')
    const [classTime, setClassTime] = useState('')
    const [classDesc, setClassDesc] = useState('')
    const [activeIndex, setActiveIndex] = useState(2)
    const [loading, setLoading] = useState(true)
    const {
        suggestedClasses: suggestedClassesResult,
        nextToken: initialSuggestedClassesToken,
        fetchMore: fetchMoreSuggestedClasses,
    } = getSuggestedClasses({
        onCompleted: () => {
            if (suggestedClasses?.length === 0 && !suggestedClassesToken) {
                setSuggestedClassesToken(initialSuggestedClassesToken)
                setSuggestedClasses(suggestedClassesResult)
            }
        },
    })

    const classBanners = suggestedClasses?.map(item => ({
        imgKey: item?.banner?.key,
    }))

    const navigateToClass = () => {
        navigation.navigate({
            name: screenNames.CLASS_DETAILS,
            params: { Class, classID: Class.id },
        })
    }

    const handleSnapToItem = index => {
        setActiveIndex(index)
    }

    async function handleFetchMoreSuggestedClasses() {
        if (suggestedClassesToken) {
            const fetchMoreResult = await fetchMoreSuggestedClasses({ variables: { nextToken: suggestedClassesToken } })
            const {
                data: {
                    listClasses: { items: moreClasses = emptyArr, nextToken: token = null },
                },
            } = fetchMoreResult ?? emptyObj
            const allSuggestedClasses =
                suggestedClasses?.length > 0 ? [...suggestedClasses, ...moreClasses] : [...moreClasses]
            const orderedByBooking = orderByBooking(allSuggestedClasses)?.slice(0, 5)
            setSuggestedClasses(orderedByBooking)
            setSuggestedClassesToken(token)
        }
    }

    useEffect(() => {
        if (suggestedClasses.length < 3) setActiveIndex(0)
        setClass(suggestedClasses[activeIndex])
        setUsername(Class?.instructor?.username)
        setClassName(Class?.name)
        setClassDate(formatDate(Class?.date))
        setClassTime(toStandardTime(Class?.startTime))
        setClassDesc(Class?.description)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [activeIndex, suggestedClasses])

    useEffect(() => {
        if (!loading && suggestedClasses?.length >= 3) {
            setTimeout(() => {
                handleSnapToItem(activeIndex + 1)
            }, 100)
        }
    }, [loading])

    useEffect(() => {
        if (suggestedClassesToken !== null) {
            handleFetchMoreSuggestedClasses()
        }
    }, [suggestedClassesToken])

    return (
        <TraineeSuggestedContainer>
            <StaticBanner hasGradientShadow defaultImage={STATIC_BANNER} resizeMode="cover" />
            {suggestedClasses.length >= 3 && !loading ? (
                <ElementContainer>
                    <LeftContainer>
                        <ClassName isBold>{truncateText({ text: className, charCeil: 30 })}</ClassName>
                        <ClassInfoContainer>
                            <Instructor isBold>{`With ${username}`}</Instructor>
                            <RowContainer>
                                <Description>{truncateText({ text: classDesc })}</Description>
                                <TimeText>{`•  Live on ${classDate}, ${classTime} EST `}</TimeText>
                            </RowContainer>
                            <ButtonContainer>
                                <ContinueButton
                                    text={'Book Now'}
                                    style={{
                                        height: 56,
                                        width: 155,
                                    }}
                                    color={colors.homebodyTurquoise}
                                    onPress={navigateToClass}
                                />
                            </ButtonContainer>
                        </ClassInfoContainer>
                    </LeftContainer>
                    <CarouselContainer>
                        <ClassCarousel
                            data={classBanners}
                            isSuggested
                            hasHeading
                            headingText="most popular"
                            handleSnapToItem={handleSnapToItem}
                            disableButtons={false}
                            activeIndex={activeIndex}
                            carouselConfig={{
                                itemHeight: 56,
                                itemWidth: 96,
                                sliderHeight: 115,
                                sliderWidth: 406,
                                buttonSize: 24,
                                iconSize: 14,
                                activePadding: 6,
                                imageBorderRadius: 12,
                            }}
                        />
                    </CarouselContainer>
                </ElementContainer>
            ) : suggestedClasses.length !== 0 && !loading ? (
                <ElementContainer>
                    <LeftContainer>
                        <ClassName isBold>{truncateText({ text: className, charCeil: 30 })}</ClassName>
                        <ClassInfoContainer>
                            <Instructor isBold>{`With ${username}`}</Instructor>
                            <RowContainer>
                                <Description>{truncateText({ text: classDesc })}</Description>
                                <TimeText>{`•  Live on ${classDate}, ${classTime} EST `}</TimeText>
                            </RowContainer>
                            <ButtonContainer>
                                <ContinueButton
                                    text={'Book Now'}
                                    style={{
                                        height: 56,
                                        width: 155,
                                    }}
                                    color={colors.homebodyTurquoise}
                                    onPress={navigateToClass}
                                />
                            </ButtonContainer>
                        </ClassInfoContainer>
                    </LeftContainer>
                    <CarouselContainer></CarouselContainer>
                </ElementContainer>
            ) : (
                <></>
            )}
        </TraineeSuggestedContainer>
    )
}

const TraineeSuggestedContainer = styled(View)`
    height: 500px;
`
const ElementContainer = styled(RowContainer)`
    height: 100%;
    padding-top: 8%;
`
const LeftContainer = styled(View)`
    flex: 2;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    margin-bottom: 60px;
`
const ClassInfoContainer = styled(View)``
const CarouselContainer = styled(View)`
    flex: 0.5;
    display: flex;
    flex-direction: column;
    margin-right: 74px;
    margin-bottom: 35px;
    align-items: center;
    justify-content: flex-end;
`
const ClassCarousel = styled(BannerCarousel)`
    align-self: flex-start;
`
const ButtonContainer = styled(View)`
    width: 155px;
    margin-top: 24px;
    margin-left: 5%;
`
const ClassName = styled(Heading)`
    font-size: 72px;
    line-height: 88px;
    margin-left: 5%;
    color: ${colors.white};
`
const Instructor = styled(Heading)`
    margin-left: 5%;
    margin-top: 8px;
    color: ${colors.white};
`
const Description = styled(SmallText)`
    margin-left: 5%;
    margin-top: 24px;
    color: ${colors.white};
`
const TimeText = styled(SmallText)`
    margin-top: 24px;
    margin-left: 5%;
    color: ${colors.white};
`
const StaticBanner = styled(CustomImage)`
    position: absolute;
    height: 500px;
    width: 100%;
`
