import React from 'react'
import { LinearGradient } from 'expo-linear-gradient'
import colorConstants from 'colors'

export const LinearGradientView = ({
    children,
    style,
    colors = [colorConstants.homebodyTurquoise, colorConstants.homebodyGreen],
    start = { x: 0.15, y: 0 },
    end = { x: 0.85, y: 0 },
}) => {
    if (children)
        return (
            <LinearGradient style={style} start={start} end={end} colors={colors}>
                {children}
            </LinearGradient>
        )
    return <LinearGradient style={style} start={start} end={end} colors={colors} />
}

export default LinearGradientView
