import React, { useState, useEffect } from 'react'
import { View, TouchableOpacity, StyleSheet, ActivityIndicator } from 'react-native'
// ui
import colors from 'colors'
import styled from 'styled-components/native'
import { LinearGradient } from 'expo-linear-gradient'
import { Inter700, Inter600, Inter500, SmallText, Caption, Paragraph, SubHeading } from 'typography'
import {
    formatDate,
    toStandardTime,
    getCanStartClass,
    isDateToday,
    truncateText,
    isClassTimeWithinXHours,
} from 'helpers'
import { getStream, getClassForCard } from 'apollo/selectors'
import { useNavigation } from '@react-navigation/native'
import { useDimensions } from 'dimensions'
// components
import { RowContainer } from 'commons/containers'
import { CREATE_CLASS_NAVIGATOR, CLASS_DETAILS } from 'screenNames'
import VectorIcon from 'components/VectorIcon'
import CustomImage from 'components/CustomImage'
import { getNavigationAction } from 'navigationActions'
import { CLASS_STATUS } from '@constants'
const { UNFINISHED, UNPUBLISHED, PUBLISHED } = CLASS_STATUS
const landingPageBannerStyle = { height: 236, width: '100%', borderRadius: 14 }
const webBannerStyle = { height: 231, width: '100%', borderRadius: 14 }
const mobileBannerStyle = { height: 150, width: '100%', borderRadius: 14 }
const webBookedBannerStyle = { height: 170, width: '100%', borderTopLeftRadius: 24, borderTopRightRadius: 24 }
const mobileBookedBannerStyle = { height: 150, width: '100%', borderTopLeftRadius: 24, borderTopRightRadius: 24 }
const gradientStart = { x: 0.15, y: 0 }
const gradientEnd = { x: 0.85, y: 0 }
const gradientColors = [colors.homebodyTurquoise, colors.homebodyGreen]

const emptyArr = []
const emptyObj = {}
const noop = () => {}

export const InstructorCreateClassCard = () => {
    const navigation = useNavigation()
    const goToCreateClass = () => navigation.navigate(CREATE_CLASS_NAVIGATOR)
    return (
        <ClassCardContainer
            style={{ alignSelf: 'flex-start', borderRadius: 24 }}
            justifyContent="flex-start"
            onPress={goToCreateClass}
            isCreateClass
        >
            <CreateCardTop />
            <AddClassButton colors={['#0CE2FF', '#5AC973']}>
                <VectorIcon family="AntDesign" name="plus" size={40} color="white" />
            </AddClassButton>
        </ClassCardContainer>
    )
}

const LoadingClassesCard = ({ isBookedCardStyle = false }) => {
    if (isBookedCardStyle) {
        return (
            <FlatlistCardContainer style={{ justifyContent: 'center' }} disabled={true}>
                <ActivityIndicator
                    style={{ alignSelf: 'center' }}
                    size="large"
                    color={colors.homebodyTurquoise}
                    animating={true}
                />
            </FlatlistCardContainer>
        )
    } else {
        return (
            <ClassCardContainer style={{ height: 220 }} disabled={true}>
                <ActivityIndicator
                    style={{ alignSelf: 'flex-start' }}
                    size="large"
                    color={colors.homebodyTurquoise}
                    animating={true}
                />
            </ClassCardContainer>
        )
    }
}

const ClassStatus = ({ isLiveSoon, classStatus, numBooked, showNumBooked = false, isMobile }) => {
    if (classStatus != PUBLISHED) {
        return (
            <ClassStatusContainer>
                <GoToClassTag>
                    <>
                        {classStatus == UNPUBLISHED ? (
                            <StyledLinearGradientButton
                                start={gradientStart}
                                end={gradientEnd}
                                colors={gradientColors}
                            />
                        ) : (
                            <></>
                        )}
                        <GoToClassText>GO TO CLASS</GoToClassText>
                    </>
                </GoToClassTag>
            </ClassStatusContainer>
        )
    } else {
        return (
            <ClassStatusContainer showNumBooked={showNumBooked}>
                {isLiveSoon && !showNumBooked && (
                    <LiveSoonTag>
                        <TagIcon family="Ionicons" name="ios-wifi" size={isMobile ? 15 : 22} color="white" />
                        <GoToClassText>Live soon</GoToClassText>
                    </LiveSoonTag>
                )}
                {showNumBooked && (
                    <NumBookedContainer>
                        <LiveSoonTag>
                            <TagIcon family="Ionicons" name="ios-wifi" size={isMobile ? 15 : 22} color="white" />
                            <GoToClassText>Live soon</GoToClassText>
                        </LiveSoonTag>
                        <BookedContainer>
                            <BookedDot />
                            <NumBookedText isSubtext>{numBooked + '  '}Booked</NumBookedText>
                        </BookedContainer>
                    </NumBookedContainer>
                )}
            </ClassStatusContainer>
        )
    }
}

const LandingPageClassStatus = ({ isLiveSoon, isLandingPageClass }) => {
    return (
        <JoinClassContainer>
            {isLiveSoon && (
                <LiveSoonTag>
                    <TagIcon family="Ionicons" name="ios-wifi" size={28} color="white" />
                    <GoToClassText isLandingPageClass={isLandingPageClass}>Live soon</GoToClassText>
                </LiveSoonTag>
            )}
        </JoinClassContainer>
    )
}

const JoinClassStatus = ({
    classID,
    setClassUnlocked,
    canStartClass,
    isBookedClass = false,
    isMobile = false,
    isFullPage = false,
}) => {
    const { stream, startPolling, stopPolling } = getStream({ streamID: classID })
    const { isHostInStreamRoom, id: streamID } = stream || emptyObj

    const joinClassTagItems = {
        iconName: isHostInStreamRoom ? 'unlock' : 'lock',
        tagColor: isHostInStreamRoom ? colors.homebodyGreen : colors.homebodyTurquoise,
        tagText: isHostInStreamRoom ? 'Join class' : 'Class locked',
    }
    const { iconName, tagColor, tagText } = joinClassTagItems

    useEffect(() => {
        const shouldStartPolling = streamID && canStartClass
        if (shouldStartPolling) {
            setClassUnlocked(isHostInStreamRoom)
            startPolling(__DEV__ ? 3000 : 10000)
        }
        return () => {
            if (shouldStartPolling) {
                stopPolling()
            }
        }
    }, [stream])

    return (
        <JoinClassContainer isBookedClass={isBookedClass}>
            <JoinClassTag isFullPage={isFullPage} color={tagColor}>
                <TagIcon family="Feather" name={iconName} size={isMobile ? 15 : 18} color="white" />
                <JoinClassText isFullPage={isFullPage} numberOfLines={1}>
                    {tagText}
                </JoinClassText>
            </JoinClassTag>
        </JoinClassContainer>
    )
}
export const ClassCard = ({
    Class: classFromParams,
    onCardPress,
    onDeletePress = noop,
    classesLoading = false,
    isBookedClass = false,
    isLandingPageClass = false,
    isExploreClass = false,
    isFullPage = false,
    isInstructor = false,
    showNumBooked = false,
    disableCardPress = false,
    navigationAction,
}) => {
    const { isMobile, isTablet, windowDimensions } = useDimensions()
    const { windowWidth } = windowDimensions
    const cardWidth = `${Math.floor(windowWidth * 0.8)}px`
    const [Class, setClass] = useState(classFromParams)
    const navigation = useNavigation()
    const navigateClassDetails = getNavigationAction({
        navigation,
        navigationAction,
        params: { classID: Class?.id, Class, hasBookedClass: isBookedClass },
        depArray: [Class?.id ?? '', Class ?? emptyObj],
    })
    const [classUnlocked, setClassUnlocked] = useState(false)
    const classFromID = getClassForCard({
        classID: Class?.id,
        onCompleted: () => {
            setClass(classFromID)
        },
    })
    const {
        name,
        date,
        startTime,
        duration,
        classStatus,
        id: classID,
        banner,
        defaultBannerIndex,
        _version,
        instructor,
    } = Class || emptyObj

    const { username = '' } = instructor || emptyObj
    const numBooked = 0
    const isUnpublished = classStatus == UNFINISHED || classStatus == UNPUBLISHED
    const bannerStyles = showNumBooked
        ? [webBookedBannerStyle, mobileBookedBannerStyle]
        : [webBannerStyle, mobileBannerStyle]
    const isClassToday = date ? isDateToday(date) : false
    const isLiveSoon = isClassToday && isClassTimeWithinXHours({ startTime, numHours: 2 })
    const canStartClass = getCanStartClass({ date, startTime })
    const isCardPressable = (isBookedClass && !isInstructor) || (!isBookedClass && !isInstructor) || isInstructor
    const handleClassCardPress = () => (onCardPress ? onCardPress({ Class, classID }) : navigateClassDetails())

    const handleBookedClassCardPress = () => {
        if (classUnlocked) {
            onCardPress({ Class, classID })
        } else {
            if (navigationAction) {
                navigateClassDetails()
            } else {
                navigation.navigate(CLASS_DETAILS, { classID, hasBookedClass: true })
            }
        }
    }

    const handleOnDeletePress = () => onDeletePress({ id: classID, _version, classStatus })

    if (classesLoading)
        return <LoadingClassesCard isBookedCardStyle={isBookedClass || isLandingPageClass || isExploreClass || false} />

    return (
        <>
            {isLandingPageClass ? (
                <FlatlistCardContainer disabled={!isCardPressable || disableCardPress} onPress={navigateClassDetails}>
                    <CustomImage imgKey={banner?.key} style={landingPageBannerStyle} resizeMode={'cover'} />
                    <BookedClassNameText isMostBold paddingTop={10} numberOfLines={1}>
                        {truncateText({ text: name, charCeil: 40 })}
                    </BookedClassNameText>
                    <ClassInfoContainer>
                        <BookedDetailsContainer isBookedClass>
                            <UsernameText>{`${username ?? ''}`}</UsernameText>
                            <DateContainer>
                                <DateText color={colors.darkGrey}>{`${formatDate(date, 'MMM d')}  `}</DateText>
                                <TimeDurationText>{`${toStandardTime(startTime)} EST`}</TimeDurationText>
                            </DateContainer>
                        </BookedDetailsContainer>
                        <LandingPageClassStatus isLiveSoon={isLiveSoon} landingPage />
                    </ClassInfoContainer>
                </FlatlistCardContainer>
            ) : (
                <FlatlistCardContainer
                    isFullPage={isFullPage}
                    showNumBooked={showNumBooked}
                    disabled={!isCardPressable || disableCardPress}
                    onPress={isBookedClass ? handleBookedClassCardPress : handleClassCardPress}
                >
                    <CustomImage
                        imgKey={banner?.key}
                        style={!isMobile && !isTablet ? bannerStyles[0] : bannerStyles[1]}
                        resizeMode={'cover'}
                    />
                    {isUnpublished && isInstructor && (
                        <DeleteClassIcon onPress={handleOnDeletePress}>
                            <VectorIcon family="AntDesign" name="delete" color="white" size={32} />
                        </DeleteClassIcon>
                    )}
                    <BookedClassNameText isMostBold paddingTop={10} numberOfLines={1} showNumBooked={showNumBooked}>
                        {truncateText({ text: name, charCeil: 35 })}
                    </BookedClassNameText>
                    <ClassInfoContainer>
                        <BookedDetailsContainer isExploreClass={isExploreClass || isBookedClass || false}>
                            <TopRowContainer>
                                <UsernameText>{`${username ?? ' '}`}</UsernameText>
                                {!showNumBooked && <TimeDurationText>{`${duration} min`}</TimeDurationText>}
                            </TopRowContainer>
                            {showNumBooked ? (
                                <BottomColumnContainer>
                                    <DateContainer showNumBooked>
                                        <DateText showNumBooked>{`${formatDate(date, 'MMM d')}  `}</DateText>
                                        <BottomRowContainer showNumBooked>
                                            <TimeDurationText>{`${toStandardTime(startTime)} EST`}</TimeDurationText>
                                            <TimeDurationText>{`${duration} min`}</TimeDurationText>
                                        </BottomRowContainer>
                                    </DateContainer>
                                    <ClassStatus
                                        isLiveSoon={isLiveSoon}
                                        classStatus={classStatus}
                                        isMobile={isMobile}
                                        numBooked={numBooked}
                                        showNumBooked
                                    />
                                </BottomColumnContainer>
                            ) : (
                                <BottomRowContainer>
                                    <DateContainer>
                                        <DateText>{`${formatDate(date, 'MMM d')}  `}</DateText>
                                        <TimeDurationText>{`${toStandardTime(startTime)} EST`}</TimeDurationText>
                                    </DateContainer>
                                    {isBookedClass && !isFullPage ? (
                                        <JoinClassStatus
                                            classID={classID}
                                            setClassUnlocked={setClassUnlocked}
                                            canStartClass={canStartClass}
                                            isBookedClass
                                            isFullPage={isFullPage}
                                            isMobile={isMobile}
                                        />
                                    ) : (
                                        <ClassStatus
                                            isLiveSoon={isLiveSoon}
                                            classStatus={classStatus}
                                            isMobile={isMobile}
                                        />
                                    )}
                                </BottomRowContainer>
                            )}
                        </BookedDetailsContainer>
                    </ClassInfoContainer>
                </FlatlistCardContainer>
            )}
            <ClassCardSeparator />
        </>
    )
}

const FlatlistCardContainer = styled(TouchableOpacity)`
    justify-content: flex-start;
    ${props => (!props.isFullPage && (props.theme.isMobile || props.theme.isTablet) ? 'width: 225px;' : '')}
    ${props =>
        props.showNumBooked
            ? `margin-bottom: 2%; margin-horizontal: 2%; border-radius: 24px; box-shadow: 0px 14px 14px ${colors.neutralGray};`
            : !props.theme.isMobile && 'margin-vertical: 10px;'}
`

const BookedDetailsContainer = styled(View)`
    ${props => (props.isExploreClass ? 'width: 100%;' : '')}
    justify-content: space-between;
`
const ClassInfoContainer = styled(RowContainer)`
    align-items: flex-start;
    justify-content: space-between;
`
const BookedClassNameText = styled(SubHeading)`
    font-size: ${props => (props.showNumBooked ? '18' : '16')}px;
    letter-spacing: 0.5px;
`
const CreateCardTop = styled(View)`
    height: 50%;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    background-color: ${colors.neutralGray};
`
const ClassCardContainer = styled(TouchableOpacity)`
    align-self: center;
    justify-content: ${props => (props.justifyContent ? `${props.justifyContent}` : 'center')};
    height: ${props => (props.theme.isMobile || props.theme.isTablet ? `${props.cardHeight}` : '231px')};
    width: ${props => (props.theme.isMobile || props.theme.isTablet ? `${props.cardWidth}` : '402px')};
    ${props => props.isCreateClass && 'box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25); margin-bottom: 5%;'}
`
const ClassCardSeparator = styled(View)`
    width: 20px;
    height: ${({ theme }) => (theme.isMobile ? 20 : 0)}px;
`
const DeleteClassIcon = styled(TouchableOpacity)`
    position: absolute;
    top: 12.5px;
    right: 15px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 2px;
    border-radius: 10px;
`
const TagIcon = styled(VectorIcon)`
    align-self: center;
`
const AddClassButton = styled(LinearGradient)`
    height: 72px;
    width: 72px;
    border-radius: 36px;
    position: absolute;
    bottom: 80px;
    align-self: center;
    justify-content: center;
    align-items: center;
`
const ClassStatusContainer = styled(View)`
    ${({ theme }) => (theme.isMobile || theme.isTablet ? 'height: 100%;' : '')}
    ${({ theme }) => (theme.isMobile || theme.isTablet ? '' : 'width: 220px;')}
    flex-direction: row;
    justify-content: ${props => (props.showNumBooked ? 'space-between' : 'flex-end')};
    align-items: center;
    ${props =>
        props.showNumBooked
            ? `
    margin-top: 10%;
    padding-vertical: 10%;
    border-top-width: ${StyleSheet.hairlineWidth}px;
    border-color: ${colors.neutralGray};`
            : ''}
`
const NumBookedText = styled(SmallText)`
    font-size: 12px;
`
const BookedContainer = styled(RowContainer)`
    align-self: center;
`
const BookedDot = styled(View)`
    width: 8px;
    height: 8px;
    border-radius: 4px;
    top: 3.5px;
    margin-right: 6px;
    background-color: ${colors.bookedBlue};
`
const JoinClassContainer = styled(ClassStatusContainer)`
    border-top-width: 0px;
    align-self: flex-end;
    align-items: center;
    padding-horizontal: 0px;
    justify-content: flex-end;
    ${({ theme }) => (theme.isMobile || theme.isTablet ? 'margin-top: 0px;' : '')}
    ${props =>
        props.isLandingPageClass
            ? 'width: 200px'
            : props.isBookedClass && (props.theme.isMobile || props.theme.isTablet)
            ? 'width: 46%'
            : 'width: 220px'};
`
const NumBookedContainer = styled(View)`
    margin-horizontal: 10%;
`
const GoToClassTag = styled(View)`
    background-color: ${colors.homebodyTurquoise};
    width: 107.02px;
    height: 27.04px;
    border-radius: 9px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`
const LiveSoonTag = styled(GoToClassTag)`
    ${props =>
        props.isLandingPageClass &&
        `
    width: 140px;
    height: 35px;
    border-radius: 12px;
    align-self: flex-end;
    justify-content: space-around;
`}
    ${({ theme }) =>
        theme.isMobile || theme.isTablet
            ? `
    width: 70px;
    height: 17px;
    border-radius: 6px;
    align-self: flex-end;
    justify-content: space-around;
`
            : 'height: 27px; width: 107px;'}

    padding-horizontal: 5px;
    flex-direction: row;
`
const JoinClassTag = styled(LiveSoonTag)`
    padding-horizontal: 10px;
    border-radius: 6px;
    ${({ theme }) => (theme.isMobile || theme.isTablet ? 'height: 20px;' : '')}
    width: ${props => (props.isFullPage || props.theme.isMobile || props.theme.isTablet ? '90px' : '75%')};
    background-color: ${props => props.color};
`
const TimeDurationText = styled(Inter500)`
    color: ${colors.darkGrey};
    font-size: ${({ theme }) => (theme.isMobile || theme.isTablet ? '12' : '18')}px;
    line-height: ${({ theme }) => (theme.isMobile || theme.isTablet ? '15' : '22')}px;
`
const DateText = styled(Inter500)`
    font-size: ${({ theme }) => (theme.isMobile || theme.isTablet ? '12' : '18')}px;
    line-height: ${({ theme }) => (theme.isMobile || theme.isTablet ? '15' : '22')}px;
    color: ${props => (props.showNumBooked ? colors.black : colors.darkGrey)};
`
const UsernameText = styled(Inter600)`
    font-size: ${({ theme }) => (theme.isMobile || theme.isTablet ? '12' : '18')}px;
    line-height: ${({ theme }) => (theme.isMobile || theme.isTablet ? '15' : '22')}px;
    padding-bottom: 0px;
`
const GoToClassText = styled(DateText)`
    color: white;
    text-align: center;
    align-self: center;
    ${props => props.isLandingPageClass && `font-size: 19px;`}
    ${({ theme }) =>
        theme.isMobile || theme.isTablet ? 'font-size: 9px; line-height: 13px;' : 'font-size: 13px; line-height: 20px;'}
`
const JoinClassText = styled(GoToClassText)`
    padding-left: ${({ theme }) => (theme.isMobile || theme.isTablet ? '2px' : '10px')};
    ${({ theme }) => (theme.isMobile || theme.isTablet ? 'font-size: 9px; line-height: 13px;' : '')}
`
const TopRowContainer = styled(RowContainer)`
    flex: 1;
    align-items: flex-start;
    justify-content: space-between;
    margin-vertical: 5px;
`
const BottomRowContainer = styled(RowContainer)`
    flex: 1;
    align-items: flex-start;
    justify-content: space-between;
    ${props => (props.showNumBooked ? 'margin-horizontal: 2%;' : '')}
`
const BottomColumnContainer = styled(View)`
    display: flex;
    flex-direction: column;
    width: 100%;
`
const DateContainer = styled(View)`
    flex-direction: ${props => (props.showNumBooked ? 'column' : 'row')};
`
const StyledLinearGradientButton = styled(LinearGradient)`
    flex-direction: row;
    justify-content: flex-start;
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 9px;
`

export default ClassCard
