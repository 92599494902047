import React, { useEffect } from 'react'
import { View } from 'react-native'
import colors from 'colors'
import styled from 'styled-components/native'
import { useElapsedTime } from 'use-elapsed-time'
import { INSTRUCTOR_DRAWER_WIDTH } from '@constants'
import { useDimensions } from 'dimensions'
import ProgressBar from 'react-native-progress/Bar'

export const ClassProgressBar = ({ style, reactiveStreamProps }) => {
    const { windowDimensions } = useDimensions()
    const { windowWidth: widthWithDrawer } = windowDimensions
    const windowWidth = widthWithDrawer - INSTRUCTOR_DRAWER_WIDTH
    const { streamState, streamHelpers } = reactiveStreamProps
    const { classStarted, classEnded, isHost, lateTraineeIDs } = streamState
    const { totalIntervalTime, setLateJoinIntervalDelay } = streamHelpers
    const { elapsedTime } = useElapsedTime({ isPlaying: classStarted })

    useEffect(() => {
        setLateJoinIntervalDelay(elapsedTime)
    }, [lateTraineeIDs])

    if (!isHost || classEnded) return null
    return (
        <ClassProgressBarContainer style={style}>
            <ProgressBar
                color={colors.homebodyTurquoise}
                progress={elapsedTime / totalIntervalTime}
                width={windowWidth * 0.6}
                unfilledColor="white"
                borderWidth={0}
            />
        </ClassProgressBarContainer>
    )
}

const ClassProgressBarContainer = styled(View)``

export default ClassProgressBar
