import React, { useEffect, useState, useMemo } from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import { isWeb } from '@constants'
import { View } from 'react-native'
import { useDimensions } from 'dimensions'
import CalendarDays from 'components/react-native-calendar-slider-carousel'
import { ScreenContainer, ContentContainer } from 'commons/containers'
import { TRAINEE_STREAM_ROOM } from 'screenNames'
import { format, addDays, isToday, isTomorrow } from 'date-fns'
import ClassesFlatList from 'commons/components/instructor/ClassesFlatList'
import { navigateClassDetailsFromCurrentStack, navigateTraineeHomeAction } from 'navigationActions'
import styled from 'styled-components/native'
import { getPublishedClassesByInstructorID } from 'apollo/selectors'
import { SubHeaderText } from 'styles/typography'

const DateTimeFilter = ({ onDateSelect, isMobile, defaultDate }) => {
    return (
        <CalendarDaysContainer isWeb={isWeb}>
            <CalendarDays
                showArrows
                isMobile={isMobile}
                onDateSelect={onDateSelect}
                daysInView={isMobile ? 5 : 10}
                defaultDate={defaultDate}
            />
        </CalendarDaysContainer>
    )
}

const ClassesContainer = ({ instructorID, date, isProfileNav = false }) => {
    const {
        classes: instructorClasses,
        loading: instructorClassesLoading,
        refetch: refetchInstructorClasses,
    } = getPublishedClassesByInstructorID({ instructorID, date })

    useEffect(() => {
        if (refetchInstructorClasses) {
            refetchInstructorClasses()
        }
    }, [date])
    const navigation = useNavigation()

    const classesDates = useMemo(() => {
        const day = new Date(date)
        const adjustedDate = new Date(day.getTime() - day.getTimezoneOffset() * -60000)
        return format(
            adjustedDate,
            `${isToday(adjustedDate) ? "'Today'" : isTomorrow(adjustedDate) ? "'Tomorrow'" : 'EEEE'}, MMM d`,
        )
    }, [])
    const goToTraineeStreamRoom = ({ Class, classID }) => {
        navigation.navigate(TRAINEE_STREAM_ROOM, { classID })
    }
    return (
        <ClassesContentContainer>
            <ClassesFlatList
                hidePicker
                isBookedClass
                horizontal
                hideNoClassesText
                subHeadingText={classesDates}
                navigationAction={isProfileNav ? navigateTraineeHomeAction : navigateClassDetailsFromCurrentStack}
                onCardPress={!isProfileNav && goToTraineeStreamRoom}
                data={instructorClasses}
                classesLoading={instructorClassesLoading}
                subHeadingAlignment="center"
            />
            {!instructorClasses?.length && (
                <SubHeaderText isMostBold isSubtext textAlign="center" style={{ marginBottom: 10 }}>
                    No Classes
                </SubHeaderText>
            )}
        </ClassesContentContainer>
    )
}

const ClassSchedule = () => {
    const { params = emptyObj } = useRoute()
    const { instructorID } = params
    const currentDate = useMemo(() => {
        return format(new Date(), 'yyyy-MM-dd')
    }, [])
    const [selectedDate, setSelectedDate] = useState(currentDate)

    const { isMobile } = useDimensions()

    const generateClassesByDateView = () => {
        const views = []
        for (let i = 0; i < 3; i++) {
            const date = format(addDays(new Date(selectedDate), i), 'yyyy-MM-dd')
            views.push(<ClassesContainer instructorID={instructorID} date={date} key={date} />)
        }
        return views
    }

    return (
        <ScreenContainer>
            <ContentContainer>
                <DateTimeFilter isMobile={isMobile} onDateSelect={setSelectedDate} defaultDate={currentDate} />
                {generateClassesByDateView()}
            </ContentContainer>
        </ScreenContainer>
    )
}

const ClassesContentContainer = styled(ContentContainer)`
    margin-bottom: 15.29px;
`
const CalendarDaysContainer = styled(View)`
    width: 100%;
    flex: ${({ isWeb }) => (isWeb ? 0 : 1)};
    z-index: 10;
    align-items: center;
`
export default ClassSchedule
