import React, { useState, useEffect } from 'react'
import { View, ActivityIndicator } from 'react-native'
// ui
import colors from 'colors'
import styled from 'styled-components/native'
import { useDimensions } from 'dimensions'
import { TitleText, Headline, Caption } from 'typography'
// state/selectors
import { userProfileForm } from 'apollo/cache'
import { INSTRUCTOR_ACCOUNT } from 'screenNames'
import { getCurrentUser, getTrainerApplication } from 'apollo/selectors'
import { useMutation } from 'apollo-augmented-hooks'
import { UPDATE_USER } from 'apollo/mutations'
import { pickFile, updateUserProfile, updateUserAvatar } from 'actions'
//form
import useReactiveForm from 'hooks/useReactiveForm'
import { userProfileFieldNames as fieldNames, instructorProfileFieldErrors } from 'formFieldInfo'
import { instructorAccountValidationFunc } from 'validations'
import { specializationPickerItems } from 'pickerItems'
import { specializations as initialSpecializations } from 'constants/initialFormStates'
import { SANITIZE_INPUT_TYPES } from '@constants'
// components
import { InputField, InputFieldRowSpacer } from 'components/InputField'
import { FieldRowContainer, FieldContainer, RowContainer, ScreenContainer, ContentContainer } from 'commons/containers'
import UploadButton from 'components/UploadButton'
import { ContinueButton } from 'components/ContinueButton'
import UserAvatar from 'components/UserAvatar'
import DropDownPicker from 'components/DropDownInput'
import { useReactiveVar } from '@apollo/client'
import { LIST_USERS } from 'apollo/queries'
import { useNavigation } from '@react-navigation/native'
import { FooterBar } from 'commons/components/FooterBar'
const emptyObj = {}
const BIO_CHAR_LIMIT = 500

export default function InstructorProfile() {
    const navigation = useNavigation()
    const {
        id,
        _version,
        profile,
        firstName,
        lastName,
        username,
        avatar,
        classCategories: specializations,
    } = getCurrentUser()
    const existingApplication = getTrainerApplication({ trainerApplicationID: id })
    const { socials: appSocials = {} } = existingApplication || emptyObj
    const { instagram: appInsta } = appSocials || emptyObj
    const { key: avatarImageKey = '' } = avatar || emptyObj
    const { bio, website, socials, location } = profile || emptyObj
    const { instagram } = socials || emptyObj
    const { zip } = location || emptyObj
    const [preSelectedItems, setPreselectedItems] = useState([])
    const [selectedKeys, setSelectedKeys] = useState([])
    const [loading, setLoading] = useState(true)
    const [uploading, setUploading] = useState(false)
    const updateUserMutation = useMutation(UPDATE_USER, {
        onCompleted: () => updateUserProfileStatus(true),
        onError: () => updateUserProfileStatus(false),
    })
    const { isMobile } = useDimensions()

    const goToInstructorAccount = () => navigation.navigate(INSTRUCTOR_ACCOUNT)
    const evaluateUsernameQueryResponse = data => {
        if (!data || data?.listUsers?.items?.length == 0) return true
        const user = data?.listUsers?.items?.[0] ?? {}
        return user.id == id
    }

    const reactiveFormProps = useReactiveForm({
        currentPage: INSTRUCTOR_ACCOUNT,
        reactiveForm: userProfileForm,
        fieldNames,
        fieldErrors: instructorProfileFieldErrors,
        validationFunc: instructorAccountValidationFunc,
        fieldQueryValidation: {
            fieldName: fieldNames.username,
            query: LIST_USERS,
            getFilter: () => ({ [fieldNames.username]: { eq: userProfileForm()?.username } }),
            evaluateQueryResponse: evaluateUsernameQueryResponse,
            queryErrorObj: { showError: true, error: instructorProfileFieldErrors.uniqueUsername },
        },
    })
    const { isValid, getIsValid, handleInvalidPage, updateForm } = reactiveFormProps
    const form = useReactiveVar(userProfileForm)

    const [actionLoading, setActionLoading] = useState(false)
    const [userProfileStatus, setUserProfileStatus] = useState({ actionSuccess: false, statusMessage: '' })

    async function handleDeletePhoto() {
        userProfileForm({ ...userProfileForm(), avatar: { bucket: '', region: '', key: '' } })
        updateUserAvatar({ id, _version, updateUserMutation })
    }
    async function handleUploadPhoto() {
        const fileName = firstName + lastName + 'profilePicture'
        await pickFile({
            fileName,
            id,
            setUploading,
            bucketPath: 'profilePictures',
            updateForm,
            fieldName: 'avatar',
        })
        updateUserAvatar({ id, _version, updateUserMutation })
    }

    async function handleSave() {
        const localValid = getIsValid()
        if (isValid || localValid) {
            setActionLoading(true)
            await updateUserProfile({ id, _version, updateUserMutation })
        } else {
            handleInvalidPage()
        }
    }

    function updateUserProfileStatus(actionSuccess) {
        const statusMessage = actionSuccess ? 'Profile updated successfully' : 'Error updating profile'
        setTimeout(() => {
            setActionLoading(false)
            setUserProfileStatus({ actionSuccess, statusMessage })
        }, 1000)
    }

    function handleItemSelection({ value }) {
        if (value.length < selectedKeys.length) {
            const keyRemoved = selectedKeys.filter(key => !value.includes(key))
            let newValue = false
            updateForm({
                fieldName: 'specializations',
                nestedField: { [fieldNames.specializations[keyRemoved[0]]]: newValue },
            })
            setSelectedKeys(value)
        } else {
            const keyAdded = value?.[value.length - 1]
            const currentValue = form?.specializations?.[keyAdded]

            if (keyAdded) {
                updateForm({
                    fieldName: 'specializations',
                    nestedField: {
                        [fieldNames.specializations?.[keyAdded]]: true,
                    },
                })
            }
            setSelectedKeys(value)
        }
    }
    useEffect(() => {
        existingApplication?.loading == false && setLoading(false)
    }, [existingApplication])

    useEffect(() => {
        const existingSelection = Object?.keys(specializations || {})?.filter(
            key => specializations[key] && key != '__typename',
        )
        setPreselectedItems(existingSelection)
        setSelectedKeys(existingSelection)
    }, [specializations])

    useEffect(() => {
        const categoryFilterString = selectedKeys?.join()
        updateForm({
            fieldName: fieldNames.specializationFilter,
            value: categoryFilterString,
        })
    }, [selectedKeys])

    useEffect(() => {
        if (userProfileStatus.statusMessage)
            setTimeout(() => {
                setUserProfileStatus({ actionSuccess: false, statusMessage: '' })
            }, 3000)
    }, [userProfileStatus])

    useEffect(() => {
        if (firstName) {
            userProfileForm({
                ...userProfileForm(),
                firstName: firstName,
                lastName,
                bio,
                website,
                socials,
                location,
                specializations: specializations !== null ? specializations : initialSpecializations,
                username,
            })
        }
    }, [firstName])

    return (
        <ScreenContainer hasTopPadding>
            <ContentContainer isFluid>
                {loading && <ActivityIndicator size="large" animating={true} color={colors.homebodyTurquoise} />}
                {firstName ? (
                    <>
                        <RowContainer>
                            <Headline isLargeHeadline paddingBottom={25} isMostBold>
                                My Account
                            </Headline>
                        </RowContainer>
                        <AvatarUploadContainer>
                            <Caption isSmall paddingBottom={15} color={colors.subTextGray}>
                                Your Profile Picture
                            </Caption>
                            <FieldRowContainer>
                                <StyledAvatar size={75} uploading={uploading} />
                                <PhotoUploadContainer>
                                    <ButtonContainer>
                                        <UploadNewButton
                                            isWhiteText
                                            showIcon={false}
                                            onPress={handleUploadPhoto}
                                            customButtonText={'Upload new'}
                                        />
                                        <DeletePhotoButton
                                            showIcon={false}
                                            disabled={avatarImageKey == ''}
                                            onPress={handleDeletePhoto}
                                            customButtonText={'Delete photo'}
                                        />
                                    </ButtonContainer>
                                    <Caption color={colors.subTextGray}>
                                        Your Profile Picture helps your fans recognize you on HOMEBODY
                                    </Caption>
                                </PhotoUploadContainer>
                            </FieldRowContainer>
                        </AvatarUploadContainer>
                        <FieldRowContainer>
                            <InputField
                                smallTitle
                                reactiveForm={true}
                                fieldTitle="*First Name"
                                fieldName={fieldNames.firstName}
                                defaultValue={firstName ?? ''}
                                {...reactiveFormProps}
                            />
                            <InputFieldRowSpacer />
                            <InputField
                                smallTitle
                                reactiveForm={true}
                                fieldTitle="*Last Name"
                                fieldName={fieldNames.lastName}
                                defaultValue={lastName ?? ''}
                                {...reactiveFormProps}
                            />
                        </FieldRowContainer>
                        <FieldRowContainer>
                            <InputField
                                smallTitle
                                reactiveForm={true}
                                shouldSanitizeInput
                                sanitizeRegex={SANITIZE_INPUT_TYPES.USERNAME_INPUT}
                                fieldTitle="Username"
                                fieldName={fieldNames.username}
                                defaultValue={username ?? ''}
                                {...reactiveFormProps}
                            />
                            <InputFieldRowSpacer />
                            <InputField
                                smallTitle
                                reactiveForm={true}
                                fieldTitle="*Instagram Handle"
                                fieldName="socials"
                                nestedFieldName={fieldNames.socials.instagram}
                                defaultValue={instagram ? instagram : appInsta ? appInsta : ''}
                                {...reactiveFormProps}
                            />
                        </FieldRowContainer>

                        <FieldRowContainer style={{ zIndex: 10 }}>
                            <InputField
                                smallTitle
                                reactiveForm={true}
                                fieldTitle="Zip"
                                fieldName="location"
                                nestedFieldName={fieldNames.location.zip}
                                defaultValue={zip ?? ''}
                                {...reactiveFormProps}
                            />
                            <InputFieldRowSpacer />
                            <DropDownPicker
                                smallTitle
                                multipleSelection
                                isNativeLabelStyle
                                alignSelf="left"
                                reactiveForm={true}
                                fieldTitle="Specializations"
                                placeholder="Select a specialization"
                                shouldPushContent={false}
                                containerStyle={{ zIndex: 10 }}
                                selected={selectedKeys}
                                fieldName={fieldNames.specializations}
                                dropDownItems={specializationPickerItems}
                                onChangeValue={value => handleItemSelection(value)}
                                width="60%"
                                {...reactiveFormProps}
                            />
                        </FieldRowContainer>
                        <FieldRowContainer>
                            <InputField
                                smallTitle
                                style={{ height: 150, padding: 10 }}
                                multiline={true}
                                reactiveForm={true}
                                fieldTitle="Bio"
                                fieldName={fieldNames.bio}
                                defaultValue={bio ?? ''}
                                maxLength={BIO_CHAR_LIMIT}
                                {...reactiveFormProps}
                            />
                        </FieldRowContainer>
                        <FieldRowContainer style={{ marginTop: 25, justifyContent: 'flex-end' }}>
                            <ContinueButton
                                nonHover
                                onPress={handleSave}
                                text="Save"
                                colors={colors.blueContinue}
                                style={{
                                    width: 148.32,
                                    height: 61.52,
                                }}
                                messageStyle={{ paddingRight: 25 }}
                                apolloActionStatus={userProfileStatus}
                                actionLoading={actionLoading}
                            />
                        </FieldRowContainer>
                    </>
                ) : null}
            </ContentContainer>
            <FooterBar />
        </ScreenContainer>
    )
}
const StyledAvatar = styled(UserAvatar)`
    height: 75px;
    width: 75px;
`
const PhotoUploadContainer = styled(View)`
    flex-direction: column;
    align-items: flex-start;
    margin-left: 22px;
`
const ButtonContainer = styled(View)`
    flex-direction: row;
    justify-content: space-betweeen;
    margin-bottom: 7.5px;
`
const AvatarUploadContainer = styled(FieldContainer)`
    padding-top: 30px;
    padding-bottom: 30px;
`

const UploadNewButton = styled(UploadButton)`
    width: 157px;
    height: 52px;
    border-radius: 15px;
    background-color: ${colors.homebodyGreen};
    justify-content: center;
    align-items: center;
    margin-right: 15px;
`

const DeletePhotoButton = styled(UploadNewButton)`
    background-color: ${colors.neutralGray};
`
