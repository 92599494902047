import { useEffect } from 'react'
import { isWeb } from '@constants'
import { AUDIENCE_EVENTS } from 'constants/streamEvents'
import { leaveStream, sendPeerToPeerMessage } from 'agoraActions'
import { useNavigation } from '@react-navigation/native'
import { TRAINEE_LEAVE_REVIEW } from 'screenNames'
import { getNavigationAction, navigateTraineeHomeAction } from 'navigationActions'
const {
    AUDIENCE_BE_RIGHT_BACK,
    AUDIENCE_HOST_HIDE_VIDEO,
    AUDIENCE_HOST_MUTE_AUDIO,
    AUDIENCE_SET_HOST_UID,
    AUDIENCE_SUBSCRIBE_TO_HOST_TRACKS,
    AUDIENCE_UNSUBSCRIBE_FROM_HOST_TRACKS,
    AUDIENCE_MESSAGE_FROM_HOST,
    AUDIENCE_STREAM_ENDED,
    AUDIENCE_LEAVE_STREAM,
    AUDIENCE_SEND_MESSAGE_TO_HOST,
    AUDIENCE_STREAM_ROOM_UNLOCKED,
    AUDIENCE_SEND_HOST_INFO,
} = AUDIENCE_EVENTS

export const useHandleAgoraAudienceEvents = reactiveStreamProps => {
    const { streamState, streamStateSetters, audienceEventEmitters, streamHelpers, user } = reactiveStreamProps
    const {
        setHost,
        setTrackState,
        setStreamEnded,
        setStreamStatusMessage,
        setStreamRoomLocked,
        setHostUID,
        setIsLoggedInToRTM,
    } = streamStateSetters
    const {
        channelName,
        streamClient,
        messagesClient,
        messagesChannel,
        uid,
        streamEvent,
        streamEventQueue,
        trackState,
        host,
        hostUID,
        logAudienceEvents,
        classComplete,
        classEnded,
        instructorClass,
        isLoggedInToRTM,
        streamEnded,
    } = streamState
    const { traineeJoined, traineeLeft } = audienceEventEmitters
    const { parseEventTriggerMessage, triggerEvent: triggerAudienceEvent } = streamHelpers
    const { avatar, profile } = user || {}
    const { firstName, lastName } = profile || {}
    const navigation = useNavigation()
    const shouldLogEvents = __DEV__ && logAudienceEvents

    const navigateTraineeHome = getNavigationAction({
        navigation,
        navigationAction: navigateTraineeHomeAction,
    })

    const goToLeaveReview = () =>
        navigation.navigate(TRAINEE_LEAVE_REVIEW, { instructorClass, classID: instructorClass?.id })

    const handleAudienceEvents = async queuedEvent => {
        const { event: audienceEvent, payload } = queuedEvent ? queuedEvent : streamEvent || {}
        switch (audienceEvent) {
            case AUDIENCE_STREAM_ROOM_UNLOCKED:
                if (shouldLogEvents) console.log('AUDIENCE_STREAM_ROOM_UNLOCKED')
                setStreamRoomLocked(false)
                break

            case AUDIENCE_BE_RIGHT_BACK:
                if (shouldLogEvents) console.log('AUDIENCE_BE_RIGHT_BACK')
                setTrackState({ video: false, audio: false, hostVideoDisabled: true, hostAudioDisabled: true })
                setStreamStatusMessage('Host will be right back')
                break

            case AUDIENCE_SET_HOST_UID:
                if (shouldLogEvents) console.log('AUDIENCE_SET_HOST_UID')
                if (hostUID == '') {
                    const { hostUid } = payload
                    setHostUID(hostUid)
                }
                break

            case AUDIENCE_SEND_HOST_INFO:
                if (shouldLogEvents) console.log('AUDIENCE_SEND_HOST_INFO')
                const info = { uid, firstName, lastName, avatarKey: avatar?.key }
                traineeJoined(info)
                break

            case AUDIENCE_SEND_MESSAGE_TO_HOST:
                if (shouldLogEvents) console.log('AUDIENCE_SEND_MESSAGE_TO_HOST')
                const { message: text } = payload
                await sendPeerToPeerMessage({ messagesClient, text, peerId: hostUID })
                break

            case AUDIENCE_HOST_HIDE_VIDEO:
                if (shouldLogEvents) console.log('AUDIENCE_HOST_HIDE_VIDEO')
                setTrackState({ ...trackState, video: false, hostVideoDisabled: true })
                setStreamStatusMessage('Host has hidden their video')
                break

            case AUDIENCE_HOST_MUTE_AUDIO:
                if (shouldLogEvents) console.log('AUDIENCE_HOST_MUTE_AUDIO')
                setTrackState({ ...trackState, audio: false, hostAudioDisabled: true })
                break

            case AUDIENCE_SUBSCRIBE_TO_HOST_TRACKS:
                if (shouldLogEvents) console.log('AUDIENCE_SUBSCRIBE_TO_HOST_TRACKS')
                const { user, mediaType } = payload
                await streamClient?.subscribe(user, mediaType)
                if (mediaType == 'video') {
                    setTrackState({ ...trackState, video: true, hostVideoDisabled: false })
                }
                if (mediaType == 'audio') {
                    setTrackState({ ...trackState, audio: true, hostAudioDisabled: false })
                    user.audioTrack?.play()
                }
                setHost(user)
                break

            case AUDIENCE_UNSUBSCRIBE_FROM_HOST_TRACKS:
                if (shouldLogEvents) console.log('AUDIENCE_UNSUBSCRIBE_FROM_HOST_TRACKS')
                setTrackState({ ...trackState, video: false, audio: false })
                break

            case AUDIENCE_MESSAGE_FROM_HOST:
                if (shouldLogEvents) console.log('AUDIENCE_MESSAGE_FROM_HOST')
                const { event: audienceEventTrigger, payload: audienceEventPayload } = parseEventTriggerMessage(payload)
                triggerAudienceEvent({ event: audienceEventTrigger, payload: audienceEventPayload })
                break

            case AUDIENCE_LEAVE_STREAM:
                if (shouldLogEvents) console.log('AUDIENCE_LEAVE_STREAM')
                const traineeInfo = { uid }
                if (!streamEnded) {
                    traineeLeft(traineeInfo)
                }
                setTrackState({ ...trackState, video: false, audio: false })
                const shouldLeaveReview = classComplete || classEnded
                const navigationAction = shouldLeaveReview ? goToLeaveReview : navigateTraineeHome
                if (isWeb) {
                    await leaveStream({
                        host,
                        streamClient,
                        messagesClient,
                        messagesChannel,
                        navigationAction,
                        isLoggedInToRTM,
                        setIsLoggedInToRTM,
                    })
                } else {
                    await leaveStream({ channelName, messagesClient, navigationAction })
                }
                break

            case AUDIENCE_STREAM_ENDED:
                if (shouldLogEvents) console.log('AUDIENCE_STREAM_ENDED')
                setTrackState({ ...trackState, video: false, audio: false })
                setStreamEnded(true)
                setStreamStatusMessage('Host has ended stream')
                triggerAudienceEvent({ event: AUDIENCE_LEAVE_STREAM, payload: { streamEnded: true } })
                break

            default:
                break
        }
    }

    useEffect(() => {
        handleAudienceEvents()
    }, [streamEvent])

    useEffect(() => {
        streamEventQueue.forEach(async queuedEvent => await handleAudienceEvents(queuedEvent))
    }, [streamEventQueue])
}

export default useHandleAgoraAudienceEvents
