import { useState, useEffect, useRef } from 'react'
import { flatten } from 'helpers'
import { useLazyQuery } from '@apollo/client'
import { useRoute } from '@react-navigation/core'
import _ from 'lodash'
const emptyObj = {}
const noop = () => {}

const useReactiveForm = ({
    reactiveForm,
    currentPage,
    fieldNames,
    fieldErrors,
    validationFunc,
    fieldQueryValidation = emptyObj,
}) => {
    const [errors, setErrors] = useState(fieldErrors || {})
    const [populateErrors, setPopulateErrors] = useState(false)
    const [touched, setTouched] = useState({})
    const [onBlur, setOnBlur] = useState(false)
    const onChangeRef = useRef({})
    const [queryError, setQueryError] = useState(false)
    const { query, fieldName, evaluateQueryResponse = noop, queryErrorObj, getFilter = noop } = fieldQueryValidation
    const [fieldQuery, { loading, error, data, called }] = useLazyQuery(query, {
        variables: {
            filter: getFilter(),
        },
    })

    const validateFieldQuery = () => {
        if (!fieldQueryValidation.fieldName) return
        const isValid = evaluateQueryResponse(data)
        if (!isValid) {
            setErrors({ ...errors, [fieldName]: queryErrorObj })
            return setQueryError(true)
        }

        setQueryError(false)
    }

    useEffect(validateFieldQuery, [loading])

    const formRendered = useRef(true)

    useEffect(() => {
        if (formRendered.current) {
            setErrors({})
            setTouched({})
            setOnBlur()
        }
        formRendered.current = false
    }, [reactiveForm()])

    const updateForm = ({ fieldName = '', value, nestedField }) => {
        if (nestedField) {
            reactiveForm({
                ...reactiveForm(),
                [fieldName]: { ...reactiveForm()[fieldName], ...nestedField },
            })
        } else {
            reactiveForm({ ...reactiveForm(), [fieldName]: value })
        }
    }

    const handleBlur = ({ fieldName, nestedFieldName = '' }) => {
        if (fieldName === fieldQueryValidation.fieldName) {
            fieldQuery()
            if (called) return validateFieldQuery()
        }
    }

    const getFieldError = ({ fieldName, nestedFieldName = '' }) => {
        const isQueryField = fieldName == fieldQueryValidation.fieldName
        if (isQueryField && queryError) return queryErrorObj
        const valid = validationFunc({ currentPage })
        const isNested = nestedFieldName != ''
        const formattedFieldName = isNested ? `${fieldName}.${nestedFieldName}` : fieldName
        const error = isNested ? _.get(fieldErrors, formattedFieldName) : fieldErrors[formattedFieldName]
        const fieldValid = isNested ? _.get(valid, formattedFieldName) : valid[formattedFieldName]
        return !fieldValid && error ? { showError: true, error } : { showError: false }
    }

    function handleInvalidPage() {
        setPopulateErrors(true)
        const newErrors = {}
        const currentPageValidations = validationFunc({ currentPage })
        //Get the current values belonging to components requiring validation on the current page
        Object.entries(currentPageValidations).map(([key, value]) => {
            if (typeof value === 'object') {
                //Validation for component with nested keys
                return Object.keys(value)
                    .filter(nestedKey => nestedKey != '__typename') //remove __typename key generated by graphql
                    .map(nestedKey => {
                        if (!newErrors[key]) newErrors[key] = {} //initialize nested key which will contain nested error
                        const fieldError = getFieldError({ fieldName: key, nestedFieldName: nestedKey })
                        newErrors[key] = {
                            ...newErrors[key],
                            [nestedKey]: fieldError,
                        }
                    })
            } else {
                //validation for component w/o nested keys
                newErrors[key] = getFieldError({ fieldName: key })
            }
        })
        setErrors(queryError ? { ...errors, ...newErrors } : newErrors)
    }

    function getIsValid() {
        const isFieldQueryValid = !fieldQueryValidation.query || (!loading && !queryError)
        return isFieldQueryValid && Object.values(flatten(validationFunc({ currentPage }))).every(val => !!val)
    }

    //flatten helper method is used here to account for nested validation keys
    const isFieldQueryValid = !fieldQueryValidation.query || (!loading && !queryError)
    const isValid = isFieldQueryValid && Object.values(flatten(validationFunc({ currentPage }))).every(val => !!val)

    return {
        isValid,
        errors,
        populateErrors,
        touched,
        updateForm,
        handleBlur,
        getFieldError,
        getIsValid,
        handleInvalidPage,
    }
}

export default useReactiveForm
