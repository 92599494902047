import React, { useMemo } from 'react'
import styled from 'styled-components/native'
import colors from 'colors'
import VectorIcon from './VectorIcon'
import { RowContainer } from 'commons/containers'
const noop = () => {}

export const StyledRatingButton = styled.TouchableOpacity`
    width: ${props => `${props.size}px` || '30px'};
    height: ${props => `${props.size}px` || '30px'};
    margin-right: 5px;
    background-color: ${colors.white};
    justify-content: center;
    align-items: center;
`

export const RatingMap = ({ rating, style, starSize = 35 }) => {
    const numIcons = [1, 2, 3, 4, 5]
    const mappedIcons = numIcons.map(index => {
        return (
            <VectorIcon
                family="FontAwesome"
                name="star"
                color={rating >= index ? colors.homebodyTurquoise : colors.grayContinue}
                size={starSize}
                key={index}
                style={{ marginRight: 5 }}
            />
        )
    })

    return <RowContainer style={style}>{mappedIcons}</RowContainer>
}

const RateIcon = ({ onPress = noop, size, index, rating }) => {
    const fill = useMemo(() => {
        if (rating >= index) {
            return colors.homebodyTurquoise
        }
        return colors.grayContinue
    }, [rating, index])

    return (
        <StyledRatingButton onPress={() => onPress(index)} size={size}>
            <VectorIcon family="FontAwesome" name="star" color={fill} size={size} />
        </StyledRatingButton>
    )
}

export default RateIcon
