import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity } from 'react-native'
import colors from 'colors'
import styled from 'styled-components/native'

export const AppCrashed = ({}) => {
    return (
        <AppCrashedContainer>
            <Text>Something went wrong! Reload and try again</Text>
        </AppCrashedContainer>
    )
}

const AppCrashedContainer = styled(View)`
    flex: 1;
    justify-content: center;
    align-items: center;
`

export default AppCrashed
