import React, { useEffect } from 'react'
import { Text, View, Image, TouchableOpacity, StyleSheet } from 'react-native'
import styled from 'styled-components/native'
import colors from 'colors'
import { RowContainer } from 'commons/containers'
import { LinearGradient } from 'expo-linear-gradient'
import { Inter600, TabBarText } from 'typography'
const gradientStart = { x: 0.15, y: 0 }
const gradientEnd = { x: 0.85, y: 0 }
const gradientColors = [colors.homebodyTurquoise, colors.homebodyGreen]

export const NavigationIconLabel = ({ title, focused, isTab }) => {
    const { focused: isFocused } = focused
    if (isTab) {
        return <TabBarText color={isFocused ? colors.homebodyTurquoise : colors.subTextGray}>{title}</TabBarText>
    }
    if (isFocused) {
        return (
            <LabelContainer>
                <StyledLinearGradient start={gradientStart} end={gradientEnd} colors={gradientColors} />
                <TitleText isFocused={isFocused}>{title}</TitleText>
            </LabelContainer>
        )
    } else {
        return (
            <LabelContainer>
                <TitleText isFocused={isFocused}>{title}</TitleText>
            </LabelContainer>
        )
    }
}
const StyledLinearGradient = styled(LinearGradient)`
    flex-direction: row;
    justify-content: flex-start;
    z-index: -5;
    position: absolute;
    height: 100px;
    width: 250px;
    bottom: -50px;
    right: -50px;
`
const TitleText = styled(Inter600)`
    color: ${props => (props.isFocused ? 'white' : colors.subTextGray)};
    font-size: 14px;
`
const LabelContainer = styled(RowContainer)`
    right: 20px;
    width: 150px;
`

export default NavigationIconLabel
