import { useEffect, useState, useCallback } from 'react'
import { isClassTimeWithinTenMinutes } from 'helpers'
import { agoraStreamState } from 'apollo/cache'
import { useReactiveVar } from '@apollo/client'
import AgoraRTM from 'agora-rtm-sdk'
import useAgoraClient from 'hooks/useAgoraClient'
import { isWeb } from '@constants'
const AgoraRTC = isWeb && require('agora-rtc-react')
import useReactiveStreamMethods from 'hooks/useReactiveStreamMethods'
import { useMutation } from 'apollo-augmented-hooks'
import { UPDATE_STREAM, UPDATE_CLASS } from 'apollo/mutations'
import { updateStream, updateClassStatus } from 'actions'
import { createRTCChannel, createRTMChannel, joinRTCChannel, joinRTMChannel } from 'agoraActions'
import { getCurrentUser, getClass } from 'apollo/selectors'
import { CLASS_STATUS, ENVIRONMENT_VARIABLES } from '@constants'
const { AGORA_APP_ID = '' } = ENVIRONMENT_VARIABLES
const { IN_PROGRESS } = CLASS_STATUS
/* 
    0 DEBUG: all logs
    1 INFO: logs of info, warning, and error levels
    2 WARNING: logs of warning and error levels
    3 ERROR: logs of error level
    4 NONE: no logs
*/
AgoraRTC.setLogLevel(__DEV__ ? 4 : 4)
const agoraRTMClient = AgoraRTM.createInstance(AGORA_APP_ID, {
    logFilter: __DEV__ ? AgoraRTM.LOG_FILTER_OFF : AgoraRTM.LOG_FILTER_OFF,
})

const emptyObj = {}

const useReactiveStream = ({ classID, isHost = false, numBooked = 0 }) => {
    const user = getCurrentUser()
    const { useClient } = useAgoraClient()
    const [shouldStartStreamRoomInterval, setShouldStartStreamRoomInterval] = useState(false)
    const streamState = useReactiveVar(agoraStreamState)
    const {
        streamClient,
        messagesClient,
        messagesChannel,
        uid,
        streamRoomLocked,
        channelName,
        streamReady,
        tracks,
        tracksReady,
        streamEnded,
        traineesAttendingClass,
        instructorClass,
    } = streamState
    const { streamStateSetters, streamHelpers, classEventEmitters, hostEventEmitters, audienceEventEmitters } =
        useReactiveStreamMethods({ streamState })
    const {
        setStreamRoomLocked,
        setStreamClient,
        setMessagesClient,
        setStreamReady,
        setMessagesChannel,
        setIsLoggedInToRTM,
        setHasJoinedRTCChannel,
        setChannelName,
        setStreamRoomJoined,
        setInstructorClass,
        setIsHost,
        setNumTraineesBooked,
        setWaitingRoomJoined,
    } = streamStateSetters
    const { displayHostOverlayMessage, requestTraineeInfo } = hostEventEmitters
    const updateStreamMutation = useMutation(UPDATE_STREAM, {
        onCompleted: ({ updateStream }) => {
            setInstructorClass({
                ...classToStream,
                stream: {
                    ...classToStream.stream,
                    _version: updateStream?._version,
                    isHostInStreamRoom: updateStream?.isHostInStreamRoom,
                },
            })
        },
    })
    const updateClassMutation = useMutation(UPDATE_CLASS, {
        onCompleted: () => {
            setInstructorClass(classToStream)
        },
    })

    const { startTime, stream: { id: streamID, _version } = emptyObj } = instructorClass || emptyObj
    const classToStream = getClass({
        classID,
        onCompleted: async () => {
            __DEV__ && console.log('classToStream: ', classToStream)
            setInstructorClass(classToStream)
            if (isHost) {
                const { startTime, stream: { id: streamID, _version } = emptyObj } = classToStream
                const shouldUpdateHostInStreamRoom = isClassTimeWithinTenMinutes(startTime)
                if (shouldUpdateHostInStreamRoom) {
                    setTimeout(
                        async () => {
                            await updateStream({ streamID, _version, isHostInStreamRoom: true, updateStreamMutation })
                        },
                        __DEV__ ? 0 : 7500,
                    )
                } else {
                    setShouldStartStreamRoomInterval(true)
                }
            } else {
                if (classToStream?.classStatus == IN_PROGRESS && streamRoomLocked) {
                    setStreamRoomLocked(false)
                }
            }
        },
    })

    async function handleUpdateClassStatus({ Class, classStatus }) {
        const { id, _version } = Class || emptyObj
        const updatedClass = await updateClassStatus({
            updateClassMutation,
            id,
            _version,
            classStatus,
        })
        setInstructorClass(updatedClass)
    }

    async function handleUpdateStreamRecord({ isHostInStreamRoom }) {
        const { stream: { _version: updatedVersion } = emptyObj } = instructorClass || emptyObj
        await updateStream({ streamID, _version: updatedVersion, isHostInStreamRoom, updateStreamMutation })
    }

    async function initializeStream() {
        if (!streamReady && tracks && tracksReady) {
            await createRTCChannel({ uid, channelName, streamClient, tracks, setStreamReady })
        } else {
            await createRTMChannel({ uid, channelName, messagesClient, setMessagesChannel, setIsLoggedInToRTM })
            displayHostOverlayMessage({ overlayMessage: 'Position your camera to fit the screen', delay: 2000 })
        }
    }

    async function joinClass() {
        await joinRTCChannel({ uid, channelName, streamClient, setHasJoinedRTCChannel })
        setStreamRoomJoined(true)
    }

    async function joinTraineeWaitingRoom() {
        await joinRTMChannel({ uid, channelName, messagesClient, setMessagesChannel, setIsLoggedInToRTM })
        setWaitingRoomJoined(true)
    }

    const initializeStreamState = () => {
        setNumTraineesBooked(numBooked)
        setChannelName(classID)
        if (streamClient == undefined) {
            const agoraRTCClient = useClient()
            if (isHost) {
                agoraRTCClient.setClientRole('host')
                setIsHost(true)
                setStreamRoomJoined(true)
            }
            setStreamClient(agoraRTCClient)
        }
        if (messagesClient == undefined) {
            setMessagesClient(agoraRTMClient)
        }
    }

    useEffect(() => {
        initializeStreamState()
    }, [])

    useEffect(() => {
        let updateStreamObject
        if (shouldStartStreamRoomInterval) {
            updateStreamObject = setInterval(async () => {
                const shouldUpdateStreamObject = isClassTimeWithinTenMinutes(startTime)
                if (shouldUpdateStreamObject) {
                    await updateStream({ streamID, _version, isHostInStreamRoom: true, updateStreamMutation })
                    clearInterval(updateStreamObject)
                }
            }, 15000)
        }
        return () => {
            clearInterval(updateStreamObject)
        }
    }, [shouldStartStreamRoomInterval])

    async function getMembers(traineesInClass) {
        const channelMembers = await messagesChannel?.getMembers()
        const traineeUIDs = traineesInClass?.map(({ uid }) => uid?.toString())
        const hostUID = uid
        const channelMembersNotInTraineeList = channelMembers?.filter(
            traineeUID => !traineeUIDs?.includes(traineeUID) && traineeUID !== hostUID?.toString(),
        )

        for (const traineeUID of channelMembersNotInTraineeList) {
            requestTraineeInfo(traineeUID)
        }

        return channelMembers
    }

    const updateTraineesInClass = useCallback(async () => {
        if (traineesAttendingClass?.length > 0 && !streamEnded) {
            await getMembers(traineesAttendingClass)
        }
    }, [traineesAttendingClass, streamEnded])

    useEffect(() => {
        let interval
        if (!streamRoomLocked && isHost) {
            interval = setInterval(async () => {
                updateTraineesInClass()
            }, 10000)
        }

        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }
    }, [streamRoomLocked, isHost, updateTraineesInClass])

    return {
        user,
        streamState,
        streamStateSetters,
        streamHelpers,
        classEventEmitters,
        hostEventEmitters,
        audienceEventEmitters,
        handleUpdateClassStatus,
        handleUpdateStreamRecord,
        joinClass,
        joinTraineeWaitingRoom,
        initializeStreamState,
        initializeStream,
    }
}

export default useReactiveStream

/*
    Useful info:
        We are using the Agora RTC (Real time communication) Web SDK API v4.6.0
            docs: https://docs.agora.io/en/Interactive%20Broadcast/API%20Reference/web_ng/interfaces/iagorartc.html#setloglevel
        We are using the Agora RTM (Real time messaging) Web API v1.4.3
            docs: https://docs.agora.io/en/Real-time-Messaging/API%20Reference/RTM_web/index.html
*/
