import { makeVar, InMemoryCache } from '@apollo/client'
import {
    initialSignUpForm,
    initialInstructorApplicationForm,
    initialCreateClassForm,
    initialUserProfileForm,
    initialAgoraStreamState,
    initialPaymentMethodForm,
} from 'initialFormStates'
const emptyObj = {}
const emptyArr = []

export const currentUserID = makeVar('')
export const searchBody = makeVar('')
export const signUpForm = makeVar(initialSignUpForm)
export const instructorApplicationForm = makeVar(initialInstructorApplicationForm)
export const createClassForm = makeVar(initialCreateClassForm)
export const userProfileForm = makeVar(initialUserProfileForm)
export const isLoggedIn = makeVar(false)
export const agoraStreamState = makeVar(initialAgoraStreamState)
export const paymentMethodForm = makeVar(initialPaymentMethodForm)

/*
  field: type of record being merged into the cache
  typename: if the record contains a __typename field
  showLogs: if logs for the customMergePolicy should be shown
  checkField: the field to check against the value being read from the cache
*/
function customMergePolicy({ field, typename = true, showLogs = false, checkField = 'userID' }) {
    return {
        // Merge the new record into the existing record in the cache.
        merge(existing, incoming, { readField, mergeObjects }) {
            showLogs &&
                console.log(field, 'merge\n\n', `existing ${field}: `, existing, `\n\nincoming ${field}: `, incoming)

            /*
              Filter out deleted records from existing data
            */
            let existingData = Array.isArray(existing)
                ? existing?.filter(item => readField('_deleted', item) !== true)
                : existing?.items?.filter(item => readField('_deleted', item) !== true) || emptyArr

            /*
              Filter out deleted records from incoming data
            */
            let incomingData = Array.isArray(incoming)
                ? incoming?.filter(item => readField('_deleted', item) !== true)
                : incoming?.items?.filter(item => readField('_deleted', item) !== true) || emptyArr

            /*
              If there is no existing data, or the existing data is an empty array, return incoming data 
            */
            if (!existing || existingData?.length === 0) {
                const incomingDataResult = typename ? { ...incoming, items: incomingData } : incomingData
                showLogs && console.log(field, 'merge result: ', result)
                return incomingDataResult
            }

            /*
              Create a map consisting of the indices of the existing data with the key being the id of the cached record
            */
            const existingDataIndices = new Map(
                existingData?.map((item, index) => [readField('id', item), index]),
                [existingData],
            )

            /*
              Initialize value of mergedData to existingData
              Iterate over the incoming data and merge each item with the existing data at the appropriate index
            */
            const mergedData = existingData
            incomingData.forEach(item => {
                const id = readField('id', item)
                const index = existingDataIndices.get(id)
                if (typeof index === 'number') {
                    // Merge the new item data with the existing item data at the appropriate index
                    mergedData[index] = mergeObjects(mergedData[index], item)
                } else {
                    // First time we've seen this item in this array, append to the end of the array
                    existingDataIndices[id] = mergedData?.length
                    mergedData?.push(item)
                }
            })

            /*
              If the typename is true, return the values with a typename field else return values
            */
            const result = typename ? { ...incoming, items: mergedData } : mergedData
            showLogs && console.log(field, 'merge result: ', result)
            return result
        },

        // Read the field from the cache and return the value
        read(existing, { readField, variables = emptyObj, args }) {
            showLogs &&
                console.log(
                    field,
                    'read\n\n',
                    `existing ${field}: `,
                    existing,
                    '\n\nvariables: ',
                    variables,
                    '\n\nargs: ',
                    args,
                )

            /*
              If existing data exists in any of it's possible forms
            */
            if (existing || existing?.items || existing?.items?.length !== 0) {
                let existingData = Array.isArray(existing) ? existing : existing?.items || emptyArr

                const id = variables?.id || ''
                const filter = variables?.filter || emptyObj

                /*
                  If a filter exists and logs are enabled, log the filter
                */
                if (Object?.keys(filter)?.length > 0 && showLogs) {
                    console.log(field, 'filter: ', filter)
                    console.log(field, 'Filter entries', Object?.entries(filter))
                }

                /*
                  If variables are passed and there is existing data 
                  filter existingData and return items that have a checkField value equal to id passed in the variables
                  else return existing data
                */
                const values =
                    variables !== emptyObj && existingData !== emptyArr
                        ? existingData?.filter(item => readField(checkField, item) === id)
                        : existingData
                /*
                  If the typename is true, return the values with a typename field else return values
                */
                const result = typename ? { ...existing, items: values } : values
                showLogs && console.log(field, 'read result\n\n', result)
                return result
            }
        },
    }
}

export const cache = new InMemoryCache({
    addTypename: true,
    typePolicies: {
        User: {
            merge: true,
            fields: {
                // Ensure all composite records which may exist on user record are merged with customMergePolicy
                classes: customMergePolicy({ field: 'user classes', checkField: 'id' }),
                files: customMergePolicy({ field: 'user files' }),
                favorites: customMergePolicy({ field: 'user favorites' }),
                classBookings: customMergePolicy({ field: 'user bookings' }),
                reviews: customMergePolicy({ field: 'user reviews' }),
                notifications: customMergePolicy({ field: 'user notifications' }),
                transactions: customMergePolicy({ field: 'user transactions' }),
                profile: {
                    merge: true,
                },
                credits: {
                    merge: true,
                },
            },
        },
        Class: {
            merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
            },
            keyFields: ['id', 'userID'],
            fields: {
                // Ensure that classBookings which may exist on class record are merged with customMergePolicy
                classBookings: customMergePolicy({ field: 'class bookings', checkField: 'classID' }),
            },
        },
        File: {
            merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
            },
            keyFields: ['id', 'userID'],
        },
        Notification: {
            // Standard merge policy for all standalone records
            merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
            },
            keyFields: ['id', 'userID'],
        },
        FavoriteInstructor: {
            merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
            },
            keyFields: ['id', 'userID'],
        },
        Review: {
            merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
            },
            keyFields: ['id', 'userID'],
        },
        ClassBooking: {
            merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
            },
            keyFields: ['id', 'userID', 'classID'],
        },
        Transactions: customMergePolicy({ field: 'Transactions', typename: false }),
        TrainerApplication: {
            merge(existing, incoming, { mergeObjects }) {
                return mergeObjects(existing, incoming)
            },
        },
        // Local only queries
        Query: {
            fields: {
                getCurrentUserID: {
                    read() {
                        return currentUserID()
                    },
                },
                isLoggedIn: {
                    read() {
                        return !!currentUserID()
                    },
                },
            },
        },
    },
})
