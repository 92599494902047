import React from 'react'
import { View, Modal, StyleSheet } from 'react-native'
import colors from 'colors'
import { Inter700 } from 'typography'
import styled from 'styled-components/native'
import FormButtons from 'components/FormButtons'
import { useDimensions } from 'dimensions'

const headerText = 'Are you sure you want to publish your class?'
const modalDetails =
    '\nYour class will be available on the app for your users to book into.\nPlease note! Once you publish your class you will be unable to make any changes.'

export const PublishClassModal = ({ visible, updateStep, loading, isSavingForm, isGradient }) => {
    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={visible}
            style={{ position: 'absolute', alignSelf: 'center', top: '15%', borderColor: 'transparent' }}
        >
            <View style={{ flex: 1, paddingTop: '15%' }}>
                <ModalContainer>
                    <View>
                        <ModalHeader>{headerText}</ModalHeader>
                        <ModalDetails>{modalDetails}</ModalDetails>
                    </View>
                    <FormButtons
                        isCentered
                        loading={loading}
                        firstButtonText="Save"
                        secondButtonText="Publish"
                        updateStep={updateStep}
                        isGradient={isGradient}
                        firstButtonColor={colors.grayContinue}
                        secondButtonColor={colors.blueContinue}
                        renderLoadingOnFirstButton={isSavingForm}
                        containerStyle={{ width: '75%' }}
                    />
                </ModalContainer>
            </View>
        </Modal>
    )
}

const ModalHeader = styled(Inter700)`
    font-size: 38px;
    line-height: 45px;
    text-align: center;
    color: ${colors.homebodyTurquoise};
`
const ModalDetails = styled(ModalHeader)`
    font-size: 20px;
    color: black;
`
const ModalContainer = styled(View)`
    align-self: center;
    width: 750px;
    height: 500px;
    border-radius: 46.61px;
    border-width: ${StyleSheet.hairlineWidth}px;
    border-color: gray;
    shadow-color: #000000;
    shadow-offset: 4px 4px;
    shadow-radius: 8px;
    shadow-opacity: 0.25;
    background-color: white;
    padding: 50px;
    justify-content: space-between;
`

export default PublishClassModal
