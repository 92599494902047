import React, { useState } from 'react'
import { Linking } from 'react-native'
// ui
import colors from 'colors'
import styled from 'styled-components/native'
// form
import { FieldContainer, FormBottomRowToColumnContainer } from 'src/commons/containers'
import { ContinueButton } from 'components/ContinueButton'

// components
import { StandardContainer, ScreenContainer, ContentContainer } from 'commons/containers'
import { TitleText } from 'styles/typography'
import { StyledTextInput } from 'commons/components/InputField'
import { View, Text } from 'react-native'
import { Image } from 'react-native-elements/dist/image/Image'
import { FooterBar } from 'commons/components/FooterBar'

const HOMEBODY_LOGO = require('assets/logo.png')

function ContactUsForm() {
    const [isMessageSent, setIsMessageSent] = useState(false)
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [subjectError, setSubjectError] = useState({ showError: false, errorMessage: '' })
    const [messageError, setMessageError] = useState({ showError: false, errorMessage: '' })

    const setEmailSubject = value => setSubject(value)
    const setEmailMessage = value => setMessage(value)

    const clearSubjectError = () => setSubjectError({ showError: false, errorMessage: '' })
    const clearMessageError = () => setMessageError({ showError: false, errorMessage: '' })

    const sendEmail = () => {
        if (subject === '' && message === '') {
            setSubjectError({ showError: true, errorMessage: 'Please provide a subject' })
            setMessageError({ showError: true, errorMessage: 'Please provide a message' })
        } else if (subject === '') {
            setSubjectError({ showError: true, errorMessage: 'Please provide a subject' })
        } else if (message === '') {
            setMessageError({ showError: true, errorMessage: 'Please provide a message' })
        } else {
            Linking.openURL(`mailto:support@homebodylivefitness.com?subject=${subject}&body=${message}`)
            setIsMessageSent(true)
        }
    }

    return (
        <ScreenContainer hasTopPadding>
            <InnerContainer>
                <ContentContainer style={{ paddingHorizontal: '5%', width: '1200px' }}>
                    <ContactUsTitle>Contact Us</ContactUsTitle>
                    {isMessageSent ? (
                        <MessagSentContainer>
                            <HomebodyLogo source={HOMEBODY_LOGO} />
                            <ThanksText>
                                Thanks for getting in touch! A member of our team will be in touch within 24 hours.
                            </ThanksText>
                            <ThanksText>The HB Team</ThanksText>
                        </MessagSentContainer>
                    ) : (
                        <FieldContainer style={{ maxWidth: '100%' }}>
                            <StyledTextInput
                                fieldTitle="Subject"
                                fieldName="subject"
                                defaultValue=""
                                style={{ maxWidth: '450px', backgroundColor: colors.white }}
                                onChangeText={setEmailSubject}
                                errorMessage={subjectError?.showError ? subjectError?.errorMessage : ''}
                                onFocus={clearSubjectError}
                            />
                            <StyledTextInput
                                style={{ height: 400, padding: 20 }}
                                multiline={true}
                                fieldName="message"
                                defaultValue=""
                                placeholder="type what you want here...."
                                onChangeText={setEmailMessage}
                                errorMessage={messageError?.showError ? messageError?.errorMessage : ''}
                                onFocus={clearMessageError}
                            />
                            <ContactFormButtonContainer>
                                <SmallContinueButton
                                    nonHover
                                    text="Send"
                                    color={colors.blueContinue}
                                    onPress={sendEmail}
                                />
                            </ContactFormButtonContainer>
                        </FieldContainer>
                    )}
                </ContentContainer>
            </InnerContainer>
            <FooterBar />
        </ScreenContainer>
    )
}

export default ContactUsForm

const ContactUsTitle = styled(TitleText)`
    margin-top: 5%;
    margin-bottom: 5%;
    margin-right: 3%;
    letter-spacing: -1px;
`
const SmallContinueButton = styled(ContinueButton)`
    width: ${props => (props.theme.isMobile ? '100%' : '148px')};
    height: 56px;
    font-size: 13px;
    margin-bottom: ${props => (props.theme.isMobile ? '10px' : '0px')};
    margin-right: 10px;
`
const ContactFormButtonContainer = styled(FormBottomRowToColumnContainer)`
    top: 0;
    padding: 5% 0 0 0;
    width: 100%;
`

const InnerContainer = styled(View)`
    flex: 1;
    flex-direction: row;
    margin-left: 3%;
    margin-bottom: 5%;
    margin-right: 3%;
    border-radius: 13px;
    shadow-color: #000000;
    shadow-offset: 1px 1px;
    shadow-radius: 10px;
    shadow-opacity: 0.1;
`
const MessagSentContainer = styled(View)`
    display: flex;
    flex-direction: column;
    justify-contant: center;
    align-items: center;
    width: 100%;
`
const HomebodyLogo = styled(Image)`
    width: 354px;
    height: 41px;
    margin-bottom: 51px;
`

const ThanksText = styled(Text)`
    font-size: 20px;
    font-weight: bold;
    color: ${colors.darkGrey};
    margin-bottom: 30px;
    text-align: center;
    max-width: 500px;
    line-height: 45px;
`
