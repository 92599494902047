import { TRAINEE_NOTIFICATION_TYPES, INSTRUCTOR_NOTIFICATION_TYPES } from 'constants/notificationConstants'
import { useMutation } from 'apollo-augmented-hooks'
import { NOTIFICATION_TYPES } from '@constants'
import { CREATE_NOTIFICATION, UPDATE_NOTIFICATION } from 'apollo/mutations'
import { createNotification, updateNotification, sendMultiplePushNotifications } from 'actions'
const emptyObj = {}
const noop = () => {}

export default function useNotifications({ onCompleted = noop } = emptyObj) {
    const createNotificationMutation = useMutation(CREATE_NOTIFICATION, { onCompleted })
    const updateNotificationMutation = useMutation(UPDATE_NOTIFICATION, { onCompleted })

    async function handleCreateNotification({
        userID,
        pushToken,
        content,
        type,
        isScheduledNotifcation = false,
        secondsUntilNotification = 600,
    }) {
        await createNotification({
            userID,
            pushToken,
            content,
            type,
            createNotificationMutation,
            isScheduledNotifcation,
            secondsUntilNotification,
        })
    }

    async function handleCreateMultipleNotifications({ users, content, type }) {
        for (const user of users) {
            const { id: userID, pushToken } = user
            await createNotification({
                userID,
                pushToken,
                content,
                type,
                createNotificationMutation,
                isSingleNotification: false,
            })
        }
        const usersWithPushTokens = users?.filter(user => user?.pushToken)
        const { title, body, data } = content
        await sendMultiplePushNotifications({ users: usersWithPushTokens, title, body, data })
    }

    async function handleUpdateNotification({ notification }) {
        await updateNotification({ notification, isNew: false, updateNotificationMutation })
    }

    return {
        handleUpdateNotification,
        handleCreateNotification,
        handleCreateMultipleNotifications,
        notificationTypes: NOTIFICATION_TYPES,
        traineeNotificationTypes: TRAINEE_NOTIFICATION_TYPES,
        instructorNotificationTypes: INSTRUCTOR_NOTIFICATION_TYPES,
    }
}
