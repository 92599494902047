import { agoraStreamStateKeys } from 'formFieldInfo'
import { agoraStreamState } from 'apollo/cache'
import { secToMinString, isClassTimeWithinTenMinutes } from 'helpers'
import { HOST_EVENTS, AUDIENCE_EVENTS, CLASS_EVENTS } from 'constants/streamEvents'
import colors from 'colors'
const emptyObj = {}
const emptyArr = []
const {
    CLASS_UPDATE_INTERVAL,
    CLASS_COMPLETE,
    CLASS_STARTED,
    CLASS_START_COUNTDOWN,
    CLASS_ENDED_BY_HOST,
    CLASS_LATE_JOIN,
} = CLASS_EVENTS

const {
    HOST_HIDE_VIDEO,
    HOST_SHOW_VIDEO,
    HOST_MUTE_AUDIO,
    HOST_UNMUTE_AUDIO,
    HOST_SEND_CHANNEL_MESSAGE,
    HOST_SEND_PEER_MESSAGE,
    HOST_MESSAGE_FROM_AUDIENCE,
    HOST_MESSAGE_FROM_AUDIENCE_MEMBER,
    HOST_END_CLASS,
    HOST_END_STREAM,
    HOST_CLASS_COMPLETED,
    HOST_BE_RIGHT_BACK,
    HOST_DISPLAY_STREAM_OVERLAY_MESSAGE,
    HOST_TRAINEE_JOINED,
    HOST_TRAINEE_LEFT,
    HOST_UNLOCK_STREAM_ROOM,
} = HOST_EVENTS

const {
    AUDIENCE_BE_RIGHT_BACK,
    AUDIENCE_HOST_HIDE_VIDEO,
    AUDIENCE_HOST_MUTE_AUDIO,
    AUDIENCE_SUBSCRIBE_TO_HOST_TRACKS,
    AUDIENCE_MESSAGE_FROM_HOST,
    AUDIENCE_STREAM_ENDED,
    AUDIENCE_TOGGLE_STREAM_AUDIO,
    AUDIENCE_TOGGLE_STREAM_VIDEO,
    AUDIENCE_LEAVE_STREAM,
    AUDIENCE_SEND_MESSAGE_TO_HOST,
    AUDIENCE_STREAM_ROOM_UNLOCKED,
    AUDIENCE_SET_HOST_UID,
    AUDIENCE_SEND_HOST_INFO,
} = AUDIENCE_EVENTS

const useReactiveStreamMethods = ({ streamState }) => {
    const keys = agoraStreamStateKeys
    const {
        uid,
        host,
        tracks,
        isHost,
        trackState,
        streamHidden,
        instructorClass,
        currentInterval,
        currentIntervalIndex,
        streamClient,
        streamReady,
        streamRoomLocked,
        streamOverlayMessage,
        classComplete,
        classStarted,
        classEnded,
        lateTraineeIDs,
        showVideoPlayer,
    } = streamState
    const { videoTrack: hostVideoTrack } = host || emptyObj
    const { intervals, startTime } = instructorClass || emptyObj
    const { type, duration, timeType } = currentInterval || emptyObj

    //------------------------------ HOST EVENT EMITTERS -----------------------------

    const toggleCamera = () => {
        const toggleCameraQueue = [
            { event: trackState.video ? HOST_HIDE_VIDEO : HOST_SHOW_VIDEO },
            { event: HOST_SEND_CHANNEL_MESSAGE, payload: { message: AUDIENCE_HOST_HIDE_VIDEO } },
        ]
        setStreamEventQueue(toggleCameraQueue)
    }

    const toggleMicrophone = () => {
        const toggleMicrophoneQueue = [
            { event: trackState.audio ? HOST_MUTE_AUDIO : HOST_UNMUTE_AUDIO },
            { event: HOST_SEND_CHANNEL_MESSAGE, payload: { message: AUDIENCE_HOST_MUTE_AUDIO } },
        ]
        setStreamEventQueue(toggleMicrophoneQueue)
    }

    const hideStream = () => {
        setStreamHidden(!streamHidden)
        const hideStreamQueue = [
            { event: HOST_BE_RIGHT_BACK },
            { event: HOST_SEND_CHANNEL_MESSAGE, payload: { message: AUDIENCE_BE_RIGHT_BACK } },
        ]
        setStreamEventQueue(hideStreamQueue)
    }

    const triggerEndStreamHostEvent = (
        { wasStreamEndedWithListener = false } = { wasStreamEndedWithListener: false },
    ) => {
        const endStreamQueue = [
            { event: HOST_SEND_CHANNEL_MESSAGE, payload: { message: AUDIENCE_STREAM_ENDED } },
            { event: HOST_END_STREAM, payload: { wasStreamEndedWithListener } },
        ]
        setStreamEventQueue(endStreamQueue)
    }

    const displayHostOverlayMessage = overlayMessage => {
        const { overlayMessage: currentOverlayMessage, id: currentOverlayId = 1 } = streamOverlayMessage
        const isDuplicateMessage = currentOverlayMessage == overlayMessage?.overlayMessage

        setStreamEvent({
            event: HOST_DISPLAY_STREAM_OVERLAY_MESSAGE,
            payload: {
                overlayMessage: isDuplicateMessage ? { ...overlayMessage, id: currentOverlayId + 1 } : overlayMessage,
            },
        })
    }

    const letUsersIn = () => {
        const shouldLetUsersIn = isClassTimeWithinTenMinutes(startTime)
        if (shouldLetUsersIn) {
            const overlayMessage = { overlayMessage: `Say hi to your users! You're live now.`, delay: 1000 }
            const letUsersInQueue = [
                { event: HOST_UNLOCK_STREAM_ROOM },
                { event: HOST_SEND_CHANNEL_MESSAGE, payload: { message: AUDIENCE_STREAM_ROOM_UNLOCKED } },
                {
                    event: HOST_DISPLAY_STREAM_OVERLAY_MESSAGE,
                    payload: { overlayMessage },
                },
            ]
            setStreamEventQueue(letUsersInQueue)
        } else {
            displayHostOverlayMessage({
                overlayMessage: 'You cannot let users in until 10 minutes before the class start time.',
                delay: 0,
            })
        }
    }

    const startClassCountdown = () => {
        if (streamRoomLocked) {
            displayHostOverlayMessage({
                overlayMessage: 'Please let your users in before starting the class',
                delay: 0,
            })
        } else {
            const { overlayMessage: currentOverlayMessage } = streamOverlayMessage
            if (currentOverlayMessage != 'the class will start in...') {
                const overlayMessage = { overlayMessage: 'the class will start in...' }
                const startClassCountdownQueue = [
                    { event: CLASS_START_COUNTDOWN, payload: { overlayMessage } },
                    {
                        event: HOST_SEND_CHANNEL_MESSAGE,
                        payload: { message: `${CLASS_START_COUNTDOWN}payload:${JSON.stringify({ overlayMessage })}` },
                    },
                ]
                setStreamEventQueue(startClassCountdownQueue)
            }
        }
    }

    const sendHostUID = peerId => {
        setStreamEvent({
            event: HOST_SEND_PEER_MESSAGE,
            payload: {
                peerId,
                message: `${AUDIENCE_SET_HOST_UID}payload:${JSON.stringify({ hostUid: uid.toString() })}`,
            },
        })
    }

    const requestTraineeInfo = peerId => {
        setStreamEvent({
            event: HOST_SEND_PEER_MESSAGE,
            payload: {
                peerId,
                message: AUDIENCE_SEND_HOST_INFO,
            },
        })
    }

    const updateLateJoinTrainee = classProgress => {
        const { timeUntilNextInterval } = classProgress
        const overlayMessage = {
            overlayMessage: `the next interval starts in ${secToMinString(timeUntilNextInterval)}`,
        }
        const peerId = lateTraineeIDs[lateTraineeIDs.length - 1]?.toString()
        const updateLateJoinTraineeQueue = [
            {
                event: HOST_SEND_PEER_MESSAGE,
                payload: {
                    peerId,
                    message: AUDIENCE_STREAM_ROOM_UNLOCKED,
                },
            },
            {
                event: HOST_SEND_PEER_MESSAGE,
                payload: {
                    peerId,
                    message: `${CLASS_LATE_JOIN}payload:${JSON.stringify({ classProgress, overlayMessage })}`,
                },
            },
        ]
        setStreamEventQueue(updateLateJoinTraineeQueue)
    }

    const hostEndClass = () => {
        const overlayMessage = { overlayMessage: 'The class has ended.' }
        const hostEndClassQueue = [
            { event: CLASS_ENDED_BY_HOST, payload: { overlayMessage } },
            {
                event: HOST_SEND_CHANNEL_MESSAGE,
                payload: { message: `${CLASS_ENDED_BY_HOST}payload:${JSON.stringify({ overlayMessage })}` },
            },
            { event: HOST_END_CLASS },
        ]
        setStreamEventQueue(hostEndClassQueue)
    }

    const messageFromAudience = message => setStreamEvent({ event: HOST_MESSAGE_FROM_AUDIENCE, payload: { message } })
    const messageFromAudienceMember = message =>
        setStreamEvent({ event: HOST_MESSAGE_FROM_AUDIENCE_MEMBER, payload: { message } })

    const removeTrainee = uid => setStreamEvent({ event: HOST_TRAINEE_LEFT, payload: { uid } })

    const hostEventEmitters = {
        displayHostOverlayMessage,
        toggleCamera,
        toggleMicrophone,
        hideStream,
        startClass,
        triggerEndStreamHostEvent,
        sendHostUID,
        letUsersIn,
        removeTrainee,
        messageFromAudience,
        messageFromAudienceMember,
        startClassCountdown,
        updateLateJoinTrainee,
        requestTraineeInfo,
    }

    //------------------------------ AUDIENCE EVENT EMITTERS ----------------------------

    const traineeJoined = traineeInfo => {
        const payload = JSON.stringify(traineeInfo)
        const traineeJoinedQueue = [
            {
                event: AUDIENCE_SEND_MESSAGE_TO_HOST,
                payload: {
                    message: `${HOST_TRAINEE_JOINED}payload:${payload}`,
                },
            },
        ]
        setStreamEventQueue(traineeJoinedQueue)
    }
    const traineeLeft = traineeInfo => {
        const payload = JSON.stringify(traineeInfo)
        const traineeLeftQueue = [
            {
                event: AUDIENCE_SEND_MESSAGE_TO_HOST,
                payload: {
                    message: `${HOST_TRAINEE_LEFT}payload:${payload}`,
                },
            },
        ]
        setStreamEventQueue(traineeLeftQueue)
    }

    const messageFromHost = message => setStreamEvent({ event: AUDIENCE_MESSAGE_FROM_HOST, payload: { message } })
    const toggleStreamAudio = () => setStreamEvent({ event: AUDIENCE_TOGGLE_STREAM_AUDIO })
    const toggleStreamVideo = () => setStreamEvent({ event: AUDIENCE_TOGGLE_STREAM_VIDEO })
    const leaveStream = () => setStreamEvent({ event: AUDIENCE_LEAVE_STREAM })
    const subscribeToHostTracks = (user, mediaType) =>
        setStreamEvent({ event: AUDIENCE_SUBSCRIBE_TO_HOST_TRACKS, payload: { user, mediaType } })

    const audienceEventEmitters = {
        traineeJoined,
        traineeLeft,
        subscribeToHostTracks,
        messageFromHost,
        toggleStreamAudio,
        toggleStreamVideo,
        leaveStream,
    }

    const shouldLog = false
    const updateStreamState = ({ key = '', value, nestedKey }) => {
        if (shouldLog) console.log('key: ', key, ' value: ', value)
        if (nestedKey) {
            agoraStreamState({
                ...agoraStreamState(),
                [key]: { ...agoraStreamState()[key], ...nestedKey },
            })
        } else {
            agoraStreamState({ ...agoraStreamState(), [key]: value })
        }
        if (shouldLog) console.log(`${key}: `, agoraStreamState()[key])
    }

    //------------------------------ CLASS EVENT EMITTERS ----------------------------
    const startClass = () => setStreamEvent({ event: CLASS_STARTED })

    const queueIntervalCountdown = currentIndex => {
        const timeUntilCountdown = (currentIntervalDurationSeconds - (currentIndex == 0 ? -5 : 5)) * 1000
        setStreamOverlayMessage({
            overlayMessage: `intervalCountdown,${currentIndex}`,
            delay: timeUntilCountdown,
        })
    }
    const updateInterval = () => {
        if (!isFinalInterval) {
            setStreamEvent({ event: CLASS_UPDATE_INTERVAL })
            return [true]
        } else {
            classCompleted()
            return [false]
        }
    }

    const classCompleted = () => {
        const overlayMessage = isHost
            ? {
                  overlayMessage:
                      'Congrats! Your class has been completed. Say bye to your users and end the live stream.',
                  delay: 1000,
              }
            : { overlayMessage: 'You finished the class, great job!', delay: 1000 }

        setTimeout(() => {
            if (isHost) {
                setStreamEventQueue([
                    { event: CLASS_COMPLETE, payload: { overlayMessage } },
                    { event: HOST_CLASS_COMPLETED },
                ])
            } else {
                setStreamEvent({
                    event: CLASS_COMPLETE,
                    payload: { overlayMessage },
                })
            }
        }, 1000) //Timeout to ensure countdown animation has finished before updating overlayMessage
    }

    const classEventEmitters = {
        startClass,
        queueIntervalCountdown,
        updateInterval,
    }

    //------------------------------ STREAM STATE SETTERS ----------------------------
    const setToken = token => updateStreamState({ key: keys.token, value: token })
    const setTraineesAttendingClass = trainee => updateStreamState({ key: keys.traineesAttendingClass, value: trainee })
    const setInstructorClass = classToStream => updateStreamState({ key: keys.instructorClass, value: classToStream })
    const setNumTraineesBooked = numTrainees => updateStreamState({ key: keys.numTraineesBooked, value: numTrainees })
    const setIsHost = isUserHost => updateStreamState({ key: keys.isHost, value: isUserHost })
    const setChannelName = text => updateStreamState({ key: keys.channelName, value: text })
    const setClassStarted = hasClassStarted => updateStreamState({ key: keys.classStarted, value: hasClassStarted })
    const setClassComplete = isClassComplete => updateStreamState({ key: keys.classComplete, value: isClassComplete })
    const setClassEnded = isClassEnded => updateStreamState({ key: keys.classEnded, value: isClassEnded })
    const setWaitingRoomJoined = hasJoined => updateStreamState({ key: keys.waitingRoomJoined, value: hasJoined })
    const setStreamRoomLocked = isRoomLocked => updateStreamState({ key: keys.streamRoomLocked, value: isRoomLocked })
    const setStreamRoomJoined = joined => updateStreamState({ key: keys.streamRoomJoined, value: joined })
    const setHasJoinedRTCChannel = hasJoined => updateStreamState({ key: keys.hasJoinedRTCChannel, value: hasJoined })
    const setStreamReady = isStreamReady => updateStreamState({ key: keys.streamReady, value: isStreamReady })
    const setStreamClient = agoraRTCClient => updateStreamState({ key: keys.streamClient, value: agoraRTCClient })
    const setMessagesClient = agoraRTMClient => updateStreamState({ key: keys.messagesClient, value: agoraRTMClient })
    const setMessagesChannel = RTMChannel => updateStreamState({ key: keys.messagesChannel, value: RTMChannel })
    const setIsLoggedInToRTM = isLoggedIn => updateStreamState({ key: keys.isLoggedInToRTM, value: isLoggedIn })
    const setTrackState = newTrackState => updateStreamState({ key: keys.trackStateKey, value: newTrackState })
    const setHost = streamHost => updateStreamState({ key: keys.host, value: streamHost })
    const setHostUID = hostUid => updateStreamState({ key: keys.hostUID, value: hostUid })
    const setStreamEvent = streamEvent => updateStreamState({ key: keys.streamEventKey, value: streamEvent })
    const setStreamEnded = hasStreamEnded => updateStreamState({ key: keys.streamEnded, value: hasStreamEnded })
    const setStreamHidden = hidden => updateStreamState({ key: keys.streamHidden, value: hidden })
    const setTracksReady = areTracksReady => updateStreamState({ key: keys.tracksReady, value: areTracksReady })
    const setTracks = tracks => updateStreamState({ key: keys.tracks, value: tracks })
    const setCurrentInterval = interval => updateStreamState({ key: keys.currentInterval, value: interval })
    const setLateTraineeIDs = numLateJoins => updateStreamState({ key: keys.lateTraineeIDs, value: numLateJoins })
    const setIsLateJoin = hasJoinedLate => updateStreamState({ key: keys.isLateJoin, value: hasJoinedLate })
    const setTimeUntilNextInterval = time => updateStreamState({ key: keys.timeUntilNextInterval, value: time })
    const setCurrentIntervalIndex = intervalIndex =>
        updateStreamState({ key: keys.currentIntervalIndex, value: intervalIndex })
    const setStreamEventQueue = streamEventQueue =>
        updateStreamState({ key: keys.streamEventQueue, value: streamEventQueue })
    const setStreamStatusMessage = newStreamStatus =>
        updateStreamState({ key: keys.streamStatusMessage, value: newStreamStatus })
    const setStreamOverlayMessage = newStreamOverlay =>
        updateStreamState({ key: keys.streamOverlayMessage, value: newStreamOverlay })

    const streamStateSetters = {
        setInstructorClass,
        setNumTraineesBooked,
        setTraineesAttendingClass,
        setIsHost,
        setChannelName,
        setClassStarted,
        setClassComplete,
        setClassEnded,
        setWaitingRoomJoined,
        setStreamRoomLocked,
        setStreamRoomJoined,
        setHasJoinedRTCChannel,
        setStreamReady,
        setStreamClient,
        setMessagesClient,
        setMessagesChannel,
        setIsLoggedInToRTM,
        setStreamEnded,
        setTrackState,
        setStreamStatusMessage,
        setStreamOverlayMessage,
        setHost,
        setHostUID,
        setStreamEvent,
        setStreamEventQueue,
        setStreamHidden,
        setTracksReady,
        setTracks,
        setCurrentInterval,
        setCurrentIntervalIndex,
        setLateTraineeIDs,
        setToken,
        setIsLateJoin,
        setTimeUntilNextInterval,
    }

    const triggerEvent = ({ event, payload }) => setStreamEvent({ event, payload })

    function parseEventTriggerMessage(messagePayload) {
        const {
            message: { text },
        } = messagePayload
        let event = ''
        let payload = emptyObj
        if (text.includes('payload:')) {
            const parts = text.split('payload:')
            event = parts[0]
            payload = JSON.parse(parts[1])
        } else {
            event = text
        }

        return { event, payload }
    }

    function getTotalIntervalTime() {
        let totalTime = intervals?.reduce(function (accumulator, item) {
            return accumulator + (item.timeType == 'min' ? item.duration * 60 : item.duration)
        }, 0)
        return totalTime
    }

    function getElapsedIntervalTime() {
        let elapsedIntervalTime = intervals?.slice(0, currentIntervalIndex).reduce(function (accumulator, item) {
            return accumulator + (item.timeType == 'min' ? item.duration * 60 : item.duration)
        }, 0)
        return elapsedIntervalTime
    }

    function getNextInterval() {
        const nextInterval = intervals && !isFinalInterval ? intervals[currentIntervalIndex + 1] : emptyObj
        return nextInterval
    }

    function getNextIntervalString() {
        const { name, duration, timeType } = !classStarted
            ? intervals?.[0] || emptyObj
            : nextInterval
            ? nextInterval
            : emptyObj
        const upNextString = !isFinalInterval
            ? `up next: ${duration} ${timeType} ${name}`
            : 'up next: workout complete!'

        return upNextString
    }

    function joinInstructorStreamRoom() {
        setIsHost(true)
        streamClient?.setClientRole('host')
        setStreamRoomJoined(true)
    }

    function joinTraineeStreamRoom() {
        setStreamRoomJoined(true)
    }

    function getIsIntervalCountdown() {
        if (classComplete) {
            return false
        } else {
            const { overlayMessage } = streamOverlayMessage || emptyObj
            const messageParts = overlayMessage.includes(',') ? overlayMessage.split(',') : emptyArr
            return messageParts ? messageParts[0] == 'intervalCountdown' : false
        }
    }

    function setLateJoinIntervalDelay(elapsedTime) {
        if (isHost && lateTraineeIDs?.length > 0) {
            const timeUntilNextInterval = Math.ceil(currentIntervalDurationSeconds + elapsedIntervalTime - elapsedTime)
            updateLateJoinTrainee({
                currentIntervalIndex: currentIntervalIndex,
                timeUntilNextInterval,
            })
        }
    }

    function getIntervalDurationSeconds(interval) {
        const { timeType, duration } = interval || emptyObj
        return !interval ? 0 : timeType == 'min' ? duration * 60 : duration
    }

    function formatIntervalType(interval) {
        const { type } = interval || emptyObj
        return !interval ? '' : type === 'WARMUP' ? 'warm up' : type == 'COOLDOWN' ? 'cool down' : type?.toLowerCase()
    }
    const handleInstructorStreamControlsOnPress = () =>
        !classStarted && !classEnded
            ? startClassCountdown()
            : !classComplete && !classEnded
            ? hostEndClass()
            : triggerEndStreamHostEvent()

    const isFinalInterval = intervals ? currentIntervalIndex == intervals.length - 1 : false
    const nextInterval = getNextInterval()
    const isIntervalCountdown = getIsIntervalCountdown()
    const currentIntervalDurationSeconds = getIntervalDurationSeconds(currentInterval)
    const nextIntervalDurationSeconds = getIntervalDurationSeconds(nextInterval)
    const formattedCurrentIntervalType = formatIntervalType(currentInterval)
    const formattedNextIntervalType = formatIntervalType(nextInterval)
    const nextIntervalString = classEnded ? 'class ended' : classComplete ? 'class complete!' : getNextIntervalString()
    const totalIntervalTime = getTotalIntervalTime()
    const elapsedIntervalTime = getElapsedIntervalTime()
    const instructorStreamControlsButtonText =
        !classStarted && !classEnded ? 'start class' : !classComplete && !classEnded ? 'end class' : 'end live stream'
    const instructorStreamControlsButtonColor =
        classStarted && !classEnded && !classComplete ? colors.homebodyGreen : colors.homebodyTurquoise

    const videoPlayerVisible =
        ((isHost && streamReady && tracks) || (!isHost && hostVideoTrack)) && trackState?.video && showVideoPlayer

    const streamHelpers = {
        videoPlayerVisible,
        totalIntervalTime,
        elapsedIntervalTime,
        formattedCurrentIntervalType,
        formattedNextIntervalType,
        isIntervalCountdown,
        nextInterval,
        isFinalInterval,
        nextIntervalString,
        nextIntervalDurationSeconds,
        currentIntervalDurationSeconds,
        instructorStreamControlsButtonText,
        instructorStreamControlsButtonColor,
        joinInstructorStreamRoom,
        joinTraineeStreamRoom,
        parseEventTriggerMessage,
        triggerEvent,
        handleInstructorStreamControlsOnPress,
        setLateJoinIntervalDelay,
    }

    return {
        streamHelpers,
        classEventEmitters,
        hostEventEmitters,
        audienceEventEmitters,
        streamStateSetters,
    }
}

export default useReactiveStreamMethods
