import awsconfig from './src/aws-exports'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import { cache, getUserInfo } from 'apollo/cache'
import { persistCache, AsyncStorageWrapper, LocalStorageWrapper } from 'apollo3-cache-persist'
import { initAugmentedHooks } from 'apollo-augmented-hooks'
const persist = async () => {
    await persistCache({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
    })
}

const url = awsconfig.aws_appsync_graphqlEndpoint
const region = awsconfig.aws_appsync_region
const auth = {
    type: awsconfig.aws_appsync_authenticationType,
    apiKey: awsconfig.aws_appsync_apiKey,
}
const link = ApolloLink.from([createAuthLink({ url, region, auth }), createHttpLink({ uri: url })])
const client = new ApolloClient({
    link,
    cache,
    connectToDevTools: true,
})
initAugmentedHooks(client)

export default client
