import React from 'react'

import Markdown from 'react-native-markdown-display'
import markdownStyles from 'styles/markdownStyles'

const MarkdownContent: React.FC = ({children}) => (
  <Markdown style={markdownStyles}>{children}</Markdown>
)


export default MarkdownContent