import React, { useState, useEffect } from 'react'
import { View, ActivityIndicator } from 'react-native'
import colors from 'colors'
import styled from 'styled-components/native'
import { ContinueButton } from 'components/ContinueButton'
import { ColumnToRowContainer, FormBottomRowToColumnContainer } from '../containers'

const emptyObj = {}
const SmallContinueButton = styled(ContinueButton)`
    width: ${props => (props.theme.isMobile ? '100%' : props.width ? props.width : '148px')};
    height: ${props => (props.height ? props.height : '56px')};
    font-size: 13px;
    margin-bottom: ${props => (props.theme.isMobile ? '10px' : '0px')};
    margin-right: ${props => (props.marginRight ? props.marginRight : '10px')};
`
const StyledActivityIndicator = styled(ActivityIndicator)`
    width: ${props => (props.theme.isMobile ? '100%' : props.width ? `${props.width}px` : '148px')};
    height: ${props => (props.height ? `${props.height}px` : '61px')};
    align-self: center;
    margin-bottom: ${props => (props.theme.isMobile ? '10px' : '0px')};
    margin-right: 10px;
`

export const FormButtons = ({
    updateStep,
    loading,
    containerStyle = emptyObj,
    isGradient = false,
    isCentered = false,
    isEnd = false,
    firstButtonText,
    secondButtonText,
    firstButtonColor,
    secondButtonColor,
    firstButtonOnPress,
    secondButtonOnPress,
    isReviewandSubmit,
    buttonTextStyle = {},
    width = 250,
    height = 40,
    renderLoadingOnFirstButton = true,
}) => {
    return (
        <FormBottomRowToColumnContainer
            isCentered={isCentered}
            isEnd={isEnd}
            isReviewandSubmit={isReviewandSubmit}
            style={[containerStyle, { zIndex: -1 }]}
        >
            {loading && renderLoadingOnFirstButton ? (
                <StyledActivityIndicator
                    size="large"
                    animating={loading}
                    height={height}
                    width={width}
                    color={colors.blueContinue}
                />
            ) : (
                <>
                    {isReviewandSubmit ? (
                        <SmallContinueButton
                            text={secondButtonText}
                            color={secondButtonColor}
                            isGradient={isGradient}
                            buttonTextStyle={buttonTextStyle}
                            width={width}
                            height={height}
                            marginRight={25}
                            onPress={updateStep || secondButtonOnPress}
                        />
                    ) : (
                        <SmallContinueButton
                            text={firstButtonText}
                            isGradient={isGradient}
                            color={firstButtonColor}
                            onPress={() => (updateStep ? updateStep({ isSaving: true }) : firstButtonOnPress())}
                            buttonTextStyle={buttonTextStyle}
                            height={height}
                            width={width}
                        />
                    )}
                </>
            )}
            {loading && !renderLoadingOnFirstButton ? (
                <StyledActivityIndicator
                    width={width}
                    height={height}
                    animating={loading}
                    size="large"
                    color={colors.blueContinue}
                />
            ) : (
                <>
                    {isReviewandSubmit ? (
                        <SmallContinueButton
                            text={firstButtonText}
                            isGradient={isGradient}
                            color={firstButtonColor}
                            buttonTextStyle={buttonTextStyle}
                            width={width}
                            height={height}
                            onPress={() => (updateStep ? updateStep({ isSaving: true }) : firstButtonOnPress())}
                        />
                    ) : (
                        <SmallContinueButton
                            text={secondButtonText}
                            color={secondButtonColor}
                            isGradient={isGradient}
                            onPress={updateStep || secondButtonOnPress}
                            buttonTextStyle={buttonTextStyle}
                            width={width}
                            height={height}
                        />
                    )}
                </>
            )}
        </FormBottomRowToColumnContainer>
    )
}
export default FormButtons
