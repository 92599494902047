import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity } from 'react-native'
import { useMutation } from 'apollo-augmented-hooks'
import { gql } from '@apollo/client'
import { GET_USER } from 'apollo/queries'
import { CREATE_FAVORITE_INSTRUCTOR, DELETE_FAVORITE_INSTRUCTOR } from 'apollo/mutations'
import { cache } from 'apollo/cache'
import { createFavoriteInstructor, deleteFavoriteInstructor } from 'actions'
import colors from 'colors'
import styled from 'styled-components/native'
import VectorIcon from 'components/VectorIcon'
import { isFavoriteInstructor } from 'helpers'
import { iconHitSlop } from '@constants'

export default function FavoriteInstructorIcon({ style, favorites = [], userID = '', instructorID = '', size = 30 }) {
    const [isFavorite, setIsFavorite] = useState(false)
    const [favoriteInstructor, setFavoriteInstructor] = useState({})
    const createFavoriteInstructorMutation = useMutation(CREATE_FAVORITE_INSTRUCTOR, {
        onCompleted: () => {
            setIsFavorite(true)
        },
        onError: error => {
            __DEV__ && console.log('Error creating favorite instructor: ', error)
            setIsFavorite(false)
        },
        refetchQueries: [
            {
                query: GET_USER,
                variables: { id: userID },
            },
        ],
    })

    const deleteFavoriteInstructorMutation = useMutation(DELETE_FAVORITE_INSTRUCTOR, {
        onCompleted: () => {
            const normalizedID = cache.identify({
                id: favoriteInstructor?.id,
                userID: userID,
                __typename: 'FavoriteInstructor',
            })
            cache.evict({ id: normalizedID })
            cache.gc()
            setIsFavorite(false)
        },
        onError: error => {
            __DEV__ && console.log('Error deleting instructor: ', error)
            setIsFavorite(true)
        },
    })

    const favoriteIconProps = {
        iconName: 'heart',
        iconColor: isFavorite ? colors.homebodyTurquoise : colors.neutralGray,
        iconSize: size,
    }

    const { iconName, iconColor, iconSize } = favoriteIconProps

    async function handleIconPress() {
        if (!isFavorite) {
            const newFavoriteInstructor = await createFavoriteInstructor({
                userID,
                instructorID,
                createFavoriteInstructorMutation,
            })
            const normalizedID = cache.identify({
                id: userID,
                __typename: 'User',
            })
            cache.modify({
                id: normalizedID,
                fields: {
                    favorites(existingFavorites, { readField, toReference }) {
                        const newFavRef = toReference({
                            __typename: 'FavoriteInstructor',
                            id: newFavoriteInstructor?.data?.createFavoriteInstructor?.id,
                            userID: newFavoriteInstructor?.data?.createFavoriteInstructor?.userID,
                        })
                        if (existingFavorites?.items) {
                            if (existingFavorites.items.some(ref => readField('id', ref) === newFavRef?.id)) {
                                return existingFavorites
                            }
                            return { ...existingFavorites, items: [...existingFavorites?.items, newFavRef] }
                        } else {
                            return { ...existingFavorites, items: [newFavRef] }
                        }
                    },
                },
            })
        } else {
            await deleteFavoriteInstructor({ favoriteInstructor, deleteFavoriteInstructorMutation })
        }
    }

    useEffect(() => {
        if (favorites && userID) {
            const favoritedInstructor = isFavoriteInstructor({ instructorID, favorites })
            if (favoritedInstructor) {
                setIsFavorite(true)
                setFavoriteInstructor(favoritedInstructor)
            } else {
                setIsFavorite(false)
            }
        }
    }, [favorites, userID])

    return (
        <TouchableFavoriteIcon style={style} onPress={handleIconPress} hitslop={iconHitSlop}>
            <VectorIcon family="FontAwesome" name={iconName} color={iconColor} size={iconSize} />
        </TouchableFavoriteIcon>
    )
}

const TouchableFavoriteIcon = styled(TouchableOpacity)`
    justify-content: center;
    align-items: center;
`
