import React from 'react'
import { View, TouchableOpacity, StyleSheet } from 'react-native'
import { Paragraph } from 'typography'
import colors from 'colors'
import styled from 'styled-components/native'
import VectorIcon from 'components/VectorIcon'

export default function AccountSettingsButton({ style, text, onPress, iconProps }) {
    const { family, name, size = 22, color = 'black' } = iconProps

    return (
        <AccountSettingsButtonContainer style={style} onPress={onPress}>
            <ButtonTextContainer>
                <VectorIcon family={family} name={name} size={size} color={color} />
                <ButtonText paddingLeft={15}>{text}</ButtonText>
            </ButtonTextContainer>
            <VectorIcon family="MaterialIcons" name="chevron-right" color={colors.dividingGray} size={28} />
        </AccountSettingsButtonContainer>
    )
}
const AccountSettingsButtonContainer = styled(TouchableOpacity)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-horizontal: 25px;
    border-width: ${StyleSheet.hairlineWidth};
    border-color: ${colors.dividingGray};
    width: ${({ theme }) => (theme.isMobile ? '100%' : theme.isTablet ? '50%' : '30%')};
    height: 60px;
`
const ButtonTextContainer = styled(View)`
    flex-direction: row;
    align-items: center;
`
const ButtonText = styled(Paragraph)``
