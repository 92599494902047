import { gql } from '@apollo/client'
import * as mutations from '@graphql/mutations'

export const CREATE_USER = gql`
    ${mutations.createUser}
`
export const UPDATE_USER = gql`
    ${mutations.updateUser}
`
export const DELETE_USER = gql`
    ${mutations.deleteUser}
`

export const CREATE_TRAINER_APPLICATION = gql`
    ${mutations.createTrainerApplication}
`
export const UPDATE_TRAINER_APPLICATION = gql`
    ${mutations.updateTrainerApplication}
`
export const DELETE_TRAINER_APPLICATION = gql`
    ${mutations.deleteTrainerApplication}
`

export const CREATE_FILE = `
    ${mutations.createFile}
`
export const UPDATE_FILE = gql`
    ${mutations.updateFile}
`
export const DELETE_FILE = gql`
    ${mutations.deleteFile}
`

export const CREATE_WAIT_LIST = gql`
    ${mutations.createWaitList}
`
export const UPDATE_WAIT_LIST = gql`
    ${mutations.updateWaitList}
`
export const DELETE_WAIT_LIST = gql`
    ${mutations.deleteWaitList}
`

export const CREATE_CLASS = gql`
    ${mutations.createClass}
`
export const UPDATE_CLASS = gql`
    ${mutations.updateClass}
`
export const DELETE_CLASS = gql`
    ${mutations.deleteClass}
`

export const CREATE_CLASS_BOOKING = gql`
    ${mutations.createClassBooking}
`
export const DELETE_CLASS_BOOKING = gql`
    ${mutations.deleteClassBooking}
`

export const CREATE_REVIEW = gql`
    ${mutations.createReview}
`

export const CREATE_STREAM = gql`
    ${mutations.createStream}
`

export const UPDATE_STREAM = gql`
    ${mutations.updateStream}
`

export const DELETE_STREAM = gql`
    ${mutations.deleteStream}
`

export const CREATE_FAVORITE_INSTRUCTOR = gql`
    ${mutations.createFavoriteInstructor}
`

export const DELETE_FAVORITE_INSTRUCTOR = gql`
    ${mutations.deleteFavoriteInstructor}
`

export const CREATE_NOTIFICATION = gql`
    ${mutations.createNotification}
`

export const UPDATE_NOTIFICATION = gql`
    ${mutations.updateNotification}
`

export const DELETE_NOTIFICATION = gql`
    ${mutations.deleteNotification}
`
