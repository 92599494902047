import React, { useState, useEffect } from 'react'
import { View, Text, TouchableOpacity, Image } from 'react-native'
import styled from 'styled-components/native'
import { useDimensions } from 'dimensions'
import { useNavigation } from '@react-navigation/native'
import { HeaderText, Headline } from 'typography'

export const NavigationTabs = ({
    title,
    screens,
    index,
    onPress,
    prevPage,
    currentPage,
    submitted = false,
    isInstructor = false,
    tabFraction = 0.025,
}) => {
    const navigation = useNavigation()

    const {
        isDesktop,
        isMobile,
        deviceDimensions: { deviceWidth },
    } = useDimensions()

    const handleGoBack = () => (onPress ? onPress() : navigation.goBack())

    const tabWidth = isMobile
        ? (deviceWidth * 0.35) / screens.length
        : deviceWidth / screens.length - deviceWidth * tabFraction

    return (
        <View style={{ width: '100%' }}>
            {isInstructor && (
                <Headline isLargeHeadline isMostBold paddingBottom={25}>
                    {title}
                </Headline>
            )}
            <Tabs>
                <TabContainer>
                    <BackBtn onPress={handleGoBack} hidden={typeof prevPage === 'undefined' || submitted}>
                        <Image style={{ width: 17, height: 14 }} source={require('assets/left-arrow.png')} />
                    </BackBtn>
                    {screens.map((title, k) => (
                        <TabItem tabWidth={tabWidth} isMobile={isMobile} active={k === index} key={title}>
                            <TabTitle active={k <= index}>{title}</TabTitle>
                            <TabBar active={k <= index}></TabBar>
                        </TabItem>
                    ))}
                </TabContainer>
            </Tabs>
            {/* <HeaderText>{currentPage}</HeaderText> */}
        </View>
    )
}

const Header = styled(HeaderText)`
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 30px;
`

const BackBtn = styled(TouchableOpacity)`
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 28px;
    background-color: #ffffff;
    box-shadow: 0px 10px 20px #f0f3f6;
    position: ${props => (props.theme.isDesktop ? 'absolute' : 'relative')};
    left: ${props => (props.theme.isDesktop ? '-60px' : '-20px')};
    margin: auto;
    visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
`

const Tabs = styled(View)`
    display: flex;
    margin-vertical: 3%;
`
const MiniHeader = styled(Text)`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
`
const TabContainer = styled(View)`
    flex-direction: row;
    position: relative;
`
// width: ${({ tabWidth, active, theme }) => (active ? '120px' : null)};
const TabItem = styled(View)`
    flex: 1;
    margin-right: 14px;
`
const TabTitle = styled(Text)`
    flex: 1;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 5px;
    text-align: left;
    top: ${({ isDesktop }) => (isDesktop ? 0 : 5)};
    color: ${({ active }) => (active ? '#0CE2FF' : '#8D99B2')};
`
const TabBar = styled(View)`
    height: 3.2px;
    background-color: ${({ active }) => (active ? '#0CE2FF' : '#8D99B2')};
`

export default NavigationTabs
