import React, { useState, useEffect } from 'react'
import { Text, View, Animated } from 'react-native'
import colorConstants from 'colors'
import styled from 'styled-components/native'
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer'
import { Paragraph, SubHeading } from 'typography'
import { secToMinString } from 'helpers'
import { useElapsedTime } from 'use-elapsed-time'
import { isWeb } from '@constants'

export const IntervalCountdownTimer = ({
    style,
    isLargeInterface = false,
    isTraineeControls = false,
    reactiveStreamProps,
}) => {
    const { streamState, classEventEmitters, streamHelpers } = reactiveStreamProps
    const {
        formattedCurrentIntervalType,
        nextIntervalString,
        currentIntervalDurationSeconds,
        nextIntervalDurationSeconds,
    } = streamHelpers
    const { updateInterval } = classEventEmitters
    const { classStarted, classEnded, currentIntervalIndex, isLateJoin, timeUntilNextInterval } = streamState
    const circleTimerProps = {
        key: currentIntervalIndex,
        isPlaying: isTraineeControls ? false : classStarted && !classEnded,
        duration: isTraineeControls
            ? nextIntervalDurationSeconds
            : isLateJoin
            ? timeUntilNextInterval
            : currentIntervalDurationSeconds,

        colors: [
            [colorConstants.homebodyGreen, 0.0],
            [colorConstants.homebodyTurquoise, 0.5],
            [colorConstants.homebodyGreen, 0.75],
        ],
        size: isLargeInterface ? 200 : 75,
        strokeWidth: isLargeInterface ? 8 : 4,
        onComplete: updateInterval,
    }
    const { key, isPlaying, duration, initialTimeRemaining, colors, size, strokeWidth, onComplete } = circleTimerProps

    if (!isTraineeControls) {
        useElapsedTime({ isPlaying: classStarted && !classEnded })
    }

    const isTraineeControlsWeb = isLargeInterface && isTraineeControls && isWeb
    const isTraineeOverlayWeb = isLargeInterface && !isTraineeControls && isWeb
    const intervalFontSize = isTraineeControlsWeb ? '24px' : isTraineeOverlayWeb ? '32px' : '24px'
    return (
        <TimerContainer style={style}>
            <CountdownCircleTimer
                key={key}
                size={size}
                colors={colors}
                duration={duration}
                isPlaying={isPlaying}
                onComplete={onComplete}
                strokeWidth={strokeWidth}
                initialTimeRemaining={initialTimeRemaining}
                isLinearGradient
                trailColor="white"
            >
                {({ remainingTime }) => (
                    <RemainingTimeText isLargeInterface={isLargeInterface} isBold color="white">
                        {secToMinString(remainingTime)}
                    </RemainingTimeText>
                )}
            </CountdownCircleTimer>
            {!isTraineeControls ? (
                <IntervalTypeText isBold color="white" paddingTop={5} intervalFontSize={intervalFontSize}>
                    {formattedCurrentIntervalType}
                </IntervalTypeText>
            ) : (
                <IntervalTypeText
                    color="white"
                    paddingTop={10}
                    paddingLeft={10}
                    paddingRight={10}
                    textAlign="center"
                    intervalFontSize={intervalFontSize}
                >
                    {nextIntervalString}
                </IntervalTypeText>
            )}
        </TimerContainer>
    )
}

const RemainingTimeText = styled(SubHeading)`
    font-size: ${({ isLargeInterface }) => (isLargeInterface ? 40 : 20)}px;
    padding-top: ${({ theme: { isTablet } }) => (isTablet ? 30 : 0)}px;
    align-self: center;
`

const IntervalTypeText = styled(Paragraph)`
    font-size: ${({ intervalFontSize, theme: { isTablet } }) => (isTablet ? '32px' : intervalFontSize)};
    align-self: center;
`
const TimerContainer = styled(View)`
    align-items: center;
`

export default IntervalCountdownTimer
