import React, { useEffect, useState } from 'react'
import { Text, View, StyleSheet } from 'react-native'
import CheckBox from '@react-native-community/checkbox'
import styled from 'styled-components/native'
import { createClassFieldNames as fieldNames } from 'formFieldInfo'
import { Inter500 } from 'typography'
import { useDimensions } from 'dimensions'
import IconCheckBox from 'commons/icons/IconCheckBox'

export const CheckboxField = ({
    label,
    reactiveFormProps,
    fieldName,
    onValueChange,
    style,
    errorMessage,
    fieldTitle,
    value,
    checked,
    color = { unchecked: '#0CE2FF', checked: '#0CE2FF' },
    isSquare = false,
}) => {
    const { isMobile } = useDimensions()

    const onValueChangeAction = () => {
        onValueChange(value)
    }

    return (
        <View style={style}>
            <InputFieldTitleText style={{ color: errorMessage ? 'red' : 'black' }}>
                {errorMessage ? errorMessage : fieldTitle}
            </InputFieldTitleText>
            <CheckboxContainer>
                {isMobile ? (
                    <CheckBox
                        color="#0CE2FF"
                        style={{ height: 23, width: 23 }}
                        reactiveForm={true}
                        fieldTitle={fieldTitle}
                        fieldName={fieldName}
                        value={checked}
                        onValueChange={onValueChangeAction}
                        {...reactiveFormProps}
                    />
                ) : (
                    <IconCheckBox
                        isSquare={isSquare}
                        onPress={onValueChangeAction}
                        checked={checked}
                        color={color}
                        size={23}
                    />
                )}
                <Label>{label}</Label>
            </CheckboxContainer>
        </View>
    )
}

export const CheckboxContainer = styled(View)`
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
`

const Label = styled.Text`
    color: #808191;
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
`

export const InputFieldTitleText = styled(Inter500)`
    font-size: 14.1794px;
    line-height: 14px;
`
