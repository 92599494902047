import { useEffect } from 'react'
import { CLASS_STATUS } from '@constants'
const { PUBLISHED, IN_PROGRESS } = CLASS_STATUS

export const useAttendAgoraStream = reactiveStreamProps => {
    const { streamState, audienceEventEmitters, joinTraineeWaitingRoom, joinClass } = reactiveStreamProps
    const {
        streamClient,
        messagesClient,
        messagesChannel,
        streamRoomLocked,
        waitingRoomJoined,
        instructorClass,
        streamRoomJoined,
    } = streamState
    const { subscribeToHostTracks, messageFromHost } = audienceEventEmitters
    const { classStatus = '' } = instructorClass || {}

    async function listenForStreamEvents() {
        streamClient?.on('user-published', async (user, mediaType) => {
            subscribeToHostTracks(user, mediaType)
        })
    }

    async function listenForChannelMessages() {
        messagesChannel?.on('ChannelMessage', message => {
            messageFromHost(message)
        })
    }

    async function listenForPeerMessage() {
        messagesClient?.on('MessageFromPeer', async message => {
            messageFromHost(message)
        })
    }

    useEffect(() => {
        //Class status check to ensure only triggered if attendee joins class by being let in from waiting room
        if (
            !streamRoomJoined &&
            !streamRoomLocked &&
            waitingRoomJoined &&
            (classStatus == PUBLISHED || classStatus == IN_PROGRESS)
        ) {
            joinClass()
        }
    }, [streamRoomLocked, waitingRoomJoined, classStatus, streamRoomJoined])

    useEffect(() => {
        if (streamClient) {
            listenForStreamEvents()
        }
    }, [streamClient])

    useEffect(() => {
        if (messagesChannel) {
            listenForChannelMessages()
        }
    }, [messagesChannel])

    useEffect(() => {
        if (!waitingRoomJoined && messagesClient) {
            joinTraineeWaitingRoom()
        }
        if (messagesClient) {
            listenForPeerMessage()
        }
    }, [messagesClient])
}

export default useAttendAgoraStream
