import { useState, useEffect } from 'react'
import { Storage } from 'aws-amplify'
import { DEFAULT_BANNERS } from '@constants'
const emptyArr = []

const useDefaultBanners = () => {
    const [defaultBanners, setDefaultBanners] = useState([])
    useEffect(() => {
        async function getS3Images() {
            let temp = []
            const list = (await Storage.list('DefaultBanners/')) || emptyArr
            list?.forEach((item, index) => {
                if (index === 0) {
                    return
                }
                temp.push(item?.key)
            })
            setDefaultBanners(temp)
        }

        getS3Images()
    }, [])

    return defaultBanners
}

export default useDefaultBanners
