import * as screenNames from 'screenNames'
import { CommonActions } from '@react-navigation/native'
import { useCallback } from 'react'
import { useQuery } from '@apollo/client'
import { CREATE_CLASS_NAVIGATOR, INSTRUCTOR_DRAWER_INDICES, CREATE_CLASS } from 'screenNames'
import { UNPUBLISHED_CLASSES } from '../constants/screenNames'
import { IS_LOGGED_IN } from 'apollo/queries'
import { getCurrentUser } from 'selectors'
import { USER_TYPES, APPLICATION_STATUS, isNative } from '@constants'
const { TRAINEE, INSTRUCTOR, ADMIN } = USER_TYPES
const { PENDING, APPROVED } = APPLICATION_STATUS
import { NOTIFICATION_ACTION_TYPES } from 'constants/notificationConstants'
const { DETAILS, REVIEW } = CREATE_CLASS
const {
    NAVIGATE_CLASS_DETAILS,
    NAVIGATE_TRAINEE_HOME,
    NAVIGATE_INSTRUCTOR_PROFILE,
    NAVIGATE_LEAVE_REVIEW,
    NAVIGATE_INSTRUCTOR_HOME,
    NAVIGATE_PUBLISHED_CLASS_DETAILS,
    NAVIGATE_UPCOMING_CLASSES,
} = NOTIFICATION_ACTION_TYPES

const noop = () => {}
const emptyObj = {}
const emptyArr = []

// export const navigatetoExplore = ()
export function getNavigationAction({ navigation, navigationAction, params = emptyObj, depArray = emptyArr }) {
    if (!navigationAction) return null
    const action = useCallback(() => navigationAction(params), [depArray])
    const navigate = useCallback(() => navigation.dispatch(action), [action])
    return navigate
}

// Instructor/trainee nav actions
export const navigateClassDetails = params =>
    CommonActions.navigate(getTraineeHomeStack({ screen: screenNames.CLASS_DETAILS, params }))

export const navigateClassDetailsFromCurrentStack = params =>
    CommonActions.navigate({
        name: screenNames.CLASS_DETAILS,
        params,
    })

// Trainee nav actions
const getTraineeHomeStack = ({ screen, params }) => ({
    name: screenNames.TRAINEE_NAVIGATOR,
    params: {
        screen: screenNames.TRAINEE_HOME_STACK_NAVIGATOR,
        params: {
            initial: false,
            screen,
            params,
        },
    },
})
const getTraineeExploreStack = ({ screen, params }) => ({
    name: screenNames.TRAINEE_NAVIGATOR,
    params: {
        screen: screenNames.EXPLORE_NAVIGATOR,
        params: {
            initial: false,
            screen,
            params,
        },
    },
})
const getTraineeFavoritesStack = ({ screen, params }) => ({
    name: screenNames.TRAINEE_NAVIGATOR,
    params: {
        screen: screenNames.FAVORITES_NAVIGATOR,
        params: {
            initial: false,
            screen,
            params,
        },
    },
})
const getTraineeProfileStack = ({ screen, params }) => ({
    name: screenNames.TRAINEE_NAVIGATOR,
    params: {
        screen: screenNames.PROFILE_NAVIGATOR,
        params: {
            initial: false,
            screen,
            params,
        },
    },
})

export const navigateExploreAction = params =>
    CommonActions.navigate(screenNames.TRAINEE_NAVIGATOR, {
        screen: screenNames.EXPLORE_NAVIGATOR,
        params,
    })

export const navigateTraineeHomeAction = params =>
    CommonActions.navigate(getTraineeHomeStack({ screen: screenNames.TRAINEE_HOME_SCREEN, params }))

export const navigateTraineeSupportAction = params =>
    CommonActions.navigate(getTraineeProfileStack({ screen: screenNames.TRAINEE_SUPPORT, params }))

export const navigateTraineeNotificationAction = params =>
    CommonActions.navigate(getTraineeProfileStack({ screen: screenNames.TRAINEE_NOTIFICATIONS, params }))

export const navigateTraineeProfileAction = params =>
    CommonActions.navigate(getTraineeProfileStack({ screen: screenNames.TRAINEE_ACCOUNT, params }))

export const navigateTraineeFavoritesAction = params =>
    CommonActions.navigate(getTraineeFavoritesStack({ screen: screenNames.FAVORITES, params }))

export const navigateTraineeExploreAction = params =>
    CommonActions.navigate(getTraineeExploreStack({ screen: screenNames.EXPLORE, params }))

export const navigateLeaveReviewFromCurrentStack = params =>
    CommonActions.navigate({ name: screenNames.TRAINEE_LEAVE_REVIEW, params })

export const navigateUpcomingClassesFromCurrentStack = params =>
    CommonActions.navigate({ name: screenNames.TRAINEE_UPCOMING_CLASSES, params })

// Instructor nav actions
const getInstructorHomeStack = ({ screen, params }) => ({
    name: screenNames.INSTRUCTOR_NAVIGATOR,
    params: {
        screen: screenNames.HOME,
        params: {
            initial: false,
            screen,
            params,
        },
    },
})

const getInstructorMyAccountStack = ({ screen, params }) => ({
    name: screenNames.INSTRUCTOR_NAVIGATOR,
    params: {
        screen: screenNames.MY_ACCOUNT_NAVIGATOR,
        params: {
            screen,
            params: {
                initial: false,
                screen,
                params,
            },
        },
    },
})

const getPublishedClassesStack = ({ screen, params }) => ({
    name: screenNames.PUBLISHED_CLASSES_NAVIGATOR,
    params: {
        screen,
        params: {
            initial: false,
            screen,
            ...params,
        },
    },
})

export const navigateInstructorProfileFromCurrentStack = params =>
    CommonActions.navigate({ name: screenNames.USER_PROFILE, params })

export const navigatePublishedClassDetails = params =>
    CommonActions.navigate(getPublishedClassesStack({ screen: screenNames.PUBLISHED_CLASS_DETAILS, params }))

export const navigateInstructorHome = params =>
    CommonActions.navigate(screenNames.INSTRUCTOR_NAVIGATOR, {
        screen: screenNames.HOME,
        params,
    })

export const navigateInstructorHomeAction = params =>
    CommonActions.navigate(getInstructorHomeStack({ screen: screenNames.HOME, params }))

export const navigateInstructorAccount = params =>
    CommonActions.navigate({
        name: screenNames.INSTRUCTOR_NAVIGATOR,
        params: {
            screen: screenNames.MY_ACCOUNT_NAVIGATOR,
            params,
        },
    })

export const navigateInstructorProfile = params =>
    CommonActions.navigate(getInstructorMyAccountStack({ screen: screenNames.INSTRUCTOR_PROFILE, params }))

export const getNavigateUserHome = params => {
    const {
        data: { isLoggedIn },
    } = useQuery(IS_LOGGED_IN)
    const { userType } = getCurrentUser()
    const isInstructor = userType === INSTRUCTOR || userType === ADMIN
    const goToSignUp = () => CommonActions.navigate(screenNames.SIGN_UP)
    let navigationAction = navigateTraineeHomeAction
    if (!isLoggedIn) navigationAction = goToSignUp
    if (isInstructor) navigationAction = navigateInstructorHome
    const depArray = [isLoggedIn, userType]
    return { navigationAction, depArray }
}

export function resetCreateClassNav({ navigation, currentRouteIndex }) {
    try {
        navigation?.dispatch(state => {
            let updatedRoutes = state.routes
            updatedRoutes[INSTRUCTOR_DRAWER_INDICES.CREATE_CLASS_NAVIGATOR] = {
                name: CREATE_CLASS_NAVIGATOR,
                state: {
                    routes: [
                        {
                            name: DETAILS,
                            params: {
                                classObj: emptyObj,
                                parentNav: navigation,
                                resetFormState: false,
                                fromUnpublished: false,
                            },
                        },
                    ],
                },
            }

            return CommonActions.reset({
                ...state,
                routes: updatedRoutes,
                index: currentRouteIndex,
            })
        })
    } catch (error) {
        __DEV__ && console.log('error resetting create class nav stack: ', error)
    }
}

export function unmountScreenOnBlur({ navigation, screenName }) {
    navigation.dispatch(state => {
        // Ensure you can't navigate out of trainee_navigator post onboarding
        const routes = state?.routes?.filter(route => route?.name !== screenName)
        return CommonActions.reset({
            ...state,
            routes,
            index: routes.length - 1,
        })
    })
}

export function getNotificationAction({ navigation, actionType = '', params = emptyObj }) {
    if (!actionType) return noop

    let navigationAction
    switch (actionType) {
        case NAVIGATE_CLASS_DETAILS:
            navigationAction = navigateClassDetailsFromCurrentStack
            break

        case NAVIGATE_TRAINEE_HOME:
            navigationAction = navigateTraineeHomeAction
            break

        case NAVIGATE_UPCOMING_CLASSES:
            navigationAction = navigateUpcomingClassesFromCurrentStack
            break

        case NAVIGATE_INSTRUCTOR_PROFILE:
            navigationAction = navigateInstructorProfileFromCurrentStack
            break

        case NAVIGATE_LEAVE_REVIEW:
            navigationAction = navigateLeaveReviewFromCurrentStack
            break

        case NAVIGATE_INSTRUCTOR_HOME:
            navigationAction = navigateInstructorHome
            break

        case NAVIGATE_PUBLISHED_CLASS_DETAILS:
            navigationAction = navigatePublishedClassDetails
            break

        default:
            break
    }

    const notificationAction = getNavigationAction({
        navigation,
        navigationAction,
        params,
    })

    return notificationAction
}

export function getInitialRoute({
    userType = TRAINEE,
    applicationStatus = '',
    submitted = false,
    isPath = false,
    isMobileWeb = false,
}) {
    const pendingApplication = applicationStatus == PENDING
    const isTrainee = userType == TRAINEE
    const traineeWithPendingApplication = isTrainee && pendingApplication && !isNative && !isMobileWeb

    const instructorRoute = isPath
        ? [
              screenNames.INSTRUCTOR_NAVIGATOR,
              {
                  screen: screenNames.HOME,
              },
          ]
        : screenNames.INSTRUCTOR_NAVIGATOR

    const standardTraineeRoute = isMobileWeb
        ? screenNames.TEST_FLIGHT_DOWNLOAD
        : isPath
        ? [
              screenNames.TRAINEE_NAVIGATOR,
              {
                  screen: screenNames.TRAINEE_HOME_SCREEN,
              },
          ]
        : screenNames.TRAINEE_NAVIGATOR

    const pendingApplicationRoute = isMobileWeb
        ? screenNames.TEST_FLIGHT_DOWNLOAD
        : isPath
        ? [
              screenNames.INSTRUCTOR_APPLICATION.NAVIGATOR,
              {
                  screen: screenNames.INSTRUCTOR_APPLICATION.START_TRAINER_APP,
              },
          ]
        : screenNames.INSTRUCTOR_APPLICATION.NAVIGATOR

    const traineeRoute = traineeWithPendingApplication ? pendingApplicationRoute : standardTraineeRoute

    return isTrainee || isNative ? traineeRoute : instructorRoute
}
