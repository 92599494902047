import React from 'react'
import { View, ImageBackground } from 'react-native'
// ui
import styled from 'styled-components/native'
import colors from 'colors'
import { LinearGradient } from 'expo-linear-gradient'
// selectors
import { getCurrentUser } from 'apollo/selectors'

// components
import { CallToActionText } from 'typography'
import StyledButton from './StyledButton'
import { getNavigationAction, navigateInstructorProfile } from 'navigationActions'

const STATIC_BANNER_IMAGE = require('assets/LandingPageBanner.png')

export default function CallToActionBanner({ navigation }) {
    const { avatar, username, profile } = getCurrentUser()
    const encourageToFinishProfile = !(avatar?.key ?? null) || !username
    const buttonText = 'Finish My Profile'
    const navigateToInstructorProfile = getNavigationAction({ navigation, navigationAction: navigateInstructorProfile })
    const greetText = profile?.firstName && `Hi ${profile.firstName}!`

    return (
        <Container>
            <BannerBackground source={STATIC_BANNER_IMAGE} resizeMode="cover">
                <GradientContainer colors={['rgba(0, 0, 0, 0.42)', 'rgb(0, 0, 0)']}>
                    <CallToActionText color="white">{greetText}</CallToActionText>
                    <CallToActionText paddingBottom={10} color="white">
                        Welcome to HOMEBODY
                    </CallToActionText>
                    {encourageToFinishProfile && (
                        <FinishButton
                            style={{ height: 42.96 }}
                            buttonTextStyle={{
                                fontWeight: '700',
                                fontSize: 18.4068,
                                lineHeight: 15,
                            }}
                            onPress={navigateToInstructorProfile}
                            text={buttonText}
                        />
                    )}
                </GradientContainer>
            </BannerBackground>
        </Container>
    )
}

const FinishButton = styled(StyledButton)`
    margin-top: 50px;
    width: 278.4px;
`

const Container = styled(View)`
    height: 300px;
    overflow: hidden;
`

const GradientContainer = styled(LinearGradient)`
    flex-direction: column;
    justify-content: center;
    align-content: space-between;
    height: 100%;
    width: 100%;
    padding-horizontal: 5%;
    padding-vertical: 20px;
`

const BannerBackground = styled(ImageBackground)`
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: 100% 100%;
`
