const socials = {
    instagram: 'instagram',
    twitter: 'twitter',
    facebook: 'facebook',
    snapchat: 'snapchat',
    linkedin: 'linkedin',
}

const location = {
    city: 'city',
    state: 'state',
    zip: 'zip',
}
const specializations = {
    barre: 'barre',
    bound: 'bound',
    crossfit: 'crossfit',
    core: 'core',
    circuitTraining: 'circuitTraining',
    dance: 'dance',
    HIIT: 'HIIT',
    kickBoxing: 'kickBoxing',
    meditation: 'meditation',
    pilates: 'pilates',
    power: 'power',
    pregnancy: 'pregnancy',
    sculpt: 'sculpt',
    strength: 'strength',
    stretch: 'stretch',
    yoga: 'yoga',
    zumba: 'zumba',
    cardio: 'cardio',
    endurance: 'endurance',
    jump: 'jump',
    foamRoll: 'foamRoll',
    run: 'run',
    yogaSculpt: 'yogaSculpt',
    restorative: 'restorative',
    recovery: 'recovery',
    strengthAndConditioning: 'strengthAndConditioning',
    flow: 'flow',
    boxing: 'boxing',
    celebrity: 'celebrity',
    weightLoss: 'weightLoss',
    bodyWeight: 'bodyWeight',
    stability: 'stability',
    mobility: 'mobility',
    functionalTraining: 'functionalTraining',
    glutes: 'glutes',
    legs: 'legs',
    arms: 'arms',
    performanceTraining: 'performanceTraining',
    flexibility: 'flexibility',
    spiritualWellness: 'spiritualWellness',
    wellness: 'wellness',
    breathWork: 'breathWork',
    beginner: 'beginner',
    advanced: 'advanced',
    kettleBell: 'kettleBell',
    tabata: 'tabata',
    bodyBuilding: 'bodyBuilding',
    athleteTraining: 'athleteTraining',
    cycling: 'cycling',
    plusSize: 'plusSize',
}

export const instructorAppFieldNames = {
    firstName: 'firstName',
    lastName: 'lastName',
    username: 'username',
    files: 'files',
    isInfluencer: 'isInfluencer',
    isTrainer: 'isTrainer',
    isOwner: 'isOwner',
    submitted: 'submitted',
    location,
    socials,
    specializations,
    gymStudioName: 'gymStudioName',
    gymStudioAddress: {
        streetAddress: 'streetAddress',
        city: 'city',
        state: 'state',
        zip: 'zip',
        country: 'country',
    },
    website: 'website',
    approved: 'approved',
}

export const instructorAppFieldErrors = {
    firstName: 'First name is required',
    lastName: 'Last name is required',
    username: 'Username is required and must be at least 3 characters long',
    location: {
        city: 'City is required',
        state: 'State is required',
        zip: 'Zip code must be 5 digits long',
    },
    files: 'Please upload at least one file',
    socials: {
        instagram: 'Instagram handle is required',
    },
    gymStudioName: 'GymStudioName is required',
    gymStudioAddress: {
        streetAddress: 'Street Address is required',
        city: 'City is required',
        state: 'State is required',
        zip: 'Zip code must be 5 digits long',
        country: 'country',
    },
    website: 'Website is required.',
}

export const createClassFieldNames = {
    userID: 'userID',
    name: 'name',
    description: 'description',
    startTime: 'startTime',
    date: 'date',
    categoryFilter: 'categoryFilter',
    category: specializations,
    duration: 'duration',
    equipment: 'equipment',
    type: 'type',
    banner: {
        bucket: 'bucket',
        region: 'region',
        key: 'key',
    },
    intervals: 'intervals',
    classStatus: 'classStatus',
    playlist: 'playlist',
    price: 'price',
    defaultBannerIndex: 'defaultBannerIndex',
}

export const createClassFieldErrors = {
    name: 'Name is required',
    description: 'Description is required',
    date: 'Date is required',
    startTime: 'Time is required',
    category: 'Category is required',
    duration: 'Duration is required',
    banner: 'Banner is required',
    playlist: 'Playlist is required',
    intervals: 'Please provide at least one interval',
    duration: 'Total interval time is longer than class duration.',
    price: 'Price is required',
}
export const userProfileFieldNames = {
    avatar: { bucket: 'bucket', region: 'region', key: 'key' },
    firstName: 'firstName',
    lastName: 'lastName',
    bio: 'bio',
    // website: 'website',
    socials,
    location,
    specializations,
    specializationFilter: 'specializationFilter',
    username: 'username',
}
export const instructorProfileFieldErrors = {
    firstName: 'First name is required.',
    lastName: 'Last name is required.',
    socials: {
        instagram: 'Instagram handle is required',
    },
    username: 'Username is required and must be at least 3 characters long',
    uniqueUsername: 'This username is taken',
    bio: 'Please add a bio',
}

export const traineeProfileFieldErrors = {
    firstName: 'First name is required.',
    lastName: 'Last name is required.',
}

export const agoraStreamStateKeys = {
    uid: 'uid',
    token: 'token',
    streamRecord: 'streamRecord',
    hostUID: 'hostUID',
    instructorClass: 'instructorClass',
    numTraineesBooked: 'numTraineesBooked',
    traineesAttendingClass: 'traineesAttendingClass',
    host: 'host',
    isHost: 'isHost',
    channelName: 'channelName',
    classStarted: 'classStarted',
    classComplete: 'classComplete',
    classEnded: 'classEnded',
    waitingRoomJoined: 'waitingRoomJoined',
    streamRoomLocked: 'streamRoomLocked',
    streamEnded: 'streamEnded',
    streamRoomJoined: 'streamRoomJoined',
    streamHidden: 'streamHidden',
    streamReady: 'streamReady',
    streamStatusMessage: 'streamStatusMessage',
    streamOverlayMessage: 'streamOverlayMessage',
    hasJoinedRTCChannel: 'hasJoinedRTCChannel',
    hasLeftStream: 'hasLeftStream',
    streamClient: 'streamClient',
    messagesClient: 'messagesClient',
    messagesChannel: 'messagesChannel',
    isLoggedInToRTM: 'isLoggedInToRTM',
    streamEventKey: 'streamEvent',
    streamEvent: {
        event: 'event',
        payload: 'payload',
    },
    streamEventQueue: 'streamEventQueue',
    tracks: 'tracks',
    tracksReady: 'tracksReady',
    trackStateKey: 'trackState',
    trackState: {
        video: 'video',
        audio: 'audio',
        hostVideoDisabled: 'hostVideoDisabled',
        hostAudioDisabled: 'hostAudioDisabled',
    },
    currentInterval: 'currentInterval',
    currentIntervalIndex: 'currentIntervalIndex',
    lateTraineeIDs: 'lateTraineeIDs',
    isLateJoin: 'isLateJoin',
    timeUntilNextInterval: 'timeUntilNextInterval',
}

export const paymentMethodFieldNames = {
    name: 'name',
    zip: 'zip',
}
export const paymentMethodFieldErrors = {
    name: 'Name on Card is required.',
    zip: 'Zip/Postal Code is required',
}
