import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  heading1: {
    fontSize: 36,
    lineHeight: 50,
    fontFamily: 'Inter_700Bold',
    marginBottom: 30,
  },
  heading3: {
    fontSize: 22,
    fontFamily: 'Inter_700Bold',
    marginTop: 30,
    lineHeight: 45,
  },
  list_item: {
    fontFamily: 'Inter_400Regular',
    fontSize: 15,
    lineHeight: 28
  },
  body: {
    fontFamily: 'Inter_400Regular',
    fontSize: 15,
    lineHeight: 22
  }
})