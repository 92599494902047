import React, { useState } from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import { StyledTextInput, InputPicker, InputField } from 'components/InputField'
import colors from 'colors'
import { useReactiveVar } from '@apollo/client'
import { FormHeaderLabel, SubHeaderText } from 'typography'
import { statePickerItems } from 'pickerItems'
import DropDownPicker from 'components/DropDownInput'
import { useDimensions } from 'dimensions'
import { instructorApplicationForm } from 'apollo/cache'
import { instructorAppFieldNames as fieldNames } from 'formFieldInfo'
import { FieldContainer } from 'commons/containers'

export const GymStudioDetails = ({ reactiveFormProps }) => {
    const form = useReactiveVar(instructorApplicationForm)
    const { isMobile } = useDimensions()
    return (
        <FieldContainer>
            <SubHeaderText isBold paddingBottom={isMobile ? 5 : 20}>
                What is the name of your gym/studio?
            </SubHeaderText>
            <InputField
                reactiveForm={true}
                fieldTitle="Gym/Studio Name"
                fieldName={fieldNames.gymStudioName}
                defaultValue={form?.gymStudioName ?? ''}
                placeholder="Crunch Fitness"
                {...reactiveFormProps}
            />
            <SubHeaderText isBold paddingBottom={isMobile ? 5 : 20}>
                Where is your gym/studio located?
            </SubHeaderText>
            <AddressContainer>
                <InputField
                    placeholder="Street Address"
                    reactiveForm={true}
                    fieldTitle="Street Address"
                    fieldName={'gymStudioAddress'}
                    nestedFieldName={fieldNames.gymStudioAddress.streetAddress}
                    defaultValue={form?.gymStudioAddress?.streetAddress ?? ''}
                    {...reactiveFormProps}
                />
                <RowContainer style={{ justifyContent: 'space-between' }}>
                    <InputField
                        placeholder="City"
                        reactiveForm={true}
                        fieldTitle="City"
                        fieldName={'gymStudioAddress'}
                        nestedFieldName={fieldNames.gymStudioAddress.city}
                        defaultValue={form?.gymStudioAddress?.city ?? ''}
                        {...reactiveFormProps}
                    />

                    <InputField
                        shouldSanitizeInput
                        placeholder="zip"
                        reactiveForm={true}
                        fieldTitle="Zip Code"
                        fieldName={'gymStudioAddress'}
                        nestedFieldName={fieldNames.gymStudioAddress.zip}
                        value={form?.gymStudioAddress?.zip ?? ''}
                        maxLength={5}
                        {...reactiveFormProps}
                    />
                </RowContainer>
                <DropDownPicker
                    reactiveForm={true}
                    fieldTitle="State"
                    fieldName={'gymStudioAddress'}
                    nestedFieldName={fieldNames.gymStudioAddress.state}
                    selected={form.gymStudioAddress?.[fieldNames.gymStudioAddress.state]}
                    dropDownItems={statePickerItems}
                    {...reactiveFormProps}
                />
            </AddressContainer>
        </FieldContainer>
    )
}
const AddressContainer = styled(View)`
    padding-bottom: ${props => (!props.theme.isMobile ? '100px' : '100px')};
`
const RowContainer = styled(View)`
    flex-direction: row;
    align-items: center;
`

export default GymStudioDetails
