import React, { useState, useEffect } from 'react'
import { View, Linking } from 'react-native'
import { useIsFocused } from '@react-navigation/native'
// ui
import colors from 'colors'
import styled from 'styled-components/native'
// components
import { ScreenContainer, ContentContainer } from 'commons/containers'
import { Headline, Inter700 } from 'styles/typography'
import { RowContainer } from 'commons/containers'
import { ContinueButton } from 'commons/components/ContinueButton'
import PayoutHistory from 'commons/components/instructor/PayoutHistory'
import { FooterBar } from 'commons/components/FooterBar'
import { getPriceString } from 'helpers'
//apollo
import { getCurrentUser } from 'apollo/selectors'
//Stripe
import { stripeAPI } from 'src/actions'
import { STRIPE_API_ROUTES } from '@constants'
const { CREATE_ACCOUNT_LINK, CREATE_LOGIN_LINK, GET_ACCOUNT_BALANCE, GET_STRIPE_CONNECT_ACCOUNT, CREATE_PAYOUT } =
    STRIPE_API_ROUTES
const emptyObj = {}

function Transactions() {
    const { accountID } = getCurrentUser()
    const isFocused = useIsFocused()
    const [availableBalanceString, setAvailableBalanceString] = useState('$0.00')
    const [onboardingLink, setOnboardingLink] = useState(emptyObj)
    const [loginLink, setLoginLink] = useState(emptyObj)
    const [initialDataLoading, setInitialDataLoading] = useState(true)
    const [shouldRefetchTransactions, setShouldRefetchTransactions] = useState(false)
    const [fetchBalanceLoading, setFetchBalanceLoading] = useState(false)
    const [transferBalanceLoading, setTransferBalanceLoading] = useState(false)
    const [transferBalanceStatus, setTransferBalanceStatus] = useState({ actionSuccess: false, statusMessage: '' })

    const goToLogin = () => Linking.openURL(loginLink?.url)
    const goToOnboarding = () => Linking.openURL(onboardingLink?.url)

    async function getAccountBalance({ wasCalledOnFocus = false }) {
        setFetchBalanceLoading(true)
        try {
            const balance = await stripeAPI({ path: GET_ACCOUNT_BALANCE, pathParameters: accountID })
            const availableBalance = getPriceString(balance?.available?.[0]?.amount)
            if (availableBalance != availableBalanceString && wasCalledOnFocus) {
                setShouldRefetchTransactions(true)
            }
            setAvailableBalanceString(availableBalance)
        } catch (error) {
            __DEV__ && console.log('error fetching account balance: ', error)
        }
        setShouldRefetchTransactions(false)
        setFetchBalanceLoading(false)
    }

    async function handleUpdateTransferStatus(wasTransferSuccessful) {
        if (wasTransferSuccessful) {
            setTransferBalanceStatus({ actionSuccess: true, statusMessage: 'Balance successfully transferred' })
            setShouldRefetchTransactions(true)
            await getAccountBalance({ wasCalledOnFocus: false })
        } else {
            setTransferBalanceStatus({ actionSuccess: false, statusMessage: 'Error transferring balance.' })
        }
        setTransferBalanceLoading(false)
    }

    async function handleTransferBalance() {
        if (accountID && availableBalanceString) {
            setTransferBalanceLoading(true)
            const createPayoutResult = await stripeAPI({ path: CREATE_PAYOUT, body: { accountID } })
            const wasTransferSuccessful = createPayoutResult?.status === 'pending' ?? false
            handleUpdateTransferStatus(wasTransferSuccessful)
        }
    }

    useEffect(() => {
        async function fetchInitialData() {
            setInitialDataLoading(true)
            const [connectAccount = emptyObj, onboardingLink = emptyObj] = await Promise.all([
                stripeAPI({ path: GET_STRIPE_CONNECT_ACCOUNT, pathParameters: accountID }),
                stripeAPI({ path: CREATE_ACCOUNT_LINK, body: { accountID } }),
            ])
            onboardingLink?.url && setOnboardingLink(onboardingLink)
            const hasCompletedOnboarding = connectAccount?.payouts_enabled
            if (hasCompletedOnboarding) {
                const [loginLink = emptyObj, balance = emptyObj] = await Promise.all([
                    stripeAPI({ path: CREATE_LOGIN_LINK, body: { accountID } }),
                    stripeAPI({ path: GET_ACCOUNT_BALANCE, pathParameters: accountID }),
                ])
                loginLink?.url && setLoginLink(loginLink)
                const balanceAmount = balance?.available?.[0]?.amount
                balanceAmount && setAvailableBalanceString(getPriceString(balanceAmount))
            }
            setInitialDataLoading(false)
        }
        accountID && fetchInitialData()
    }, [accountID])

    useEffect(() => {
        const shouldRefetchBalance = accountID && isFocused
        if (shouldRefetchBalance) {
            getAccountBalance({ wasCalledOnFocus: true })
        }
    }, [accountID, isFocused])

    return (
        <ScreenContainer hasTopPadding>
            <ContentContainer isFluid>
                <Headline isLargeHeadline isMostBold paddingBottom={25}>
                    Your Payouts
                </Headline>
                <TopRowContainer>
                    <LoginContainer>
                        <ContinueButton
                            onPress={goToOnboarding}
                            text="Stripe Onboarding"
                            disabled={initialDataLoading || !onboardingLink?.url || loginLink?.url}
                            actionLoading={initialDataLoading}
                            buttonTextStyle={{ fontSize: 16 }}
                            color={
                                !onboardingLink?.url && !initialDataLoading
                                    ? colors.cancelClassRed
                                    : colors.homebodyTurquoise
                            }
                        />
                        <ContinueButton
                            onPress={goToLogin}
                            text="Stripe Login"
                            disabled={initialDataLoading || !loginLink?.url}
                            actionLoading={initialDataLoading}
                            buttonTextStyle={{ fontSize: 16 }}
                            color={
                                !loginLink?.url && !initialDataLoading
                                    ? colors.cancelClassRed
                                    : colors.homebodyTurquoise
                            }
                        />
                    </LoginContainer>
                    <TransferContainer>
                        <YourBalanceText>Your available balance</YourBalanceText>
                        <AvailableBalanceText>{availableBalanceString}</AvailableBalanceText>
                        <ContinueButton
                            text="Transfer Balance"
                            style={{ width: '100%' }}
                            onPress={handleTransferBalance}
                            buttonTextStyle={{ fontSize: 16 }}
                            actionLoading={transferBalanceLoading || initialDataLoading || fetchBalanceLoading}
                            apolloActionStatus={transferBalanceStatus}
                            messageStyle={{ fontSize: 16, position: 'absolute', bottom: 80 }}
                            disabled={
                                transferBalanceLoading || initialDataLoading || availableBalanceString === '$0.00'
                            }
                        />
                    </TransferContainer>
                </TopRowContainer>
                <PayoutHistory
                    accountID={accountID}
                    initialDataLoading={initialDataLoading}
                    shouldRefetchTransactions={shouldRefetchTransactions}
                />
            </ContentContainer>

            <FooterBar />
        </ScreenContainer>
    )
}
export default Transactions

const YourBalanceText = styled(Inter700)`
    align-self: center;
    font-size: 16px;
    line-height: 18px;
`
const AvailableBalanceText = styled(Inter700)`
    color: ${colors.homebodyGreen};
    align-self: center;
    font-size: 40.89px;
    line-height: 65.26px;
    letter-spacing: -0.74px;
`
const TopRowContainer = styled(RowContainer)`
    padding-vertical: 3%;
`
const LoginContainer = styled(View)`
    width: 325px;
    height: 250px;
    display: flex;
    padding-vertical: 25px;
    padding-horizontal: 25px;
    justify-content: space-between;
    border-radius: 20px;
    box-shadow: 0px 14px 14px ${colors.neutralGray};
`
const TransferContainer = styled(LoginContainer)`
    margin-left: 35px;
`
