import React, { useEffect } from 'react'
import { View, Text, ScrollView, StyleSheet, TouchableOpacity, Modal as NativeModal } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import styled from 'styled-components/native'
import WebModal from 'modal-react-native-web'
import { termsAndConditions } from 'copy'
import { AntDesign } from '@expo/vector-icons'
import { isWeb } from '@constants'
import { useDimensions } from 'dimensions'
import colors from 'styles/colors'
import VerticalScrollView from 'commons/VerticalScrollView'
import { Inter400 } from 'typography'

const Modal = isWeb ? WebModal : NativeModal
const gradientStart = { x: 0.15, y: 0 }
const gradientEnd = { x: 0.85, y: 0 }
const gradientColors = [colors.homebodyTurquoise, colors.homebodyGreen]
const AGREEMENT_TITLE = `Homebody Live Fitness\nTERMS AND CONDITIONS`

export const TermsModal = ({ setTermsShown, modalVisible, setModalVisible, setTermsAgreed }) => {
    const { isMobile } = useDimensions()

    const hasReadTerms = ({ layoutMeasurement, contentOffset, contentSize }) => {
        const paddingToBottom = 20
        return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom
    }

    const handleCloseModal = (agreed = true) => {
        setModalVisible(false)
        setTermsShown(true)
        setTermsAgreed(agreed)
    }

    return (
        <Modal animationType="slide" transparent={true} visible={modalVisible}>
            <TermsContainer isMobile={isMobile}>
                <CloseIconContainer>
                    <CloseModal onPress={handleCloseModal}>
                        <AntDesign style={{ alignSelf: 'center' }} name="close" size={16} color="black" />
                    </CloseModal>
                </CloseIconContainer>
                <VerticalScrollViewContainer
                    onScroll={({ nativeEvent }) => {
                        if (hasReadTerms(nativeEvent)) handleCloseModal()
                    }}
                    thumbColor={colors.homebodyTurquoise}
                    trackColor={`white`}
                    glassColor={`white`}
                    glassVisiblity={0.25}
                    glassSize={20}
                >
                    <HeaderContainer>
                        <AgreementTitle>{AGREEMENT_TITLE}</AgreementTitle>
                    </HeaderContainer>
                    <AgreementText> {termsAndConditions} </AgreementText>
                </VerticalScrollViewContainer>
                <ButtonsContainer>
                    <AgreementButton isBordered onPress={() => handleCloseModal(false)}>
                        <AntDesign style={{ alignSelf: 'center' }} name="close" size={18} color="black" />
                        <ButtonText>Decline</ButtonText>
                    </AgreementButton>
                    <AgreementButton onPress={handleCloseModal}>
                        <StyledLinearGradientButton start={gradientStart} end={gradientEnd} colors={gradientColors} />
                        <AntDesign style={{ alignSelf: 'center' }} name="check" size={18} color="white" />
                        <ButtonText color="white">Accept</ButtonText>
                    </AgreementButton>
                </ButtonsContainer>
            </TermsContainer>
        </Modal>
    )
}
const CloseModal = styled(TouchableOpacity)`
    height: 45px;
    width: 45px;
    justify-content: center;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    border-radius: 22.5;
    border: 0px solid;
`
const CloseIconContainer = styled(View)`
    width: 100%;
    align-items: flex-end;
    margin-bottom: 10px;
`

const HeaderContainer = styled(View)`
    flex-direction: row;
    height: 40px;
    margin-bottom: 8px;
    justify-content: space-between;
    background-color: white;
`

const VerticalScrollViewContainer = styled(VerticalScrollView)`
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 5px;
    width: 301px;
`
const AgreementTitle = styled(Text)`
    font-weight: bold;
`

const AgreementText = styled(Text)`
    line-height: 20px;
    padding-bottom: 20px;
`

const ButtonsContainer = styled(View)`
    padding: 13px 10px;
    justify-content: center;
    display: flex;
    flex-direction: row;
    width: 100%;
`
const AgreementButton = styled(TouchableOpacity)`
    height: 40px;
    width: 100px;
    margin: 0px 5px;
    border: 0px solid;
    padding: 6px 15px;
    border-radius: 18px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: ${({ isBordered }) => (isBordered ? colors.grayContinue : '#fff')};
`

const ButtonText = styled(Text)`
    margin-left: 4px;
    font-family: Inter_700Bold;
    line-height: 20px;
    font-size: 15px;
    color: ${props => (props.color ? props.color : 'black')};
`

const StyledLinearGradientButton = styled(LinearGradient)`
    z-index: -1;
    position: absolute;
    height: 40px;
    width: 100px;
    overflow: hidden;
    border-radius: 18px;
`

const TermsContainer = styled(View)`
    flex: 1;
    height: 445px;
    width: 331px;
    position: absolute;
    top: 20%;
    left: ${props => (props.isMobile ? '7.5%' : '125px')};
    padding: 15px;
    border-radius: 20px;
    align-self: ${props => (props.isMobile ? 'center' : 'flex-start')};
    border-width: ${StyleSheet.hairlineWidth}px;
    border-color: gray;
    shadow-color: #000000;
    shadow-offset: 4px 4px;
    shadow-radius: 8px;
    shadow-opacity: 0.25;
    background-color: white;
`

export default TermsModal
