import React, { useEffect } from 'react'
import { InputField } from 'components/InputField'
import { FieldContainer } from 'src/commons/containers'
import { useReactiveVar } from '@apollo/client'
import { createClassForm } from 'apollo/cache'
import { createClassFieldNames as fieldNames } from 'formFieldInfo'
import { SubHeading } from 'typography'

const freeClassBetaCopy = 'Classes are currently free for all users.\nThanks for being apart of our beta testing!'

export const Price = ({ reactiveFormProps }) => {
    const form = useReactiveVar(createClassForm)
    const { price } = form

    return (
        <FieldContainer>
            <InputField
                shouldSanitizeInput
                value={price}
                reactiveForm={true}
                largeTitle={true}
                fieldTitle="Class price"
                maxLength={3}
                fieldName={fieldNames.price}
                {...reactiveFormProps}
            />
        </FieldContainer>
    )
}

export default Price
