import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import colors from 'colors'
import { useDimensions } from 'dimensions'
import DropDownPicker from 'react-native-dropdown-picker'
import { Inter500 } from 'typography'
import { Hoverable, Pressable } from 'react-native-web-hover'
import { truncateText } from 'helpers'

DropDownPicker.setListMode('SCROLLVIEW')
// DropDownPicker.setMode('BADGE')

const emptyArr = []
const emptyObj = {}
const noop = () => {}
const StyledDropDownPicker = styled(DropDownPicker)`
    height: ${props => (props.largeTitle ? '56px' : '50px')}
    flex-direction: row;
    align-self: flex-start;
    align-items: center;
    padding-left: 10px;
    width: ${props => (props.width ? props.width : '100%')};
    border-width: 1px;
    border-color: ${props => (props.hasError ? 'red' : props.hovered ? colors.blueContinue : colors.inputFieldGray)}
    background-color: ${colors.inputFieldGray};
    border-radius: 16px;
    margin-bottom: ${props => (props.open && props.shouldPushContent ? '200px' : '0px')}
`
const labelStyleNative = {
    width: '100%',
    alignSelf: 'center',
    color: colors.black,
}

const labelStyleWeb = {
    width: '100%',
    alignSelf: 'center',
    color: colors.darkGrey,
    fontSize: 14,
    fontFamily: 'Inter_600SemiBold',
    marginLeft: 20,
}

const placeHolderStyleNative = {
    paddingLeft: 8,
    lineHeight: 22,
    fontSize: 15.38,
    fontFamily: 'Inter_600SemiBold',
}

const placeHolderStyleWeb = {
    color: colors.darkGrey,
    fontSize: 14,
    fontFamily: 'Inter_600SemiBold',
    marginLeft: 20,
}
export const DropDownInput = ({
    fieldName,
    nestedFieldName = '',
    fieldTitle = '',
    errors = emptyArr,
    selected,
    style,
    containerStyle = emptyObj,
    width,
    alignSelf,
    multipleSelection = false,
    dropDownItems,
    onChangeValue,
    updateForm = noop,
    handleBlur = noop,
    reactiveForm = false,
    isNativeLabelStyle = false,
    largeTitle = false,
    smallTitle = false,
    shouldPushContent = true,
    shouldResetSelected = false,
    placeholder = 'Select an item',
    populateErrors = false,
    getFieldError = noop,
}) => {
    const { isMobile } = useDimensions()
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState(selected || null)
    const [items, setItems] = useState(dropDownItems)
    const isNested = nestedFieldName != ''
    const [fieldError, setFieldError] = useState({})
    const { showError = false, error = '' } = fieldError
    const updateFieldError = () => setFieldError(getFieldError({ fieldName, nestedFieldName }))
    const hideFieldError = () => setFieldError({ ...fieldError, showError: false })

    const updateAndValidate = () => {
        updateForm(isNested ? { fieldName, nestedField: { [nestedFieldName]: value } } : { fieldName, value })
        if (populateErrors) {
            updateFieldError()
        }
        error ? handleBlur({ fieldName, nestedFieldName }) : noop
    }

    const handleOpen = () => {
        if (populateErrors) {
            hideFieldError()
        }
        setOpen(!open)
    }

    const multipleText = truncateText({ text: `${value}`, charCeil: 30 })

    useEffect(() => {
        if (shouldResetSelected) {
            setValue('')
        }
        setItems(dropDownItems)
    }, [dropDownItems])

    useEffect(() => {
        if (populateErrors) {
            updateFieldError()
        }
    }, [populateErrors])

    useEffect(() => {
        if (multipleSelection) {
            setValue(selected)
        }
    }, [selected])

    //Apologies for the smelly styling, styled components don't get along very well with multiple style props
    return (
        <DropDownContainer style={containerStyle}>
            {!!fieldTitle && (
                <InputFieldTitleText
                    largeTitle={largeTitle}
                    smallTitle={smallTitle}
                    style={{ color: showError ? 'red' : smallTitle ? colors.darkGrey : 'black' }}
                >
                    {showError ? error : fieldTitle}
                </InputFieldTitleText>
            )}
            <Hoverable>
                {({ hovered }) => (
                    <StyledDropDownPicker
                        style={style}
                        width={width}
                        open={open}
                        setOpen={handleOpen}
                        items={items}
                        setItems={setItems}
                        value={value}
                        setValue={setValue}
                        hasError={showError}
                        hovered={hovered}
                        placeholder={placeholder}
                        shouldPushContent={shouldPushContent}
                        largeTitle={largeTitle}
                        onClose={
                            error
                                ? () => {
                                      if (populateErrors) {
                                          updateFieldError()
                                      }
                                      handleBlur({ fieldName, nestedFieldName })
                                  }
                                : noop
                        }
                        onChangeValue={value =>
                            onChangeValue ? onChangeValue({ value, setOpen }) : updateAndValidate()
                        }
                        multiple={multipleSelection}
                        multipleText={multipleText}
                        zIndex={1000}
                        dropDownContainerStyle={{
                            backgroundColor: colors.inputFieldGray,
                            width: width ? width : '100%',
                            alignSelf: alignSelf ? alignSelf : 'center',
                            borderColor: showError ? 'red' : hovered ? colors.blueContinue : colors.inputFieldGray,
                            borderTopWidth: 0,
                            zIndex: 1001,
                            top: largeTitle ? 56 : 49,
                        }}
                        labelStyle={isMobile || isNativeLabelStyle ? labelStyleNative : labelStyleWeb}
                        placeholderStyle={isMobile || isNativeLabelStyle ? placeHolderStyleNative : placeHolderStyleWeb}
                        arrowIconContainerStyle={{
                            alignSelf: 'center',
                            marginRight: !isMobile ? 10 : 5,
                        }}
                        tickIconContainerStyle={{
                            marginRight: 5,
                        }}
                        listItemLabelStyle={{
                            marginLeft: 10,
                        }}
                        listItemContainerStyle={{
                            flexDirection: 'row',
                            alignContent: 'space-between',
                            justifyContent: 'center',
                            paddingTop: !isMobile ? 10 : 0,
                            height: 40,
                        }}
                        selectedItemContainerStyle={{
                            backgroundColor: colors.blueContinue,
                        }}
                        selectedItemLabelStyle={{
                            color: 'white',
                            marginLeft: 10,
                        }}
                    />
                )}
            </Hoverable>
        </DropDownContainer>
    )
}

const DropDownContainer = styled(View)`
    flex: 1;
    flex-direction: column;
`
export const InputFieldTitleText = styled(Inter500)`
    font-size: ${props => (props.largeTitle ? '22px' : props.smallTitle ? '11.14px' : '14.1794px')};
    font-weight: ${props => (props.largeTitle ? 'bold' : 'normal')};
    padding-bottom: ${props => (props.largeTitle ? '20px' : '10px')};
    padding-top: ${props => (props.largeTitle ? '40px' : '10px')};
    line-height: 14px;
    z-index: -1;
`
export default DropDownInput
