import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { FormHeaderLabel } from 'typography'
import { specializationPickerItems } from 'pickerItems'
import DropDownPicker from 'components/DropDownInput'
import { instructorApplicationForm } from 'apollo/cache'
import { InputField } from 'components/InputField'
import { FieldContainer, FieldRowContainer } from 'src/commons/containers'
import { useReactiveVar } from '@apollo/client'
import { instructorAppFieldNames as fieldNames } from 'formFieldInfo'

export const Specializations = ({ reactiveFormProps }) => {
    const { updateForm } = reactiveFormProps
    const [preSelectedItems, setPreselectedItems] = useState([])
    const [selectedKeys, setSelectedKeys] = useState([])
    const form = useReactiveVar(instructorApplicationForm)
    const { specializations } = form

    function handleItemSelection({ value }) {
        if (value.length < selectedKeys.length) {
            const keyRemoved = selectedKeys.filter(key => !value.includes(key))
            let newValue = false
            updateForm({
                fieldName: 'specializations',
                nestedField: { [fieldNames.specializations[keyRemoved[0]]]: newValue },
            })
            setSelectedKeys(value)
        } else {
            const keyAdded = value?.[value.length - 1]
            const currentValue = form?.specializations?.[keyAdded]
            if (keyAdded) {
                updateForm({
                    fieldName: 'specializations',
                    nestedField: {
                        [fieldNames.specializations?.[keyAdded]]: currentValue === null ? true : !currentValue,
                    },
                })
            }
            setSelectedKeys(value)
        }
    }

    useEffect(() => {
        const existingSelection =
            Object?.keys(specializations || {})?.filter(key => specializations[key] && key != '__typename') || {}
        setPreselectedItems(existingSelection)
        setSelectedKeys(existingSelection)
    }, [])

    return (
        <FieldContainer>
            <FieldRowContainer>
                <FormHeaderLabel>What do you specialize in? Select all that apply</FormHeaderLabel>
            </FieldRowContainer>
            <DropDownPicker
                selected={preSelectedItems}
                dropDownItems={specializationPickerItems}
                multipleSelection={true}
                fieldTitle="Specializations"
                fieldName={fieldNames.specializations}
                reactiveForm={true}
                onChangeValue={value => handleItemSelection(value)}
                {...reactiveFormProps}
            />
        </FieldContainer>
    )
}

export default Specializations
