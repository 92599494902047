import React, { useState } from 'react'
import { TouchableOpacity } from 'react-native'
import { Feather, FontAwesome } from '@expo/vector-icons'

const IconCheckBox = ({
    onPress = () => {},
    checked = false,
    color = { unchecked: 'black', checked: 'red' },
    size = 30,
    isSquare = false,
}) => {
    const [isChecked, setIsChecked] = useState(checked)

    const boxPressed = () => {
        onPress(!isChecked)
        setIsChecked(!isChecked)
    }
    return (
        <TouchableOpacity onPress={boxPressed}>
            {isSquare ? (
                <FontAwesome
                    name={checked ? 'check-square' : 'square-o'}
                    color={checked ? color.checked : color.unchecked}
                    size={size}
                />
            ) : (
                <Feather
                    name={checked ? 'check-circle' : 'circle'}
                    color={checked ? color.checked : color.unchecked}
                    size={size}
                />
            )}
        </TouchableOpacity>
    )
}

export default IconCheckBox
