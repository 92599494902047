import React, { useMemo, useEffect } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { createDrawerNavigator } from '@react-navigation/drawer'
import * as screenNames from 'screenNames'
import colors from 'colors'
import { INSTRUCTOR_DRAWER_WIDTH } from '@constants'
import VectorIcon from 'components/VectorIcon'
import { agoraStreamState } from 'apollo/cache'
import { useReactiveVar } from '@apollo/client'
//------------------ Instructor Screens ------------------
import CreateClassForm from 'screens/Instructor/createClass/CreateClassForm'
import Home from 'screens/Instructor/Home'
import UnpublishedClasses from 'screens/Instructor/UnpublishedClasses'
import Dashboard from 'screens/Instructor/Dashboard'
import PublishedClasses from 'screens/Instructor/PublishedClasses'
import Transactions from 'screens/Instructor/Transactions'
import ContactUsForm from 'screens/Instructor/ContactUsForm'
import Notifications from 'screens/Instructor/Notifications'
import InstructorProfile from 'screens/Instructor/InstructorProfile'
import ChangePassword from 'screens/trainee/ChangePassword'
import PublishedClassDetails from 'screens/Instructor/PublishedClassDetails'

//------------------ Nav Components ------------------
import WebHeader from 'components/WebHeader'
import { InstructorDrawer } from 'components/InstructorDrawer'
import NavigationIconLabel from 'components/NavigationIconLabel'

//------------------ Streaming Screens ------------------
import InstructorStreamRoom from 'screens/Streaming/InstructorStreamRoom'
import { useDimensions } from 'dimensions'
import { View } from 'react-native'
import Settings from 'screens/Instructor/Settings'

const emptyObj = {}
const noop = () => {}

const Stack = createStackNavigator()
const Drawer = createDrawerNavigator()

const drawerIconStyle = { zIndex: 10, paddingLeft: 5 }
const CreateClassScreens = Object.values(screenNames.CREATE_CLASS).map(name => {
    return <Stack.Screen key={name} name={name} options={{ title: name }} component={CreateClassForm} key={name} />
})

const CreateClassStackNav = () => (
    <Stack.Navigator
        screenOptions={{ headerShown: false }}
        detachInactiveScreens={true}
        initialRouteName={__DEV__ ? screenNames.CREATE_CLASS.DETAILS : screenNames.CREATE_CLASS.DETAILS}
    >
        {CreateClassScreens}
    </Stack.Navigator>
)

const SettingsScreens = [
    {
        name: screenNames.INSTRUCTOR_SETTINGS,
        component: Settings,
        options: {
            title: 'Settings',
        },
    },
    {
        name: screenNames.INSTRUCTOR_PROFILE,
        component: InstructorProfile,
        options: {
            title: 'Edit profile',
        },
    },
    {
        name: screenNames.CHANGE_PASSWORD,
        component: ChangePassword,
        options: {
            title: 'Change password',
        },
    },
    {
        name: screenNames.CONTACT_US_FORM,
        component: ContactUsForm,
        options: {
            title: 'Contact Us',
        },
    },
]

const SettingsStackNav = () => (
    <Stack.Navigator
        screenOptions={{ headerShown: false }}
        initialRouteName={screenNames.INSTRUCTOR_SETTINGS_NAVIGATOR}
    >
        {SettingsScreens.map(({ name, component, options = {} }) => (
            <Stack.Screen key={name} name={name} component={component} options={options} />
        ))}
    </Stack.Navigator>
)

const PublishedClassesScreens = [
    {
        name: screenNames.PUBLISHED_CLASSES,
        component: PublishedClasses,
        options: {
            title: 'Published classes',
        },
    },
    {
        name: screenNames.PUBLISHED_CLASS_DETAILS,
        component: PublishedClassDetails,
        options: {
            title: 'Class Details',
        },
    },
    {
        name: screenNames.INSTRUCTOR_STREAM_ROOM,
        component: InstructorStreamRoom,
        options: {
            title: 'Stream room',
        },
    },
]

const PublishedClassesStackNav = () => (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={screenNames.PUBLISHED_CLASSES}>
        {PublishedClassesScreens.map(({ name, component, options = {} }) => (
            <Stack.Screen key={name} name={name} component={component} options={options} />
        ))}
    </Stack.Navigator>
)

const MyAccountScreens = [
    {
        name: screenNames.INSTRUCTOR_PROFILE,
        component: InstructorProfile,
        options: {
            title: 'My Account',
        },
    },
]

const MyAccountStackNav = () => (
    <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName={screenNames.INSTRUCTOR_PROFILE}>
        {MyAccountScreens.map(({ name, component, options = {} }) => (
            <Stack.Screen key={name} name={name} component={component} options={options} />
        ))}
    </Stack.Navigator>
)

const drawerScreenOptions = ({ isMobile, isDesktop, isUserInStream }) => ({
    drawerType: isUserInStream ? 'front' : 'permanent',
    defaultStatus: isMobile || isUserInStream ? 'closed' : 'open',
    drawerPosition: 'left',
    drawerStyle: {
        width: INSTRUCTOR_DRAWER_WIDTH,
    },
    drawerItemStyle: {
        alignContent: 'flex-start',
        width: 200,
        height: 56,
        justifyContent: 'center',
        borderRadius: 12,
    },
    headerShown: false,
})

export const InstructorDrawerNavigator = () => {
    const { isMobile, isDesktop } = useDimensions()
    const streamState = useReactiveVar(agoraStreamState)
    const { streamRoomJoined: isUserInStream } = streamState
    const screenOptions = useMemo(
        () => drawerScreenOptions({ isMobile, isDesktop, isUserInStream }),
        [isMobile, isDesktop, isUserInStream],
    )

    return (
        <View style={{ flex: 1 }}>
            {isDesktop ? <WebHeader /> : <></>}
            <Drawer.Navigator
                initialRouteName={__DEV__ ? screenNames.UNPUBLISHED_CLASSES : screenNames.HOME}
                screenOptions={screenOptions}
                drawerContent={props => <InstructorDrawer {...props} />}
            >
                <Drawer.Screen
                    name={screenNames.HOME}
                    options={{
                        title: 'Overview',
                        drawerLabel: focused => <NavigationIconLabel title="My Homebody" focused={focused} />,
                        drawerIcon: ({ focused }) => (
                            <VectorIcon
                                family="AntDesign"
                                name="home"
                                style={drawerIconStyle}
                                color={focused ? 'white' : colors.subTextGray}
                            />
                        ),
                    }}
                    component={Home}
                />

                <Drawer.Screen
                    name={screenNames.CREATE_CLASS_NAVIGATOR}
                    options={{
                        unmountOnBlur: true,
                        title: 'Create class',
                        drawerLabel: focused => <NavigationIconLabel title="Create Class" focused={focused} />,
                        drawerIcon: ({ focused }) => (
                            <VectorIcon
                                family="SimpleLineIcons"
                                name="pencil"
                                style={drawerIconStyle}
                                color={focused ? 'white' : colors.subTextGray}
                            />
                        ),
                    }}
                    component={CreateClassStackNav}
                />
                <Drawer.Screen
                    name={screenNames.PUBLISHED_CLASSES_NAVIGATOR}
                    options={{
                        title: 'Published classes',
                        drawerLabel: focused => <NavigationIconLabel title="Published Classes" focused={focused} />,
                        drawerIcon: ({ focused }) => (
                            <VectorIcon
                                family="AntDesign"
                                name="videocamera"
                                style={drawerIconStyle}
                                color={focused ? 'white' : colors.subTextGray}
                            />
                        ),
                    }}
                    component={PublishedClassesStackNav}
                />
                <Drawer.Screen
                    name={screenNames.UNPUBLISHED_CLASSES}
                    options={{
                        title: 'Unpublished classes',
                        drawerLabel: focused => <NavigationIconLabel title="Unpublished Classes" focused={focused} />,
                        drawerIcon: ({ focused }) => (
                            <VectorIcon
                                family="MaterialIcons"
                                name="access-time"
                                style={drawerIconStyle}
                                color={focused ? 'white' : colors.subTextGray}
                            />
                        ),
                    }}
                    component={UnpublishedClasses}
                />
                <Drawer.Screen
                    name={screenNames.TRANSACTIONS}
                    options={{
                        title: 'Transactions',
                        drawerLabel: focused => <NavigationIconLabel title="Transactions" focused={focused} />,
                        drawerIcon: ({ focused }) => (
                            <VectorIcon
                                family="MaterialIcons"
                                name="align-horizontal-left"
                                style={drawerIconStyle}
                                color={focused ? 'white' : colors.subTextGray}
                            />
                        ),
                    }}
                    component={Transactions}
                />
                <Drawer.Screen
                    name={screenNames.MY_ACCOUNT_NAVIGATOR}
                    options={{
                        title: 'My account',
                        drawerLabel: focused => <NavigationIconLabel title="My Account" focused={focused} />,
                        drawerIcon: ({ focused }) => (
                            <VectorIcon
                                family="MaterialCommunityIcons"
                                name="account"
                                style={drawerIconStyle}
                                color={focused ? 'white' : colors.subTextGray}
                            />
                        ),
                    }}
                    component={MyAccountStackNav}
                />

                {/* <Drawer.Screen
                name={screenNames.DASHBOARD}
                options={{
                    title: 'Dashboard',
                    drawerLabel: focused => <NavigationIconLabel title="Dashboard" focused={focused} />,
                    drawerIcon: ({ focused }) => (
                        <VectorIcon
                            family="AntDesign"
                            name="barschart"
                            style={drawerIconStyle}
                            color={focused ? 'white' : colors.subTextGray}
                        />
                    ),
                }}
                component={Dashboard}
            />
            /> */}
                <Drawer.Screen
                    name={screenNames.NOTIFICATIONS}
                    options={{
                        title: 'Notifications',
                        drawerLabel: focused => <NavigationIconLabel title="Notifications" focused={focused} />,
                        drawerIcon: ({ focused }) => (
                            <VectorIcon
                                family="Ionicons"
                                name="notifications-outline"
                                style={drawerIconStyle}
                                color={focused ? 'white' : colors.subTextGray}
                            />
                        ),
                    }}
                    component={Notifications}
                />
                <Drawer.Screen
                    name={screenNames.INSTRUCTOR_SETTINGS_NAVIGATOR}
                    options={{
                        title: 'Settings',
                        drawerLabel: focused => <NavigationIconLabel title="Settings" focused={focused} />,
                        drawerIcon: ({ focused }) => (
                            <VectorIcon
                                family="Ionicons"
                                name="settings-outline"
                                style={drawerIconStyle}
                                color={focused ? 'white' : colors.subTextGray}
                            />
                        ),
                    }}
                    component={SettingsStackNav}
                />
            </Drawer.Navigator>
        </View>
    )
}
