import React, { useState, useEffect } from 'react'
import { TouchableOpacity } from 'react-native'
import colors from 'colors'
import styled from 'styled-components/native'
import { SubHeaderText } from 'typography'

export const StreamRoomButton = ({ style, onPress, buttonColor = colors.subTextGray, buttonText }) => {
    return (
        <StyledStreamRoomButton style={style} onPress={onPress} buttonColor={buttonColor}>
            <StreamRoomButtonText color="white" isBold>
                {buttonText}
            </StreamRoomButtonText>
        </StyledStreamRoomButton>
    )
}
const StreamRoomButtonText = styled(SubHeaderText)`
    font-size: 17px;
    line-height: 20.5px;
`
const StyledStreamRoomButton = styled(TouchableOpacity)`
    border-radius: 5px;
    height: 50px;
    width: 150px;
    backgroundColor: ${props => props.buttonColor}
    justify-content: center;
    align-items: center;
    align-self: center;
`

export default StreamRoomButton
