import React from 'react'
import { TouchableOpacity } from 'react-native'
import CheckBox from '@react-native-community/checkbox'
import { SubHeaderText } from 'typography'
import colors from 'colors'
import styled from 'styled-components/native'
import { useDimensions } from 'dimensions'
import IconCheckBox from 'commons/icons/IconCheckBox'

const noop = () => {}
export default function CheckBoxWithLabel({ label = '', value = false, onValueChange = noop }) {
    const { isMobile } = useDimensions()

    return (
        <>
            {isMobile ? (
                <CheckBoxWithLabelContainer onPress={onValueChange}>
                    <SubHeaderText isBold>{label}</SubHeaderText>
                </CheckBoxWithLabelContainer>
            ) : (
                <CheckBoxWithLabelContainer>
                    <IconCheckBox
                        onPress={onValueChange}
                        checked={value}
                        color={{ unchecked: colors.starGray, checked: colors.homebodyTurquoise }}
                    />
                    <SubHeaderText paddingLeft={15} isBold onPress={onValueChange}>
                        {label}
                    </SubHeaderText>
                </CheckBoxWithLabelContainer>
            )}
        </>
    )
}

const CheckBoxWithLabelContainer = styled(TouchableOpacity)`
    flex-direction: row;
    align-items: center;
    margin-vertical: 15px;
    padding-left: 5px;
`
