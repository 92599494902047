import React from 'react'
import { FillContainer } from 'commons/containers'
import PressContent from './PressContent'
import Team from './Team'
import Contact from './Contact'
import Features from './Features'
import { FooterBar } from 'components/FooterBar'

const PressKitPage: React.FC = () => (
    <FillContainer>
        <PressContent />
        <Features />
        <Team />
        <Contact />
        <FooterBar />
    </FillContainer>
)

export default PressKitPage
