import React, { useState, useEffect } from 'react'
import { View, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import colors from 'colors'
import { useDimensions } from 'dimensions'
import { Inter500, Inter600 } from 'typography'
import { get } from 'lodash'
import { Hoverable } from 'react-native-web-hover'
import { AntDesign } from '@expo/vector-icons'
import { iconHitSlop } from '@constants'
import { formatDate } from 'helpers'
import VectorIcon from 'components/VectorIcon'
const noop = () => {}
const StyledDropdown = styled(View)`
    height: 56px;
    width: 60%;
    flex-direction: row;
    justifyContent: space-between;
    padding-horizontal: 12px;
    align-items: center;
    padding-left: 10px;
    border-width: 1px;
    border-color: ${props => (props.hasError ? 'red' : props.hovered ? colors.blueContinue : colors.inputFieldGray)}
    background-color: ${colors.inputFieldGray};
    border-radius: 16px;
`

export const DateTimeDropdown = ({
    fieldName,
    nestedFieldName = '',
    fieldTitle,
    errors = [],
    getFieldError,
    open = false,
    setOpen,
    placeholder = 'Select a date',
    date = {},
    largeTitle = true,
    updateForm,
    populateErrors = false,
    fieldError,
    setFieldError,
}) => {
    const isNested = nestedFieldName != ''
    const { showError = false, error = '' } = fieldError
    const formattedDate = date?.dateString ? formatDate(date.dateString) : placeholder

    const updateFieldError = () => setFieldError(getFieldError({ fieldName, nestedFieldName }))
    const hideFieldError = () => setFieldError({ ...fieldError, showError: false })
    const handleOpen = () => {
        if (populateErrors) {
            hideFieldError()
        }
        setOpen(!open)
    }

    useEffect(() => {
        if (populateErrors) {
            updateFieldError()
        }
    }, [populateErrors])

    useEffect(() => {
        const { dateString: value } = date
        updateForm(isNested ? { fieldName, nestedField: { [nestedFieldName]: value } } : { fieldName, value })
    }, [date])

    return (
        <DropdownContainer onPress={handleOpen}>
            <InputFieldTitleText largeTitle={largeTitle} style={{ color: showError ? 'red' : 'black', marginTop: 20 }}>
                {showError ? error : fieldTitle}
            </InputFieldTitleText>
            <Hoverable>
                {({ hovered }) => (
                    <StyledDropdown hasError={showError} hovered={hovered}>
                        <DateTimeText>{formattedDate}</DateTimeText>
                        <View>
                            <TouchableOpacity onPress={handleOpen} hitSlop={iconHitSlop}>
                                <VectorIcon
                                    family="Entypo"
                                    name={open ? 'chevron-thin-up' : 'chevron-thin-down'}
                                    size={15}
                                    color="black"
                                />
                            </TouchableOpacity>
                        </View>
                    </StyledDropdown>
                )}
            </Hoverable>
        </DropdownContainer>
    )
}
const DateTimeText = styled(Inter600)`
    padding-left: 20px;
    font-size: 15px;
    line-height: 22px;
    color: ${colors.darkGrey};
`
export const InputFieldTitleText = styled(Inter500)`
    line-height: 14px;
    color: ${props => (props.showError ? 'red' : 'black')};
    font-weight: ${props => (props.largeTitle ? 'bold' : 'normal')};
    padding-bottom: ${props => (props.largeTitle ? '20px' : '0px')};
    padding-top: ${props => (props.largeTitle ? '25px' : '5px')};
    font-size: ${props => (props.largeTitle ? '22px' : '14.1794px')};
`
const DropdownContainer = styled(TouchableOpacity)`
    flex: 1;
    flex-direction: column;
    z-index: 10;
    padding-top: '20px';
`
export default DateTimeDropdown
