import React, { useState, useEffect } from 'react'
import { useNavigation, useRoute, StackActions } from '@react-navigation/core'
import { View, ActivityIndicator } from 'react-native'
import { ContinueButton } from 'commons/components/ContinueButton'
import { TraineeWebBannerHeader } from 'commons/components/TraineeWebBannerHeader'
import CustomImage from 'commons/components/CustomImage'
import { FormBottomRowToColumnContainer, RowContainer, ScreenContainer, BannerContainer } from 'commons/containers'
import { Inter900, Inter500, HeaderText, SubHeaderText, Headline, SmallText } from 'styles/typography'
import PaymentModal from 'commons/components/PaymentModal'
import colors from 'styles/colors'
import styled from 'styled-components/native'
import { MY_CREDITS, CLASS_DETAILS } from 'screenNames'
import { FooterBar } from 'commons/components/FooterBar'
import { getCurrentUser } from 'apollo/selectors'
import { cache } from 'apollo/cache'
import * as queries from 'apollo/queries'
import { STRIPE_API_ROUTES, isWeb } from '@constants'
const { CREATE_INTENT, CREATE_SUBSCRIPTION, GET_PRODUCT, GET_PRICE } = STRIPE_API_ROUTES
import { stripeAPI } from 'src/actions'
import { getPriceString } from 'helpers'
import { useDimensions } from 'dimensions'
import useDefaultBanners from 'commons/hooks/useDefaultBanners'
import { useApolloClient } from '@apollo/client'
const emptyObj = {}

const PaymentDetail = ({ name = '', detail = '', isHeader = false, isMobile }) => {
    return (
        <PaymentDetailContainer>
            {isMobile ? (
                isHeader ? (
                    <>
                        <TitleText>{name}</TitleText>
                        <TitleText>{detail}</TitleText>
                    </>
                ) : (
                    <>
                        <InfoText isMostBold color={colors.homebodyTurquoise}>
                            {name}
                        </InfoText>
                        <InfoText isMostBold>{detail}</InfoText>
                    </>
                )
            ) : isHeader ? (
                <>
                    <HeaderText>{name}</HeaderText>
                    <HeaderText>{detail}</HeaderText>
                </>
            ) : (
                <>
                    <Headline isMostBold color={colors.homebodyTurquoise}>
                        {name}
                    </Headline>
                    <Headline isMostBold>{detail}</Headline>
                </>
            )}
        </PaymentDetailContainer>
    )
}

export default function PurchaseForm() {
    const navigation = useNavigation()
    const client = useApolloClient()
    const { isMobile } = useDimensions()
    const defaultBanners = useDefaultBanners()
    const { params } = useRoute() || {}
    const amount = 500
    const { priceID, classID, paymentMethod, isProfile } = params || emptyObj
    const [classFromID, setClassFromID] = useState({})
    const [credits, setCredits] = useState(0)
    const [price, setPrice] = useState(0)
    const [total, setTotal] = useState(0)
    const [monthly, setMonthly] = useState(false)
    const [fee, setFee] = useState(0)
    const [loading, setLoading] = useState(true)
    const { id: userID, customerID, email } = getCurrentUser()

    const [modalVisible, setModalVisible] = useState(false)

    useEffect(() => {
        const getClass = async () => {
            const {
                data: { getClass: classData },
            } = await client.query({
                query: queries.GET_CLASS,
                variables: {
                    id: `${classID}`,
                },
            })

            setClassFromID({ ...classData })
        }
        classID && getClass()
    }, [classID])

    const { banner, defaultBannerIndex } = classFromID || emptyObj

    async function makePurchase() {
        const description = `${credits} credits for ${getPriceString(price)}${monthly ? '/month' : ''}` || 'Purchase'
        let purchase
        if (monthly) {
            purchase = await stripeAPI({
                path: CREATE_SUBSCRIPTION,
                body: {
                    customerID,
                    paymentMethod,
                    price: priceID,
                    description,
                    metadata: { credits, userID },
                },
            })
        } else {
            purchase = await stripeAPI({
                path: CREATE_INTENT,
                body: {
                    amount: total,
                    paymentMethod,
                    customerID,
                    email,
                    description,
                    metadata: { credits, userID },
                },
            })
        }

        __DEV__ && console.log('purchase: ', purchase)
        return purchase
    }

    const navigatePurchase = async () => {
        setModalVisible(true)
        const purchaseResult = await makePurchase()
        const { status: purchaseStatus, metadata: { credits: creditsPurchased } = emptyObj } = purchaseResult
        const isSuccessfulPurchase = purchaseStatus == 'succeeded'
        const normalizedID = cache.identify({
            id: userID,
            __typename: 'User',
        })
        cache.modify({
            id: normalizedID,
            fields: {
                credits(existing) {
                    if (creditsPurchased && isSuccessfulPurchase) {
                        return parseInt(existing) + parseInt(creditsPurchased)
                    }
                },
            },
        })
        setTimeout(() => {
            setModalVisible(false)
            classID
                ? navigation.navigate(CLASS_DETAILS, { classID, hasBookedClass: false })
                : navigation.navigate(MY_CREDITS, { isProfile })
        }, 2500)
    }

    useEffect(() => {
        async function getProduct() {
            const price = await stripeAPI({ path: GET_PRICE, pathParameters: priceID })
            const product = await stripeAPI({ path: GET_PRODUCT, pathParameters: price?.product })
            setCredits(product?.metadata?.credits)
            setPrice(price?.unit_amount)
            setTotal(price?.unit_amount + fee)
            setMonthly(price?.recurring !== null ? true : false)
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        }
        priceID && getProduct()
    }, [priceID])

    useEffect(() => {
        const resetStack = navigation.getParent().addListener('blur', () => {
            navigation.dispatch(StackActions.popToTop())
        })

        return resetStack
    }, [navigation])

    const imageStyle = { height: 250, width: '100%' }
    return (
        <ScreenContainer>
            {!isMobile &&
                (classID ? (
                    <BannerContainer>
                        <CustomImage
                            resizeMode="cover"
                            imgKey={banner?.key}
                            defaultBanner
                            defaultImage={defaultBanners && defaultBanners[defaultBannerIndex]}
                            style={imageStyle}
                        />
                    </BannerContainer>
                ) : (
                    <TraineeWebBannerHeader title={'My Credits'} />
                ))}
            {isMobile ? (
                <PurchaseContainer>
                    <PaymentModal modalVisible={modalVisible} />
                    {loading ? (
                        <StyledActivityIndicator size="large" color={colors.homebodyTurquoise} animating={true} />
                    ) : (
                        <>
                            <InfoContainer>
                                <TitleText>You're About to Purchase</TitleText>
                                <InfoText style={{ marginTop: '8%' }}>{`${credits} credits for ${getPriceString(
                                    price,
                                )}${monthly ? '/month' : ''}`}</InfoText>
                                <SubText style={{ marginTop: '4%' }}>
                                    {monthly
                                        ? 'This is a monthly subscription. You can cancel at anytime. All unused credits can roll over on a monthly basis.'
                                        : ''}
                                </SubText>
                            </InfoContainer>
                            <DetailContainer>
                                <TitleText>You Pay</TitleText>
                                <PaymentDetail
                                    name={`${credits} credits`}
                                    detail={`${getPriceString(price)}${monthly ? '/month' : ''}`}
                                    isMobile={isMobile}
                                />
                                <PaymentDetail
                                    name={'Processing Fee'}
                                    detail={`${getPriceString(fee)}`}
                                    isMobile={isMobile}
                                />
                                <PaymentDetail name={'Coupon Code'} isMobile={isMobile} />
                                <BottomBorderView />
                                <PaymentDetail
                                    name={'Total'}
                                    detail={`${getPriceString(total)} USD`}
                                    isHeader
                                    isMobile={isMobile}
                                />
                                {monthly && <PaymentDetail detail={'today'} isMobile={isMobile} />}
                            </DetailContainer>{' '}
                            <FormBottomRowToColumnContainer style={{ paddingBottom: '1%', justifyContent: 'center' }}>
                                <StyledContinueButton
                                    nonHover
                                    disabled={loading}
                                    color={colors.homebodyTurquoise}
                                    text={monthly ? 'Confirm & Subscribe' : 'Confirm'}
                                    onPress={navigatePurchase}
                                />
                            </FormBottomRowToColumnContainer>
                        </>
                    )}
                </PurchaseContainer>
            ) : (
                <>
                    <WebPurchaseContainer>
                        <PaymentModal modalVisible={modalVisible} />
                        {loading ? (
                            <StyledActivityIndicator size="large" color={colors.homebodyTurquoise} animating={true} />
                        ) : (
                            <>
                                <InfoContainer flex={1}>
                                    <HeaderText>You're About to Purchase</HeaderText>
                                    <SubHeaderText
                                        isMostBold
                                        paddingTop={27}
                                        style={{ letterSpacing: '-0.2px' }}
                                    >{`${credits} credits for ${getPriceString(price)}${
                                        monthly ? '/month' : ''
                                    }`}</SubHeaderText>
                                    <Headline style={{ marginTop: '4%' }}>
                                        {monthly && 'This is a monthly subscription. You can cancel at anytime. '}
                                        <Headline isBold>All unused credits can roll over on a monthly basis.</Headline>
                                    </Headline>
                                </InfoContainer>
                                <InfoContainer flex={1}>
                                    <HeaderText>You Pay</HeaderText>
                                    <PaymentDetail
                                        name={`${credits} credits`}
                                        detail={`${getPriceString(price)}${monthly ? '/month' : ''}`}
                                    />
                                    <PaymentDetail
                                        name={'Processing Fee'}
                                        detail={`${getPriceString(fee)}`}
                                        isMobile={isMobile}
                                    />
                                    <PaymentDetail name={'Coupon Code'} isMobile={isMobile} />
                                    <BottomBorderView />
                                    <PaymentDetail
                                        name={'Total'}
                                        detail={`${getPriceString(total)} USD`}
                                        isHeader
                                        isMobile={isMobile}
                                    />
                                    {monthly && <TodayText isBold>today</TodayText>}
                                    <PurchaseButton
                                        nonHover
                                        disabled={loading}
                                        color={colors.homebodyTurquoise}
                                        text={monthly ? 'Confirm & Subscribe' : 'Confirm'}
                                        onPress={navigatePurchase}
                                    />
                                </InfoContainer>
                            </>
                        )}
                    </WebPurchaseContainer>
                    <FooterBar />
                </>
            )}
        </ScreenContainer>
    )
}

const InfoContainer = styled(View)`
    ${({ flex }) => flex && `flex: ${flex};`}
    ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
    padding-horizontal: ${({ theme }) => (theme.isMobile ? '4' : '6')}%;
    ${({ theme }) => theme.isMobile && 'width: 100%; margin-top: 5%; margin-bottom: 10%;'}
`
const DetailContainer = styled(View)`
    flex: 1;
    width: 100%;
    padding-horizontal: 4%;
`
const PaymentDetailContainer = styled(RowContainer)`
    justify-content: space-between;
    align-items: center;
    margin-top: 4%;
    ${({ theme }) => theme.isMobile && 'margin-right: 8%;'}
`
const PurchaseContainer = styled(View)`
    flex: 1;
    ${({ theme }) => !theme.isMobile && 'width: 50%'}
`
const WebPurchaseContainer = styled(View)`
    flex-direction: row;
    padding-top: 5%;
    justify-content: space-around;
    flex: 1;
    padding-bottom: 50px;
`
const BottomBorderView = styled(View)`
    height: 30px;
    width: 100%;
    border-bottom-width: 2px;
    border-color: ${colors.dividingGray};
    ${({ theme }) => !theme.isMobile && 'margin-top: 10%;'}
`
const TitleText = styled(Inter900)`
    font-size: 21px;
    line-height: 25px;
`
const TodayText = styled(SmallText)`
    align-self: flex-end;
    letter-spacing: -0.2px;
    ${({ theme }) => theme.isMobile && 'margin-right: 8%;'}
`
const InfoText = styled(Inter900)`
    font-size: 14px;
    line-height: 17px;
    color: ${props => props.color || colors.black};
`
const SubText = styled(Inter500)`
    font-size: 10px;
    line-height: 12px;
`
const StyledContinueButton = styled(ContinueButton)`
    ${({ theme }) => !theme.isMobile && 'width: 371px;'}
`
const StyledActivityIndicator = styled(ActivityIndicator)`
    flex: 1;
    align-self: center;
`
const PurchaseButton = styled(ContinueButton)`
    ${({ theme }) => !theme.isMobile && 'width: 268px; align-self:flex-end; margin-top: 15%;'}
`
