import React from 'react'
import { View } from 'react-native'
import { Inter900, Inter700, Inter500, TEXT_LINEAR_GRADIENT } from 'typography'
import colors from 'colors'

import styled from 'styled-components/native'
import { useDimensions } from 'dimensions'
import { CONTACT } from '../../constants/appInfo'
import SocialIcons from 'components/SocialIcons'

const GetInTouch: React.FC = () => {
    const { isMobile } = useDimensions()
    return (
        <Container>
            <Cell>
                <TitleText>Get in Touch</TitleText>
                <Tagline>{'Give us a shout.We’d love to\nhear from you.'}</Tagline>
                {!isMobile && <ContactText>{CONTACT.EMAIL}</ContactText>}
            </Cell>
            <ContactCell>
                <View style={{ flexWrap: 'wrap', paddingRight: 10, paddingBottom: 32 }}>
                    <ContactLabel>Contact Info</ContactLabel>
                    <ContactText>{CONTACT.EMAIL}</ContactText>
                </View>
                <View style={{ marginBottom: 50 }}>
                    <ContactLabel>Locations</ContactLabel>
                    <ContactText>{`Los Angeles, CA`}</ContactText>
                    <ContactText>{`Manhattan, NY`}</ContactText>
                    <ContactText>{`Miami, FL`}</ContactText>
                </View>
                <View>
                    <SocialIcons />
                </View>
            </ContactCell>
        </Container>
    )
}

const Container = styled(View)`
    flex-direction: ${props => (props.theme.isMobile ? 'column' : 'row')};
    justify-content: flex-start;
    padding-bottom: 92px;
    padding-left: 5%;
    padding-right: 5%;
`

const Cell = styled(View)`
    width: ${props => (props.theme.isMobile ? '100%' : props.theme.isTablet ? '70%' : '40%')};
`

const ContactCell = styled(Cell)`
    flex-direction: ${props => (props.theme.isMobile || props.theme.isTablet ? 'column' : 'row')};
    justify-content: space-around;
    width: ${props => (props.theme.isMobile ? '50%' : '65%')};
`

const ContactLabel = styled(Inter700)`
    font-size: ${({ theme }) => (theme.isMobile ? '16px' : '20px')};
    line-height: ${({ theme }) => (theme.isMobile ? '23px' : '25px')};
    margin-bottom: 20px;
`

const ContactText = styled(Inter500)`
    font-size: ${({ theme }) => (theme.isMobile ? '14px' : '20px')};
    line-height: ${({ theme }) => (theme.isMobile ? '22px' : '25px')};
    margin-bottom: 14px;
    color: ${colors.subTextGray};
`

const TitleText = styled(Inter900)`
    font-size: ${props => (props.theme.isMobile ? '38px' : '67px')};
    line-height: ${props => (props.theme.isMobile ? '42px' : '72px')};
    margin-bottom: 35px;
`

const Tagline = styled(Inter900)`
    ${({ theme }) =>
        !theme.isMobile &&
        TEXT_LINEAR_GRADIENT({
            direction: '270.45deg',
            colorStop1: `${colors.homebodyGreen} 14.37%`,
            colorStop2: `${colors.homebodyTurquoise} 98.03%`,
        })};
    width: 100%;
    font-size: ${props => (props.theme.isMobile ? '18px' : '38px')};
    line-height: ${props => (props.theme.isMobile ? '28px' : '45px')};
    margin-bottom: ${props => (props.theme.isMobile ? '24px' : '30px')};
`

export default GetInTouch
