import { useEffect } from 'react'
import { HOST_EVENTS } from 'constants/streamEvents'
import {
    toggleCamera,
    toggleMicrophone,
    endStream,
    sendChannelMessage,
    sendPeerToPeerMessage,
    beRightBack,
} from 'agoraActions'
import { CLASS_STATUS } from '@constants'
const { COMPLETED, IN_PROGRESS, PUBLISHED } = CLASS_STATUS
const {
    HOST_BE_RIGHT_BACK,
    HOST_HIDE_VIDEO,
    HOST_SHOW_VIDEO,
    HOST_MUTE_AUDIO,
    HOST_UNMUTE_AUDIO,
    HOST_END_STREAM,
    HOST_END_CLASS,
    HOST_CLASS_COMPLETED,
    HOST_SEND_CHANNEL_MESSAGE,
    HOST_SEND_PEER_MESSAGE,
    HOST_DISPLAY_STREAM_OVERLAY_MESSAGE,
    HOST_MESSAGE_FROM_AUDIENCE,
    HOST_MESSAGE_FROM_AUDIENCE_MEMBER,
    HOST_TRAINEE_JOINED,
    HOST_TRAINEE_LEFT,
    HOST_UNLOCK_STREAM_ROOM,
} = HOST_EVENTS
const emptyObj = {}

export const useHandleAgoraHostEvents = reactiveStreamProps => {
    const { streamState, streamStateSetters, streamHelpers, handleUpdateClassStatus, handleUpdateStreamRecord } =
        reactiveStreamProps
    const { parseEventTriggerMessage, triggerEvent: triggerHostEvent } = streamHelpers
    const {
        tracks,
        classEnded,
        classComplete,
        trackState,
        streamEvent,
        streamEventQueue,
        streamRoomLocked,
        streamClient,
        messagesClient,
        messagesChannel,
        traineesAttendingClass,
        classStarted,
        lateTraineeIDs,
        logHostEvents,
        instructorClass,
        isLoggedInToRTM,
    } = streamState
    const {
        setStreamEnded,
        setTrackState,
        setStreamStatusMessage,
        setStreamOverlayMessage,
        setTraineesAttendingClass,
        setStreamRoomLocked,
        setLateTraineeIDs,
        setIsLoggedInToRTM,
    } = streamStateSetters

    const shouldLogEvents = __DEV__ && logHostEvents
    const handleHostEvents = async queuedEvent => {
        const { event: hostEvent, payload } = queuedEvent ? queuedEvent : streamEvent || emptyObj
        switch (hostEvent) {
            case HOST_UNLOCK_STREAM_ROOM:
                if (shouldLogEvents) console.log('HOST_UNLOCK_STREAM_ROOM')
                setStreamRoomLocked(false)
                await handleUpdateClassStatus({ Class: instructorClass, classStatus: IN_PROGRESS })
                break

            case HOST_MESSAGE_FROM_AUDIENCE: //fall through case
            case HOST_MESSAGE_FROM_AUDIENCE_MEMBER:
                if (shouldLogEvents) console.log('HOST_MESSAGE_FROM_AUDIENCE')
                const { event: hostEventTrigger, payload: hostEventPayload } = parseEventTriggerMessage(payload)
                triggerHostEvent({ event: hostEventTrigger, payload: hostEventPayload })
                break

            case HOST_TRAINEE_JOINED:
                if (shouldLogEvents) console.log('HOST_TRAINEE_JOINED')
                const traineeInfo = payload || emptyObj
                if (classStarted) {
                    setLateTraineeIDs([...lateTraineeIDs, traineeInfo?.uid])
                }
                const traineeAlreadyJoined = traineesAttendingClass.filter(trainee => trainee.uid == traineeInfo.uid)
                const shouldAddTrainee = traineeAlreadyJoined?.length === 0 || traineesAttendingClass?.length === 0
                if (shouldAddTrainee) {
                    setTraineesAttendingClass([...traineesAttendingClass, traineeInfo])
                }
                break

            case HOST_TRAINEE_LEFT:
                if (shouldLogEvents) console.log('HOST_TRAINEE_LEFT')
                const { uid } = payload
                const remainingTrainees = traineesAttendingClass.filter(trainee => trainee.uid != uid)
                if (remainingTrainees != traineesAttendingClass) {
                    setTraineesAttendingClass(remainingTrainees)
                }
                break

            case HOST_DISPLAY_STREAM_OVERLAY_MESSAGE:
                if (shouldLogEvents) console.log('HOST_DISPLAY_STREAM_OVERLAY_MESSAGE')
                const { overlayMessage } = payload
                setStreamOverlayMessage(overlayMessage)
                break

            case HOST_BE_RIGHT_BACK:
                if (shouldLogEvents) console.log('HOST_BE_RIGHT_BACK')
                await beRightBack({ tracks, trackState, setTrackState })
                setStreamStatusMessage('You have hidden the stream')
                break

            case HOST_SHOW_VIDEO: //Fall through case
            case HOST_HIDE_VIDEO:
                if (shouldLogEvents) console.log('HOST_TOGGLE_VIDEO')
                await toggleCamera({ tracks, trackState, setTrackState })
                setStreamStatusMessage(`Camera hidden: ${trackState.video}\nMicrophone muted: ${!trackState.audio}`)
                break

            case HOST_MUTE_AUDIO: //Fall through case
            case HOST_UNMUTE_AUDIO:
                if (shouldLogEvents) console.log('HOST_TOGGLE_AUDIO')
                await toggleMicrophone({ tracks, trackState, setTrackState })
                setStreamStatusMessage(`Camera hidden: ${!trackState.video}\nMicrophone muted: ${trackState.audio}`)
                break

            case HOST_SEND_CHANNEL_MESSAGE:
                if (shouldLogEvents) console.log('HOST_SEND_CHANNEL_MESSAGE')
                const { message: channelMessage } = payload
                await sendChannelMessage({ messagesChannel, text: channelMessage })
                break

            case HOST_SEND_PEER_MESSAGE:
                if (shouldLogEvents) console.log('HOST_SEND_PEER_MESSAGE')
                const { message: peerMessage, peerId } = payload
                await sendPeerToPeerMessage({ messagesClient, text: peerMessage, peerId })
                break

            case HOST_END_CLASS:
                if (shouldLogEvents) console.log('HOST_END_CLASS')
                await handleUpdateClassStatus({ Class: instructorClass, classStatus: __DEV__ ? PUBLISHED : COMPLETED })
                break

            case HOST_CLASS_COMPLETED:
                if (shouldLogEvents) console.log('HOST_CLASS_COMPLETED')
                await handleUpdateClassStatus({ Class: instructorClass, classStatus: __DEV__ ? PUBLISHED : COMPLETED })
                break

            case HOST_END_STREAM:
                if (shouldLogEvents) console.log('HOST_END_STREAM')
                const { wasStreamEndedWithListener } = payload
                setTrackState({ ...trackState, video: false, audio: false })
                setStreamEnded(true)
                setStreamStatusMessage('You have ended stream')
                await handleUpdateStreamRecord({ isHostInStreamRoom: false })
                const shouldUpdateClassStatus = (classStarted || !streamRoomLocked) && !(classEnded || classComplete)
                if (shouldUpdateClassStatus) {
                    await handleUpdateClassStatus({
                        Class: instructorClass,
                        classStatus: __DEV__ ? PUBLISHED : COMPLETED,
                    })
                }
                await endStream({
                    tracks,
                    messagesClient,
                    messagesChannel,
                    streamClient,
                    setIsLoggedInToRTM,
                    isLoggedInToRTM,
                    wasStreamEndedWithListener,
                })
                break

            default:
                break
        }

        return true
    }

    useEffect(() => {
        handleHostEvents()
    }, [streamEvent])

    useEffect(() => {
        streamEventQueue.forEach(async queuedEvent => await handleHostEvents(queuedEvent))
    }, [streamEventQueue])
}

export default useHandleAgoraHostEvents
