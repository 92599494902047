import React, { useState, useEffect, useMemo, useCallback } from 'react'
import _ from 'lodash'
import { Inter_500Medium } from '@expo-google-fonts/inter'
import { TouchableOpacity, View } from 'react-native'
import styled from 'styled-components/native'
import { configureFonts, DefaultTheme, Menu, Provider } from 'react-native-paper'
import { useNavigation } from '@react-navigation/native'
import { FontAwesome } from '@expo/vector-icons'
import * as screenNames from 'screenNames'
import { logout } from 'actions'
import colors from 'colors'
import { instructorApplicationForm } from 'apollo/cache'
import { initialInstructorApplicationForm } from 'initialFormStates'
import { TEXT_LINEAR_GRADIENT } from 'styles/typography'
import * as navigate from 'navigationActions'
import { USER_TYPES } from '@constants'
import { useApolloClient } from '@apollo/client'
const { INSTRUCTOR } = USER_TYPES
const emptyObj = {}
const LOCATION_LOGOUT = 'logout'

const noop = () => {}

const DropdownMenu = ({
    selectedItem,
    setSelectedItem,
    isInstructor = false,
    isUserInStream,
    menuVisible,
    setMenuVisible,
}) => {
    const client = useApolloClient()
    const navigation = useNavigation()
    const openMenu = () => setMenuVisible(true)
    const closeMenu = () => setMenuVisible(false)

    const fontConfig = {
        web: {
            regular: {
                fontFamily: Inter_500Medium,
                fontSize: 'medium',
            },
        },
    }

    const navigateToTake = useCallback(() => {
        instructorApplicationForm(initialInstructorApplicationForm)
        navigation.navigate(screenNames.INSTRUCTOR_APPLICATION.NAVIGATOR, {
            screen: screenNames.INSTRUCTOR_APPLICATION.START_TRAINER_APP,
        })
    }, [])

    const traineeHomeNav = navigate.getNavigationAction({
        navigation,
        navigationAction: navigate.navigateTraineeHomeAction,
    })

    const exploreNav = navigate.getNavigationAction({
        navigation,
        navigationAction: navigate.navigateTraineeExploreAction,
    })

    const favoriteNav = navigate.getNavigationAction({
        navigation,
        navigationAction: navigate.navigateTraineeFavoritesAction,
    })

    const profileNav = navigate.getNavigationAction({
        navigation,
        navigationAction: navigate.navigateTraineeProfileAction,
    })

    const traineeNavigationItems = [
        {
            title: 'Home',
            navigator: screenNames.TRAINEE_HOME_STACK_NAVIGATOR,
            navigationAction: traineeHomeNav,
        },
        {
            title: 'Explore',
            navigator: screenNames.EXPLORE_NAVIGATOR,
            navigationAction: exploreNav,
        },
        {
            title: 'Favorites',
            navigator: screenNames.FAVORITES_NAVIGATOR,
            navigationAction: favoriteNav,
        },
        {
            title: 'Profile',
            navigator: screenNames.PROFILE_NAVIGATOR,
            navigationAction: profileNav,
        },
        {
            title: 'Logout',
            screen: LOCATION_LOGOUT,
        },
        {
            title: 'Apply to be a creator',
            navigationAction: navigateToTake,
            isGradient: true,
        },
    ]

    const navigationItems = useMemo(
        () => (isInstructor ? _.initial(traineeNavigationItems) : traineeNavigationItems),
        [isInstructor],
    )

    useEffect(() => {
        const state = navigation.getState()
        const initialLocation = state?.routes?.[0]?.state?.routes?.[0]?.name
        const initialTitle = navigationItems?.filter(
            item => item.navigator === initialLocation || item.screen === initialLocation,
        )?.[0]?.title
        initialTitle && setSelectedItem(initialTitle)
    }, [navigation])

    const navigateTo = useCallback(
        async item => {
            const { title, screen, navigator, navigationAction } = item || emptyObj
            setSelectedItem(title)
            closeMenu()
            if (navigationAction) {
                navigationAction()
            } else if (navigator) {
                navigation.navigate(navigator, { screen })
            } else if (screen === LOCATION_LOGOUT) {
                navigation.navigate(screenNames.LANDING_PAGE)
                await logout()
                await client?.clearStore()
                client?.cache?.gc()
            } else {
                navigation.navigate(screen)
            }
        },
        [navigation],
    )

    const theme = {
        ...DefaultTheme,
        dark: false,
        fonts: configureFonts(fontConfig),
    }

    return (
        <Provider theme={theme}>
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                <Menu
                    visible={menuVisible}
                    onDismiss={closeMenu}
                    anchor={
                        <TouchableOpacity onPress={isUserInStream ? noop : openMenu}>
                            <FontAwesome
                                name={menuVisible ? 'chevron-up' : 'chevron-down'}
                                color={colors.dropDownGray}
                                size={20}
                                style={{ marginLeft: '10px' }}
                            ></FontAwesome>
                        </TouchableOpacity>
                    }
                    style={{ top: '55px', left: '-85px', width: '150px' }}
                    contentStyle={{
                        borderRadius: '24px',
                        minWidth: '180px',
                        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
                    }}
                >
                    <View style={{ zIndex: 10, paddingBottom: '15px' }}>
                        {navigationItems.map((item, index) => (
                            <MenuItem
                                key={index}
                                onPress={() => navigateTo(item)}
                                title={
                                    <MenuText selectedItem={selectedItem} item={item}>
                                        {item.title}
                                    </MenuText>
                                }
                                disabled={typeof item.disabled === 'undefined' ? false : item.disabled}
                            />
                        ))}
                    </View>
                </Menu>
            </View>
        </Provider>
    )
}

const MenuText = styled.Text`
    font-size: 14px;
    color: ${({ item, selectedItem }) => (item?.title === selectedItem ? colors.homebodyTurquoise : colors.black)};
    font-weight: ${({ selectedItem, item }) => (item?.title === selectedItem ? '900' : '500')};
    ${({ selectedItem, item }) => item?.title === selectedItem && 'font-weight: 900;'}
    ${({ item }) =>
        item?.isGradient &&
        TEXT_LINEAR_GRADIENT({
            direction: '90.74deg',
            colorStop1: `${colors.homebodyTurquoise} 3.23%`,
            colorStop2: `${colors.homebodyGreen} 70.45%`,
        })}
`

const MenuItem = styled(Menu.Item)`
    height: 25px;
    margin-top: 17px;
`

export default DropdownMenu
