import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { useRoute } from '@react-navigation/core'
import styled from 'styled-components'
import { ScreenContainer, ContentContainer } from 'commons/containers'
import { navigateClassDetailsFromCurrentStack } from 'navigationActions'
import { getClassesToBookByDateTime } from 'apollo/selectors'
import ClassesFlatList from 'commons/components/instructor/ClassesFlatList'
import { PAGINATED_LIMIT } from '@constants'
const emptyObj = {}
const emptyArr = []

export default function AllClasses() {
    const [refreshing, setRefreshing] = useState(false)
    const { params } = useRoute()
    const { onRefresh } = params || emptyObj
    const [classesToBook, setClassesToBook] = useState(emptyArr)
    const [classNextToken, setClassNextToken] = useState('')

    const {
        classesToBook: results,
        loading: classesLoading,
        refetch: refetchClasses,
        nextToken: initialToken,
        fetchMore: classFetchMore,
    } = getClassesToBookByDateTime({
        limit: PAGINATED_LIMIT,
        onCompleted: () => {
            if (classesToBook?.length === 0 && !classNextToken) {
                setClassNextToken(initialToken)
                setClassesToBook(results)
            }
        },
    })

    async function handleFetchMore() {
        if (classNextToken) {
            const fetchMoreResult = await classFetchMore({ variables: { nextToken: classNextToken } })
            const {
                data: { classByClassStatus: { nextToken: token = null, items: moreClasses = emptyArr } } = emptyObj,
            } = fetchMoreResult
            const allClasses = classesToBook?.length > 0 ? [...classesToBook, ...moreClasses] : [...moreClasses]
            setClassesToBook(allClasses)
            setClassNextToken(token)
        } else {
            __DEV__ && console.log('no nextToken')
        }
    }

    function handlePullToRefresh() {
        setRefreshing(true)
        refetchClasses()
        setTimeout(() => {
            setRefreshing(false)
        }, 1000)
    }

    return (
        <ScreenContainer>
            <AllClassContainer>
                <ClassesFlatList
                    style={{ height: '100%' }}
                    hidePicker
                    isFullPage
                    isExploreClass
                    hasRefreshControl
                    data={classesToBook}
                    classesLoading={classesLoading}
                    navigationAction={navigateClassDetailsFromCurrentStack}
                    onReachEnd={classNextToken && handleFetchMore}
                    onPullToRefresh={handlePullToRefresh}
                    refreshing={refreshing}
                />
            </AllClassContainer>
        </ScreenContainer>
    )
}

const AllClassContainer = styled(View)`
    flex: 1;
    width: ${({ theme }) => (theme.isDesktop ? MAX_FORM_WIDTH : '100%')};
    padding-horizontal: 5%;
`
