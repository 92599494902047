import React, { useState, useEffect } from 'react'
import { View, Image, Linking } from 'react-native'
import colors from 'styles/colors'
import styled from 'styled-components/native'
import { Paragraph } from 'styles/typography'
import { validateEmail } from 'helpers'
import { useDimensions } from 'dimensions'
import {
    ScreenContainer,
    ContentContainer,
    RowContainer,
    FieldRowContainer,
    FormBottomRowToColumnContainer,
} from 'commons/containers'
import { StyledTextInput, InputFieldRowSpacer } from 'commons/components/InputField'
import { TraineeWebBannerHeader } from 'commons/components/TraineeWebBannerHeader'
import { ContinueButton } from 'commons/components/ContinueButton'
import { FooterBar } from 'commons/components/FooterBar'
import { getCurrentUser } from 'apollo/selectors'
import { isWeb } from '@constants'
const emptyObj = {}

const HOMEBODY_LOGO = require('assets/logo.png')

export default function SupportRequest() {
    const { isMobile } = useDimensions()
    const { profile, email: user_email, phoneNumber: user_phone } = getCurrentUser() || emptyObj
    const { firstName: first_name, lastName: last_name } = profile || emptyObj
    const [firstName, setFirstName] = useState(first_name)
    const [firstNameError, setFirstNameError] = useState({ showError: false, errorMessage: '' })
    const [lastName, setLastName] = useState(last_name)
    const [lastNameError, setLastNameError] = useState({ showError: false, errorMessage: '' })
    const [email, setEmail] = useState(user_email)
    const [emailError, setEmailError] = useState({ showError: false, errorMessage: '' })
    const [message, setMessage] = useState('')
    const [messageError, setMessageError] = useState({ showError: false, errorMessage: '' })
    const [isMessageSent, setIsMessageSent] = useState(false)

    const clearFirstNameError = () => setFirstNameError({ showError: false, errorMessage: '' })
    const clearLastNameError = () => setLastNameError({ showError: false, errorMessage: '' })
    const clearEmailError = () => setEmailError({ showError: false, errorMessage: '' })
    const clearMessageError = () => setMessageError({ showError: false, errorMessage: '' })

    const sendEmail = () => {
        console.log('firstName, lastName, email, message: ', firstName, lastName, email, message)
        !firstName && setFirstNameError({ showError: true, errorMessage: 'First name required' })
        !lastName && setLastNameError({ showError: true, errorMessage: 'Last name required' })
        !email && setEmailError({ showError: true, errorMessage: 'Email is required' })
        !validateEmail(email) && setEmailError({ showError: true, errorMessage: 'Please enter valid email' })
        !message && setMessageError({ showError: true, errorMessage: 'Message is required' })

        if (firstName && lastName && email && message) {
            Linking?.openURL(`mailto:support@homebodylivefitness.com?subject=SupportRequest&body=${message}`)
            setIsMessageSent(true)
        }
    }

    return (
        <ScreenContainer>
            {isWeb && <TraineeWebBannerHeader title="Support Request" />}
            <ContentContainer isFluid={isWeb} style={isWeb ? { alignItems: 'center' } : emptyObj}>
                <TraineeNativeHeaderContainer>
                    <HomebodyLogo source={HOMEBODY_LOGO} />
                    <Paragraph isSubtext style={{ paddingTop: 20 }}>
                        support@homebodylivefitness.com
                    </Paragraph>
                </TraineeNativeHeaderContainer>
                <FieldRowContainer>
                    <StyledTextInput
                        fieldTitle="First Name"
                        defaultValue={first_name ?? ''}
                        onChangeText={setFirstName}
                        errorMessage={firstNameError?.showError ? firstNameError?.errorMessage : ''}
                        onFocus={clearFirstNameError}
                    />
                    <InputFieldRowSpacer />
                    <StyledTextInput
                        fieldTitle="Last Name"
                        defaultValue={last_name ?? ''}
                        onChangeText={setLastName}
                        errorMessage={lastNameError?.showError ? lastNameError?.errorMessage : ''}
                        onFocus={clearLastNameError}
                    />
                </FieldRowContainer>
                <FieldRowContainer>
                    <StyledTextInput
                        fieldTitle="Email"
                        defaultValue={user_email ?? ''}
                        onChangeText={setEmail}
                        errorMessage={emailError?.showError ? emailError?.errorMessage : ''}
                        onFocus={clearEmailError}
                    />
                </FieldRowContainer>
                <FieldRowContainer>
                    <StyledTextInput
                        style={{ height: 150, paddingTop: 15 }}
                        multiline
                        placeholder="please start typing your message here..."
                        onChangeText={setMessage}
                        errorMessage={messageError?.showError ? messageError?.errorMessage : ''}
                        onFocus={clearMessageError}
                    />
                </FieldRowContainer>
                <ButtonContainer isCentered>
                    <SmallContinueButton nonHover text="Submit" color={colors.blueContinue} onPress={sendEmail} />
                </ButtonContainer>
            </ContentContainer>
            {isWeb && <FooterBar />}
        </ScreenContainer>
    )
}

const ButtonContainer = styled(FormBottomRowToColumnContainer)`
    padding-horizontal: 0px;
    padding-top: ${({ theme }) => (theme.isMobile ? '10px' : '7%')};
    padding-bottom: 15px;
`
const SmallContinueButton = styled(ContinueButton)`
    width: ${({ theme }) => (theme.isMobile ? '100%' : '320px')};
    height: 56px;
    font-size: 13px;
`
const TraineeNativeHeaderContainer = styled(View)`
    max-width: 900px;
    flex-direction: column;
    align-self: center;
    width: 100%;
    padding-top: ${({ theme }) => (theme.isMobile ? '20%' : '5%')};
`
const HomebodyLogo = styled(Image)`
    align-self: flex-start;
    max-width: 214px;
    width: 100%;
    height: 25px;
    right: 48px;
`
