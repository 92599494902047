import React, { useEffect } from 'react'
import { LinearGradient } from 'expo-linear-gradient'
import styled from 'styled-components/native'
import colors from 'styles/colors'
import { SubHeading } from 'styles/typography'
import { ImageBackground } from 'react-native'

export const TraineeWebBannerHeader = ({
    title,
    gradStart = colors.homebodyTurquoise,
    gradEnd = colors.homebodyGreen,
    image,
    height = 339,
}) => {
    const gradientColors = [gradStart, gradEnd]
    const gradientStart = { x: 0.35, y: 0 }
    const gradientEnd = { x: 0.65, y: 0 }

    if (image) {
        return (
            <BannerImage source={image} resizeMode="cover" height={height}>
                {title && <BannerTitle isMostBold>{title}</BannerTitle>}
            </BannerImage>
        )
    }

    return (
        <BannerContainer height={height} start={gradientStart} end={gradientEnd} colors={gradientColors}>
            {title && <BannerTitle isMostBold>{title}</BannerTitle>}
        </BannerContainer>
    )
}

const BannerContainer = styled(LinearGradient)`
    ${props => props.height && `height: ${props.height}px;`}
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => props.image && `background-image: ${props.image};`}
`

const BannerImage = styled(ImageBackground)`
    ${props => props.height && `height: ${props.height};`};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const BannerTitle = styled(SubHeading)`
    font-size: 70.52px;
    color: ${colors.white};
`
