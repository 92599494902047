import React, { useState } from 'react'
import { View, TouchableOpacity, FlatList, ActivityIndicator } from 'react-native'
import colors from 'colors'
import styled from 'styled-components/native'
import { isWeb } from '@constants'
import { SubHeading, Caption, Heading } from 'typography'
import TraineeAvatar from 'components/TraineeAvatar'
import VectorIcon from 'components/VectorIcon'
import { RowContainer } from 'commons/containers'
import ViewAllHeading from './trainee/ViewAllHeading'
import { getUserByID } from 'apollo/selectors'
import { Headline } from 'typography'
import { useDimensions } from 'dimensions'
import { truncateText } from 'helpers'
const noop = () => {}

const ReviewCard = ({ review, cardWidth, onPress = noop }) => {
    if (!review?.review) return null
    const userID = review.userID
    const [user, setUser] = useState(userID)
    const userFromID = getUserByID({
        userID: userID,
        onCompleted: () => {
            setUser(userFromID)
        },
    })
    const { avatar, profile } = user
    const { firstName, lastName } = profile || {}
    return (
        <ReviewCardContainer cardWidth={cardWidth}>
            <ReviewInfoContainer>
                <UserAvatar userInfo={{ firstName, lastName }} size={isWeb ? 42 : 32} avatarImageKey={avatar?.key} />
                <ReviewDetailsContainer>
                    <RatingContainer>
                        <VectorIcon family="FontAwesome" name="star" color={colors.homebodyGreen} size={16} />
                        <ReviewText isBold isSubtext paddingLeft={6}>
                            {review.rating}
                        </ReviewText>
                    </RatingContainer>
                    <ReviewText isSubtext>
                        {review.review === '' ? ' ' : truncateText({ text: review.review })}
                    </ReviewText>
                </ReviewDetailsContainer>
            </ReviewInfoContainer>
        </ReviewCardContainer>
    )
}
const EmptyReviewCard = ({ onPress = noop, reviewsLoading }) => {
    if (reviewsLoading) {
        return (
            <ReviewCardContainer onPress={onPress}>
                <ReviewInfoContainer>
                    <LoadingReviewContainer>
                        <ActivityIndicator size={32} animating={reviewsLoading} color={colors.homebodyTurquoise} />
                    </LoadingReviewContainer>
                    <RatingContainer>
                        <ReviewText isSubtext>Reviews loading...</ReviewText>
                    </RatingContainer>
                </ReviewInfoContainer>
            </ReviewCardContainer>
        )
    } else {
        return <ReviewInfoContainer></ReviewInfoContainer>
    }
}

const ReviewAvatarCard = ({ review, onPress = noop, avatarSize }) => {
    if (!review?.rating) return null
    const userID = review.userID
    const [user, setUser] = useState(userID)
    const userFromID = getUserByID({
        userID: userID,
        onCompleted: () => {
            setUser(userFromID)
        },
    })
    const { avatar, profile } = user
    const { firstName, lastName } = profile || {}

    const handleOnPress = () => onPress({ instructor, instructorID })
    return (
        <AvatarCardContainer onPress={handleOnPress}>
            <UserAvatarContainer avatarSize={avatarSize}>
                <UserAvatar
                    isAvatar
                    style={{ alignItems: 'center' }}
                    userInfo={{ firstName, lastName }}
                    size={avatarSize}
                    avatarImageKey={avatar?.key}
                />
            </UserAvatarContainer>

            <Caption paddingTop={16} textAlign="center">{`${firstName}\n${lastName}`}</Caption>
            <RowContainer style={{ paddingTop: 9, paddingBottom: 42 }}>
                {[1, 2, 3, 4, 5].map(index => {
                    return (
                        <VectorIcon
                            family="FontAwesome"
                            name="star"
                            color={review.rating >= index ? colors.homebodyTurquoise : colors.grayContinue}
                            size={14}
                            key={index}
                            style={{ marginRight: 2 }}
                        />
                    )
                })}
            </RowContainer>
        </AvatarCardContainer>
    )
}

const EmptyReviewAvatarCard = ({ onPress = noop, reviewsLoading, avatarSize }) => {
    return (
        <LoadingAvatarCardContainer avatarSize={avatarSize}>
            <ActivityIndicator size={avatarSize} animating={reviewsLoading} color={colors.homebodyTurquoise} />
        </LoadingAvatarCardContainer>
    )
}

const FlatListHeading = ({ headingText, onHeadingPress, isSmallHeading, isAvatar }) => {
    if (isAvatar) {
        return (
            <Headline paddingLeft={32} paddingBottom={50} isBold>
                {headingText}
            </Headline>
        )
    }
    if (isSmallHeading) {
        return (
            <SmallHeadingText isMostBold paddingBottom={20}>
                {headingText}
            </SmallHeadingText>
        )
    } else {
        return <ViewAllHeading headingText={headingText} onHeadingPress={onHeadingPress} />
    }
}

export default function ReviewFlatList({
    style,
    flatListStyle,
    data,
    onCardPress = noop,
    headingText = '',
    onHeadingPress,
    reviewsLoading,
    avatarSize = 62,
    horizontal = false,
    isAvatar = false,
    isSmallHeading = false,
}) {
    const { windowDimensions } = useDimensions()
    const { windowWidth } = windowDimensions
    const cardWidth = isWeb ? '250px' : `${Math.floor(windowWidth * 0.45)}px`
    const ListEmptyComponent = () => {
        if (isAvatar) {
            return (
                <>
                    <EmptyReviewAvatarCard
                        onPress={onHeadingPress}
                        reviewsLoading={reviewsLoading}
                        avatarSize={avatarSize}
                    />
                </>
            )
        } else {
            return <EmptyReviewCard onPress={onHeadingPress} reviewsLoading={reviewsLoading} />
        }
    }

    const renderItem = ({ item }) => {
        if (isAvatar) {
            return <ReviewAvatarCard review={item} onPress={onCardPress} avatarSize={avatarSize} />
        } else {
            return <ReviewCard cardWidth={cardWidth} review={item} onPress={onCardPress} />
        }
    }

    return (
        <ReviewFlatlistContainer style={style} isAvatar={isAvatar} horizontal={horizontal}>
            {!!headingText && (
                <FlatListHeading
                    headingText={headingText}
                    onHeadingPress={onHeadingPress}
                    isSmallHeading={isSmallHeading}
                    isAvatar={isAvatar}
                />
            )}
            <ReviewList
                isAvatar={isAvatar}
                horizontal={horizontal}
                style={flatListStyle}
                data={data}
                keyExtractor={item => `${item.id}${item._version}`}
                renderItem={renderItem}
                ListEmptyComponent={ListEmptyComponent}
            />
        </ReviewFlatlistContainer>
    )
}

const SmallHeadingText = styled(Heading)``

const ReviewFlatlistContainer = styled(View)`
    width: 100%;
    border-radius: ${props => (props.horizontal ? '24px' : '0px')};
    box-shadow: ${props => (props.horizontal && props.isAvatar ? `0px 14px 14px ${colors.neutralGray}` : 'none')};
    padding-bottom: ${props => (props.theme.isMobile ? '25px' : '0px')};
`
const ReviewInfoContainer = styled(View)`
    height: 100%;
    width: 75%;
    flex-direction: row;
    align-items: flex-start;
`
const ReviewDetailsContainer = styled(View)`
    flex-direction: column;
    align-self: center;
    align-items: flex-start;
`
const RatingContainer = styled(View)`
    flex-direction: row;
    align-items: center;
`

const UserAvatarContainer = styled(View)`
    width: ${props => props.avatarSize + 10}px;
    height: ${props => props.avatarSize + 10}px;
    border-radius: ${props => (props.avatarSize + 10) / 2}px;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-color: ${colors.favoriteBlue};
`

const UserAvatar = styled(TraineeAvatar)`
    align-self: center;
    padding-right: ${props => (props.isAvatar ? '0px' : '18px')};
`
const ReviewText = styled(SubHeading)``

const ReviewList = styled(FlatList)`
    padding-left: ${props => (props.isAvatar ? '32px' : '0px')};
    padding-bottom: 3%;
`
const ReviewCardContainer = styled(View)`
    width: ${props => `${props.cardWidth}`};
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-horizontal: 10px;
`
const LoadingReviewContainer = styled(View)`
    flex-direction: column;
    justify-content: center;
    align-self: center;
    height: 100%;
    width: 55px;
    padding-horizontal: 25px;
`

const AvatarCardContainer = styled(View)`
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;
    align-items: center;
    margin-right: 20px;
`

const LoadingAvatarCardContainer = styled(View)`
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;
    height: ${props => props.avatarSize}px;
    width: 100px;
    margin-right: 20px;
`
