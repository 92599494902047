import React from 'react'
import { StandardContainer } from 'commons/containers'
import termsOfService from 'data/termsOfService'
import MarkdownContent from '../privacy/MarkdownContent'
import { FooterBar } from 'components/FooterBar'

const TermsOfServicePage: React.FC = () => {
    return (
        <StandardContainer>
            <MarkdownContent>{termsOfService}</MarkdownContent>
            <FooterBar />
        </StandardContainer>
    )
}

export default TermsOfServicePage
