// 0, 74, 117
export default {
    black: '#111111',
    white: '#FFFFFF',
    grayContinue: '#B3BFD2',
    blueContinue: '#0CE2FF',
    blackContinue: '#000000',
    blueHover: '#0CE2FF',
    googleBlueContinue: '#3F8CFF',
    inputFieldGray: '#F2F4F7',
    subTextGray: '#8D99B2',
    homebodyTurquoise: '#0CE2FF',
    homebodyGreen: '#5AC973',
    homeBodyGreyBg: '#F6F6F6',
    pressKitGreyText: '#7F7F7F',
    deactiveLightmode: '#B2B3BD',
    primary: '#00D1FF',
    darkGrey: '#808191',
    danger: '#EB5757',
    pressKitGreyText: '#7F7F7F',
    unpublishedRed: '#EB5757',
    neutralGray: '#F0F3F6',
    dividingGray: '#AAB2BA',
    bookedBlue: '#0049C6',
    starGray: '#C4C4C4',
    progressGray: '#D6D6D6',
    favoriteBlue: '#004a75',
    searchBarGray: '#F3F5F7',
    neutralGray: 'rgba(228, 228, 228, 0.5)',
    blackTextCalendar: '#22242A',
    greyTextCalendar: '#AAB2BA',
    greyBgCalendar: 'rgba(45, 78, 130, 0.36);',
    cancelClassRed: '#FF0C0C',
    cancelledClassRed: '#FF380C',
    dropDownGray: '#919399',
    faintYellow: '#F4F4F4',
    neutralGray50: '#E4E4E480',
    upNextStreamGray: '#F9F9F9',
}
