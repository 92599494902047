import React from 'react'

import { View, TouchableOpacity, Linking, ViewStyle, StyleProp } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import { SOCIAL_LINKS } from '../../constants/appInfo'

import styled from 'styled-components/native'

interface Props {
  style?: StyleProp<ViewStyle>
}

const SocialIcons: React.FC<Props> = ({ style }) => (
  <SocialIconsRow style={style} >
    <TouchableOpacity onPress={() => Linking.openURL(SOCIAL_LINKS.fb)} >
      <Ionicons
        name={'logo-facebook'}
        size={24}
      />
    </TouchableOpacity>
    <TouchableOpacity onPress={() => Linking.openURL(SOCIAL_LINKS.instagramm)}>
      <Ionicons
        name={'logo-instagram'}
        size={24}
      />
    </TouchableOpacity>
    <TouchableOpacity onPress={() => Linking.openURL(SOCIAL_LINKS.twitter)}>
      <Ionicons
        name={'logo-twitter'}
        size={24}
      />
    </TouchableOpacity>
  </SocialIconsRow>
)

const SocialIconsRow = styled(View)`
  margin-bottom: ${props => (props.theme.isMobile || props.theme.isTablet ? '40px' : '0')};
  flex-direction: row;
  justify-content: space-between;
  width: 110;
`


export default SocialIcons