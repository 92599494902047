import React, { useState, useEffect, useCallback } from 'react'
import colors from 'colors'
import styled from 'styled-components/native'
import { useDimensions } from 'dimensions'
import { View, TouchableOpacity } from 'react-native'
import { useRoute } from '@react-navigation/native'
import { isWeb } from '@constants'
import useReactiveStream from 'hooks/useReactiveStream'
import useHandleClassEvents from 'hooks/useHandleClassEvents'
import ClassInfoOverlay from 'components/streaming/InstructorStreamOverlay'
import StreamMessageOverlay from 'components/streaming/StreamMessageOverlay'
import IntervalCountdownTimer from 'components/streaming/IntervalCountdownTimer'
import AgoraStreamPlayer from 'components/streaming/AgoraStreamPlayer'
import TraineeWaitingRoom from 'components/streaming/TraineeWaitingRoom'
import useAttendAgoraStream from 'hooks/useAttendAgoraStream'
import useHandleAgoraAudienceEvents from 'hooks/useHandleAgoraAudienceEvents'
import StreamRoomButton from 'components/streaming/StreamRoomButton'
import VectorIcon from '../../commons/components/VectorIcon'
import { BOTTOM_TAB_BAR_HEIGHT } from '../../constants/constants'

export const TraineeStreamRoom = () => {
    const { isMobile } = useDimensions()
    const [showTraineeControls, setShowTraineeControls] = useState(false)
    const {
        params: { classID },
    } = useRoute() || {}
    const { windowDimensions } = useDimensions()
    const { windowHeight, windowWidth } = windowDimensions
    const reactiveStreamProps = useReactiveStream({ classID })
    const { streamState, streamHelpers, audienceEventEmitters } = reactiveStreamProps
    const { leaveStream } = audienceEventEmitters
    const { isFinalInterval } = streamHelpers
    const {
        isHost,
        streamEnded,
        classStarted,
        streamRoomLocked,
        streamOverlayMessage: { overlayMessage },
    } = streamState

    const isLargeInterface = !isMobile && !isHost
    const controlsIconProps = {
        iconName: showTraineeControls ? 'up' : 'down',
        iconSize: isLargeInterface ? 32 : 16,
    }
    const { iconName, iconSize } = controlsIconProps

    const toggleTraineeControls = () => setShowTraineeControls(!showTraineeControls)
    useAttendAgoraStream(reactiveStreamProps)
    useHandleAgoraAudienceEvents(reactiveStreamProps)
    useHandleClassEvents(reactiveStreamProps)

    const awaitLeaveStream = async () => {
        await leaveStream()
    }

    const leaveStreamOnBrowserBackOrClose = useCallback(async event => {
        await leaveStream()
    }, [])

    useEffect(() => {
        if (!streamEnded) {
            window.addEventListener('popstate', leaveStreamOnBrowserBackOrClose)
            window.addEventListener('beforeunload', leaveStreamOnBrowserBackOrClose)
        } else {
            window.removeEventListener('popstate', leaveStreamOnBrowserBackOrClose)
            window.removeEventListener('beforeunload', leaveStreamOnBrowserBackOrClose)
        }
    }, [streamEnded])

    if (streamRoomLocked) {
        return <TraineeWaitingRoom reactiveStreamProps={reactiveStreamProps} />
    } else {
        return (
            <TraineeStreamRoomContainer windowHeight={windowHeight - BOTTOM_TAB_BAR_HEIGHT}>
                <StreamContainer>
                    <AgoraStreamPlayer
                        reactiveStreamProps={reactiveStreamProps}
                        videoPlayerStyle={{ height: windowHeight - BOTTOM_TAB_BAR_HEIGHT, width: '100%' }} //AgoraVideoPlayer requires a pixel value, percentages are incompatible
                    />
                    {classStarted && (
                        <ClassInfoOverlay
                            isTraineeOverlay={true}
                            streamPlayerHeight={windowHeight}
                            reactiveStreamProps={reactiveStreamProps}
                            isLargeInterface={isLargeInterface}
                        />
                    )}
                    {!!overlayMessage && <ClassMessageOverlay reactiveStreamProps={reactiveStreamProps} />}
                    {showTraineeControls && (
                        <>
                            <TraineeControlsOverlay>
                                {!isFinalInterval && (
                                    <NextIntervalInfo
                                        isTraineeControls
                                        reactiveStreamProps={reactiveStreamProps}
                                        isLargeInterface={isLargeInterface}
                                    />
                                )}
                                <TraineeStreamRoomControls
                                    onPress={awaitLeaveStream}
                                    buttonColor={colors.homebodyTurquoise}
                                    buttonText="leave class"
                                />
                            </TraineeControlsOverlay>
                        </>
                    )}
                    <OpenControlsContainer
                        onPress={toggleTraineeControls}
                        isLargeInterface={isLargeInterface}
                        showTraineeControls={showTraineeControls}
                    >
                        <VectorIcon family="AntDesign" name={iconName} size={iconSize} color="white" />
                    </OpenControlsContainer>
                </StreamContainer>
            </TraineeStreamRoomContainer>
        )
    }
}

const NextIntervalInfo = styled(IntervalCountdownTimer)`
    align-self: center;
`

const OpenControlsContainer = styled(TouchableOpacity)`
    position: absolute;
    height: ${props => (props.isLargeInterface ? 56 : 28)}px;
    width: ${props => (props.isLargeInterface ? 56 : 28)}px;
    border-radius: ${props => (props.isLargeInterface ? 28 : 14)}px;
    background-color: ${props => (props.showTraineeControls ? 'transparent' : 'rgba(0, 0, 0, 0.4)')};
    justify-content: center;
    align-items: center;
    top: 30px;
    right: 30px;
`
const TraineeControlsOverlay = styled(View)`
    position: absolute;
    flex-direction: column;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 25%;
    ${isWeb && `background-color: rgba(0, 0, 0, 0.4);`}
`
const TraineeStreamRoomControls = styled(StreamRoomButton)`
    position: absolute;
    bottom: 10%;
`
const ClassMessageOverlay = styled(StreamMessageOverlay)``

const TraineeStreamRoomContainer = styled(View)`
    flex-direction: row;
    height: ${props => props.windowHeight}px;
    width: 100%;
`
const StreamContainer = styled(View)`
    flex-direction: column;
    flex: 1;
`
export default TraineeStreamRoom
