import { useNavigation, getFocusedRouteNameFromRoute } from '@react-navigation/native'
import { View } from 'react-native'
import { createStackNavigator } from '@react-navigation/stack'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import React, { useMemo, useState, useEffect } from 'react'
import VectorIcon from 'components/VectorIcon'
import { DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer'
import WebHeader from 'commons/components/WebHeader'
import TraineeNativeHeader from 'components/trainee/TraineeNativeHeader'
import NavigationIconLabel from 'commons/components/NavigationIconLabel'
import { useDimensions } from 'dimensions'
import colors from 'colors'
import { INSTRUCTOR_DRAWER_WIDTH } from '@constants'
//------------------ Navigators ------------------
import * as screenNames from 'screenNames'
import ClassDetails from 'screens/common/ClassDetails'
import UserProfile from 'screens/common/UserProfile'
import ExploreScreen from 'screens/trainee/ExploreScreen'
import FavoritesScreen from 'screens/trainee/FavoritesScreen'
import TraineeHomeScreen from 'screens/trainee/TraineeHomeScreen'
import TraineeStreamRoom from 'screens/trainee/TraineeStreamRoom'
import TraineeProfile from 'screens/trainee/TraineeProfile'
import TraineeAccount from 'screens/trainee/TraineeAccount'
import TraineeSettings from 'screens/trainee/TraineeSettings'
import ChangePassword from 'screens/trainee/ChangePassword'
import TraineeUpcomingClasses from 'screens/trainee/TraineeUpcomingClasses'
import TraineeNotifications from 'screens/trainee/TraineeNotifications'
import TraineeNotificationDetails from 'screens/trainee/TraineeNotificationDetails'
import TraineeLeaveReview from 'screens/trainee/TraineeLeaveReview'
import MyCreditsForm from 'screens/payments/MyCreditsForm'
import CreditPackageForm from 'screens/payments/CreditPackageForm'
import PurchaseForm from 'screens/payments/PurchaseForm'
import BookClass from 'screens/common/BookClass'
import TraineeWallet from 'screens/trainee/TraineeWallet'
import CheckoutPage from 'screens/payments/CheckoutForm'
import TransactionHistory from 'screens/trainee/TransactionHistory'
import TermsAndConditions from 'screens/trainee/TermsAndConditions'
import PrivacyPolicy from 'screens/trainee/PrivacyPolicy'
import ClassSchedule from 'screens/trainee/ClassSchedule'
import AllClasses from 'screens/trainee/AllClasses'
import DeleteAccount from 'screens/trainee/DeleteAccount'
import CancelSubscription from 'screens/trainee/CancelSubscription'
import { HOME_ICON, DUMBBELL_ICON } from 'constants/svgIcons'
import * as Notifications from 'expo-notifications'
import SvgIcon from 'commons/SvgIcon'
import styled from 'styled-components/native'
import SupportRequest from 'screens/trainee/SupportRequest'

const labelStyle = {
    color: '#8D99B2',
    fontSize: 18,
}
const drawerIconStyle = { zIndex: 10, paddingLeft: 10 }
const tabIconStyle = {}
const TAB_LABEL = 'tabBarLabel'
const TAB_ICON = 'tabBarIcon'
const DRAWER_LABEL = 'drawerLabel'
const DRAWER_ICON = 'drawerIcon'

const Drawer = createDrawerNavigator()
const Tab = createBottomTabNavigator()
const Stack = createStackNavigator()

const TraineeHomeScreens = [
    {
        name: screenNames.TRAINEE_HOME_SCREEN,
        component: TraineeHomeScreen,
    },
    {
        name: screenNames.CLASS_DETAILS,
        component: ClassDetails,
    },
    {
        name: screenNames.BOOK_CLASS,
        component: BookClass,
    },
    {
        name: screenNames.CREDIT_PACKAGES,
        component: CreditPackageForm,
    },
    {
        name: screenNames.WALLET,
        component: TraineeWallet,
    },
    {
        name: screenNames.PURCHASE_CREDIT,
        component: PurchaseForm,
    },
    {
        name: screenNames.MY_CREDITS,
        component: MyCreditsForm,
    },
    {
        name: screenNames.TRAINEE_STREAM_ROOM,
        component: TraineeStreamRoom,
        options: {
            gestureEnabled: false,
        },
    },
    {
        name: screenNames.USER_PROFILE,
        component: UserProfile,
    },
    {
        name: screenNames.CLASS_SCHEDULE,
        component: ClassSchedule,
    },
    {
        name: screenNames.TRAINEE_LEAVE_REVIEW,
        component: TraineeLeaveReview,
    },
]

export const TraineeHomeStackNav = () => (
    <Stack.Navigator
        screenOptions={{
            title: 'Home',
            headerShown: false,
        }}
        initialRouteName={screenNames.TRAINEE_HOME_SCREEN}
    >
        {TraineeHomeScreens.map(({ name, component, options = {} }) => (
            <Stack.Screen key={name} name={name} component={component} options={options} />
        ))}
    </Stack.Navigator>
)

const ExploreScreens = [
    {
        name: screenNames.EXPLORE,
        component: ExploreScreen,
    },
    {
        name: screenNames.ALL_CLASSES,
        component: AllClasses,
    },
    {
        name: screenNames.USER_PROFILE,
        component: UserProfile,
    },
    {
        name: screenNames.CLASS_SCHEDULE,
        component: ClassSchedule,
    },
    {
        name: screenNames.CLASS_DETAILS,
        component: ClassDetails,
    },
    {
        name: screenNames.BOOK_CLASS,
        component: BookClass,
    },
    {
        name: screenNames.ADD_CARD,
        component: CheckoutPage,
    },
    {
        name: screenNames.CREDIT_PACKAGES,
        component: CreditPackageForm,
    },
    {
        name: screenNames.MY_CREDITS,
        component: MyCreditsForm,
    },
    {
        name: screenNames.WALLET,
        component: TraineeWallet,
    },
    {
        name: screenNames.PURCHASE_CREDIT,
        component: PurchaseForm,
    },
]

export const ExploreStackNav = () => (
    <Stack.Navigator
        screenOptions={{
            title: 'Explore',
            headerShown: false,
        }}
        initialRouteName={screenNames.EXPLORE}
    >
        {ExploreScreens.map(({ name, component, options = {} }) => (
            <Stack.Screen key={name} name={name} component={component} options={options} />
        ))}
    </Stack.Navigator>
)

const FavoritesScreens = [
    {
        name: screenNames.FAVORITES,
        component: FavoritesScreen,
    },
    {
        name: screenNames.USER_PROFILE,
        component: UserProfile,
    },
    {
        name: screenNames.CLASS_SCHEDULE,
        component: ClassSchedule,
    },
    {
        name: screenNames.CLASS_DETAILS,
        component: ClassDetails,
    },
]

export const FavoritesStackNav = () => (
    <Stack.Navigator
        screenOptions={{
            title: 'Favorites',
            headerShown: false,
        }}
        initialRouteName={screenNames.FAVORITES}
    >
        {FavoritesScreens.map(({ name, component, options = {} }) => (
            <Stack.Screen key={name} name={name} component={component} options={options} />
        ))}
    </Stack.Navigator>
)

const ProfileScreens = [
    {
        name: screenNames.TRAINEE_ACCOUNT,
        component: TraineeAccount,
    },
    {
        name: screenNames.TRAINEE_PROFILE,
        component: TraineeProfile,
    },
    {
        name: screenNames.TRAINEE_UPCOMING_CLASSES,
        component: TraineeUpcomingClasses,
    },
    {
        name: screenNames.TRAINEE_SETTINGS,
        component: TraineeSettings,
    },
    {
        name: screenNames.TRAINEE_SUPPORT,
        component: SupportRequest,
    },
    {
        name: screenNames.CHANGE_PASSWORD,
        component: ChangePassword,
    },
    {
        name: screenNames.TRANSACTION_HISTORY,
        component: TransactionHistory,
    },
    {
        name: screenNames.WALLET,
        component: TraineeWallet,
    },
    {
        name: screenNames.ADD_CARD,
        component: CheckoutPage,
    },
    {
        name: screenNames.MY_CREDITS,
        component: MyCreditsForm,
    },
    {
        name: screenNames.CREDIT_PACKAGES,
        component: CreditPackageForm,
    },
    {
        name: screenNames.PURCHASE_CREDIT,
        component: PurchaseForm,
    },
    {
        name: screenNames.TRAINEE_NOTIFICATIONS,
        component: TraineeNotifications,
    },
    {
        name: screenNames.TRAINEE_NOTIFICATION_DETAILS,
        component: TraineeNotificationDetails,
    },
    {
        name: screenNames.CLASS_DETAILS,
        component: ClassDetails,
    },
    {
        name: screenNames.BOOK_CLASS,
        component: BookClass,
    },
    {
        name: screenNames.USER_PROFILE,
        component: UserProfile,
    },
    {
        name: screenNames.CLASS_SCHEDULE,
        component: ClassSchedule,
    },
    {
        name: screenNames.TRAINEE_LEAVE_REVIEW,
        component: TraineeLeaveReview,
    },
    {
        name: screenNames.TERMS_AND_CONDITIONS,
        component: TermsAndConditions,
    },
    {
        name: screenNames.PRIVACY_POLICY,
        component: PrivacyPolicy,
    },
    {
        name: screenNames.DELETE_ACCOUNT,
        component: DeleteAccount,
    },
    {
        name: screenNames.CANCEL_SUBSCRIPTION,
        component: CancelSubscription,
    },
]

export const ProfileStackNav = () => (
    <Stack.Navigator
        initialRouteName={__DEV__ ? screenNames.TRAINEE_ACCOUNT : screenNames.TRAINEE_ACCOUNT}
        screenOptions={{
            title: 'My Profile',
            headerShown: false,
        }}
    >
        {ProfileScreens.map(({ name, component, options = {} }) => (
            <Stack.Screen key={name} name={name} component={component} options={options} />
        ))}
    </Stack.Navigator>
)

const TraineeWebDrawer = props => {
    return (
        <DrawerContentScrollView {...props}>
            <View style={{ width: '100%', height: 35 }} />
            <DrawerItemList {...props} labelStyle={labelStyle} />
        </DrawerContentScrollView>
    )
}
const drawerScreenOptions = ({ isMobile, isDesktop, navigation }) => ({
    drawerType: __DEV__ ? 'front' : 'front',
    defaultStatus: isMobile ? 'closed' : 'closed',
    drawerPosition: 'left',
    drawerStyle: {
        width: INSTRUCTOR_DRAWER_WIDTH,
    },
    drawerItemStyle: {
        alignContent: 'center',
        height: 56,
        justifyContent: 'center',
        borderRadius: 12,
    },
    drawerLabelStyle: {
        height: 56,
        paddingHorizontal: 20,
    },
    tabBarStyle: {
        height: 70,
        paddingBottom: 15,
    },
})

function getTabBarStyle(route) {
    const routeName = getFocusedRouteNameFromRoute(route) ?? ''
    const tabBarStyle =
        routeName == screenNames.TRAINEE_STREAM_ROOM ? { display: 'none' } : { height: 70, paddingBottom: 15 }
    return tabBarStyle
}

function getNativeHeader({ navigation, route }) {
    const focusedRouteName = getFocusedRouteNameFromRoute(route)
    return <TraineeNativeHeader navigation={navigation} routeName={route?.name} focusedRouteName={focusedRouteName} />
}

const TraineeNavigator = () => {
    const [badgeCount, setBadgeCount] = useState(0)
    const navigation = useNavigation()
    const { isMobile, isDesktop } = useDimensions()
    const screenOptions = useMemo(() => drawerScreenOptions({ isMobile, isDesktop, navigation }), [isMobile, isDesktop])
    const isDrawer = isDesktop
    const TabOrDrawer = isDrawer ? Drawer : Tab
    const labelKey = isDrawer ? DRAWER_LABEL : TAB_LABEL
    const iconKey = isDrawer ? DRAWER_ICON : TAB_ICON
    const iconStyle = isDrawer ? drawerIconStyle : tabIconStyle
    const focusedColor = isDrawer ? 'white' : colors.homebodyTurquoise

    useEffect(() => {
        async function getBadgeCount() {
            const count = await Notifications.getBadgeCountAsync()
            setBadgeCount(count)
        }
        getBadgeCount()
    }, [])
    return (
        <>
            {isDesktop && <WebHeader isTraineePortal />}
            <TabOrDrawer.Navigator
                initialRouteName={
                    __DEV__ ? screenNames.TRAINEE_HOME_STACK_NAVIGATOR : screenNames.TRAINEE_HOME_STACK_NAVIGATOR
                }
                screenOptions={screenOptions}
                drawerContent={props => <TraineeWebDrawer {...props} />}
            >
                <TabOrDrawer.Screen
                    name={screenNames.TRAINEE_HOME_STACK_NAVIGATOR}
                    component={TraineeHomeStackNav}
                    options={({ route, navigation }) => ({
                        header: () => (isDesktop ? undefined : getNativeHeader({ navigation, route })),
                        tabBarStyle: getTabBarStyle(route),
                        title: 'Home',
                        tabBarLabelPosition: 'below-icon',
                        [labelKey]: focused => <NavigationIconLabel title="Home" focused={focused} isTab={!isDrawer} />,
                        [iconKey]: ({ focused }) => (
                            <IconWrapper>
                                <SvgIcon
                                    icon={HOME_ICON}
                                    color={focused ? focusedColor : colors.subTextGray}
                                    size={24}
                                    style={iconStyle}
                                />
                            </IconWrapper>
                        ),
                    })}
                />
                <TabOrDrawer.Screen
                    name={screenNames.EXPLORE_NAVIGATOR}
                    component={ExploreStackNav}
                    options={({ route }) => ({
                        header: () => (isDesktop ? undefined : getNativeHeader({ route })),
                        title: 'Explore',
                        tabBarLabelPosition: 'below-icon',
                        [labelKey]: focused => (
                            <NavigationIconLabel title="Explore" focused={focused} isTab={!isDrawer} />
                        ),
                        [iconKey]: ({ focused }) => (
                            <IconWrapper>
                                <SvgIcon
                                    icon={DUMBBELL_ICON}
                                    color={focused ? focusedColor : colors.subTextGray}
                                    size={24}
                                    style={iconStyle}
                                />
                            </IconWrapper>
                        ),
                    })}
                />
                <TabOrDrawer.Screen
                    name={screenNames.FAVORITES_NAVIGATOR}
                    component={FavoritesStackNav}
                    options={({ route }) => ({
                        header: () => (isDesktop ? undefined : getNativeHeader({ route })),
                        title: 'Favorites',
                        tabBarLabelPosition: 'below-icon',
                        [labelKey]: focused => (
                            <NavigationIconLabel title="Favorites" focused={focused} isTab={!isDrawer} />
                        ),
                        [iconKey]: ({ focused }) => (
                            <VectorIcon
                                family="FontAwesome"
                                name="heartbeat"
                                style={iconStyle}
                                color={focused ? focusedColor : colors.subTextGray}
                            />
                        ),
                    })}
                />
                <TabOrDrawer.Screen
                    name={screenNames.PROFILE_NAVIGATOR}
                    component={ProfileStackNav}
                    options={({ route }) => ({
                        header: () => (isDesktop ? undefined : getNativeHeader({ route })),
                        title: 'My profile',
                        tabBarLabelPosition: 'below-icon',
                        [labelKey]: focused => (
                            <NavigationIconLabel title="Profile" focused={focused} isTab={!isDrawer} />
                        ),
                        [iconKey]: ({ focused }) => (
                            <VectorIcon
                                family="Ionicons"
                                name="person"
                                style={iconStyle}
                                color={focused ? focusedColor : colors.subTextGray}
                            />
                        ),
                    })}
                />
            </TabOrDrawer.Navigator>
        </>
    )
}

const IconWrapper = styled(View)`
    z-index: 1;
`

export default TraineeNavigator
