import React, { useEffect, useState, useMemo } from 'react'
import { isWeb } from '@constants'
import { FlatList, View, RefreshControl } from 'react-native'
import { isDateThisWeek, isDateToday, isDateThisMonth } from 'helpers'
import { useNavigation } from '@react-navigation/core'
import { useDimensions } from 'dimensions'
// ui
import { RowContainer } from 'commons/containers'
import styled from 'styled-components/native'
import colors from 'styles/colors'
// Components
import ClassCard, { InstructorCreateClassCard } from 'components/instructor/ClassCard'
import DropDownPicker from 'components/DropDownInput'
import { dateFilterPickerItems } from 'pickerItems'
import FlatlistCarousel from 'components/FlatlistCarousel'
import ViewAllHeading from 'components/trainee/ViewAllHeading'
import { Headline, SubHeaderText } from 'typography'
import { ALL_CLASSES } from 'screenNames'

const noop = () => {}
const ClassesFlatList = ({
    onCardPress = undefined,
    onDeletePress = noop,
    navigationAction = noop,
    classesLoading = true,
    style,
    data,
    headingText = '',
    subHeadingText = '',
    currentScreen = '',
    isCarousel = false,
    horizontal = false,
    isHome = false,
    hidePicker = false,
    isBookedClass = false,
    isLandingPageClass = false,
    isWebProfile = false,
    isExploreClass = false,
    isFullPage = false,
    isInstructor = false,
    shouldRefetch = false,
    showCreateWhenEmpty = false,
    disableCardPress = false,
    hideNoClassesText = false,
    subHeadingAlignment = 'left',
    onRefresh = noop,
    onReachEnd = noop,
    onPullToRefresh = noop,
    hasRefreshControl = false,
    refreshing = false,
    headingFontSize,
}) => {
    const navigation = useNavigation()
    const { isMobile, isTablet } = useDimensions()
    const [filter, setFilter] = useState('All Classes')
    const [filteredClasses, setFilteredClasses] = useState([])
    const classesToday = useMemo(() => (data?.length ? data.filter(Class => isDateToday(Class.date)) : []), [data])
    const classesThisWeek = useMemo(
        () => (data?.length ? data.filter(Class => isDateThisWeek(Class.date)) : []),
        [data],
    )
    const classesThisMonth = useMemo(
        () => (data?.length ? data.filter(Class => isDateThisMonth(Class.date)) : []),
        [data],
    )
    const goToAllClasses = () => navigation.navigate(ALL_CLASSES, { onRefresh })
    const updateFilter = ({ value }) => setFilter(value)
    const renderLoadingCard = () => <ClassCard classesLoading={classesLoading} />
    const renderItem = ({ item }) => {
        return (
            <ClassCard
                Class={item}
                disableCardPress={disableCardPress}
                navigationAction={navigationAction}
                currentScreen={currentScreen}
                onDeletePress={onDeletePress}
                onCardPress={onCardPress}
                isLandingPageClass={isLandingPageClass}
                isExploreClass={isExploreClass}
                isFullPage={isFullPage}
                isBookedClass={isBookedClass}
                isInstructor={isInstructor}
            />
        )
    }

    function handleFilterClasses() {
        switch (filter) {
            case 'All Classes':
                return data
            case 'Today':
                return classesToday
            case 'This Week':
                return classesThisWeek
            case 'This Month':
                return classesThisMonth
            default:
                return data
        }
    }
    useEffect(() => {
        setFilteredClasses(handleFilterClasses())
    }, [filter])

    useEffect(() => {
        if (data?.length > 0) {
            setFilteredClasses(data)
        } else {
            setFilteredClasses([])
        }
    }, [data])

    const NoClassesFoundText = () => {
        if (hideNoClassesText) {
            return <></>
        } else {
            return <StyledSubHeader isSubtext>No Classes found</StyledSubHeader>
        }
    }
    const listEmptyComponent = classesLoading
        ? renderLoadingCard
        : showCreateWhenEmpty
        ? InstructorCreateClassCard
        : NoClassesFoundText
    return (
        <ClassesFlatListContainer>
            <HeaderContainer horizontal={horizontal} isCarousel={isCarousel}>
                {!!headingText && isExploreClass && (
                    <ViewAllHeading headingText={headingText || ''} onHeadingPress={goToAllClasses} />
                )}
                {!!headingText && !isExploreClass && (
                    <Headline
                        isProfileHeadline={isWebProfile}
                        isLargeHeadline={!isWebProfile}
                        fontSize={headingFontSize}
                        isMostBold
                        paddingBottom={25}
                        marginBottom={isHome ? 119 : 0}
                    >
                        {headingText}
                    </Headline>
                )}
                {!hidePicker && (
                    <DropDownPicker
                        dropDownItems={dateFilterPickerItems}
                        selected={filter}
                        alignSelf="flex-end"
                        fieldTitle=""
                        fieldName=""
                        width={150}
                        shouldPushContent={false}
                        containerStyle={{ zIndex: 10 }}
                        style={{ alignSelf: 'flex-end' }}
                        reactiveForm={false}
                        onChangeValue={value => updateFilter(value)}
                    />
                )}
            </HeaderContainer>
            {!!subHeadingText && (
                <Headline
                    textAlign={subHeadingAlignment}
                    isSubHeadline
                    isMostBold
                    paddingBottom={10}
                    paddingTop={hidePicker && !isMobile ? 25 : 10}
                >
                    {subHeadingText}
                </Headline>
            )}
            {isCarousel ? (
                <FlatlistCarousel
                    data={filteredClasses}
                    renderItem={renderItem}
                    hasHeader={false}
                    ListEmptyComponent={listEmptyComponent}
                />
            ) : (
                <StyledFlatList
                    style={style}
                    contentContainerStyle={{
                        marginBottom: horizontal ? 15 : 0,
                        marginTop: isExploreClass && !horizontal ? 10 : 0,
                    }}
                    horizontal={horizontal}
                    data={filteredClasses}
                    numColumns={horizontal || isMobile || isTablet ? undefined : 3}
                    ListEmptyComponent={listEmptyComponent}
                    renderItem={renderItem}
                    keyExtractor={item => `${item.id}${item._version}`}
                    onEndReached={onReachEnd}
                    onEndReachedThreshold={onReachEnd !== noop && (isMobile || isTablet ? 0.5 : 0.2)}
                    showsVerticalScrollIndicator={isExploreClass && (isMobile || isTablet) ? false : true}
                    showsHorizontalScrollIndicator={isBookedClass && (isMobile || isTablet) ? false : true}
                    refreshControl={
                        hasRefreshControl ? (
                            <RefreshControl
                                refreshing={refreshing}
                                onRefresh={onPullToRefresh}
                                tintColor={colors.homebodyTurquoise}
                            />
                        ) : undefined
                    }
                />
            )}
        </ClassesFlatListContainer>
    )
}

const ClassesFlatListContainer = styled(View)`
    width: 100%;
`

const StyledFlatList = styled(FlatList)``

const HeaderContainer = styled(RowContainer)`
    padding-top: ${props => (props.isCarousel ? '50px' : '0px')};
    width: 100%;
    z-index: 5;
    ${props => (props.horizontal ? 'align-self: flex-start;' : '')}
    ${isWeb && `align-items: flex-start`};
`

const StyledSubHeader = styled(SubHeaderText)`
    margin-bottom: 20px;
    ${({ theme }) => theme.isMobile && 'align-self: center; margin-top: 20px;'}
`

export default ClassesFlatList
