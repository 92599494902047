import React from 'react'
import { View, Text, ScrollView } from 'react-native'
import styled from 'styled-components/native'
import { InputField } from 'components/InputField'
import { FormHeaderLabel } from 'typography'
import { FieldContainer } from 'src/commons/containers'
import { instructorApplicationForm } from 'apollo/cache'
import { useReactiveVar } from '@apollo/client'
import { capitalizeFirstLetter } from 'helpers'
import { instructorAppFieldNames as fieldNames } from 'formFieldInfo'
import { SANITIZE_INPUT_TYPES } from '@constants'

const emptyObj = {}
export const Socials = ({ socials, reactiveFormProps }) => {
    const form = useReactiveVar(instructorApplicationForm)
    const { isOwner = false } = form
    const formattedSocials = Object?.keys(socials || emptyObj)
        ?.filter(key => key != '__typename')
        ?.map(key => ({
            platform: key,
            handle: socials?.[key],
        }))

    const instagramHandleText = isOwner ? 'Please enter your instagram handle' : '*Please enter your instagram handle'
    const websiteOrSocialText = isOwner ? '*Please enter your website' : 'Any additional social media?'
    return (
        <FieldContainer>
            <FormHeaderLabel>{instagramHandleText}</FormHeaderLabel>
            <InputField
                shouldSanitizeInput
                sanitizeRegex={SANITIZE_INPUT_TYPES.USERNAME_INPUT}
                placeholder="Username"
                reactiveForm={true}
                fieldTitle="Instagram"
                fieldName="socials"
                nestedFieldName={fieldNames.socials.instagram}
                value={form?.socials?.instagram ?? ''}
                {...reactiveFormProps}
            />
            <FormHeaderLabel>{websiteOrSocialText}</FormHeaderLabel>
            {isOwner && (
                <InputField
                    shouldSanitizeInput
                    sanitizeRegex={SANITIZE_INPUT_TYPES.USERNAME_INPUT}
                    placeholder="www.crunchfitness.com"
                    reactiveForm={true}
                    fieldTitle="Website"
                    fieldName="website"
                    value={form?.website ?? ''}
                    {...reactiveFormProps}
                />
            )}
            {!isOwner &&
                formattedSocials
                    ?.slice(1, formattedSocials.length)
                    ?.map((item, index) => (
                        <SocialInput
                            item={item}
                            index={index}
                            key={item.platform}
                            reactiveFormProps={reactiveFormProps}
                        />
                    ))}
        </FieldContainer>
    )
}

const SocialInput = ({ item, reactiveFormProps }) => {
    const { platform, handle } = item
    return (
        <InputField
            fieldTitle={capitalizeFirstLetter(platform)}
            placeholder={'Username'}
            reactiveForm={true}
            fieldTitle={capitalizeFirstLetter(platform)}
            fieldName="socials"
            defaultValue={handle}
            nestedFieldName={platform}
            {...reactiveFormProps}
        />
    )
}

export default Socials
