import React, { useState, useEffect } from 'react'
// ui
import { View, ActivityIndicator } from 'react-native'
import styled from 'styled-components/native'
import { getS3Object } from 'actions'
import { Avatar } from 'react-native-elements'
import { getCurrentUser } from 'apollo/selectors'
import { userProfileForm } from 'apollo/cache'
import colors from 'colors'
import CustomImage from 'components/CustomImage'
import { MY_ACCOUNT_NAVIGATOR, INSTRUCTOR_ACCOUNT } from 'screenNames'
import { useNavigation } from '@react-navigation/native'
const noop = () => {}

const UserAvatar = ({ size, style, uploading = false, disableAvatarOnPress = true, onPress }) => {
    const navigation = useNavigation()
    const { firstName = '', lastName = '', avatar } = getCurrentUser()
    const { key: avatarImageKey } = avatar || {}
    const { avatar: newAvatar } = userProfileForm() //To avoid creating more than one profilePicture entry in S3
    const [uri, setUri] = useState(undefined)
    const initials = !uri ? firstName?.charAt(0)?.toUpperCase() + lastName?.charAt(0)?.toUpperCase() : ''

    useEffect(() => {
        async function updateAvatar() {
            const uri = avatar?.key ? await getS3Object(avatar?.key) : undefined
            setUri(uri)
        }
        updateAvatar()
    }, [avatar, newAvatar])

    return (
        <UserAvatarContainer>
            <Avatar
                rounded
                size={size}
                title={initials}
                source={{ uri: uploading ? undefined : uri }}
                onPress={onPress ? onPress : null}
                containerStyle={{ backgroundColor: !avatarImageKey ? colors.blueContinue : 'transparent' }}
                placeholderStyle={{ backgroundColor: !avatarImageKey ? colors.blueContinue : 'transparent' }}
            />
            {uploading && (
                <ActivityIndicatorContainer style={style}>
                    <ActivityIndicator size="large" color={colors.homebodyTurquoise} animating={uploading} />
                </ActivityIndicatorContainer>
            )}
            {!!avatarImageKey && !!!newAvatar?.key && !uploading && !!!uri && (
                <CustomImage
                    uploading={uploading}
                    resizeMode="stretch"
                    style={{
                        height: size,
                        width: size,
                        borderRadius: size / 2,
                        position: 'absolute',
                        alignSelf: 'center',
                    }}
                    imgKey={avatarImageKey}
                />
            )}
        </UserAvatarContainer>
    )
}
const ActivityIndicatorContainer = styled(View)`
    position: absolute;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    align-self: center;
`
const UserAvatarContainer = styled(View)``
export default UserAvatar
