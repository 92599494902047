import React, { createRef } from 'react'
import { ScrollView, TouchableOpacity, View } from 'react-native'

import moment from 'moment'

import { Inter500, Inter600, Inter900 } from 'typography'
import styled from 'styled-components/native'
import { SCREEN_WIDTH } from '@constants'
import colors from 'colors'
import { format, getWeek, getWeekOfMonth, parseISO, startOfWeek } from 'date-fns'

const DAY_SIZE = 190
const scrollWidth = SCREEN_WIDTH

export default class CalendarDaysWeb extends React.Component {
    containerRef = createRef()
    availableDates = []

    constructor(props) {
        super(props)
        this.state = {
            selectedDayIndex: undefined,
            leftMostDateIndex: 0,
            availableDates: [],
            scrollPosition: 0,
            daysSize: DAY_SIZE,
            selectedDate: undefined,
        }
    }

    scroll = direction => {
        const { isMobile } = this.props
        const numDaysToScroll = isMobile ? 5 : 7
        if (this.scrollView) {
            const { scrollPosition } = this.state
            let newPosition = 0
            if (direction === 'left') {
                newPosition = Math.max(scrollPosition - this.state.daysSize * numDaysToScroll, 0)
            } else {
                newPosition = scrollPosition + this.state.daysSize * numDaysToScroll
            }
            const leftMostDateIndex = Math.floor(newPosition / this.state.daysSize)

            if (!this.availableDates[leftMostDateIndex]) {
                return
            }

            this.setState({
                scrollPosition: newPosition,
                leftMostDateIndex: leftMostDateIndex,
            })

            this.scrollView.scrollTo({ x: newPosition, animated: true })
        }
    }
    scrollHandle = event => {
        this.setState({
            scrollPosition: event.nativeEvent.contentOffset.x,
        })
        setTimeout(() => {
            this.setState({ leftmostDateIndex: Math.ceil(event.nativeEvent.contentOffset.x / this.state.daysSize) })
        }, 500)
    }
    dateSelect = props => {
        const { onDateSelect } = this.props
        const { selectedDayIndex } = this.state
        const isSameDay = selectedDayIndex == props.key
        const selectedDate = new Date(parseISO(props.date))
        this.setState({
            selectedDayIndex: isSameDay ? undefined : props.key,
            selectedDate: isSameDay ? undefined : selectedDate,
        })
        onDateSelect(props.date)
    }

    scrollLeft = () => this.scroll('left')
    scrollRight = () => this.scroll('right')

    componentDidMount = () => {
        this.setAvailableDates()
    }

    componentDidUpdate = prevProps => {
        const propsToCheck = ['firstDate', 'lastDate', 'numberOfDays', 'disabledText', 'disabledDates']
        if (propsToCheck.some(propName => prevProps[propName] !== this.props[propName])) {
            this.setAvailableDates()
        }
    }

    setAvailableDates = () => {
        const { firstDate, lastDate, numberOfDays, disabledText, disabledDates } = this.props

        const daysProps = {
            firstDate,
            lastDate,
            numberOfDays: numberOfDays || 90,
            disabledText: disabledText || null,
            disabledDates: disabledDates || null,
        }
        this.availableDates = this.generateDates(daysProps)
        this.setState({ availableDates: this.availableDates })
    }

    generateDates = props => {
        const date = moment(props.firstDate)
        const disabledDates = props.disabledDates ? props.disabledDates : []

        const first = props.firstDate ? moment(props.firstDate) : moment(new Date())
        const last = props.lastDate ? moment(props.lastDate) : null

        const numberOfDays = last ? moment.duration(last.diff(first)).asDays() + 1 : props.numberOfDays

        const dates = []
        for (let i = 0; i < numberOfDays; i += 1) {
            const isDisabled = !!disabledDates.includes(date.format('YYYY-MM-DD'))
            const formattedDate = date.format('YYYY-MM-DD')
            dates.push({
                date: formattedDate,
                day: date.format('D'),
                day_of_week: date.format('ddd'),
                month: date.format('MMMM'),
                disabled: isDisabled,
            })
            date.add(1, 'days')
            if (this.props.defaultDate === formattedDate && this.selectedDayIndex === undefined) {
                this.dateSelect({ key: dates.length - 1, date: formattedDate })
            }
        }

        return dates
    }

    render() {
        let days
        const { selectedDayIndex, leftMostDateIndex, selectedDate } = this.state

        const { style, daysInView, width, paginate, showArrows, leftArrow, rightArrow } = this.props

        let scrollWidth = null
        if (this.props.isInstructorProfile) {
            this.containerRef.current?.measure((_x, _y, width) => {
                scrollWidth = width
                const weeklyDaysSize = Math.floor(scrollWidth / 7)
                this.setState({
                    daysSize: weeklyDaysSize,
                })
            })
        } else if (width) {
            scrollWidth = width
        } else if (daysInView) {
            scrollWidth = daysInView * this.state.daysSize
        }

        const leftmostDateWeek = this?.availableDates?.[leftMostDateIndex + 1]
            ? format(
                  startOfWeek(new Date(this?.availableDates[leftMostDateIndex + 1]?.date), { weekStartsOn: 1 }),
                  'dd',
              )
            : ''
        const monthOfSelectedDate = selectedDate
            ? format(selectedDate, 'MMMM')
            : this.availableDates?.[leftMostDateIndex]?.month
        const selectedDateWeek = selectedDate
            ? format(startOfWeek(selectedDate, { weekStartsOn: 1 }), 'dd')
            : leftmostDateWeek
        const selectedMonthText = `Week of ` + monthOfSelectedDate + ` ` + selectedDateWeek

        if (this.state.availableDates) {
            days = this.state.availableDates.map((val, key) => {
                const isSelected = selectedDayIndex === key
                const isClosed = val.disabled
                const isNextToSelected = selectedDayIndex + 1 === key
                const isPreviousToSelected = selectedDayIndex - 1 === key

                return (
                    <TouchableOpacity
                        key={key}
                        disabled={isClosed}
                        onPress={() => this.dateSelect({ key, date: this.availableDates[key].date })}
                    >
                        <SingleContainer daysSize={this.state.daysSize}>
                            <DateContainer isSelected={isSelected}>
                                <DateText
                                    isSelected={isSelected}
                                    isClosed={isClosed}
                                    isBlack={isNextToSelected || isPreviousToSelected}
                                >
                                    {val.day}
                                </DateText>
                                <DayContainer>
                                    <DayText
                                        isSelected={isSelected}
                                        isClosed={isClosed}
                                        isBlack={isNextToSelected || isPreviousToSelected}
                                    >
                                        {val.day_of_week}
                                    </DayText>
                                </DayContainer>
                            </DateContainer>
                        </SingleContainer>
                    </TouchableOpacity>
                )
            })
        }

        return (
            <DatePickerContainer ref={this.containerRef}>
                <MonthAndArrowContainer isInstructorProfile={this.props.isInstructorProfile}>
                    {showArrows && <TouchableOpacity onPress={this.scrollLeft}>{leftArrow}</TouchableOpacity>}
                    <MonthText>{selectedMonthText}</MonthText>
                    {showArrows && <TouchableOpacity onPress={this.scrollRight}>{rightArrow}</TouchableOpacity>}
                </MonthAndArrowContainer>

                <MonthBackground>
                </MonthBackground>
                <SliderContainer style={[style, { width: '100%' }]}>
                    <ScrollView
                        contentContainerStyle={{
                            flexDirection: 'row',
                            paddingTop: 17.5,
                            paddingLeft: this.props.isMobile ? 10 : 0,
                            alignItems: 'center',
                        }}
                        ref={scrollView => {
                            this.scrollView = scrollView
                        }}
                        horizontal
                        snapToInterval={
                            paginate && scrollWidth % this.state.daysSize === 0 ? scrollWidth : this.state.daysSize
                        }
                        decelerationRate="fast"
                        showsHorizontalScrollIndicator={false}
                        onScroll={this.scrollHandle}
                        scrollEventThrottle={300}
                    >
                        <View style={{ width: (scrollWidth % this.state.daysSize) / 2 }} />
                        {days || null}
                    </ScrollView>
                </SliderContainer>
            </DatePickerContainer>
        )
    }
}

const MonthAndArrowContainer = styled(View)`
    flex-direction: row;
    justify-content: ${({ isInstructorProfile }) => (isInstructorProfile ? 'center' : 'space-around')};
    background-color: ${({ isInstructorProfile }) => (isInstructorProfile ? '#F4F4F4' : 'transparent')};
    align-items: center;
    padding-vertical: 15px;
`
const SliderContainer = styled(View)`
    height: ${DAY_SIZE}px;
    width: ${scrollWidth}px;
    flex-direction: row;
    justify-content: center;
`

const SingleContainer = styled(View)`
    height: ${DAY_SIZE}px;
    width: ${({ daysSize }) => daysSize}px;
    align-items: center;
`

const DateContainer = styled(View)`
    border-radius: 5px;
    overflow: hidden;
    width: 73px;
    height: 101px;
    flex-direction: column;
    justify-content: center;
    background-color: ${({ isSelected }) => (isSelected ? colors.greyBgCalendar : colors.white)};
`
const DayContainer = styled(View)``

const MonthText = styled(Inter900)`
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    color: ${colors.blackTextCalendar};

`

const DateText = styled(Inter900)`
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: ${({ isSelected, isClosed, isBlack }) =>
        isClosed
            ? colors.greyTextCalendar
            : isSelected
            ? colors.white
            : isBlack
            ? colors.blackTextCalendar
            : colors.greyTextCalendar};
`
const DayText = styled(Inter900)`
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: ${({ isSelected, isClosed, isBlack }) =>
        isClosed
            ? colors.greyTextCalendar
            : isSelected
            ? colors.white
            : isBlack
            ? colors.blackTextCalendar
            : colors.greyTextCalendar};
`

const DatePickerContainer = styled(View)`
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
`

const MonthBackground = styled(View)`
    width: 115%;
    background-color: ${colors.faintYellow};
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 54px;
    top: 0px;
    z-index: -2;
`
