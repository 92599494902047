import React, { useState, useEffect } from 'react'
import { View, Text, Platform, Image, ScrollView, RefreshControl } from 'react-native'
import styled from 'styled-components/native'
import { HEADER_HEIGHT, isWeb } from '@constants'
import { useDimensions } from 'dimensions'
import { KeyboardAwareScrollView, listenToKeyboardEvents } from 'react-native-keyboard-aware-scroll-view'
import colors from 'styles/colors'

export const StandardContainer = styled(isWeb ? View : KeyboardAwareScrollView)`
    flex: 1;
    padding-top: ${({ theme }) => (theme.isMobile ? '2.5%' : '5%')};
    padding-left: ${({ theme }) => (theme.isMobile ? '5%' : '5%')};
    padding-right: ${({ theme }) => (theme.isMobile ? '5%' : '5%')};
    padding-bottom: ${({ theme }) => (theme.isMobile ? '0%' : '2.5%')};
    background-color: white;
`
export const StandardDrawerContainer = styled(ScrollView)`
    flex: 1;
    padding-top: ${({ theme }) => (theme.isMobile ? '5%' : '10%')};
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 2.5%;
    background-color: white;
`
export const StandardTraineeContainer = styled(ScrollView)`
    flex: 1;
    padding-left: 10%;
    padding-right: 10%;
    background-color: white;
`
export const FillContainer = styled(ScrollView)`
    flex: 1;
    padding-left: 0%;
    padding-right: 0%;
    background-color: white;
`

export const ScreenContainer = styled(View)`
    flex: 1;
    padding-top: ${({ hasTopPadding }) => (hasTopPadding ? '5%' : '0px')};
    background-color: white;
    justify-content: flex-start;
    ${isWeb &&
    `
        overflow-x: hidden;
        overflow-y: scroll;
        padding-bottom: 20px;
    `}
`
export const MAX_FORM_WIDTH = '800px'

const ContentContainerScrollViewStyles = {
    alignItems: 'center',
    // flex: 1,
}

const NativeContentContainerWrapper = ({ children, refreshing, onPullToRefresh }) => {
    return (
        <WebContentContainer
            contentContainerStyle={ContentContainerScrollViewStyles}
            refreshControl={
                onPullToRefresh ? (
                    <RefreshControl
                        refreshing={refreshing}
                        onRefresh={onPullToRefresh}
                        tintColor={colors.homebodyTurquoise}
                    />
                ) : undefined
            }
        >
            {children}
        </WebContentContainer>
    )
}

const WebContentContainer = styled(isWeb ? View : ScrollView)`
    flex: 1;
    width: ${({ theme, isFluid }) => (isFluid ? '100%' : theme.isDesktop ? MAX_FORM_WIDTH : '100%')};
    padding-horizontal: 5%;
    background-color: white;
    flex-direction: column;
    padding-bottom: 50px;
    ${isWeb && `justify-content: flex-start;`}
    ${isWeb && `align-self: flex-start;`}
    margin-bottom: ${({ buttonPaddingBottom }) => (buttonPaddingBottom ? 50 : 0)}px;
    ${props => props.width && `width: ${props.width};`}
`
export const ContentContainer = isWeb ? WebContentContainer : NativeContentContainerWrapper

export const ColumnToRowContainer = styled(View)`
    flex: 1;
    flex-direction: ${({ theme }) => (theme.isMobile ? 'column' : 'row')};
    justify-content: ${({ theme }) => (theme.isMobile ? 'space-around' : 'center')};
`

export const AppFormContainer = styled(StandardContainer)`
    padding-top: 2%;
    padding-left: ${props => (props.theme.isMobile ? '5%' : '8%')};
    padding-right: ${props => (props.theme.isMobile ? '5%' : '5%')};
`
export const FieldContainer = styled(View)`
    flex-direction: column;
    max-width: ${({ theme }) => (theme.isMobile ? '100%' : '450px')};
    margin-bottom: ${({ theme }) => (theme.isSmallDevice ? HEADER_HEIGHT : 0)}px;
`
export const FormBottomRowToColumnContainer = styled(View)`
    bottom: ${({ theme }) => (theme.isMobile ? '0px' : '20px')};
    width: ${({ theme }) => (theme.isDesktop ? MAX_FORM_WIDTH : '100%')};
    ${({ theme, alignSelf, isReviewandSubmit }) =>
        theme.isDesktop
            ? isReviewandSubmit
                ? ''
                : alignSelf
                ? `align-self: ${alignSelf}`
                : 'align-self: center;'
            : null}
    padding-horizontal: 5%;
    flex-direction: ${({ theme, flexDirection }) =>
        theme.isMobile || theme.isTablet || flexDirection == 'column' ? 'column' : 'row'};
    justify-content: ${({ theme, isCentered, isEnd, isReviewandSubmit }) =>
        isCentered && !isReviewandSubmit ? 'center' : isEnd && !isReviewandSubmit ? 'flex-end' : 'flex-start'};
`

export const FieldRowContainer = styled(View)`
    flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
    justify-content: space-between;
    max-width: ${({ theme, maxWidth }) => (maxWidth ? maxWidth : theme.isMobile ? '100%' : '900px')};
    width: 100%;
    align-items: center;
`
export const HeaderTextWrapper = styled(View)`
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
`

export const RowContainer = styled(View)`
    flex-direction: row;
`

export const BannerContainer = styled(View)`
    width: 100%;
    justify-content: center;
    align-items: center;
`
export const WebContentContainerFluid = styled(isWeb ? View : ScrollView)`
    flex: 1;
    width: 100%;
    padding-horizontal: ${({ paddingHorizontal }) => (paddingHorizontal ? paddingHorizontal : '5%')};
    background-color: white;
    flex-direction: column;
    ${isWeb && `justify-content: flex-start`}
    ${isWeb && `align-self: flex-start`}
    margin-bottom: ${({ buttonPaddingBottom }) => (buttonPaddingBottom ? 50 : 0)}px;
`
