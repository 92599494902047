import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { useIsFocused } from '@react-navigation/native'
// ui
import styled from 'styled-components/native'
// selectors
import { getCurrentUser, getReviewsByInstructorID, getPublishedClassesByInstructorID } from 'apollo/selectors'
// components
import { ScreenContainer, ContentContainer } from 'commons/containers'
import ClassesFlatList from 'components/instructor/ClassesFlatList'
import { PUBLISHED_CLASSES_NAVIGATOR, PUBLISHED_CLASS_DETAILS } from 'screenNames'
import CallToActionBanner from 'components/CallToActionBanner'
import ReviewFlatList from 'components/ReviewFlatList'
import { useMutation } from 'apollo-augmented-hooks'
import { UPDATE_USER } from 'apollo/mutations'
import { STRIPE_API_ROUTES, USER_TYPES } from '@constants'
const { INSTRUCTOR, ADMIN } = USER_TYPES
const { CREATE_STRIPE_CONNECT_ACCOUNT } = STRIPE_API_ROUTES
import { stripeAPI, updateUserAccountID } from 'src/actions'
import { FooterBar } from 'commons/components/FooterBar'
import { Heading } from 'styles/typography'
const emptyArr = []
const emptyObj = {}

function Home({ navigation, route }) {
    const isFocused = useIsFocused()
    const [publishedClasses, setPublishedClasses] = useState(emptyArr)
    const [publishedClassesToken, setPublishedClassesToken] = useState(null)
    const [reviews, setReviews] = useState(emptyArr)
    const [reviewsToken, setReviewsToken] = useState(null)
    const updateUserMutation = useMutation(UPDATE_USER, {
        onCompleted: () => {
            __DEV__ && console.log('created a stripe connected account')
        },
        onError: () => {
            __DEV__ && console.log('error updating user accountID')
        },
    })
    const {
        id: instructorID,
        _version,
        userType,
        accountID,
        email,
        profile: { firstName = '', lastName = '' } = {},
    } = getCurrentUser()

    async function createStripeConnectAccount({ id, firstName, lastName, email, _version }) {
        const { id: accountID = '' } = await stripeAPI({
            path: CREATE_STRIPE_CONNECT_ACCOUNT,
            body: { firstName, lastName, email },
        })
        await updateUserAccountID({
            id,
            _version,
            updateUserMutation,
            accountID,
        })
    }

    useEffect(() => {
        const shouldCreateConnectedAccount = accountID === '' && (userType === INSTRUCTOR || userType === ADMIN)
        shouldCreateConnectedAccount &&
            createStripeConnectAccount({ id: instructorID, firstName, lastName, email, _version })
    }, [instructorID])

    const {
        classes: publishedClassesResult,
        nextToken: initialPublishedClassesToken,
        fetchMore: fetchMorePublishedClasses,
        refetch: refetchPublishedClasses,
    } = getPublishedClassesByInstructorID({
        instructorID,
        isInstructorView: true,
        onCompleted: () => {
            if (publishedClasses?.length === 0 && !publishedClassesToken) {
                setPublishedClassesToken(initialPublishedClassesToken)
                setPublishedClasses(publishedClassesResult)
            }
        },
    })

    async function handleFetchMorePublishedClasses() {
        if (publishedClassesToken) {
            const fetchMoreResult = await fetchMorePublishedClasses({ variables: { nextToken: publishedClassesToken } })
            const {
                data: {
                    listClasses: { items: moreClasses = emptyArr, nextToken: token = null },
                },
            } = fetchMoreResult ?? emptyObj
            const allPublishedClasses =
                publishedClasses?.length > 0 ? [...publishedClasses, ...moreClasses] : [...moreClasses]
            setPublishedClasses(allPublishedClasses)
            setPublishedClassesToken(token)
        }
    }

    const onClassCardPress = ({ Class, classID }) =>
        navigation.navigate(PUBLISHED_CLASSES_NAVIGATOR, {
            screen: PUBLISHED_CLASS_DETAILS,
            params: { Class, classID },
        })

    const {
        instructorReviews: reviewsResult,
        nextToken: initialReviewsToken,
        fetchMore: fetchMoreReviews,
        loading: reviewsLoading,
        refetch: refetchReviews,
    } = getReviewsByInstructorID({
        instructorID,
        isAvatar: true,
        onCompleted: () => {
            if (reviews?.length === 0 && !reviewsToken) {
                setReviewsToken(initialReviewsToken)
                setReviews(reviewsResult)
            }
        },
    })

    async function handleFetchMoreReviews() {
        if (reviewsToken) {
            const fetchMoreResult = await fetchMoreReviews({ variables: { nextToken: reviewsToken } })
            const {
                data: {
                    listReviews: { items: moreReviews = emptyArr, nextToken: token = null },
                },
            } = fetchMoreResult ?? emptyObj
            const allReviews = reviews?.length > 0 ? [...reviews, ...moreReviews] : [...moreReviews]
            setReviews(allReviews)
            setReviewsToken(token)
        }
    }

    useEffect(() => {
        if (publishedClassesToken !== null) {
            handleFetchMorePublishedClasses()
        }
    }, [publishedClassesToken])

    useEffect(() => {
        if (reviewsToken !== null) {
            handleFetchMoreReviews()
        }
    }, [reviewsToken])

    useEffect(() => {
        if (!isFocused) {
            setPublishedClasses(emptyArr)
            setPublishedClassesToken(null)
            refetchPublishedClasses()
            refetchReviews && refetchReviews()
        }
    }, [isFocused])

    return (
        <ScreenContainer>
            <CallToActionBanner navigation={navigation} />
            <ContentContainer isFluid>
                <PublishedClassesContainer>
                    <ClassesFlatList
                        isCarousel
                        isInstructor
                        isHome
                        hideNoClassesText
                        classesLoading={false}
                        headingText="My Upcoming Classes"
                        showCreateWhenEmpty
                        data={publishedClasses}
                        navigation={navigation}
                        onCardPress={onClassCardPress}
                    />
                </PublishedClassesContainer>
                <Heading isMostBold isLargeHeading marginTop={144} marginBottom={60}>
                    Your Reviews
                </Heading>
                <ReviewFlatList
                    isAvatar
                    horizontal
                    style={{ paddingTop: 32, marginTop: 55 }}
                    data={reviews}
                    reviewsLoading={reviewsLoading}
                />
            </ContentContainer>
            <FooterBar />
        </ScreenContainer>
    )
}
const PublishedClassesContainer = styled(View)``
export default Home
