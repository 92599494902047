import React from 'react'
import { View, Modal as NativeModal } from 'react-native'
import styled from 'styled-components/native'
import WebModal from 'modal-react-native-web'
import { isWeb } from '@constants'
import { useDimensions } from 'dimensions'
import colors from 'styles/colors'
import { Inter500, Inter900, Paragraph } from 'typography'

const Modal = isWeb ? WebModal : NativeModal

export const PaymentModal = ({ modalVisible }) => {
    const { isMobile } = useDimensions()

    return (
        <Modal animationType="fade" transparent={true} visible={modalVisible}>
            <PaymentModalContainer isMobile={isMobile}>
                <TextContainer>
                    <TitleText>One moment while we process your payment</TitleText>
                    <SubText fontSize={isMobile ? 16 : 25} isBold={!isMobile}>
                        Please do not exit the screen. This should only take a few moments
                    </SubText>
                </TextContainer>
            </PaymentModalContainer>
        </Modal>
    )
}

const PaymentModalContainer = styled(View)`
    height: 100%;
    width: 100%;
    position: absolute;
    align-self: ${props => (props.isMobile ? 'center' : 'flex-start')};
    background-color: ${colors.white}F2;
`

const TextContainer = styled(View)`
    flex: 1;
    margin-top: ${({ theme }) => (theme.isMobile ? 80 : 20)}%;
    margin-left: ${({ theme }) => (theme.isMobile ? 10 : 40)}%;
    margin-right: ${({ theme }) => (theme.isMobile ? 10 : 40)}%;
    color: ${colors.white}F2;
`
const TitleText = styled(Inter900)`
    margin-bottom: 10%;
    font-size: ${({ theme }) => (theme.isMobile ? '21' : '40')}px;
    line-height: ${({ theme }) => (theme.isMobile ? '25' : '50')}px;
    text-align: center;
`
const SubText = styled(Paragraph)`
    line-height: ${({ theme }) => (theme.isMobile ? '19' : '30')}px;
    text-align: center;
    letter-spacing: 0px;
    color: ${({ theme }) => (theme.isMobile ? colors.subTextGray : colors.black)};
`

export default PaymentModal
