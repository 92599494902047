import React, { useState, useEffect, useRef } from 'react'
import { Image, ActivityIndicator, View } from 'react-native'
// ui
import styled from 'styled-components/native'
import _ from 'lodash'
import colors from 'colors'
import LinearGradientView from './LinearGradientView'
import { S3Image } from 'aws-amplify-react-native'
const CustomImage = ({
    style,
    imgKey,
    defaultBanner = false,
    defaultImage = '',
    resizeMode = 'contain',
    hasShadow = false,
    hasGradientShadow = false,
    shadowColor = 'black',
}) => {
    const [imageLoaded, setImageLoaded] = useState(false)
    const S3ImageRef = useRef(null)

    /*
        For some strange reason the S3Image prop "onLoad" just doesn't want to fire
        so I had to use a ref.
    */
    useEffect(() => {
        let timer
        if (S3ImageRef?.current?.state) {
            timer = setTimeout(function () {
                setImageLoaded(true)
            }, 500)
        }
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        }
    }, [S3ImageRef.current?.state, imgKey])

    return (
        <>
            {imgKey ? (
                <>
                    <S3Image
                        ref={S3ImageRef}
                        imgKey={imgKey}
                        resizeMode={resizeMode}
                        style={imageLoaded ? style : {}}
                    />
                    {hasShadow && <ImageShadow shadowColor={shadowColor} style={style} />}
                    {hasGradientShadow && (
                        <LinearGradientShadow
                            style={style}
                            start={{ x: 0.0, y: 0.75 }}
                            end={{ x: 0.0, y: 1.0 }}
                            colors={['transparent', '#000000']}
                        />
                    )}
                    {!imageLoaded && (
                        <ActivityIndicatorContainer style={style}>
                            <ActivityIndicator size="large" color={colors.homebodyTurquoise} animating={!imageLoaded} />
                        </ActivityIndicatorContainer>
                    )}
                </>
            ) : (
                <>
                    {defaultBanner ? (
                        <S3Image ref={S3ImageRef} imgKey={defaultImage} resizeMode={resizeMode} style={style} />
                    ) : (
                        <Image style={style} resizeMode={resizeMode} source={defaultImage} />
                    )}
                    {hasShadow && <ImageShadow style={style} />}
                    {hasGradientShadow && (
                        <LinearGradientShadow
                            style={style}
                            start={{ x: 0.0, y: 0.75 }}
                            end={{ x: 0.0, y: 1.0 }}
                            colors={['transparent', '#000000']}
                        />
                    )}
                </>
            )}
        </>
    )
}

const ImageShadow = styled(View)`
    position: absolute;
    opacity: 0.35;
    height: ${props => (props?.style?.height ? props.style.height : '100%')};
    width: 100%;
    flex-direction: column;
    justify-content: center;
    background-color: ${props => props.shadowColor};
`
const LinearGradientShadow = styled(LinearGradientView)`
    position: absolute;
    opacity: 1;
    height: ${props => (props?.style?.height ? props.style.height : '100%')};
    width: 100%;
    flex-direction: column;
    justify-content: center;
`

const ActivityIndicatorContainer = styled(View)`
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    align-self: center;
`
export default CustomImage
