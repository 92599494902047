export const NOTIFICATION_ICON_TYPES = {
    CLASS_ICON: { iconProps: { family: 'MaterialCommunityIcons', name: 'dumbbell', size: 25, color: 'white' } },
    ALERT_ICON: { iconProps: { family: 'MaterialCommunityIcons', name: 'bell-outline', size: 25, color: 'white' } },
    HEART_ICON: { iconProps: { family: 'MaterialCommunityIcons', name: 'heart-outline', size: 25, color: 'white' } },
}
const { CLASS_ICON, ALERT_ICON, HEART_ICON } = NOTIFICATION_ICON_TYPES

export const NOTIFICATION_ACTION_TYPES = {
    NAVIGATE_CLASS_DETAILS: 'NAVIGATE_CLASS_DETAILS',
    NAVIGATE_TRAINEE_HOME: 'NAVIGATE_TRAINEE_HOME',
    NAVIGATE_INSTRUCTOR_HOME: 'NAVIGATE_INSTRUCTOR_HOME',
    NAVIGATE_PUBLISHED_CLASS_DETAILS: 'NAVIGATE_PUBLISHED_CLASS_DETAILS',
    NAVIGATE_INSTRUCTOR_PROFILE: 'NAVIGATE_INSTRUCTOR_PROFILE',
    NAVIGATE_LEAVE_REVIEW: 'NAVIGATE_LEAVE_REVIEW',
    NAVIGATE_UPCOMING_CLASSES: 'NAVIGATE_UPCOMING_CLASSES',
    NO_ACTION: '',
}

const {
    NAVIGATE_CLASS_DETAILS,
    NAVIGATE_INSTRUCTOR_PROFILE,
    NAVIGATE_LEAVE_REVIEW,
    NAVIGATE_UPCOMING_CLASSES,
    NO_ACTION,
    NAVIGATE_INSTRUCTOR_HOME,
    NAVIGATE_PUBLISHED_CLASS_DETAILS,
} = NOTIFICATION_ACTION_TYPES

export const TRAINEE_NOTIFICATION_TYPES = {
    // Navigate to Class Details
    ENROLLED_IN_CLASS: {
        actionType: NAVIGATE_CLASS_DETAILS,
        icon: CLASS_ICON,
    },
    // No action
    CLASS_CANCELLED: {
        actionType: NO_ACTION,
        icon: ALERT_ICON,
    },
    // Navigate to trainee home
    CLASS_STARTING_SOON: {
        actionType: NAVIGATE_UPCOMING_CLASSES,
        icon: CLASS_ICON,
    },
    // Navigate to instructor profile
    FAVORITE_INSTRUCTOR_CLASS: {
        actionType: NAVIGATE_INSTRUCTOR_PROFILE,
        icon: HEART_ICON,
    },
    // Navigate to leave review
    LEAVE_CLASS_REVIEW: {
        actionType: NAVIGATE_LEAVE_REVIEW,
        icon: HEART_ICON,
    },
}

export const INSTRUCTOR_NOTIFICATION_TYPES = {
    // Display review in notification body window
    TRAINEE_LEFT_REVIEW: {
        actionType: NAVIGATE_INSTRUCTOR_HOME,
        icon: ALERT_ICON,
    },
    // Navigate to published classes page
    INSTRUCTOR_CLASS_STARTING_SOON: {
        actionType: NAVIGATE_PUBLISHED_CLASS_DETAILS,
        icon: CLASS_ICON,
    },
}
