import React, { useState, useEffect } from 'react'
import { Text, View, ActivityIndicator } from 'react-native'
import colors from 'colors'
import styled from 'styled-components/native'
import { RowContainer } from 'src/commons/containers'
import { useReactiveVar } from '@apollo/client'
import { createClassForm } from 'apollo/cache'
import { Inter600, Inter700, ShortButtonText } from 'typography'
import { pickFile } from 'actions'
import { UPLOAD_ICON } from 'constants/svgIcons'
import SvgIcon from 'commons/SvgIcon'
import CustomImage from 'components/CustomImage'
import BannerCarousel from 'commons/components/BannerCarousel'
import { TouchableOpacity } from 'react-native-gesture-handler'
const bannerDescription = `Your class banner will be the visual that appears on the user app. Upload your own banner or choose one of our pre-selected banners.`

export const Banner = ({ reactiveFormProps, images }) => {
    const form = useReactiveVar(createClassForm)
    const {
        banner: { key },
        defaultBannerIndex,
    } = form || {}
    const fileName = form?.name + form?.date + form?.startTime + Date.now()
    const [uploading, setUploading] = useState(false)
    const [uploadError, setUploadError] = useState({ showError: false, error: '' })
    const [uploaded, setUploaded] = useState(key ? key.substring(0, 5) == 'Class' : false)
    const { showError = false, error = '' } = uploadError
    const { updateForm } = reactiveFormProps
    const [defaultImage, setDefaultImage] = useState(images[defaultBannerIndex] || images[1])
    const [activeIndex, setActiveIndex] = useState(defaultBannerIndex != undefined ? defaultBannerIndex + 1 : 2)

    const handleDefaultBanner = () => {
        setUploaded(false)
        updateForm({
            fieldName: 'banner',
            value: { bucket: 'DefaultBanners', region: 'us-east-1', key: images[defaultBannerIndex] },
        })
    }

    async function handleBannerUpload() {
        setUploadError({ showError: false, error: '' })
        await pickFile({
            fileName,
            id: form.id,
            setUploading,
            setUploadError,
            bucketPath: 'ClassBanners',
            updateForm,
            fieldName: 'banner',
        })
        !showError && setUploaded(true)
    }

    const handleSnapToItem = index => {
        if (uploaded) return
        const adjustedIndex = index % images.length
        updateForm({ fieldName: 'defaultBannerIndex', value: adjustedIndex })
        setDefaultImage(images[adjustedIndex])
        updateForm({
            fieldName: 'banner',
            value: { bucket: 'DefaultBanners', region: 'us-east-1', key: images[adjustedIndex] },
        })
        setActiveIndex(index + 1)
    }

    useEffect(() => {
        const shouldUpdateInitialDefaultBanner = key === '' && uploaded == false && images.length !== 0
        if (shouldUpdateInitialDefaultBanner) {
            updateForm({
                fieldName: 'banner',
                value: { bucket: 'DefaultBanners', region: 'us-east-1', key: images[defaultBannerIndex] },
            })
        }
    }, [key, uploaded, images])

    useEffect(() => {
        let timeout
        if (showError) {
            timeout = setTimeout(() => {
                setUploaded(false)
                setUploadError({ showError: false, error: '' })
            }, 3000)
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [showError, error])

    return (
        <Container>
            <UploadText>Upload a Banner</UploadText>
            <BannerDescriptionText>{bannerDescription}</BannerDescriptionText>
            {showError ? (
                <ErrorContainer>
                    <ErrorText>{error}</ErrorText>
                </ErrorContainer>
            ) : (
                <CustomImage
                    imgKey={uploaded && key}
                    defaultBanner
                    defaultImage={images[defaultBannerIndex]}
                    style={{ height: 560, width: 976, alignSelf: 'center', marginTop: 50 }}
                />
            )}
            <BannerCarousel
                data={images}
                disableButtons={uploaded && key}
                disableCards={uploaded && key}
                activeIndex={activeIndex}
                handleSnapToItem={handleSnapToItem}
                carouselConfig={{
                    itemHeight: 126,
                    itemWidth: 244,
                    sliderHeight: 225,
                    sliderWidth: 900,
                    buttonSize: 54,
                    iconSize: 24,
                    activePadding: 12,
                }}
            />
            <RowContainer>
                <BannerButton onPress={handleDefaultBanner} color={colors.subTextGray}>
                    <BannerButtonText>Default Banner</BannerButtonText>
                </BannerButton>
                <BannerButton onPress={handleBannerUpload}>
                    <SvgIcon icon={UPLOAD_ICON} color={'#fff'} size={17} />
                    <BannerButtonText>Or choose file</BannerButtonText>
                </BannerButton>
            </RowContainer>
            {uploading && (
                <ActivityIndicator
                    animating={uploading}
                    size="large"
                    color={colors.blueContinue}
                    style={{ marginLeft: 10 }}
                />
            )}
        </Container>
    )
}
const Container = styled(View)`
    max-width: 1010px;
    width: 100%;
`
const UploadBannerContainer = styled(View)``
const BannerDescriptionText = styled(Inter600)`
    font-size: 17px;
    color: #808191;
`
const UploadText = styled(Inter700)`
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 7px;
`
const ErrorContainer = styled(View)`
    height: 560px;
    width: 976px;
    margin-top: 50px;
    align-self: center;
    align-items: center;
    justify-content: center;
`

const ErrorText = styled(Inter700)`
    color: ${colors.cancelClassRed};
`
const BannerButton = styled(TouchableOpacity)`
    width: 158px;
    height: 55.36px;
    border-radius: 16px;
    background-color: ${({ color }) => (color ? color : colors.deactiveLightmode)};
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 18px;
`
const BannerButtonText = styled(ShortButtonText)`
    padding: 5px;
    margin-left: 5px;
    color: #fff;
`
export default Banner
