import 'expo-dev-client'
import 'react-native-gesture-handler'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { StatusBar } from 'expo-status-bar'
import React, { useEffect, useState, useCallback, Component } from 'react'
import { LogBox, SafeAreaView } from 'react-native'
import WebSplashScreen from './src/screens/SplashScreen'
import { NavigationContainer } from '@react-navigation/native'
import Amplify, { Hub } from 'aws-amplify'
import { ApolloProvider } from '@apollo/client/react'
import Navigator from './src/Navigator'
import { ThemeProvider } from 'styled-components/native'
import { useDimensions } from 'dimensions'
import { linkingConfig } from 'screenNames'
import { authListener } from 'apollo/channels'
import {
    useFonts,
    Inter_100Thin,
    Inter_200ExtraLight,
    Inter_300Light,
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
} from '@expo-google-fonts/inter'
import * as Sentry from 'sentry-expo'
import useApolloClient from 'commons/hooks/useApolloClient'
import awsconfig from './src/aws-exports'
import { isNative, isWeb, ENVIRONMENT_VARIABLES } from '@constants'
const { SERVERLESS_API_URL = '', REV_CAT_APPLE_API_KEY = '' } = ENVIRONMENT_VARIABLES
import AppCrashed from './AppCrashed'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import AWS from 'aws-sdk'
import { enableScreens } from 'react-native-screens'
import Purchases from 'react-native-purchases'
import * as SplashScreen from 'expo-splash-screen'
import * as Updates from 'expo-updates'
const noop = () => {}

if (isNative) {
    SplashScreen.preventAutoHideAsync()
}

if (__DEV__) {
    LogBox.ignoreAllLogs()
}

enableScreens(true)

const apiGatewayConfig = {
    API: {
        endpoints: [
            {
                name: 'stripe-api',
                endpoint: SERVERLESS_API_URL,
            },
        ],
    },
}

AWS.config.update({ region: 'us-east-1' })
Amplify.configure({ ...awsconfig, ...apiGatewayConfig })

Hub.listen('auth', authListener)
Sentry.init({
    dsn: 'https://48d493568bb1435491084d949c995597@o916315.ingest.sentry.io/5857766',
})

export default class AppWrapper extends Component {
    state = { error: null }
    componentDidCatch(error) {
        __DEV__ && console.log('fatal error: ', error)
        this.setState({ error })
        Sentry.Browser.captureException(error)
    }

    render() {
        const { error } = this.state
        if (error) return <AppCrashed />
        return <App />
    }
}

function App() {
    const { client, clearCache } = useApolloClient()
    const Dimensions = useDimensions()
    const [isApolloClientReady, setIsApolloClientReady] = useState(false)
    const [isOTAUpdateReady, setIsOTAUpdateReady] = useState(isWeb || __DEV__ ? true : false)
    const [isAppReady, setIsAppReady] = useState(__DEV__ ? false : false)
    let [fontsLoaded] = useFonts({
        Inter_100Thin,
        Inter_200ExtraLight,
        Inter_300Light,
        Inter_400Regular,
        Inter_500Medium,
        Inter_600SemiBold,
        Inter_700Bold,
        Inter_800ExtraBold,
        Inter_900Black,
    })

    async function checkForUpdate() {
        try {
            const checkForUpdateResult = (await Updates.checkForUpdateAsync()) ?? { isAvailable: false }
            if (checkForUpdateResult?.isAvailable) {
                const fetchUpdateResult = await Updates.fetchUpdateAsync()
                const isNewUpdate = fetchUpdateResult?.isNew
                if (isNewUpdate) {
                    await Updates.reloadAsync()
                } else {
                    setIsOTAUpdateReady(true)
                }
            } else {
                setIsOTAUpdateReady(true)
            }
        } catch (error) {
            __DEV__ && console.log('error checking for OTA update: ', error)
            setIsOTAUpdateReady(true)
        }
    }

    useEffect(() => {
        async function initRevenueCat() {
            Purchases.setDebugLogsEnabled(__DEV__)
            const isConfigured = await Purchases.isConfigured()
            !isConfigured && Purchases.setup(REV_CAT_APPLE_API_KEY)
        }

        isNative && initRevenueCat()
    }, [])

    useEffect(() => {
        if (client) {
            setIsApolloClientReady(true)
        }
    }, [client])

    useEffect(() => {
        const isWebReady = isWeb && isApolloClientReady && fontsLoaded
        const isNativeReady = isNative && fontsLoaded && isApolloClientReady && isOTAUpdateReady
        if (isWebReady) {
            setIsAppReady(true)
        }
        if (isNativeReady) {
            setIsAppReady(true)
        }
    }, [fontsLoaded, isApolloClientReady, isOTAUpdateReady])

    useEffect(() => {
        isNative && !__DEV__ && checkForUpdate()
    }, [])

    const onLayoutRootView = isNative
        ? useCallback(async () => {
              if (isAppReady) {
                  setTimeout(async () => {
                      await SplashScreen.hideAsync()
                  }, 1000)
              }
          }, [isAppReady])
        : noop

    if (!isAppReady) {
        if (isNative) {
            return null
        } else {
            return (
                <SafeAreaView style={{ flex: 1 }}>
                    <WebSplashScreen loading />
                </SafeAreaView>
            )
        }
    }

    return (
        <SafeAreaProvider>
            <GestureHandlerRootView style={{ flex: 1 }} onLayout={onLayoutRootView}>
                <NavigationContainer linking={linkingConfig(Dimensions.isMobile)}>
                    <ApolloProvider client={client}>
                        <ThemeProvider theme={Dimensions}>
                            <Navigator />
                            <StatusBar style="auto" />
                        </ThemeProvider>
                    </ApolloProvider>
                </NavigationContainer>
            </GestureHandlerRootView>
        </SafeAreaProvider>
    )
}
