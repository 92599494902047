import React from 'react'
import { StandardContainer } from 'commons/containers'
import privacyContent from 'data/privacyContent'
import MarkdownContent from './MarkdownContent'
import { FooterBar } from 'components/FooterBar'

const PrivacyPage: React.FC = () => {
    return (
        <StandardContainer>
            <MarkdownContent>{privacyContent}</MarkdownContent>
            <FooterBar />
        </StandardContainer>
    )
}

export default PrivacyPage
