import React, { useState, useEffect } from 'react'
import { View, TouchableOpacity } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/core'
import {
    ScreenContainer,
    ContentContainer,
    RowContainer,
    FormBottomRowToColumnContainer,
    MAX_FORM_WIDTH,
} from 'commons/containers'
import { ContinueButton } from 'commons/components/ContinueButton'
import { isWeb } from '@constants'
import { StyledInputField } from 'commons/components/InputField'
import VectorIcon from 'commons/components/VectorIcon'
import { iconHitSlop, isNative } from '@constants'
import { Heading, SubHeading, Paragraph } from 'typography'
import { useDimensions } from 'dimensions'
import colors from 'colors'
import CustomImage from 'components/CustomImage'
import { CREATE_REVIEW } from 'apollo/mutations'
import { createReview } from 'actions'
import * as screenNames from 'screenNames'
import { GET_USER } from 'apollo/queries'
import { getCurrentUser, getClass, getExistingReviews } from 'apollo/selectors'
import { useMutation } from 'apollo-augmented-hooks'
import RateIcon from 'commons/components/RateIcon'
import styled from 'styled-components/native'
import useNotifications from 'hooks/useNotifications'
import { FooterBar } from 'commons/components/FooterBar'

const emptyObj = {}

export default function TraineeLeaveReview() {
    const { id: userID, firstName, pushToken = '' } = getCurrentUser()
    const navigation = useNavigation()
    const { isMobile, isDesktop } = useDimensions()
    const route = useRoute()
    const { params = emptyObj } = route
    const { instructorClass: classFromRoute = emptyObj, classID = '', fromNotifications = false } = params || emptyObj
    const [instructorClass, setInstructorClass] = useState(classFromRoute)

    const classFromID = getClass({
        classID,
        onCompleted: () => {
            setReviewObj({ ...reviewObj, instructorID: classFromID?.instructor?.id })
            setInstructorClass(classFromID)
        },
    })

    const { existingReview } = getExistingReviews({ userID, instructorID: classFromID?.instructor?.id, classID })
    const { name, banner, defaultBannerIndex } = instructorClass || emptyObj

    const [rating, setRating] = useState(0)
    const [review, setReview] = useState('')
    const [actionLoading, setActionLoading] = useState(false)
    const [reviewSubmitted, setReviewSubmitted] = useState(false)
    const [reviewObj, setReviewObj] = useState({
        review: '',
        rating: 0,
        userID: userID,
        instructorID: instructorClass?.instructor?.id,
    })
    const setReviewText = value => setReviewObj({ ...reviewObj, review: value })
    const setReviewVal = value => setReviewObj({ ...reviewObj, rating: value })

    const {
        notificationTypes: { TRAINEE_LEFT_REVIEW, LEAVE_CLASS_REVIEW },
        handleCreateNotification,
    } = useNotifications()

    const [createReviewStatus, setCreateReviewStatus] = useState({ actionSuccess: false, statusMessage: '' })
    const { actionSuccess: createReviewSuccess } = createReviewStatus

    const createReviewMutation = useMutation(CREATE_REVIEW, {
        onCompleted: () => {
            updateCreateReviewStatus(true)
        },
        onError: () => updateCreateReviewStatus(false),
        refetchQueries: [
            {
                query: GET_USER,
                variables: { id: userID },
            },
        ],
    })

    function updateCreateReviewStatus(actionSuccess) {
        const statusMessage = actionSuccess ? 'Successfully submitted review' : 'Error submitting review'
        setTimeout(() => {
            setActionLoading(false)
            setCreateReviewStatus({ actionSuccess, statusMessage })
            if (actionSuccess) setReviewSubmitted(true)
        }, 1000)
    }

    async function handleLeaveReview() {
        if (!userID) {
            setCreateReviewStatus({ actionSuccess: false, statusMessage: `Error submitting review` })
        } else {
            setActionLoading(true)
            const { review = '', rating, instructorID } = reviewObj
            await createReview({ review, rating, userID, instructorID, classID, createReviewMutation })
            const content = {
                title: `${firstName} left you a review.`,
                body: review ?? '',
                data: JSON.stringify({ review, rating }),
            }
            await handleCreateNotification({ userID: instructorID, content, type: TRAINEE_LEFT_REVIEW })
        }
    }

    function handleReviewText(value) {
        setReview(value)
        setReviewText(value)
    }

    function handleBrowseClasses() {
        navigation.navigate(screenNames.TRAINEE_HOME_SCREEN)
    }

    async function handlePromptReview() {
        const content = {
            title: 'Leave a Review!',
            body: `You did it! Book another class with ${instructorClass?.instructor?.username}`,
            data: JSON.stringify({ classID, fromNotifications: true }),
        }
        await handleCreateNotification({
            userID,
            pushToken,
            content,
            type: LEAVE_CLASS_REVIEW,
            isScheduledNotification: true,
        })

        handleBrowseClasses()
    }

    useEffect(() => {
        if (existingReview?.id) {
            const { rating = 0 } = existingReview
            setReviewSubmitted(true)
            setRating(rating)
        }
    }, [existingReview])

    const hasLeftReview = rating !== 0 || review !== ''
    const imageStyle = { height: isMobile ? 175 : 250, width: '100%' }
    const starSize = 35
    return (
        <ScreenContainer>
            <BannerContainer>
                <CustomImage resizeMode="cover" imgKey={banner?.key} style={imageStyle} />
                {!fromNotifications && (
                    <ExitReviewIcon
                        hitSlop={iconHitSlop}
                        onPress={reviewSubmitted ? handleBrowseClasses : handlePromptReview}
                    >
                        <VectorIcon family="Entypo" name="cross" size={30} color="black" />
                    </ExitReviewIcon>
                )}
            </BannerContainer>
            <ContentContainer buttonPaddingBottom>
                {reviewSubmitted && (
                    <ThanksForSubmittingContainer>
                        <Heading isMostBold textAlign="center">
                            Thanks for submitting!
                        </Heading>
                    </ThanksForSubmittingContainer>
                )}
                <ReviewContentContainer reviewSubmitted={reviewSubmitted}>
                    {reviewSubmitted ? (
                        <RowContainer
                            style={{
                                marginTop: 30,
                                marginBottom: 30,
                                alignSelf: 'center',
                            }}
                        >
                            {[1, 2, 3, 4, 5].map(index => {
                                return (
                                    <VectorIcon
                                        family="FontAwesome"
                                        name="star"
                                        color={rating >= index ? colors.homebodyTurquoise : colors.grayContinue}
                                        size={starSize}
                                        key={index}
                                        style={{ marginRight: 5 }}
                                    />
                                )
                            })}
                        </RowContainer>
                    ) : (
                        <View style={{ width: '100%' }}>
                            <Heading isMostBold marginBottom={isDesktop ? 5 : 10} marginTop={isDesktop ? 60 : 30}>
                                Review Your Class
                            </Heading>
                            <SubHeading isMostBold marginBottom={isDesktop ? 20 : 10}>
                                {name}
                            </SubHeading>
                            <Paragraph color={colors.subTextGray}>How did you enjoy your class?</Paragraph>
                            <RowContainer style={{ marginTop: 20, marginBottom: 20 }}>
                                {[1, 2, 3, 4, 5].map(index => {
                                    return (
                                        <RateIcon
                                            key={index}
                                            index={index}
                                            rating={rating}
                                            size={starSize}
                                            onPress={() => {
                                                setRating(index)
                                                setReviewVal(index)
                                            }}
                                        />
                                    )
                                })}
                            </RowContainer>

                            <StyledInputField
                                style={{ height: 150, paddingTop: 15 }}
                                multiline={true}
                                placeholder="start typing here..."
                                onChangeText={handleReviewText}
                            />
                        </View>
                    )}
                </ReviewContentContainer>
            </ContentContainer>
            <FormBottomRowToColumnContainer
                alignSelf="flex-start"
                flexDirection="column"
                isCentered={isNative}
                style={{ marginBottom: 25, justifyContent: 'flex-start' }}
            >
                <ContinueButton
                    nonHover
                    //Apologies for the hardcoded width, the webContentContainer isn't getting along well with flex
                    color={hasLeftReview ? colors.homebodyTurquoise : colors.grayContinue}
                    disabled={!hasLeftReview}
                    apolloActionStatus={createReviewStatus}
                    actionLoading={actionLoading}
                    onPress={reviewSubmitted ? handleBrowseClasses : handleLeaveReview}
                    text={reviewSubmitted ? 'Browse classes' : 'Leave Review'}
                />
            </FormBottomRowToColumnContainer>
            {isWeb && <FooterBar />}
        </ScreenContainer>
    )
}

const ThanksForSubmittingContainer = styled(View)`
    width: 100%;
    margin-top: ${({ theme }) => (theme.isDesktop ? '35%' : '25%')};
`
const BannerContainer = styled(View)`
    width: ${({ theme }) => (theme.isDesktop ? MAX_FORM_WIDTH : '100%')}
    justify-content: center;
    align-items: center;
`
const ReviewContentContainer = styled(View)`
    width: 100%;
    flex-direction: column;
    align-items: ${props => (props.reviewSubmitted ? 'center' : 'flex-start')};
`

const ExitReviewIcon = styled(TouchableOpacity)`
    position: absolute;
    top: ${({ theme }) => (theme.isDesktop ? '15%' : '25%')};
    right: ${({ theme }) => (theme.isDesktop ? '5%' : '7.5%')};
    align-self: flex-end;
`
