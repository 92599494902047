import React, { useState, useEffect } from 'react'
import { Text, TouchableOpacity, ActivityIndicator } from 'react-native'
import styled from 'styled-components/native'
import colors from 'colors'
import { Hoverable, Pressable } from 'react-native-web-hover'
import { ButtonText } from 'typography'
import { LinearGradient } from 'expo-linear-gradient'
import { MAX_FORM_WIDTH } from 'commons/containers'
const gradientStart = { x: 0.15, y: 0 }
const gradientEnd = { x: 0.85, y: 0 }
const gradientColors = [colors.homebodyTurquoise, colors.homebodyGreen]
export const StyledContinueButton = styled.TouchableOpacity`
    flex-direction: column;
    width: ${props => props.width || '100%'};
    height: ${props => (props.theme.isMobile ? '60px' : '60px')};
    border-radius: 16px;
    background-color: ${props => props.color || colors.blueContinue};
    justify-content: center;
    align-items: center;
`
const StyledLinearGradient = styled(LinearGradient)`
    width: ${props => props.width || '100%'};
    height: ${props => (props.theme.isMobile ? '60px' : '56px')};
    border-radius: 16px;
    background-color: ${props => props.color || colors.blueContinue};
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
const StyledActivityIndicator = styled(ActivityIndicator)`
    width: ${props => props.width || '100%'};
    height: ${props => (props.theme.isMobile ? '60px' : '60px')};
    margin-right: 10px;
`
export const ContinueButton = ({
    onPress,
    text,
    disabled,
    color,
    isGradient = false,
    style = {},
    buttonTextStyle = {},
    messageStyle = {},
    nonHover = false,
    actionLoading = false,
    apolloActionStatus = { actionSuccess: false, statusMessage: '' },
}) => {
    const [showStatusMessage, setShowStatusMessage] = useState(true)
    const { actionSuccess, statusMessage } = apolloActionStatus

    useEffect(() => {
        let timer
        if (statusMessage) {
            if (!showStatusMessage) {
                setShowStatusMessage(true)
            }
            timer = setTimeout(() => {
                setShowStatusMessage(false)
            }, 3000)
        }
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        }
    }, [statusMessage, actionSuccess])

    if (isGradient) {
        return (
            <Hoverable>
                {({ hovered }) => (
                    <TouchableOpacity
                        onPress={onPress}
                        disabled={disabled}
                        color={hovered && !disabled ? 'black' : color}
                    >
                        <StyledLinearGradient
                            start={gradientStart}
                            end={gradientEnd}
                            colors={gradientColors}
                            style={style}
                        >
                            <ButtonText style={buttonTextStyle}>{text}</ButtonText>
                        </StyledLinearGradient>
                    </TouchableOpacity>
                )}
            </Hoverable>
        )
    } else {
        return (
            <>
                {!!statusMessage && showStatusMessage && (
                    <ActionStatusText
                        style={messageStyle}
                        paddingBottom={10}
                        color={actionSuccess ? colors.homebodyTurquoise : 'red'}
                    >
                        {statusMessage}
                    </ActionStatusText>
                )}
                <Hoverable>
                    {({ hovered }) => (
                        <StyledContinueButton
                            onPress={onPress}
                            disabled={disabled}
                            style={style}
                            color={!nonHover && hovered && !disabled ? 'black' : color}
                        >
                            {actionLoading ? (
                                <StyledActivityIndicator animating={actionLoading} size="large" color="white" />
                            ) : (
                                <ButtonText style={buttonTextStyle}>{text}</ButtonText>
                            )}
                        </StyledContinueButton>
                    )}
                </Hoverable>
            </>
        )
    }
}

const ActionStatusText = styled(ButtonText)`
    align-self: center;
`
