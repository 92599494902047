export const HOST_EVENTS = {
    HOST_BE_RIGHT_BACK: 'HOST_BE_RIGHT_BACK',
    HOST_HIDE_VIDEO: 'HOST_HIDE_VIDEO',
    HOST_SHOW_VIDEO: 'HOST_SHOW_VIDEO',
    HOST_MUTE_AUDIO: 'HOST_MUTE_AUDIO',
    HOST_UNMUTE_AUDIO: 'HOST_UNMUTE_AUDIO',
    HOST_END_CLASS: 'HOST_END_CLASS',
    HOST_END_STREAM: 'HOST_END_STREAM',
    HOST_CLASS_COMPLETED: 'HOST_CLASS_COMPLETED',
    HOST_SEND_CHANNEL_MESSAGE: 'HOST_SEND_CHANNEL_MESSAGE',
    HOST_SEND_PEER_MESSAGE: 'HOST_SEND_PEER_MESSAGE',
    HOST_JOIN_STREAM_ROOM: 'HOST_JOIN_STREAM_ROOM',
    HOST_DISPLAY_STREAM_OVERLAY_MESSAGE: 'HOST_DISPLAY_STREAM_OVERLAY_MESSAGE',
    HOST_MESSAGE_FROM_AUDIENCE: 'HOST_MESSAGE_FROM_AUDIENCE',
    HOST_MESSAGE_FROM_AUDIENCE_MEMBER: 'HOST_MESSAGE_FROM_AUDIENCE_MEMBER',
    HOST_TRAINEE_JOINED: 'HOST_TRAINEE_JOINED',
    HOST_TRAINEE_LEFT: 'HOST_TRAINEE_LEFT',
    HOST_UNLOCK_STREAM_ROOM: 'HOST_UNLOCK_STREAM_ROOM',
}

export const AUDIENCE_EVENTS = {
    AUDIENCE_BE_RIGHT_BACK: 'AUDIENCE_BE_RIGHT_BACK',
    AUDIENCE_HOST_HIDE_VIDEO: 'AUDIENCE_HOST_HIDE_VIDEO',
    AUDIENCE_HOST_MUTE_AUDIO: 'AUDIENCE_HOST_MUTE_AUDIO',
    AUDIENCE_SET_HOST_UID: 'AUDIENCE_SET_HOST_UID',
    AUDIENCE_SUBSCRIBE_TO_HOST_TRACKS: 'AUDIENCE_SUBSCRIBE_TO_HOST_TRACKS',
    AUDIENCE_UNSUBSCRIBE_FROM_HOST_TRACKS: 'AUDIENCE_UNSUBSCRIBE_FROM_HOST_TRACKS',
    AUDIENCE_MESSAGE_FROM_HOST: 'AUDIENCE_MESSAGE_FROM_HOST',
    AUDIENCE_STREAM_STARTED: 'AUDIENCE_STREAM_STARTED',
    AUDIENCE_STREAM_ENDED: 'AUDIENCE_STREAM_ENDED',
    AUDIENCE_TOGGLE_STREAM_AUDIO: 'AUDIENCE_TOGGLE_STREAM_AUDIO',
    AUDIENCE_LEAVE_STREAM: 'AUDIENCE_LEAVE_STREAM',
    AUDIENCE_SEND_MESSAGE_TO_HOST: 'AUDIENCE_SEND_MESSAGE_TO_HOST',
    AUDIENCE_STREAM_ROOM_UNLOCKED: 'AUDIENCE_STREAM_ROOM_UNLOCKED',
    AUDIENCE_SEND_HOST_INFO: 'AUDIENCE_SEND_HOST_INFO',
}

export const CLASS_EVENTS = {
    CLASS_ENDED_BY_HOST: 'CLASS_ENDED_BY_HOST',
    CLASS_UPDATE_INTERVAL: 'CLASS_UPDATE_INTERVAL',
    CLASS_COMPLETE: 'CLASS_COMPLETE',
    CLASS_UNLOCK_ROOM: 'CLASS_UNLOCK_ROOM',
    CLASS_STARTED: 'CLASS_STARTED',
    CLASS_START_COUNTDOWN: 'CLASS_START_COUNTDOWN',
    CLASS_LATE_JOIN: 'CLASS_LATE_JOIN',
}
