import React from 'react'
import { TouchableOpacity, Text, StyleSheet } from 'react-native'
import styled from 'styled-components/native'
import UploadIcon from 'icons/UploadIcon'
import DeleteIcon from 'icons/DeleteIcon'
import Colors from 'colors'
import { ShortButtonText } from 'typography'
const defaultUploadButtonStyle = {
    marginLeft: 20,
    padding: 15,
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 5,
    borderColor: Colors.inputFieldGray,
    borderWidth: StyleSheet.hairlineWidth,
}

export default ({
    style,
    disabled,
    onPress,
    uploaded,
    successfulUpload,
    customButtonText = '',
    showIcon = true,
    isWhiteText,
}) => {
    const buttonText = customButtonText ? customButtonText : successfulUpload || uploaded ? 'Uploaded' : 'Pick file'
    return (
        <TouchableOpacity disabled={disabled} onPress={onPress} style={style || defaultUploadButtonStyle}>
            <ShortButtonText style={{ padding: 5, marginRight: 5, color: isWhiteText ? 'white' : 'black' }}>
                {buttonText}
            </ShortButtonText>
            {showIcon && <>{uploaded ? <DeleteIcon onPress={onPress} size={25} /> : <UploadIcon size={25} />}</>}
        </TouchableOpacity>
    )
}
