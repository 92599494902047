import React, { useState, useEffect } from 'react'
import colors from 'colors'
import { Headline, HeaderText } from 'typography'
import styled from 'styled-components/native'
// nav
import { INSTRUCTOR_APPLICATION } from 'screenNames'
// state/selectors
import { useReactiveVar } from '@apollo/client'
import { instructorApplicationForm } from 'apollo/cache'
import { getInstructorAppNavigation, getCurrentUser, getTrainerApplication } from 'selectors'
import { useMutation } from 'apollo-augmented-hooks'
import { CREATE_FILE, DELETE_FILE, UPDATE_TRAINER_APPLICATION } from 'apollo/mutations'
import { updateTrainerApplication, reviewInstructorApplication } from 'actions'
import { updateUser } from '@mutations'
import { LIST_USERS, LIST_TRAINER_APPLICATION } from 'apollo/queries'
// form
import useReactiveForm from 'hooks/useReactiveForm'
import { instructorAppFieldNames, instructorAppFieldErrors } from 'formFieldInfo'
import { instructorAppValidationFunc } from 'validations'
import { APPLICATION_STATUS, isWeb } from '@constants'
// components
import { AppFormContainer, ScreenContainer, ContentContainer } from 'commons/containers'
import { NavigationTabs } from 'components/NavigationTabs'
import FormButtons from 'components/FormButtons'
import { View } from 'react-native'
import PersonalDetails from 'screens/InstructorApplication/PersonalDetails'
import Certifications from 'screens/InstructorApplication/Certifications'
import Socials from 'screens/InstructorApplication/Socials'
import Specializations from 'screens/InstructorApplication/Specializations'
import BusinessRegistration from 'screens/InstructorApplication/BusinessRegistration'
import GymStudioDetails from 'screens/InstructorApplication/GymStudioDetails'
import ReviewAndSubmit from 'screens/InstructorApplication/ReviewAndSubmit'
import { FooterBar } from 'commons/components/FooterBar'
const { APPROVED, DENIED, PENDING } = APPLICATION_STATUS
const {
    NAVIGATOR,
    START_TRAINER_APP,
    PERSONAL_DETAILS,
    SOCIALS,
    SPECIALIZATIONS,
    CERTIFICATIONS,
    GYM_STUDIO_DETAILS,
    BUSINESS_REGISTRATION,
    REVIEW_AND_SUBMIT,
    ADMIN_REVIEW,
} = INSTRUCTOR_APPLICATION

const noop = () => {}
const emptyArr = []
const emptyObj = {}

const ApplicationForm = ({ navigation, route }) => {
    const {
        index: indexString,
        trainerApplicationID: navTrainerApplicationID,
        isReviewing: isReviewingString,
    } = route.params || emptyObj
    const index = parseInt(indexString)
    const isReviewing = isReviewingString == true // the param is a string so we force it to be a boolean, "false" evaluates to truthiness. isn't that fun?
    const { id: userID, userType } = getCurrentUser()
    const form = useReactiveVar(instructorApplicationForm)
    const { isTrainer, isOwner, isInfluencer } = form
    const trainerApplicationID = isReviewing ? navTrainerApplicationID : userID
    const existingApplication = getTrainerApplication({
        trainerApplicationID,
        onCompleted: () => {
            if (!navTrainerApplicationID && !form?.id) {
                instructorApplicationForm({ ...existingApplication })
            }
        },
    })

    const { nav, currentPage, prevPage, nextPage } = getInstructorAppNavigation({ index, isTrainer, isOwner })
    const isAdmin = userType === 'ADMIN' || __DEV__
    const updateTrainerApplicationMutation = useMutation(UPDATE_TRAINER_APPLICATION)
    const updateUserMutation = useMutation(updateUser)
    const createFileMutation = useMutation(CREATE_FILE)
    const deleteFileMutation = useMutation(DELETE_FILE)
    const [certificationUploads, setCertificationUploads] = useState([])
    const [businessRegistrationUploads, setBusinessRegistrationUploads] = useState([])

    const evaluateUsernameQueryResponse = data => !(data?.listUsers?.items?.length ?? false)
    const reactiveFormProps = useReactiveForm({
        reactiveForm: instructorApplicationForm,
        currentPage,
        fieldNames: instructorAppFieldNames,
        fieldErrors: instructorAppFieldErrors,
        validationFunc: instructorAppValidationFunc,
        fieldQueryValidation: {
            fieldName: instructorAppFieldNames.username,
            query: LIST_USERS,
            getFilter: () => ({ [instructorAppFieldNames.username]: { eq: instructorApplicationForm()?.username } }),
            evaluateQueryResponse: evaluateUsernameQueryResponse,
            queryErrorObj: { showError: true, error: 'This username is taken' },
        },
    })
    const { isValid, updateForm, handleInvalidPage } = reactiveFormProps

    useEffect(() => {
        updateForm({ fieldName: 'id', value: trainerApplicationID })
    }, [trainerApplicationID])

    const goToStartTrainerApp = () => navigation.navigate(NAVIGATOR, { screen: START_TRAINER_APP })

    useEffect(() => {
        const existingItems = instructorApplicationForm()?.files?.items || emptyArr

        let items = { items: [...existingItems] }
        if (currentPage == CERTIFICATIONS && certificationUploads?.length) {
            items = {
                ...items?.items,
                items: [...certificationUploads],
            }
        } else {
            if (currentPage == BUSINESS_REGISTRATION && businessRegistrationUploads?.length) {
                items = {
                    items: [...items?.items, ...businessRegistrationUploads],
                }
            }
        }
        updateForm({ ...instructorApplicationForm(), fieldName: instructorAppFieldNames.files, value: items })
    }, [certificationUploads, businessRegistrationUploads])

    const updateStep = ({ isStartingOver = false, isSaving = false } = {}) => {
        const isSubmit = currentPage === REVIEW_AND_SUBMIT && !isReviewing
        if (!isValid && !isSaving) return handleInvalidPage()
        if (isSaving) {
            goToStartTrainerApp()
            if (isSubmit) return
        }
        if (isValid) {
            const { _version, id: trainerAppID } = existingApplication
            updateTrainerApplication({
                updateTrainerApplicationMutation,
                createFileMutation,
                deleteFileMutation,
                trainerApplicationID,
                _version,
                userID,
                index,
                form,
                navigation,
                currentPage,
                isSubmit,
                isReviewing,
                isStartingOver,
                certificationUploads,
                businessRegistrationUploads,
            })
        }
        if (!isSubmit) {
            navigation.navigate(nextPage, { index: index + 1 })
        } else {
            updateForm({ fieldName: instructorAppFieldNames?.submitted, value: true })
        }
    }
    const approveApplication = approved => {
        updateForm({ fieldName: instructorAppFieldNames?.approved, value: approved ? APPROVED : DENIED })
        reviewInstructorApplication({ trainerApplicationID, updateTrainerApplicationMutation, updateUserMutation })
        return navigation.navigate(ADMIN_REVIEW)
    }
    let secondButtonOnPress = () => updateStep({ isSaving: true })
    let firstButtonOnPress = updateStep
    let secondButtonText = 'Save for later'
    let firstButtonText = currentPage === REVIEW_AND_SUBMIT ? 'Submit' : 'Continue'
    if (isReviewing && isAdmin) {
        secondButtonOnPress = () => approveApplication(false)
        firstButtonOnPress = () => approveApplication(true)
        secondButtonText = 'Deny'
        firstButtonText = 'Approve'
    }

    return (
        <ScreenContainer>
            <HeaderContainer isInfluencer={isInfluencer}>
                <HeadlineContainer>
                    <Headline isBold>HOMEBODY Creator Application</Headline>
                </HeadlineContainer>
                <NavigationTabs
                    title={'HOMEBODY Application'}
                    navigation={navigation}
                    screens={nav}
                    index={index}
                    submitted={form?.submitted}
                    currentPage={currentPage}
                    prevPage={prevPage}
                />
            </HeaderContainer>
            <ContentContainer buttonPaddingBottom>
                <HeaderText isMostBold paddingTop={10} paddingBottom={25}>
                    {form?.submitted ? 'Application Submitted' : currentPage}
                </HeaderText>
                {currentPage == PERSONAL_DETAILS && <PersonalDetails reactiveFormProps={reactiveFormProps} />}
                {currentPage == SOCIALS && <Socials socials={form?.socials} reactiveFormProps={reactiveFormProps} />}
                {currentPage == SPECIALIZATIONS && <Specializations reactiveFormProps={reactiveFormProps} />}
                {currentPage == CERTIFICATIONS && (
                    <Certifications
                        files={existingApplication?.files}
                        reactiveFormProps={reactiveFormProps}
                        certificationUploads={certificationUploads}
                        setCertificationUploads={setCertificationUploads}
                    />
                )}
                {currentPage == GYM_STUDIO_DETAILS && <GymStudioDetails reactiveFormProps={reactiveFormProps} />}
                {currentPage == BUSINESS_REGISTRATION && (
                    <BusinessRegistration
                        files={existingApplication?.files}
                        reactiveFormProps={reactiveFormProps}
                        businessRegistrationUploads={businessRegistrationUploads}
                        setBusinessRegistrationUploads={setBusinessRegistrationUploads}
                    />
                )}
                {currentPage == REVIEW_AND_SUBMIT && (
                    <ReviewAndSubmit
                        updateStep={updateStep}
                        existingApplication={existingApplication}
                        navigation={navigation}
                        isReviewing={isReviewing && isAdmin}
                    />
                )}
            </ContentContainer>
            {(!form.submitted || isAdmin) && (
                <FormButtons
                    secondButtonOnPress={secondButtonOnPress}
                    firstButtonOnPress={firstButtonOnPress}
                    secondButtonText={secondButtonText}
                    firstButtonText={firstButtonText}
                    secondButtonColor={colors.grayContinue}
                    firstButtonColor={colors.blueContinue}
                    isReviewandSubmit={true}
                    buttonTextStyle={{ fontSize: 13.11 }}
                    height={36.72}
                    width={238}
                />
            )}
            {isWeb && <FooterBar />}
        </ScreenContainer>
    )
}

const HeadlineContainer = styled(View)`
    margin-top: 30px;
    margin-bottom: -25px;
`
const HeaderContainer = styled(View)`
    width: ${props => (props.isInfluencer ? '80%' : '45%')};
    padding-left: 5%;
`
export default ApplicationForm
