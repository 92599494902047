import React, { useEffect } from 'react'
import { useNavigation, useIsFocused } from '@react-navigation/native'
import { View } from 'react-native'
import styled from 'styled-components/native'
import { HeaderText, SubHeaderText } from 'typography'
import { ScreenContainer, ContentContainer } from 'commons/containers'
import TraineeBookedClasses from 'components/TraineeBookedClasses'
import { EXPLORE_NAVIGATOR, EXPLORE, CREDIT_PACKAGES, TRAINEE_PROFILE, TRAINEE_SETTINGS } from 'screenNames'
import { getCurrentUser } from 'apollo/selectors'
import { TraineeWebBannerHeader } from 'commons/components/TraineeWebBannerHeader'
import UserAvatar from 'commons/components/UserAvatar'
import colors from 'colors'
import { ContinueButton } from 'commons/components/ContinueButton'
import { FooterBar } from 'commons/components/FooterBar'
import { stripeAPI } from 'src/actions'
import { STRIPE_API_ROUTES } from '@constants'
const { LIST_SUBSCRIPTIONS, CANCEL_SUBSCRIPTION } = STRIPE_API_ROUTES

export default function TraineeAccount() {
    const navigation = useNavigation()
    const isFocused = useIsFocused()
    const { profile, credits = 0, refetch: refetchCurrentUser, customerID } = getCurrentUser()
    const { firstName, lastName } = profile || {}

    const goToMyProfile = () => navigation.navigate(TRAINEE_PROFILE, { initial: false })
    const goToSettings = () => navigation.navigate(TRAINEE_SETTINGS, { initial: false })
    const goToPackages = () => navigation.navigate(CREDIT_PACKAGES)
    const goToExplore = () =>
        navigation.navigate(EXPLORE_NAVIGATOR, { screen: EXPLORE, params: { byInstructor: false } })

    useEffect(() => {
        if (refetchCurrentUser) {
            refetchCurrentUser()
        }
    }, [isFocused])

    return (
        <ScreenContainer>
            <TraineeWebBannerHeader />
            <AvatarContainer>
                <UserAvatar size={307} />
            </AvatarContainer>
            <ContentContainer isFluid>
                <HeaderText isMostBold isLargeHeader marginTop={80}>
                    {firstName} {lastName}
                </HeaderText>
                <CreditsText isMostBold isLargeSubheader marginTop={37}>
                    Credits: {credits}
                </CreditsText>
                <BuyAndBrowseContainer>
                    <BuyCreditsContainer>
                        <BuyCreditsText marginTop={48} isMostBold color={colors.subTextGray}>
                            Buy Credits - Take Classes
                        </BuyCreditsText>
                        <BuyCreditsButton nonHover text="Buy Credits" onPress={goToPackages} />
                        <MyAccountButton nonHover text="My Account" onPress={goToMyProfile} />
                    </BuyCreditsContainer>
                    <BookClassesContainer>
                        <BookClassesText marginTop={48} isMostBold color={colors.subTextGray}>
                            Looking for something to book?
                        </BookClassesText>
                        <BrowseClassesButton
                            text="Browse Classes"
                            onPress={goToExplore}
                            buttonTextStyle={{ color: colors.subTextGray }}
                        />
                        <SettingsButton
                            text="Settings"
                            onPress={goToSettings}
                            buttonTextStyle={{ color: colors.subTextGray }}
                        />
                    </BookClassesContainer>
                </BuyAndBrowseContainer>
                <ClassesContainer>
                    <TraineeBookedClasses headingText="My Upcoming Classes" />
                </ClassesContainer>
            </ContentContainer>
            <FooterBar />
        </ScreenContainer>
    )
}

const CreditsText = styled(SubHeaderText)``
const BuyCreditsText = styled(SubHeaderText)``
const BookClassesText = styled(SubHeaderText)``
const AvatarContainer = styled(View)`
    position: absolute;
    right: 79px;
    top: 195px;
    z-index: 2;
`
const ClassesContainer = styled(View)`
    margin-top: 92px;
`
const BuyCreditsButton = styled(ContinueButton)`
    margin-top: 23px;
    height: 45px;
`
const MyAccountButton = styled(BuyCreditsButton)``
const BrowseClassesButton = styled(BuyCreditsButton)`
    background-color: ${colors.white};
    border-width: 1px;
    border-color: ${colors.grayContinue};
`
const SettingsButton = styled(BrowseClassesButton)``
const BuyAndBrowseContainer = styled(View)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`
const BuyCreditsContainer = styled(View)`
    display: flex;
    flex-direction: column;
    width: 30%;
`
const BookClassesContainer = styled(BuyCreditsContainer)`
    margin-left: 116px;
`
