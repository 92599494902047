import React, { useState, useEffect } from 'react'
import { useIsFocused } from '@react-navigation/native'
import { createApplicationReviewPickerItems } from 'helpers'
import { USER_TYPES, APPLICATION_STATUS } from '@constants'
import * as queries from 'apollo/queries'
import { View } from 'react-native'
import DropDownPicker from 'components/DropDownInput'
import * as screenNames from 'screenNames'

import { FieldContainer, StandardContainer } from 'commons/containers'
import { ContinueButton } from 'components/ContinueButton'
import {
    getCurrentUser,
    getInstructorAppNavigation,
    getTrainerApplicationAsync,
    getApplicationsToReview,
} from 'selectors'
import { instructorApplicationForm } from 'apollo/cache'
const emptyArr = []

export default function AdminInstructorApplicationReview({ navigation }) {
    const { userType } = getCurrentUser() || {}
    const [reviewApplicationID, setReviewApplicationID] = useState('')
    const [applicationsToReview, setApplicationsToReview] = useState(emptyArr)
    const [applications, setApplications] = useState(emptyArr)
    const [applicationsToken, setApplicationsToken] = useState(null)
    const isAdmin = userType === USER_TYPES.ADMIN
    if (!__DEV__ && !isAdmin) return null

    const isFocused = useIsFocused()
    const {
        trainerApplications: applicationsResult,
        nextToken: initialApplicationsToken,
        fetchMore: fetchMoreApplications,
        refetch: refetchApplicationsToReview,
    } = getApplicationsToReview({
        onCompleted: () => {
            if (applications?.length === 0 && !applicationsToken) {
                setApplicationsToken(initialApplicationsToken)
                setApplications(applicationsResult)
            }
        },
    })

    async function handleFetchMoreApplications() {
        if (applicationsToken) {
            const fetchMoreResult = await fetchMoreApplications({ variables: { nextToken: applicationsToken } })
            const {
                data: {
                    listTrainerApplications: { items: moreApplications = emptyArr, nextToken: token = null },
                },
            } = fetchMoreResult ?? emptyObj
            const allApplications =
                applications?.length > 0 ? [...applications, ...moreApplications] : [...moreApplications]
            setApplications(allApplications)
            setApplicationsToken(token)
        }
    }

    useEffect(() => {
        if (applicationsToken !== null) {
            handleFetchMoreApplications()
        }
    }, [applicationsToken])

    const handleOnChange = ({ value }) => {
        if (value == null) return
        setReviewApplicationID(value)
    }

    async function handleReviewApplication() {
        const application = await getTrainerApplicationAsync({ id: reviewApplicationID })
        instructorApplicationForm(application)
        const { isInfluencer, isTrainer, isOwner } = application
        const { nav, currentPage, prevPage, nextPage } = getInstructorAppNavigation({ index: 0, ...application })

        const reviewIndex = nav.length - 1
        navigation.navigate(screenNames.INSTRUCTOR_APPLICATION.REVIEW_AND_SUBMIT, {
            index: reviewIndex,
            trainerApplicationID: reviewApplicationID,
            isReviewing: true,
        })
    }

    useEffect(() => {
        if (isFocused) {
            setApplications(emptyArr)
            setApplicationsToken(null)
            setApplicationsToReview(emptyArr)
            refetchApplicationsToReview && refetchApplicationsToReview()
        }
    }, [isFocused])

    useEffect(() => {
        if (applications?.length > 0) {
            const reviewableApplications = createApplicationReviewPickerItems({ applications }) ?? emptyArr
            setApplicationsToReview(reviewableApplications)
        }
    }, [applications])

    return (
        <StandardContainer>
            <FieldContainer style={{ marginTop: 20 }}>
                <DropDownPicker
                    shouldPushContent={false}
                    placeholder={'Select an application'}
                    onChangeValue={value => handleOnChange(value)}
                    dropDownItems={applicationsToReview}
                />
                <View style={{ zIndex: -1 }}>
                    <ContinueButton
                        disabled={!reviewApplicationID}
                        style={{ marginTop: 20 }}
                        text={'Review Application'}
                        onPress={handleReviewApplication}
                    />
                </View>
            </FieldContainer>
        </StandardContainer>
    )
}
