import React from 'react'
import { View } from 'react-native'
import { FillContainer } from 'commons/containers'
import Waitlist from './Waitlist'
import BrowseClasses from './BrowseClasses'
import Features from './Features'
import Shop from './Shop'
import Transforming from './Transforming'
import CreatorExperience from './CreatorExperience'
import GetPaid from './GetPaid'
import GetInTouch from './GetInTouch'
import { FooterBar } from 'components/FooterBar'

export default function LandingPage() {
    return (
        <FillContainer>
            <Waitlist />
            <BrowseClasses />
            <Features />
            <Shop />
            <Transforming />
            <GetPaid />
            <GetInTouch />
            <FooterBar />
        </FillContainer>
    )
}
