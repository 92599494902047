import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import useCustomForm from 'hooks/useCustomForm'
import { signUpForm } from 'apollo/cache'
import { initialSignUpForm } from 'initialFormStates'
import { ContinueButton } from 'components/ContinueButton'
import colors from 'colors'
import { validateEmail } from 'helpers'
import { InputField, InputFieldRowSpacer } from 'components/InputField'
import TermsModal from 'components/TermsModal'
import * as screenNames from 'screenNames'
import { StandardContainer, FieldContainer, FieldRowContainer, HeaderTextWrapper } from 'commons/containers'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { LIST_USERS } from 'apollo/queries'
import { Inter500, HeaderText, SubHeaderText } from 'typography'
import { FooterBar } from 'commons/components/FooterBar'
import IconCheckBox from 'icons/IconCheckBox'
import { isWeb, SANITIZE_INPUT_TYPES } from '@constants'
import { useDimensions } from 'dimensions'

const fieldNames = {
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    password: 'password',
}

const fieldErrors = {
    firstName: 'First name is required',
    lastName: 'Last name is required',
    email: 'Must be a valid email',
    uniqueEmail: 'An account with that email already exists',
    password: 'Password must be at least 8 characters',
}
const validationFunc = ({ values }) => {
    return {
        firstName: !!values.firstName,
        lastName: !!values.lastName,
        email: validateEmail(values.email),
        password: values.password.length >= 8,
    }
}

const evaluateEmailQueryResponse = data => !(data?.listUsers?.items?.length ?? false)

const SignUpScreen = ({ navigation }) => {
    const { isMobileWeb } = useDimensions()
    const customFormProps = useCustomForm({
        initialValues: initialSignUpForm,
        fieldNames,
        fieldErrors,
        validationFunc,
        fieldQueryValidation: {
            fieldName: fieldNames.email,
            query: LIST_USERS,
            getFilter: values => ({ [fieldNames.email]: { eq: values.email } }),
            evaluateQueryResponse: evaluateEmailQueryResponse,
            queryErrorObj: { showError: true, error: fieldErrors.uniqueEmail },
        },
    })
    const { values, isValid, formIsFilled, handleInvalidPage } = customFormProps
    const [termsShown, setTermsShown] = useState(false)
    const [promptTerms, setPromptTerms] = useState(false)
    const [termsAgreed, setTermsAgreed] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showErrors, setShowErrors] = useState(false)
    const goToLogin = () => navigation.navigate(screenNames.LOGIN)
    async function validateForm() {
        if (isValid && termsAgreed) {
            signUpForm(values) //set the value of the reactiveVariable to the values locally stored in the form
            navigation.navigate(screenNames.VERIFICATION)
        } else {
            handleInvalidPage()
            setShowErrors(true)
            if (!termsAgreed) {
                setPromptTerms(true)
            }
        }
    }

    return (
        <>
            <StandardContainer>
                <HeaderTextWrapper>
                    <HeaderText>Sign up</HeaderText>
                    <SubHeaderText isSubtext>
                        {''}
                        Already a user?
                        <SubHeaderText onPress={goToLogin} isBold isSubtext>
                            {' '}
                            Login Now
                        </SubHeaderText>
                    </SubHeaderText>
                </HeaderTextWrapper>
                <FieldContainer>
                    <FieldRowContainer>
                        <InputField
                            shouldSanitizeInput
                            reactiveForm={false}
                            value={values?.firstName}
                            sanitizeRegex={SANITIZE_INPUT_TYPES.NO_SPACES}
                            fieldName={fieldNames.firstName}
                            fieldTitle={'First name'}
                            {...customFormProps}
                        />
                        <InputFieldRowSpacer />
                        <InputField
                            shouldSanitizeInput
                            reactiveForm={false}
                            value={values?.lastName}
                            sanitizeRegex={SANITIZE_INPUT_TYPES.NO_SPACES}
                            fieldName={fieldNames.lastName}
                            fieldTitle={'Last name'}
                            {...customFormProps}
                        />
                    </FieldRowContainer>
                    <InputField
                        shouldSanitizeInput
                        reactiveForm={false}
                        value={values?.email}
                        sanitizeRegex={SANITIZE_INPUT_TYPES.NO_SPACES}
                        fieldName={fieldNames.email}
                        fieldTitle={'Email'}
                        {...customFormProps}
                    />
                    <RowContainer style={{ flexDirection: 'row' }}>
                        <InputField
                            shouldSanitizeInput
                            sanitizeRegex={SANITIZE_INPUT_TYPES.NO_SPACES}
                            reactiveForm={false}
                            value={values?.password}
                            fieldName={fieldNames.password}
                            fieldTitle={'Password'}
                            secure={!showPassword}
                            {...customFormProps}
                        />
                        <MaterialCommunityIcons
                            onPress={() => setShowPassword(!showPassword)}
                            name={`eye-${showPassword ? '' : 'off-'}outline`}
                            color={'gray'}
                            size={20}
                            style={{ position: 'absolute', right: 20, bottom: 25 }}
                        />
                    </RowContainer>
                    <RowContainer>
                        <TermsText
                            style={{
                                paddingLeft: 5,
                                paddingRight: 5,
                                textAlign: 'center',
                                color: promptTerms ? 'red' : 'black',
                            }}
                        >
                            Terms & Conditions
                        </TermsText>
                        <IconCheckBox
                            style={{ alignSelf: 'center', marginLeft: 5 }}
                            onPress={
                                !termsShown
                                    ? () => {
                                          setPromptTerms(false)
                                          setModalVisible(!modalVisible)
                                      }
                                    : () => {
                                          setTermsAgreed(!termsAgreed)
                                          if (promptTerms) {
                                              setPromptTerms(false)
                                          }
                                      }
                            }
                            checked={termsAgreed}
                            color={{ unchecked: colors.starGray, checked: colors.homebodyTurquoise }}
                            size={20}
                        />
                    </RowContainer>
                    <ContinueButton
                        onPress={validateForm}
                        style={{ marginTop: 25, height: 56, width: 363, alignSelf: isWeb ? 'flex-start' : 'center' }}
                        text="Continue"
                        color={formIsFilled && termsShown ? colors.blueContinue : colors.grayContinue}
                    />
                    <TermsModal
                        setTermsShown={setTermsShown}
                        modalVisible={modalVisible}
                        setModalVisible={setModalVisible}
                        setTermsAgreed={setTermsAgreed}
                    />
                </FieldContainer>
            </StandardContainer>
            {isWeb && !isMobileWeb && <FooterBar />}
        </>
    )
}

export const TermsText = styled(Inter500)`
    font-size: 12px;
    line-height: 14px;
`

const RowContainer = styled(View)`
    flex-direction: row;
    align-items: center;
`

export default SignUpScreen
