import React from 'react'
import { useNavigation } from '@react-navigation/core'
import { View, Linking } from 'react-native'
import { useRoute } from '@react-navigation/core'
import { Inter900, Inter500, HeaderText, Heading } from 'styles/typography'
import { ScreenContainer, ContentContainer, RowContainer, FormBottomRowToColumnContainer } from 'commons/containers'
import { ContinueButton } from 'commons/components/ContinueButton'
import { FooterBar } from 'commons/components/FooterBar'
import colors from 'styles/colors'
import styled from 'styled-components/native'
import { getCurrentUser } from 'apollo/selectors'
import { CREDIT_PACKAGES, TRAINEE_ACCOUNT, PROFILE_NAVIGATOR } from 'screenNames'
import { useDimensions } from 'dimensions'
import { TraineeWebBannerHeader } from 'commons/components/TraineeWebBannerHeader'
import { isWeb } from '@constants'
const emptyObj = {}
const noop = () => {}

export default function MyCreditsForm() {
    const navigation = useNavigation()
    const { isMobile, isTablet } = useDimensions()
    const { params } = useRoute()
    const { isProfile = false } = params || emptyObj
    const { credits } = getCurrentUser()

    const goToPackages = () => navigation.navigate(CREDIT_PACKAGES)
    const goToProfile = () => navigation.navigate(PROFILE_NAVIGATOR, { screen: TRAINEE_ACCOUNT })

    return (
        <MyCreditsFormContainer>
            {!isMobile && !isTablet && <TraineeWebBannerHeader title="My Credits" />}
            <ContentContainer>
                {!isMobile && <TitleText isMostBold>My Credit Balance</TitleText>}
                <CreditsContainer>
                    <CreditsText isMostBold>Credits:&nbsp;</CreditsText>
                    <CreditsText isMostBold color={colors.homebodyTurquoise}>{`${credits ? credits : 0}`}</CreditsText>
                </CreditsContainer>
                <InfoContainer>
                    <InfoText>
                        Don’t worry! Unused credits roll over on a month to month basis.&nbsp;
                        <InfoText>
                            Learn about our credit system&nbsp;
                            <InfoText color={colors.homebodyTurquoise} onPress={noop}>
                                here.&nbsp;
                            </InfoText>
                        </InfoText>
                    </InfoText>
                </InfoContainer>
                {isMobile ? (
                    <FormBottomRowToColumnContainer alignSelf="center" flexDirection="column" isCentered>
                        <ContinueButton
                            nonHover
                            color={colors.homebodyTurquoise}
                            text={isProfile ? 'Return To Profile' : 'Buy Credits'}
                            onPress={isProfile ? goToProfile : goToPackages}
                        />
                    </FormBottomRowToColumnContainer>
                ) : (
                    <ContinueButton
                        nonHover
                        style={{ width: 300 }}
                        color={colors.homebodyTurquoise}
                        text={isProfile ? 'Return To Profile' : 'Buy Credits'}
                        onPress={isProfile ? goToProfile : goToPackages}
                    />
                )}
            </ContentContainer>
            {isWeb && <FooterBar />}
        </MyCreditsFormContainer>
    )
}

const MyCreditsFormContainer = styled(ScreenContainer)``

const TitleText = styled(HeaderText)`
    width: 100%;
    margin-top: ${({ theme }) => (theme.isMobile ? '9%' : '100px')};
    text-align: ${({ theme }) => (theme.isMobile ? 'center' : 'left')};
`
const CreditsContainer = styled(RowContainer)`
    ${({ theme }) => theme.isMobile && 'padding-horizontal: 26%; margin-top: 38%;'};
    ${({ theme }) => !theme.isMobile && 'margin-top: 9%; justify-content: flex-start;'};
`
const CreditsText = styled(Heading)`
    text-align: center;
    color: ${props => props.color || colors.black};
`
const InfoContainer = styled(View)`
    display: flex;
    flex-direction: column;
    padding-horizontal: ${({ theme }) => (theme.isMobile ? '3' : '0')}%;
    margin-top: ${({ theme }) => (theme.isMobile ? '20' : '5')}%;
    padding-bottom: ${({ theme }) => (theme.isMobile ? '65' : '12')}%;
`
const InfoText = styled(Inter500)`
    font-size: ${({ theme }) => (theme.isMobile ? '16' : '20')}px;
    line-height: ${({ theme }) => (theme.isMobile ? '20' : '25')}px;
    text-align: ${({ theme }) => (theme.isMobile ? 'center' : 'left')};
    color: ${props => props.color || colors.black};
`
