import React from 'react'
import { View } from 'react-native'
import { Inter900, Inter700, Inter500, TEXT_LINEAR_GRADIENT } from 'typography'
import colors from 'colors'

import styled from 'styled-components/native'
import { useDimensions } from 'dimensions'
import { CONTACT } from '../../constants/appInfo'

const ContactSupport: React.FC = () => {
    const { isMobile } = useDimensions()
    return (
        <Container>
            <Cell>
                <TitleText>Need Support?</TitleText>
                {!isMobile && (
                    <>
                        <Tagline>{'Send us an email.\nWe are happy to help.'}</Tagline>
                    </>
                )}
            </Cell>
            <ContactCell>
                <View style={{ paddingRight: 10, paddingTop: 130, justifyContent: 'center' }}>
                    <ContactLabel>Contact Support</ContactLabel>
                    <ContactText>{CONTACT.SUPPORT_EMAIL}</ContactText>
                </View>
            </ContactCell>
        </Container>
    )
}

const Container = styled(View)`
    flex: 1;
    flex-direction: ${props => (props.theme.isMobile ? 'column' : 'row')};
    padding-top: ${props => (props.theme.isMobile ? '50px' : '78px')};
    justify-content: center;
    padding-bottom: 92px;
    padding-left: 5%;
    padding-right: 5%;
`

const Cell = styled(View)`
    justify-content: center;
    width: ${props => (props.theme.isMobile ? '100%' : props.theme.isTablet ? '70%' : '35%')};
`

const ContactCell = styled(Cell)`
    flex-direction: ${props => (props.theme.isMobile || props.theme.isTablet ? 'column' : 'row')};
    justify-content: space-around;
    width: ${props => (props.theme.isMobile ? '50%' : '30%')};
`

const ContactLabel = styled(Inter700)`
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
`

const ContactText = styled(Inter500)`
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 30px;
    color: ${colors.subTextGray};
`

const TitleText = styled(Inter900)`
    font-size: ${props => (props.theme.isMobile ? '38px' : '67px')};
    line-height: ${props => (props.theme.isMobile ? '42px' : '72px')};
    margin-bottom: 35px;
`

const Tagline = styled(Inter900)`
    ${TEXT_LINEAR_GRADIENT};
    font-size: 38px;
    line-height: 45px;
    margin-bottom: 30px;
`

export default ContactSupport
