import React, { useState, useEffect } from 'react'
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native'
import styled from 'styled-components/native'
import Modal from 'modal-react-native-web'
import colors from 'styles/colors'
import { FormHeaderLabel } from 'typography'
import { AntDesign, Entypo } from '@expo/vector-icons'
import { FieldContainer, FieldRowContainer } from 'src/commons/containers'
import { StyledTextInput } from 'components/InputField'
import { LinearGradient } from 'expo-linear-gradient'
import { CheckboxField } from 'components/CheckboxField'
import CounterInput from 'components/instructor/CounterInput'
import { calculateClassTimeRemaining } from 'helpers'
const noop = () => {}
export default function IntervalModal({
    visible,
    close,
    title,
    currentInterval,
    intervals,
    setIntervals,
    index,
    isEditing,
    timeRemaining,
    setTimeRemaining,
}) {
    const { name, duration, timeType, type, id: currentIntervalID } = currentInterval
    const [state, setState] = useState({
        name,
        duration,
        timeType,
        type,
    })
    const [checkBoxOptions, setCheckBoxOptions] = useState([
        { value: 'sec', title: 'Seconds', checked: state?.timeType === 'sec' },
        { value: 'min', title: 'Minutes', checked: state?.timeType === 'min' },
    ])
    const [nameError, setNameError] = useState('')
    const [timeError, setTimeError] = useState('')
    const [durationError, setDurationError] = useState('')
    const isSeconds = state?.timeType == 'sec'

    const clearNameError = nameError ? () => setNameError('') : noop
    const clearDurationError = durationError ? () => setDurationError('') : noop
    const setIntervalName = value => setState({ ...state, name: value })
    const setIntervalDuration = value => setState({ ...state, duration: value })
    const setIntervalTimeType = value => {
        setTimeError('')
        setCheckBoxOptions([
            { value: 'sec', title: 'Seconds', checked: value === 'sec' ? true : false },
            { value: 'min', title: 'Minutes', checked: value === 'sec' ? false : true },
        ])
        setState({ ...state, timeType: value })
    }

    const handleClose = () => {
        if (!isEditing) setIntervals(intervals.filter(interval => interval.id != currentIntervalID))
        close()
    }
    const validateInterval = () => {
        const { name, duration, timeType } = state
        if (!name) setNameError('Name is required')
        if (!duration) setDurationError('Duration is required')
        if (!timeType) setTimeError('Time is required')
        return name && duration && timeType
    }
    const onSubmit = () => {
        const isIntervalValid = validateInterval()
        if (isIntervalValid) {
            let newIntervals = [...intervals]
            const { name, duration, timeType, type } = state
            const newTime = calculateClassTimeRemaining({ timeType, duration, timeRemaining })
            if (newTime < 0 && !__DEV__) {
                setTimeError('Not enough time remaining')
            } else {
                setTimeRemaining(newTime)
                newIntervals[index] = { id: currentIntervalID, name, duration, timeType, type, existing: true }
                setIntervals(newIntervals)
            }
        }
    }
    return (
        <Modal animationType="slide" transparent={true} visible={visible}>
            <ModalContainer>
                <FormHeaderLabel style={{ alignSelf: 'center', marginVertical: 0 }}>
                    Add {title} Interval
                </FormHeaderLabel>
                <FieldContainer style={{ marginHorizontal: '5%' }}>
                    <FieldRowContainer>
                        <StyledTextInput
                            style={{ fontSize: 25 }}
                            autoFocus
                            placeholder="Name"
                            errorMessage={nameError}
                            defaultValue={state.name}
                            onFocus={clearNameError}
                            onChangeText={setIntervalName}
                            onSubmitEditing={onSubmit}
                        />
                    </FieldRowContainer>
                </FieldContainer>
                <FieldRowContainer
                    style={{
                        justifyContent: 'flex-start',
                        alignItems: 'flex-end',
                        paddingRight: 45,
                        marginHorizontal: '5%',
                    }}
                >
                    <StyledTextInput
                        style={{ fontSize: 25, textAlign: 'center', width: 225 }}
                        largeTitle
                        shouldSanitizeInput
                        placeholder="Duration"
                        value={state.duration}
                        errorMessage={durationError}
                        onFocus={clearDurationError}
                        maxLength={6}
                        onChangeText={setIntervalDuration}
                    />
                    <TimeTypeContainer>
                        {checkBoxOptions.map((obj, i) => {
                            return (
                                <CheckboxField
                                    isSquare
                                    key={i}
                                    label={obj.title}
                                    onValueChange={setIntervalTimeType}
                                    value={obj.value}
                                    checked={obj.checked}
                                    color={{ unchecked: colors.starGray, checked: colors.homebodyGreen }}
                                />
                            )
                        })}
                    </TimeTypeContainer>
                </FieldRowContainer>

                <ModalFooter>
                    <CancelButton onPress={handleClose}>
                        <AntDesign name={'close'} color={'#888888'} size={14} />
                        <CancelButtonText>CANCEL</CancelButtonText>
                    </CancelButton>
                    <OKButton onPress={onSubmit}>
                        <GradientOK colors={['#0CE2FF', '#5AC973']}>
                            <Entypo name={'check'} color={'#fff'} size={16} />
                        </GradientOK>
                    </OKButton>
                </ModalFooter>
            </ModalContainer>
        </Modal>
    )
}

const TimeTypeContainer = styled(View)``
const GradientOK = styled(LinearGradient)`
    height: 47px;
    width: 47px;
    border-radius: 50%;
    align-items: center;
    padding: 15px;
`
const ModalContainer = styled(View)`
    position: absolute;
    left: 40%;
    top: 20%;
    flex: 1;
    height: 400px;
    width: 423px;
    padding: 20px;
    border-radius: 20px;
    border-width: ${StyleSheet.hairlineWidth}px;
    border-color: gray;
    shadow-color: #000000;
    shadow-offset: 4px 4px;
    shadow-radius: 8px;
    shadow-opacity: 0.25;
    background-color: white;
    padding-top: 50px;
`

const ModalFooter = styled(View)`
    margin-top: 50px;
    position: absolute;
    bottom: 40px;
    align-items: center;
    justify-content: space-between;
    width: 82.5%;
    flex-direction: row;
`

const CancelButton = styled(TouchableOpacity)`
    padding-left: 20px;
    padding: 10px;
    flex-direction: row;
    align-self: flex-end;
`

const CancelButtonText = styled(Text)`
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 3px;
    margin-left: 10px;
`

const OKButton = styled(TouchableOpacity)`
    height: 47px;
    width: 47px;
    border-radius: 24px;
    box-shadow: 0px 19.6245px 14.7184px rgba(0, 0, 0, 0.15);
`
