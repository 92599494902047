import React, { useState, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { isWeb } from '@constants'
import { LANDING_PAGE, LOGIN } from 'screenNames'
import { getCurrentUser } from 'apollo/selectors'
import CognitoIdentityServiceProvider from 'aws-sdk/clients/cognitoidentityserviceprovider'
import { useNavigation } from '@react-navigation/native'
import { logout } from 'actions'
import { View } from 'react-native'
import { ScreenContainer, ContentContainer } from 'commons/containers'
import VectorIcon from 'components/VectorIcon'
import { ContinueButton } from 'components/ContinueButton'
import { SubHeading } from 'typography'
import colors from 'colors'
import styled from 'styled-components/native'
import { DELETE_USER } from 'apollo/mutations'
import { useMutation } from 'apollo-augmented-hooks'
import { deleteUser } from 'actions'

const deleteAccountText =
    "We're sorry to see you go! \nAre you sure you want to delete your account? This can not be undone."

export default function DeleteAccount() {
    const { id, _version } = getCurrentUser()
    const [cognitoUser, setCognitoUser] = useState(undefined)
    const deleteUserMutation = useMutation(DELETE_USER)
    const navigation = useNavigation()

    useEffect(() => {
        async function getAuthenticatedUser() {
            const user = await Auth.currentAuthenticatedUser()
            setCognitoUser(user)
        }
        getAuthenticatedUser()
    }, [])

    async function handleDeleteUser() {
        if (cognitoUser) {
            try {
                const cognitoIdentityProvider = new CognitoIdentityServiceProvider({ region: 'us-east-1' })
                const params = { AccessToken: cognitoUser?.signInUserSession?.accessToken?.jwtToken }
                cognitoIdentityProvider.deleteUser(params, function (error, data) {
                    if (error) {
                        __DEV__ && console.log('\n\nerror deleting cognito user: ', error)
                        return
                    }
                })
                await deleteUser({ id, _version, deleteUserMutation })
                navigation.navigate(isWeb ? LANDING_PAGE : LOGIN)
                await logout({ isGlobal: true })
            } catch (error) {
                __DEV__ && console.log('error deleting user: ', error)
            }
        } else {
            return
        }
    }

    return (
        <ScreenContainer>
            <ContentContainer>
                <TextAndIconContainer>
                    <VectorIcon family="MaterialCommunityIcons" name="emoticon-sad-outline" size={40} color="black" />
                    <DeleteAccountTextContainer>
                        <SubHeading isMostBold fontSize={21} textAlign="center">
                            {deleteAccountText}
                        </SubHeading>
                    </DeleteAccountTextContainer>
                </TextAndIconContainer>
            </ContentContainer>
            <ButtonContainer>
                <ContinueButton
                    text="YES. Delete"
                    onPress={handleDeleteUser}
                    color={colors.grayContinue}
                    style={{ height: 56 }}
                />
            </ButtonContainer>
        </ScreenContainer>
    )
}

const TextAndIconContainer = styled(View)`
    padding-top: 55%;
`
const DeleteAccountTextContainer = styled(View)`
    padding-top: 22px;
    width: 95%;
    align-self: center;
`
const ButtonContainer = styled(View)`
    align-self: center;
    width: 100%;
    padding-bottom: 25px;
    padding-horizontal: 5%;
`
