import React, { useState, useEffect } from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Text, View, TouchableOpacity } from 'react-native'
import { ScreenContainer, ContentContainer } from 'commons/containers'
import { Heading, Paragraph } from 'typography'
import colors from 'colors'
import styled from 'styled-components/native'
import { ContinueButton } from 'components/ContinueButton'
import { getNavigationAction, navigateExploreAction } from 'navigationActions'
import { TraineeWebBannerHeader } from 'commons/components/TraineeWebBannerHeader'
import { FooterBar } from 'commons/components/FooterBar'
import { isWeb } from '@constants'

export default function TraineeNotificationDetails() {
    const navigation = useNavigation()
    const { params = emptyObj } = useRoute()
    const { title, body, timeSinceNotification } = params

    const goToExplore = getNavigationAction({
        navigation,
        navigationAction: navigateExploreAction,
    })

    const isCancelledClass = title?.includes('Cancelled')
    const titleColor = isCancelledClass ? colors.cancelledClassRed : colors.homebodyTurquoise

    return (
        <ScreenContainer>
            {isWeb && <TraineeWebBannerHeader title="My Notifications" />}
            <ContentContainer isFluid={isWeb}>
                <NotificationDetailsContainer>
                    <NotificationTitle isMostBold color={titleColor}>
                        {title}
                    </NotificationTitle>
                    <NotificationMessage paddingTop={15} isSubtext>
                        {body}
                    </NotificationMessage>
                    <NotificationTime>{timeSinceNotification}</NotificationTime>
                </NotificationDetailsContainer>
            </ContentContainer>
            {isCancelledClass && (
                <ContinueButtonContainer>
                    <ContinueButton onPress={goToExplore} text="Book Another Class" />
                </ContinueButtonContainer>
            )}
            {isWeb && <FooterBar />}
        </ScreenContainer>
    )
}

const NotificationDetailsContainer = styled(View)`
    padding-top: ${({ theme }) => (theme.isMobile ? '15%' : '5%')};
    padding-left: 5%;
    width: 100%;
`
const NotificationTitle = styled(Heading)``
const NotificationMessage = styled(Paragraph)``
const ContinueButtonContainer = styled(View)`
    width: ${({ theme }) => (theme.isMobile ? '100%' : '500px')};
    padding-horizontal: ${({ theme }) => (theme.isMobile ? '7.5%' : '0%')};
    margin-left: ${({ theme }) => (theme.isMobile ? '0%' : '5%')};
    padding-bottom: 7.5%;
`
const NotificationTime = styled(Paragraph)`
    color: ${colors.homebodyTurquoise};
    margin-top: 30px;
    align-self: flex-start;
`
