import { generateAgoraUID } from 'agoraHelpers'
import { CLASS_STATUS } from '@constants'

export const initialSignUpForm = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
}
const socials = {
    instagram: '',
    snapchat: '',
    linkedin: '',
    facebook: '',
    twitter: '',
}
const location = {
    city: '',
    state: '',
    zip: '',
}
export const specializations = {
    barre: false,
    bound: false,
    crossfit: false,
    core: false,
    circuitTraining: false,
    dance: false,
    HIIT: false,
    kickBoxing: false,
    meditation: false,
    pilates: false,
    power: false,
    pregnancy: false,
    sculpt: false,
    strength: false,
    stretch: false,
    yoga: false,
    zumba: false,
    cardio: false,
    endurance: false,
    jump: false,
    foamRoll: false,
    run: false,
    yogaSculpt: false,
    restorative: false,
    recovery: false,
    strengthAndConditioning: false,
    flow: false,
    boxing: false,
    celebrity: false,
    weightLoss: false,
    bodyWeight: false,
    stability: false,
    mobility: false,
    functionalTraining: false,
    glutes: false,
    legs: false,
    arms: false,
    performanceTraining: false,
    flexibility: false,
    spiritualWellness: false,
    wellness: false,
    breathWork: false,
    beginner: false,
    advanced: false,
    kettleBell: false,
    tabata: false,
    bodyBuilding: false,
    athleteTraining: false,
    cycling: false,
    plusSize: false,
}
const gymStudioAddress = {
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    country: 'United States',
}
export const initialInstructorApplicationForm = {
    id: '',
    files: { items: [] },
    firstName: '',
    lastName: '',
    username: '',
    isInfluencer: false,
    isTrainer: false,
    isOwner: false,
    submitted: false,
    location: {
        city: '',
        state: '',
        zip: '',
    },
    socials,
    specializations,
    gymStudioName: '',
    gymStudioAddress,
    website: '',
    lastIndex: 0,
    approved: 'PENDING',
}
export const initialCreateClassForm = {
    userID: '',
    name: '',
    description: '',
    date: '',
    categoryFilter: '',
    category: specializations,
    startTime: '',
    duration: 10,
    equipment: '',
    banner: {
        bucket: '',
        region: '',
        key: '',
    },
    intervals: [],
    classStatus: CLASS_STATUS.UNFINISHED,
    playlist: '',
    price: 0,
    defaultBannerIndex: 2,
    id: '',
    _version: undefined,
}
export const initialUserProfileForm = {
    avatar: {
        bucket: '',
        region: '',
        key: '',
    },
    firstName: '',
    lastName: '',
    location,
    socials,
    bio: '',
    username: '',
    specializations,
    specializationFilter: '',
}
export const initialAgoraStreamState = {
    uid: generateAgoraUID(),
    token: '',
    hostUID: '',
    channelName: '',
    instructorClass: undefined,
    numTraineesBooked: 0,
    traineesAttendingClass: [],
    host: undefined,
    isHost: false,
    classStarted: false,
    classComplete: false,
    classEnded: false,
    waitingRoomJoined: false,
    streamRoomLocked: true,
    streamEnded: false,
    streamReady: false,
    streamHidden: false,
    streamRoomJoined: false,
    streamStatusMessage: '',
    streamOverlayMessage: {
        overlayMessage: '',
        delay: 0,
        id: 1,
    },
    hasJoinedRTCChannel: false,
    hasLeftStream: false,
    streamClient: undefined,
    messagesClient: undefined,
    messagesChannel: undefined,
    isLoggedInToRTM: false,
    streamEvent: {
        event: '',
        payload: {},
    },
    streamEventQueue: [],
    tracks: [],
    tracksReady: false,
    trackState: {
        video: true,
        audio: true,
        hostVideoDisabled: false,
        hostAudioDisabled: false,
    },
    currentIntervalIndex: 0,
    currentInterval: {
        name: '',
        type: '',
        duration: '',
        timeType: '',
        nextIntervalName: '',
    },
    lateTraineeIDs: [],
    showVideoPlayer: true,
    isLateJoin: false,
    timeUntilNextInterval: 0,
    wasStreamEndedWithListener: false,
    logHostEvents: __DEV__,
    logAudienceEvents: __DEV__,
    logClassEvents: __DEV__,
}

export const initialPaymentMethodForm = {
    name: '',
    zip: '',
}
