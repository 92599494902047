import React from 'react'
import { useNavigation } from '@react-navigation/core'
import { ScreenContainer, ContentContainer } from 'commons/containers'
import { useDimensions } from 'dimensions'
import TraineeBookedClasses from 'components/TraineeBookedClasses'

export default function TraineeUpcomingClasses() {
    const navigation = useNavigation()
    const { isMobile } = useDimensions()
    return (
        <ScreenContainer>
            <ContentContainer>
                <TraineeBookedClasses isProfileNav horizontal={false} />
            </ContentContainer>
        </ScreenContainer>
    )
}
