import React from 'react'
import { useNavigation } from '@react-navigation/core'
import { logout } from 'actions'
import { View, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import { Paragraph } from 'typography'

import { LANDING_PAGE, CHANGE_PASSWORD, CONTACT_US_FORM } from 'screenNames'
import VectorIcon from 'components/VectorIcon'
import { useApolloClient } from '@apollo/client'

export default function InstructorSettings({ style }) {
    const navigation = useNavigation()
    const client = useApolloClient()

    const handleLogout = async () => {
        await logout()
        await client.clearStore()
        client.cache.gc()
        navigation.navigate(LANDING_PAGE)
    }
    const goToChangePassword = () => navigation.navigate(CHANGE_PASSWORD, { initial: false })
    const goToContact = () => navigation.navigate(CONTACT_US_FORM)

    return (
        <InstructorSettingsContainer style={style}>
            <SettingsButton onPress={goToChangePassword}>
                <ButtonTextContainer>
                    <VectorIcon family="Feather" name="lock" />
                    <ButtonText>Change Password</ButtonText>
                </ButtonTextContainer>
            </SettingsButton>
            <SettingsButton onPress={goToContact}>
                <ButtonTextContainer>
                    <VectorIcon family="FontAwesome" name="phone" />
                    <ButtonText>Contact Us</ButtonText>
                </ButtonTextContainer>
            </SettingsButton>
            <SettingsButton onPress={handleLogout}>
                <ButtonTextContainer>
                    <VectorIcon family="SimpleLineIcons" name="logout" />
                    <ButtonText>Logout</ButtonText>
                </ButtonTextContainer>
            </SettingsButton>
        </InstructorSettingsContainer>
    )
}

const SettingsButton = styled(TouchableOpacity)`
    margin-bottom: 32px;
`
const InstructorSettingsContainer = styled(View)`
    width: 100%;
`
const ButtonTextContainer = styled(View)`
    flex-direction: row;
    align-items: center;
`
const ButtonText = styled(Paragraph)`
    margin-left: 12px;
`
