import React, { useEffect } from 'react'
import { Text } from 'react-native'
import styled from 'styled-components/native'
import { Inter500 } from 'typography'
import colors from 'colors'

export const FrontText = styled(Inter500)`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 16px;
`
export const SubTitle = styled(FrontText)`
    font-size: 22px;
    line-height: 30px;
    letter-spacing: -1px;
`
export const BoldSubTitle = styled(SubTitle)`
    font-weight: bold;
`
