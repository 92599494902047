import React from 'react'
import { TouchableOpacity } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import { iconHitSlop } from '@constants'

const DeleteIcon = ({ onPress, color = 'red', size = 20 }) => (
    <TouchableOpacity onPress={onPress} hitSlop={iconHitSlop}>
        <AntDesign name="delete" size={size} color={color} />
    </TouchableOpacity>
)
export default DeleteIcon
