import React from 'react'
import { View, ImageSourcePropType, Image } from 'react-native'
import { Inter900 } from 'typography'

import styled from 'styled-components/native'
import { useDimensions } from 'dimensions'

type Logo = {
    id: string
    photo: ImageSourcePropType
}

const Features: React.FC = () => {
    const {isMobile} = useDimensions()

    return(
        <Container>
            <Title>Features</Title>
            <LogoContainer>
               {LOGOS.map(item => (
                   <Logo logo={item} key={item.id} />
               ))} 
            </LogoContainer>
        </Container>
    )

}

const Logo = ({ logo }: { logo: Logo }): JSX.Element => (
    <LogoImgContainer>
        <LogoImg source={logo.photo} resizeMode="center"/>
    </LogoImgContainer>
)

const LOGOS: Logo[] = [
    {
        id: 'usWeekly',
        photo: require('assets/logos/usWeekly.png'),
    },
    {
        id: 'People',
        photo: require('assets/logos/people.png'),
    },
    {
        id: 'Page Six',
        photo: require('assets/logos/pageSix.png'),
    },
    {
        id: 'Bravo',
        photo: require('assets/logos/bravo.png'),
    },
    {
        id: 'Bustle',
        photo: require('assets/logos/bustle.png'),
    },
    {
        id: 'Msn',
        photo: require('assets/logos/msn.png'),
    },
]

const Container = styled(View)`
    padding-left: 2.5%;
    padding-right: 2.5%;
    padding-top: 5%;
    padding-bottom: 5%;
`

const LogoContainer = styled(View)`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: ${props => (props.theme.isMobile ? '29px' : '98px')};
`

const LogoImg = styled(Image)`
    width: 100%;
    height: 100%;
`

const LogoImgContainer = styled(View)`
    flex: 0.17;
    align-self: ${props => (props.theme.isMobile || props.theme.isTablet ? 'center' : 'auto')};
    width: ${props => (props.theme.isMobile ? '68px' : '206px')};
    height: ${props => (props.theme.isMobile ? '48px' : '100px')};
`

const Title = styled(Inter900)`
    font-size: ${props => (props.theme.isMobile ? '39px' : '52px')};
    line-height: 56px;
    align-self: center;
`

export default Features