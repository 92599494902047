import { INSTRUCTOR_APPLICATION, CREATE_CLASS, TRAINEE_ACCOUNT } from 'screenNames'
import { createClassForm, instructorApplicationForm, userProfileForm, paymentMethodForm } from 'apollo/cache'
import { hasUploadedFile, calculateClassTimeRemaining, getSelectedClassCategories } from 'helpers'
import { FILE_TYPES } from '@constants'
const { PERSONAL_DETAILS, SOCIALS, CERTIFICATIONS, BUSINESS_REGISTRATION, GYM_STUDIO_DETAILS } = INSTRUCTOR_APPLICATION
const { DETAILS, INTERVALS, PLAYLIST, PRICE, REVIEW } = CREATE_CLASS
const { CERTIFICATION, BUSINESS } = FILE_TYPES

export const instructorAppValidationFunc = ({ currentPage }) => {
    let validations = {}
    switch (currentPage) {
        /* if a field is nested in fieldNames/fieldErrors the validation func must be nested with the same outer key
            for the onBlur errors/validation func to function as expected */
        case PERSONAL_DETAILS:
            validations = {
                firstName: !!instructorApplicationForm()?.firstName,
                lastName: !!instructorApplicationForm()?.lastName,
                username: !!instructorApplicationForm()?.username && instructorApplicationForm()?.username?.length >= 3,
                location: {
                    zip:
                        !!instructorApplicationForm()?.location?.zip &&
                        instructorApplicationForm()?.location?.zip?.length == 5,
                },
            }
            break
        case SOCIALS:
            validations = {
                socials: {
                    instagram: instructorApplicationForm()?.isInfluencer
                        ? !!instructorApplicationForm()?.socials?.instagram
                        : true,
                },
                website: instructorApplicationForm()?.isOwner ? !!instructorApplicationForm()?.website : true,
            }
            break
        case CERTIFICATIONS:
            const { files: certificationFiles = [] } = instructorApplicationForm()
            const hasUploadedCertificationFile = hasUploadedFile({
                files: certificationFiles,
                requiredFileType: CERTIFICATION,
            })
            validations = {
                files: hasUploadedCertificationFile,
            }
            break
        case GYM_STUDIO_DETAILS:
            validations = {
                gymStudioName: !!instructorApplicationForm()?.gymStudioName,
                gymStudioAddress: {
                    streetAddress: !!instructorApplicationForm()?.gymStudioAddress.streetAddress,
                    city: !!instructorApplicationForm()?.gymStudioAddress.city,
                    state: !!instructorApplicationForm()?.gymStudioAddress.state,
                    zip:
                        !!instructorApplicationForm()?.gymStudioAddress?.zip &&
                        instructorApplicationForm()?.gymStudioAddress?.zip?.length == 5,
                },
            }
            break
        case BUSINESS_REGISTRATION:
            const { files: businessFiles = [] } = instructorApplicationForm()
            const hasUploadedBusinessFile = hasUploadedFile({ files: businessFiles, requiredFileType: BUSINESS })
            validations = {
                files: hasUploadedBusinessFile,
            }
            break
        default:
            break
    }
    return validations
}

export const createClassValidationFunc = ({ currentPage }) => {
    let validations = {}
    switch (currentPage) {
        case DETAILS:
            validations = {
                name: !!createClassForm()?.name,
                description: !!createClassForm()?.description,
                date: !!createClassForm()?.date,
                startTime: !!createClassForm()?.startTime,
                duration: createClassForm()?.duration > 0,
                category: getSelectedClassCategories({ classCategories: createClassForm()?.category })?.length > 0,
            }
            break
        case PRICE:
            validations = {
                price: !!createClassForm()?.price?.toString(),
            }
            break
        case INTERVALS:
            validations = {
                intervals: !!createClassForm()?.intervals?.length > 0,
                duration:
                    createClassForm()?.intervals?.reduce((acc, interval) => {
                        return calculateClassTimeRemaining({
                            timeType: interval.timeType,
                            duration: interval.duration,
                            timeRemaining: acc,
                        })
                    }, createClassForm()?.duration * 60) >= 0,
            }
            break
        // case PLAYLIST:
        //     validations = {
        //         playlist: !!createClassForm().playlist,
        //     }
        //     break
        default:
            break
    }
    return validations
}

export const instructorAccountValidationFunc = () => {
    let validations = {
        firstName: !!userProfileForm()?.firstName,
        lastName: !!userProfileForm()?.lastName,
        socials: { instagram: !!userProfileForm()?.socials?.instagram },
        username: !!userProfileForm()?.username && userProfileForm()?.username?.length >= 3,
        bio: !!userProfileForm()?.bio,
    }
    return validations
}

export const traineeAccountValidationFunc = () => {
    let validations = {
        firstName: !!userProfileForm()?.firstName,
        lastName: !!userProfileForm()?.lastName,
    }
    return validations
}

export const paymentMethodValidationFunc = () => {
    let validations = {
        name: !!paymentMethodForm()?.name,
        zip: !!paymentMethodForm()?.zip,
    }
    return validations
}
