import React, { useEffect } from 'react'
import { Text, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import colors from 'colors'
import { Hoverable, Pressable } from 'react-native-web-hover'
import { Inter100 } from 'typography'

export const StyledContinueButton = styled.TouchableOpacity`
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background-color: ${props => props.color || colors.blueContinue};
    justify-content: center;
    align-items: center;
`
export const ButtonText = styled(Inter100)`
    font-size: 40px;
    color: white;
    margin-top: -5px;
`
const AddButton = ({ onPress, text, disabled, color, style = {} }) => {
    return (
        <Hoverable>
            {({ hovered }) => (
                <StyledContinueButton
                    onPress={onPress}
                    disabled={disabled}
                    style={style}
                    color={hovered && !disabled ? 'black' : color}
                >
                    <ButtonText>+</ButtonText>
                </StyledContinueButton>
            )}
        </Hoverable>
    )
}

export default AddButton;


