import React, { useState, useEffect } from 'react'
import { View, ActivityIndicator } from 'react-native'
import colors from 'colors'
import styled from 'styled-components/native'
import { Heading, SubHeaderText } from 'typography'
import ClassOverview from 'components/ClassOverview'
import { FillContainer } from 'commons/containers'
import ClassBannerImage from 'components/CustomImage'
import { useDimensions } from 'dimensions'
import { getDefaultBanners } from 'helpers'
const defaultBanners = getDefaultBanners()

export const TraineeWaitingRoom = ({ reactiveStreamProps }) => {
    const { isMobile } = useDimensions()
    const { streamState } = reactiveStreamProps
    const { instructorClass } = streamState
    const { name: className, defaultBannerIndex, banner } = instructorClass || {}

    if (instructorClass) {
        return (
            <TraineeWaitingRoomContainer
                contentContainerStyle={{
                    alignSelf: 'center',
                    alignItems: 'center',
                    width: isMobile ? '100%' : '60%',
                    backgroundColor: 'white',
                    paddingHorizontal: isMobile ? 15 : 30,
                }}
            >
                <PleaseWaitText isMostBold paddingTop={50} paddingBottom={50}>
                    Please wait, the host will let you in soon
                </PleaseWaitText>
                <ClassBannerContainer>
                    <ClassBannerImage
                        resizeMode="stretch"
                        imgKey={banner?.key}
                        style={{ height: 220, width: '100%', borderRadius: 15, paddingTop: 25 }}
                        defaultImage={defaultBanners[defaultBannerIndex]}
                    />
                </ClassBannerContainer>
                <StyledClassOverview Class={instructorClass} />
            </TraineeWaitingRoomContainer>
        )
    } else {
        return (
            <LoadingContainer>
                <ActivityIndicator animating size="large" color={colors.homebodyTurquoise} />
            </LoadingContainer>
        )
    }
}

const TraineeWaitingRoomContainer = styled(FillContainer)``
const StyledClassOverview = styled(ClassOverview)`
    margin-top: 75px;
`
const PleaseWaitText = styled(SubHeaderText)`
    align-self: center;
`
const LoadingContainer = styled(View)`
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: white;
`
const ClassNameContainer = styled(View)`
    height: 100%;
    width: 75%;
    position: absolute;
    align-self: center;
    justify-content: center;
`
const ClassBannerContainer = styled(View)`
    height: 183px;
    width: ${({ theme }) => (theme.isMobile ? '100%' : '50%')};
    align-self: center;
    justify-content: center;
    align-items: center;
`

export default TraineeWaitingRoom
