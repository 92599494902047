import React, { useState } from 'react'
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native'
import styled from 'styled-components/native'
import colors from 'colors'
import Modal from 'modal-react-native-web'
import { AntDesign, Entypo } from '@expo/vector-icons'
import { LinearGradient } from 'expo-linear-gradient'
import { Calendar } from 'react-native-calendars'
import DateTimeDropdown from 'components/instructor/DateTimeDropdown'
import { createClassFieldNames as fieldNames } from 'formFieldInfo'
import VectorIcon from 'components/VectorIcon'
// Modal.setAppElement('body')
const today = new Date()
const noop = () => {}

export default function CalendarModal({
    visible,
    setVisible,
    date,
    setDate,
    multipleSelection = false,
    reactiveFormProps,
}) {
    const { populateErrors, getFieldError } = reactiveFormProps
    const [fieldError, setFieldError] = useState({})
    const initialMarkedDate = date ? { [date.dateString]: { selected: true } } : {}

    const handleBlur = () => (populateErrors ? setFieldError(getFieldError({ fieldName: fieldNames.date })) : noop)
    const [markedDates, setMarkedDates] = useState(initialMarkedDate)
    const handleCancel = () => {
        setDate({})
        setMarkedDates({})
        setVisible(false)
    }

    const handleDayPress = day => {
        setDate(day)
        const { dateString } = day
        const { selected } = markedDates[dateString] || false
        setMarkedDates(
            multipleSelection
                ? { ...markedDates, [dateString]: { selected: !selected } }
                : { [dateString]: { selected: !selected } },
        )
    }

    const handleSubmit = () => setVisible(false)

    const Arrow = ({ direction }) => (
        <VectorIcon family="FontAwesome" name={`angle-${direction}`} size={30} color={colors.homebodyTurquoise} />
    )

    return (
        <>
            <DateTimeDropdown
                fieldTitle={'Date '}
                fieldName={fieldNames.date}
                open={visible}
                setOpen={setVisible}
                date={date}
                fieldError={fieldError}
                setFieldError={setFieldError}
                {...reactiveFormProps}
            />
            <Modal animationType="none" transparent={true} visible={visible}>
                {/* For some reason styling this view works but not the styled element, ModalContainer below */}
                <View style={{flex: 1, flexDirection: 'column', alignSelf: 'center', justifyContent: 'center', alignItems: 'center'}}>
                    <CalendarContainer onBlur={handleBlur}>
                        <Calendar
                            minDate={today}
                            markedDates={markedDates}
                            onDayPress={handleDayPress}
                            renderArrow={direction => <Arrow direction={direction} />}
                            theme={{
                                todayTextColor: colors.blueContinue,
                                selectedDayBackgroundColor: colors.blueContinue,
                                disabledArrowColor: 'orange',
                                'stylesheet.calendar.header': {
                                    header: {
                                        marginTop: 8,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    },
                                },
                            }}
                        />
                        <ModalFooter>
                            <CancelButton onPress={handleCancel}>
                                <AntDesign name={'close'} color={'#888888'} size={14} />
                                <CancelButtonText>CANCEL</CancelButtonText>
                            </CancelButton>
                            <OKButton onPress={handleSubmit}>
                                <GradientOK colors={['#0CE2FF', '#5AC973']}>
                                    <Entypo name={'check'} color={'#fff'} size={16} />
                                </GradientOK>
                            </OKButton>
                        </ModalFooter>
                    </CalendarContainer>
                </View>
            </Modal>
        </>
    )
}

const ModalContainer = styled(View)`
    flex: 1;
    flex-direction: 'column';
    align-self: 'center';
    justify-content: 'center';
    align-items: 'center';
`

const CalendarContainer = styled(View)`
    height: 450px;
    width: 300px;
    padding: 20px;
    border-radius: 16px;
    border-width: ${StyleSheet.hairlineWidth}px;
    border-color: gray;
    shadow-color: #000000;
    shadow-offset: 4px 4px;
    shadow-radius: 8px;
    shadow-opacity: 0.25;
    background-color: white;
    z-index: -5;
`
const GradientOK = styled(LinearGradient)`
    height: 47px;
    width: 47px;
    border-radius: 50%;
    align-items: center;
    padding: 15px;
`
const ModalFooter = styled(View)`
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
`
const CancelButton = styled(TouchableOpacity)`
    padding: 10px;
    flex-direction: row;
`

const CancelButtonText = styled(Text)`
    font-size: 12px;
    font-weight: 700;
`

const OKButton = styled(TouchableOpacity)`
    height: 47px;
    width: 47px;
    align-self: flex-end;
`
