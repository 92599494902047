import React, { useEffect } from 'react'
import styled from 'styled-components/native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { FieldContainer } from 'src/commons/containers'
import { useReactiveVar } from '@apollo/client'
import { createClassForm } from 'apollo/cache'
import { formatDate, toStandardTime } from 'helpers'
import ClassReview from 'components/instructor/ClassReview'
import { CREATE_CLASS, CREATE_CLASS_NAVIGATOR } from 'screenNames'
import { getTraineesByFavoriteInstructorID, getUsersByUserIDArray } from 'selectors'
const { DETAILS, BANNER, INTERVALS, PRICE } = CREATE_CLASS
const emptyObj = {}
const noop = () => {}

export const Review = ({ isDateTimeValidObj = emptyObj, setFavoritedInstructorUsers = noop, defaultBanners }) => {
    const navigation = useNavigation()
    const route = useRoute()
    const { params = emptyObj } = route
    const { fromUnpublished, parentNav = emptyObj } = params
    const form = useReactiveVar(createClassForm)
    const { id, date, startTime, duration, equipment, userID: instructorID, intervals, price } = form
    const { isInvalidTime, isInvalidDate, showInvalidDateTime = false } = isDateTimeValidObj
    const { trainees: userIDArray } = getTraineesByFavoriteInstructorID({ instructorID })
    const { users } = getUsersByUserIDArray({ userIDArray })

    useEffect(() => {
        setFavoritedInstructorUsers(users)
    }, [users])

    const navActions = {
        goToDetails: () =>
            navigation.navigate(DETAILS, { index: 0, isEditing: true, classID: id, fromUnpublished, parentNav }),
        goToBanner: () =>
            navigation.navigate(CREATE_CLASS_NAVIGATOR, {
                screen: BANNER,
                params: { index: 1, isEditing: true, classID: id, fromUnpublished },
            }),
        goToIntervals: () => {
            navigation.navigate(CREATE_CLASS_NAVIGATOR, {
                screen: INTERVALS,
                params: { index: 2, isEditing: true, classID: id, fromUnpublished },
            })
        },
        goToPrice: () => {
            navigation.navigate(CREATE_CLASS_NAVIGATOR, {
                screen: PRICE,
                params: { index: 3, isEditing: true, classID: id, fromUnpublished },
            })
        },
    }

    const classDetails = [
        {
            error: showInvalidDateTime && isInvalidDate,
            detail: !isInvalidDate || !showInvalidDateTime ? formatDate(date) : 'Please select new date',
            handleEdit: navActions?.goToDetails,
        },
        {
            error: showInvalidDateTime && isInvalidTime,
            detail: !isInvalidTime || !showInvalidDateTime ? toStandardTime(startTime) : 'Please select new time',
            handleEdit: navActions?.goToDetails,
        },
        {
            detail: `${duration} min`,
            handleEdit: navActions?.goToDetails,
        },
        {
            detail: equipment,
            handleEdit: navActions?.goToDetails,
        },
        {
            detail: `Intervals (${intervals?.length})`,
            handleEdit: navActions?.goToIntervals,
        },
        {
            detail: `Price: ${price} credits`,
            handleEdit: navActions?.goToPrice,
        },
    ]

    return (
        <ReviewContainer>
            <ClassReview
                Class={form}
                classDetails={classDetails}
                navActions={navActions}
                defaultBanners={defaultBanners}
            />
        </ReviewContainer>
    )
}

const ReviewContainer = styled(FieldContainer)``
export default Review
