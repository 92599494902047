import React, { useMemo } from 'react'
import {
    AntDesign,
    Entypo,
    EvilIcons,
    Feather,
    FontAwesome,
    FontAwesome5,
    Fontisto,
    Foundation,
    Ionicons,
    MaterialCommunityIcons,
    MaterialIcons,
    Octicons,
    SimpleLineIcons,
    Zocial,
} from '@expo/vector-icons'

const defaultIconStyle = { alignSelf: 'center' }

export const VectorIcon = ({ family, name, size = 24, color = 'black', style = defaultIconStyle }) => {
    function getIcon() {
        switch (family) {
            case 'AntDesign':
                return <AntDesign name={name} size={size} color={color} style={style} />
            case 'Ionicons':
                return <Ionicons name={name} size={size} color={color} style={style} />
            case 'Entypo':
                return <Entypo name={name} size={size} color={color} style={style} />
            case 'FontAwesome':
                return <FontAwesome name={name} size={size} color={color} style={style} />
            case 'MaterialCommunityIcons':
                return <MaterialCommunityIcons name={name} size={size} color={color} style={style} />
            case 'EvilIcons':
                return <EvilIcons name={name} size={size} color={color} style={style} />
            case 'Feather':
                return <Feather name={name} size={size} color={color} style={style} />
            case 'FontAwesome5':
                return <FontAwesome5 name={name} size={size} color={color} style={style} />
            case 'Fontisto':
                return <Fontisto name={name} size={size} color={color} style={style} />
            case 'Foundation':
                return <Foundation name={name} size={size} color={color} style={style} />
            case 'MaterialIcons':
                return <MaterialIcons name={name} size={size} color={color} style={style} />
            case 'Octicons':
                return <Octicons name={name} size={size} color={color} style={style} />
            case 'SimpleLineIcons':
                return <SimpleLineIcons name={name} size={size} color={color} style={style} />
            case 'Zocial':
                return <Zocial name={name} size={size} color={color} style={style} />
        }
    }
    const Icon = useMemo(() => getIcon(), [name, size, color, style])
    return Icon
}

export default VectorIcon
