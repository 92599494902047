import React, { useState, useEffect } from 'react'
import { View, TouchableOpacity, FlatList, ActivityIndicator } from 'react-native'
import styled from 'styled-components/native'
import colors from 'styles/colors'
import VectorIcon from 'commons/components/VectorIcon'
import { ScreenContainer, ContentContainer, BannerContainer } from 'commons/containers'
import { ContinueButton } from 'commons/components/ContinueButton'
import { TraineeWebBannerHeader } from 'commons/components/TraineeWebBannerHeader'
import CustomImage from 'commons/components/CustomImage'
import { HeaderText } from 'styles/typography'
import { Heading, SmallText, SubHeading } from 'styles/typography'
import { useDimensions } from 'dimensions'
import { useNavigation, useRoute } from '@react-navigation/core'
import { ADD_CARD, PURCHASE_CREDIT } from 'screenNames'
import useDefaultBanners from 'commons/hooks/useDefaultBanners'
import { getCurrentUser } from 'apollo/selectors'
import * as queries from 'apollo/queries'
import { useApolloClient } from '@apollo/client'
import { isWeb, STRIPE_API_ROUTES } from '@constants'
const { LIST_PAYMENT_METHODS, DELETE_CARD } = STRIPE_API_ROUTES
import { stripeAPI } from 'src/actions'
import { FooterBar } from 'commons/components/FooterBar'
const emptyObj = {}
const emptyArr = []
const noop = () => {}

function CardCard({ item = emptyObj, goToPurchase = noop, isSettings = false, deleteCard = noop }) {
    const { id: paymentMethodID, card: { brand, last4 } = emptyObj } = item
    const cardType = brand || ''
    const [showLoadingCard, setShowLoadingCard] = useState(false)

    async function handleOnPress() {
        if (isSettings) {
            setShowLoadingCard(true)
            await deleteCard({ paymentMethodID })
            setShowLoadingCard(false)
        } else {
            goToPurchase(paymentMethodID)
        }
    }

    return (
        <CardCardContainer isLoading={showLoadingCard}>
            {showLoadingCard ? (
                <>
                    <ActivityIndicator size="large" color={colors.homebodyTurquoise} animating={true} />
                    <PaymentText>Deleting card...</PaymentText>
                </>
            ) : (
                <>
                    <CardDetailsContainer disabled={isSettings} onPress={handleOnPress}>
                        <VectorIcon family={'Fontisto'} name={cardType} size={25} color={colors.darkGrey} />
                        <PaymentText>{`Card ending in ${last4 || '....'}`}</PaymentText>
                    </CardDetailsContainer>
                    <CardIconContainer onPress={handleOnPress}>
                        <VectorIcon
                            style={{ alignSelf: 'flex-end' }}
                            family="AntDesign"
                            name={isSettings ? 'delete' : 'right'}
                            size={25}
                            color={colors.grayContinue}
                        />
                    </CardIconContainer>
                </>
            )}
        </CardCardContainer>
    )
}

function renderLoading() {
    return (
        <CardCardContainer isLoading>
            <ActivityIndicator size="large" color={colors.homebodyTurquoise} animating={true} />
            <PaymentText>Loading...</PaymentText>
        </CardCardContainer>
    )
}

export default function TraineeWallet() {
    const client = useApolloClient()
    const defaultBanners = useDefaultBanners()
    const { params } = useRoute() || emptyObj
    const { priceID, classID, isProfile, isSettings = false, refetch = false } = params || emptyObj
    const { isMobile } = useDimensions()
    const navigation = useNavigation()
    const { customerID, credits } = getCurrentUser() || emptyObj
    const [cards, setCards] = useState([])
    const [loading, setLoading] = useState(true)
    const [classFromID, setClassFromID] = useState({})

    useEffect(() => {
        const getClass = async () => {
            const {
                data: { getClass: classData },
            } = await client.query({
                query: queries.GET_CLASS,
                variables: {
                    id: `${classID}`,
                },
            })

            setClassFromID({ ...classData })
        }
        classID && getClass()
    }, [classID])

    const { banner, defaultBannerIndex } = classFromID || emptyObj

    const goToAddCard = () =>
        navigation.navigate(ADD_CARD, {
            isWallet: true,
            ...(isSettings && { isSettings }),
            ...(priceID && { priceID }),
            ...(classID && { classID }),
        })
    const goToPurchase = paymentMethod =>
        navigation.navigate(PURCHASE_CREDIT, { priceID, classID, paymentMethod, isProfile })

    async function handleRefetchCards() {
        setLoading(true)
        setCards(emptyArr)
        const userCards =
            (await stripeAPI({
                path: LIST_PAYMENT_METHODS,
                pathParameters: customerID,
            })) ?? emptyArr
        __DEV__ && console.log('cards: ', userCards)
        setCards(userCards)
        setLoading(false)
    }

    async function deleteCard({ paymentMethodID }) {
        try {
            await stripeAPI({
                path: DELETE_CARD,
                body: { paymentMethodID },
            })
            await handleRefetchCards()
        } catch (error) {
            __DEV__ && console.log('error deleting card: ', error)
        }
    }

    const renderItem = ({ item }) => (
        <CardCard item={item} deleteCard={deleteCard} goToPurchase={goToPurchase} isSettings={isSettings} />
    )

    useEffect(() => {
        async function getUserCards() {
            const userCards = await stripeAPI({
                path: LIST_PAYMENT_METHODS,
                pathParameters: customerID,
            })
            __DEV__ && console.log('cards: ', userCards)
            setCards(userCards)
            setLoading(false)
        }
        customerID && getUserCards()
    }, [customerID, refetch])

    const listEmptyComponent = loading ? renderLoading(isMobile) : noop

    const imageStyle = { height: 250, width: '100%' }
    return (
        <ScreenContainer>
            {!isMobile &&
                (classID ? (
                    <BannerContainer>
                        <CustomImage
                            resizeMode="cover"
                            imgKey={banner?.key}
                            defaultBanner
                            defaultImage={defaultBanners && defaultBanners[defaultBannerIndex]}
                            style={imageStyle}
                        />
                    </BannerContainer>
                ) : (
                    <TraineeWebBannerHeader title={'My Credits'} />
                ))}
            <TitleText isMostBold>{isSettings ? 'Your payment methods' : 'Select your payment method'}</TitleText>
            <WalletContainer>
                {!loading && cards.length === 0 ? (
                    <CardMessage isMostBold>You do not have any cards on file. would you like to add one?</CardMessage>
                ) : (
                    <CardList
                        data={cards}
                        renderItem={renderItem}
                        keyExtractor={item => `${item.id}`}
                        ListEmptyComponent={listEmptyComponent}
                    />
                )}
                <StyledContinueButton
                    nonHover
                    color={colors.homebodyTurquoise}
                    onPress={goToAddCard}
                    text={'Add New'}
                />
            </WalletContainer>
            {isWeb && <FooterBar />}
        </ScreenContainer>
    )
}

const CardList = styled(FlatList)`
    flex: 1;
    margin-top: ${({ theme }) => (theme.isMobile ? '15' : '9')}%;
    margin-bottom: ${({ theme }) => (theme.isMobile ? '15' : '9')}%;
`
const WalletContainer = styled(ContentContainer)``
const CardMessage = styled(Heading)`
    align-self: ${({ theme }) => (theme.isMobile ? 'center' : 'flex-start')};
    text-align: ${({ theme }) => (theme.isMobile ? 'center' : 'flex-start')};
    margin-top: ${({ theme }) => (theme.isMobile ? '60%' : '9%')};
    ${({ theme }) => theme.isMobile && 'flex: 1; margin-left: 5%; margin-right: 5%;'};
    ${({ theme }) => !theme.isMobile && 'margin-bottom: 25%;'};
`
const PaymentText = styled(SmallText)`
    padding-left: 4%;
    ${({ theme }) => !theme.isMobile && 'font-size: 26px; line-height: 32px;'}
`
const CardCardContainer = styled(View)`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${({ isLoading = false }) => (isLoading ? 'flex-start' : 'space-between')};
    padding-vertical: 4%;
    padding-horizontal: 4%;
`
const CardIconContainer = styled(TouchableOpacity)`
    width: 15%;
    height: 100%;
    justify-content: center;
`
const StyledContinueButton = styled(ContinueButton)`
    ${({ theme }) => !theme.isMobile && 'width: 371px;'}
`
const TitleText = styled(HeaderText)`
    margin-top: 5%;
    margin-left: 5%;
    text-align: 'flex-start;
    align-self: flex-start;
`

const CardDetailsContainer = styled(TouchableOpacity)`
    width: 85%;
    flex-direction: row;
`
