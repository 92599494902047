import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { Heading, SubHeading, TEXT_LINEAR_GRADIENT } from 'typography'
import colors from 'colors'
import styled from 'styled-components/native'
import VectorIcon from 'components/VectorIcon'
import { useDimensions } from 'dimensions'

const emptyObj = {}

export default function ViewAllHeading({
    style,
    isGradient = false,
    headingText,
    onHeadingPress,
    viewAllText = 'View all',
    vectorIconProps = {
        family: 'MaterialIcons',
        name: 'chevron-right',
        color: colors.homebodyTurquoise,
        size: 28,
        style: emptyObj,
    },
    fontSize
}) {
    const {isMobile} = useDimensions()
    const { family, name, color, size, style: iconStyle } = vectorIconProps
    return (
        <ViewAllHeadingContainer style={style}>
            <HeadingText fontSize={fontSize} isMostBold>{headingText}</HeadingText>
            {onHeadingPress && isMobile && (
                <TouchableViewAll isGradient={isGradient} onPress={onHeadingPress}>
                    <ViewAllText isMostBold isGradient={isGradient} color={colors.homebodyTurquoise}>
                        {viewAllText}
                    </ViewAllText>
                    <VectorIcon family={family} name={name} color={color} size={size} style={iconStyle} />
                </TouchableViewAll>
            )}
        </ViewAllHeadingContainer>
    )
}

const HeadingText = styled(Heading)``
const ViewAllText = styled(SubHeading)`
    padding-left: 20px;
    ${props =>
        props.isGradient &&
        !props.theme.isMobile &&
        `
    ${TEXT_LINEAR_GRADIENT({
        direction: '90deg',
        colorStop1: '#00CCEE 6.47%',
        colorStop2: `${colors.homebodyGreen} 100%`,
    })}
    `};
`
const TouchableViewAll = styled(TouchableOpacity)`
    ${props =>
        props.isGradient &&
        !props.theme.isMobile &&
        `
    ${TEXT_LINEAR_GRADIENT({
        direction: '90deg',
        colorStop1: '#00CCEE 6.47%',
        colorStop2: `${colors.homebodyGreen} 100%`,
    })}
`};
    padding-top: 5px;
    flex-direction: row;
    align-self: center;
    align-items: center;
`
const ViewAllHeadingContainer = styled(View)`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`
