import React from 'react'
import { View } from 'react-native'

import { LinearGradient } from 'expo-linear-gradient'
import { useDimensions } from 'dimensions'
import styled from 'styled-components/native'
import colors from 'colors'

interface Props {
    isThin?: boolean
}

const gradientColors = [colors.homebodyTurquoise, colors.homebodyGreen]

const GradientBar: React.FC<Props> = ({ isThin = true }) => {
    const { isMobile } = useDimensions()
    const gradientStart = { x: 0.15, y: 0 }
    const gradientEnd = { x: 0.85, y: 0 }
    const height = isMobile || isThin ? 7 : 10
    const conteinerHeight = isMobile || isThin ? 35 : 50

    return (
        <BarContainer style={{ height: conteinerHeight }}>
            <LinearGradient
                style={{ height, opacity: 0.4 }}
                start={gradientStart}
                end={gradientEnd}
                colors={gradientColors}
            />
            <LinearGradient
                style={{ height, opacity: 0.65 }}
                start={gradientStart}
                end={gradientEnd}
                colors={gradientColors}
            />
            <LinearGradient
                style={{ height, opacity: 0.4 }}
                start={gradientStart}
                end={gradientEnd}
                colors={gradientColors}
            />
        </BarContainer>
    )
}

const BarContainer = styled(View)`
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
`

export default GradientBar
