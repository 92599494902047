import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { Inter900, Inter700, TEXT_LINEAR_GRADIENT } from 'typography'
import colors from 'colors'
import { useDimensions } from 'dimensions'
import { useNavigation } from '@react-navigation/native'
import styled from 'styled-components/native'
import VectorIcon from 'components/VectorIcon'
import * as screenNames from 'screenNames'
const creatorExperience = 'The HOMEBODY creator experience.'
const getPaid = `get paid to do what you love`
const marketplaceText =
    'The first ever marketplace for you to monetize your live workouts, connect\nwith your followers, sell your products, join a community, create your brand\nand grow your business.'
const byCreators = 'by creators, for creators'
const GetPaid: React.FC = () => {
    const { isMobile } = useDimensions()
    const navigation = useNavigation()

    const goToLogin = () => navigation.navigate(screenNames.LOGIN)

    return (
        <Container>
            <CreatorExperienceText>{creatorExperience}</CreatorExperienceText>
            <GetPaidText>{getPaid}</GetPaidText>
            <MarketplaceText>{marketplaceText}</MarketplaceText>
            <ButtonContainer>
                <ApplyToBeACreatorButton onPress={goToLogin}>
                    <ButtonText>APPLY TO BE A CREATOR</ButtonText>
                    <VectorIcon
                        family="Ionicons"
                        name="ios-arrow-forward"
                        size={22}
                        color="white"
                        style={{ paddingLeft: 5 }}
                    />
                </ApplyToBeACreatorButton>
                <LoginCreatorPortalButton onPress={goToLogin}>
                    <ButtonText color={colors.homebodyTurquoise}>LOGIN TO CREATOR PORTAL</ButtonText>
                </LoginCreatorPortalButton>
            </ButtonContainer>
            <ByCreatorsText>{byCreators}</ByCreatorsText>
        </Container>
    )
}

const Container = styled(View)`
    align-items: ${({ theme }) => (theme.isMobile ? 'flex-start' : 'center')}
    padding-top: ${({ theme }) => (theme.isMobile ? '60px' : '119px')};
    padding-bottom: ${({ theme }) => (theme.isMobile ? '80px' : '160px')};
    padding-horizontal: 5%;
    background: white;
`
const CreatorExperienceText = styled(Inter700)`
    padding-top: 26px;
    font-size: 16px;
    line-height: 23px;
    color: ${colors.subTextGray};
`
const GetPaidText = styled(Inter900)`
    ${({ theme }) =>
        !theme.isMobile &&
        TEXT_LINEAR_GRADIENT({
            direction: '90.02deg',
            colorStop1: `${colors.homebodyTurquoise} 3.02%`,
            colorStop2: `${colors.homebodyGreen} 100.13%`,
        })};
    padding-top: 26px;
    font-size: ${({ theme }) => (theme.isMobile ? '42px' : '52px')};
    line-height: ${({ theme }) => (theme.isMobile ? '42px' : '56px')};
    letter-spacing: -1px;
    text-align: ${({ theme }) => (theme.isMobile ? 'left' : 'center')};
`

const MarketplaceText = styled(Inter900)`
    font-size: 18px;
    line-height: 28px;
    text-align: ${({ theme }) => (theme.isMobile ? 'left' : 'center')};
    padding-top: 26px;
    color: ${colors.subTextGray};
`

const ButtonContainer = styled(View)`
    padding-top: 48px;
    flex-direction: ${({ theme }) => (theme.isMobile ? 'column' : 'row')};
`
const ButtonText = styled(Inter900)`
    font-size: 15px;
    line-height: 23.13px;
    color: ${({ color }) => color || 'white'};
`
const ApplyToBeACreatorButton = styled(TouchableOpacity)`
    width: 245px;
    height: 50px;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background-color: black;
`
const LoginCreatorPortalButton = styled(ApplyToBeACreatorButton)`
    border-width: 1px;
    margin-top: ${({ theme }) => (theme.isMobile ? '25px' : '0px')}
    border-color: ${colors.homebodyTurquoise};
    background-color: white;
`
const ByCreatorsText = styled(Inter900)`
    padding-top: 26px;
    font-size: 18px;
    line-height: 28px;
`

export default GetPaid
