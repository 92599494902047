import React from 'react'
import { View } from 'react-native'
import colors from 'colors'
import styled from 'styled-components/native'
import { formatDate, toStandardTime } from 'helpers'
import { HeaderText, SubHeaderText, SmallText } from 'typography'
import { useDimensions } from 'dimensions'

const Detail = ({ name, detail, isAbout }) => {
    const { isMobile } = useDimensions()
    const isDescription = name.substring(0, 5) == 'About'
    return (
        <DetailContainer isAbout={isAbout}>
            <NameText paddingTop={isMobile ? 10 : 20} isMostBold>
                {name}
            </NameText>
            <DetailText paddingTop={isMobile ? 10 : 20} color={isDescription ? 'black' : colors.homebodyTurquoise}>
                {detail}
            </DetailText>
        </DetailContainer>
    )
}

const EquipmentDetail = ({ name, detail, isAbout }) => {
    const { isMobile } = useDimensions()
    const isDescription = name.substring(0, 5) == 'About'
    const equipments = detail.split(',')

    return (
        <DetailContainer isAbout={isAbout}>
            <NameText paddingTop={isMobile ? 10 : 20} isMostBold>
                {name}
            </NameText>
            {equipments?.map((equipment, index) => {
                return (
                    <DetailText
                        paddingTop={isMobile ? 10 : 20}
                        color={isDescription ? 'black' : colors.homebodyTurquoise}
                        key={index}
                    >
                        {equipment.trim()}
                    </DetailText>
                )
            })}
        </DetailContainer>
    )
}

const MobileDetails = ({ Class }) => {
    const {
        name,
        description,
        price,
        date: beforeDateFormat,
        startTime: beforeTimeFormat,
        duration,
        equipment,
    } = Class || {}

    const date = formatDate(beforeDateFormat)
    const startTime = toStandardTime(beforeTimeFormat)
    const dateTime = `${date} ${startTime}`

    return (
        <>
            <ClassOverviewText isMostBold>{name}</ClassOverviewText>
            <Detail isAbout name={`About the Class`} detail={description} />
            <DetailsContainer>
                <Detail name={'Price'} detail={`${price} credits`} />
                <Detail name={'Live on'} detail={dateTime} />
                <Detail name={'Duration'} detail={`${duration} min`} />
                {!!equipment && <Detail name={'Equipment needed'} detail={equipment} />}
            </DetailsContainer>
        </>
    )
}

const WebDetails = ({ Class }) => {
    const {
        name,
        description,
        price,
        date: beforeDateFormat,
        startTime: beforeTimeFormat,
        duration,
        equipment,
    } = Class || {}

    const date = formatDate(beforeDateFormat)
    const startTime = toStandardTime(beforeTimeFormat)
    const dateTime = `${date} ${startTime}`

    return (
        <>
            <ClassOverviewText isMostBold>Class Overview</ClassOverviewText>
            <Detail isAbout name={`About ${name}`} detail={description} />
            <DetailsContainerWeb>
                <Detail name={'Live on'} detail={dateTime} />
                <Detail name={'Duration'} detail={`${duration} min`} />
                {!!equipment && <EquipmentDetail name={'Equipment needed'} detail={equipment} />}
                <Detail name={'Price'} detail={`${price} credits`} />
            </DetailsContainerWeb>
        </>
    )
}

export const ClassOverview = ({ style, Class }) => {
    const { isMobile } = useDimensions()

    return (
        <ClassOverviewContainer style={style}>
            {isMobile ? <MobileDetails Class={Class} /> : <WebDetails Class={Class} />}
        </ClassOverviewContainer>
    )
}
const ClassOverviewText = styled(HeaderText)`
    padding-top: 5%;
    align-self: flex-start;
`
const ClassOverviewContainer = styled(View)`
    align-self: flex-start;
    margin-top: 5%;
    ${props => !props.isMobile && 'width: 100%;'}
`
const DetailContainer = styled(View)`
    width: 100%;
    ${props => props.isAbout && props.theme.isMobile && 'padding-bottom: 10%;'}
    max-width: ${({ theme, isAbout }) =>
        !theme.isMobile && !isAbout ? '33.33%' : isAbout && !theme.isMobile ? '60%' : '100%'};
    margin-top: ${({ theme, isAbout }) => (!theme.isMobile && !isAbout ? '58px' : '0px')};
`
const NameText = styled(SubHeaderText)`
    font-size: ${({ theme }) => (theme.isMobile ? '14' : '28')}px;
`
const DetailText = styled(SmallText)`
    font-size: ${({ theme }) => (theme.isMobile ? '12' : '25')}px;
    line-height: ${({ theme }) => (theme.isMobile ? '14' : '28')}px;
`

const DetailsContainer = styled(View)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

const DetailsContainerWeb = styled(View)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
`
export default ClassOverview
