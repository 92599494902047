import React, { useState, useEffect } from 'react'
import { View, TextInput, ActivityIndicator } from 'react-native'
//constants
import { NOTIFICATION_TYPES, PAGINATED_LIMIT } from '@constants'
const { INSTRUCTOR_CLASS_STARTING_SOON, TRAINEE_LEFT_REVIEW } = NOTIFICATION_TYPES
// ui
import colors from 'colors'
import { TitleText, Paragraph } from 'typography'
import styled from 'styled-components/native'
import { useDimensions } from 'dimensions'
import { useIsFocused } from '@react-navigation/native'
// components
import { ScreenContainer, ContentContainer } from 'commons/containers'
import VectorIcon from 'commons/components/VectorIcon'
import NotificationFlatList from 'commons/components/NotificationFlatList'
import { getCurrentUser, getNotificationsByUserID, getNumNewNotifications } from 'selectors'
import { ContinueButton } from 'components/ContinueButton'
import { RatingMap } from 'components/RateIcon'
import { FooterBar } from 'commons/components/FooterBar'

//helpers
const emptyObj = {}
const noop = () => {}

function getSelectedNotificationContent({ type, data, notificationAction = noop }) {
    switch (type) {
        case TRAINEE_LEFT_REVIEW:
            const { rating = 0 } = JSON?.parse(data) || emptyObj
            if (rating == 0) return
            else {
                return <RatingMap rating={rating} style={{ marginTop: 25 }} />
            }

        case INSTRUCTOR_CLASS_STARTING_SOON:
            return (
                <ContinueButtonContainer>
                    <ContinueButton onPress={notificationAction} text="Go to my Class" />
                </ContinueButtonContainer>
            )

        default:
            break
    }
}

const SelectedNotification = ({ loading, numNotifications, selectedNotification = emptyObj }) => {
    const { type, notificationAction, content: { title, body, data } = emptyObj, createdAt } = selectedNotification
    const initialNotificationBody = `You have ${numNotifications} new notifications`
    const notificationTitle = title ?? 'Notifications'
    const notificationBody = body ?? initialNotificationBody
    const selectedNotificationContent = getSelectedNotificationContent({ type, data, notificationAction })

    return (
        <SelectedNotificationContainer>
            <NotificationTitle isMostBold>{notificationTitle}</NotificationTitle>
            {loading ? (
                <StyledActivityIndicator size={'large'} color={colors.homebodyTurquoise} animating />
            ) : (
                <>
                    <NotificationBody isRegular>{notificationBody}</NotificationBody>
                    {selectedNotificationContent}
                </>
            )}
        </SelectedNotificationContainer>
    )
}

function Notifications({ navigation, route }) {
    const [placeholder, setPlaceholder] = useState('Search for notification')
    const [searchText, setSearchText] = useState('')
    const [selectedNotification, setSelectedNotification] = useState(undefined)
    const { id: userID } = getCurrentUser() || emptyObj
    const [nextToken, setNextToken] = useState('')
    const [allNotifications, setAllNotifications] = useState([])
    const [loading, setLoading] = useState(true)

    const isFocused = useIsFocused()

    const {
        notifications,
        loading: notificationsLoading,
        refetch: refetchNotifications,
        nextToken: initialToken,
        fetchMore,
    } = getNotificationsByUserID({
        userID,
        onCompleted: () => {
            if (allNotifications?.length === 0 && !nextToken) {
                setNextToken(initialToken)
                setAllNotifications(notifications)
            }
        },
    })

    async function handleFetchMore() {
        if (nextToken) {
            const fetchMoreResult = await fetchMore({ variables: { nextToken } })
            const {
                data: {
                    notificationsByUserID: { nextToken: token = null, items: moreNotifications = emptyArr },
                } = emptyObj,
            } = fetchMoreResult
            const newNotifications =
                allNotifications?.length > 0 ? [...allNotifications, ...moreNotifications] : [...moreNotifications]
            setAllNotifications(newNotifications)
            setNextToken(token)
            token == null && setLoading(false)
        } else {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (nextToken) {
            handleFetchMore()
        } else {
            setLoading(false)
        }
    }, [nextToken])

    const numNewNotifications =
        allNotifications?.length > 0 ? allNotifications?.reduce((n, x) => n + (x.isNew === true), 0) : 0

    const onPress = notification => {
        setSelectedNotification(notification)
    }

    return (
        <ScreenContainer hasTopPadding>
            <ContentContainer isFluid>
                <InnerContainer>
                    <NotificationListContainer>
                        <SearchBarContainer>
                            <MagnifyingGlassContainer>
                                <VectorIcon
                                    family="SimpleLineIcons"
                                    name="magnifier"
                                    size={19}
                                    color={colors.subTextGray}
                                />
                            </MagnifyingGlassContainer>
                            <SearchBar
                                placeholder={placeholder}
                                value={searchText}
                                onChangeText={setSearchText}
                                onSubmitEditing={noop}
                            />
                        </SearchBarContainer>
                        <NotificationFlatList
                            isInstructor
                            data={allNotifications}
                            onPress={onPress}
                            selected={selectedNotification}
                        />
                    </NotificationListContainer>
                    <SelectedNotification
                        loading={loading}
                        numNotifications={numNewNotifications}
                        selectedNotification={selectedNotification}
                    />
                </InnerContainer>
            </ContentContainer>
            <FooterBar />
        </ScreenContainer>
    )
}

const ContinueButtonContainer = styled(View)`
    margin-top: 7.5%;
    width: 35%;
`
const StyledActivityIndicator = styled(ActivityIndicator)`
    margin-top: 3%;
    align-self: flex-start;
`

const SelectedNotificationContainer = styled(View)`
    flex: 1;
    flex-direction: column;
    margin-left: 3%;
`

const NotificationTitle = styled(TitleText)`
    margin-top: 5%;
    margin-right: 3%;
    letter-spacing: -1px;
`

const NotificationBody = styled(Paragraph)`
    margin-top: 3%;
    margin-right: 3%;
    letter-spacing: -1px;
`

const SearchBarContainer = styled(View)`
    flex-direction: row;
    align-items: center;
    margin-top: 20%;
    margin-bottom: 10%;
    margin-left: 5%;
    margin-right: 5%;
`
const SearchBar = styled(TextInput)`
    flex: 1;
    width: 100%;
    height: 16px;
    margin-left: 12%;
    margin-right: 5%;
    padding-left: 20px;
    font-family: Inter_600SemiBold;
    placeholder-text-color: ${colors.subTextGray}
    background-color: ${colors.white};
    color: ${colors.homebodyTurquoise};
    outline-width: 0px;
    overflow: hidden;
`
const MagnifyingGlassContainer = styled(View)`
    position: absolute;
    z-index: 10;
    margin-left: 15px;
    margin-right: 25px;
`

const InnerContainer = styled(View)`
    flex: 1;
    flex-direction: row;
    border-radius: 13px;
    shadow-color: #000000;
    shadow-offset: 4px 4px;
    shadow-radius: 10px;
    shadow-opacity: 0.1;
`

const NotificationListContainer = styled(View)`
    width: 25%;
    height: 100%;
    border-radius: 13px;
    shadow-color: #000000;
    shadow-offset: 0px 20px;
    shadow-radius: 20px;
    shadow-opacity: 0.1;
`

export default Notifications
