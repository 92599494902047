import { Text } from 'react-native'
import styled from 'styled-components/native'
import colors from 'colors'

export const Inter100 = styled(Text)`
    font-family: Inter_100Thin;
`
export const Inter200 = styled(Text)`
    font-family: Inter_200ExtraLight;
`
export const Inter300 = styled(Text)`
    font-family: Inter_300Light;
`
export const Inter400 = styled(Text)`
    font-family: Inter_400Regular;
`
export const Inter500 = styled(Text)`
    font-family: Inter_500Medium;
`
export const Inter600 = styled(Text)`
    font-family: Inter_600SemiBold;
`
export const Inter700 = styled(Text)`
    font-family: Inter_700Bold;
`
export const Inter800 = styled(Text)`
    font-family: Inter_800ExtraBold;
`
export const Inter900 = styled(Text)`
    font-family: Inter_900Black;
`

const TextProps = styled(Text)`
    color: ${({ color }) => (color ? color : 'black')};
    position: ${({ position }) => (position ? position : 'relative')};
    text-align: ${({ textAlign }) => (textAlign ? textAlign : 'left')};
    padding-top: ${({ paddingTop }) => (paddingTop ? `${paddingTop}px` : '0px')};
    margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : '0px')};
    padding-bottom: ${({ paddingBottom }) => (paddingBottom ? `${paddingBottom}px` : '0px')};
    margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '0px')};
    padding-left: ${({ paddingLeft }) => (paddingLeft ? `${paddingLeft}px` : '0px')};
    margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : '0px')};
    padding-right: ${({ paddingRight }) => (paddingRight ? `${paddingRight}px` : '0px')};
    margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}px` : '0px')};
`

export const TitleText = styled(TextProps)`
    font-family: ${({ isMostBold }) => (isMostBold ? 'Inter_900Black' : 'Inter_700Bold')};
    font-size: 37px;
    line-height: 44px;
`
export const Caption = styled(TextProps)`
    font-family: ${({ isThin, isSemiBold }) =>
        isThin ? 'Inter_400Regular' : isSemiBold ? 'Inter_600SemiBold' : 'Inter_500Medium'};
    font-size: ${({ isSmall }) => (isSmall ? '11' : '13')}px;
    line-height: ${({ theme }) => (theme.isMobile ? '18' : '22')}px;
    color: ${props => (props.color ? props.color : 'black')};
`
export const HeaderText = styled(TextProps)`
    font-family: Inter_900Black;
    font-size: ${({ theme, isLargeHeader }) => (theme.isMobile ? '28' : isLargeHeader ? '63' : '48')}px;
    line-height: ${({ theme }) => (theme.isMobile ? 50 : 70)}px;
    margin-vertical: ${({ theme }) => (theme.isMobile ? 10 : 20)}px;
    letter-spacing: -1px;
`
export const SubHeaderText = styled(TextProps)`
    font-family: ${({ isBold, isMostBold }) =>
        isMostBold ? 'Inter_900Black' : isBold ? 'Inter_700Bold' : 'Inter_500Medium'};
    letter-spacing: -1px;
    color: ${props => (props.color ? props.color : props.isSubtext ? colors.subTextGray : 'black')};
    font-size: ${({ theme, isLargeSubheader }) => (theme.isMobile ? 18 : isLargeSubheader ? 29 : 22)}px;
`
export const SubHeading = styled(TextProps)`
    font-family: ${({ isBold, isMostBold }) =>
        isMostBold ? 'Inter_900Black' : isBold ? 'Inter_700Bold' : 'Inter_500Medium'};
    letter-spacing: -1px;
    line-height: 24px;
    color: ${props => (props.color ? props.color : props.isSubtext ? colors.subTextGray : 'black')};
    font-size: ${({ theme, fontSize }) => (fontSize ? fontSize : theme.isMobile ? 14 : 18)}px;
    ${props => props.maxWidth && `max-width: ${props.maxWidth};`}
`
export const Paragraph = styled(TextProps)`
    font-size: ${({ theme, fontSize }) => (fontSize ? fontSize : theme.isMobile ? '16' : '20')}px;
    letter-spacing: -1px;
    font-family: ${({ isBold, isRegular }) =>
        isBold ? 'Inter_700Bold' : isRegular ? 'Inter_400Regular' : 'Inter_500Medium'};
    color: ${props => (props.color ? props.color : props.isSubtext ? colors.subTextGray : 'black')};
`
export const CallToActionText = styled(TextProps)`
    font-family: Inter_900Black;
    font-size: 45px;
    line-height: 54px;
    letter-spacing: -0.32px;
    color: ${props => (props.color ? props.color : 'white')};
`
export const Heading = styled(TextProps)`
    font-size: ${({ theme, isLargeHeading, fontSize }) =>
        fontSize ? fontSize : theme.isMobile ? 21 : isLargeHeading ? 40 : 29}px;
    line-height: ${({ theme }) => (theme.isMobile ? 25 : 38)}px;
    letter-spacing: -0.5px;
    font-family: ${({ isBold, isMostBold }) =>
        isMostBold ? 'Inter_900Black' : isBold ? 'Inter_700Bold' : 'Inter_500Medium'};
    color: ${props => (props.color ? props.color : props.isSubtext ? colors.subTextGray : 'black')};
    ${props => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
`
export const Headline = styled(TextProps)`
    font-size: ${({ isSubHeadline, isLargeHeadline, isProfileHeadline }) =>
        isSubHeadline ? 20 : isProfileHeadline ? 29 : isLargeHeadline ? 40 : 24}px;
    line-height: ${({ isProfileHeadline }) => (isProfileHeadline ? '38px' : '32px')}
    font-family: ${({ isBold, isMostBold }) =>
        isMostBold ? 'Inter_900Black' : isBold ? 'Inter_700Bold' : 'Inter_500Medium'};
    color: ${props => (props.color ? props.color : props.isSubtext ? colors.subTextGray : 'black')};
`
export const SmallText = styled(TextProps)`
    font-size: ${({ theme }) => (theme.isMobile ? 14 : 16)}px;
    line-height: 14.52px;
    letter-spacing: -1px;
    font-family: ${({ isBold }) => (isBold ? 'Inter_700Bold' : 'Inter_500Medium')};
    color: ${props => (props.color ? props.color : props.isSubtext ? colors.subTextGray : 'black')};
`
export const HeaderBarText = styled(TextProps)`
    font-family: Inter_600SemiBold;
    font-size: 18px;
    line-height: 22px;
`
export const FormHeaderLabel = styled(Inter700)`
    font-size: ${({ theme }) => (theme.isMobile ? 18 : 22)}px;
    margin-vertical: ${({ theme }) => (theme.isMobile ? 15 : 20)}px;
`
export const TabBarText = styled(TextProps)`
    font-size: ${({ theme }) => (theme.isMobile ? 10 : 12)}px;
    color: ${props => (props.color ? props.color : props.isSubtext ? colors.subTextGray : 'black')};
    font-family: Inter_900Black;
    line-height: 11px;
`
export const ButtonText = styled(TextProps)`
    font-family: Inter_700Bold;
    font-size: ${({ theme, fontSize }) => (fontSize ? fontSize : theme.isMobile ? 16 : 20)}px;
    line-height: ${({ theme }) => (theme.isMobile ? 16 : 20)}px;
    color: ${props => (props.color ? props.color : 'white')};
    ${props => props.textUppercase && 'text-transform: uppercase;'}
`
export const ShortButtonText = styled(TextProps)`
    font-family: Inter_700Bold;
    font-size: ${({ theme }) => (theme.isMobile ? 13 : 14)}px;
    line-height: ${({ theme }) => (theme.isMobile ? 18 : 18)}px;
    color: ${props => (props.color ? props.color : 'white')};
`
export const TopTabText = styled(TextProps)`
    font-family: Inter_600SemiBold;
    font-size: ${({ theme }) => (theme.isMobile ? 14 : 16)}px;
    line-height: ${({ theme }) => (theme.isMobile ? 15 : 20)}px;
    color: ${props => (props.color ? props.color : colors.subTextGray)};
`

export const TimeSliderLabel = styled(TextProps)`
    font-family: Inter_700Bold;
    font-size: ${({ theme }) => (theme.isMobile ? 10 : 14)}px;
    line-height: ${({ theme }) => (theme.isMobile ? 12 : 18)}px;
    color: ${colors.grayContinue};
`

export const TEXT_LINEAR_GRADIENT = ({
    direction = '90deg',
    colorStop1 = `${colors.homebodyTurquoise} 3.23%`,
    colorStop2 = `${colors.homebodyGreen} 70.45%`,
} = {}) => `
background-image: linear-gradient(${direction}, ${colorStop1}, ${colorStop2});
text-fill-color: transparent;
background-clip: text;
-webkit-background-clip: text;
-moz-background-clip: text;
-webkit-text-fill-color: transparent;
-moz-text-fill-color: transparent;
`
