import React, { useState, useEffect, useCallback } from 'react'
import { useNavigation } from '@react-navigation/core'
import { Text, View, TouchableOpacity } from 'react-native'
import colors from 'colors'
import styled from 'styled-components/native'
import { isWeb } from '@constants'
import { Heading, SubHeaderText, Paragraph, ButtonText, HeaderText } from 'typography'
import { instructorApplicationForm } from 'apollo/cache'
import { initialInstructorApplicationForm } from 'initialFormStates'
import { ColumnToRowContainer, StandardContainer } from 'commons/containers'
import { BUTTON_BORDER_RADIUS } from 'constants/constants'
import * as screenNames from 'screenNames'
import { useDimensions } from 'dimensions'
import { getCurrentUser } from 'apollo/selectors'
import { usePushNotificationPermissions } from 'hooks/useNativePermissions'
import { stripeAPI, updateUserCustomerID } from 'src/actions'
import { useMutation } from 'apollo-augmented-hooks'
import { UPDATE_USER } from 'apollo/mutations'
import { STRIPE_API_ROUTES } from '@constants'
import { FooterBar } from 'commons/components/FooterBar'
import { cache } from 'apollo/cache'
const { CREATE_CUSTOMER } = STRIPE_API_ROUTES

const TEACH_PARAGRAPH = `I want to build my brand and grow my business on the HOMEBODY app by joining an exclusive network of trainers, influencers and celebrities.`
const TAKE_PARAGRAPH = `I want to take classes on the HOMEBODY app and gain access to a network and community of world class influencers, trainers and celebrities.`

const OnboardingSection = ({ title, paragraph, buttonText, buttonColor, onPress }) => {
    return (
        <OnboardingSectionContainer>
            <SubHeaderText isBold textAlign="center">
                {title}
            </SubHeaderText>
            <SectionSpacer />
            <Paragraph isSubtext textAlign="center">
                {paragraph}
            </Paragraph>
            <SectionSpacer />
            <SectionSpacer />
            <StyledButton width="260px" style={{ height: 40.11 }} color={buttonColor} onPress={onPress}>
                <ButtonText textAlign="center" style={{ fontSize: 14.32 }} color={buttonColor}>
                    {buttonText}
                </ButtonText>
            </StyledButton>
        </OnboardingSectionContainer>
    )
}

export const Onboarding = ({}) => {
    const navigation = useNavigation()
    const [customerCreated, setCustomerCreated] = useState(false)
    const {
        isMobileWeb,
        deviceDimensions: { deviceHeight },
    } = useDimensions()
    const { firstName, lastName, email, id, customerID, pushToken, _version } = getCurrentUser()

    const { pushNotificationPermissions, registerPushNotifications } = usePushNotificationPermissions({
        id,
        _version,
    })

    const navigateToTeach = useCallback(
        () =>
            navigation.navigate(screenNames.INSTRUCTOR_APPLICATION.NAVIGATOR, {
                screen: screenNames.INSTRUCTOR_APPLICATION.START_TRAINER_APP,
            }),
        [],
    )
    const navigateToTake = useCallback(() => {
        instructorApplicationForm(initialInstructorApplicationForm)
        navigation.navigate(screenNames.TRAINEE_NAVIGATOR, { initial: true })
    }, [])

    async function handleRegisterPushNotifications() {
        await registerPushNotifications()
    }

    useEffect(() => {
        async function createCustomer() {
            const customerResult = await stripeAPI({
                path: CREATE_CUSTOMER,
                body: { firstName, lastName, email, userID: id },
            })
            const normalizedID = cache.identify({
                id: id,
                __typename: 'User',
            })
            cache.modify({
                id: normalizedID,
                fields: {
                    customerID() {
                        return customerResult?.id
                    },
                },
            })
            setCustomerCreated(true)
        }

        const shouldRegisterPushNotifications = id && pushToken === '' && !isWeb
        shouldRegisterPushNotifications && handleRegisterPushNotifications()

        const shouldCreateCustomer = id && customerID === '' && !customerCreated
        shouldCreateCustomer && createCustomer()
    }, [id, customerID])

    return (
        <>
            <StandardContainer>
                <OnboardingContainer>
                    <HeaderText textAlign="center" isBold paddingBottom={isWeb ? 0 : deviceHeight * 0.2}>
                        Welcome to HOMEBODY
                    </HeaderText>
                    <ColumnToRowContainer>
                        {isWeb && (
                            <OnboardingSection
                                title="I want to teach classes"
                                paragraph={TEACH_PARAGRAPH}
                                buttonText="Start Application"
                                buttonColor={colors.homebodyTurquoise}
                                onPress={navigateToTeach}
                            />
                        )}
                        <OnboardingSection
                            title="I want to take classes"
                            paragraph={TAKE_PARAGRAPH}
                            buttonText="Browse Classes"
                            buttonColor={colors.homebodyGreen}
                            onPress={navigateToTake}
                        />
                    </ColumnToRowContainer>
                </OnboardingContainer>
            </StandardContainer>
            {isWeb && !isMobileWeb && <FooterBar />}
        </>
    )
}

const OnboardingContainer = styled(View)`
    flex: 1;
    justify-content: space-evenly;
    align-self: center;
    max-width: ${props => (props.theme.isMobile ? '100%' : '450px')};
`

const StyledButton = styled.TouchableOpacity`
    width: ${props => props.width || '100%'};
    height: ${props => (props.theme.isMobile ? '60px' : '60px')};
    border-radius: ${BUTTON_BORDER_RADIUS};
    border-color: ${props => props.color || colors.blueContinue};
    border-width: 2px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const SectionSpacer = styled(View)`
    height: 20px;
`

const OnboardingSectionContainer = styled(View)`
    width: 100%;
    max-height: ${({ theme }) => (theme.isMobile ? '100%' : '300px')};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-horizontal: ${({ theme }) => (theme.isMobile ? 0 : 20)}px;
`

export default Onboarding
