import React, { useState, useEffect } from 'react'
import { useNavigation, useRoute } from '@react-navigation/core'
import { Text, View, TouchableOpacity } from 'react-native'
import { FieldContainer } from 'commons/containers'
import VectorIcon from 'components/VectorIcon'
import { Paragraph } from 'typography'
import { isWeb } from '@constants'
import colors from 'colors'
import styled from 'styled-components/native'
import CustomImage from 'components/CustomImage'
import BackButton from 'commons/components/BackButton'
import { PUBLISHED_CLASSES } from 'screenNames'

const emptyObj = {}

const EditButton = ({ style, isCreateClassForm, onPress, editText = 'Edit' }) => {
    if (isCreateClassForm) {
        return (
            <EditButtonContainer style={style} onPress={onPress}>
                <VectorIcon family="SimpleLineIcons" name="pencil" size={18} color="white" />
                <ButtonText>{editText}</ButtonText>
            </EditButtonContainer>
        )
    } else {
        return null
    }
}

const Detail = ({ detail, error = false, handleEdit, isCreateClassForm }) => {
    return (
        <NameDetails>
            <RowContainer>
                <TextDetails color={error ? 'red' : 'black'} isBold={!isCreateClassForm}>
                    {detail}
                </TextDetails>
            </RowContainer>
            <EditButton isCreateClassForm={isCreateClassForm} onPress={handleEdit} />
        </NameDetails>
    )
}

const ClassDetails = ({ classDetails, isCreateClassForm }) => {
    return (
        <FieldContainer>
            {classDetails?.map(
                ({ detail, handleEdit, error }) =>
                    !!detail && (
                        <Detail
                            key={detail}
                            detail={detail}
                            error={error}
                            handleEdit={handleEdit}
                            isCreateClassForm={isCreateClassForm}
                        />
                    ),
            )}
        </FieldContainer>
    )
}

export default function ClassReview({
    style,
    isCreateClassForm = true,
    Class = emptyObj,
    classDetails,
    navActions,
    defaultBanners,
}) {
    const navigation = useNavigation()
    const { params = emptyObj } = useRoute()

    const { id, name, description, banner, defaultBannerIndex } = Class

    const DEFAULT_BANNER = defaultBanners && !banner?.key ? defaultBanners[defaultBannerIndex] : ''

    const goToPublishedClasses = () => navigation.navigate(PUBLISHED_CLASSES)

    return (
        <ClassReviewContainer style={style} isCreateClassForm={isCreateClassForm}>
            {!isCreateClassForm && !isWeb && (
                <BackButton
                    size={40}
                    onPress={goToPublishedClasses}
                    containerStyle={{ position: 'absolute', alignSelf: 'flex-start', left: '-4%', top: -2 }}
                />
            )}
            <ImageContainer>
                <CustomImage imgKey={banner?.key} style={{ height: '100%', width: '100%' }} />
                <EditBannerButton
                    isCreateClassForm={isCreateClassForm}
                    onPress={navActions?.goToBanner}
                    editText="Edit banner"
                />
            </ImageContainer>
            <ClassDetailsContainer>
                <ClassDetailsText>Class Details:</ClassDetailsText>
                <WideNameDetails>
                    <ClassNameText isBold>{name}</ClassNameText>
                    <EditButton onPress={navActions?.goToDetails} />
                </WideNameDetails>
                <WideNameDetails>
                    <ClassNameText isSmall isBold={!isCreateClassForm} paddingBottom={!isCreateClassForm ? 25 : 0}>
                        {description}
                    </ClassNameText>
                    <EditButton onPress={navActions?.goToDetails} />
                </WideNameDetails>
            </ClassDetailsContainer>
            <ClassDetails classDetails={classDetails} isCreateClassForm={isCreateClassForm} />
        </ClassReviewContainer>
    )
}

const EditBannerButton = styled(EditButton)`
    margin-top: 10px;
`
const ClassNameText = styled(Paragraph)`
    font-size: ${props => (props?.isCreateClassForm && !props?.isSmall ? '26.42' : '20')}px;
    line-height: ${props => (props?.isCreateClassForm ? '32' : '24')}px;
`
const ClassDetailsContainer = styled(View)`
    flex-direction: column;
    justify-content: space-between;
`
const ClassDetailsText = styled(Text)`
    font-size: 36px;
    line-height: 44px;
    font-weight: bold;
    margin-bottom: 10px;
`
const RowContainer = styled(View)`
    flex-direction: row;
`
export const EditButtonContainer = styled(TouchableOpacity)`
    flex-direction: row;
    background-color: ${colors.blueContinue};
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    border-radius: 6.4px;
    padding-horizontal: 10px;
    padding-vertical: 5px;
    align-self: flex-end;
`
export const ButtonText = styled(Text)`
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    align-self: center;
    padding-left: 5px;
`
const NameDetails = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    flex: 1;
`
const WideNameDetails = styled(NameDetails)`
    width: 80%;
    max-width: 800px;
`
const TextDetails = styled(Paragraph)`
    max-width: ${props => (props.theme.isMobile ? '75%' : '100%')};
`
const ImageContainer = styled(View)`
    padding-bottom: 50px;
    width: ${props => (props?.isCreateClassForm ? '600' : '600')}px;
    height: 380px;
`
const ClassReviewContainer = styled(View)``
