import React, { useEffect } from 'react'
import { View, FlatList } from 'react-native'
import colors from 'colors'
import styled from 'styled-components/native'
import { useDimensions } from 'dimensions'
import { INSTRUCTOR_DRAWER_WIDTH } from '@constants'
import { Heading, SubHeaderText, Paragraph, SubHeading } from 'typography'
import WaitingRoomProgress from 'react-native-progress/Bar'
import TraineeAvatar from 'components/TraineeAvatar'
import ClassBannerImage from 'components/CustomImage'
import LinearGradientView from 'components/LinearGradientView'
import LetUsersInButton from 'components/streaming/StreamRoomButton'

const RenderIntervalDetail = ({ item }) => {
    const { type, duration, timeType, name } = item
    const convertedType = type === 'WARMUP' ? 'warm up' : type == 'COOLDOWN' ? 'cool down' : type.toLowerCase()
    return (
        <IntervalDetailContainer>
            <IntervalText isBold isSubtext>
                {convertedType}
            </IntervalText>
            <IntervalText isSubtext>{`${duration} ${timeType}`}</IntervalText>
            <IntervalText isSubtext>{name}</IntervalText>
        </IntervalDetailContainer>
    )
}

const RenderTrainee = ({ item }) => {
    const { firstName, lastName, avatarKey } = item || {}
    const nameString = `${firstName} ${lastName?.charAt(0)?.toUpperCase()}.`
    return (
        <TraineeContainer>
            <StyledTraineeAvatar size={60} userInfo={item} avatarImageKey={avatarKey} />
            <TraineeNameText isBold isSubtext paddingLeft={15}>
                {nameString}
            </TraineeNameText>
        </TraineeContainer>
    )
}

export const InstructorStreamRoomDetails = ({ reactiveStreamProps }) => {
    const { windowDimensions } = useDimensions()
    const { windowWidth: widthWithDrawer } = windowDimensions
    const windowWidth = widthWithDrawer - INSTRUCTOR_DRAWER_WIDTH
    const { streamState, hostEventEmitters } = reactiveStreamProps
    const { letUsersIn } = hostEventEmitters
    const { instructorClass, numTraineesBooked, traineesAttendingClass, streamRoomLocked } = streamState
    const { name: className, intervals, banner, defaultBannerIndex, duration } = instructorClass || {}
    const numTraineesWaiting = traineesAttendingClass?.length

    return (
        <ClassDetailsContainer>
            <ClassBannerImage hasShadow resizeMode="stretch" style={{ height: '20%', width: '100%' }} />
            <ClassNameContainer>
                <Heading isBold color="white">
                    {className}
                </Heading>
            </ClassNameContainer>
            {streamRoomLocked ? (
                <>
                    <TraineeDetailsContainer>
                        <UsersBookedText isBold isSubtext paddingTop={25}>
                            {numTraineesBooked} users booked this class
                        </UsersBookedText>
                        <UsersWaitingText isBold isSubtext paddingTop={25}>
                            {numTraineesWaiting} users in your waiting room
                        </UsersWaitingText>
                        <UsersWaitingProgressContainer>
                            <WaitingRoomProgress
                                color={colors.homebodyTurquoise}
                                progress={numTraineesWaiting / numTraineesBooked || 0.0}
                                width={windowWidth * 0.25}
                                unfilledColor={colors.progressGray}
                                borderWidth={0}
                            />
                        </UsersWaitingProgressContainer>
                    </TraineeDetailsContainer>
                    <IntervalsContainer>
                        <IntervalsFlatList data={intervals} renderItem={RenderIntervalDetail} />
                    </IntervalsContainer>
                    <TotalTimeContainer>
                        <ClassDurationText isBold isSubtext paddingTop={15} paddingLeft={30}>
                            TOTAL TIME: {duration} min
                        </ClassDurationText>
                        <LetUsersInButton onPress={letUsersIn} buttonText="let users in" />
                        <FooterLinearGradient gradientColors={[colors.homebodyGreen, colors.homebodyTurquoise]} />
                    </TotalTimeContainer>
                </>
            ) : (
                <TraineesFlatList
                    data={traineesAttendingClass}
                    renderItem={RenderTrainee}
                    keyExtractor={item => item?.uid?.toString()}
                />
            )}
        </ClassDetailsContainer>
    )
}

const StyledTraineeAvatar = styled(TraineeAvatar)`
    height: 100px;
    flex-direction: column;
    justify-content: center;
`
const TraineesFlatList = styled(FlatList)`
    height: 100%;
`
const TraineeNameText = styled(SubHeading)`
    font-size: 20px;
    width: 75%;
`
const TraineeContainer = styled(View)`
    width: 100%;
    height: 100px;
    padding-left: 30px;
    align-self: flex-start;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom-width: 1px;
    border-color: ${colors.dividingGray};
`
const TraineesInClassContainer = styled(View)`
    width: 100%;
    height: 100%;
`
const ClassDetailsContainer = styled(View)`
    flex-direction: column;
    width: 30%;
    height: 100%;
`
const ClassNameContainer = styled(View)`
    height: 20%;
    width: 100%'
    position: absolute;
    justify-content: flex-end;
    padding-bottom: 20px;
    padding-left: 20px;
`
const UsersWaitingProgressContainer = styled(View)`
    margin-vertical: 20px;
`
const UsersBookedText = styled(SubHeaderText)``
const UsersWaitingText = styled(Paragraph)``
const TraineeDetailsContainer = styled(View)`
    height: 15%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-horizontal: 15px;
    border-bottom-width: 1px;
    border-color: ${colors.dividingGray};
`
const ClassDurationText = styled(SubHeaderText)`
    align-self: flex-start;
`
const TotalTimeContainer = styled(View)`
    flex-direction: column;
    justify-content: space-between;
    height: 25%;
`
const IntervalsContainer = styled(View)`
    height: 35%;
    width: 100%;
    border-bottom-width: 1px;
    border-color: ${colors.dividingGray};
`
const IntervalsFlatList = styled(FlatList)`
    padding-left: 25px;
    padding-right: 25px;
`
const IntervalText = styled(SubHeading)`
    width: 33%;
`
const IntervalDetailContainer = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    padding-vertical: 10px;
`
const FooterLinearGradient = styled(LinearGradientView)`
    width: 100%;
    height: 10px;
`
export default InstructorStreamRoomDetails
