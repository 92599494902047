import axios from 'axios'
import { agoraStreamState } from 'apollo/cache'
import { initialAgoraStreamState } from 'initialFormStates'
import { Auth, API } from 'aws-amplify'
import { ENVIRONMENT_VARIABLES } from '@constants'
const { AGORA_APP_ID = '', AGORA_TOKEN_LAMBDA_ARN = '' } = ENVIRONMENT_VARIABLES
import Lambda from 'aws-sdk/clients/lambda'
import AWS from 'aws-sdk'

const AUDIO = 0
const VIDEO = 1

export async function getAgoraTokensWithLambda({ uid, channelName, getRtcToken, isHost = false }) {
    const credentials = await Auth.currentCredentials()
    AWS.config.update({ credentials })
    const lambda = new Lambda()
    const params = {
        FunctionName: AGORA_TOKEN_LAMBDA_ARN,
        Payload: JSON.stringify({ channelName, uid, isHost, getRtcToken }),
    }

    return new Promise(function (resolve) {
        lambda.invoke(params, function (error, data) {
            if (error) {
                __DEV__ && console.log('error getting agora tokens: ', { error })
            } else {
                resolve(data.Payload)
            }
        })
    })
}

export async function fetchAgoraRTMToken(uid) {
    return new Promise(function (resolve) {
        axios
            .post(
                'http://localhost:8082/fetch_rtm_token',
                {
                    uid: uid,
                },
                {
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                },
            )
            .then(function (response) {
                const token = response.data.token
                resolve(token)
            })
            .catch(function (error) {
                __DEV__ && console.log(error)
            })
    })
}
//------------------------------ Agora RTC -----------------------------

export async function fetchAgoraRTCToken({ uid, channelName, tokenRole }) {
    return new Promise(function (resolve) {
        axios
            .post(
                'http://localhost:8082/fetch_rtc_token',
                {
                    uid: uid,
                    channelName: channelName,
                    role: tokenRole,
                },
                {
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                },
            )
            .then(function (response) {
                const token = response.data.token
                resolve(token)
            })
            .catch(function (error) {
                __DEV__ && console.log(error)
            })
    })
}
//------------------------------ Host actions
export async function createRTCChannel({ uid, channelName, streamClient, tracks, setStreamReady }) {
    try {
        const tokenJSON = await getAgoraTokensWithLambda({ uid, channelName, getRtcToken: true, isHost: true })
        const rtcToken = JSON.parse(tokenJSON)
        await streamClient?.join(AGORA_APP_ID, channelName, rtcToken, uid)
        await streamClient?.publish([tracks[AUDIO], tracks[VIDEO]])
        setStreamReady(true)
    } catch (error) {
        __DEV__ && console.log('error initializing stream: ', error)
        __DEV__ && console.log('error entries', Object?.entries(error))
    }
}

export async function createRTMChannel({ uid, channelName, messagesClient, setMessagesChannel, setIsLoggedInToRTM }) {
    try {
        const isLoggedIn = await agoraMessagesLogin({ messagesClient, uid: uid.toString() })
        setIsLoggedInToRTM(isLoggedIn)
        if (isLoggedIn) {
            const RTMChannel = await createMessagesChannel({ messagesClient, channelName })
            await joinMessagesChannel(RTMChannel)
            setMessagesChannel(RTMChannel)
        }
    } catch (error) {
        __DEV__ && console.log('error creating messages channel: ', error)
    }
}

export async function toggleCamera({ tracks, trackState, setTrackState }) {
    await tracks[VIDEO].setEnabled(!trackState.video)
    setTrackState({ ...trackState, video: !trackState.video })
}

export async function toggleMicrophone({ tracks, trackState, setTrackState }) {
    await tracks[AUDIO].setEnabled(!trackState.audio)
    setTrackState({ ...trackState, audio: !trackState.audio })
}

export async function beRightBack({ tracks, trackState, setTrackState }) {
    await tracks[VIDEO].setEnabled(!trackState.video)
    await tracks[AUDIO].setEnabled(!trackState.audio)
    setTrackState({ audio: !trackState.audio, video: !trackState.video })
}

export async function endStream({
    tracks,
    messagesClient,
    messagesChannel,
    streamClient,
    isLoggedInToRTM,
    setIsLoggedInToRTM,
    wasStreamEndedWithListener,
}) {
    try {
        tracks?.[AUDIO] && (await tracks[AUDIO].setEnabled(false))
        tracks?.[VIDEO] && (await tracks[VIDEO].setEnabled(false))
        streamClient && (await streamClient?.removeAllListeners())
        streamClient && (await streamClient?.leave())
    } catch (error) {
        __DEV__ && console.log('\n\nerror leaving stream in RTC Client: ', error, '\n\n')
    }

    try {
        messagesChannel && (await messagesChannel?.leave())
        messagesChannel && (await messagesChannel.removeAllListeners())
        if (messagesClient && isLoggedInToRTM) {
            await messagesClient?.logout()
            await messagesClient?.removeAllListeners()
            setIsLoggedInToRTM(false)
        }
    } catch (error) {
        __DEV__ && console.log('\n\nerror ending stream in RTM Client: ', error, '\n\n')
    }

    agoraStreamState({ ...initialAgoraStreamState, wasStreamEndedWithListener })
}

//------------------------------ Audience actions
export async function joinRTCChannel({ uid, channelName, streamClient, setHasJoinedRTCChannel }) {
    try {
        const tokenJSON = await getAgoraTokensWithLambda({ uid, channelName, getRtcToken: true, isHost: false })
        const rtcToken = JSON.parse(tokenJSON)
        await streamClient?.join(AGORA_APP_ID, channelName, rtcToken, uid)
        setHasJoinedRTCChannel(true)
    } catch (error) {
        __DEV__ && console.log('error joining stream: ', error)
    }
}

export async function joinRTMChannel({ uid, channelName, messagesClient, setMessagesChannel, setIsLoggedInToRTM }) {
    try {
        const isLoggedIn = await agoraMessagesLogin({ messagesClient, uid: uid.toString() })
        setIsLoggedInToRTM(isLoggedIn)
        if (isLoggedIn) {
            const RTMChannel = await createMessagesChannel({ messagesClient, channelName })
            await joinMessagesChannel(RTMChannel)
            setMessagesChannel(RTMChannel)
        }
    } catch (error) {
        __DEV__ && console.log('error joining messages channel: ', error)
    }
}

export async function leaveStream({
    host,
    streamClient,
    messagesClient,
    messagesChannel,
    navigationAction,
    isLoggedInToRTM,
    setIsLoggedInToRTM,
}) {
    try {
        if (host) {
            await streamClient?.unsubscribe(host)
        }
        streamClient && (await streamClient?.leave())
        streamClient && (await streamClient?.removeAllListeners())
        messagesChannel && (await messagesChannel?.leave())
        if (messagesClient && isLoggedInToRTM) {
            await messagesChannel?.removeAllListeners()
            await messagesClient?.logout()
            await messagesClient?.removeAllListeners()
            setIsLoggedInToRTM(false)
        }
        navigationAction()
    } catch (error) {
        __DEV__ && console.log('error leaving stream: ', error)
        navigationAction()
    }
}

//------------------------------ Agora RTM -----------------------------
export async function agoraMessagesLogin({ messagesClient, uid }) {
    try {
        const tokenJSON = await getAgoraTokensWithLambda({ uid, getRtcToken: false })
        const rtmToken = JSON.parse(tokenJSON)
        await messagesClient?.login({ token: rtmToken, uid: uid.toString() })
        return true
    } catch (error) {
        __DEV__ && console.log('RTM login error: ', error)
        return false
    }
}

export async function createMessagesChannel({ messagesClient, channelName }) {
    try {
        const channel = await messagesClient?.createChannel(channelName)
        return channel
    } catch (error) {
        __DEV__ && console.log('error creating RTM channel: ', error)
        return false
    }
}

export async function joinMessagesChannel(channel) {
    try {
        await channel.join()
        return true
    } catch (error) {
        __DEV__ && console.log('error joining RTM channel: ', error)
    }
}

export async function sendChannelMessage({ messagesChannel, text }) {
    try {
        messagesChannel && (await messagesChannel.sendMessage({ text }))
    } catch (error) {
        __DEV__ && console.log('error sending channel message : ', error)
    }
}

export async function sendPeerToPeerMessage({ messagesClient, text, peerId }) {
    try {
        await messagesClient?.sendMessageToPeer({ text }, peerId)
    } catch (error) {
        __DEV__ && console.log('error sending peer message: ', error)
    }
}
