import Svg, { Path } from 'react-native-svg'

const defaultIconStyle = { alignSelf: 'center' }

const SvgIcon = ({ icon, size = 24, color = 'black', style = defaultIconStyle }) => {
    const { viewBox, path } = icon
    return (
        <Svg height={size} width={size} color={color} viewBox={viewBox} style={style}>
            <Path fillRule="evenodd" fill="currentColor" d={path} />
        </Svg>
    )
}

export default SvgIcon
