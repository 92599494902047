import React, { useState, useEffect, useRef } from 'react'
import { ActivityIndicator } from 'react-native'
import { useNavigation, useIsFocused } from '@react-navigation/native'
import colors from 'styles/colors'
import styled from 'styled-components/native'
import { ScreenContainer, ContentContainer } from 'commons/containers'
import { SubHeading } from 'styles/typography'
import { useDimensions } from 'dimensions'
import { getCurrentUser, getNotificationsByUserID, getNumNewNotifications } from 'selectors'
import { TraineeWebBannerHeader } from 'commons/components/TraineeWebBannerHeader'
import * as Notifications from 'expo-notifications'
import NotificationFlatList from 'commons/components/NotificationFlatList'
import { FooterBar } from 'commons/components/FooterBar'
import { isWeb, PAGINATED_LIMIT } from '@constants'

const emptyObj = {}

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: true,
    }),
})

export default function TraineeNotifications() {
    const navigation = useNavigation()
    const { isMobile } = useDimensions()
    const { id: userID, _version, notifications: notificationsOnUser } = getCurrentUser() || emptyObj
    const isFocused = useIsFocused()
    const notificationListener = useRef()
    const responseListener = useRef()
    const [loading, setLoading] = useState(true)
    const [refreshing, setRefreshing] = useState(false)
    const [nextToken, setNextToken] = useState('')
    const [allNotifications, setAllNotifications] = useState([])
    const [notificationReceived, setNotificationReceived] = useState(false)

    const { numNewNotifications } = getNumNewNotifications({ userID })

    const {
        notifications,
        loading: notificationsLoading,
        refetch: refetchNotifications,
        nextToken: initialToken,
        fetchMore,
    } = getNotificationsByUserID({
        userID,
        limit: PAGINATED_LIMIT,
        onCompleted: () => {
            if (allNotifications?.length == 0 && !nextToken) {
                setNextToken(initialToken)
                setAllNotifications(notifications)
            }
        },
    })

    async function handleFetchMore() {
        if (nextToken) {
            const fetchMoreResult = await fetchMore({ variables: { nextToken } })
            const {
                data: {
                    notificationsByUserID: { nextToken: token = null, items: moreNotifications = emptyArr },
                } = emptyObj,
            } = fetchMoreResult
            const newNotifications =
                allNotifications?.length > 0 ? [...allNotifications, ...moreNotifications] : [...moreNotifications]
            setAllNotifications(newNotifications)
            setNextToken(token)
        } else {
            __DEV__ && console.log('no nextToken')
        }
    }

    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
        setNotificationReceived(true)
    })

    useEffect(() => {
        if (refetchNotifications || isFocused || notificationReceived) {
            refetchNotifications()
        }
    }, [isFocused, notificationReceived])

    function handlePullToRefresh() {
        setRefreshing(true)
        refetchNotifications()
        setTimeout(() => {
            setRefreshing(false)
        }, 1000)
    }

    useEffect(() => {
        allNotifications && !notificationsLoading && setLoading(false)
    }, [allNotifications])

    return (
        <ScreenContainer>
            {isWeb && <TraineeWebBannerHeader title="My Notifications" />}
            <ContentContainer isFluid={isWeb}>
                {allNotifications.length == 0 ? (
                    <MessageText color={colors.subTextGray}>you have no notifications</MessageText>
                ) : (
                    <NotificationFlatList
                        data={allNotifications}
                        onReachEnd={nextToken && handleFetchMore}
                        refreshing={refreshing}
                        onPullToRefresh={handlePullToRefresh}
                    />
                )}
            </ContentContainer>
            {isWeb && <FooterBar />}
        </ScreenContainer>
    )
}

const StyledActivityIndicator = styled(ActivityIndicator)`
    padding-top: 80%;
    align-self: center;
`
const MessageText = styled(SubHeading)`
    padding-top: 80%;
    align-self: center;
`
