import React, { useState, useEffect, useMemo } from 'react'
import { useNavigation, useRoute, useIsFocused } from '@react-navigation/native'
import { View, TouchableOpacity, Touchable, Text } from 'react-native'
import { ScreenContainer, ContentContainer, WebContentContainerFluid } from 'commons/containers'
import VectorIcon from 'components/VectorIcon'
import { capitalizeFirstLetter } from 'helpers'
import { FooterBar } from 'commons/components/FooterBar'
import DropDownPicker from 'components/DropDownInput'
import { ButtonText } from 'typography'
import { useDimensions } from 'dimensions'
import { navigateClassDetailsFromCurrentStack } from 'navigationActions'
import colors from 'colors'
import styled from 'styled-components/native'
import { getClassesToBookByDateTime, getInstructors } from 'apollo/selectors'
import { classCategoryPickerItems, instructorClassCategoryPickerItems } from 'pickerItems'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import * as screenNames from 'screenNames'
import ClassesFlatList from 'components/instructor/ClassesFlatList'
import InstructorFlatList from 'components/trainee/InstructorFlatList'
import TimeSlider from 'components/TimeSlider'
import CalendarDays from 'components/react-native-calendar-slider-carousel'
import { SEARCH_USERS } from 'apollo/queries'
import { CLASS_STATUS, USER_TYPES, isWeb, PAGINATED_LIMIT } from '@constants'
import TraineeSuggested from './TraineeSuggested'
import { searchBody } from 'apollo/cache'
const { PUBLISHED } = CLASS_STATUS
const { INSTRUCTOR } = USER_TYPES
const emptyObj = {}
const emptyArr = []
const noop = () => {}

const DateTimeFilter = ({
    onDateSelect,
    timeValues,
    setTimeValues,
    category,
    updateClassCategoryFilter,
    searchInstructors,
    toggleShowInstructor,
    specialization,
    updateInstructorSpecializationFilter,
}) => {
    const {
        isMobile,
        windowDimensions: { windowWidth },
    } = useDimensions()

    return (
        <View style={{ width: '100%', flex: 1, zIndex: 10, alignItems: 'center' }}>
            <CalendarDays
                showArrows
                isMobile={isMobile}
                onDateSelect={onDateSelect}
                daysInView={isMobile ? 5 : 7}
                leftArrow={<VectorIcon family="Entypo" name="chevron-left" size={24} />}
                rightArrow={<VectorIcon family="Entypo" name="chevron-right" size={24} />}
            />
            <TimeAndTypeContainer>
                <ToggleInstructorsContainer>
                    <ClassesButton onPress={toggleShowInstructor} searchInstructors={searchInstructors}>
                        <ButtonText
                            textUppercase
                            fontSize="13"
                            color={searchInstructors ? colors.grayContinue : 'white'}
                        >
                            Classes
                        </ButtonText>
                    </ClassesButton>
                    <View style={{ width: 10 }} />
                    <InstructorsButton onPress={toggleShowInstructor} searchInstructors={searchInstructors}>
                        <ButtonText
                            textUppercase
                            fontSize="13"
                            color={searchInstructors ? 'white' : colors.grayContinue}
                        >
                            Instructors
                        </ButtonText>
                    </InstructorsButton>
                </ToggleInstructorsContainer>

                <TimeSlider timeValues={timeValues} onValuesChangeFinish={setTimeValues} />
            </TimeAndTypeContainer>
            {searchInstructors ? (
                <InstructorSpecializationDropDown
                    placeholder="Instructor Specialization"
                    selected={specialization}
                    shouldPushContent={false}
                    onChangeValue={value => updateInstructorSpecializationFilter(value)}
                    dropDownItems={instructorClassCategoryPickerItems}
                    width={isMobile ? windowWidth * 0.875 : 350}
                    containerStyle={{
                        marginTop: 53,
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        alignSelf: 'flex-start',
                        justifyContent: 'center',
                        zIndex: 15,
                    }}
                />
            ) : (
                <ClassCategoryDropDown
                    placeholder="Class Category"
                    selected={category}
                    shouldPushContent={false}
                    onChangeValue={value => updateClassCategoryFilter(value)}
                    dropDownItems={classCategoryPickerItems}
                    width={isMobile ? windowWidth * 0.875 : 350}
                    containerStyle={{
                        marginTop: 53,
                        marginBottom: 52,
                        flexDirection: 'row',
                        alignSelf: 'flex-start',
                        justifyContent: 'center',
                        height: 56,
                    }}
                />
            )}
        </View>
    )
}

export const ExploreScreen = () => {
    const navigation = useNavigation()
    const isFocused = useIsFocused()
    const { params = emptyObj } = useRoute()
    const { byInstructor = __DEV__ ? false : false } = params
    const { isMobile, windowDimensions } = useDimensions()
    const { windowWidth } = windowDimensions
    const searchText = useReactiveVar(searchBody)
    const [activeSearch, setActiveSearch] = useState(false)
    const [prevCategory, setPrevCategory] = useState('')
    const [category, setCategory] = useState('')
    const [specialization, setSpecialization] = useState('')
    const [prevSpecialization, setPrevSpecialization] = useState('')
    const [timeValues, setTimeValues] = useState([0, 24])
    const [prevTimeValues, setPrevTimeValues] = useState([[0, 24]])
    const [date, setDate] = useState(undefined)
    const [prevDate, setPrevDate] = useState(undefined)
    const [instructors, setInstructors] = useState(emptyArr)
    const [instructorNextToken, setInstructorNextToken] = useState('')
    const [classesToBook, setClassesToBook] = useState(emptyArr)
    const [classNextToken, setClassNextToken] = useState('')
    const [searchInstructors, setSearchInstructors] = useState(byInstructor)
    const {
        instructors: instructorResults,
        loading: instructorsLoading,
        refetch: refetchInstructors,
        nextToken: instructorToken,
        fetchMore: instructorFetchMore,
    } = getInstructors({
        limit: PAGINATED_LIMIT,
        category: specialization,
        onCompleted: () => {
            if (instructors?.length === 0 && !instructorNextToken) {
                setInstructorNextToken(instructorToken)
                setInstructors(instructorResults)
            } else if (!specialization && prevSpecialization) {
                setPrevSpecialization('')
                setInstructorNextToken(instructorToken)
                setInstructors(instructorResults)
            } else if (specialization) {
                setInstructorNextToken(instructorToken)
                setInstructors(instructorResults)
            } else {
                setInstructors(instructorResults)
            }
        },
    })
    const [customSearch, setCustomSearch] = useState(false)
    const [instructorSearchResults, setInstructorSearchResults] = useState([])

    const { startTime, endTime } = useMemo(() => {
        const [startTimeValue, endTimeValue] = timeValues
        const startTime = `${startTimeValue < 10 ? '0' : ''}${startTimeValue}:00:00`
        const endTime = `${endTimeValue < 10 ? '0' : ''}${endTimeValue}:00:00`
        return { startTime, endTime }
    }, [timeValues])

    const {
        classesToBook: classQueryResults,
        loading: classesLoading,
        refetch: refetchClassesToBook,
        nextToken: classToken,
        fetchMore: classFetchMore,
    } = getClassesToBookByDateTime({
        date,
        startTime,
        endTime,
        category,
        searchBody: searchText,
        limit: PAGINATED_LIMIT,
        onCompleted: () => {
            if ((classesToBook?.length === 0 && !classNextToken) || searchText) {
                setClassNextToken(classToken)
                setClassesToBook(classQueryResults)
            } else if ((!date && prevDate) || (!category && prevCategory)) {
                setPrevDate(undefined)
                setPrevCategory('')
                setClassNextToken(classToken)
                setClassesToBook(classQueryResults)
            } else if (date || category) {
                setClassNextToken(classToken)
                setClassesToBook(classQueryResults)
            } else if (prevTimeValues[0] !== timeValues[0] || prevTimeValues[1] !== timeValues[1]) {
                setPrevTimeValues(timeValues)
                setClassNextToken(classToken)
                setClassesToBook(classQueryResults)
            } else if (activeSearch && !searchText) {
                setActiveSearch(false)
                setClassNextToken(classToken)
                setClassesToBook(classQueryResults)
            } else {
                setClassesToBook(classQueryResults)
            }
        },
    })

    const [
        queryInstructors,
        { data: instructorSearchMatches, loading: instructorsResultsLoading, error: instructorsError },
    ] = useLazyQuery(SEARCH_USERS, {
        variables: {
            filter: {
                userType: { eq: INSTRUCTOR },
                or: searchText?.includes(' ')
                    ? [
                          {
                              firstName: {
                                  match: searchText?.split()?.[0],
                              },
                              lastName: {
                                  wildcard: searchText?.split(' ')?.[1] + '*',
                              },
                          },
                          {
                              firstName: {
                                  match: capitalizeFirstLetter(searchText?.split()?.[0]),
                              },
                              lastName: {
                                  wildcard: searchText?.split(' ')?.[1] + '*',
                              },
                          },
                      ]
                    : [
                          {
                              firstName: { wildcard: searchText + '*' },
                          },
                          {
                              firstName: {
                                  wildcard: capitalizeFirstLetter(searchText + '*'),
                              },
                          },
                          {
                              username: { wildcard: searchText + '*' },
                          },
                      ],
            },
        },
        onCompleted: () => {
            const matchingInstructors = instructorSearchMatches?.searchUsers?.items ?? emptyArr
            setInstructorSearchResults(matchingInstructors)
        },
        onError: () => {
            __DEV__ && console.log('error searching instructors: ', instructorsError)
        },
    })

    const onInstructorCardPress = instructor =>
        navigation.navigate(screenNames.EXPLORE_NAVIGATOR, {
            screen: screenNames.USER_PROFILE,
            params: { user: instructor, id: instructor.id },
        })

    const toggleShowInstructor = () => {
        clearSearchState()
        setSearchInstructors(!searchInstructors)
    }

    const updateClassCategoryFilter = ({ value, setOpen }) => {
        setPrevCategory(category)
        if (value == 'showAllClasses') {
            setCategory('')
            setTimeValues([0, 24])
            setPrevTimeValues([0, 24])
        } else {
            setCategory(value)
        }
    }

    const updateInstructorSpecializationFilter = ({ value, setOpen }) => {
        setPrevSpecialization(specialization)
        if (value == 'showAllSpecializations') {
            setSpecialization('')
        } else {
            setSpecialization(value)
        }
    }

    const onDateSelect = selectedDate => {
        setPrevDate(date)
        setDate(date ? (selectedDate == date ? undefined : selectedDate) : selectedDate)
    }

    function clearSearchState() {
        setCustomSearch(false)
        if (searchInstructors) {
            setInstructorSearchResults(emptyArr)
            setSpecialization('')
            setPrevSpecialization('')
            setInstructors(instructorResults)
        }
        searchBody('')
    }

    useEffect(() => {
        setSearchInstructors(byInstructor)
    }, [byInstructor])

    useEffect(() => {
        if (!searchText && customSearch) {
            clearSearchState()
        } else if (searchText) {
            !customSearch && setCustomSearch(true)
            if (searchInstructors) {
                queryInstructors()
            }
        }
    }, [searchText])

    useEffect(() => {
        if (searchText && !activeSearch) setActiveSearch(true)
        if (refetchClassesToBook) {
            refetchClassesToBook()
        }
        if (refetchInstructors) {
            refetchInstructors()
        }
    }, [isFocused, timeValues, date, searchText])

    async function handleFetchMoreClass() {
        if (classNextToken) {
            const fetchMoreResult = await classFetchMore({ variables: { nextToken: classNextToken } })
            const {
                data: { classByClassStatus: { nextToken: token = null, items: moreClasses = emptyArr } } = emptyObj,
            } = fetchMoreResult
            const allClasses = classesToBook?.length > 0 ? [...classesToBook, ...moreClasses] : [...moreClasses]
            setClassesToBook(allClasses)
            setClassNextToken(token)
        } else {
            __DEV__ && console.log('no nextToken')
        }
    }

    async function handleFetchMoreInstructors() {
        if (instructorNextToken) {
            const fetchMoreResult = await instructorFetchMore({ variables: { nextToken: instructorNextToken } })
            const {
                data: { userByUserType: { nextToken: token = null, items: moreInstructors = emptyArr } } = emptyObj,
            } = fetchMoreResult
            const allInstructors = instructors?.length > 0 ? [...instructors, ...moreInstructors] : [...moreInstructors]
            setInstructors(allInstructors)
            setInstructorNextToken(token)
        } else {
            __DEV__ && console.log('no nextToken')
        }
    }

    const handleTimeChange = values => {
        setPrevTimeValues(timeValues)
        setTimeValues(values)
    }

    useEffect(() => {
        const resetStack = navigation.getParent().addListener('blur', () => {
            searchBody('')
        })

        return resetStack
    }, [navigation])

    return (
        <ScreenContainer>
            <WebContentContainerFluid paddingHorizontal="0px">
                <TraineeSuggested />
                <ContentContainer width="100%">
                    <DateTimeFilter
                        onDateSelect={onDateSelect}
                        timeValues={timeValues}
                        setTimeValues={handleTimeChange}
                        category={category}
                        updateClassCategoryFilter={updateClassCategoryFilter}
                        searchInstructors={searchInstructors}
                        toggleShowInstructor={toggleShowInstructor}
                        specialization={specialization}
                        updateInstructorSpecializationFilter={updateInstructorSpecializationFilter}
                    />

                    {searchInstructors ? (
                        <InstructorList
                            hidePicker
                            isExplore
                            data={!customSearch ? instructors : instructorSearchResults}
                            instructorsLoading={!customSearch ? instructorsLoading : instructorsResultsLoading}
                            onCardPress={onInstructorCardPress}
                            onReachEnd={instructorNextToken && handleFetchMoreInstructors}
                            headingText={`Browse By Instructors`}
                            headingFontSize={35}
                        />
                    ) : (
                        <ClassesFlatList
                            style={{ minHeight: 355 }}
                            hidePicker
                            horizontal
                            headingText={`Browse By Classes`}
                            data={classesToBook}
                            classesLoading={classesLoading}
                            navigationAction={navigateClassDetailsFromCurrentStack}
                            onReachEnd={classNextToken && handleFetchMoreClass}
                            headingFontSize={35}
                        />
                    )}
                </ContentContainer>
            </WebContentContainerFluid>
            <FooterBar />
        </ScreenContainer>
    )
}

const ClassCategoryDropDown = styled(DropDownPicker)``
const InstructorSpecializationDropDown = styled(DropDownPicker)``

const HalfButton = styled(TouchableOpacity)`
    height: 50px; 
    border-radius:  ${({ theme }) => (theme.isMobile ? 5 : 5)}px 
    justify-content: center;
    align-items: center;
    border-width: 1px;
    width: 153px;
`
const InstructorsButton = styled(HalfButton)`
    background-color: ${props => (props.searchInstructors ? colors.homebodyTurquoise : 'white')};
    border-color: ${props => (props.searchInstructors ? colors.homebodyTurquoise : colors.grayContinue)};
`
const ClassesButton = styled(HalfButton)`
    background-color: ${props => (!props.searchInstructors ? colors.homebodyTurquoise : 'white')};
    border-color: ${props => (!props.searchInstructors ? colors.homebodyTurquoise : colors.grayContinue)};
`
const ToggleInstructorsContainer = styled(View)`
    flex-direction: row;
    width: 339px;
    justify-content: space-between;
`
const InstructorList = styled(InstructorFlatList)`
    padding-top: 49px;
`
const TimeAndTypeContainer = styled(View)`
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
`

export default ExploreScreen
