import React, { useState, useEffect } from 'react'
import { Text, TouchableOpacity, ActivityIndicator } from 'react-native'
import styled from 'styled-components/native'
import colors from 'colors'
import { Hoverable, Pressable } from 'react-native-web-hover'
import { ButtonText } from 'typography'
import { LinearGradient } from 'expo-linear-gradient'
const gradientStart = { x: 0.15, y: 0 }
const gradientEnd = { x: 0.85, y: 0 }
const gradientColors = [colors.homebodyTurquoise, colors.homebodyGreen]
export const StyledButtonContainer = styled.TouchableOpacity`
    width: ${({ width, theme }) => width || theme.isMobile ? '100%' : '200px'};
    height: ${props => (props.theme.isMobile ? '60px' : '60px')};
    border-radius: 10px;
    background-color: ${props => props.color || colors.blueContinue};
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
const StyledLinearGradient = styled(LinearGradient)`
    width: ${props => props.width || '100%'};
    height: ${props => (props.theme.isMobile ? '60px' : '56px')};
    border-radius: 16px;
    background-color: ${props => props.color || colors.blueContinue};
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
const StyledActivityIndicator = styled(ActivityIndicator)`
    width: ${props => props.width || '100%'};
    height: ${props => (props.theme.isMobile ? '80px' : '60px')};
    margin-bottom: ${props => (props.theme.isMobile ? '10px' : '0px')};
    margin-right: 10px;
`
export default function StyledButton({
    onPress,
    text,
    disabled,
    color,
    isGradient = false,
    style = {},
    buttonTextStyle = {},
    nonHover = false,
    actionLoading = false,
    apolloActionStatus = { actionSuccess: false, statusMessage: '' },
}) {
    const [showStatusMessage, setShowStatusMessage] = useState(true)
    const { actionSuccess, statusMessage } = apolloActionStatus

    useEffect(() => {
        if (!!statusMessage) {
            setTimeout(() => {
                setShowStatusMessage(false)
            }, 3000)
        }
    }, [statusMessage])

    if (isGradient) {
        return (
            <Hoverable>
                {({ hovered }) => (
                    <TouchableOpacity
                        onPress={onPress}
                        disabled={disabled}
                        color={hovered && !disabled ? 'black' : color}
                    >
                        <StyledLinearGradient
                            start={gradientStart}
                            end={gradientEnd}
                            colors={gradientColors}
                            style={style}
                        >
                            <ButtonText style={buttonTextStyle}>{text}</ButtonText>
                        </StyledLinearGradient>
                    </TouchableOpacity>
                )}
            </Hoverable>
        )
    } else {
        return (
            <>
                {!!statusMessage && showStatusMessage && (
                    <ActionStatusText paddingBottom={10} color={actionSuccess ? colors.homebodyTurquoise : 'red'}>
                        {statusMessage}
                    </ActionStatusText>
                )}
                <Hoverable>
                    {({ hovered }) => (
                        <StyledButtonContainer
                            onPress={onPress}
                            disabled={disabled}
                            style={style}
                            color={!nonHover && hovered && !disabled ? 'black' : color}
                        >
                            {actionLoading ? (
                                <StyledActivityIndicator animating={actionLoading} size="large" color="white" />
                            ) : (
                                <ButtonText>{text}</ButtonText>
                            )}
                        </StyledButtonContainer>
                    )}
                </Hoverable>
            </>
        )
    }
}

const ActionStatusText = styled(ButtonText)`
    align-self: center;
`
