import React, { useEffect, useState, useMemo } from 'react'
import { View } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/core'
import { loadStripe } from '@stripe/stripe-js'
import styled from 'styled-components/native'
import colors from 'colors'
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js'
import { WALLET } from 'screenNames'
import { FooterBar } from 'commons/components/FooterBar'
import { ScreenContainer, ContentContainer, RowContainer, FieldContainer } from 'commons/containers'
import { TraineeWebBannerHeader } from 'commons/components/TraineeWebBannerHeader'
import { ContinueButton } from 'components/ContinueButton'
import { InputField, InputFieldTitleText } from 'components/InputField'
import { useReactiveVar } from '@apollo/client'
import { paymentMethodForm } from 'apollo/cache'
import { paymentMethodValidationFunc } from 'validations'
import useReactiveForm from 'hooks/useReactiveForm'
import { paymentMethodFieldNames as fieldNames, paymentMethodFieldErrors } from 'formFieldInfo'
import { STRIPE_ELEMENT_TYPES, ENVIRONMENT_VARIABLES } from '@constants'
const { STRIPE_PUBLIC_KEY = '' } = ENVIRONMENT_VARIABLES
const { CARD_NUMBER, CARD_EXPIRY, CARD_CVC } = STRIPE_ELEMENT_TYPES
import { getCurrentUser } from 'apollo/selectors'
import { STRIPE_API_ROUTES } from '@constants'
const { GET_SECRET, ADD_CARD } = STRIPE_API_ROUTES
import { stripeAPI } from 'src/actions'
const emptyObj = {}

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: 'black',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
}

const StripeElementField = ({ style, elementType, formError, fieldTitle }) => {
    const [elementError, setElementError] = useState('')

    const clearElementError = () => setElementError('')

    const props = { onFocus: clearElementError, options: CARD_ELEMENT_OPTIONS }

    function getStripeElement() {
        switch (elementType) {
            case CARD_NUMBER:
                return <CardNumberElement {...props} />

            case CARD_EXPIRY:
                return <CardExpiryElement {...props} />

            case CARD_CVC:
                return <CardCvcElement {...props} />
        }
    }

    function updateElementError() {
        const { code, type, message: errorMessage } = formError || emptyObj
        switch (code) {
            case 'invalid_number':
            case 'incomplete_number':
                if (elementType == CARD_NUMBER && !elementError) setElementError(errorMessage)
                break

            case 'invalid_expiry_month_past':
            case 'incomplete_expiry':
                if (elementType == CARD_EXPIRY && !elementError) setElementError(errorMessage)
                break

            case 'incomplete_cvc':
                if (elementType == CARD_CVC && !elementError) setElementError(errorMessage)
                break

            default:
                setElementError('')
                break
        }
    }

    const StripeElement = useMemo(() => getStripeElement())

    useEffect(() => {
        updateElementError()
    }, [formError])

    return (
        <View style={style}>
            <InputFieldTitleText largeTitle style={{ color: elementError ? 'red' : 'black' }}>
                {elementError ? elementError : fieldTitle}
            </InputFieldTitleText>
            <StripeElementContainer>{StripeElement}</StripeElementContainer>
        </View>
    )
}

const CheckoutForm = ({ isWallet, params, form, reactiveFormProps }) => {
    const stripe = useStripe()
    const elements = useElements()
    const navigation = useNavigation()
    const [loading, setLoading] = useState(false)
    const [createPaymentMethodError, setCreatePaymentMethodError] = useState('')
    const { id: userID, classBookings, refetch: refetchUser, firstName, lastName, email, customerID } = getCurrentUser()
    const { name, zip: postal_code } = form
    const { isValid, handleInvalidPage, updateForm } = reactiveFormProps

    const goToWallet = () => navigation.navigate(WALLET, { refetch: true, ...params })

    const handleSubmitPaymentMethod = async () => {
        if (elements == null || !stripe) {
            return
        }
        if (!isValid) {
            handleInvalidPage()
            return
        }
        addPaymentMethod()
    }

    const addPaymentMethod = async () => {
        setLoading(true)
        const setupIntent = await stripeAPI({ path: GET_SECRET, pathParameters: customerID })
        const result = await stripe.confirmCardSetup(setupIntent, {
            payment_method: {
                type: 'card',
                card: elements.getElement(CardNumberElement), //When using multiple stripe elements, CardNumberElement has access to associated field (i.e. expirary)
                billing_details: {
                    name,
                    address: {
                        postal_code,
                    },
                },
            },
        })
        if (result.error) {
            setCreatePaymentMethodError(result.error)
        } else {
            const id = await stripeAPI({
                path: ADD_CARD,
                body: { customerID, paymentMethod: result?.setupIntent?.payment_method },
            })
            if (id !== customerID) {
                setCreatePaymentMethodError('Error attaching payment method')
            }
            setLoading(false)
            goToWallet()
        }
    }

    return (
        <View>
            <StripeElementField
                elementType={CARD_NUMBER}
                formError={createPaymentMethodError}
                fieldTitle="Card Number"
            />
            <RowContainer style={{ justifyContent: 'space-between' }}>
                <StripeElementField
                    style={{ width: '45%' }}
                    elementType={CARD_EXPIRY}
                    formError={createPaymentMethodError}
                    fieldTitle="Card Expiry"
                />
                <StripeElementField
                    style={{ width: '45%' }}
                    elementType={CARD_CVC}
                    formError={createPaymentMethodError}
                    fieldTitle="Card CVC"
                />
            </RowContainer>
            <InputField
                shouldSanitizeInput
                reactiveForm={true}
                maxLength={5}
                largeTitle={true}
                fieldTitle="Zip/Postal Code"
                fieldName={fieldNames.zip}
                value={postal_code ?? ''}
                onSubmitEditing={handleSubmitPaymentMethod}
                {...reactiveFormProps}
            />
            <ContinueButton
                style={{ marginTop: 25 }}
                text={isWallet ? 'Add To Wallet' : 'Pay'}
                onPress={handleSubmitPaymentMethod}
                actionLoading={loading}
            />
        </View>
    )
}

export default function CheckoutPage({}) {
    const { params } = useRoute()
    const { isWallet = false } = params || emptyObj
    const form = useReactiveVar(paymentMethodForm)
    const { name, zip } = form

    const reactiveFormProps = useReactiveForm({
        currentPage: WALLET,
        reactiveForm: paymentMethodForm,
        fieldNames,
        fieldErrors: paymentMethodFieldErrors,
        validationFunc: paymentMethodValidationFunc,
    })

    return (
        <Elements stripe={stripePromise}>
            <ScreenContainer>
                <TraineeWebBannerHeader title={'My Credits'} />
                <ContentContainer>
                    <FieldContainer>
                        <InputField
                            reactiveForm={true}
                            largeTitle={true}
                            fieldTitle="Name on Card"
                            fieldName={fieldNames.name}
                            defaultValue={name ?? ''}
                            {...reactiveFormProps}
                        />
                        <CheckoutForm
                            isWallet={isWallet}
                            params={params}
                            form={form}
                            reactiveFormProps={reactiveFormProps}
                        />
                    </FieldContainer>
                </ContentContainer>
                <FooterBar />
            </ScreenContainer>
        </Elements>
    )
}

const StripeElementContainer = styled(View)`
    height: 61.52px;
    padding-horizontal: 10px;
    justify-content: center;
    border-radius: 20px;
    background-color: ${colors.inputFieldGray};
`
