import React from 'react'
import { View, TouchableOpacity, StyleSheet, ActivityIndicator, FlatList } from 'react-native'
import colors from 'colors'
import styled from 'styled-components/native'
import { SubHeading, Caption, SubHeaderText, TEXT_LINEAR_GRADIENT, Paragraph } from 'typography'
import { getInstructorRatingAverage, getInstructorSpecializationsString, truncateText } from 'helpers'
import TraineeAvatar from 'components/TraineeAvatar'
import DropDownPicker from 'components/DropDownInput'
import VectorIcon from 'components/VectorIcon'
import { RowContainer } from 'commons/containers'
import { specializationPickerItems } from 'pickerItems'
import { getCurrentUser, getReviewsByInstructorID } from 'apollo/selectors'
import FavoriteInstructorIcon from 'components/trainee/FavoriteInstructorIcon'
import ViewAllHeading from 'components/trainee/ViewAllHeading'
import { LinearGradient } from 'expo-linear-gradient'
import { useDimensions } from 'dimensions'
import { PAGINATED_LIMIT, APOLLO_FETCH_POLICIES } from '@constants'
import { isWeb } from '@constants'
import { ContinueButton } from '../ContinueButton'

const noop = () => {}
const emptyObj = {}

const InstructorCard = ({ instructor, favorites, userID, onPress = noop }) => {
    const { profile, avatar, id: instructorID, classCategories = emptyObj } = instructor
    const { firstName, lastName } = profile || emptyObj
    const { instructorReviews: reviews, loading: reviewsLoading } = getReviewsByInstructorID({ instructorID })
    const averageRating = getInstructorRatingAverage(reviews)
    let specialization = getInstructorSpecializationsString({ classCategories, shouldAddSuffix: true })
    if (!isWeb) {
        specialization = truncateText({ text: specialization, charCeil: 15 })
    }

    return (
        <InstructorCardContainer onPress={() => onPress(instructor)}>
            <InstructorInfoContainer>
                <InstructorAvatar userInfo={{ firstName, lastName }} size={55} avatarImageKey={avatar?.key} />
                <InstructorDetailsContainer>
                    <InstructorNameText isMostBold>{`${firstName} ${lastName}`}</InstructorNameText>
                    <CategoryRatingsContainer>
                        <SubContainer flex={isWeb ? 0.8 : 1.25}>
                            <SpecializationsText>{specialization}</SpecializationsText>
                        </SubContainer>
                        <SubContainer flex={isWeb ? 1 : 1}>
                            <VectorIcon family="AntDesign" name="star" color={colors.homebodyGreen} size={16} />
                            <RatingText isMostBold isNew={averageRating === 0} paddingLeft={5}>
                                {averageRating === 0 ? 'new instructor!' : averageRating}
                            </RatingText>
                        </SubContainer>
                    </CategoryRatingsContainer>
                </InstructorDetailsContainer>
            </InstructorInfoContainer>
            <FavoriteIcon favorites={favorites} userID={userID} instructorID={instructorID} size={24} />
        </InstructorCardContainer>
    )
}
const EmptyInstructorCard = ({ onPress = noop, instructorsLoading }) => {
    const { isMobile } = useDimensions()

    if (instructorsLoading) {
        return (
            <InstructorCardContainer onPress={onPress}>
                <InstructorInfoContainer style={{ justifyContent: 'center' }}>
                    <LoadingInstructorContainer>
                        <ActivityIndicator size={40} animating={instructorsLoading} color={colors.homebodyTurquoise} />
                    </LoadingInstructorContainer>
                    <InstructorDetailsContainer>
                        <InstructorNameText isMostBold paddingLeft={15}>
                            Favorites loading...
                        </InstructorNameText>
                    </InstructorDetailsContainer>
                </InstructorInfoContainer>
            </InstructorCardContainer>
        )
    }

    if (isMobile) {
        return (
            <>
                <InstructorCardContainer onPress={onPress}>
                    <InstructorInfoContainer style={{ justifyContent: 'center' }}>
                        <FindInstructorButton marginLeft={50} colors={['#0CE2FF', '#5AC973']}>
                            <VectorIcon family="SimpleLineIcons" name="magnifier" size={24} color="white" />
                        </FindInstructorButton>
                        <InstructorDetailsContainer>
                            <RowContainer>
                                <InstructorNameText isMostBold paddingLeft={20}>
                                    No Favorite Instructor
                                </InstructorNameText>
                                <VectorIconSadOutline
                                    family="MaterialCommunityIcons"
                                    name="emoticon-sad-outline"
                                    size={24}
                                    color="black"
                                />
                            </RowContainer>
                            <InstructorNameText paddingLeft={20}>Find Your Favorite Instructor!</InstructorNameText>
                        </InstructorDetailsContainer>
                    </InstructorInfoContainer>
                </InstructorCardContainer>
                <EmptyInstructorMessage>
                    <SubHeading style={{ textAlign: 'center' }}>
                        You can add an instructor by tapping on the heart icon in their profile
                    </SubHeading>
                </EmptyInstructorMessage>
            </>
        )
    }

    return (
        <>
            <EmptyInstructorMessage>
                <VectorIconSadOutline
                    family="MaterialCommunityIcons"
                    name="emoticon-sad-outline"
                    size={60}
                    color="black"
                />
                <NoFavoriteHeader isMostBold textAlign="center" fontSize="33" marginTop="34.6">
                    you have no favorite instructors
                </NoFavoriteHeader>
                <NoFavoriteSubHeader maxWidth="404px" marginTop={34.63} textAlign="center" fontSize="22">
                    add an instructors to your favorites by tapping on the heart icon in their profile
                </NoFavoriteSubHeader>
                <BrowseInstructorBtn text="Browse Instructors" onPress={onPress} />
            </EmptyInstructorMessage>
        </>
    )
}

const InstructorAvatarCard = ({ instructor, onPress = noop, avatarSize, isSmallHeading }) => {
    const { avatar, profile, id: instructorID } = instructor
    const { firstName, lastName } = profile || {}
    const handleOnPress = () => onPress({ instructor, instructorID })
    return (
        <AvatarContainer onPress={handleOnPress}>
            <InstructorAvatar
                isAvatar
                userInfo={{ firstName, lastName }}
                size={avatarSize}
                avatarImageKey={avatar?.key}
            />
            {isSmallHeading ? (
                <Caption paddingTop={10}>{`${firstName}\n${lastName}`}</Caption>
            ) : (
                <SubHeading paddingTop={10}>{`${firstName} ${lastName}`}</SubHeading>
            )}
        </AvatarContainer>
    )
}

const EmptyInstructorAvatarCard = ({ onPress = noop, instructorsLoading, avatarSize }) => {
    if (instructorsLoading) {
        return (
            <LoadingAvatarContainer avatarSize={avatarSize}>
                <ActivityIndicator size={40} animating={instructorsLoading} color={colors.homebodyTurquoise} />
            </LoadingAvatarContainer>
        )
    } else {
        return (
            <AvatarContainer onPress={onPress}>
                <FindInstructorButton colors={['#0CE2FF', '#5AC973']}>
                    <VectorIcon family="SimpleLineIcons" name="magnifier" size={24} color="white" />
                </FindInstructorButton>
                <SubHeading paddingTop={10}>{`Find Instructors`}</SubHeading>
            </AvatarContainer>
        )
    }
}

const FlatListHeading = ({ headingText, onHeadingPress, isSmallHeading, fontSize }) => {
    if (isSmallHeading) {
        return (
            <SmallHeadingText paddingBottom={20} fontSize={fontSize}>
                {headingText}
            </SmallHeadingText>
        )
    } else {
        return <ViewAllHeading headingText={headingText} onHeadingPress={onHeadingPress} fontSize={fontSize} />
    }
}
export default function InstructorFlatList({
    style,
    flatListStyle,
    data,
    onCardPress,
    headingText = '',
    onHeadingPress,
    instructorsLoading,
    avatarSize = 75,
    isAvatar = false,
    isExplore = false,
    horizontal = false,
    hidePicker = false,
    isSmallHeading = false,
    hideFindInstructorsCard = false,
    shouldHideNoInstructorsText = false,
    onReachEnd = noop,
    headingFontSize,
}) {
    const ListEmptyComponent = () => {
        if (isAvatar) {
            return (
                <>
                    {!hideFindInstructorsCard && (
                        <EmptyInstructorAvatarCard
                            onPress={onHeadingPress}
                            instructorsLoading={instructorsLoading}
                            avatarSize={avatarSize}
                        />
                    )}
                </>
            )
        } else if (isExplore) {
            return <></>
        } else {
            return <EmptyInstructorCard onPress={onHeadingPress} instructorsLoading={instructorsLoading} />
        }
    }

    const renderItem = ({ item }) => {
        if (isAvatar) {
            return (
                <InstructorAvatarCard
                    instructor={item}
                    onPress={onCardPress}
                    avatarSize={avatarSize}
                    isSmallHeading={isSmallHeading}
                />
            )
        } else {
            return <InstructorCard instructor={item} favorites={favorites} userID={userID} onPress={onCardPress} />
        }
    }

    const { id: userID, favorites } = getCurrentUser()

    return (
        <InstructorFlatlistContainer style={style} isExplore={isExplore}>
            {!hidePicker && (
                <ClassCategoryPicker
                    dropDownItems={specializationPickerItems}
                    selected={'Class Category'}
                    fieldTitle=""
                    fieldName=""
                    reactiveForm={false}
                />
            )}
            {!!headingText && (
                <FlatListHeading
                    headingText={headingText}
                    onHeadingPress={onHeadingPress}
                    isSmallHeading={isSmallHeading}
                    fontSize={headingFontSize}
                />
            )}
            {data.length === 0 && !shouldHideNoInstructorsText && (
                <StyledSubHeader isSubtext isExplore={isExplore}>
                    {isExplore ? 'No Instructors found' : 'You have no favorite Instructors'}
                </StyledSubHeader>
            )}
            <InstructorList
                horizontal={horizontal}
                style={flatListStyle}
                data={data}
                initialNumToRender={PAGINATED_LIMIT}
                renderItem={renderItem}
                ListEmptyComponent={ListEmptyComponent}
                onEndReached={onReachEnd}
                keyExtractor={item => `${item.id}${item._version}`}
                onEndReachedThreshold={onReachEnd !== noop && 0.5}
                showsVerticalScrollIndicator={false}
                isExplore={isExplore}
            />
        </InstructorFlatlistContainer>
    )
}

const SmallHeadingText = styled(SubHeading)``

const FindInstructorButton = styled(LinearGradient)`
    height: 55px;
    width: 55px;
    border-radius: 27.5px;
    align-self: center;
    justify-content: center;
    align-items: center;
    ${props => props.marginLeft && `margin-left: ${props.marginLeft}px;`}
`
const ClassCategoryPicker = styled(DropDownPicker)`
    align-self: flex-start;
    width: 235px;
    height: 56px;
    background-color: transparent;
    border-width: ${StyleSheet.hairlineWidth}px;
    border-color: ${colors.grayContinue};
`
const FavoriteIcon = styled(FavoriteInstructorIcon)`
    padding-top: 20px;
    align-self: flex-start;
`
const InstructorFlatlistContainer = styled(View)`
    flex: 1;
`
const InstructorInfoContainer = styled(View)`
    height: 100%;
    width: 75%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
const InstructorDetailsContainer = styled(View)`
    align-items: flex-start;
    width: 100%;
`
const CategoryRatingsContainer = styled(View)`
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-top: 5px;
`
const InstructorAvatar = styled(TraineeAvatar)`
    align-self: center;
    padding-right: ${props => (props.isAvatar ? '0' : '15')}px;
`
const InstructorNameText = styled(SubHeading)``
const SpecializationsText = styled(SubHeading)``
const RatingText = styled(SubHeading)`
    color: ${colors.homebodyTurquoise};
`
const InstructorList = styled(FlatList)`
    ${({ theme, isExplore }) => !theme.isMobile && isExplore && `min-height: 500px;`}
`
const InstructorCardContainer = styled(TouchableOpacity)`
    height: 100px;
    width: 100%;
    margin-bottom: 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    padding-horizontal: ${props => (props.theme.isMobile ? '15px' : '25px')};
    border-radius: 20px;
    border-color: ${colors.dividingGray};
    border-width: 1px;
    ${({ theme }) => !theme.isMobile && 'max-width: 650px;'}
    ${({ theme }) => !theme.isMobile && 'align-self: flex-start;'}
`
const LoadingInstructorContainer = styled(View)`
    flex-direction: column;
    justify-content: center;
    align-self: center;
    height: 100%;
    width: 55px;
    padding-horizontal: 25px;
`
const LoadingAvatarContainer = styled(View)`
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;
    height: ${props => props.avatarSize}px;
    width: 100px;
    margin-right: 20px;
`
const AvatarContainer = styled(TouchableOpacity)`
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-start;
    align-items: center;
    margin-right: 20px;
`
const EmptyInstructorMessage = styled(View)`
    align-items: center;
    margin-top: 20px;
    ${({ theme }) => theme.isMobile && 'margin-horizontal: 15%;'}
`
const StyledSubHeader = styled(SubHeaderText)`
    margin-bottom: 20px;
    ${props => props.theme.isMobile && props.isExplore && 'align-self: center;'}
`

const NoFavoriteHeader = styled(SubHeading)`
    ${({ theme }) => theme.isTablet && 'padding-top:10px;'}
`
const NoFavoriteSubHeader = styled(SubHeading)``

const BrowseInstructorBtn = styled(ContinueButton)`
    height: 45px;
    width: 317px;
    justify-content: center;
    border-radius: 13px;
    margin-top: 34.63px;
    margin-bottom: 200px;
`

const VectorIconSadOutline = styled(VectorIcon)`
    border-radius: 12px;
    padding-left: 10px;
`
const SubContainer = styled(View)`
    ${props => props.flex && `flex: ${props.flex};`}
    flex-direction: row;
    justify-content: flex-start;
`
