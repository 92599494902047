import React from 'react'
import { Text, View } from 'react-native'
import styled from 'styled-components/native'
import { InputField, StyledTextInput, InputFieldRowSpacer } from 'components/InputField'
import DropDownPicker from 'components/DropDownInput'
import { FieldContainer, FieldRowContainer, MAX_FORM_WIDTH } from 'src/commons/containers'
import { statePickerItems } from 'pickerItems'
import { useReactiveVar } from '@apollo/client'
import { instructorApplicationForm } from 'apollo/cache'
import { getCurrentUser } from 'apollo/selectors'
import { instructorAppFieldNames as fieldNames } from 'formFieldInfo'
import { FormHeaderLabel, Heading } from 'typography'
import { SANITIZE_INPUT_TYPES } from '@constants'

export const PersonalDetails = ({ reactiveFormProps }) => {
    const { firstName, lastName } = getCurrentUser() || ''
    const form = useReactiveVar(instructorApplicationForm)
    return (
        <FieldContainer style={{ maxWidth: MAX_FORM_WIDTH }}>
            <FormHeaderLabel>*Please enter your full name and create a username</FormHeaderLabel>
            <FieldRowContainer>
                <InputField
                    reactiveForm={true}
                    fieldTitle="First Name"
                    fieldName="firstName"
                    placeholder="First Name"
                    value={form?.firstName}
                    {...reactiveFormProps}
                />
                <InputFieldRowSpacer />
                <InputField
                    reactiveForm={true}
                    fieldTitle="Last Name"
                    fieldName="lastName"
                    placeholder="Last Name"
                    value={form?.lastName}
                    {...reactiveFormProps}
                />
                <InputFieldRowSpacer />
                <InputField
                    shouldSanitizeInput
                    sanitizeRegex={SANITIZE_INPUT_TYPES.USERNAME_INPUT}
                    reactiveForm={true}
                    fieldTitle="Username"
                    fieldName="username"
                    placeholder="username"
                    value={form?.username ?? ''}
                    {...reactiveFormProps}
                />
            </FieldRowContainer>
            <FormHeaderLabel>*Please enter your zip code</FormHeaderLabel>
            <FieldRowContainer>
                <InputField
                    width="30%"
                    shouldSanitizeInput
                    reactiveForm={true}
                    maxLength={5}
                    fieldTitle="Zip"
                    fieldName="location"
                    placeholder="Zip Code"
                    nestedFieldName={fieldNames.location.zip}
                    value={form?.location?.[fieldNames.location.zip] ?? ''}
                    {...reactiveFormProps}
                />
            </FieldRowContainer>
        </FieldContainer>
    )
}

export default PersonalDetails
