import { useEffect } from 'react'
import { CLASS_EVENTS } from 'constants/streamEvents'
const {
    CLASS_UPDATE_INTERVAL,
    CLASS_COMPLETE,
    CLASS_ENDED_BY_HOST,
    CLASS_STARTED,
    CLASS_START_COUNTDOWN,
    CLASS_LATE_JOIN,
} = CLASS_EVENTS

export const useHandleClassEvents = reactiveStreamProps => {
    const { streamState, streamStateSetters, streamHelpers, classEventEmitters, handleUpdateClassStatus } =
        reactiveStreamProps
    const {
        setCurrentInterval,
        setCurrentIntervalIndex,
        setStreamOverlayMessage,
        setClassStarted,
        setClassComplete,
        setClassEnded,
        setIsLateJoin,
        setTimeUntilNextInterval,
    } = streamStateSetters
    const { streamEvent, streamEventQueue, instructorClass, currentIntervalIndex, logClassEvents, isLateJoin } =
        streamState
    const { triggerEvent: triggerClassEvent } = streamHelpers
    const { queueIntervalCountdown } = classEventEmitters
    const { intervals } = instructorClass || {}

    const shouldLogEvents = __DEV__ && logClassEvents
    const handleClassEvents = async queuedEvent => {
        const { event: classEvent, payload } = queuedEvent ? queuedEvent : streamEvent || {}

        switch (classEvent) {
            case CLASS_UPDATE_INTERVAL:
                if (shouldLogEvents) console.log('CLASS_UPDATE_INTERVAL')
                if (isLateJoin) {
                    setIsLateJoin(false)
                }
                setCurrentInterval(intervals[currentIntervalIndex + 1])
                setCurrentIntervalIndex(currentIntervalIndex + 1)
                break

            case CLASS_STARTED:
                if (shouldLogEvents) console.log('CLASS_STARTED')
                setCurrentInterval(intervals[currentIntervalIndex])
                setClassStarted(true)
                break

            case CLASS_COMPLETE:
                if (shouldLogEvents) console.log('CLASS_COMPLETE')
                const { overlayMessage: classCompleteMessage } = payload
                setClassComplete(true)
                setStreamOverlayMessage(classCompleteMessage)
                break

            case CLASS_START_COUNTDOWN:
                if (shouldLogEvents) console.log('CLASS_START_COUNTDOWN')
                const { overlayMessage: countdownMessage } = payload
                setStreamOverlayMessage(countdownMessage)
                break

            case CLASS_ENDED_BY_HOST:
                if (shouldLogEvents) console.log('CLASS_ENDED_BY_HOST')
                const { overlayMessage: endClassMessage } = payload
                setClassEnded(true)
                setStreamOverlayMessage(endClassMessage)
                break

            case CLASS_LATE_JOIN:
                if (shouldLogEvents) console.log('CLASS_LATE_JOIN')
                const { classProgress, overlayMessage: lateJoinMessage } = payload
                const { currentIntervalIndex: intervalInProgress, timeUntilNextInterval } = classProgress
                setIsLateJoin(true)
                setTimeUntilNextInterval(timeUntilNextInterval)
                setCurrentIntervalIndex(intervalInProgress)
                setCurrentInterval(intervals[intervalInProgress])
                setClassStarted(true)
                break

            default:
                break
        }
    }

    useEffect(() => {
        handleClassEvents()
    }, [streamEvent])

    useEffect(() => {
        streamEventQueue.forEach(async queuedEvent => await handleClassEvents(queuedEvent))
    }, [streamEventQueue])
}

export default useHandleClassEvents
