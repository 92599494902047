import React, { useState, useEffect, useMemo } from 'react'
import { Text, View, TouchableOpacity, FlatList, RefreshControl } from 'react-native'
import styled from 'styled-components/native'
import colors from 'colors'
import VectorIcon from 'components/VectorIcon'
import { truncateText, getTimeSinceCreatedAt } from 'helpers'
import { Inter900, Inter500 } from 'styles/typography'
import { useNavigation } from '@react-navigation/native'
import { getNotificationAction } from 'navigationActions'
import { TRAINEE_NOTIFICATION_DETAILS } from 'screenNames'
import { TRAINEE_NOTIFICATION_TYPES, INSTRUCTOR_NOTIFICATION_TYPES } from 'constants/notificationConstants'
import { iconHitSlop, NOTIFICATION_TYPES, PAGINATED_LIMIT } from '@constants'
import useNotifications from 'hooks/useNotifications'
import { useDimensions } from 'dimensions'
const emptyObj = {}
const noop = () => {}

const newColor = colors.homebodyTurquoise
const oldColor = colors.subTextGray

const NotificationCard = ({
    notification,
    navigation,
    onPress,
    selectedItem,
    isInstructor,
    updateNotificationSeen,
}) => {
    const {
        id,
        type,
        content: { title, body, data } = emptyObj,
        isNew: isNewNotification,
        createdAt,
    } = notification || emptyObj
    const isTraineeNotification = TRAINEE_NOTIFICATION_TYPES?.hasOwnProperty(type)
    const { actionType, icon } = isTraineeNotification
        ? TRAINEE_NOTIFICATION_TYPES?.[type]
        : INSTRUCTOR_NOTIFICATION_TYPES?.[type]
    const { iconProps } = icon || emptyObj
    const truncatedText = truncateText({ text: body })
    const isCancelledClass = type == NOTIFICATION_TYPES?.CLASS_CANCELLED
    const params = JSON?.parse(data) || emptyObj
    const [textColor, setTextColor] = useState(
        isNewNotification ? (isCancelledClass ? colors.cancelledClassRed : newColor) : oldColor,
    )
    const timeSinceNotification = useMemo(() => getTimeSinceCreatedAt(createdAt), [])

    const goToNotificationDetails = async () => {
        navigation.navigate(TRAINEE_NOTIFICATION_DETAILS, { title, body, timeSinceNotification })
        if (isNewNotification) {
            await updateNotificationSeen({ notification })
        }
    }
    const notificationAction = getNotificationAction({ navigation, actionType, params })

    const handleOnPress = async () => {
        setTextColor(oldColor)
        if (!isInstructor) {
            notificationAction()
        } else {
            onPress({ ...notification, notificationAction })
        }
        if (isNewNotification) {
            await updateNotificationSeen({ notification })
        }
    }

    return (
        <View
            style={{
                backgroundColor: id === selectedItem && isInstructor ? colors.inputFieldGray : colors.white,
                marginTop: isInstructor ? '3%' : '5%',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}
        >
            <NotificationCardContainer onPress={handleOnPress} isInstructor={isInstructor}>
                <NotificationContainer>
                    <IconContainer
                        iconSize={iconProps.size}
                        isInstructor={isInstructor}
                        color={isInstructor ? newColor : textColor}
                    >
                        <VectorIcon
                            family={iconProps.family}
                            name={iconProps.name}
                            color={colors.white}
                            size={iconProps.size}
                        />
                    </IconContainer>
                    <NotificationInfoContainer isInstructor={isInstructor}>
                        <TitleText color={textColor}>{title}</TitleText>
                        <MessageText color={isInstructor ? textColor : oldColor}>{truncatedText}</MessageText>
                        <TimeText color={textColor}>{timeSinceNotification}</TimeText>
                    </NotificationInfoContainer>
                    <ArrowContainer disabled={isInstructor} hitSlop={iconHitSlop} onPress={goToNotificationDetails}>
                        <VectorIcon family="AntDesign" name="right" size={18} />
                    </ArrowContainer>
                </NotificationContainer>
            </NotificationCardContainer>
            <SelectedHighlight
                style={{
                    backgroundColor: id === selectedItem && isInstructor ? colors.homebodyTurquoise : colors.white,
                }}
            />
        </View>
    )
}

export default function NotificationFlatList({
    style,
    data,
    onPress = noop,
    selected = null,
    isInstructor = false,
    onReachEnd = noop,
    refreshing,
    onPullToRefresh = noop,
}) {
    const { isMobile } = useDimensions()
    const navigation = useNavigation()
    const { handleUpdateNotification: updateNotificationSeen } = useNotifications()

    const renderItem = ({ item }) => {
        return (
            <NotificationCard
                navigation={navigation}
                notification={item}
                onPress={onPress}
                selectedItem={selected}
                isInstructor={isInstructor}
                updateNotificationSeen={updateNotificationSeen}
            />
        )
    }
    return (
        <NotificationFlatlistContainer isInstructor={isInstructor}>
            <NotificationList
                initialNumToRender={PAGINATED_LIMIT}
                style={style}
                data={data}
                renderItem={renderItem}
                keyExtractor={item => `${item.id}${item._version}`}
                onEndReached={onReachEnd}
                onEndReachedThreshold={onReachEnd !== noop && 0.3}
                refreshControl={
                    onPullToRefresh ? (
                        <RefreshControl
                            refreshing={refreshing}
                            onRefresh={onPullToRefresh}
                            tintColor={colors.homebodyTurquoise}
                        />
                    ) : undefined
                }
                showsVerticalScrollIndicator={false}
            />
        </NotificationFlatlistContainer>
    )
}

const TitleText = styled(Inter900)`
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 2%;
    color: ${props => props.color};
`

const MessageText = styled(Inter500)`
    font-size: 12px;
    line-height: 15px;
    color: ${props => props.color};
`

const TimeText = styled(Inter500)`
    font-size: 9px;
    line-height: 11px;
    margin-top: 2%;
    color: ${props => props.color};
`

const NotificationFlatlistContainer = styled(View)`
    flex: 1;
    align-self: ${({ isInstructor, theme }) => (!isInstructor && !theme.isMobile ? 'center' : 'flex-start')};
    width: ${({ isInstructor, theme }) => (!isInstructor && !theme.isMobile ? '35%' : '100%')};
`

const NotificationList = styled(FlatList)`
    flex: 1;
`

const SelectedHighlight = styled(View)`
    height: 100%;
    width: 3px;
    position: absolute;
    right: 0px;
`

const ArrowContainer = styled(TouchableOpacity)`
    align-self: center;
    flex: 1;
`

const IconContainer = styled(View)`
    width: ${props => props.iconSize + (props.isInstructor ? 18 : 12)}px;
    height: ${props => props.iconSize + (props.isInstructor ? 18 : 12)}px;
    border-radius: ${props => (props.iconSize + (props.isInstructor ? 18 : 12)) / 2}px;
    align-self: center;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
    background-color: ${props => props.color};
`

const NotificationInfoContainer = styled(View)`
    width: ${props => (props.isInstructor ? '70%' : '75%')};
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    background-color: transparent;
`

const NotificationContainer = styled(View)`
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
`

const NotificationCardContainer = styled(TouchableOpacity)`
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    align-self: flex-start;
    padding-left: ${props => (props.isInstructor ? '5%' : '0%')};
    padding-vertical: 10px;
`
