import { gql } from '@apollo/client'
import * as queries from '@graphql/queries'

export const GET_WAIT_LIST = gql`
    ${queries.getWaitList}
`

export const LIST_WAIT_LISTS = gql`
    ${queries.listWaitLists}
`

export const GET_USER = gql`
    query GetUser($id: ID!) @persist {
        getUser(id: $id) {
            id
            email
            username
            phoneNumber
            avatar {
                bucket
                region
                key
            }
            customerID
            accountID
            credits
            files {
                items {
                    id
                    name
                    ownerName
                    trainerApplicationID
                    userID
                    fileType
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                }
                nextToken
                startedAt
            }
            application {
                id
                firstName
                lastName
                username
                isInfluencer
                isTrainer
                isOwner
                submitted
                location {
                    city
                    state
                    zip
                }
                socials {
                    instagram
                    twitter
                    facebook
                    snapchat
                    linkedin
                }
                gymStudioName
                website
                specializations {
                    barre
                    bound
                    crossfit
                    core
                    circuitTraining
                    dance
                    HIIT
                    kickBoxing
                    meditation
                    pilates
                    power
                    pregnancy
                    sculpt
                    strength
                    stretch
                    yoga
                    zumba
                    cardio
                    endurance
                    jump
                    foamRoll
                    run
                    yogaSculpt
                    restorative
                    recovery
                    strengthAndConditioning
                    flow
                    boxing
                    celebrity
                    weightLoss
                    bodyWeight
                    stability
                    mobility
                    functionalTraining
                    glutes
                    legs
                    arms
                    performanceTraining
                    flexibility
                    spiritualWellness
                    wellness
                    breathWork
                    beginner
                    advanced
                    kettleBell
                    tabata
                    bodyBuilding
                    athleteTraining
                    cycling
                    plusSize
                    other
                }
                userID
                lastIndex
                gymStudioAddress {
                    streetAddress
                    city
                    state
                    zip
                    country
                }
                files {
                    nextToken
                    startedAt
                }
                approved
                dateApproved
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
            }
            cognitoUserID
            classes {
                items {
                    id
                    userID
                    name
                    description
                    date
                    categoryFilter
                    duration
                    equipment
                    classStatus
                    classStatusString
                    price
                    startTime
                    defaultBannerIndex
                    playlist
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                }
                nextToken
                startedAt
            }
            profile {
                firstName
                lastName
                bio
                website
                socials {
                    instagram
                    twitter
                    facebook
                    snapchat
                    linkedin
                }
                location {
                    city
                    state
                    zip
                }
                specializations {
                    barre
                    bound
                    crossfit
                    core
                    circuitTraining
                    dance
                    HIIT
                    kickBoxing
                    meditation
                    pilates
                    power
                    pregnancy
                    sculpt
                    strength
                    stretch
                    yoga
                    zumba
                    cardio
                    endurance
                    jump
                    foamRoll
                    run
                    yogaSculpt
                    restorative
                    recovery
                    strengthAndConditioning
                    flow
                    boxing
                    celebrity
                    weightLoss
                    bodyWeight
                    stability
                    mobility
                    functionalTraining
                    glutes
                    legs
                    arms
                    performanceTraining
                    flexibility
                    spiritualWellness
                    wellness
                    breathWork
                    beginner
                    advanced
                    kettleBell
                    tabata
                    bodyBuilding
                    athleteTraining
                    cycling
                    plusSize
                    other
                }
            }
            userType
            userTypeString
            categoryFilter
            classCategories {
                barre
                bound
                crossfit
                core
                circuitTraining
                dance
                HIIT
                kickBoxing
                meditation
                pilates
                power
                pregnancy
                sculpt
                strength
                stretch
                yoga
                zumba
                cardio
                endurance
                jump
                foamRoll
                run
                yogaSculpt
                restorative
                recovery
                strengthAndConditioning
                flow
                boxing
                celebrity
                weightLoss
                bodyWeight
                stability
                mobility
                functionalTraining
                glutes
                legs
                arms
                performanceTraining
                flexibility
                spiritualWellness
                wellness
                breathWork
                beginner
                advanced
                kettleBell
                tabata
                bodyBuilding
                athleteTraining
                cycling
                plusSize
                other
            }
            classBookings {
                items {
                    id
                    classID
                    userID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                }
                nextToken
                startedAt
            }
            reviews {
                items {
                    id
                    review
                    rating
                    classID
                    userID
                    instructorID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                }
                nextToken
                startedAt
            }
            favorites {
                items {
                    id
                    userID
                    instructorID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                }
                nextToken
                startedAt
            }
            notifications {
                items {
                    id
                    userID
                    pushToken
                    type
                    isNew
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                }
                nextToken
                startedAt
            }
            transactions {
                items {
                    id
                    credits
                    classID
                    finalCreditBalance
                    initialCreditBalance
                    type
                    userID
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                }
                nextToken
                startedAt
            }
            pushToken
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
        }
    }
`

export const LIST_USERS_BY_TYPE = gql`
    ${queries.userByUserType}
`

export const GET_USER_PROFILE = gql`
    query GetUser($id: ID!) @persist {
        getUser(id: $id) {
            id
            avatar {
                bucket
                region
                key
            }
            application {
                id
                approved
                dateApproved
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
            }
            cognitoUserID
            classes {
                items {
                    id
                    userID
                    name
                    description
                    date
                    categoryFilter
                    duration
                    equipment
                    classStatus
                    classStatusString
                    price
                    startTime
                    defaultBannerIndex
                    playlist
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                }
                nextToken
                startedAt
            }
            profile {
                firstName
                lastName
                bio
                socials {
                    instagram
                    twitter
                    facebook
                    snapchat
                    linkedin
                }
            }
            userType
            categoryFilter
            classCategories {
                barre
                bound
                crossfit
                core
                circuitTraining
                dance
                HIIT
                kickBoxing
                meditation
                pilates
                power
                pregnancy
                sculpt
                strength
                stretch
                yoga
                zumba
                cardio
                endurance
                jump
                foamRoll
                run
                yogaSculpt
                restorative
                recovery
                strengthAndConditioning
                flow
                boxing
                celebrity
                weightLoss
                bodyWeight
                stability
                mobility
                functionalTraining
                glutes
                legs
                arms
                performanceTraining
                flexibility
                spiritualWellness
                wellness
                breathWork
                beginner
                advanced
                kettleBell
                tabata
                bodyBuilding
                athleteTraining
                cycling
                plusSize
                other
            }
            pushToken
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
        }
    }
`
export const LIST_USERS = gql`
    ${queries.listUsers}
`
export const LIST_USERS_MIN = gql`
    query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String, $isAvatar: Boolean!) @persist {
        listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                avatar {
                    bucket
                    region
                    key
                }
                cognitoUserID
                profile {
                    firstName
                    lastName
                    bio
                    website
                }
                userType
                categoryFilter @skip(if: $isAvatar)
                classCategories @skip(if: $isAvatar) {
                    barre
                    bound
                    crossfit
                    core
                    circuitTraining
                    dance
                    HIIT
                    kickBoxing
                    meditation
                    pilates
                    power
                    pregnancy
                    sculpt
                    strength
                    stretch
                    yoga
                    zumba
                    cardio
                    endurance
                    jump
                    foamRoll
                    run
                    yogaSculpt
                    restorative
                    recovery
                    strengthAndConditioning
                    flow
                    boxing
                    celebrity
                    weightLoss
                    bodyWeight
                    stability
                    mobility
                    functionalTraining
                    glutes
                    legs
                    arms
                    performanceTraining
                    flexibility
                    spiritualWellness
                    wellness
                    breathWork
                    beginner
                    advanced
                    kettleBell
                    tabata
                    bodyBuilding
                    athleteTraining
                    cycling
                    plusSize
                    other
                }
                pushToken
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
            }
            nextToken
            startedAt
        }
    }
`
export const SEARCH_USERS = gql`
    query SearchUsers(
        $filter: SearchableUserFilterInput
        $sort: [SearchableUserSortInput]
        $limit: Int
        $nextToken: String
        $from: Int
        $aggregates: [SearchableUserAggregationInput]
    ) {
        searchUsers(
            filter: $filter
            sort: $sort
            limit: $limit
            nextToken: $nextToken
            from: $from
            aggregates: $aggregates
        ) {
            items {
                id
                email
                username
                firstName
                lastName
                phoneNumber
                avatar {
                    bucket
                    region
                    key
                }
                files {
                    nextToken
                    startedAt
                }
                application {
                    id
                    username
                    isInfluencer
                    isTrainer
                    isOwner
                    submitted
                    gymStudioName
                    userID
                    lastIndex
                    approved
                    dateApproved
                    createdAt
                    updatedAt
                    _version
                    _deleted
                    _lastChangedAt
                }
                cognitoUserID
                classes {
                    nextToken
                    startedAt
                }
                profile {
                    firstName
                    lastName
                    bio
                    website
                }
                userType
                userTypeString
                categoryFilter
                classCategories {
                    barre
                    bound
                    crossfit
                    core
                    circuitTraining
                    dance
                    HIIT
                    kickBoxing
                    meditation
                    pilates
                    power
                    pregnancy
                    sculpt
                    strength
                    stretch
                    yoga
                    zumba
                    other
                }
                classBookings {
                    nextToken
                    startedAt
                }
                reviews {
                    nextToken
                    startedAt
                }
                favorites {
                    nextToken
                    startedAt
                }
                notifications {
                    nextToken
                    startedAt
                }
                pushToken
                createdAt
                updatedAt
                _deleted
                _lastChangedAt
            }
            nextToken
            total
            aggregateItems {
                name
                result {
                    ... on SearchableAggregateScalarResult {
                        value
                    }
                }
            }
        }
    }
`

export const GET_CURRENT_USER_ID = gql`
    query GetCurrentUserID {
        getCurrentUserID @client
    }
`
export const IS_LOGGED_IN = gql`
    query IsUserLoggedIn {
        isLoggedIn @client
    }
`
export const GET_TRAINER_APPLICATION = gql`
    ${queries.getTrainerApplication}
`
export const LIST_TRAINER_APPLICATION = gql`
    ${queries.listTrainerApplications}
`
export const GET_CLASS = gql`
    ${queries.getClass}
`
export const GET_CLASS_CARD = gql`
    query GetClassCard($id: ID!) {
        getClassCard(id: $id) {
            id
            instructor {
                id
                username
                pushToken
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
            }
            name
            description
            date
            duration
            banner {
                bucket
                region
                key
            }
            classStatus
            startTime
            defaultBannerIndex
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
        }
    }
`
export const LIST_CLASSES = gql`
    ${queries.listClasses}
`

export const SEARCH_CLASSES = gql`
    query SearchClasses(
        $filter: SearchableClassFilterInput
        $sort: [SearchableClassSortInput]
        $limit: Int
        $nextToken: String
        $from: Int
        $aggregates: [SearchableClassAggregationInput]
    ) {
        searchClasses(
            filter: $filter
            sort: $sort
            limit: $limit
            nextToken: $nextToken
            from: $from
            aggregates: $aggregates
        ) {
            items {
                id
                userID
                instructor {
                    id
                    email
                    username
                    phoneNumber
                    cognitoUserID
                    userType
                    userTypeString
                    pushToken
                    createdAt
                    updatedAt
                    _deleted
                    _lastChangedAt
                }
                name
                description
                date
                categoryFilter
                category {
                    barre
                    bound
                    crossfit
                    core
                    circuitTraining
                    dance
                    HIIT
                    kickBoxing
                    meditation
                    pilates
                    power
                    pregnancy
                    sculpt
                    strength
                    stretch
                    yoga
                    zumba
                    cardio
                    endurance
                    jump
                    foamRoll
                    run
                    yogaSculpt
                    restorative
                    recovery
                    strengthAndConditioning
                    flow
                    boxing
                    celebrity
                    weightLoss
                    bodyWeight
                    stability
                    mobility
                    functionalTraining
                    glutes
                    legs
                    arms
                    performanceTraining
                    flexibility
                    spiritualWellness
                    wellness
                    breathWork
                    beginner
                    advanced
                    kettleBell
                    tabata
                    bodyBuilding
                    athleteTraining
                    cycling
                    plusSize
                    other
                }
                duration
                equipment
                banner {
                    bucket
                    region
                    key
                }
                intervals {
                    name
                    duration
                    timeType
                    type
                    id
                }
                classStatus
                classStatusString
                price
                startTime
                defaultBannerIndex
                classBookings {
                    nextToken
                    startedAt
                }
                stream {
                    id
                    isHostInStreamRoom
                    classID
                    createdAt
                    updatedAt
                    _deleted
                    _lastChangedAt
                }
                playlist
                createdAt
                updatedAt
                _deleted
                _lastChangedAt
            }
            nextToken
            total
            aggregateItems {
                name
                result {
                    ... on SearchableAggregateScalarResult {
                        value
                    }
                }
            }
        }
    }
`
export const GET_CLASS_BOOKING = gql`
    ${queries.getClassBooking}
`
export const LIST_CLASS_BOOKINGS = gql`
    ${queries.listClassBookings}
`
export const LIST_REVIEWS_MIN = gql`
    query ListReviews($filter: ModelReviewFilterInput, $limit: Int, $nextToken: String, $isAvatar: Boolean!) @persist {
        listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                review @skip(if: $isAvatar)
                rating
                userID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
            }
            nextToken
            startedAt
        }
    }
`
export const LIST_REVIEWS_WITH_REVIEWER = gql`
    query ListReviewer($filter: ModelReviewFilterInput, $limit: Int, $nextToken: String) {
        listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                review
                rating
                userID
                reviewer {
                    firstName
                    lastName
                    userID
                    profilePicture {
                        bucket
                        region
                        key
                    }
                }
                _version
                _deleted
                _lastChangedAt
                createdAt
                updatedAt
            }
            nextToken
            startedAt
        }
    }
`

export const GET_STREAM = gql`
    ${queries.getStream}
`
export const LIST_STREAMS = gql`
    ${queries.listStreams}
`
export const LIST_FAVORITE_INSTRUCTORS = gql`
    ${queries.listFavoriteInstructors}
`

export const GET_NOTIFICATION = gql`
    ${queries?.getNotification}
`
export const LIST_NOTIFICATIONS = gql`
    ${queries?.listNotifications}
`
export const NOTIFICATIONS_BY_ID = gql`
    ${queries?.notificationsByUserID}
`
export const SEARCH_NOTIFICATIONS = gql`
    ${queries?.searchNotifications}
`

export const AGGREGATE_NOTIFICATIONS = gql`
    query SearchNotifications(
        $filter: SearchableNotificationFilterInput
        $sort: [SearchableNotificationSortInput]
        $limit: Int
        $nextToken: String
        $from: Int
        $aggregates: [SearchableNotificationAggregationInput]
    ) {
        searchNotifications(
            filter: $filter
            sort: $sort
            limit: $limit
            nextToken: $nextToken
            from: $from
            aggregates: $aggregates
        ) {
            aggregateItems {
                name
                result {
                    ... on SearchableAggregateScalarResult {
                        value
                    }
                }
            }
        }
    }
`
export const SEARCH_CLASSES_WITH_BOOKINGS = gql`
    query SearchClasses(
        $filter: SearchableClassFilterInput
        $sort: [SearchableClassSortInput]
        $limit: Int
        $nextToken: String
        $from: Int
        $aggregates: [SearchableClassAggregationInput]
    ) {
        searchClasses(
            filter: $filter
            sort: $sort
            limit: $limit
            nextToken: $nextToken
            from: $from
            aggregates: $aggregates
        ) {
            items {
                id
                userID
                name
                description
                date
                banner {
                    bucket
                    region
                    key
                }
                classStatus
                classStatusString
                startTime
                defaultBannerIndex
                classBookings {
                    items {
                        id
                        classID
                        userID
                    }
                    nextToken
                    startedAt
                }
                instructor {
                    id
                    profile {
                        firstName
                        lastName
                    }
                    _version
                    _deleted
                    _lastChangedAt
                }
                playlist
                createdAt
                updatedAt
                _deleted
                _lastChangedAt
            }
            nextToken
            total
        }
    }
`
export const CLASS_BY_STATUS = gql`
    ${queries.classByClassStatus}
`
export const CLASS_BY_USER = gql`
    ${queries.classByUserID}
`
export const GET_TRANSACTION = gql`
    ${queries.getTransaction}
`
export const LIST_TRANSACTIONS = gql`
    ${queries.listTransactions}
`
