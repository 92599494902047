import React, { useState, useRef, useEffect } from 'react'
import {
    View,
    Text,
    TouchableOpacity,
    TextInput,
    ScrollView,
    Image,
    ActivityIndicator,
    Pressable,
    StyleSheet,
    Linking,
} from 'react-native'
import styled from 'styled-components/native'
import { Ionicons } from '@expo/vector-icons'
import * as screenNames from 'screenNames'
import { FieldRowContainer, FieldContainer } from 'commons/containers'
import { validateEmail } from 'helpers'
import { StyledTextInput } from 'components/InputField'
import { useMutation, useQuery } from '@apollo/client'
import { GET_WAIT_LIST } from 'apollo/queries'
import { CREATE_WAIT_LIST } from 'apollo/mutations'
import { useDimensions } from 'dimensions'
import { Inter500, Inter700, TEXT_LINEAR_GRADIENT, SubHeaderText, Headline } from 'typography'
import { Video, AVPlaybackStatus } from 'expo-av'
import { LinearGradient } from 'expo-linear-gradient'
import { USER_TYPES } from '@constants'
import colors from 'colors'
import { SCREEN_WIDTH } from '@constants'
import { useAssets } from 'expo-asset'
import { ContinueButton } from 'commons/components/ContinueButton'
import { getCurrentUser } from 'selectors'
import { useNavigation } from '@react-navigation/native'
import { currentUserID } from '../../apollo/cache'
import { getNavigationAction, getNavigateUserHome } from 'navigationActions'

const MOBILE_VIDEO_HEIGHT = 500
const DESKTOP_VIDEO_HEIGHT = 700
const APPLE_APP_STORE_IMAGE = require('assets/appStoreDownload.png')
const APPLE_APP_STORE_URL = 'https://apps.apple.com/us/app/homebody/id1593266402'
const PROMO_URL = 'https://homebody-content-bucket170333-dev.s3.amazonaws.com/public/profilePictures/HOMEBODY-promo.mp4'

const tagline = 'The live streaming fitness platform designed by creators, '
const tgalineGradiented = 'for creators'
const postSubmitMessage = `Thanks for joining our waitlist for HOMEBODY, we'll be in touch soon. Stay Fit!`

const gradientColors = ['rgba(0, 0, 0, 0.75)', 'rgba(0, 0, 0, 0.74)', 'rgba(0, 0, 0, 0)']

const VideoBg = () => {
    const { isMobile } = useDimensions()
    const [assets] = useAssets([PROMO_URL])
    const video = useRef<Video>(null)
    const gradientStart = { x: 1.69, y: 0 }
    const gradientEnd = { x: 35.86, y: 0 }

    return (
        <>
            <PromoVideo ref={video} source={{ uri: PROMO_URL }} resizeMode="cover" isLooping isMuted shouldPlay />
            <VideoOverlay />
        </>
    )
}

const Waitlist = () => {
    const { isMobile, isMobileWeb } = useDimensions()
    const navigation = useNavigation()
    const [teach, setTeach] = useState(false)
    const [take, setTake] = useState(false)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [activityVisible, setActivityVisible] = useState(false)
    const [listJoined, setListJoined] = useState(false)
    const { id: userID = '', userType = '' } = getCurrentUser()
    let waitListText = listJoined ? postSubmitMessage : 'Sign up to join our waitlist'
    const goToAppDownload = () => navigation.navigate(screenNames.TEST_FLIGHT_DOWNLOAD)
    const goToSignUp = () => navigation.navigate(screenNames.SIGN_UP)
    const ctaOnPress = () =>
        !userID ? goToSignUp() : isInstructor ? navigateToInstructorApp() : navigateToTraineeHome()
    const openToAppleAppStore = () => Linking.openURL(APPLE_APP_STORE_URL)

    const { navigationAction, depArray } = getNavigateUserHome()
    const tryIt = getNavigationAction({ navigation, navigationAction, depArray })
    return (
        <Container>
            <VideoBg />
            <ElementContainer>
                <TaglineContainer>
                    <Tagline>
                        {tagline}
                        <TaglineGradiented>{tgalineGradiented}</TaglineGradiented>
                    </Tagline>
                </TaglineContainer>
                <CTAButton onPress={isMobileWeb ? goToAppDownload : tryIt}>
                    <Headline color="white" isBold>
                        Try it free
                    </Headline>
                </CTAButton>
                <AvailableNowContainer>
                    <AvailableNowText>Available now on iOS</AvailableNowText>
                    <AppleAppStoreButton onPress={openToAppleAppStore}>
                        <AppleAppStoreImage source={APPLE_APP_STORE_IMAGE} />
                    </AppleAppStoreButton>
                </AvailableNowContainer>
            </ElementContainer>
        </Container>
    )
}

const Container = styled(View)`
    background-color: black;
`

const ElementContainer = styled(View)`
    flex-direction: column;
    justify-content: space-around;
    padding-left: 5%;
    padding-right: 5%;
    padding-vertical: 5%;
    padding-bottom: 5%;
    flex: 1;
    width: 100%;
    min-height: ${({ theme }) => (theme.isMobile ? MOBILE_VIDEO_HEIGHT - 100 : DESKTOP_VIDEO_HEIGHT)}px;
`
const StaticBanner = styled(Image)`
    position: absolute;
    height: 100%;
    width: 100%;
`
const PromoVideo = styled(Video)`
    position: absolute;
    max-height: ${({ theme }) => (theme.isMobile ? 400 : DESKTOP_VIDEO_HEIGHT)}px;
    left: -10%;
    width: 115%;
`
const VideoOverlay = styled(View)`
    height: ${({ theme }) => (theme.isMobile ? 400 : DESKTOP_VIDEO_HEIGHT)}px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
`
const AppleAppStoreButton = styled(TouchableOpacity)`
    width: ${props => (props.theme.isMobile ? '50%' : '175px')};
`
const AppleAppStoreImage = styled(Image)`
    width: 160px;
    height: 46px;
    margin-top: 25px;
`

const AvailableNowContainer = styled(View)`
    justify-content: space-between;
`
const AvailableNowText = styled(Inter700)`
    color: white;
    font-size: ${props => (props.theme.isMobile ? '17px' : '22px')};
    line-height: ${props => (props.theme.isMobile ? '24px' : '30px')};
`
export const CTAButton = styled(TouchableOpacity)`
    width: ${props => (props.theme.isMobile ? '60%' : '250px')};
    height: ${props => (props.theme.isMobile ? '40px' : '60px')};
    border-radius: ${props => (props.theme.isMobile ? '5px' : '10px')};
    background-color: ${props => props.color || colors.blueContinue};
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const TaglineContainer = styled(View)`
    margin-top: ${({ theme }) => (theme.isMobile ? '0px' : '50px')};
    max-width: ${({ theme }) => (theme.isDesktop ? '850px' : '90%')};
    flex-direction: row;
    flex-wrap: wrap;
    flex-shrink: 1;
`

const Tagline = styled(Inter700)`
    font-size: ${({ theme }) => (theme.isDesktop ? 52 : 32)}px;
    text-align: left;
    color: white;
    letter-spacing: -2px;
`

const TaglineGradiented = styled(Tagline)`
    ${TEXT_LINEAR_GRADIENT};
    letter-spacing: -1px;
`

export default Waitlist
