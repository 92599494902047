import React from 'react'
import { View, Modal, TouchableOpacity, StyleSheet, Linking } from 'react-native'
import colors from 'colors'
import { Inter900, Inter500 } from 'typography'
import styled from 'styled-components/native'
import FormButtons from 'components/FormButtons'
import { ButtonText } from 'typography'
import { ContinueButton } from 'components/ContinueButton'
import { isAndroid } from '@constants'

const headerText = 'Allow push notifications?'
const modalDetails = 'Please allow push notifications to receive important updates on your upcoming classes.'
const styles = StyleSheet.create({
    continueButton: {
        width: 104,
        height: 40,
    },
})

export const AllowNotificationModal = ({ visible, toggleVisible, loading, isGradient }) => {
    function goToSettings() {
        toggleVisible()
        if (isAndroid) {
            Linking.openSettings()
        } else {
            Linking.openURL('app-settings:')
        }
    }

    return (
        <Modal animationType="slide" transparent={true} visible={visible}>
            <ModalContainer>
                <ModalContentContainer>
                    <View>
                        <ModalHeader>{headerText}</ModalHeader>
                        <ModalDetails>{modalDetails}</ModalDetails>
                    </View>
                    <ButtonsContainer>
                        <ButtonContainer onPress={toggleVisible}>
                            <ContinueButton
                                onPress={toggleVisible}
                                text={`Don't Allow`}
                                color={colors.grayContinue}
                                style={styles.continueButton}
                            />
                        </ButtonContainer>
                        <ButtonContainer onPress={goToSettings}>
                            <ContinueButton
                                onPress={goToSettings}
                                text={`Allow`}
                                color={colors.blueContinue}
                                style={styles.continueButton}
                            />
                        </ButtonContainer>
                    </ButtonsContainer>
                </ModalContentContainer>
            </ModalContainer>
        </Modal>
    )
}

const ModalHeader = styled(Inter900)`
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    color: ${colors.homebodyTurquoise};
`
const ModalContainer = styled(View)`
    flex: 1;
    padding-top: 60%;
`
const ModalDetails = styled(Inter500)`
    font-size: 12px;
    color: black;
    padding-vertical: 14px;
    padding-horizontal: 34px;
    text-align: center;
    line-height: 21px;
`
const ModalContentContainer = styled(View)`
    align-self: center;
    width: 296px;
    height: 245px;
    border-radius: 39.41px;
    border-width: ${StyleSheet.hairlineWidth}px;
    border-color: gray;
    shadow-color: #000000;
    shadow-offset: 4px 4px;
    shadow-radius: 8px;
    shadow-opacity: 0.25;
    background-color: white;
    padding-top: 45px;
    overflow: hidden;
`
const ButtonsContainer = styled(View)`
    padding-top: 16px;
    flex-direction: row;
`

const ButtonContainer = styled(TouchableOpacity)`
    border: 0.8px solid #b3bfd2;
    padding-vertical: 15px;
    width: 50%;
    align-items: center;
    justify-content: center;
`

export default AllowNotificationModal
