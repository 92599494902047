import { useRef, useState } from 'react'
import colors from 'styles/colors'
import styled from 'styled-components/native'
import { View, ScrollView, Animated } from 'react-native'
import { isWeb } from '@constants'

const VerticalScrollView = ({
    children,
    thumbColor = colors.homeBodyGreyBg,
    trackColor = 'transparent',
    thickness = 5,
    glassVisiblity = 1,
    glassSize = 0,
    glassColor = 'transparent',
    style = {},
}) => {
    const [completeScrollBarHeight, setCompleteScrollBarHeight] = useState(1)
    const [visibleScrollBarHeight, setVisibleScrollBarHeight] = useState(0)

    const scrollIndicator = useRef(new Animated.Value(0)).current

    const scrollIndicatorheight =
        completeScrollBarHeight > visibleScrollBarHeight
            ? (visibleScrollBarHeight * visibleScrollBarHeight) / completeScrollBarHeight
            : visibleScrollBarHeight

    const difference =
        visibleScrollBarHeight > scrollIndicatorheight ? visibleScrollBarHeight - scrollIndicatorheight : 1

    const scrollIndicatorPosition = Animated.multiply(
        scrollIndicator,
        visibleScrollBarHeight / completeScrollBarHeight,
    ).interpolate({
        inputRange: [0, difference],
        outputRange: [0, difference],
        extrapolate: 'clamp',
    })

    const ScrollThumbStyle = {
        width: thickness,
        borderRadius: 20,
        backgroundColor: thumbColor,
        height: scrollIndicatorheight,
        transform: [{ translateY: scrollIndicatorPosition }],
    }

    return (
        <GlassParentContainer style={style}>
            <ScrollParentContainer>
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    scrollEventThrottle={16}
                    onContentSizeChange={(_, height) => {
                        setCompleteScrollBarHeight(height)
                    }}
                    onLayout={({
                        nativeEvent: {
                            layout: { height },
                        },
                    }) => {
                        setVisibleScrollBarHeight(height)
                    }}
                    onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollIndicator } } }], {
                        useNativeDriver: false,
                    })}
                >
                    {children}
                </ScrollView>
                <ScrollTrack color={trackColor} thickness={thickness}>
                    <Animated.View style={ScrollThumbStyle} />
                </ScrollTrack>
            </ScrollParentContainer>
            {glassSize && (
                <GlassContainer size={glassSize} color={glassColor} thickness={thickness} opacity={glassVisiblity} />
            )}
        </GlassParentContainer>
    )
}

const GlassParentContainer = styled(View)`
    flex: 1;
`
const GlassContainer = styled(View)`
    background-color: ${({ color }) => color};
    height: ${({ size }) => `${size}px`};
    margin-top: ${({ size }) => `${-size}px`};
    opacity: ${({ opacity }) => 1 - opacity};
    margin-left: 10px;
    margin-right: ${({ thickness }) => `${thickness + 10}px`};
`
const ScrollParentContainer = styled(View)`
    flex: 1;
    flex-direction: row;
    padding-horizontal: 10px;
    ${isWeb && `overflow-y: auto;`}
`

const ScrollTrack = styled(View)`
    height: 100%;
    width: ${({ thickness }) => `${thickness}px`};
    background-color: ${({ color }) => color};
`

export default VerticalScrollView
