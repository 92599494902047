import React from 'react'
import { TouchableOpacity } from 'react-native'
import { Feather } from '@expo/vector-icons'
import { iconHitSlop } from '@constants'

const MenuIcon = ({ onPress, color = 'black', size }) => (
    <TouchableOpacity onPress={onPress} hitSlop={iconHitSlop}>
        <Feather name="menu" size={size} color={color} />
    </TouchableOpacity>
)
export default MenuIcon
