import React, { useState, useEffect } from 'react'
import { View, FlatList, ActivityIndicator } from 'react-native'
import colors from 'styles/colors'
import _ from 'lodash'
import { ScreenContainer, ContentContainer, RowContainer } from 'commons/containers'
import { SubHeading, Caption } from 'styles/typography'
import styled from 'styled-components/native'
import { normalizeTransactionData } from 'helpers'
import { getCurrentUser, getTransactionsByUserID } from 'apollo/selectors'
import { stripeAPI } from 'src/actions'
import { FooterBar } from 'commons/components/FooterBar'
import { TraineeWebBannerHeader } from 'commons/components/TraineeWebBannerHeader'
import { STRIPE_API_ROUTES, TRANSACTION_RECORD_TYPES } from '@constants'
const { CREDIT_TRANSACTION, INTENT_TRANSACTION, SUBSCRIPTION_TRANSACTION } = TRANSACTION_RECORD_TYPES
const { LIST_INTENTS, LIST_SUBSCRIPTIONS, LIST_PAYMENT_METHODS } = STRIPE_API_ROUTES

const TransactionCard = ({ transaction }) => {
    const { credits, title, detail, price, dateString, transactionType } = transaction

    return (
        <TransactionCardContainer>
            <RowContainer style={{ justifyContent: 'space-between' }}>
                <SubHeading isMostBold color={colors.homebodyTurquoise}>
                    {title}
                </SubHeading>
                <SubHeading isMostBold style={{ letterSpacing: 0 }}>
                    {price}
                </SubHeading>
            </RowContainer>
            <SubHeading color={colors.subTextGray}>{detail}</SubHeading>
            <RowContainer>
                <Caption isSmall color={colors.homebodyTurquoise}>
                    {dateString}
                </Caption>
                {transactionType !== 'credit_transaction' && !!credits && (
                    <Caption isSmall color={colors.homebodyTurquoise} style={{ marginLeft: 30 }}>
                        {`${credits} credits`}
                    </Caption>
                )}
            </RowContainer>
        </TransactionCardContainer>
    )
}

export default function TransactionHistory() {
    const { customerID, id } = getCurrentUser()
    const [intentTransactions, setIntentTransactions] = useState(null)
    const [subscriptionTransactions, setSubscriptionTransactions] = useState(null)
    const [creditTransactions, setCreditTransactions] = useState(null)
    const [transactions, setTransactions] = useState(null)
    const [loading, setLoading] = useState(false)
    const { userTransactions: inAppCreditTransactions } = getTransactionsByUserID({ userID: id }) || []

    const renderItem = ({ item }) => {
        return <TransactionCard transaction={item} />
    }

    function aggregateNormalizedTransactions() {
        let aggregate = []
        intentTransactions?.length && aggregate?.push(...intentTransactions)
        creditTransactions?.length && aggregate?.push(...creditTransactions)
        subscriptionTransactions?.length && aggregate?.push(...subscriptionTransactions)
        const sortedTransactions = _?.sortBy(aggregate, transaction => transaction?.created)?.reverse()
        setTransactions(sortedTransactions)
    }

    useEffect(() => {
        if (inAppCreditTransactions?.length) {
            const creditTransactionsForRender = normalizeTransactionData({
                transactions: inAppCreditTransactions,
                type: CREDIT_TRANSACTION,
            })
            setCreditTransactions(creditTransactionsForRender)
        }
    }, [inAppCreditTransactions])

    useEffect(() => {
        async function getAllTransactionData() {
            const [storedPaymentMethods, subscriptions, intents] = await Promise.all([
                stripeAPI({
                    path: LIST_PAYMENT_METHODS,
                    pathParameters: customerID,
                }),
                stripeAPI({
                    path: LIST_SUBSCRIPTIONS,
                    pathParameters: customerID,
                }),
                stripeAPI({
                    path: LIST_INTENTS,
                    pathParameters: customerID,
                }),
            ])

            const paymentMethods = new Map(
                storedPaymentMethods?.map(obj => [obj?.id, obj?.card?.last4]),
                [storedPaymentMethods],
            )

            const subscriptionTransactions = normalizeTransactionData({
                transactions: subscriptions,
                type: SUBSCRIPTION_TRANSACTION,
            })
            const intentTransactionsToRender = normalizeTransactionData({
                transactions: intents,
                type: INTENT_TRANSACTION,
                paymentMethods,
            })

            setSubscriptionTransactions(subscriptionTransactions)
            setIntentTransactions(intentTransactionsToRender)
        }

        if (customerID) {
            getAllTransactionData()
        }
    }, [customerID])

    useEffect(() => {
        aggregateNormalizedTransactions()
    }, [intentTransactions, subscriptionTransactions, creditTransactions])

    useEffect(() => {
        transactions !== null &&
            setTimeout(() => {
                setLoading(false)
            }, 1000)
    }, [transactions])

    if (loading) {
        return (
            <ScreenContainer>
                <ContentContainer>
                    {loading && (
                        <StyledActivityIndicator size="large" animating={true} color={colors.homebodyTurquoise} />
                    )}
                </ContentContainer>
            </ScreenContainer>
        )
    } else {
        return (
            <ScreenContainer>
                <TraineeWebBannerHeader title="My Transactions" />
                <ContentContainer isFluid>
                    {transactions?.length === 0 ? (
                        <MessageText color={colors.subTextGray}>you have no transactions</MessageText>
                    ) : (
                        <TransactionFlatlist
                            data={transactions}
                            renderItem={renderItem}
                            keyExtractor={item => `${item?.created}${item?.detail}`}
                        />
                    )}
                </ContentContainer>
                <FooterBar />
            </ScreenContainer>
        )
    }
}

const TransactionHistoryContainer = styled(View)`
    ${({ theme }) => !theme.isMobile && 'width: 40%;'}
`
const TransactionFlatlist = styled(FlatList)``

const TransactionCardContainer = styled(View)`
    width: 60%;
    align-self: center;
    padding-horizontal: 10%;
    padding-vertical: 27px;
`
const StyledActivityIndicator = styled(ActivityIndicator)`
    padding-top: 80%;
    align-self: center;
`
const MessageText = styled(SubHeading)`
    padding-top: 80%;
    align-self: center;
`
