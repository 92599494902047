import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { FieldContainer, ScreenContainer, ContentContainer } from 'commons/containers'
import { Heading, SubHeading } from 'typography'
import { useDimensions } from 'dimensions'
import { getCurrentUser } from 'apollo/selectors'
import colors from 'colors'
import styled from 'styled-components/native'
import UserAvatar from 'components/UserAvatar'
import { userProfileFieldNames as fieldNames, traineeProfileFieldErrors } from 'formFieldInfo'
import useReactiveForm from 'hooks/useReactiveForm'
import { FieldRowContainer } from 'commons/containers'
import { TRAINEE_PROFILE } from 'screenNames'
import { useMutation } from 'apollo-augmented-hooks'
import { userProfileForm } from 'apollo/cache'
import { UPDATE_USER } from 'apollo/mutations'
import { InputField, InputFieldRowSpacer } from 'components/InputField'
import { traineeAccountValidationFunc } from 'validations'
import { ContinueButton } from 'components/ContinueButton'
import { updateUserProfile, updateUserAvatar, pickFile, pickImage } from 'actions'
import { isNative, isWeb } from '@constants'
import { useImagePermissions } from 'hooks/useNativePermissions'
import { FooterBar } from 'commons/components/FooterBar'
import { TraineeWebBannerHeader } from 'commons/components/TraineeWebBannerHeader'

const UploadPhotoButtonStyles = {
    height: 69,
    width: 200,
    borderRadius: 19.5,
}

const DeletePhotoButtonStyles = {
    height: 69,
    width: 200,
    borderRadius: 19.5,
    marginLeft: 17,
}

const DeletePhotoButtonTextStyle = {
    color: colors.black,
    fontSize: 17,
}

const SaveButtonStyle = {
    marginTop: 120,
    marginLeft: 'auto',
    width: 150,
}

export default function TraineeProfile() {
    const { imagePermissions, getImagePermissions } = useImagePermissions()
    const { isMobile } = useDimensions()
    const { id, _version, profile, avatar, email, phoneNumber, firstName, lastName } = getCurrentUser()
    const { bio, socials } = profile || {}
    const { instagram } = socials || {}
    const updateUserMutation = useMutation(UPDATE_USER, {
        onCompleted: () => updateUserProfileStatus(true),
        onError: () => updateUserProfileStatus(false),
    })
    const reactiveFormProps = useReactiveForm({
        currentPage: TRAINEE_PROFILE,
        reactiveForm: userProfileForm,
        fieldNames,
        fieldErrors: traineeProfileFieldErrors,
        validationFunc: traineeAccountValidationFunc,
    })

    const isTablet = isNative && !isMobile

    const { isValid, handleInvalidPage, updateForm } = reactiveFormProps

    const [uploading, setUploading] = useState(false)
    const [actionLoading, setActionLoading] = useState(false)
    const [userProfileStatus, setUserProfileStatus] = useState({ actionSuccess: false, statusMessage: '' })

    async function handleSave() {
        const desiredFields = Object.keys(traineeAccountValidationFunc())
        if (isValid) {
            setActionLoading(true)
            await updateUserProfile({ id, _version, updateUserMutation, isTraineeProfile: true })
        } else {
            setActionLoading(false)
            handleInvalidPage({ desiredFields })
        }
    }

    function updateUserProfileStatus(actionSuccess) {
        const statusMessage = actionSuccess ? 'Profile updated successfully' : 'Error updating profile'
        setTimeout(() => {
            setActionLoading(false)
            setUserProfileStatus({ actionSuccess, statusMessage })
        }, 500)
    }

    async function handleUploadPhoto(justGranted) {
        if (!imagePermissions && !justGranted && isNative) {
            const permissionsGranted = await getImagePermissions()
            if (permissionsGranted) {
                handleUploadPhoto(justGranted)
            }
        } else {
            const fileName = firstName + lastName + 'profilePicture'
            const photoUploadArgs = {
                fileName,
                id,
                setUploading,
                bucketPath: 'profilePictures',
                updateForm,
                fieldName: 'avatar',
            }

            const uploadImageSuccess = isNative ? await pickImage(photoUploadArgs) : await pickFile(photoUploadArgs)
            if (uploadImageSuccess) {
                updateUserAvatar({ id, _version, updateUserMutation })
            }
        }
    }

    async function handleDeletePhoto() {
        userProfileForm({ ...userProfileForm(), avatar: { bucket: '', region: '', key: '' } })
        updateUserAvatar({ id, _version, updateUserMutation })
    }

    useEffect(() => {
        if (profile) {
            userProfileForm({ ...userProfileForm(), firstName, lastName, bio, socials })
        }
    }, [profile])

    useEffect(() => {
        if (userProfileStatus.statusMessage)
            setTimeout(() => {
                setUserProfileStatus({ actionSuccess: false, statusMessage: '' })
            }, 3000)
    }, [userProfileStatus])

    return (
        <ScreenContainer>
            <TraineeWebBannerHeader />
            <Container isFluid>
                <HeadingText isMostBold fontSize={40} marginBottom={40}>
                    My Account
                </HeadingText>
                <ProfilePictureText fontSize={14.3} marginBottom={43} color={colors.darkGrey}>
                    * Your Profile Picture
                </ProfilePictureText>
                <AvatarAndNameContainer>
                    <UserAvatar size={98} userInfo={{ firstName, lastName }} uploading={uploading} />
                    <AvatarActionsAndTooltip>
                        <AvatarActionsContainer>
                            <ContinueButton
                                text="Upload New"
                                onPress={handleUploadPhoto}
                                color={colors.homebodyGreen}
                                buttonTextStyle={{
                                    color: colors.white,
                                    fontSize: 17,
                                }}
                                style={UploadPhotoButtonStyles}
                                nonHover
                            ></ContinueButton>
                            <ContinueButton
                                text="Delete Photo"
                                onPress={handleDeletePhoto}
                                color={colors.neutralGray50}
                                buttonTextStyle={DeletePhotoButtonTextStyle}
                                style={DeletePhotoButtonStyles}
                                nonHover
                            ></ContinueButton>
                        </AvatarActionsContainer>
                        <SubHeading color={colors.darkGrey} fontSize={17} marginTop={9}>
                            Your Profile Picture helps your instructor recognize you on HOMEBODY
                        </SubHeading>
                    </AvatarActionsAndTooltip>
                </AvatarAndNameContainer>
                <FormContainer>
                    <FieldRowContainer flexDirection={isTablet ? 'column' : 'row'} maxWidth="891px">
                        <InputField
                            smallTitle
                            reactiveForm={true}
                            fieldTitle="* First Name"
                            fieldName={fieldNames.firstName}
                            defaultValue={firstName ?? ''}
                            {...reactiveFormProps}
                            width="98%"
                        />
                        <InputFieldRowSpacer />
                        <InputField
                            smallTitle
                            reactiveForm={true}
                            fieldTitle="Last Name"
                            fieldName={fieldNames.lastName}
                            defaultValue={lastName ?? ''}
                            {...reactiveFormProps}
                            width="100%"
                        />
                    </FieldRowContainer>
                    <FieldRowContainer flexDirection={isTablet ? 'column' : 'row'} maxWidth="891px">
                        <InputField
                            style={{ marginBottom: 25 }}
                            smallTitle
                            reactiveForm={false}
                            fieldTitle="Email"
                            fieldName="email"
                            placeholder={email ?? ''}
                            editable={false}
                            hoverable={false}
                            width={phoneNumber ? '98%' : '100%'}
                        />
                        {phoneNumber && (
                            <InputField
                                style={{ marginBottom: 25 }}
                                containerStyle={{ marginLeft: 10 }}
                                smallTitle
                                reactiveForm={false}
                                fieldTitle="Phone Number"
                                fieldName="phone"
                                placeholder={phoneNumber ?? ''}
                                editable={false}
                                hoverable={false}
                                width="100%"
                            />
                        )}
                    </FieldRowContainer>
                </FormContainer>
                <ContinueButton
                    nonHover
                    text="Save"
                    onPress={handleSave}
                    apolloActionStatus={userProfileStatus}
                    actionLoading={actionLoading}
                    style={SaveButtonStyle}
                />
            </Container>
            <FooterBar />
        </ScreenContainer>
    )
}

const AvatarAndNameContainer = styled(View)`
    margin-bottom: 80px;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
`

const HeadingText = styled(Heading)``

const Container = styled(ContentContainer)`
    margin-top: 80px;
`

const ProfilePictureText = styled(SubHeading)``

const AvatarActionsContainer = styled(View)`
    flex-direction: row;
    align-items: center;
`

const AvatarActionsAndTooltip = styled(View)`
    justify-content: center;
    margin-left: 29.5px;
`

const FormContainer = styled(View)`
    padding-right: 25px;
`
