import React, { useState, useEffect } from 'react'
import { useNavigation, useRoute, StackActions, useIsFocused } from '@react-navigation/native'
import { View, TouchableOpacity } from 'react-native'
import { updateClassStatus, deleteStream } from 'actions'
import { ScreenContainer, ContentContainer } from 'commons/containers'
import { INSTRUCTOR_STREAM_ROOM } from 'screenNames'
import { listUsersByClassBookings, getClassBookingsByClassID } from 'apollo/selectors'
import { formatDate, toStandardTime, getCanStartClass, copyClassLinkToClipboard } from 'helpers'
import { getClass, getCurrentUser } from 'apollo/selectors'
import { DELETE_STREAM, UPDATE_CLASS } from 'apollo/mutations'
import { useMutation } from 'apollo-augmented-hooks'
import InstructorFlatList from 'components/trainee/InstructorFlatList'
import VectorIcon from 'commons/components/VectorIcon'
import ClassReview from 'components/instructor/ClassReview'
import colors from 'colors'
import { Caption, ButtonText, Heading } from 'typography'
import styled from 'styled-components/native'
import { CLASS_STATUS } from '@constants'
import { agoraStreamState } from 'apollo/cache'
import { initialAgoraStreamState } from 'initialFormStates'
import { generateAgoraUID } from 'agoraHelpers'
import useNotifications from 'hooks/useNotifications'
import { CancelClassModal } from 'components/instructor/CancelClassModal'
import { useReactiveVar } from '@apollo/client'
import { FooterBar } from 'commons/components/FooterBar'
import { cache } from 'apollo/cache'
const { UNPUBLISHED, CANCELLED } = CLASS_STATUS

const emptyObj = {}
const emptyArr = []

export const ClassButton = ({ onPress, iconProps, color, text }) => {
    const { family, name, size } = iconProps
    return (
        <ButtonContainer>
            <TouchableClassButton onPress={onPress} color={color}>
                <VectorIcon family={family} name={name} size={size} color="white" />
            </TouchableClassButton>
            <ClassButtonText isSemiBold paddingTop={5} color={colors.darkGrey}>
                {text}
            </ClassButtonText>
        </ButtonContainer>
    )
}
const noop = () => {}

export default function PublishedClassDetails() {
    const navigation = useNavigation()
    const { username, id: userID } = getCurrentUser()
    const streamState = useReactiveVar(agoraStreamState)
    const { wasStreamEndedWithListener } = streamState
    const { params = emptyObj } = useRoute()
    const { classID, wasInstructorInStreamRoom = false, setClassCancelled = noop } = params
    const [Class, setClass] = useState(emptyObj)
    const [canJoinStreamRoom, setCanJoinStreamRoom] = useState(
        !(wasInstructorInStreamRoom || wasStreamEndedWithListener),
    )
    const [classButtonsMessage, setClassButtonsMessage] = useState('')
    const [cancelModalVisible, setCancelModalVisible] = useState(false)
    const [actionLoading, setActionLoading] = useState(false)
    const [classBookings, setClassBookings] = useState(emptyArr)
    const [classBookingsNextToken, setClassBookingsNextToken] = useState(null)
    const [bookedUsers, setBookedUsers] = useState(emptyArr)
    const [listUsersNextToken, setListUsersNextToken] = useState(null)
    const [cancelClassStatus, setCancelClassStatus] = useState({ actionSuccess: false, statusMessage: '' })
    const updateClassMutation = useMutation(UPDATE_CLASS, {
        onCompleted: () => updateCancelClassStatus(true),
        onError: () => updateCancelClassStatus(false),
    })
    const deleteStreamMutation = useMutation(DELETE_STREAM)
    const { actionSuccess: isClassCanceled } = cancelClassStatus
    const classFromID = getClass({
        classID,
        onCompleted: () => {
            setClass(classFromID)
        },
    })

    const isFocused = useIsFocused()

    useEffect(() => {
        if ((wasInstructorInStreamRoom || wasStreamEndedWithListener) && isFocused) {
            setTimeout(
                () => {
                    setCanJoinStreamRoom(true)
                },
                __DEV__ ? 2000 : 10000,
            )
        }
    }, [isFocused])

    const {
        id,
        name,
        date: dateBeforeFormat,
        startTime: startTimeBeforeFormat,
        duration,
        equipment,
        _version,
        stream = emptyObj,
    } = Class || emptyObj
    const { _version: streamVersion } = stream || emptyObj

    const classDetails = [
        {
            detail: formatDate(dateBeforeFormat) ?? 'Invalid Date',
        },
        {
            detail: startTime,
        },
        {
            detail: `${duration} min`,
        },
        {
            detail: equipment,
        },
    ]

    const {
        notificationTypes: { CLASS_CANCELLED },
        handleCreateMultipleNotifications,
    } = useNotifications()

    const {
        classBookings: classBookingsResult,
        loading: bookedUsersLoading,
        nextToken: initialClassBookingsToken,
        fetchMore: fetchMoreClassBookings,
    } = getClassBookingsByClassID({
        classID,
        onCompleted: () => {
            if (classBookings?.length === 0 && !classBookingsNextToken) {
                setClassBookingsNextToken(initialClassBookingsToken)
                setClassBookings(classBookingsResult)
            }
        },
    })

    async function handleFetchMoreClassBookings() {
        if (classBookingsNextToken) {
            const fetchMoreResult = await fetchMoreClassBookings({ variables: { nextToken: classBookingsNextToken } })
            const {
                data: {
                    listClassBookings: { items: moreBookings = emptyArr, nextToken: token = null },
                },
            } = fetchMoreResult
            const allClassBookings = classBookings?.length > 0 ? [...classBookings, ...moreBookings] : [...moreBookings]
            setClassBookings(allClassBookings)
            setClassBookingsNextToken(token)
        }
    }

    useEffect(() => {
        if (classBookingsNextToken !== null) {
            handleFetchMoreClassBookings()
        }
    }, [classBookingsNextToken])

    const {
        bookedUsers: usersByClassBookingsResult,
        nextToken: initialToken,
        fetchMore: fetchMoreBookedUsers,
    } = listUsersByClassBookings({
        classBookings,
        isAvatar: true,
        onCompleted: () => {
            if (bookedUsers?.length === 0 && !listUsersNextToken) {
                setListUsersNextToken(initialToken)
                setBookedUsers(usersByClassBookingsResult)
            }
        },
    })

    async function handleFetchMoreUsers() {
        if (listUsersNextToken) {
            const fetchMoreResult = await fetchMoreBookedUsers({ variables: { nextToken: listUsersNextToken } })
            const {
                data: {
                    listUsers: { items: moreUsers = emptyArr, nextToken: token = null },
                },
            } = fetchMoreResult
            const allBookedUsers = bookedUsers?.length > 0 ? [...bookedUsers, ...moreUsers] : [...moreUsers]
            setBookedUsers(allBookedUsers)
            setListUsersNextToken(token)
        }
    }

    useEffect(() => {
        if (listUsersNextToken !== null) {
            handleFetchMoreUsers()
        }
    }, [listUsersNextToken])

    const numBooked = bookedUsers?.length
    const date = formatDate(dateBeforeFormat)
    const startTime = toStandardTime(startTimeBeforeFormat)
    const canStartClass = getCanStartClass({
        date: dateBeforeFormat,
        startTime: startTimeBeforeFormat,
        isInstructor: true,
    })

    const goToInstructorStreamRoom = () => {
        setCanJoinStreamRoom(false)
        agoraStreamState({ ...initialAgoraStreamState })
        navigation.navigate(INSTRUCTOR_STREAM_ROOM, { classID: Class?.id, numBooked })
    }

    async function handleClassButtonPress(button) {
        switch (button) {
            case 'cancel':
                toggleCancelModalVisible()
                break

            case 'share':
                copyClassLinkToClipboard({ id })
                setClassButtonsMessage('Class link copied to clipboard')
                break

            case 'launch':
                if (!canJoinStreamRoom) {
                    setClassButtonsMessage(
                        'You cannot launch the class until 10 seconds after leaving the stream room.',
                    )
                } else {
                    if (canStartClass) {
                        goToInstructorStreamRoom()
                    } else {
                        setClassButtonsMessage(
                            `You cannot launch this class until ${date} within 1 hour of ${startTime}`,
                        )
                    }
                }
                break
        }
    }

    function updateCancelClassStatus(actionSuccess) {
        const statusMessage = actionSuccess ? 'This Class has been Canceled' : 'Error canceling class.'
        setTimeout(() => {
            setActionLoading(false)
            setCancelClassStatus({ actionSuccess, statusMessage })
            setClassButtonsMessage(statusMessage)
        }, 1000)
    }

    async function handleCancelClass() {
        setActionLoading(true)
        await updateClassStatus({
            updateClassMutation,
            id,
            _version,
            classStatus: __DEV__ ? CANCELLED : CANCELLED,
        })
        await deleteStream({ id, _version: streamVersion, deleteStreamMutation })

        setClassCancelled(true)

        const normalizedClassID = cache.identify({
            id,
            userID,
            __typename: 'Class',
        })

        const normalizedStreamID = cache.identify({
            id,
            __typename: 'Stream',
        })

        cache.evict({ id: normalizedClassID })
        cache.evict({ id: normalizedStreamID })
        cache.gc()

        if (bookedUsers?.length > 0) {
            const content = {
                title: 'Class Cancelled',
                body: `Sorry! ${username} cancelled ${name}. You will be refunded, but we have a ton of other classes you can book right now!`,
                data: JSON.stringify({}),
            }
            await handleCreateMultipleNotifications({
                users: bookedUsers,
                content,
                type: CLASS_CANCELLED,
            })
        }
        toggleCancelModalVisible()
        setActionLoading(false)
    }
    const cancelClass = () => handleClassButtonPress('cancel')
    const shareClass = () => handleClassButtonPress('share')
    const launchClass = () => handleClassButtonPress('launch')

    const toggleCancelModalVisible = () => setCancelModalVisible(!cancelModalVisible)

    useEffect(() => {
        let timer
        if (classButtonsMessage && !isClassCanceled) {
            timer = setTimeout(() => {
                setClassButtonsMessage('')
            }, 3000)
        }
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        }
    }, [classButtonsMessage])

    useEffect(() => {
        const resetStack = navigation.getParent().addListener('blur', () => {
            navigation.dispatch(StackActions.popToTop())
        })

        return resetStack
    }, [navigation])

    return (
        <ScreenContainer hasTopPadding>
            <ContentContainer isFluid>
                <ClassReview Class={Class} isCreateClassForm={false} classDetails={classDetails} />
                <TraineeFlatList
                    isAvatar
                    hidePicker
                    horizontal
                    isSmallHeading
                    shouldHideNoInstructorsText
                    hideFindInstructorsCard
                    avatarSize={60}
                    data={bookedUsers}
                    headingText={`${numBooked} users booked into this class`}
                    instructorsLoading={bookedUsersLoading}
                />
                <View>
                    {!isClassCanceled && (
                        <ClassButtonsMessageText color={colors.homebodyTurquoise}>
                            {classButtonsMessage}
                        </ClassButtonsMessageText>
                    )}
                    <ClassButtonsContainer>
                        {!isClassCanceled ? (
                            <>
                                <CancelClassButton
                                    onPress={cancelClass}
                                    text="Cancel Class"
                                    iconProps={{ family: 'Feather', name: 'x', size: 34 }}
                                    color={colors.cancelClassRed}
                                />
                                <ShareClassButton
                                    onPress={shareClass}
                                    text="Share Class"
                                    iconProps={{ family: 'AntDesign', name: 'plus', size: 34 }}
                                    color={colors.homebodyTurquoise}
                                />
                                <LaunchClassButton
                                    onPress={launchClass}
                                    text="Launch Class"
                                    iconProps={{ family: 'Entypo', name: 'controller-play', size: 34 }}
                                    color={colors.homebodyGreen}
                                />
                            </>
                        ) : (
                            <Heading paddingTop={25} isBold color={colors.cancelledClassRed}>
                                {classButtonsMessage}
                            </Heading>
                        )}
                    </ClassButtonsContainer>
                </View>
                {cancelModalVisible && (
                    <CancelClassModal
                        actionLoading={actionLoading}
                        cancelClassStatus={cancelClassStatus}
                        visible={cancelModalVisible}
                        firstButtonOnPress={toggleCancelModalVisible}
                        secondButtonOnPress={handleCancelClass}
                    />
                )}
            </ContentContainer>
            <FooterBar />
        </ScreenContainer>
    )
}

const ClassButtonsMessageText = styled(ButtonText)`
    align-self: center;
    position: absolute;
    top: -40px;
`
const TraineeFlatList = styled(InstructorFlatList)`
    margin-top: 50px;
    margin-bottom: 75px;
`
const ClassButtonsContainer = styled(View)`
    flex-direction: row;
    width: 40%;
    align-self: center;
    align-items: center;
    justify-content: space-between;
`
const ClassButtonText = styled(Caption)``
const ButtonContainer = styled(View)`
    align-items: center;
`
const TouchableClassButton = styled(TouchableOpacity)`
    height: 72px;
    width: 72px;
    border-radius: 36px;
    justify-content: center;
    align-items: center;
    background-color: ${props => props?.color};
`
const CancelClassButton = styled(ClassButton)``
const ShareClassButton = styled(ClassButton)``
const LaunchClassButton = styled(ClassButton)``
