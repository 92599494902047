import React, { useState, useEffect } from 'react'
import MultiSlider from 'react-native-multi-slider'

import { View, StyleSheet } from 'react-native'
import { TimeSliderLabel } from 'typography'
import { formatHourAMPM } from 'helpers'

import styled from 'styled-components/native'
import colors from 'colors'
import { isWeb } from '@constants'
const TABLET_SLIDER_LENGTH = 600

interface Props {
    onValuesChangeFinish: () => void
    timeValues: [number, number]
    isTablet: boolean
    min?: number
    max?: number
}

const TimeSlider: React.FC<Props> = ({ onValuesChangeFinish, timeValues, min = 0, max = 24, isTablet = false }) => {
    const [values, setValues] = useState(timeValues)
    useEffect(() => {
        setValues(timeValues)
    }, [timeValues])
    const styles = timeSliderStyles({ isTablet })
    return (
        <Container>
            <TimeSliderText>{formatHourAMPM(values[0])}</TimeSliderText>
            <MultiSlider
                showSteps
                values={values}
                onValuesChange={setValues}
                onValuesChangeFinish={onValuesChangeFinish}
                min={min}
                max={max}
                sliderLength={isTablet ? TABLET_SLIDER_LENGTH : undefined}
                containerStyle={styles.containerStyle}
                trackStyle={styles.trackStyle}
                customMarker={CustomMarker}
                selectedStyle={styles.selectedStyle}
            />
            <TimeSliderText>{formatHourAMPM(values[1])}</TimeSliderText>
        </Container>
    )
}

const CustomMarker = () => (
    <MarkerContainer>
        <Marker />
    </MarkerContainer>
)

const TimeSliderText = styled(TimeSliderLabel)`
    padding-left: 10px;
    padding-right: 10px;
    ${({ theme }) => theme.isTablet && 'padding-top: 10px;'}
    ${({ theme }) => !theme.isMobile && 'font-size: 25px; margin-left: 25px; margin-right: 25px;'}
`

const Container = styled(View)`
    flex-direction: row;
    align-items: center;
    ${({ theme }) => theme.isTablet && 'justify-content: space-between; width: 100%; padding-vertical: 10px;'}
`

const MarkerContainer = styled(View)`
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background-color: ${colors.white};
    align-items: center;
    justify-content: center;
`

const Marker = styled(View)`
    height: ${({ theme }) => (theme.isMobile ? '12.6px' : '20px')};
    width: ${({ theme }) => (theme.isMobile ? '12.6px' : '20px')};
    border-radius: ${({ theme }) => (theme.isMobile ? '6.3px' : '12px')};
    background-color: ${colors.blueContinue};
    margin-bottom: -3px;
`

const timeSliderStyles = ({ isTablet }) =>
    StyleSheet.create({
        selectedStyle: {
            backgroundColor: colors.blueContinue,
        },
        trackStyle: {
            height: 3.36,
            backgroundColor: colors.grayContinue,
            borderRadius: 3,
        },
        containerStyle: {
            width: isTablet ? TABLET_SLIDER_LENGTH : '100%',
        },
    })

export default TimeSlider
