import React, { useState, useEffect } from 'react'
import { View, Text, TouchableOpacity, ActivityIndicator } from 'react-native'
import styled from 'styled-components/native'
import { StyledTextInput } from 'components/InputField'
import { FieldContainer, FieldRowContainer } from 'commons/containers'
import colors from 'colors'
import { pickFile, deleteFile } from 'actions'
import { FormHeaderLabel } from 'typography'
import { getCurrentUser } from 'apollo/selectors'
import { DELETE_FILE } from 'apollo/mutations'
import { useMutation } from 'apollo-augmented-hooks'
import UploadButton from 'components/UploadButton'
import { FILE_TYPES } from '@constants'
const { CERTIFICATION } = FILE_TYPES
const noop = () => {}
const emptyObj = {}
const emptyArr = []

export const Certifications = ({
    files = {
        items: emptyArr,
    },
    reactiveFormProps,
    certificationUploads,
    setCertificationUploads,
}) => {
    const { id } = getCurrentUser()
    const deleteFileMutation = useMutation(DELETE_FILE)
    const { isValid, getFieldError, populateErrors } = reactiveFormProps
    const [fieldError, setFieldError] = useState({})
    const { showError = false, error = '' } = fieldError

    const updateFieldError = () => setFieldError(getFieldError({ fieldName: 'files' }))
    const hideFieldError = () => setFieldError({ ...fieldError, showError: false })

    useEffect(() => {
        if (isValid) {
            updateFieldError()
        }
    }, [isValid])

    useEffect(() => {
        if (populateErrors) {
            updateFieldError()
        }
    }, [populateErrors])

    const onBlur = () => (populateErrors ? updateFieldError() : noop)

    const defaultCerts = [
        {
            name: '',
            existing: false,
            file: { key: '' },
            fileType: CERTIFICATION,
        },
    ]
    const existingCerts = files?.items
        ?.filter(file => file?.fileType == CERTIFICATION && !file?._deleted)
        ?.map(item => ({
            existing: true,
            name: item?.name,
            fileID: item?.id,
            file: { key: `trainerApplicationDocuments/${id}/${item?.name}` },
            fileType: item?.fileType,
            _version: item?._version,
        }))

    useEffect(() => {
        setCertificationUploads(existingCerts?.length ? existingCerts : defaultCerts)
    }, [files?.items])

    function handleRemoveFile(file) {
        deleteFile({ file, deleteFileMutation })
    }

    return (
        <FieldContainer style={{ flexDirection: 'column' }}>
            <FieldRowContainer>
                <FormHeaderLabel>What certifications do you have?</FormHeaderLabel>
                <AddButton
                    onPress={() => {
                        setCertificationUploads([...certificationUploads, { name: '', file: {}, existing: false }])
                    }}
                >
                    <AddText>+ Add</AddText>
                </AddButton>
            </FieldRowContainer>
            {certificationUploads?.map((item, index) => (
                <CertificationUpload
                    files={files}
                    item={item}
                    index={index}
                    key={item?.fileID ?? 'supressWarning'}
                    certificationUploads={certificationUploads}
                    setCertificationUploads={setCertificationUploads}
                    showError={showError}
                    error={error}
                    hideFieldError={hideFieldError}
                    updateFieldError={updateFieldError}
                    onBlur={onBlur}
                    deleteFile={handleRemoveFile}
                />
            ))}
        </FieldContainer>
    )
}

const CertificationUpload = ({
    index,
    certificationUploads,
    setCertificationUploads,
    item,
    error,
    showError,
    hideFieldError,
    updateFieldError,
    onBlur,
    deleteFile,
}) => {
    const { id } = getCurrentUser()
    const { existing } = item
    const [uploading, setUploading] = useState(false)
    const [successfulUpload, setSuccessfulUpload] = useState(existing)
    const [certificationTitle, setCertificationTitle] = useState(item.name || '')
    const [errorMessage, setErrorMessage] = useState('')
    async function handleFileUpload() {
        if (showError) {
            hideFieldError()
        }
        if (certificationTitle) {
            setErrorMessage('')
            const key = await pickFile({ fileName: certificationTitle, id, setUploading })
            const newCerts = certificationUploads?.map((item, i) =>
                i === index ? { name: certificationTitle, file: { key }, fileType: CERTIFICATION } : item,
            )
            setSuccessfulUpload(true)
            setCertificationUploads(newCerts)
            updateFieldError()
        } else {
            setErrorMessage('*name required')
        }
    }

    const clearErrorMessage = () => (showError ? hideFieldError() : errorMessage ? setErrorMessage('') : noop)

    return (
        <FieldRowContainer>
            <StyledTextInput
                editable={!successfulUpload}
                fieldTitle={`Certification ${index + 1}`}
                errorMessage={showError && error ? error : errorMessage}
                onChangeText={setCertificationTitle}
                placeholder="i.e. Physical Therapy"
                onFocus={clearErrorMessage}
                onBlur={onBlur}
                defaultValue={item.name}
            />
            <UploadButton
                successfulUpload={successfulUpload}
                onPress={item.existing ? () => deleteFile(item) : handleFileUpload}
                uploaded={item.existing}
            />
            {uploading && (
                <ActivityIndicator
                    animating={uploading}
                    size="large"
                    color={colors.blueContinue}
                    style={{ marginLeft: 10 }}
                />
            )}
        </FieldRowContainer>
    )
}

const AddText = styled(Text)`
    color: white;
    text-align: center;
`
const AddButton = styled(TouchableOpacity)`
    margin-horizontal: 10px;
    background-color: ${colors.blueContinue};
    height: 25px;
    width: 60px;
    margin-top: 10px;
    align-self: center;
    justify-content: center;
`

export default Certifications
