//------------------ Auth Screens ------------------
export const AUTH_NAVIGATOR = 'AUTH_NAVIGATOR'
export const LANDING_PAGE = 'LANDING_PAGE'
export const PRIVACY_PAGE = 'PRIVACY_PAGE'
export const TERMS_OF_SERVICE_PAGE = 'TERMS_OF_SERVICE_PAGE'
export const WAITLIST = 'WAITLIST'
export const SIGN_UP = 'SIGN_UP'
export const VERIFICATION = 'VERIFICATION'
export const LOGIN = 'LOGIN'
export const ONBOARDING = 'ONBOARDING'
export const SANDBOX = 'SANDBOX'
export const PRESS_KIT_PAGE = 'PRESS_KIT_PAGE'
export const SUPPORT = 'SUPPORT'

export const WEB_DRAWER = 'WEB_DRAWER'
export const FOR_INFLUENCERS = 'FOR_INFLUENCERS'
export const FOR_USERS = 'FOR_USERS'
export const SHOP = 'SHOP'
export const BLOG = 'BLOG'
export const GET_IN_TOUCH = 'GET_IN_TOUCH'

//------------------ Instructor Screens ------------------
export const INSTRUCTOR_NAVIGATOR = 'INSTRUCTOR_NAVIGATOR'
export const CREATE_CLASS_NAVIGATOR = 'CREATE_CLASS_NAVIGATOR'
export const DASHBOARD = 'DASHBOARD'
export const HOME = 'HOME'
export const UNPUBLISHED_CLASSES = 'UNPUBLISHED_CLASSES'
export const PUBLISHED_CLASSES = 'PUBLISHED_CLASSES'
export const PUBLISHED_CLASS_DETAILS = 'PUBLISHED_CLASS_DETAILS'
export const TRANSACTIONS = 'TRANSACTIONS'
export const NOTIFICATIONS = 'NOTIFICATIONS'
export const MY_ACCOUNT_NAVIGATOR = 'MY_ACCOUNT_NAVIGATOR'
export const INSTRUCTOR_ACCOUNT = 'INSTRUCTOR_ACCOUNT'
export const INSTRUCTOR_PROFILE = 'INSTRUCTOR_PROFILE'
export const INSTRUCTOR_SETTINGS_NAVIGATOR = 'INSTRUCTOR_SETTINGS_NAVIGATOR'
export const INSTRUCTOR_SETTINGS = 'INSTRUCTOR_SETTINGS'

export const PUBLISHED_CLASSES_NAVIGATOR = 'PUBLISHED_CLASSES_NAVIGATOR'

//------------------ Streaming Screens ------------------
export const STREAMING_NAVIGATOR = 'STREAMING_NAVIGATOR'
export const INSTRUCTOR_STREAM_ROOM = 'INSTRUCTOR_STREAM_ROOM'
export const STREAMING_HOME = 'STREAMING_HOME'

//------------------ Trainee Screens ------------------
export const BOTTOM_TAB = 'BOTTOM_TAB'
export const CLASS_DETAILS = 'CLASS_DETAILS'
export const BOOK_CLASS = 'BOOK_CLASS'
export const PROFILE = 'PROFILE'
export const TEST_FLIGHT_DOWNLOAD = 'TEST_FLIGHT_DOWNLOAD'
export const TRAINEE_NAVIGATOR = 'TRAINEE_NAVIGATOR'
export const TRAINEE_HOME_STACK_NAVIGATOR = 'TRAINEE_HOME_STACK_NAVIGATOR'
export const TRAINEE_HOME_SCREEN = 'TRAINEE_HOME_SCREEN'
export const TRAINEE_STREAM_ROOM = 'TRAINEE_STREAM_ROOM'
export const TRAINEE_LEAVE_REVIEW = 'TRAINEE_LEAVE_REVIEW'

export const INSTRUCTOR_CLASS_SCHEDULE = 'INSTRUCTOR_CLASS_SCHEDULE'

export const EXPLORE_NAVIGATOR = 'EXPLORE_NAVIGATOR'
export const EXPLORE = 'EXPLORE'
export const ALL_CLASSES = 'ALL_CLASSES'

export const FAVORITES_NAVIGATOR = 'FAVORITES_NAVIGATOR'
export const FAVORITES = 'FAVORITES'

export const PROFILE_NAVIGATOR = 'PROFILE_NAVIGATOR'
export const USER_PROFILE = 'USER_PROFILE'
export const CLASS_SCHEDULE = 'CLASS_SCHEDULE'
export const TRAINEE_PROFILE = 'TRAINEE_PROFILE'
export const TRAINEE_ACCOUNT = 'TRAINEE_ACCOUNT'
export const TRAINEE_SETTINGS = 'TRAINEE_SETTINGS'
export const CONTACT_US_FORM = 'CONTACT_US_FORM'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const TRANSACTION_HISTORY = 'TRANSACTION_HISTORY'
export const WALLET = 'WALLET'
export const ADD_CARD = 'ADD_CARD'
export const TRAINEE_UPCOMING_CLASSES = 'TRAINEE_UPCOMING_CLASSES'
export const TRAINEE_SUPPORT = 'TRAINEE_SUPPORT'
export const TRAINEE_NOTIFICATIONS = 'TRAINEE_NOTIFICATIONS'
export const TRAINEE_NOTIFICATION_DETAILS = 'TRAINEE_NOTIFICATION_DETAILS'
export const TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS'
export const PRIVACY_POLICY = 'PRIVACY_POLICY'
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION'

//------------------ Payment Screen Mockups ------------------
export const PAYMENT_NAVIGATOR = 'PAYMENT_NAVIGATOR'
export const MY_CREDITS = 'MY_CREDITS'
export const CREDIT_PACKAGES = 'CREDIT_PACKAGES'
export const PURCHASE_CREDIT = 'PURCHASE_CREDIT'

export const INSTRUCTOR_DRAWER_INDICES = {
    HOME: 0,
    CREATE_CLASS_NAVIGATOR: 1,
    PUBLISHED_CLASSES_NAVIGATOR: 2,
    UNPUBLISHED_CLASSES: 3,
    MY_ACCOUNT_NAVIGATOR: 4,
    DASHBOARD: 5,
    TRANSACTIONS: 6,
    NOTIFICATIONS: 7,
}

export const INSTRUCTOR_APPLICATION = {
    NAVIGATOR: 'Instructor Application',
    START_TRAINER_APP: 'Welcome!',
    PERSONAL_DETAILS: 'Personal details',
    SOCIALS: 'Socials',
    SPECIALIZATIONS: 'Specializations',
    CERTIFICATIONS: 'Certifications',
    GYM_STUDIO_DETAILS: 'Gym/Studio details',
    BUSINESS_REGISTRATION: 'Business Registration',
    REVIEW_AND_SUBMIT: 'Review & Submit',
    ADMIN_REVIEW: 'Admin Review',
}
const {
    START_TRAINER_APP,
    PERSONAL_DETAILS,
    SOCIALS,
    SPECIALIZATIONS,
    CERTIFICATIONS,
    GYM_STUDIO_DETAILS,
    BUSINESS_REGISTRATION,
    ADMIN_REVIEW,
} = INSTRUCTOR_APPLICATION
export const defaultInstructorApplicationPaths = [PERSONAL_DETAILS, SOCIALS]
export const instructorApplicationPaths = {
    influencer: defaultInstructorApplicationPaths,
    trainer: [SPECIALIZATIONS, CERTIFICATIONS],
    owner: [GYM_STUDIO_DETAILS, BUSINESS_REGISTRATION],
}

export const CREATE_CLASS = {
    DETAILS: 'Class Details',
    BANNER: 'Upload Banner',
    INTERVALS: 'Set Intervals',
    // PLAYLIST: 'Set Playlist',
    PRICE: 'Set Price',
    REVIEW: 'Review & Publish',
}
const { DETAILS, BANNER, INTERVALS, PLAYLIST, PRICE, REVIEW } = CREATE_CLASS
export const createClassPath = [DETAILS, BANNER, INTERVALS, PRICE, REVIEW]

const defaultLinking = {
    prefixes: [
        'https://dev.homebodylivefitness.com',
        'https://main--homebodylivefitness.netlify.app/',
        'locahost:19006/',
    ],
}

const instructorViewConfig = {
    [INSTRUCTOR_NAVIGATOR]: {
        path: 'instructor-app',
        screens: {
            [HOME]: 'home',
            [CREATE_CLASS_NAVIGATOR]: {
                screens: { ...CREATE_CLASS, path: 'create_class' },
            },
            [PUBLISHED_CLASSES_NAVIGATOR]: {
                path: 'published',
                screens: {
                    [PUBLISHED_CLASSES]: 'classes',
                    [PUBLISHED_CLASS_DETAILS]: 'class_details',
                    [INSTRUCTOR_STREAM_ROOM]: 'stream',
                },
            },
            [UNPUBLISHED_CLASSES]: 'unpublished_classes',
            [DASHBOARD]: 'dashboard',
            [MY_ACCOUNT_NAVIGATOR]: {
                path: 'account',
                screens: {
                    [INSTRUCTOR_ACCOUNT]: '',
                    [INSTRUCTOR_PROFILE]: 'edit',
                },
            },
            [TRANSACTIONS]: 'transactions',
            [INSTRUCTOR_SETTINGS_NAVIGATOR]: {
                path: 'settings',
                screens: {
                    [INSTRUCTOR_SETTINGS]: '',
                    [CHANGE_PASSWORD]: 'change_password',
                    [CONTACT_US_FORM]: 'contact',
                },
            },
            [CLASS_DETAILS]: 'classDetails',
            [BOOK_CLASS]: 'bookClass',
            [NOTIFICATIONS]: 'notifications',
        },
    },
}

const instructorApplicationConfig = {
    [INSTRUCTOR_APPLICATION.NAVIGATOR]: {
        path: 'apply',
        screens: {
            [START_TRAINER_APP]: '',
            [PERSONAL_DETAILS]: 'personal_details',
            [SOCIALS]: 'socials',
            [SPECIALIZATIONS]: 'specializations',
            [CERTIFICATIONS]: 'certifications',
            [GYM_STUDIO_DETAILS]: 'gym_studio_details',
            [BUSINESS_REGISTRATION]: 'business_registration',
            [REVIEW]: 'review',
            [ADMIN_REVIEW]: 'admin',
        },
    },
}

const bottomTabConfig = {
    [TRAINEE_NAVIGATOR]: {
        path: 'app',
        screens: {
            [TRAINEE_HOME_STACK_NAVIGATOR]: {
                path: 'home',
                screens: {
                    [TRAINEE_HOME_SCREEN]: '',
                    [CLASS_DETAILS]: 'details',
                    [BOOK_CLASS]: 'bookClass',
                    [CREDIT_PACKAGES]: 'package',
                    [WALLET]: 'wallet',
                    [ADD_CARD]: 'add_card',
                    [PURCHASE_CREDIT]: 'purchase',
                    [USER_PROFILE]: 'favorite_instructor',
                    [TRAINEE_STREAM_ROOM]: 'attend_class',
                    [TRAINEE_LEAVE_REVIEW]: 'review',
                },
            },
            [EXPLORE_NAVIGATOR]: {
                path: 'explore',
                screens: {
                    [EXPLORE]: '',
                    [ALL_CLASSES]: 'all',
                    [CLASS_DETAILS]: 'details',
                    [BOOK_CLASS]: 'bookClass',
                    [CREDIT_PACKAGES]: 'package',
                    [WALLET]: 'wallet',
                    [ADD_CARD]: 'add_card',
                    [PURCHASE_CREDIT]: 'purchase',
                    [USER_PROFILE]: 'instructor_profile',
                    [INSTRUCTOR_CLASS_SCHEDULE]: 'schedule',
                    [CLASS_SCHEDULE]: 'class_schedule',
                },
            },
            [FAVORITES_NAVIGATOR]: {
                path: 'favorites',
                screens: {
                    [FAVORITES]: '',
                    [USER_PROFILE]: 'favorite_profile',
                },
            },
            [PROFILE_NAVIGATOR]: {
                path: 'profile',
                screens: {
                    [TRAINEE_ACCOUNT]: '',
                    [TRAINEE_PROFILE]: 'my_account',
                    [TRAINEE_SETTINGS]: 'settings',
                    [MY_CREDITS]: 'credits',
                    [CREDIT_PACKAGES]: 'credits/packages',
                    [PURCHASE_CREDIT]: 'credits/purchase',
                    [WALLET]: 'settings/wallet',
                    [ADD_CARD]: 'settings/wallet/add_card',
                    [CHANGE_PASSWORD]: 'settings/change_password',
                    [TRANSACTION_HISTORY]: 'settings/transaction_history',
                    [TERMS_AND_CONDITIONS]: 'settings/terms_and_conditions',
                    [PRIVACY_POLICY]: 'settings/privacy_policy',
                    [CANCEL_SUBSCRIPTION]: 'settings/cancel_subscription',
                    [TRAINEE_SUPPORT]: 'settings/support_request',
                    [TRAINEE_UPCOMING_CLASSES]: 'upcoming_classes',
                    [TRAINEE_NOTIFICATIONS]: 'settings/notifications',
                    [TRAINEE_NOTIFICATION_DETAILS]: 'settings/notifications/details',
                    [CLASS_DETAILS]: 'settings/notifications/class_details',
                    [USER_PROFILE]: 'notification/instructor_profile',
                },
            },
        },
    },
}

const paymentConfig = {
    [PAYMENT_NAVIGATOR]: {
        path: 'payment',
        screens: {
            [MY_CREDITS]: '',
            [CREDIT_PACKAGES]: 'package',
            [PURCHASE_CREDIT]: 'purchase',
        },
    },
}
export const TraineeHeaderConfig = {
    //Auth
    [LOGIN]: { title: '', showBackButton: false, isLogoHeader: true },
    [SIGN_UP]: { title: '', showBackButton: false, isLogoHeader: true },
    [TEST_FLIGHT_DOWNLOAD]: { title: '', showBackButton: false, isLogoHeader: true },
    [VERIFICATION]: { title: '', showBackButton: true, isLogoHeader: true },
    [ONBOARDING]: { title: '', showBackButton: false, isLogoHeader: true },
    //Screens in multiple nested navigators
    [CLASS_DETAILS]: { title: 'Class Details', showBackButton: true },
    [BOOK_CLASS]: { title: 'Book Class', showBackButton: true },
    [USER_PROFILE]: { title: 'Instructor Profile', showBackButton: true },
    [CLASS_SCHEDULE]: { title: 'Class Schedule', showBackButton: true },
    //Logo Headers
    [TRAINEE_HOME_STACK_NAVIGATOR]: { title: '', showBackButton: false, isLogoHeader: true },
    [TRAINEE_HOME_SCREEN]: { title: '', showBackButton: false, isLogoHeader: true },
    [EXPLORE_NAVIGATOR]: { title: '', showBackButton: false, isLogoHeader: true },
    [EXPLORE]: { title: '', showBackButton: false, isLogoHeader: true },
    [ALL_CLASSES]: { title: 'All Classes', showBackButton: true },
    //Streaming
    [TRAINEE_STREAM_ROOM]: { title: 'My Waiting Room', showBackButton: true },
    [TRAINEE_LEAVE_REVIEW]: { title: 'Review Class', showBackButton: true },
    //Favorites Navigator
    [FAVORITES_NAVIGATOR]: { title: 'Favorites', showBackButton: false },
    [FAVORITES]: { title: 'Favorites', showBackButton: false },
    //Profile navigator
    [PROFILE_NAVIGATOR]: { title: 'My Account', showBackButton: false },
    [TRAINEE_ACCOUNT]: { title: 'My Account', showBackButton: false },
    [TRAINEE_PROFILE]: { title: 'My Account', showBackButton: true },
    [TRAINEE_SETTINGS]: { title: 'Settings', showBackButton: true },
    [CHANGE_PASSWORD]: { title: 'Reset Password', showBackButton: true },
    [TRANSACTION_HISTORY]: { title: 'Transactions', showBackButton: true },
    [TRAINEE_UPCOMING_CLASSES]: { title: 'Upcoming Classes', showBackButton: true },
    [TRAINEE_SUPPORT]: { title: 'Support Request', showBackButton: true },
    [TRAINEE_NOTIFICATIONS]: { title: 'Notifications', showBackButton: true },
    [TRAINEE_NOTIFICATION_DETAILS]: { title: 'Notifications', showBackButton: true },
    [TERMS_AND_CONDITIONS]: { title: 'Terms and Conditions', showBackButton: true },
    [PRIVACY_POLICY]: { title: 'Privacy Policy', showBackButton: true },
    [WALLET]: { title: 'My Wallet', showBackButton: true },
    [PAYMENT_NAVIGATOR]: { title: '', showBackButton: false },
    [MY_CREDITS]: { title: 'My Credits', showBackButton: true },
    [CREDIT_PACKAGES]: { title: 'Buy Credits', showBackButton: true },
    [PURCHASE_CREDIT]: { title: '', showBackButton: false },
    [ADD_CARD]: { title: 'Add Card', showBackButton: true },
    [DELETE_ACCOUNT]: { title: 'Account', showBackButton: true },
}

export const linkingConfig = isMobile => {
    return {
        ...defaultLinking,
        config: {
            screens: {
                [LANDING_PAGE]: '',
                [PRIVACY_PAGE]: 'privacy',
                [CLASS_DETAILS]: 'details',
                [SIGN_UP]: 'signup',
                [VERIFICATION]: 'verification',
                [LOGIN]: 'login',
                [ONBOARDING]: 'welcome',
                [PRESS_KIT_PAGE]: 'press',
                [SUPPORT]: 'support',
                [SANDBOX]: 'sandbox',
                [TEST_FLIGHT_DOWNLOAD]: 'download',
                ...instructorApplicationConfig,
                ...instructorViewConfig,
                ...bottomTabConfig,
                ...paymentConfig,
            },
        },
    }
}
