import React from 'react'
import { TouchableOpacity } from 'react-native'
import VectorIcon from './VectorIcon'
import styled from 'styled-components/native'

export default function InstaButton({ onPress, disabled, size = 30 }) {
    return (
        <TouchableInstaIcon onPress={onPress} disabled={disabled}>
            <VectorIcon family="AntDesign" name="instagram" size={size} />
        </TouchableInstaIcon>
    )
}

const TouchableInstaIcon = styled(TouchableOpacity)`
    align-self: center;
    justify-content: center;
    align-items: center;
`
