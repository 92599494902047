import React, { useState } from 'react'
import { View, Text } from 'react-native'
// ui
import colors from 'colors'
import styled from 'styled-components/native'
import { useDimensions } from 'dimensions'
// components
import { StandardContainer } from 'commons/containers'

function Dashboard({ navigation, route }) {
    return (
        <StandardContainer>
            <Text>Dashboard</Text>
        </StandardContainer>
    )
}
export default Dashboard
