import _ from 'lodash'

export function getCurrentMinutes() {
    return new Date().getMinutes()
}

function getCurrentHour({ shouldAddPrefix = false } = {}) {
    const currentHour = new Date().getHours()
    if (currentHour < 10 && shouldAddPrefix) {
        return '0' + parseInt(currentHour)
    } else {
        return currentHour
    }
}

function createTimePickerItems({ numMinuteIntervals = 6, isTimeFromNow = false }) {
    const currentHour = isTimeFromNow ? getCurrentHour({ shouldAddPrefix: false }) : 0
    const currentMinutes = getCurrentMinutes()
    const interval = 60 / numMinuteIntervals
    const firstValidInterval = (Math.floor(currentMinutes / interval) + 2) * interval
    const minutesUntilNextInterval = firstValidInterval % currentMinutes
    const currentHourInterval =
        currentMinutes < firstValidInterval ? firstValidInterval : currentMinutes + minutesUntilNextInterval

    let times = []
    let currentHourItemsCreated = !isTimeFromNow

    for (let hour = currentHour; hour < 24; hour++) {
        const leadingZeroHour = `${hour < 10 ? '0' : ''}${hour}`
        const standardHour = `${hour > 12 ? hour % 12 : hour}`
        const label = `${!hour ? '12' : standardHour}:00 ${hour < 12 ? 'AM' : 'PM'}`
        const value = `${leadingZeroHour}:00:00`
        if (currentHourItemsCreated) {
            times = [...times, { label, value }]
        }

        for (
            let minutes = !currentHourItemsCreated ? currentHourInterval : interval;
            minutes < 60;
            minutes += interval
        ) {
            const formattedMinutes = `${minutes < 10 ? '0' : ''}${minutes}`
            const label = `${!hour ? '12' : standardHour}:${formattedMinutes} ${hour < 12 ? 'AM' : 'PM'}`
            const value = `${leadingZeroHour}:${formattedMinutes}:00`
            times = [...times, { label, value }]
        }
        currentHourItemsCreated = true
    }
    return times
}

export const timePickerItems = createTimePickerItems({ numMinuteIntervals: 6 })
export const validTimePickerItems = createTimePickerItems({ numMinuteIntervals: 6, isTimeFromNow: true })

export const dateFilterPickerItems = [
    {
        label: 'All Classes',
        value: 'All Classes',
    },
    {
        label: 'Today',
        value: 'Today',
    },
    {
        label: 'This Week',
        value: 'This Week',
    },
    {
        label: 'This Month',
        value: 'This Month',
    },
]

export const specializationPickerItems = [
    {
        label: 'Advanced',
        value: 'advanced',
    },
    {
        label: 'Arms',
        value: 'arms',
    },
    {
        label: 'Athlete Training',
        value: 'athleteTraining',
    },
    {
        label: 'Barre',
        value: 'barre',
    },
    {
        label: 'Beginner',
        value: 'beginner',
    },
    {
        label: 'Body Building',
        value: 'bodyBuilding',
    },
    {
        label: 'Body Weight',
        value: 'bodyWeight',
    },
    {
        label: 'Bound',
        value: 'bound',
    },
    {
        label: 'Boxing',
        value: 'boxing',
    },
    {
        label: 'Breath Work',
        value: 'breathWork',
    },
    {
        label: 'Cardio',
        value: 'cardio',
    },
    {
        label: 'Celebrity',
        value: 'celebrity',
    },
    {
        label: 'Circuit Training',
        value: 'circuitTraining',
    },
    {
        label: 'Core',
        value: 'core',
    },
    {
        label: 'Crossfit',
        value: 'crossfit',
    },
    {
        label: 'Cycling',
        value: 'cycling',
    },
    {
        label: 'Dance',
        value: 'dance',
    },
    {
        label: 'Endurance',
        value: 'endurance',
    },
    {
        label: 'Flexibilty',
        value: 'flexibility',
    },
    {
        label: 'Flow',
        value: 'flow',
    },
    {
        label: 'Foam Roll',
        value: 'foamRoll',
    },
    {
        label: 'Functional Training',
        value: 'functionalTraining',
    },
    {
        label: 'Glutes',
        value: 'glutes',
    },
    {
        label: 'HIIT',
        value: 'HIIT',
    },
    {
        label: 'Jump',
        value: 'jump',
    },
    {
        label: 'Kettle Bell',
        value: 'kettleBell',
    },
    {
        label: 'Kick Boxing',
        value: 'kickBoxing',
    },
    {
        label: 'Legs',
        value: 'legs',
    },
    {
        label: 'Meditation',
        value: 'meditation',
    },
    {
        label: 'Mobility',
        value: 'mobility',
    },
    {
        label: 'Pilates',
        value: 'pilates',
    },
    {
        label: 'Performance Training',
        value: 'performanceTraining',
    },
    {
        label: 'Plus Size',
        value: 'plusSize',
    },
    {
        label: 'Power',
        value: 'power',
    },
    {
        label: 'Pregnancy',
        value: 'pregnancy',
    },
    {
        label: 'Recovery',
        value: 'recovery',
    },
    {
        label: 'Restorative',
        value: 'restorative',
    },
    {
        label: 'Run',
        value: 'run',
    },
    {
        label: 'Sculpt',
        value: 'sculpt',
    },
    {
        label: 'Spiritual Wellness',
        value: 'spiritualWellness',
    },
    {
        label: 'Stability',
        value: 'stability',
    },
    {
        label: 'Strength',
        value: 'strength',
    },
    {
        label: 'Strength & Conditioning',
        value: 'strengthAndConditioning',
    },
    {
        label: 'Stretch',
        value: 'stretch',
    },
    {
        label: 'Tabata',
        value: 'tabata',
    },
    {
        label: 'Weight Loss',
        value: 'weightLoss',
    },
    {
        label: 'Wellness',
        value: 'wellness',
    },
    {
        label: 'Yoga',
        value: 'yoga',
    },
    {
        label: 'Yoga Sculpt',
        value: 'yogaSculpt',
    },
    {
        label: 'Zumba',
        value: 'zumba',
    },
]
export const classCategoryPickerItems = [
    {
        label: 'All Classes',
        value: 'showAllClasses',
    },
    ...specializationPickerItems,
]

export const instructorClassCategoryPickerItems = [
    {
        label: 'All Specializations',
        value: 'showAllSpecializations',
    },
    ...specializationPickerItems,
]

export const playlistPickerItems = [
    {
        label: 'Hip hop',
        value: 'https://open.spotify.com/playlist/37i9dQZF1DX0XUsuxWHRQd?si=aa27eee36476497e',
    },
    {
        label: 'Top hits',
        value: 'https://open.spotify.com/playlist/37i9dQZF1DXcBWIGoYBM5M?si=eb456589cf654821',
    },
    {
        label: 'Rock',
        value: 'https://open.spotify.com/playlist/37i9dQZF1DWXRqgorJj26U?si=9dedc6e3d019437b',
    },
    {
        label: 'Provide a playlist',
        value: 'Provide a playlist',
    },
]

export const socialPickerItems = [
    {
        name: 'twitter',
    },
    {
        name: 'facebook',
    },
    {
        name: 'snapchat',
    },
    {
        name: 'linkedin',
    },
]
export const statePickerItems = [
    {
        label: 'Alabama',
        value: 'Alabama',
        abbreviation: 'AL',
    },
    {
        label: 'Alaska',
        value: 'Alaska',
        abbreviation: 'AK',
    },
    {
        label: 'American Samoa',
        value: 'American Samoa',
        abbreviation: 'AS',
    },
    {
        label: 'Arizona',
        value: 'Arizona',
        abbreviation: 'AZ',
    },
    {
        label: 'Arkansas',
        value: 'Arkansas',
        abbreviation: 'AR',
    },
    {
        label: 'California',
        value: 'California',
        abbreviation: 'CA',
    },
    {
        label: 'Colorado',
        value: 'Colorado',
        abbreviation: 'CO',
    },
    {
        label: 'Connecticut',
        value: 'Connecticut',
        abbreviation: 'CT',
    },
    {
        label: 'Delaware',
        value: 'Delaware',
        abbreviation: 'DE',
    },
    {
        label: 'District Of Columbia',
        value: 'District Of Columbia',
        abbreviation: 'DC',
    },
    {
        label: 'Florida',
        value: 'Florida',
        abbreviation: 'FL',
    },
    {
        label: 'Georgia',
        value: 'Georgia',
        abbreviation: 'GA',
    },
    {
        label: 'Guam',
        value: 'Guam',
        abbreviation: 'GU',
    },
    {
        label: 'Hawaii',
        value: 'Hawaii',
        abbreviation: 'HI',
    },
    {
        label: 'Idaho',
        value: 'Idaho',
        abbreviation: 'ID',
    },
    {
        label: 'Illinois',
        value: 'Illinois',
        abbreviation: 'IL',
    },
    {
        label: 'Indiana',
        value: 'Indiana',
        abbreviation: 'IN',
    },
    {
        label: 'Iowa',
        value: 'Iowa',
        abbreviation: 'IA',
    },
    {
        label: 'Kansas',
        value: 'Kansas',
        abbreviation: 'KS',
    },
    {
        label: 'Kentucky',
        value: 'Kentucky',
        abbreviation: 'KY',
    },
    {
        label: 'Louisiana',
        value: 'Louisiana',
        abbreviation: 'LA',
    },
    {
        label: 'Maine',
        value: 'Maine',
        abbreviation: 'ME',
    },
    {
        label: 'Marshall Islands',
        value: 'Marshall Islands',
        abbreviation: 'MH',
    },
    {
        label: 'Maryland',
        value: 'Maryland',
        abbreviation: 'MD',
    },
    {
        label: 'Massachusetts',
        value: 'Massachusetts',
        abbreviation: 'MA',
    },
    {
        label: 'Michigan',
        value: 'Michigan',
        abbreviation: 'MI',
    },
    {
        label: 'Minnesota',
        value: 'Minnesota',
        abbreviation: 'MN',
    },
    {
        label: 'Mississippi',
        value: 'Mississippi',
        abbreviation: 'MS',
    },
    {
        label: 'Missouri',
        value: 'Missouri',
        abbreviation: 'MO',
    },
    {
        label: 'Montana',
        value: 'Montana',
        abbreviation: 'MT',
    },
    {
        label: 'Nebraska',
        value: 'Nebraska',
        abbreviation: 'NE',
    },
    {
        label: 'Nevada',
        value: 'Nevada',
        abbreviation: 'NV',
    },
    {
        label: 'New Hampshire',
        value: 'New Hampshire',
        abbreviation: 'NH',
    },
    {
        label: 'New Jersey',
        value: 'New Jersey',
        abbreviation: 'NJ',
    },
    {
        label: 'New Mexico',
        value: 'New Mexico',
        abbreviation: 'NM',
    },
    {
        label: 'New York',
        value: 'New York',
        abbreviation: 'NY',
    },
    {
        label: 'North Carolina',
        value: 'North Carolina',
        abbreviation: 'NC',
    },
    {
        label: 'North Dakota',
        value: 'North Dakota',
        abbreviation: 'ND',
    },
    {
        label: 'Northern Mariana Islands',
        value: 'Northern Mariana Islands',
        abbreviation: 'MP',
    },
    {
        label: 'Ohio',
        value: 'Ohio',
        abbreviation: 'OH',
    },
    {
        label: 'Oklahoma',
        value: 'Oklahoma',
        abbreviation: 'OK',
    },
    {
        label: 'Oregon',
        value: 'Oregon',
        abbreviation: 'OR',
    },
    {
        label: 'Palau',
        value: 'Palau',
        abbreviation: 'PW',
    },
    {
        label: 'Pennsylvania',
        value: 'Pennsylvania',
        abbreviation: 'PA',
    },
    {
        label: 'Puerto Rico',
        value: 'Puerto Rico',
        abbreviation: 'PR',
    },
    {
        label: 'Rhode Island',
        value: 'Rhode Island',
        abbreviation: 'RI',
    },
    {
        label: 'South Carolina',
        value: 'South Carolina',
        abbreviation: 'SC',
    },
    {
        label: 'South Dakota',
        value: 'South Dakota',
        abbreviation: 'SD',
    },
    {
        label: 'Tennessee',
        value: 'Tennessee',
        abbreviation: 'TN',
    },
    {
        label: 'Texas',
        value: 'Texas',
        abbreviation: 'TX',
    },
    {
        label: 'Utah',
        value: 'Utah',
        abbreviation: 'UT',
    },
    {
        label: 'Vermont',
        value: 'Vermont',
        abbreviation: 'VT',
    },
    {
        label: 'Virgin Islands',
        value: 'Virgin Islands',
        abbreviation: 'VI',
    },
    {
        label: 'Virginia',
        value: 'Virginia',
        abbreviation: 'VA',
    },
    {
        label: 'Washington',
        value: 'Washington',
        abbreviation: 'WA',
    },
    {
        label: 'West Virginia',
        value: 'West Virginia',
        abbreviation: 'WV',
    },
    {
        label: 'Wisconsin',
        value: 'Wisconsin',
        abbreviation: 'WI',
    },
    {
        label: 'Wyoming',
        value: 'Wyoming',
        abbreviation: 'WY',
    },
]
