import React from 'react'
import { View, ActivityIndicator } from 'react-native'
import { isWeb } from '@constants'
import colors from 'colors'
import styled from 'styled-components/native'
import { HeaderText } from 'typography'
export const BeRightBack = ({ style, reactiveStreamProps }) => {
    const { streamState } = reactiveStreamProps
    const { streamStatusMessage } = streamState

    return (
        <BeRightBackContainer style={style}>
            {!!!streamStatusMessage ? (
                <ActivityIndicator animating={true} size={isWeb ? 100 : 'large'} color="white" />
            ) : (
                <HeaderText isBold textAlign="center" color="white">
                    {streamStatusMessage}
                </HeaderText>
            )}
        </BeRightBackContainer>
    )
}
const BeRightBackContainer = styled(View)`
    justify-content: center;
    align-items: center;
    background-color: ${colors.homebodyTurquoise};
`

export default BeRightBack
