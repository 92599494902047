import React, { useRef, useState, useEffect } from 'react'
import { useNavigation, useRoute } from '@react-navigation/core'
import { View, ActivityIndicator } from 'react-native'
import { Inter900, Inter500, HeaderText, SmallText, SubHeaderText } from 'styles/typography'
import { BannerContainer, ScreenContainer, ContentContainer } from 'commons/containers'
import { ContinueButton } from 'commons/components/ContinueButton'
import CustomImage from 'commons/components/CustomImage'

import colors from 'styles/colors'
import styled from 'styled-components/native'
import { useDimensions } from 'dimensions'
import useDefaultBanners from 'commons/hooks/useDefaultBanners'

import { isNative } from '@constants'
import { MY_CREDITS, WALLET, TRAINEE_ACCOUNT } from 'screenNames'

import { stripeAPI } from 'src/actions'
import { STRIPE_API_ROUTES } from '@constants'
const { LIST_PRODUCTS, LIST_PRICES } = STRIPE_API_ROUTES
import { getPriceString } from 'helpers'
import { getCurrentUser } from 'apollo/selectors'
import * as queries from 'apollo/queries'
import { useApolloClient } from '@apollo/client'
import { TraineeWebBannerHeader } from 'commons/components/TraineeWebBannerHeader'
import { FlatList } from 'react-native-gesture-handler'
import { FooterBar } from 'commons/components/FooterBar'
const emptyObj = {}
const noop = () => {}

const CreditInfo = ({ hasSufficientCredits }) => {
    const infoText = hasSufficientCredits
        ? 'Please select a credit plan below to continue.'
        : "You don't have enough credits."

    return (
        <InfoContainer>
            {!hasSufficientCredits && (
                <SubHeaderText isMostBold isLargeSubheader>
                    {infoText}
                </SubHeaderText>
            )}
            <DetailsContainer>
                <InfoText>Please select a credit plan below to continue.</InfoText>
                <InfoText>
                    Learn about our credit system&nbsp;
                    <InfoText color={colors.homebodyTurquoise} onPress={() => {}}>
                        here.&nbsp;
                    </InfoText>
                    {!hasSufficientCredits && 'You can cancel at any time.'}
                </InfoText>
            </DetailsContainer>
        </InfoContainer>
    )
}

const OptionListItem = ({ item, prices, onPress }) => {
    const credits = item?.metadata?.credits
    const price = prices?.get(item.id)?.unit_amount
    const recurring = prices?.get(item.id)?.type !== 'one_time'
    const interval = prices?.get(item.id)?.recurring?.interval
    const savings = ((1 - price / 100 / credits) * 100)?.toFixed()

    return (
        <ListItemContainer>
            <OptionButton
                nonHover
                color={colors.grayContinue}
                text={`${credits} credits for ${getPriceString(price)}${recurring ? `/${interval}` : ''}`}
                onPress={() => onPress(prices?.get(item.id)?.id)}
            />
            <SavingsText isBold style={{ marginVertical: 9 }}>
                {savings && savings != 0 && `save ${savings}%`}
            </SavingsText>
        </ListItemContainer>
    )
}

const LoadingItem = () => {
    return <OptionButton color={colors.grayContinue} actionLoading={true} />
}

export default function CreditPackageForm() {
    const client = useApolloClient()
    const navigation = useNavigation()
    const { params } = useRoute() || emptyObj
    const { isMobile } = useDimensions()
    const defaultBanners = useDefaultBanners()
    const [classFromID, setClassFromID] = useState({})
    const [products, setProducts] = useState([])
    const [prices, setPrices] = useState(new Map())
    const [loading, setLoading] = useState(true)

    const { hasSufficientCredits = true, isProfile = false, classID } = params || emptyObj
    const { credits } = getCurrentUser()

    const titleText = hasSufficientCredits ? 'Buy & Save!' : 'Oops!'

    const goToWallet = id => navigation.navigate(WALLET, { priceID: id, classID, isProfile })

    const goToCredits = () => navigation.navigate(MY_CREDITS)

    useEffect(() => {
        async function populateProducts() {
            const products = await stripeAPI({ path: LIST_PRODUCTS })
            setProducts(products?.filter(item => item.active)?.reverse())
        }
        async function populatePrices() {
            const prices = await stripeAPI({ path: LIST_PRICES })
            const priceMap = new Map(
                prices?.map(obj => [obj?.product, obj]),
                [prices],
            )
            setPrices(priceMap)
        }
        populateProducts()
        populatePrices()
    }, [])

    useEffect(() => {
        const getClass = async () => {
            const {
                data: { getClass: classData },
            } = await client.query({
                query: queries.GET_CLASS,
                variables: {
                    id: `${classID}`,
                },
            })

            setClassFromID({ ...classData })
        }
        classID && getClass()
    }, [classID])

    const { banner, defaultBannerIndex } = classFromID || emptyObj

    useEffect(() => {
        products?.length !== 0 && prices?.size !== 0 && setLoading(false)
    }, [products, prices])

    const renderItem = ({ item }) => {
        return <OptionListItem item={item} prices={prices} onPress={goToWallet} />
    }
    const renderLoading = () => {
        return <LoadingItem />
    }
    const listEmptyComponent = loading ? renderLoading : noop

    const imageStyle = { height: 250, width: '100%' }
    return (
        <ScreenContainer>
            {classID ? (
                <BannerContainer>
                    <CustomImage
                        resizeMode="cover"
                        imgKey={banner?.key}
                        defaultBanner
                        defaultImage={defaultBanners && defaultBanners[defaultBannerIndex]}
                        style={imageStyle}
                    />
                </BannerContainer>
            ) : (
                <TraineeWebBannerHeader title={'My Credits'} />
            )}
            <ContentContainer isFluid>
                <TopInfoContainer>
                    <TitleText isMostBold>{titleText}</TitleText>
                    <CreditInfo hasSufficientCredits={hasSufficientCredits} />
                    {!hasSufficientCredits && (
                        <CreditsContainer>
                            <SubHeaderText isMostBold isLargeSubheader>
                                Available Credits:&nbsp;
                                <SubHeaderText isMostBold isLargeSubheader color={colors.homebodyTurquoise}>
                                    {credits ?? 0}
                                </SubHeaderText>
                            </SubHeaderText>
                        </CreditsContainer>
                    )}
                </TopInfoContainer>
                <OptionButtonContainer>
                    <OptionButtonList
                        data={products}
                        numColumns={2}
                        renderItem={renderItem}
                        ListEmptyComponent={listEmptyComponent}
                    />
                    {!loading && hasSufficientCredits && (
                        <OptionButton
                            nonHover
                            color={colors.homebodyTurquoise}
                            text={'Check Credit Balance'}
                            onPress={goToCredits}
                            style={{ width: 250, alignSelf: 'flex-start', marginVertical: '5%' }}
                        />
                    )}
                </OptionButtonContainer>
            </ContentContainer>
            <FooterBar />
        </ScreenContainer>
    )
}

const TitleText = styled(HeaderText)`
    margin-top: 100px;
    text-align: flex-start;
    align-self: flex-start;
    width: 100%;
`
const InfoContainer = styled(View)`
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    margin-bottom: 5%;
`
const DetailsContainer = styled(View)`
    width: 100%;
    flex-direction: column
    justify-content: flex-start;
    margin-top: 5%;
`
const InfoText = styled(SmallText)`
    font-size: 25px;
    line-height: 30px;
    text-align: flex-start;
    color: ${props => props.color || colors.black};
`
const CreditsContainer = styled(View)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 5%;
    margin-bottom: 5%;
`
const TopInfoContainer = styled(View)`
    align-self: flex-start;
    flex-direction: column;
`
const OptionButtonContainer = styled(View)`
    align-self: flex-start;
    flex-direction: column;
    margin-top: 50px;
`
const OptionButtonList = styled(FlatList)``
const ListItemContainer = styled(View)`
    flex-direction: column;
`
const OptionButton = styled(ContinueButton)`
    width: 400px;
    margin-right: 32px;
`
const SavingsText = styled(SmallText)`
    text-align: center;
    justify-content: center;
    color: ${colors.homebodyTurquoise};
`
