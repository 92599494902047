import React, { useState, useEffect } from 'react'
import { useNavigation } from '@react-navigation/core'
import { Image, StyleSheet } from 'react-native'
import { RowContainer } from 'commons/containers'
import { Heading } from 'typography'
import styled from 'styled-components/native'
import * as screenNames from 'screenNames'
import colors from 'colors'
import BackButton from 'components/BackButton'
import { isNative, isWeb, HEADER_HEIGHT } from '@constants'
import { agoraStreamState } from 'apollo/cache'
import { useReactiveVar } from '@apollo/client'

const HOMEBODY_LOGO = require('assets/logo.png')
const headerConfig = screenNames.TraineeHeaderConfig

export const TraineeLogoHeader = () => {
    return (
        <TraineeNativeHeaderContainer>
            <HomebodyLogo source={HOMEBODY_LOGO} />
        </TraineeNativeHeaderContainer>
    )
}

export const TraineeNativeHeader = ({ routeName, focusedRouteName }) => {
    const navigation = useNavigation()
    const streamState = useReactiveVar(agoraStreamState)
    const { streamRoomJoined: isTraineeInStream } = streamState
    const {
        title = '',
        showBackButton = false,
        isLogoHeader = false,
        hideHeader = false,
    } = headerConfig?.[focusedRouteName || routeName] || {}

    const isHiddenHeader = !title

    const goBackInStack = () => navigation.pop()

    if (hideHeader || isTraineeInStream) return null
    if (isLogoHeader) {
        return <TraineeLogoHeader />
    } else {
        return (
            <TraineeNativeHeaderContainer isHiddenHeader={isHiddenHeader}>
                {showBackButton && isNative && (
                    <BackButton
                        onPress={goBackInStack}
                        size={28}
                        containerStyle={{
                            position: 'absolute',
                            left: '10%',
                            alignSelf: 'center',
                            justifyContent: 'center',
                        }}
                    />
                )}
                <ScreenTitleText isMostBold color={colors.homebodyTurquoise}>
                    {title}
                </ScreenTitleText>
            </TraineeNativeHeaderContainer>
        )
    }
}

const HomebodyLogo = styled(Image)`
    max-width: 300px;
    width: ${({ theme }) => (theme.isMobile ? '100%' : '50%')};
    height: 35px;
`

const TraineeNativeHeaderContainer = styled(RowContainer)`
    height: ${HEADER_HEIGHT}px;
    background-color: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    width: 100%;
    align-self: center;
    justify-content: center;
    align-items: center;
`

const ScreenTitleText = styled(Heading)`
    align-self: center;
`
export default TraineeNativeHeader
