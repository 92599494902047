import React, { useState, useEffect } from 'react'
import { Image, View } from 'react-native'
import { InputField, InputFieldTitleText } from 'components/InputField'
import { FieldContainer, FieldRowContainer } from 'src/commons/containers'
import { useReactiveVar } from '@apollo/client'
import { createClassForm } from 'apollo/cache'
import { createClassFieldNames as fieldNames } from 'formFieldInfo'
import { isPlaylistDefault, parsePlaylistID, getSpotifyPlaylistImage } from 'helpers'
import { playlistPickerItems } from 'pickerItems'
import styled from 'styled-components/native'
import DropDownPicker from 'components/DropDownInput'

export const Playlist = ({ reactiveFormProps }) => {
    const form = useReactiveVar(createClassForm)
    const { playlist } = form || {}
    const { updateForm } = reactiveFormProps
    const isDefaultPlaylist = isPlaylistDefault(playlist)
    const [showPlaylist, setShowPlaylist] = useState(!isDefaultPlaylist)
    const [playlistID, setPlaylistID] = useState(parsePlaylistID(playlist))
    const [playlistImageURL, setPlaylistImageURL] = useState(playlistID)
    const toggleShowPlaylist = () => setShowPlaylist(!showPlaylist)

    useEffect(() => {
        if (isPlaylistDefault(playlist) && showPlaylist) {
            toggleShowPlaylist()
        }
        if (playlist == 'Provide a playlist' && !showPlaylist) {
            updateForm({ fieldName: fieldNames.playlist, value: '' })
            toggleShowPlaylist()
        }
        setPlaylistID(parsePlaylistID(playlist))
    }, [playlist])

    useEffect(() => {
        const updateImage = async () => {
            const url = await getSpotifyPlaylistImage(playlistID)
            setPlaylistImageURL(url)
        }
        updateImage()
    }, [playlistID])

    return (
        <>
            <PlaylistArtContainer showPlaylist={showPlaylist} playlistImageURL={playlistImageURL}>
                <DropDownPicker
                    reactiveForm={true}
                    fieldTitle="Select a Playlist"
                    placeholder="Select a playlist"
                    largeTitle={true}
                    selected={playlist}
                    shouldPushContent={false}
                    containerStyle={{ zIndex: 10 }}
                    shouldResetSelected={false}
                    fieldName={fieldNames.playlist}
                    dropDownItems={playlistPickerItems}
                    {...reactiveFormProps}
                />
                {playlistImageURL && (
                    <AlbumArtContainer>
                        <InputFieldTitleText largeTitle>Album Art</InputFieldTitleText>
                        <AlbumArt source={{ uri: playlistImageURL }} />
                    </AlbumArtContainer>
                )}
            </PlaylistArtContainer>

            <FieldContainer>
                {showPlaylist && (
                    <InputField
                        reactiveForm={true}
                        largeTitle={true}
                        fieldTitle="Playlist link"
                        fieldName={fieldNames.playlist}
                        defaultValue={playlist ?? ''}
                        {...reactiveFormProps}
                    />
                )}
            </FieldContainer>
        </>
    )
}
const AlbumArt = styled(Image)`
    width: 250px;
    height: 250px;
`
const AlbumArtContainer = styled(View)`
    align-items: center;
    margin-left: 200px;
`
const PlaylistArtContainer = styled(FieldRowContainer)`
    justify-content: space-between;
    max-width: ${({ showPlaylist, playlistImageURL }) => (showPlaylist && !playlistImageURL ? '450px' : '900px')};
`
export default Playlist
