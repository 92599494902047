import React, { useState, useEffect } from 'react'
import { InputField } from 'components/InputField'
import DropDownPicker from 'components/DropDownInput'
import { FieldContainer } from 'src/commons/containers'
import { specializationPickerItems, timePickerItems, validTimePickerItems } from 'pickerItems'
import { toStandardTime, isDateToday, getSelectedClassCategories } from 'helpers'
import { useReactiveVar } from '@apollo/client'
import { createClassForm } from 'apollo/cache'
import { createClassFieldNames as fieldNames } from 'formFieldInfo'
import CalendarModal from 'components/instructor/CalendarModal'

export const Details = ({ reactiveFormProps }) => {
    const form = useReactiveVar(createClassForm)
    const { updateForm, loading } = reactiveFormProps
    const { name, description, date: storedDate, startTime, duration, category, equipment } = form || {}
    const [date, setDate] = useState(storedDate ? { dateString: storedDate } : {})
    const [validTimes, setValidTimes] = useState(isToday ? validTimePickerItems : timePickerItems)
    const [calendarVisible, setCalendarVisible] = useState(false)
    const [invalidStartTime, setInvalidStartTime] = useState(false)
    const isToday = date?.dateString ? isDateToday(date?.dateString) : false
    const [selectedKeys, setSelectedKeys] = useState(getSelectedClassCategories({ classCategories: category }))

    function validateStartTime() {
        const standardStartTime = startTime ? toStandardTime(startTime).toUpperCase() : undefined
        if (validTimePickerItems.filter(item => item.label === standardStartTime).length == 0) {
            setInvalidStartTime(true)
            updateForm({ fieldName: 'startTime', value: '' })
        }
    }
    function handleItemSelection({ value }) {
        if (value.length < selectedKeys.length) {
            const keyRemoved = selectedKeys.filter(key => !value.includes(key))
            let newValue = false
            updateForm({
                fieldName: 'category',
                nestedField: { [fieldNames.category[keyRemoved[0]]]: newValue },
            })
            setSelectedKeys(value)
        } else {
            const keyAdded = value?.[value.length - 1]
            const currentValue = form?.category?.[keyAdded]

            if (keyAdded) {
                updateForm({
                    fieldName: 'category',
                    nestedField: {
                        [fieldNames.category?.[keyAdded]]: currentValue === null ? true : !currentValue,
                    },
                })
            }
            setSelectedKeys(value)
        }
    }

    useEffect(() => {
        if (!date?.dateString || !isToday) {
            setValidTimes(timePickerItems)
        } else {
            validateStartTime()
            setValidTimes(validTimePickerItems)
        }
    }, [date])

    useEffect(() => {
        const categoryFilterString = selectedKeys?.join()
        updateForm({
            fieldName: fieldNames.categoryFilter,
            value: categoryFilterString,
        })
    }, [selectedKeys])

    return (
        <FieldContainer style={{ zIndex: 10 }}>
            <InputField
                reactiveForm={true}
                largeTitle={true}
                fieldTitle="Class name"
                fieldName={fieldNames.name}
                defaultValue={name ?? ''}
                {...reactiveFormProps}
            />
            <InputField
                style={{ height: 200, padding: 20, width: '200%' }}
                multiline={true}
                reactiveForm={true}
                largeTitle={true}
                fieldTitle="Description"
                fieldName={fieldNames.description}
                defaultValue={description ?? ''}
                placeholder="Please give a short description of your class..."
                {...reactiveFormProps}
            />
            <CalendarModal
                date={date}
                ariaHideApp={false}
                setDate={setDate}
                visible={calendarVisible}
                setVisible={setCalendarVisible}
                reactiveFormProps={reactiveFormProps}
            />
            <DropDownPicker
                alignSelf="left"
                reactiveForm={true}
                fieldTitle="Start Time"
                placeholder="Select a start time (EST)"
                largeTitle={true}
                shouldPushContent={false}
                width="60%"
                containerStyle={{ zIndex: 11 }}
                shouldResetSelected={invalidStartTime}
                selected={startTime}
                fieldName={fieldNames.startTime}
                dropDownItems={validTimes}
                {...reactiveFormProps}
            />
            <InputField
                shouldSanitizeInput
                value={duration}
                reactiveForm={true}
                largeTitle={true}
                fieldTitle="Class Duration (min)"
                fieldName={fieldNames.duration}
                width="60%"
                {...reactiveFormProps}
            />
            <DropDownPicker
                alignSelf="left"
                multipleSelection
                reactiveForm={true}
                fieldTitle="Class Category"
                placeholder="Select a category"
                largeTitle={true}
                selected={selectedKeys}
                shouldResetSelected={false}
                shouldPushContent={false}
                containerStyle={{ zIndex: 10 }}
                fieldName={'category'}
                dropDownItems={specializationPickerItems}
                onChangeValue={value => handleItemSelection(value)}
                width="60%"
                {...reactiveFormProps}
            />
            <InputField
                reactiveForm={true}
                fieldTitle="Equipment Needed"
                largeTitle={true}
                fieldName={fieldNames.equipment}
                defaultValue={equipment ?? ''}
                {...reactiveFormProps}
            />
        </FieldContainer>
    )
}

export default Details
