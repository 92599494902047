import React from 'react'
import { TouchableOpacity } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
const noop = () => {}
export const BackButton = ({ containerStyle = {}, buttonStyle = {}, size, color = 'black', onPress, ...rest }) => {
    const navigation = useNavigation()
    const defaultButtonStyle = { alignSelf: 'center' }
    const defaultContainerStyle = { justifyContent: 'center', alignItems: 'center', paddingLeft: '5%' }

    const goBack = () => navigation.goBack()
    return (
        <TouchableOpacity onPress={onPress || goBack} style={containerStyle || defaultContainerStyle} {...rest}>
            <Ionicons style={buttonStyle || defaultButtonStyle} name="chevron-back" size={size} color={color} />
        </TouchableOpacity>
    )
}

export default BackButton
