export default `
# Homebody Live Fitness Privacy Policy

Homebody Live Fitness is committed to maintaining the accuracy, confidentiality, and security of your Personal Data. This Privacy Policy describes how your personal information is collected, used, and shared when you sign up for an account, use any of our services, use our mobile application, or visit our website, homebodylivefitness.com. By visiting our website or using any of our services, you agree to our Privacy Policy, which is outlined below.  By visiting our website or using any of our services or mobile application you are accepting the practices described in our Privacy Policy and you consent to receive it via electronic form via publication on the world wide web between you (“you,” “user,” “your”) and Homebody Live Fitness (“the service,” “us,” we”, "our", "company").   This Privacy Policy is part of our Terms and Conditions which govern your use of the site and services.  This Privacy Policy does not apply to any products, services, websites, or content that are offered by third parties (“Third Party Services”, “service providers”), which are governed by their respective privacy policies. Please read this policy carefully to understand our practices regarding your personal data and how we treat it. By using our website or services, you acknowledge that you have read and understood and consent to this Privacy Policy.

### What Personal Information Do We Collect?
As part of our website and services, you may be asked to provide certain information about you and we may collect information automatically through your usage. We collect personal information that you provide or input on the site, including:
- Name, date of birth and contact information, including your phone number and email address;
- Login details, including email address, username and password;
- Social media account information, and profile image,
- Information in correspondence with us
- Payment information, including your address and account information,
- Demographic information;
- Pictures, video, and other user uploaded or submitted content;
- Location as provided by the user
- Fitness information, including height, weight, class usage and fitness goals
Additionally, we may collect information automatically about users via a variety of methods, such as cookies, web beacons, JavaScript, and log files. This information may include user IP addresses, browser types, domain names, device type, time stamp, referring URL and other log file information; user activities within the Service; aggregate and statistical information regarding overall server/visitor traffic and navigation patterns for the Service. Web servers collect this type of basic information automatically as part of Web log processes.

We may also receive information if you chose to link or sign up using a third-party service account (such as Facebook), including personal data, such as your user ID, name, e-mail address, profile picture/avatar page posts, fan counts, engagement and comments that are available through API integrations with the third-party service you use for signing into the Service.

### How Do We Use Your Personal Information?
***Personal data you provide to the Service.***  We may use the personal data you provide to the Service for the following purposes:
- To set up and maintain your registration with the Service;
- To communicate with you;
- To tailor our content and information that we may send or display to you, to offer personalized help and instructions, and to otherwise personalize your experience with our Service.
- To provide features available in the Service;
- To develop, improve, and protect the Service;
- For direct marketing and market research, including for example reading recommendations and targeted marketing, in accordance with applicable law;
- To allow teachers/influencers to contact users;
- To use user content as part of our Service as well as advertising and marketing campaigns to promote the services
- To comply with applicable legal obligations, including responding to a subpoena or court order
- To enforce our terms, conditions and policies
- To prevent and investigate fraud and other misuses; and
- To protect our rights and/or our property.

***Data collected automatically.*** We may use the data collected automatically for the following purposes:
- To manage the Service;
- To provide features available in the Service;
- To personalize the Service. In particular, cookies may be used for providing us information on the content you’ve shared in social media after or before registering to the Service. We may use this information to provide you with advertiser content or other recommendations;
- To develop, improve, and protect the Service;
- For market research and data analysis, including for example reading recommendations and behavioral targeting and targeted marketing, in accordance with applicable law;
- To audit and analyze the Service; and
- To ensure the technical functionality and security of the Service.

### Do We Ever Share Your Personal Information with Third-Parties?
We share the information we collect as follows:

***Legal Disclosures.*** We may disclose a Service user’s information (including personal information) where we believe that we are required to do so in order to comply with an applicable statute, regulation, rule or law, a subpoena, a warrant or administrative request, a court or regulatory order, or other valid legal processes. We may also disclose personal information where we believe it is necessary to identify, contact or bring legal action against someone who may be violating the Terms of Service for our Service, to detect fraud, for assistance with a delinquent account, as evidence in litigation in which we are involved, or to protect the safety and/or security of our users, the Service or the general public.

***Service Providers.*** We may contract with independent contractors, vendors and suppliers to provide specific services related to the Service, such as hosting and maintaining the Service, providing payment processing and fraud screening, data analysis, and developing applications for the Service, email services and marketing enrichment services. We may disclose a Service user’s information to these service providers as necessary for those service providers to provide their service. Similarly, we may share your information with our influencer/teacher partners to allow them to contact you in connection with the service.

***Business Transfers.*** We reserve the right to transfer information (including personal information) to a third party in the event of a sale, merger or other transfer of all or substantially all of the assets of the Company or any of its affiliates (including as part of a bankruptcy proceeding). We may disclose personal information about Service users to our affiliated companies. Our affiliates’ use of your personal information will be in accordance with the terms of this Privacy Policy.

***Social Media Sharing.*** If a user chooses to share information or other content and feedback through social media outlets, such as YouTube, Vimeo, Facebook, Instagram and Twitter, third parties may receive information about a user’s experience on the Service.
Aggregate and De-Identified Information. We may also provide aggregate, anonymous or de-identified information about users and the Service for marketing and research purposes. For example, we might inform third parties regarding the number of unique users who visit the Service, the demographic breakdown of our registered users of the Service, and the educational progress of categories of users.

### What Privacy Protections are there for third-party links used on the website?
Content and information posted by the Company may contain links to other sites, including those of our business partners or affiliates. While we seek to link only to sites that share our high standards and respect for privacy and security, we are not responsible for the privacy practices used by other sites.

### Does the Company use Cookies?
The Website, in line with almost every other internet-based company, utilizes cookie technology to allow the company to improve the functionality of the service and improve your user experience. Your browser or device may allow you to block or delete cookies from our site, however, this may interfere with the functionality of our Service. Cookies collect information automatically even when a user is not logged into their account. The information collected may include information about the platform and operating system you are using, your browser type and version, computer and connection information, and what time you accessed the site. This information will be collected any time you access the website unless you opt out.

***What are cookies?***
Cookies are small pieces of data, stored in text files, that are stored on your computer or other device when websites are loaded in a browser. They are widely used to “remember” you and your preferences, either for a single visit (through a “session cookie”) or for multiple repeat visits (using a “persistent cookie”). They ensure a consistent and efficient experience for visitors, and perform essential functions such as allowing users to register and remain logged in. Cookies may be set by the site that you are visiting (known as “first party cookies”), or by third parties, such as those who serve content or provide advertising or analytics services on the website (“third party cookies”).

Both websites and HTML emails may also contain other tracking technologies such as “web beacons” or “pixels.” These are typically small transparent images that provide us with statistics, for similar purposes as cookies. They are often used in conjunction with cookies, though they are not stored on your computer in the same way. As a result, if you disable cookies, web beacons may still load, but their functionality will be restricted.

***Which cookies does The Company use?***
Anytime you visit and use a The Company website, The Company or a third party places cookies in your browser. Certain cookies are specific to features or specific preferences, and certain cookies will be used all the time.  Four different types of cookies are used by The Company:

- ***Essential Cookies.*** These are cookies are placed in your browser by the Company and are also known as “strictly necessary” cookies. These are necessary to allow you to move around the site and use its features, such as “Save and View Favorites.”
- ***Analytics Cookies.*** Also known as “performance cookies,” analytics cookies collect information about visits to our sites and how the services we offer are being used. We use this data to make improvements and report our performance. For example, these cookies collect information about how visitors use our sites, which site the visitor came from, the number of each user’s visits and how long a user stays on the site. We might also use analytics cookies to test new ads, pages, or features to see how users react to them. Analytics cookies are primarily third-party cookies.
- ***Functionality Cookies.*** Sometimes called “preference cookies,” these first-party cookies allow us to remember information you have entered or choices you have made (such as your username, language, region, and marketing preferences) on our sites, so the next time you visit the site you will not have to set them again. These cookies also allow us to provide a better user experience on our website.
- ***Targeting Cookies.*** The Company and our advertising partners or other third-party partners may use these types of cookies, also known as “advertising cookies,” to deliver advertising and track ad performance, and enable advertising networks to deliver ads that may be relevant to you based upon your activities (this is sometimes called “behavioral” or “targeted” advertising) on our sites.

**How can you manage and delete cookies?**
Almost all browsers use cookies to track your internet use automatically. However, these browsers also allow you to change the settings in your browser to manage your cookie settings to delete any previously sent cookies and to not accept new cookies. Again, please keep in mind that disabling cookies will negatively impact your user experience with our site. Please review your browser’s settings and preferences for more information on how to manage and delete cookies. Browsers on mobile devices may have different ways to manage cookie settings.

### Does the Company use any other user tracking technologies?
Technology used on the Internet is constantly changing. The company uses technology standard to the Internet, such as pixel tags, web beacons, and other similar technologies, to track visitors to our sites.

***How do we respond to “Do Not Track” Signals?***
We do not currently recognize automated browser signals regarding tracking mechanisms, which may include "Do Not Track" instructions. You can change your privacy preferences regarding the use of cookies and similar technologies through your browser. For more information on Do Not Track please visit http://www.allaboutdnt.org/

***Are There Any Age Restrictions for Use of the Company’s Services?***
You must be at least 18 years old or older to gain access to our website or our services. This website is not intended for those who are under 18 years old. The Company does not knowingly collect any information from anyone who is under 18 years of age and does so in compliance with the Children’s Online Privacy Protection Act. This website, as well as its contents and services, is directed solely to individuals who are at least 18 years old.

If we learn we have collected Personal Data from a child under 18 without parental consent, we will delete that information, unless we are legally obligated to retain such data.   If you have any reason to believe we may have unknowingly collected data from a minor under the age of 18, please immediately send us an email at support@homebodylivefitness.com.

### What Rights do You have?
You have the following rights with respect to the personal data we hold about you:
- The right to know what data we hold about you: You can contact us at support@homebodylivefitness.com to review the personal data you have provided to the Service. We seek to swiftly respond to your inquiry. We may charge a small processing fee if less than twelve (12) months has passed since your last inquiry relating to personal data we hold about you.
- The right to have incomplete, incorrect, outdated, or unnecessary personal data corrected, deleted, or updated.​ The easiest way to correct, delete, or update the personal data you have provided to the Service is to access your profile settings and enter the necessary changes there. If you have additional questions regarding the correction, deletion, or updating of the personal data we hold about you, please contact us at support@homebodylivefitness.com .
- The right to opt out of receiving electronic direct marketing communications from us:​ All electronic direct marketing communications that you may receive from us, such as e-mail messages, give you an option of not receiving such communications from us in the future. If you have any additional questions about electronic direct marketing received from us, please contact us at support@homebodylivefitness.com.

### What Additional Rights Do Nevada Users Have?
Under the Nevada Privacy Law (SB220), certain Nevada residents may opt out of the sale of “personally identifiable information” for monetary consideration to a person for that person to license or sell such information to additional persons. “Personally identifiable information” includes first and last name, address, email address, phone number, Social Security Number, or an identifier that allows a specific person to be contacted either physically or online.

Homebody Live Fitness does not sell your personally identifiable information. However, if you are a Nevada resident who has purchased or leased goods or services from us, you may submit a request to opt out of any potential future sales under Nevada law by emailing support@homebodylivefitness.com. If our policy on selling personal identifiable information changes, we will honor your request.

### What Additional Rights Do California Users Have?
The California Consumer Privacy Act provides some California residents with the additional rights listed below.

***California Right to Know.*** You have the right to know and see what data we have collected about you over the past twelve (12) months, including:
- The categories of personal information we have collected about you;
- The categories of sources from which the personal information is collected;
- The business or commercial purpose for collecting your personal information;
- The categories of third parties with whom we have shared your personal information; and
- The specific pieces of personal information we have collected about you.

***California Right to Delete.*** You have the right to request that we delete the personal information we have collected from you (and direct our service providers to do the same). There are a number of exceptions, however, that include, but are not limited to, when the information is necessary for us or a third party to do any of the following:
- Provide you with a good or service, or otherwise perform a contract between us and you;
- Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;
- Fix our system in the case of a bug;
- Protect the free speech rights of you or other users;
- Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et seq.);
- Comply with a legal obligation; or
- Make other internal and lawful uses of the information that are compatible with the context in which you provided it.

***California Other Rights.*** You have the right to request and obtain from us once a year, free of charge, information about the personal information (if any) we disclose to third parties for their own direct marketing purposes in the preceding calendar year. If applicable, this information would include a list of the categories of personal information that was shared and the names and addresses of all third parties with which we shared information in the immediately preceding calendar year. You also have the right not to be discriminated against for exercising any of the rights listed above.

***Exercising Your California Privacy Rights.*** To request access to or deletion of your personal information, or to exercise any other data rights under California law, you may write to us at support@homebodylivefitness.com. Please include your full name and email address associated with your use of our services, along with why you are writing, so that we can process your request in an efficient manner.

***California Response Timing and Format.*** We aim to respond to a consumer request for access or deletion within 45 days of receiving that request. If we require more time, we will inform you of the reason and extension period in writing.

### What Additional Rights do EAA Residents Have?
If you are resident outside the United States, including in the EEA; European Economic Area (“EEA”), which includes the member states of the European Union (“EU”), we transfer Personal Data provided by you for processing in the United States, including Personal Information sent via e-mails or when you create an account.  Under the GDPR, we are considered a “controller” and a “co-processor” of the Personal Data of EEA Residents.  By providing Personal Data to us for the purpose of using the service, website or mobile application, you consent to the processing of such data in the United States.  The transfer of your Personal Data to the United States is necessary for the performance of a contract between you and us for your use of the website or mobile application.
Please note that the United States does not have data protection laws equivalent to those in the EEA and other jurisdictions.

**Rights of EEA Residents**
This section of the Privacy Policy is applicable to residents of the EEA, which consists of the member states of the European Union.  This section also applies to residents of Switzerland and residents of the United Kingdom.   Residents of the EEA, UK and Switzerland are referred to here as “EEA Residents.”

All processing of Personal Data of EEA Residents is performed by us in accordance with the General Data Protection Regulation (2016/679) of the European Parliament and of the Council on the protection of natural persons regarding the processing of Personal Data and on the free movement of such data (“GDPR”).

Under the GDPR, we are both the controller and a co-processor of the Personal Data of EEA Residents. Our purpose for collecting and processing Personal Data from EEA Residents is to provide them with the features and functionalities of our website and mobile application and information regarding our services.  The legal basis for collecting Personal Data is because it is necessary for performance of a contract between us to provide you with the website and mobile application and its related features and functionality. We also rely on your consent to receive information about our services.  You may withdraw consent from receiving marketing and promotional communications by clicking the “Update Email Preferences” link on the communication.  If EEA Residents do not provide Personal Data to us or withdraw consent for processing such Personal Data, we may not be able to provide such residents with certain features or functionalities of the website or mobile application or information regarding the services, including processing payments.

If you are an EEA resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us at support@homebodylivefitness.com

Additionally, if you are an EEA resident, we are hereby notifying you that we are processing your information in order to fulfill contracts we might have with you, or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to the United States.

### Security of Transmission of Personal Information and Risks Related to the Internet
Our website and mobile application have security measures in place to help protect against the loss, theft, misuse and unauthorized access, disclosure, alteration and destruction of the information under the company’s control. The website uses no encryption (data scrambling) on certain portions of the website, but does use encryption on portions where you are transmitting financial information, such as credit card information. When you are on any website that asks you for confidential information, you should check to see if the information being transmitted is encrypted in order to increase the security of your information. Although every effort is made to ensure no one else will view, seize or obtain your information, complete confidentiality and security is not yet possible over the Internet. Any unencrypted email communication over the Internet is not secure or confidential, and is subject to possible interception, loss and alteration. The company, its agents, administrators, employees and affiliates may not be held liable for any damages you or anyone else may suffer or incur as a result of the transmission of confidential or sensitive information over the Internet, and all such communications will be made at your own risk.

We seek to use reasonable organizational, technical and administrative measures to protect Personal Information under our control. For example, we seek to use Secure Sockets Layer ("SSL") technology for all financial transactions conducted through the website or mobile application.

Unfortunately, no data storage system or data transmission over the Internet can be guaranteed to be 100% secure. Please also be aware that we may use third-party cloud service providers that provide hosting, data storage and other services pursuant to standard terms and conditions that may be non-negotiable; these service providers have informed us or the general public that they apply security measures they consider adequate for the protection of information within their system, or they have a general reputation for applying such measures.

### How Are Users Notified of Any Changes to this Policy?
We may amend this Privacy Policy from time to time. We will provide notice of any material changes made to our Privacy Policy by prominently posting the revised Policy with an updated date of revision on our homepage. We encourage users to check this page periodically for any changes. If we make any material changes that affect information we have previously collected about you, we will provide you with notice via email or within the Service.

### How Can I Contact the Company Regarding this Policy?
If you have any questions about this Privacy Policy or our security measures at Homebody Live Fitness, please contact us at support@homebodylivefitness.com. Your continued use of the Service following any changes to this Privacy Policy constitutes your acceptance of any such changes made.
`

