import { Dimensions, Platform } from 'react-native'
const { height, width } = Dimensions.get('window')
const windowHeight = Dimensions.get('window').height
const windowWidth = Dimensions.get('window').width
import { isWeb } from '@constants'
// from https://ios-resolution.com/
const iPhone8Width = 375
const iPhone12MaxWidth = 428
const iPadMaxWidth = 1024
const minDesktopWidth = 1200
export const isMobileMaxWidth = iPhone12MaxWidth

export const useDimensions = () => {
    const isSmallDevice = width <= iPhone8Width
    const isMobile = width <= iPhone12MaxWidth
    const isTablet = width > iPhone12MaxWidth && width <= iPadMaxWidth
    const isDesktop = isWeb && width >= minDesktopWidth
    const isMobileWeb = isWeb && isMobile
    const deviceDimensions = { deviceHeight: height, deviceWidth: width }
    const windowDimensions = { windowHeight, windowWidth }
    const isAndroid = Platform.OS === 'android'
    return {
        isSmallDevice,
        isMobile,
        isMobileWeb,
        isAndroid,
        isTablet,
        isDesktop,
        deviceDimensions,
        windowDimensions,
    }
}

export const deviceHeights = {
    isiPhoneX: height == 812,
    isAverage: height == 667,
    isPlus: height == 736,
    isSmalliPhoneHeight: height == 568,
}
