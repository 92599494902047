import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import BeRightBack from 'components/streaming/BeRightBack'
import { isWeb } from '@constants'
const VIDEO = 1

export const AgoraStreamPlayer = ({ reactiveStreamProps, videoPlayerStyle }) => {
    const { AgoraVideoPlayer } = isWeb && require('agora-rtc-react')
    const { streamState, streamHelpers } = reactiveStreamProps
    const { tracks, host, isHost } = streamState
    const { videoPlayerVisible } = streamHelpers
    const { videoTrack: hostVideoTrack } = host || {}
    const videoTrack = isHost ? tracks[VIDEO] : hostVideoTrack

    return (
        <VideoPlayerContainer style={videoPlayerStyle}>
            {videoPlayerVisible ? (
                <AgoraVideoPlayer videoTrack={videoTrack} style={videoPlayerStyle} />
            ) : (
                <BeRightBack reactiveStreamProps={reactiveStreamProps} style={videoPlayerStyle} />
            )}
        </VideoPlayerContainer>
    )
}

const VideoPlayerContainer = styled(View)``
export default AgoraStreamPlayer
