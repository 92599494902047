import React from 'react'
import { Animated } from 'react-native'
import { Heading } from 'typography'
import { isWeb } from '@constants'
import styled from 'styled-components/native'
import useStreamOverlayAnimation from 'hooks/useStreamOverlayAnimation'
export const StreamMessageOverlay = ({ reactiveStreamProps, isPortrait }) => {
    const { streamState } = reactiveStreamProps
    const {
        streamOverlayMessage: { overlayMessage },
    } = streamState || {}

    const { isCountdown, isIntervalCountdown, overlayOpacity, count } = useStreamOverlayAnimation({
        reactiveStreamProps,
    })

    return (
        <StreamOverlayContainer isPortrait={isPortrait} style={{ opacity: overlayOpacity }}>
            {isCountdown && (
                <CountdownText color="white" isMostBold paddingBottom={isWeb ? 50 : 0}>
                    {count}
                </CountdownText>
            )}
            <StreamOverlayText textAlign="center" color="white" isBold>
                {!isIntervalCountdown ? overlayMessage : ''}
            </StreamOverlayText>
        </StreamOverlayContainer>
    )
}

const CountdownText = styled(Heading)`
    font-size: 80px;
    padding-top: ${({ theme: { isMobile, isTablet }, isPortrait }) =>
        isMobile || isTablet ? (isPortrait ? '40%' : '20%') : '0%'};
`
const StreamOverlayText = styled(Heading)``
const StreamOverlayContainer = styled(Animated.View)`
    background-color: transparent;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-items: center;
    align-self: center;
    top: ${({ theme: { isMobile, isTablet } }) => (isMobile || isTablet ? '0%' : '20%')};
    height: ${({ theme: { isMobile, isTablet } }) => (isMobile || isTablet ? '100%' : '50%')};
    width: ${({ theme: { isMobile, isTablet } }) => (isMobile || isTablet ? '100%' : '75%')};
`

export default StreamMessageOverlay
