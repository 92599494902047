import React, { useEffect, useState } from 'react'
// ui
import { useNavigation, useRoute, useIsFocused } from '@react-navigation/native'
// components
import { ScreenContainer, ContentContainer } from 'commons/containers'
import ClassesFlatList from 'components/instructor/ClassesFlatList'
import DeleteClassModal from 'components/instructor/DeleteClassModal'
import { FooterBar } from 'commons/components/FooterBar'
// Selectors
import { CLASS_STATUS } from '@constants'
import { populateCreateClassForm, removeDeletedRecords, orderByDateAndTime, mergeUniqueArrayObjects } from 'helpers'
import { resetCreateClassNav } from 'navigationActions'
import { getCurrentUser, getUnpublishedClasses, getUnfinishedClasses } from 'selectors'
import { CREATE_CLASS_NAVIGATOR, CREATE_CLASS, INSTRUCTOR_DRAWER_INDICES } from 'screenNames'
import { DELETE_CLASS } from 'apollo/mutations'
import { useMutation } from 'apollo-augmented-hooks'
import { deleteClass } from 'actions'
import { cache } from 'apollo/cache'
const { UNFINISHED } = CLASS_STATUS
const emptyArr = []
const emptyObj = {}

const UnpublishedClasses = () => {
    const navigation = useNavigation()
    const route = useRoute()
    const { params = {} } = route
    const isFocused = useIsFocused()
    const { resetFormState = false, shouldResetFetchMore = false } = params || {}
    const [classToDelete, setClassToDelete] = useState({})
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const { id: instructorID } = getCurrentUser()

    const [unfinishedClasses, setUnfinishedClasses] = useState(emptyArr)
    const [unfinishedClassesToken, setUnfinishedClassesToken] = useState(null)

    const {
        unfinishedClasses: unfinishedClassesResult,
        fetchMore: fetchMoreUnfinishedClasses,
        nextToken: initialUnfinishedClassesToken,
        loading: unfinishedClassesLoading,
        refetch: refetchUnfinished,
    } = getUnfinishedClasses({
        instructorID,
        onCompleted: () => {
            if (unfinishedClasses?.length === 0 && !unfinishedClassesToken) {
                setUnfinishedClassesToken(initialUnfinishedClassesToken)
                setUnfinishedClasses(unfinishedClassesResult)
            }
        },
    })

    async function handleFetchMoreUnfinishedClasses() {
        if (unfinishedClassesToken) {
            const fetchMoreResult = await fetchMoreUnfinishedClasses({
                variables: { nextToken: unfinishedClassesToken },
            })
            const {
                data: {
                    listClasses: { items: classes = emptyArr, nextToken: token = null },
                },
            } = fetchMoreResult ?? emptyObj
            const moreClasses = removeDeletedRecords(classes)
            const allUnfinishedClasses =
                unfinishedClasses?.length > 0
                    ? mergeUniqueArrayObjects(unfinishedClasses, moreClasses)
                    : [...moreClasses]
            const orderedClasses = orderByDateAndTime(allUnfinishedClasses)
            setUnfinishedClasses(orderedClasses)
            setUnfinishedClassesToken(token)
        }
    }

    useEffect(() => {
        if (unfinishedClassesToken !== null) {
            handleFetchMoreUnfinishedClasses()
        }
    }, [unfinishedClassesToken])

    const [unpublishedClasses, setUnpublishedClasses] = useState(emptyArr)
    const [unpublishedClassesToken, setUnpublishedClassesToken] = useState(null)

    const {
        unpublishedClasses: unpublishedClassesResult,
        nextToken: initialUnpublishedClassesToken,
        fetchMore: fetchMoreUnpublishedClasses,
        loading: unpublishedClassesLoading,
        refetch: refetchUnpublished,
    } = getUnpublishedClasses({
        instructorID,
        onCompleted: () => {
            if (unpublishedClasses?.length === 0 && !unpublishedClassesToken) {
                setUnpublishedClassesToken(initialUnpublishedClassesToken)
                setUnpublishedClasses(unpublishedClassesResult)
            }
        },
    })
    async function handleFetchMoreUnpublishedClasses() {
        if (unpublishedClassesToken) {
            const fetchMoreResult = await fetchMoreUnpublishedClasses({
                variables: { nextToken: unpublishedClassesToken },
            })
            const {
                data: {
                    listClasses: { items: moreClasses = emptyArr, nextToken: token = null },
                },
            } = fetchMoreResult ?? emptyObj
            const allUnpublishedClasses =
                unpublishedClasses?.length > 0
                    ? mergeUniqueArrayObjects(unpublishedClasses, moreClasses)
                    : [...moreClasses]
            setUnpublishedClasses(allUnpublishedClasses)
            setUnpublishedClassesToken(token)
        }
    }

    useEffect(() => {
        if (unpublishedClassesToken !== null) {
            handleFetchMoreUnpublishedClasses()
        }
    }, [unpublishedClassesToken])

    const publishClass = ({ Class }) => {
        populateCreateClassForm(Class)
        navigation.navigate(CREATE_CLASS_NAVIGATOR, {
            screen: CREATE_CLASS.REVIEW,
            params: {
                index: 4,
                fromUnpublished: true,
                parentNav: navigation,
                resetUnpublishedClassesState: () => {
                    setUnpublishedClasses(emptyArr)
                    setUnpublishedClassesToken(null)
                },
            },
        })
    }

    const editClass = ({ Class, classID }) => {
        populateCreateClassForm(Class)
        navigation.navigate(CREATE_CLASS_NAVIGATOR, {
            screen: CREATE_CLASS.DETAILS,
            params: { index: 0, fromUnpublished: true, parentNav: navigation },
        })
    }

    const deleteClassMutation = useMutation(DELETE_CLASS, {
        onCompleted: () => {
            if (classToDelete?.classStatus == UNFINISHED) {
                const deletedClassRemoved = unfinishedClasses?.filter(item => item?.id !== classToDelete?.id)
                setUnfinishedClasses(deletedClassRemoved)
            } else {
                const deletedClassRemoved = unpublishedClasses?.filter(item => item?.id !== classToDelete?.id)
                setUnpublishedClasses(deletedClassRemoved)
            }
            toggleDeleteModalVisible()
        },
        onError: () => {},
    })

    async function handleDeleteClass() {
        const { id, _version } = classToDelete
        await deleteClass({ id, _version, deleteClassMutation })
        const normalizedID = cache.identify({
            id,
            userID: instructorID,
            __typename: 'Class',
        })
        cache.evict({ id: normalizedID })
        cache.gc()
    }

    const handleOpenDeleteModal = ({ id, _version, classStatus }) => {
        toggleDeleteModalVisible()
        setClassToDelete({ id, _version, classStatus })
    }

    const toggleDeleteModalVisible = () => setDeleteModalVisible(!deleteModalVisible)

    useEffect(() => {
        if (resetFormState) {
            resetCreateClassNav({ navigation, currentRouteIndex: INSTRUCTOR_DRAWER_INDICES.UNPUBLISHED_CLASSES })
        }
        if (resetFormState || shouldResetFetchMore) {
            setUnfinishedClasses(emptyArr)
            setUnpublishedClasses(emptyArr)
            setUnfinishedClassesToken(null)
            setUnpublishedClassesToken(null)
            refetchUnfinished()
            refetchUnpublished()
        }
    }, [params])

    useEffect(() => {
        if (isFocused) {
            refetchUnfinished()
            refetchUnpublished()
        }
    }, [isFocused])

    return (
        <ScreenContainer hasTopPadding>
            <ContentContainer isFluid>
                <ClassesFlatList
                    isInstructor
                    hideNoClassesText
                    showCreateWhenEmpty
                    onDeletePress={handleOpenDeleteModal}
                    onCardPress={editClass}
                    classesLoading={false}
                    data={unfinishedClasses}
                    headingText="My Unpublished Classes"
                    subHeadingText="Unfinished Classes"
                />
                {!!unpublishedClasses?.length && (
                    <ClassesFlatList
                        hidePicker
                        isInstructor
                        hideNoClassesText
                        onDeletePress={handleOpenDeleteModal}
                        onCardPress={publishClass}
                        classesLoading={false}
                        data={unpublishedClasses}
                        subHeadingText="Ready to Publish"
                    />
                )}
                {deleteModalVisible && (
                    <DeleteClassModal
                        visible={deleteModalVisible}
                        firstButtonOnPress={toggleDeleteModalVisible}
                        secondButtonOnPress={handleDeleteClass}
                    />
                )}
            </ContentContainer>
            <FooterBar />
        </ScreenContainer>
    )
}

export default UnpublishedClasses
