import React from 'react'
import { View, Pressable, Linking } from 'react-native'
import { Inter900, Inter700, Inter500 } from 'typography'
import colors from 'colors'
import { RowWeb } from 'styles/positionHelpers'
import styled from 'styled-components/native'
import SocialIcons from 'components/SocialIcons'
import { useDimensions } from 'dimensions'
import { CONTACT } from '../../constants/appInfo'
const downloadables = `https://drive.google.com/drive/folders/1ndRbJx3Ct9Ur5AYbB5f6ipts2kHYuaYQ?usp=sharing`

const Contact: React.FC = () => {
    const { isMobile } = useDimensions()

    return (
        <Container>
            <ContentCell>
                <Title>Download our press kit</Title>
                <RowContainer>
                    <View>
                        <CellHeaderText onPress={() => Linking.openURL(downloadables)}>Downloadables</CellHeaderText>
                        <Pressable onPress={() => Linking.openURL(`/homebody_fact_sheet.pdf`)}>
                            <ItemsText>Fact Sheet</ItemsText>
                        </Pressable>
                    </View>
                    {/* <View>
            <CellHeaderText>In the Press</CellHeaderText>
            <ItemsText>The latest..</ItemsText>
          </View> */}
                </RowContainer>
            </ContentCell>
            <ContentCell>
                <Title>Contact</Title>
                <ContactRow>
                    <View>
                        <ContactHeaderText>For press and media inquiries plese contact</ContactHeaderText>
                        <Email isPress />
                        <ContactHeaderText style={{ marginTop: 40 }}>
                            For general inquiries please contact
                        </ContactHeaderText>
                        <Email />
                    </View>
                    <SocialIcons style={{ alignSelf: isMobile ? 'auto' : 'flex-end', marginTop: 40 }} />
                </ContactRow>
            </ContentCell>
        </Container>
    )
}

const Email = ({ isPress }): JSX.Element => (
    <Pressable onPress={() => Linking.openURL(`mailto:${isPress ? CONTACT.PRESS_EMAIL : CONTACT.EMAIL}`)}>
        <ContactText>{isPress ? CONTACT.PRESS_EMAIL : CONTACT.EMAIL}</ContactText>
    </Pressable>
)

const Container = styled(RowWeb)`
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
    width: 100%;
`

const RowContainer = styled(View)`
    flex-direction: row;
`

const ContactRow = styled(RowWeb)`
    justify-content: space-between;
`

const ContentCell = styled(View)`
    width: ${props => (props.theme.isMobile ? '100%' : '50%')};
`

const Title = styled(Inter900)`
    font-size: 38px;
    margin-top: 50px;
`

const CellHeaderText = styled(Inter900)`
    font-size: 20px;
    line-height: 26px;
    margin-top: 32px;
    margin-right: 30px;
`

const ItemsText = styled(CellHeaderText)`
    color: ${colors.subTextGray};
    cursor: pointer;
`

const ContactHeaderText = styled(Inter700)`
    font-size: 16px;
    line-height: 23px;
    margin-top: 16px;
    margin-bottom: 16px;
`

const ContactText = styled(Inter500)`
    font-size: 14px;
    line-height: 23px;
    cursor: pointer;
`

export default Contact
