import { Auth, Logger } from 'aws-amplify'
const logger = new Logger('My-Logger')
import { AUTH_CHANNEL } from 'apollo/channelTypes'
import { currentUserID, isLoggedIn } from 'apollo/cache'

export const authListener = async data => {
    const { SIGN_IN, CONFIRM_SIGN_UP, SIGN_OUT, SIGN_IN_FAILURE, TOKEN_REFRESH, TOKEN_REFRESH_FAILURE, CONFIGURED } =
        AUTH_CHANNEL
    switch (data.payload.event) {
        case SIGN_IN:
            try {
                const {
                    attributes: { sub: cognitoUserID },
                } = await Auth.currentAuthenticatedUser({ bypassCache: true })
                //setting the value of this reactive var will trigger appropriate "login" rerenders
                currentUserID(cognitoUserID)
                logger.info('user signed in')
                break
            } catch (error) {
                __DEV__ && console.log('Error signing in: ', error)
                break
            }

            break
        case CONFIRM_SIGN_UP:
            const { userDetails, createUser } = data.payload.data
            await createUser({
                input: {
                    ...userDetails,
                    userType: 'TRAINEE',
                    credits: 20,
                    customerID: '',
                    accountID: '',
                    pushToken: '',
                },
            })
            logger.info('user signed up')
            break
        case SIGN_OUT:
            currentUserID('')
            isLoggedIn(false)
            logger.info('user signed out')
            break
        case SIGN_IN_FAILURE:
            logger.error('user sign in failed')
            break
        case TOKEN_REFRESH:
            logger.info('token refresh succeeded')
            break
        case TOKEN_REFRESH_FAILURE:
            logger.error('token refresh failed')
            break
        case CONFIGURED:
            logger.info('the Auth module is configured')
    }
}
