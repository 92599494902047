import React from 'react'
import { View } from 'react-native'
import { DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer'

const labelStyle = {
    color: '#8D99B2',
    fontSize: 18,
}

export const InstructorDrawer = props => {
    return (
        <DrawerContentScrollView {...props}>
            <View style={{ width: '100%', height: 35 }} />
            <DrawerItemList {...props} labelStyle={labelStyle} />
        </DrawerContentScrollView>
    )
}

export default InstructorDrawer
