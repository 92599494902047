import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { View, TouchableOpacity, Linking, Image } from 'react-native'
import { ScreenContainer, ContentContainer } from 'commons/containers'
import { LANDING_PAGE } from 'screenNames'
import { Heading, SubHeaderText } from 'typography'
import VectorIcon from 'commons/components/VectorIcon'
import styled from 'styled-components/native'
import { ContinueButton } from 'commons/components/ContinueButton'
import { iconHitSlop } from '@constants'

const APPLE_APP_STORE_IMAGE = require('assets/appStoreDownload.png')
const APPLE_APP_STORE_URL = 'https://apps.apple.com/us/app/homebody/id1593266402'
const headingText = 'Now available in the \napp store'
const subHeadingText = 'HOMEBODY is currently available on iOS. Please download our app using the link below.'
const instructorText =
    'If you are an instructor looking to join our platform, please sign up and fill out an application on our website.\n\nYou can also email us at hello@homebodylivefitness.com'

export default function TestFlightDownload() {
    const navigation = useNavigation()
    const openTestFlightLink = () => Linking.openURL(APPLE_APP_STORE_URL)
    const goToLandingPage = () => navigation.navigate(LANDING_PAGE)
    return (
        <ScreenContainer>
            <ContentContainer>
                <ExitScreenIcon hitSlop={iconHitSlop} onPress={goToLandingPage}>
                    <VectorIcon family="Entypo" name="cross" size={30} color="black" />
                </ExitScreenIcon>
                <HeaderSubHeaderContainer>
                    <Heading isMostBold fontSize={26} textAlign="center">
                        {headingText}
                    </Heading>
                    <SubHeaderText paddingTop={25} textAlign="center">
                        {subHeadingText}
                    </SubHeaderText>
                </HeaderSubHeaderContainer>
                <AppStoreDownloadContainer onPress={openTestFlightLink}>
                    <DownloadImage source={APPLE_APP_STORE_IMAGE} />
                </AppStoreDownloadContainer>
                <InstructorTextContainer>
                    <SubHeaderText textAlign="center">{instructorText}</SubHeaderText>
                </InstructorTextContainer>
            </ContentContainer>
            <DownloadNowButton text="Download Now" onPress={openTestFlightLink} nonHover />
        </ScreenContainer>
    )
}

const InstructorTextContainer = styled(View)`
    max-width: 300px;
    align-self: center;
    align-content: center;
`
const DownloadNowButton = styled(ContinueButton)`
    width: 315px;
    height: 45px;
    align-self: center;
    margin-bottom: 10px;
`
const ExitScreenIcon = styled(TouchableOpacity)`
    position: absolute;
    top: 25px;
    right: 25px;
    align-self: flex-end;
`
const HeaderSubHeaderContainer = styled(View)`
    justify-content: space-between;
    max-width: 300px;
    align-content: center;
    align-self: center;
    margin-top: 75px;
`
const AppStoreDownloadContainer = styled(TouchableOpacity)`
    margin-vertical: 50px;
    justify-content: center;
    align-self: center;
    align-items: center;
    width: 100%;
    height: 36px;
`
const DownloadImage = styled(Image)`
    width: 160px;
    height: 46px;
`
