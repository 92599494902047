import React from 'react'
import { View, TextInput } from 'react-native'
import { getCurrentUser } from 'apollo/selectors'
import { isWeb } from '@constants'
import colors from 'colors'
import styled from 'styled-components/native'
import VectorIcon from 'components/VectorIcon'

export default function ClassSearchBar({
    searchText,
    setSearchText,
    handleSearch,
    placeHolder,
    searchInstructors = false,
    ...rest
}) {
    const { firstName } = getCurrentUser()

    const name = firstName ? `Hey ${firstName}.` : ''
    const explorePlaceholder = searchInstructors
        ? `${name} Let's find you an instructor!`
        : `${name} Let's find you a class!`

    return (
        <ClassSearchBarContainer>
            <MagnifyingGlassContainer>
                <VectorIcon family="SimpleLineIcons" name="magnifier" size={20} color={colors.subTextGray} />
            </MagnifyingGlassContainer>
            <SearchBar
                selectionColor={colors.homebodyTurquoise}
                placeholder={placeHolder ? placeHolder : explorePlaceholder}
                value={searchText}
                onChangeText={setSearchText}
                onSubmitEditing={handleSearch}
                {...rest}
            />
        </ClassSearchBarContainer>
    )
}

const ClassSearchBarContainer = styled(View)`
    flex-direction: row;
    align-items: center;
    width: ${({ theme }) => (theme.isMobile || theme.isTablet ? 100 : 30)}%;
    padding-vertical: ${({ theme }) => (theme.isMobile ? 20 : theme.isTablet ? 30 : 40)}px;
`
const SearchBar = styled(TextInput)`
    flex: 1;
    width: 100%;
    height: ${({ theme }) => (theme.isTablet ? 60 : 40)}px;
    flex-direction: row;
    align-items: center;
    padding-left: ${({ theme }) => (theme.isMobile ? 45 : 50)}px;
    background-color: ${colors.searchBarGray};
    font-family: Inter_600SemiBold;
    color: ${colors.darkGrey};
    border-radius: 10px;
    overflow: hidden;
    ${isWeb && `outline-color: ${colors.blueContinue};`}
`
const MagnifyingGlassContainer = styled(View)`
    position: absolute;
    z-index: 10;
    padding-left: 15px;
`
