import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import { Headline } from 'typography'
import { isWeb } from '@constants'
import IntervalCountdownTimer from 'components/streaming/IntervalCountdownTimer'
import ClassProgressBar from 'components/streaming/ClassProgressBar'
export const InstructorStreamOverlay = ({
    isTraineeOverlay = false,
    isLargeInterface = false,
    reactiveStreamProps,
    streamPlayerHeight,
}) => {
    const { streamState } = reactiveStreamProps
    const { currentInterval, classComplete, classEnded } = streamState
    const { name: intervalName } = currentInterval || {}

    if (classComplete || classEnded) return null
    return (
        <StreamOverlayContainer isTraineeOverlay={isTraineeOverlay} streamPlayerHeight={streamPlayerHeight}>
            <ClassProgress reactiveStreamProps={reactiveStreamProps} />
            <IntervalInfoContainer isLargeInterface={isLargeInterface}>
                <IntervalCountdown isLargeInterface={isLargeInterface} reactiveStreamProps={reactiveStreamProps} />
                <IntervalNameContainer>
                    <IntervalNameText isLargeInterface={isLargeInterface} color="white" isBold>
                        {intervalName}
                    </IntervalNameText>
                </IntervalNameContainer>
            </IntervalInfoContainer>
        </StreamOverlayContainer>
    )
}

const StreamOverlayContainer = styled(View)`
    position: absolute;
    width: 100%;
    height: ${props => (props.streamPlayerHeight ? props.streamPlayerHeight : '100%')};
    justify-content: ${props => (props.isTraineeOverlay ? 'flex-end' : 'space-between')};
    padding-bottom: ${props => (props.isTraineeOverlay && isWeb ? '10%' : '5%')};
    ${props => !props.isTraineeOverlay && `top: 20%`};
    ${props => !props.isTraineeOverlay && `padding-top: 5%`};
`

const ClassProgress = styled(ClassProgressBar)`
    flex-direction: row;
    align-self: center;
    justify-content: center;
`
const IntervalInfoContainer = styled(View)`
    width: 100%;
    flex-direction: row;
    padding-horizontal: 7.5%;
    justify-content: space-between;
    align-items: flex-end;
`
const IntervalCountdown = styled(IntervalCountdownTimer)`
    justify-content: center;
    align-items: center;
`
const IntervalNameText = styled(Headline)`
    font-size: ${props => (props.isLargeInterface ? 32 : 24)}px;
`
const IntervalNameContainer = styled(View)`
    max-width: ${({ theme }) => (theme.isMobile ? 200 : 400)}px;
    background-color: rgba(0, 0, 0, 0.1);
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`
export default InstructorStreamOverlay
