import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components/native'
import colors from 'colors'
import { INSTRUCTOR_HEADER_HEIGHT, USER_TYPES, INSTRUCTOR_DRAWER_WIDTH } from '@constants'
const { TRAINEE, INSTRUCTOR, ADMIN } = USER_TYPES
import { Inter600, TEXT_LINEAR_GRADIENT } from 'typography'
import * as screenNames from 'screenNames'
import { RowContainer } from 'commons/containers'
import { useNavigation } from '@react-navigation/native'
import { agoraStreamState, searchBody } from 'apollo/cache'
import { useReactiveVar } from '@apollo/client'
import { getCurrentUser } from 'apollo/selectors'
import * as navigate from 'navigationActions'
import { TouchableOpacity, Image, View, useWindowDimensions } from 'react-native'
import VectorIcon from 'components/VectorIcon'
import UserAvatar from 'components/UserAvatar'
import DropdownMenu from 'components/DropdownMenu'
import NavigationItem from 'src/navigators/NavigationElements'
import ClassSearchBar from 'components/trainee/ClassSearchBar'
const noop = () => {}

export const WebHeader = ({ isTraineePortal = false }) => {
    const navigation = useNavigation()
    const { height: windowHeight } = useWindowDimensions()
    const headerHeight = useMemo(() => windowHeight * 0.1, [windowHeight])
    const streamState = useReactiveVar(agoraStreamState)
    const searchText = useReactiveVar(searchBody)
    const [menuVisible, setMenuVisible] = useState(false)
    const { streamRoomJoined: isUserInStream = false } = streamState
    const [selectedItem, setSelectedItem] = useState('Home')
    const { userType = TRAINEE } = getCurrentUser()
    const isInstructor = userType == INSTRUCTOR || userType == ADMIN
    const toggleMenuVisible = () => !isUserInStream && setMenuVisible(!menuVisible)

    const traineeHomeNav = navigate.getNavigationAction({
        navigation,
        navigationAction: navigate.navigateTraineeHomeAction,
    })

    const instructorHomeNav = navigate.getNavigationAction({
        navigation,
        navigationAction: navigate.navigateInstructorHome,
    })

    const contactNav = navigate.getNavigationAction({
        navigation,
        navigationAction: navigate.navigateTraineeSupportAction,
        params: { initial: false },
    })

    const notificationNav = navigate.getNavigationAction({
        navigation,
        navigationAction: navigate.navigateTraineeNotificationAction,
    })

    const exploreNav = navigate.getNavigationAction({
        navigation,
        navigationAction: navigate.navigateTraineeExploreAction,
    })

    const goToExplore = () => {
        if (isUserInStream) return
        setSelectedItem('Explore')
        exploreNav()
    }

    const handleMyHomebodyPress = () => {
        if (isUserInStream) return

        if (isInstructor) {
            instructorHomeNav()
        } else {
            setSelectedItem('Home')
            traineeHomeNav()
        }
    }

    const goToLandingPage = () => {
        if (isUserInStream) return
        navigation.navigate(screenNames.LANDING_PAGE)
    }
    const goToContactUs = () => {
        if (isUserInStream) return
        setSelectedItem('Profile')
        contactNav()
    }

    const goToNotifications = () => {
        if (isUserInStream) return
        setSelectedItem('Profile')
        notificationNav()
    }

    const setSearchText = text => {
        searchBody(text)
    }

    const handleSearch = () => {
        searchText && goToExplore()
    }

    return (
        <HeaderContainer isTraineePortal={isTraineePortal} headerHeight={headerHeight}>
            <LogoContainer onPress={goToLandingPage}>
                <Logo source={require('assets/logo.png')} />
            </LogoContainer>
            <ClassSearchBar
                placeHolder={'Search'}
                searchText={searchText}
                setSearchText={setSearchText}
                handleSearch={handleSearch}
            />
            <RightActionsContainer>
                <ExploreTextContainer>
                    <NavigationItem title="Explore" onPress={goToExplore} isDrawer={false} />
                </ExploreTextContainer>
                <ChatIconContainer onPress={goToContactUs}>
                    <VectorIcon
                        family="Ionicons"
                        name="chatbubble-ellipses-outline"
                        size={20}
                        color={colors.darkGrey}
                    />
                </ChatIconContainer>
                <TouchableOpacity onPress={goToNotifications}>
                    <NotificationBellIConContainer>
                        <VectorIcon family="Feather" name="bell" size={20} color={colors.darkGrey} />
                        {/* <NotificationBadge>9</NotificationBadge> */}
                    </NotificationBellIConContainer>
                </TouchableOpacity>
                <MyHomebodyText onPress={handleMyHomebodyPress}>My HOMEBODY</MyHomebodyText>
                <AvatarAndIconsContainer>
                    <UserAvatar size="medium" onPress={toggleMenuVisible} />
                    <DropdownMenu
                        menuVisible={menuVisible}
                        setMenuVisible={setMenuVisible}
                        isUserInStream={isUserInStream}
                        isInstructor={isInstructor}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        userType={userType}
                    />
                </AvatarAndIconsContainer>
            </RightActionsContainer>
        </HeaderContainer>
    )
}

const HeaderContainer = styled(RowContainer)`
    justify-content: ${props => (!props.theme.isMobile ? 'space-between' : 'center')};
    align-items: center;
    background-color: white;
    height: ${({ headerHeight }) => headerHeight}px;
    min-height: ${INSTRUCTOR_HEADER_HEIGHT * 0.6}px;
    max-height: ${INSTRUCTOR_HEADER_HEIGHT}px;
    padding-right: 75px;
    padding-left: ${({ isTraineePortal }) => (isTraineePortal ? '5%' : undefined)};
    z-index: 10;
    shadow-color: #000000;
    shadow-offset: 0px 4px;
    shadow-radius: 4px;
    shadow-opacity: 0.25;
`

const LogoContainer = styled(TouchableOpacity)`
    align-self: center;
    justify-content: center;
    ${({ isTraineePortal }) => !isTraineePortal && `width: ${INSTRUCTOR_DRAWER_WIDTH}px;`}
`
const AvatarAndIconsContainer = styled(RowContainer)`
    align-content: right;
    justify-content: flex-end;
    align-items: center;
`

const NotificationBellIConContainer = styled.View`
    display: flex;
    justify-content: center;
    flex-direction: row;
    position: relative;
    margin-right: 70px;
`

const RightActionsContainer = styled.View`
    display: flex;
    width: 600px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
`

const NotificationBadge = styled.Text`
    background-color: ${colors.homebodyTurquoise};
    color: ${colors.white};
    border-radius: 50;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding: 0 4px;
    height: 16px;
    min-width: 16px;
    position: absolute;
    top: -10px;
    left: 16px;
`
const ChatIconContainer = styled.TouchableOpacity`
    margin-right: 48px;
`

const ExploreTextContainer = styled(View)`
    margin-right: 70px;
`

const MyHomebodyText = styled(Inter600)`
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    align-self: center;
    margin-right: 24px;
    ${TEXT_LINEAR_GRADIENT({
        direction: '90.74deg',
        colorStop1: `${colors.homebodyTurquoise} 3.23%`,
        colorStop2: `${colors.homebodyGreen} 70.45%`,
    })}
`

const Logo = styled(Image)`
    height: 30px;
    width: 263px;
    align-self: center;
`
export default WebHeader
